/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../shared/database/database.service';
import { DetailsStateParams } from '../../shared/interfaces/DetailsStateParams';
import { PawnedProperty } from './model/pawned-property';
import { SlideSourceInfo } from '../../schema/SlideSourceInfo';

/**
 * A component that displays the details of a pawned property.
 */
@Component({
    selector: 'sds-pawned-property-detail',
    template: require('./pawned-property-detail.component.html')
})
export class PawnedPropertyDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        ['Description', { srcHtml: 'app/rms/pawned-property/slides/description.html' }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The pawned property model to display.
     */
    public pawnedProperty: PawnedProperty;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the PawnedPropertyDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param involvementsSlideSourceInfo inject the involvement slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(PawnedProperty, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.pawnedProperty = detailModel, () => this.onError.emit());
    }
}
