/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import PermissionsRepository from 'app/permissions/PermissionsRepository';
import { TabsScope } from '../../interfaces/TabsScope';
import { APP_TAB_CALL_PERMISSION_NAME, APP_TAB_INCIDENTS_PERMISSION_NAME, APP_TAB_SEARCH_PERMISSION_NAME, APP_TAB_UNITS_PERMISSION_NAME } from './constants';

/**
 * Function which checks the visibility for all of the tabs
 *
 * @param scope tabsScope that will be modified wether the tabs are visible or not
 * @param permissionsRepository The permission repository that is reponsible for check the permissions.
 */
export function checkTabsVisibility(scope: TabsScope, permissionsRepository: PermissionsRepository): void {
    scope.hideCalls = !permissionsRepository.checkStatePermissions(APP_TAB_CALL_PERMISSION_NAME);
    scope.hideUnits = !permissionsRepository.checkStatePermissions(APP_TAB_UNITS_PERMISSION_NAME);
    scope.hideMyIncidents = !permissionsRepository.checkStatePermissions(APP_TAB_INCIDENTS_PERMISSION_NAME);
    scope.hideSearch = !permissionsRepository.checkStatePermissions(APP_TAB_SEARCH_PERMISSION_NAME);
}
