/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { injectionCondition } from '../../../../../conditional-injection';
import { DatabaseService } from '../../../../../shared/database/database.service';
import { Offense } from './offense';
import { StatuteService } from './statute.service';

/**
 * Gets the statutes from the 'lwoffs' table.
 */
@Injectable()
@injectionCondition({ minServerVersion: '1701' })
export class OffenseStatuteService extends StatuteService {

    constructor(private databaseService: DatabaseService) {
        super();
    }

    /**
     * @inheritdoc
     */
    protected getStatutes(incidentId: string): Observable<string[]> {
        return this.databaseService.getAll(Offense, `number=${incidentId}`)
            .map(({ results: offenses }) => offenses.map(o => o.statute));
    }
}
