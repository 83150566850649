/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { MemoizedTreeNode } from '../../../../data-structures/tree-node';
import { ClassNode } from '../class/class-node';

/**
 * A node that contains a reference to a particular property of a class.
 */
export abstract class PropertyNode extends MemoizedTreeNode {

    /**
     * Constructs a new instance of the PropertyNode class.
     *
     * @param parent The parent ClassNode.
     * @param propertyKey The property key (or name if you prefer).
     */
    constructor(public parent: ClassNode, public propertyKey: string | symbol) {
        super();
    }
}
