/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * A general interface that represents a node in a tree.
 */
export abstract class TreeNode {

    /**
     * @returns The collection of child nodes.
     */
    public get children(): Iterable<TreeNode> {
        throw new Error('Abstract property');
    }
}

/**
 * A node that uses memoization to store the children
 * once they have been computed.
 */
export abstract class MemoizedTreeNode extends TreeNode {

    /**
     * The memoized collection of child nodes.
     */
    private _children: Iterable<TreeNode>;

    /**
     * @inheritdoc
     */
    public get children(): Iterable<TreeNode> {
        if (!this._children) {
            this._children = [...this.initializeChildren()];
        }
        return this._children;
    }

    /**
     * Initializes the collection of child nodes.
     *
     * @returns The iterable collection of child nodes.
     */
    protected abstract initializeChildren(): Iterable<TreeNode>;
}
