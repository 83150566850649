/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAttributes = angular.IAttributes;

const offlineIcon: string = require('../../assets/icons/offlineIcon.svg');

ConnectionErrorBar.$inject = ['$sce'];

/**
 * A factory function that creates a directive that displays an error bar across the top of the page
 * whenever the connection is lost, i.e. `connected` is false.
 *
 * @returns {IDirective} A directive that displays an error bar across the top of the page when the connection is lost.
 */
export default function ConnectionErrorBar($sce: any): IDirective {
    return {
        restrict: 'E',
        scope: {
            connected: '='
        },
        template: `<ion-header-bar class="bar bar-header bar-subheader bar-dark">
                       <div ng-bind-html="offlineIcon"></div>
                       <span>No Server Connection</span>
                   </ion-header-bar>`,
        link: (scope: ConnectionErrorBarScope, _element: IAugmentedJQuery, attributes: IAttributes) => {
            scope.offlineIcon = $sce.trustAsHtml(offlineIcon);

            // Watch for the `connected` flag to change. When it does, add or remove the
            // 'not-connected' class from the `sds-connection-error-bar` element.
            scope.$watch('connected', (connected: boolean) => {
                let method = connected ? '$removeClass' : '$addClass';
                attributes[method]('not-connected');
            });
        }
    };
}

/**
 *
 */
interface ConnectionErrorBarScope extends IScope {

    /**
     *
     */
    offlineIcon: string;
}
