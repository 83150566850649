/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Constraint } from './constraint';

/**
 * Represents a request to the Tables API.
 */
export class TablesApiRequest {

    /**
     * The name of the database table.
     */
    public table: string;

    /**
     * The set of fields that should be included in the response.
     */
    public fields = new Set<string>();

    /**
     * The set of columns that should be expanded.
     */
    public expands = new Set<string>();

    /**
     * The set of tables that should be included.
     */
    public includes = new Set<string>();

    /**
     * A flag that indicates if the involvements should be retrieved.
     */
    public getInvolvements: boolean;

    /**
     * Constructs a new instance of the TablesApiRequest class.
     *
     * @param constraint The constraint that determines which records are returned by the API.
     */
    constructor(public constraint: Constraint) {
    }
}

/**
 * The data that is needed while traversing the model tree in order to build a request.
 */
export interface RequestCreationData {

    /**
     * The current expand/include path.
     */
    currentPath: string[];

    /**
     * The request that needs to be built.
     */
    request: TablesApiRequest;
}
