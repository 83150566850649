/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { provideBasedOnCondition } from '../../conditional-injection/provide-based-on-condition';
import { SharedModule } from '../shared/shared.module';
import { LawIncidentDetailComponent } from './law/detail/law-incident-detail.component';
import { EmsIncidentDetailComponent } from './ems/detail/ems-incident-detail.component';
import { FireIncidentDetailComponent } from './fire/detail/fire-incident-detail.component';
import { IncidentOfficerComponent } from './list/incident-officer/incident-officer.component';
import { IncidentSearchComponent } from './search/incident-search.component';
import { PatientAssessmentsBatchRequest } from './ems/detail/data/patient-assessments-batch-request.service';
import { PatientAssessmentsRequestCreator } from './ems/detail/data/patient-assessments-request-creator.service';
import { PatientDrugsBatchRequest } from './ems/detail/data/patient-drugs-batch-request.service';
import { PatientDrugsRequestCreator } from './ems/detail/data/patient-drugs-request-creator.service';
import { PatientTreatmentsBatchRequest } from './ems/detail/data/patient-treatments-batch-request.service';
import { PatientTreatmentsRequestCreator } from './ems/detail/data/patient-treatments-request-creator.service';
import { OffenseStatuteService } from './law/detail/statutes/offense-statute.service';
import { OriginalStatuteService } from './law/detail/statutes/original-statute.service';
import { StatuteService } from './law/detail/statutes/statute.service';

@NgModule({
    imports: [SharedModule],
    declarations: [
        EmsIncidentDetailComponent,
        FireIncidentDetailComponent,
        LawIncidentDetailComponent,
        IncidentOfficerComponent,
        IncidentSearchComponent
    ],
    providers: [
        PatientAssessmentsBatchRequest,
        PatientAssessmentsRequestCreator,
        PatientDrugsBatchRequest,
        PatientDrugsRequestCreator,
        PatientTreatmentsBatchRequest,
        PatientTreatmentsRequestCreator,
        provideBasedOnCondition(StatuteService, OriginalStatuteService, OffenseStatuteService)
    ]
})
export class IncidentModule { }
