/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import AdvancedWebSocketOptions from './AdvancedWebSocketOptions';

const advancedWebSocketDefaultOptions: AdvancedWebSocketOptions = {
    url: '',
    timeout: 3000,
    pingInterval: 5000,
    headers: {},
    acceptAllCerts: true
};

export default advancedWebSocketDefaultOptions;
