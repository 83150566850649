/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import * as angular from 'angular';
import { SpillmanLocalStorage } from '../shared/storage/spillman-local-storage';
import { CustomNotificationBuilder } from './custom-notification-builder/custom-notification-builder';
import { CustomNotificationOptions } from './custom-notification-builder/custom-notification-options';

/**
 * The name of the event that is broadcast when the user the user
 * was unassigned from the CAD call.
 */
export const REFRESH_PUSH_NOTIFICATIONS_EVENT = 'Spillman:RefreshPushNotifications';

@Injectable()
export class NotificationService {

    public static $inject = [
        '$rootScope',
        '$state',
        '$timeout',
        'spillmanLocalStorage',
        'customNotificationBuilder'
    ];

    public unitName: string;
    public updatedParams: Array<string>;
    public callsData = <any>[];
    public unitsData = <any>[];
    public isInitialDataSet = false;
    public updatedCallId = '';
    public missedUpdatesList = {};
    public currentNotification = '';

    public update: Object;
    private NOTIFICATION_DURATION = 5000;
    private NOTIFICATION_TYPES = {
        'callAssigned': 'call assigned',
        'callUnassigned': 'call unassigned',
        'callUpdated': 'call updated'
    };
    private CALL_PARAMS = ['status', 'unit', 'otherUnits', 'callNumberAndType',
        'agency', 'zone', 'callAddress', 'comments', 'flexComment',
        'complainantFirstName', 'complainantMiddleName', 'complainantLastName',
        'complainantSuffix', 'complainantPhone', 'complainantAddress',
        'complainantCity', 'complainantState', 'complainantZip',
        'contactName', 'contactPhone', 'contactAddress'];

    /**
     * Provide  in-app notifications logic
     * @param $rootScope The Angular $rootScope that can be used to broadcast the application events.
     * @param $state The service that transitions between states.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param spillmanLocalStorage The object that handles storing Spillman-specific data in local storage.
     * @param customNotificationBuilder
     */
    constructor(
        @Inject('$rootScope') private $rootScope: angular.IRootScopeService,
        @Inject('$state') private $state: angular.ui.IStateService,
        @Inject('$timeout') private $timeout: angular.ITimeoutService,
        private spillmanLocalStorage: SpillmanLocalStorage,
        private customNotificationBuilder: CustomNotificationBuilder
    ) {
        this.unitName = this.getUnitName();
    }

    /**
     * On success notification shown callback
     */
    public onSuccess = () => {
        const routeName = 'app.calls.callsDetails';
        const params = {
            id: this.updatedCallId,
            updates: this.updatedParams
        };
        this.$state.go(routeName, params);
        this.updatedCallId = '';
    };

    /**
     * Notification trigger
     */
    public onUpdate(data: any) {
        let message;

        if (data?.message) {
            message = data.message;
        } else if (data?.data) {
            message = data.data;
        }

        if (message) {
            this.update = JSON.parse(message);
            const updateType = this.getUpdateType();
            let isUnAssignEvent = false;

            if (this.isInitialDataSet && updateType === 'callData') {
                // eslint-disable-next-line dot-notation,@typescript-eslint/dot-notation
                this.update['data'].callData.forEach((callData: any) => {
                    if (!this.isNewCallData(callData) && this.isAssignedToUnitCall(callData)) {
                        const notificationType = this.getNotificationType(callData);
                        const activeCallId = this.getActiveCallId();
                        const callIndex = this.getCallIndex(callData);
                        isUnAssignEvent = notificationType === this.NOTIFICATION_TYPES.callUnassigned;

                        this.updatedParams = this.getUpdatedParams('callsData', callData, callIndex);

                        if (this.isVisibleUpdates()) {
                            this.updatedCallId = callData.callIdAndType;
                            const options = this.getNotificationSettings(notificationType);
                            const isNewAssign = notificationType === this.NOTIFICATION_TYPES.callAssigned ||
                                notificationType === this.NOTIFICATION_TYPES.callUnassigned;

                            if (this.currentNotification === this.NOTIFICATION_TYPES.callAssigned) {
                                return;
                            }

                            this.setMissedUpdatesList(isNewAssign);

                            if ((activeCallId === this.updatedCallId) || (isNewAssign && notificationType === this.NOTIFICATION_TYPES.callUpdated)) {
                                return;
                            }

                            this.currentNotification = notificationType;
                            this.showNotification(options);
                        }
                    }
                });
            }
            this.setInitialData();
            if (isUnAssignEvent) {
                this.refreshPushNotificationSubscription();
            }
        }
    }

    /**
     * Shows notification
     * @param options notification configuration
     */
    private showNotification(options: CustomNotificationOptions) {
        this.customNotificationBuilder.hideNotification();
        this.customNotificationBuilder.showNotification(options, this.onSuccess);

        this.$timeout(() => {
            this.currentNotification = '';
        }, this.NOTIFICATION_DURATION);
    };

    /**
     * Checks is new call data received
     * @param callData contains updated call data
     */
    private isNewCallData(callData: any): boolean {
        const isNewCallData = this.callsData && !this.callsData
            .map((item: any) => (item.callIdAndType))
            .find((item: any) => callData.callIdAndType === item);

        return isNewCallData;
    }

    /**
     * Checks is unit assigned to call or was assigned to call before update (reassign)
     */
    private isAssignedToUnitCall(data: any): boolean {
        const oldCallData = (data && this.getCallDataById(data)) || {};
        const unitData = this.unitsData.find((item: any) => item.unit === this.unitName);
        const isCallActive = unitData && unitData.callIdAndType === data.callIdAndType;
        const isUnitWasAssignedToCall = oldCallData.unit === this.unitName || (oldCallData.otherUnits && oldCallData.otherUnits.includes(this.unitName));
        const isUnitAssignedToCall = data.unit === this.unitName || (data.otherUnits && data.otherUnits.includes(this.unitName));
        return isUnitWasAssignedToCall || isUnitAssignedToCall || isCallActive;
    }

    /**
     * Checks is responsible unit was changed
     * @param callData contains call data
     */
    private isUnitChanged(callData: any): boolean {
        const oldCallData = this.getCallDataById(callData);
        const isUnitStillRespondingToCall = oldCallData.otherUnits.includes(this.unitName) && callData.otherUnits.includes(this.unitName);
        return callData.unit !== oldCallData.unit && !isUnitStillRespondingToCall;
    }

    /**
     * Checks is received updated values should be highllighted on call details screen
     */
    private isVisibleUpdates() {
        let isVisible = false;
        this.updatedParams.forEach(param => {
            if (this.CALL_PARAMS.indexOf(param) !== -1) {
                isVisible = true;
            }
        });
        return isVisible;
    }

    /**
     * Gets call data by call id
     * @param callData contains call data
     */
    private getCallDataById(callData: any) {
        return this.callsData.find((call: any) => call.callIdAndType === callData.callIdAndType) || {};
    }

    /**
     * Gets call index
     * @param callData ontains call data
     */
    private getCallIndex(callData: any) {
        return this.callsData.findIndex((call: any) => (call.callIdAndType === callData.callIdAndType));
    }

    /**
     * Gets unit index
     */
    private getUnitIndex() {
        return this.unitsData.findIndex((unit: any) => (unit.unit === this.unitName));
    }

    /**
     * Gets active call id (checks current location)
     */
    private getActiveCallId() {
        const locationParams = location.href.split('/');
        return locationParams[locationParams.length - 1];
    }

    /**
     * Creates list of updates that was missed by user
     */
    private setMissedUpdatesList(isNewAssign: boolean) {

        if (this.missedUpdatesList[this.updatedCallId]) {
            if (isNewAssign) {
                this.missedUpdatesList[this.updatedCallId].assign = true;
            }
            const updatedParams = this.missedUpdatesList[this.updatedCallId].data || [];
            updatedParams.push(...this.updatedParams);
            this.missedUpdatesList[this.updatedCallId].data = [...new Set(updatedParams)];

        } else {
            this.missedUpdatesList[this.updatedCallId] = {
                assign: isNewAssign,
                data: this.updatedParams
            };
        }
    }

    /**
     * Gets list of updated params
     * @param dataType is it calls or units data
     * @param data calls or units data
     * @param callIndex
     */
    private getUpdatedParams(dataType: string, data: any, callIndex: number): string[] {
        const oldData = this[dataType];
        const updatedParams: string[] = [];
        Object.keys(oldData[callIndex]).forEach(key => {
            if (angular.isDefined(data[key]) && oldData[callIndex][key] !== data[key]) {
                updatedParams.push(key);
            }
        });
        Object.keys(data).forEach(key => {
            if (Object.keys(oldData[callIndex]).indexOf(key) === -1) {
                updatedParams.push(key);
            }
        });
        return updatedParams;
    }

    /**
     * Set/update calls and unit data locally
     */
    private setInitialData(): void {
        /* eslint-disable dot-notation,@typescript-eslint/dot-notation */
        const data = this.update['data'] || {};
        const callsData = data.callData;
        const unitsData = data.unitData;

        if (callsData) {
            callsData.forEach((callData: any) => {
                if (!this.callsData || this.isNewCallData(callData)) {
                    this.callsData.push(callData);
                } else {
                    // update stored call data
                    try {
                        if (this.isInitialDataSet) {
                            const callIndex = this.getCallIndex(callData);
                            Object.assign(this.callsData[callIndex], callData);
                        }
                    } catch (e) {
                        // eslint-disable-next-line no-console
                        console.log(e);
                    }

                }
            });
        } else if (unitsData) {
            unitsData.forEach((unitData: any) => {
                const unitIndex = this.getUnitIndex();
                if (unitIndex !== -1) {
                    Object.assign(this.unitsData[unitIndex], unitData);
                } else {
                    this.unitsData.push(unitData);
                }
            });
        }
        this.callsData = this.callsData ? this.callsData : callsData;
        this.unitsData = this.unitsData ? this.unitsData : unitsData;
        this.isInitialDataSet = !!(this.callsData && this.unitsData);
    }

    /**
     * Set notification settings
     */
    private getNotificationSettings(notificationType: string): CustomNotificationOptions {
        const themeType = this.getThemeType();
        const notificationColor = {
            update: themeType ? '#b7b159' : '#f7ec95',
            assign: themeType ? '#d27a7a' : '#e47e6c'
        };
        const notificationTextColor = {
            update: themeType ? '#4e4e4e' : '#333333',
            assign: themeType ? '#ffffff' : '#ffffff'
        };

        const backgroundColor = notificationType === this.NOTIFICATION_TYPES.callUpdated ?
            notificationColor.update : notificationColor.assign;
        const textColor = notificationType === this.NOTIFICATION_TYPES.callUpdated ?
            notificationTextColor.update : notificationTextColor.assign;
        const message = this.getNotificationMessage(notificationType);
        return <CustomNotificationOptions>{
            message,
            duration: this.NOTIFICATION_DURATION, // default 3000. Or specify the nr of ms yourself.
            position: 'top',
            styling: {
                backgroundColor, // Default #333333
                opacity: 0.9, // 0.0 (transparent) to 1.0 (opaque). Default 1
                color: textColor, // Default #FFFFFF
                fontSize: 14, // Default is approx. 10.
                textAlign: 'center',
                horizontalPadding: 20,
                verticalPadding: 16
            }
        };
    }

    /**
     * Define which message should be shown
     * @param notificationType type of update
     */
    private getNotificationMessage(notificationType: string) {
        let message = '';

        switch (true) {
            case notificationType === this.NOTIFICATION_TYPES.callAssigned:
                message = 'You are assigned to a new call';
                break;

            case notificationType === this.NOTIFICATION_TYPES.callUnassigned:
                message = 'You are unassigned from a call';
                break;

            case notificationType === this.NOTIFICATION_TYPES.callUpdated:
                message = 'Call details updated';
                break;
            default:
                break;
        }
        return message;
    }

    /**
     * Specify update type
     */
    private getNotificationType(callData: any): string {
        let type = '';
        const unitWasChanged = angular.isDefined(callData.unit);
        switch (true) {
            case !!(callData && (unitWasChanged || callData.otherUnits)):
                const isCallAssignedToUnit = this.unitName === callData.unit;
                const isUnitRespondingToCall = callData.otherUnits && callData.otherUnits.includes(this.unitName);

                if ((isCallAssignedToUnit || isUnitRespondingToCall) && this.isUnitChanged(callData)) {
                    type = this.NOTIFICATION_TYPES.callAssigned;
                } else if (!isUnitRespondingToCall && unitWasChanged && callData.unit !== this.unitName) {
                    type = this.NOTIFICATION_TYPES.callUnassigned;
                } else {
                    type = this.NOTIFICATION_TYPES.callUpdated;
                }
                break;

            default:
                type = this.NOTIFICATION_TYPES.callUpdated;
        }
        return type;
    }

    /**
     * Define what update was received
     */
    private getUpdateType(): string {
        /* eslint-disable dot-notation,@typescript-eslint/dot-notation */
        return this.update['data'].callData ? 'callData' : 'unitData';
    }

    /**
     * Returns current unit name
     */
    private getUnitName(): string {
        /* eslint-disable dot-notation,@typescript-eslint/dot-notation */
        return this.spillmanLocalStorage['session'].data.unitNumber;
    }

    /**
     * Returns current theme
     */
    private getThemeType(): number {
        let theme = this.spillmanLocalStorage.getObject({ key: `${this.unitName}.themeSetting` }) || {};
        return Number(theme);
    }

    /**
     * Broadcast the refresh event to update subscription on push notification when the user
     * was unassigned from the CAD call from the Flex-client side
     */
    private refreshPushNotificationSubscription() {
        const callIds: string[] = [];
        this.callsData.forEach((call: any) => {
            if (call.unit === this.unitName || call.otherUnits.includes(this.unitName)) {
                callIds.push(call.callIdAndType);
            }
        });
        this.$rootScope.$broadcast(REFRESH_PUSH_NOTIFICATIONS_EVENT, callIds);
    }
}
