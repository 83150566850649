/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * An object representing a component which creates a link to the call history screen.
 */
const callHistoryLinkComponent = {
    template: '<div class="call-history-link" ui-sref="app.search.callsHistory">Call History</div>'
};

export default callHistoryLinkComponent;
