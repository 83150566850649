/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../../shared/database/decorators';

/**
 * A phone number.
 */
@table('nmotel')
export class PhoneNumber {

    @field('phone')
    public phone: string;

    @field('ptype')
    public priority: string;

    @field('phtype')
    public type: string;
}
