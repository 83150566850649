/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IScope = angular.IScope;
import { FileDownloader } from '../file-downloader';
import { FileMetadata } from '../../../../schema/FileMetadata';

FileAttachmentDirective.$inject = ['fileDownloader'];

/**
 * A directive that displays a file attachment.
 *
 * @param fileDownloader The service that downloads files.
 */
function FileAttachmentDirective(fileDownloader: FileDownloader): IDirective {
    return {
        restrict: 'E',
        scope: {
            file: '='
        },
        template: '<a ng-click="open(file)"><file-display file-name="file.name"></file-display></a>',
        link: function ($scope: FileAttachmentScope) {
            $scope.open = fileDownloader.download.bind(fileDownloader);
        }
    };
}

export default FileAttachmentDirective;

/**
 * An interface that defines the scope for the FileAttachmentDirective.
 */
interface FileAttachmentScope extends IScope {

    /**
     * Opens the specified file.
     *
     * @param file The metadata that describes the file to open.
     */
    open: (file: FileMetadata) => void;
}
