/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, includeOne, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { NameReference } from '../../shared/model/name-reference';
import { VehicleReference } from '../../shared/model/vehicle-reference';
import { Offense } from './offense';
import { Involvement } from '../../../schema/Involvement';
import { IncidentReference } from '../../shared/model/incident-reference';
import { Circumstance } from './circumstance';

/**
 * The citation model.
 */
@table('ctmain')
export class Citation {

    @field('agency')
    public agency: string;

    @expand('locatn', 'desc')
    public area: string;

    @field('bondamt')
    public bondAmount: number;

    @expand('bondtyp', 'descrpt')
    public bondType: string;

    @field('number')
    public id: string;

    @expand('court', 'desc')
    public court: string;

    @field('dtcourt')
    public courtDate: Date;

    @expand('nameid', NameReference)
    public defendant: NameReference | string;

    @expand('issuoff', 'fullnam')
    public issuingOfficer: string;

    @expand('incidnt', IncidentReference)
    public relatedIncident: IncidentReference | string;

    @field('spact')
    public speedActual: number;

    @field('sppost')
    public speedPosted: number;

    @field('spsafe')
    public speedSafe: number;

    @includeOne('ctinfo', 'ctstid')
    public stateCitationNumber: string;

    @field('street')
    public street: string;

    @field('city')
    public city: string;

    @field('state')
    public state: string;

    @field('zip')
    public zip: string;

    @expand('citype', 'desc')
    public type: string;

    @expand('vehiid', VehicleReference)
    public vehicle: VehicleReference | string;

    @field('vildate')
    public violationDate: Date;

    @field('dtissue')
    public whenIssued: Date;

    @includeMany('ctoffs', Offense)
    public offenses: Offense[];

    @includeMany('ctccirc', Circumstance)
    public circumstances: Circumstance[];

    @includeMany('ctcasnum', 'casenum')
    public caseNumbers: string[];

    @includeOne('ctmdesc', 'text')
    public comments: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
