/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * The pawned description model
 */
@table('pndesc')
export class PawnedDescription {

    @field('number')
    public id: string;

    @field('subject')
    public subjectCode: string;

    @expand('subject', 'desc')
    public subject: string;

    @field('descrip')
    public description: string;
}
