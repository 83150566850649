/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input, OnInit, Inject } from '@angular/core';
import { AttachFilesTablesStore } from '../attach-files-tables/attach-files-tables-store.service';
import { PermissionsRepository } from '../../../../permissions/PermissionsRepository';

/**
 * A component which displays the link to the attachment screen.
 */
@Component({
    selector: 'sds-attachment-link',
    template: `
        <a *ngIf="showLink" class="button button-clear button-icon ion-paperclip" (click)="viewAttachments()">
    `
})
export class AttachmentLinkComponent implements OnInit {

    /**
     * The record primary key.
     */
    @Input() public key: string;

    /**
     * The record table name.
     */
    @Input() public table: string;

    /**
     * Flag indicating if the link should be shown.
     */
    public showLink: boolean;

    /**
     * Constructs a new instance of the AttachmentLinkComponent.
     *
     * @param attachFilesTablesStore A store containing information about tables which can have attachments.
     * @param $state The service that transitions between states.
     * @param permissionsRepository The repository which stores permissions of a user.
     */
    constructor(
        private attachFilesTablesStore: AttachFilesTablesStore,
        @Inject('$state') private $state: angular.ui.IStateService,
        @Inject('permissionsRepository') private permissionsRepository: PermissionsRepository
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.showLink = this.key && this.attachFilesTablesStore.isAttachable(this.table) && this.permissionsRepository.checkStatePermissions('app.attachments.attachmentsList');
    }

    /**
     * Transitions the screen to the attachment state using the table and id.
     */
    public viewAttachments() {
        this.$state.go('app.attachments.attachmentsList', {
            table: this.table,
            id: this.key
        });
    }
}
