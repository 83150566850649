/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BatchRequest } from '../../../../../api/batch-request/batch-request.service';
import { Patient } from '../model';
import { Assessment } from '../model';
import { PatientAssessmentsRequestCreator } from './patient-assessments-request-creator.service';
import { ResponseParser } from '../../../../../api/response-parser.service';
import { Logger } from '../../../../../logging';

/**
 * A service that loads the assessments for an entire list of patients
 * by making only one request to the server.
 */
@Injectable()
export class PatientAssessmentsBatchRequest extends BatchRequest<Patient, Assessment> {

    /**
     * Constructs a new instance of the PatientAssessmentsBatchRequest class.
     *
     * @param http The Angular service that makes http requests.
     * @param requestCreator The object that creates the request to retrieve
     *                       the joined items for the list of models.
     * @param responseParser The object that knows how to parse a response from Spillman's API.
     * @param logger Logs errors to the appropriate location based on the environment.
     */
    constructor(
        http: Http,
        requestCreator: PatientAssessmentsRequestCreator,
        responseParser: ResponseParser,
        logger: Logger
    ) {
        super(http, requestCreator, responseParser, logger);
    }

    /**
     * @inheritdoc
     */
    protected getPrimaryKey(patient: Patient): string {
        return patient.id;
    }

    /**
     * @inheritdoc
     */
    protected getForeignKey(assessment: Assessment): string {
        return assessment.number;
    }

    /**
     * @inheritdoc
     */
    protected updateModelWithJoins(patient: Patient, assessmentsPromise: Promise<Assessment[]>): void {
        assessmentsPromise.then(assessments => patient.assessments = assessments);
    }
}
