/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import angular, { IRootScopeService, IScope } from 'angular';
import { CALL_ACTIONS } from '../../../../../filtering/consts';
import AccordionComponentController from '../../../../../filtering/components/baseClasses/accordion/accordion-component.component';

/**
 * A component of generic accordion that can be personalized
 * @param title defines the title of the accordion component
 * @param options defines options for accordion checkbox
 */
export const callAccordionComponent: angular.IComponentOptions = {
    template: require('../../../../../filtering/components/baseClasses/accordion/accordion-component.component.html'),
    bindings: {
        title: '@',
        options: '<',
        error: '<',
        isLoading: '<',
        selected: '&',
        isAvailable: '<',
        userData: '<',
        unavailableMessage: '<'
    },

    controller: class CallAccordionComponentController extends AccordionComponentController {
        public static $inject = ['$rootScope', '$scope'];

        constructor(
            $rootScope: IRootScopeService,
            $scope: IScope
        ) {
            super($rootScope,$scope,CALL_ACTIONS);
        }
    }
};
