/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../shared/database/decorators';
import { Arrest } from '../../shared/model/arrest';

/**
 * The class that joins an arrest with a offense.
 *
 * Note: The reason this class exists is because there is a many-to-many relationship
 *       between offenses and arrests. So there has to be a joining table in between.
 *       Currently the DatabaseService (and decorators) don't allow you to flatten
 *       joining tables such as this, but it would be a great feature to add.
 */
@table('jlofxar')
export class ArrestJoin {

    @expand('arrnum', Arrest)
    public arrest: Arrest | string;
}
