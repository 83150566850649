/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import ILogService = angular.ILogService;
import IUploadService = angular.angularFileUpload.IUploadService;
import IStateService = angular.ui.IStateService;
import ImageType from '../../../../../schema/ImageType';
import AddAttachmentController from '../AddAttachmentController';
import AddAttachmentScope from '../AddAttachmentScope';
import ImageTypeCache from '../../../../../shared/caching/ImageTypeCache';
import AddAttachmentStateParams from '../AddAttachmentStateParams';
import { UrlFactory } from '../../../../../authentication';
import TimedAlert from '../../../../../shared/TimedAlert';
import IonicLoadingService = ionic.loading.IonicLoadingService;
import IonicPopupService = ionic.popup.IonicPopupService;
import IonicHistoryService = ionic.navigation.IonicHistoryService;

/**
 * The controller for the Add Image page.
 */
export default class AddImageController extends AddAttachmentController<ImageType> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$scope', '$state', '$stateParams', '$q', '$log', 'timedAlert', 'imageTypeCache', 'urlFactory', 'Upload', '$ionicLoading', '$ionicPopup', '$ionicHistory'];

    /**
     * Constructs a new instance of the AddImageController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param $state The service that transitions between states.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     * @param $q The angular service that handles creating and working with promises.
     * @param $log The Angular service that performs logging.
     * @param timedAlert An alert that will automatically close after a specified amount of time.
     * @param imageTypeCache The cache that stores image types from the imgtype table.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param uploadService A service provided by the ng-file-upload library that handles file uploads.
     * @param $ionicLoading An overlay that displays a spinner while waiting for the incidents to be retrieved.
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param $ionicHistory The service that manages the user's browsing history.
     */
    constructor($scope: AddAttachmentScope<ImageType>,
        $state: IStateService,
        $stateParams: AddAttachmentStateParams,
        $q: IQService,
        $log: ILogService,
        timedAlert: TimedAlert,
        imageTypeCache: ImageTypeCache,
        urlFactory: UrlFactory,
        uploadService: IUploadService,
        $ionicLoading: IonicLoadingService,
        $ionicPopup: IonicPopupService,
        $ionicHistory: IonicHistoryService) {
        super($scope, $state, $stateParams, $q, $log, timedAlert, imageTypeCache, urlFactory, uploadService, $ionicLoading, $ionicPopup, $ionicHistory, {
            title: 'Image',
            apiSuffix: 'images',
            previewTemplateUrl: 'app/rms/shared/attachments/add/images/imagePreview.html',
            descriptionMaxLength: 33,
            accept: 'image/*'
        });
    }

    public $onInit = () => {};
}
