/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IResourceClass = angular.resource.IResourceClass;
import IScope = angular.IScope;
import ImageMetadata from '../../schema/ImageMetadata';
import { UrlFactory } from '../../authentication';
import find = require('lodash/find');

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
ThumbnailDirective.$inject = ['imageAPI', 'urlFactory'];

/**
 * A directive which creates a url based on scope parameters passed in and whether there exists a thumbnail
 * for the image; after which it will display the image.
 *
 * @param imageAPI The api to access stored images.
 * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
 * @returns {IDirective} A directive which displays an image based on whether it has a thumbnail.
 */
export default function ThumbnailDirective(imageAPI: IResourceClass<ImageMetadata>, urlFactory: UrlFactory): IDirective {

    /**
     * The primary key column if not explicitly specified.
     */
    const DEFAULT_PRIMARY_KEY_COLUMN = 'number';

    return {
        restrict: 'E',
        scope: {
            table: '@',
            model: '=',
            primaryKeyColumn: '@',
            size: '@'
        },
        template: '<img ng-if="url" ng-src="{{url}}" onerror="this.style.display = \'none\'" onload="this.removeAttribute(\'style\')">',
        link: function ($scope: ThumbnailScope) {

            // By watching for the model to change, this will automatically re-query for the thumbnail when the user
            // pulls to refresh the page, since a new model instance will be created.
            $scope.$watch('model', () => {
                let primaryKeyColumn = $scope.primaryKeyColumn || DEFAULT_PRIMARY_KEY_COLUMN;
                let model = $scope.model || {};
                let primaryKey = model[primaryKeyColumn];

                // If there is no primary key, then just set the URL to undefined.
                if (!primaryKey) {
                    $scope.url = undefined;
                    return;
                }

                imageAPI.query({
                    table: $scope.table,
                    id: primaryKey
                }).$promise.then(metadata => {
                    let thumbnail = find(metadata, m => m.thumbnail1);
                    if (thumbnail) {
                        let size = $scope.size ? '/' + $scope.size : '';
                        $scope.url = urlFactory.create({
                            path: `/images/${thumbnail.number}${size}`
                        });
                    } else {
                        $scope.url = undefined;
                    }
                });
            });
        }
    };
}

/*
 *  Defines the scope for the Thumbnail directive.
 */
interface ThumbnailScope extends IScope {
    /**
     *  The table name.
     */
    table: String;

    /**
     * The model whose thumbnail will be displayed.
     */
    model: Object;

    /**
     * The name of the primary key column.
     * If not specified, then 'number' will be used by default.
     */
    primaryKeyColumn: string;

    /**
     *  The type of image you wish to retrieve.
     *  Options: thumbnail, medium
     *  If size is not provided it will return the largest image size.
     */
    size: String;

    /**
     *  The completed url used to retrieve the image.
     */
    url: String;
}
