/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../../shared/database/decorators';

/**
 * A garage address for the vehicle.
 */
@table('vhaddr')
export class GarageAddress {

    @field('street')
    public street: string;

    @field('city')
    public city: string;

    @field('state')
    public state: string;

    @field('zip')
    public zip: string;
}
