/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import DecoratorTransform from '../../../../shared/transforms/DecoratorTransform';
import AggregatorCadCall from '../../../../schema/AggregatorCadCall';
import CityCache from '../../../../shared/caching/CityCache';

/**
 * A transform that adds the `fullCity` property to the call, which is a reference to a
 * City object from the CityCache.
 */
export default class CallFullCityTransform implements DecoratorTransform<AggregatorCadCall> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['cityCache'];

    /**
     * Constructs a new instance of the CallFullCityTransform class.
     *
     * @param cityCache The cache that stores cities from the apcity table.
     */
    constructor(private cityCache: CityCache) {
    }

    // @Override
    public invoke(call: AggregatorCadCall): void {
        call.fullCity = this.cityCache.map[call.city];
    }
}
