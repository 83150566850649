/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Credentials } from '../Credentials';
import { Observable } from 'rxjs/Observable';

/**
 * An abstract class that defines a method for building an authentication token,
 * which is the value to assign to the 'Authorization' header on all HTTP requests.
 */
export abstract class AuthenticationTokenBuilder {

    /**
     * Builds the authentication token, which will be assigned to the 'Authorization' header.
     *
     * @param credentials The information used to authenticate the user.
     * @returns An Observable that, when resolved, returns the authentication token that
     *          will be assigned to the 'Authorization' header.
     */
    public abstract buildAuthenticationToken(credentials: Credentials): Observable<string>;
}
