/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../../../shared/database/database.service';
import { DetailsStateParams } from '../../../../shared/interfaces/DetailsStateParams';
import { FireIncident, ModusOperandi, Responder } from './model';
import { SlideSourceInfo } from '../../../../schema/SlideSourceInfo';
import { JoinPipe } from '../../../../shared/pipes';
import { RadioLogService } from '../../../shared/radio-log/radio-log.service';

/**
 * A component that displays the details of a fire incident.
 */
@Component({
    selector: 'sds-fire-incident-detail',
    template: require('./fire-incident-detail.component.html')
})
export class FireIncidentDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        ['Narrative', { srcHtml: 'app/rms/shared/slides/comments.html' }],
        ['Supplements', { srcHtml: 'app/rms/shared/slides/supplements.html' }],
        this.radioLogsSlideSourceInfo,
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The fire incident model to display.
     */
    public fireIncident: FireIncident;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the FireIncidentDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     * @param radioLogService A service which retrieves and attaches radio logs to an incident.
     * @param $stateParams The passed in url parameters.
     * @param involvementsSlideSourceInfo The SlideSourceInfo for involvements which is appended to slideUrlMap.
     * @param radioLogsSlideSourceInfo The SlideSourceInfo for radio logs which is appended to slideUrlMap.
     */
    constructor(
        private databaseService: DatabaseService,
        private joinPipe: JoinPipe,
        private radioLogService: RadioLogService<FireIncident>,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('radioLogsSlideSourceInfo') private radioLogsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * Format functions needed to display a list of items correctly inside of a popup.
     */
    public singleItemFormatter = (model: any) => model.description;
    public responderFormatter = (responder: Responder) => responder.name;
    public moFormatter = (mo: ModusOperandi) => this.joinPipe.transform([mo.factor, mo.method], ' - ');

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(FireIncident, this.$stateParams.id)
            .concatMap(this.radioLogService.attachRadioLogs)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.fireIncident = detailModel,
                () => this.onError.emit());
    }
}
