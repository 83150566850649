/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IRootScopeService = angular.IRootScopeService;
import SettingKey from '../SettingKey';
import Setting from '../Setting';
import SettingStorage from '../SettingStorage';
import Option from '../Option';
import UnitFilter from '../../cad/units/filtering/UnitFilter';
import UnitFilterVersion from './UnitFilterVersion';
import { UNIT_ACTIONS } from '../../../app/cad/filtering/consts';
import { PlatformDetector } from '../../conditional-injection/platform-detection/platform-detector.service';
import FiltersStorage from 'app/cad/filtering/storage/FiltersStorage';

/**
 * The setting that determines which CAD units are filtered from the list.
 */
export default class UnitFilterSetting extends Setting<UnitFilter> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$q', '$state', 'settingStorage', '$rootScope', 'unitFiltersStorage','unitFilterVersion', 'platformDetector'];

    /**
     * Constructs a new instance of the UnitFilterSetting class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param settingStorage The object that handles persistent storage of the setting value.
     * @param $rootScope The Angular $rootScope that can be used to broadcast events throughout the app.
     */
    constructor(
        $q: IQService,
        public $state: angular.ui.IStateService ,
        settingStorage: SettingStorage,
        $rootScope: IRootScopeService,
        private filtersStorage: FiltersStorage,
        unitFilterVersion: UnitFilterVersion,
        platformDetector: PlatformDetector
    ) {
        super($q, settingStorage, $rootScope, SettingKey.unitFilterSetting, 'Unit filtering', [new Option('All', UnitFilter.filterNone)], 0, undefined, platformDetector, unitFilterVersion);

        // Listen to ADVANCED_FILTER_CHANGED to create new Custom Filter and apply its filters
        $rootScope.$on(`${UNIT_ACTIONS.ADVANCED_FILTER_CHANGED}`, () => {
            this.createCustomFilter();
        });

        // Listen to ADVANCED_FILTER_DELETED to delete a Custom Filter
        $rootScope.$on(`${UNIT_ACTIONS.ADVANCED_FILTER_DELETED}`, (event, { deletedFilterName }) => {
            this.deleteCustomFilter(deletedFilterName);
        });

        // First time UnitFilterSetting is constructed then create custom filter if needed
        this.createCustomFilter();
    }

    private createCustomFilter() {
        const savedFilterNames = this.filtersStorage.getAllSavedFilterNames();

        savedFilterNames.forEach(name => {
            let filterIndex = this.options.findIndex(option => option.text === name);

            if (filterIndex === -1) {
                const customFilter = (this.options as unknown as Option<UnitFilter>[]).push(new Option(name, UnitFilter.unitCustomFilter, { requiresUnit: true }));
                // If last saved option is custom filter or newFilterApply is true then add recently added custom filter option
                if (this.filtersStorage.isCurrentFilter(name)) {
                    this.selectedIndex = customFilter - 1;
                }
            } else {
                if (this.filtersStorage.isCurrentFilter(name)) {
                    let selectedIndex = this.options.findIndex(option => option.text === name);

                    this.selectedIndex = selectedIndex;
                }
            }
        });
    }

    private deleteCustomFilter(deletedFilterName: string) {
        let filterIndex = this.options.findIndex(option => option.text === deletedFilterName);

        if (filterIndex !== -1) {
            this.options.splice(filterIndex, 1);
        }
    }
}
