/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

const isOsSupported = (): boolean => typeof cordova !== 'undefined' && (cordova.platformId === 'android' || cordova.platformId === 'ios');

const isCrashlyticsAvailable = (): boolean => isOsSupported() && cordova.plugins !== undefined && cordova.plugins.firebase !== undefined && cordova.plugins.firebase.crashlytics !== undefined;

const isAnalyticsAvailable = (): boolean => isOsSupported() && cordova.plugins !== undefined && cordova.plugins.firebase !== undefined && cordova.plugins.firebase.analytics !== undefined;

const isRemoteConfigAvailable = (): boolean => isOsSupported() && cordova.plugins !== undefined && cordova.plugins.firebase !== undefined && cordova.plugins.firebase.config !== undefined;

export { isAnalyticsAvailable, isCrashlyticsAvailable, isOsSupported, isRemoteConfigAvailable };
