/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import ICompileService = angular.ICompileService;
import IHttpService = angular.IHttpService;
import IParseService = angular.IParseService;
import ITimeoutService = angular.ITimeoutService;
import IScope = angular.IScope;
import IAttributes = angular.IAttributes;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import ITranscludeFunction = angular.ITranscludeFunction;
import IDirective = angular.IDirective;
import IPromise = angular.IPromise;
import { UrlFactory } from '../../authentication';
import CancellableFunction from '../interfaces/CancellableFunction';
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;
import debounce = require('lodash/debounce');

const NG_MODEL = 'ngModel';

/**
 * The name of the event that is raised when a value is selected from the autocomplete.
 */
export const autocompleteValueSelectedEvent = 'spillman:autocomplete:value-selected';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
AutocompleteDirective.$inject = [
    '$compile',
    '$http',
    '$injector',
    '$parse',
    '$timeout',
    '$ionicScrollDelegate',
    'vsprintf'
];

/**
 * A factory function that creates the Autocomplete directive. This directive can only be applied to an input of type text.
 * As the user types it will search the database for matching results and display them in a popup below the input.
 *
 * @param $compile The Angular compiler used to process raw DOM elements.
 * @param $http The Angular service that makes http requests.
 * @param $injector The Angular injector service.
 * @param $parse The Angular service that parses a string and converts it into code.
 * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
 * @param $ionicScrollDelegate A delegate used to control the ion-scroll directive.
 * @param vsprintf A function that formats a string using sprintf notation.
 * @returns {IDirective} A directive that will automatically search the database for matching results as the user enters text into an input.
 */
export function AutocompleteDirective(
    $compile: ICompileService,
    $http: IHttpService,
    $injector: angular.auto.IInjectorService,
    $parse: IParseService,
    $timeout: ITimeoutService,
    $ionicScrollDelegate: IonicScrollDelegate,
    vsprintf: (format: string, args: any[]) => string
): IDirective {
    /**
     * The directive name.
     */
    let DIRECTIVE_NAME = 'spillman-autocomplete';

    /**
     * The handle used to identify the ion-scroll.
     */
    let SCROLLER_HANDLE = `${DIRECTIVE_NAME}-results-scroller`;

    /**
     * The template for this directive.
     *
     * NOTE: the input on which this directive is applied will be removed from the DOM
     *       and then re-inserted inside the outermost "wrapper" div.
     */
    let TEMPLATE = `<div class="${DIRECTIVE_NAME}-wrapper">
                      <div class="${DIRECTIVE_NAME}-popup" ng-show="showPopup">
                        <div ng-show="message" class="${DIRECTIVE_NAME}-message">{{message}}</div>

                        <ion-scroll delegate-handle="${SCROLLER_HANDLE}" ng-hide="message" direction="y" locking="true" scrollbar-x="false" scrollbar-y="true" zooming="false" has-bouncing="true">
                          <ion-list>
                            <ion-item ng-repeat="item in results" ng-click="select(item.value)" ng-bind-html="item.label"></ion-item>
                          </ion-list>
                        </ion-scroll>
                      </div>
                    </div>`;

    /**
     * The default options that will be used if not overridden.
     *
     * These can be overridden by using the `spillman-autocomplete-options` attribute.
     * For example:
     *     spillman-autocomplete-options="{debounceTime: 500, errorMessage="Oh no!", labelFormat: '%s - %s'}"
     */
    let defaultOptions: AutocompleteOptions = {
        debounceTime: 300,
        doSearchWithBlankText: false,
        noResultsMessage: 'No results',
        errorMessage: 'Error: unable to get results from server',
        labelFormat: '%s - %s',
        labelFields: ['abbr', 'desc'],
        valueFormat: '%s',
        valueFields: ['abbr']
    };

    /**
     * Initializes the original input on which this directive was applied by inserting it inside the wrapper div.
     *
     * @param wrapper The element that will contain the input.
     * @param transclude The transclude function that was passed to the link function.
     * @param scope The scope which should be used when inserting the input into the DOM.
     * @returns {JQuery} A reference to the input.
     */
    function initializeInput(wrapper: JQuery, transclude: ITranscludeFunction, scope: IScope): JQuery {
        let input: JQuery = undefined;
        transclude(scope, (clone: JQuery) => {
            wrapper.prepend(input = clone);
        });
        return input;
    }

    /**
     * Initializes the popup that will show the results from the server.
     *
     * @param wrapper The wrapper element that contains the popup.
     * @param input The original input on which this directive was applied.
     * @returns {JQuery} A reference to the popup.
     */
    function initializePopup(wrapper: JQuery, input: JQuery): JQuery {
        let popup = wrapper.find('.spillman-autocomplete-popup');
        let inputPosition = input.position();

        popup.css({
            width: input.innerWidth() + 'px',
            top: (inputPosition.top + input.height()) + 'px',
            left: inputPosition.left + 'px',
            display: 'block'
        });
        return popup;
    }

    /**
     * Creates a debounced event handler. When executed, the handler will make a request to the server to get the results
     * that match the text that was entered by the user into the input element.
     *
     * @param input The input whose text will be used to find matching results from the server.
     * @param table The name of the database table to search.
     * @param scope The scope to update with the results.
     * @param scrollDelegate The delegate that controls the ion-scroll directive inside the popup.
     * @param options The options that allow for customization of the autocomplete directive.
     * @returns {CancellableFunction} A debounced event handler that can be cancelled when necessary.
     */
    function createDebouncedInputEventHandler(input: JQuery, table: string, scope: AutocompleteScope, scrollDelegate: IonicScrollDelegate, options: AutocompleteOptions): CancellableFunction {
        // The lodash `debounce` function is used so that we only go to the server after the user
        // has stopped typing for as long as specified by the `debounceTime` option.
        return <CancellableFunction>debounce(() => {
            let value = input.val();

            // Only go to the server if either the `doSearchWithBlankText` flag is true
            // or there are non-whitespace characters.
            if (typeof value === 'string' && (options.doSearchWithBlankText || (value && value.trim()))) {
                retrieveResults(value, table, options)
                    .then(results => {
                        scope.results = results;
                        scope.message = (results.length > 0) ? undefined : options.noResultsMessage;

                        // Make sure the ion-scroll is at the top, since it won't automatically go to the top
                        // if there are more or less results than last time.
                        scrollDelegate.scrollTop();
                        scope.showPopup = true;
                    })
                    .catch(() => {
                        scope.results = [];
                        scope.message = options.errorMessage;
                        scope.showPopup = true;
                    });
            } else {
                // We're outside of Angular's digest cycle, so we need to wrap this is scope.$apply.
                scope.$apply(() => {
                    scope.showPopup = false;
                    scope.results = [];
                });
            }
        }, options.debounceTime);
    }

    /**
     * Retrieves the results from the server.
     *
     * @param text The text that was entered by the user.
     * @param table The name of the database table.
     * @param options The options that allow for customization of the autocomplete directive.
     * @returns {IPromise<AutocompleteItem[]>} A promise that when resolved will provide the results from the server.
     */
    function retrieveResults(text: string, table: string, options: AutocompleteOptions): IPromise<AutocompleteItem[]> {
        // This directive gets initialized before Angular 2 has been bootstrapped.
        // Therefore, we cannot inject the UrlFactory, which is an Angular 2 service, in the constructor.
        const urlFactory = $injector.get<UrlFactory>('urlFactory');

        let url = urlFactory.create({
            path: `/../cache/${table}`
        });
        let config = {
            params: {
                text: text,
                format: options.labelFormat
            }
        };

        return $http.get<any>(url, config)
            .then(response => {
                return response.data.map((item: any) => {
                    return {
                        label: vsprintf(options.labelFormat, options.labelFields.map(f => item[f])),
                        value: vsprintf(options.valueFormat, options.valueFields.map(f => item[f]))
                    };
                });
            });
    }

    return {
        restrict: 'A',

        // This means to remove the entire element from the DOM. It will be reinserted by the `transclude` function below.
        transclude: 'element',

        // 1000 was chosen because that is the same priority as the ng-repeat directive, which also transcludes the entire element.
        priority: 1000,
        scope: {
            spillmanAutocomplete: '@',
            spillmanAutocompleteOptions: '='
        },
        link(scope: AutocompleteScope, element: IAugmentedJQuery, attributes: IAttributes, _controller: Object, transclude: ITranscludeFunction) {
            // Compile the template and add it to the DOM.
            let wrapper = $compile(TEMPLATE)(scope);
            element.parent().append(wrapper);

            // Use the parent scope for the input, which is the scope it came from.
            // This is important so that it still has a reference to the same ng-model as before.
            let parentScope = scope.$parent;
            let input = initializeInput(wrapper, transclude, parentScope);

            // Check that the input is actually an input of type='text'.
            if (!input.is('input:text')) {
                throw Error(`The ${DIRECTIVE_NAME} directive can only be applied to an input of type text.`);
            }

            initializePopup(wrapper, input);

            // Here, we are getting a reference to the model to which the input is bound.
            let model = $parse(attributes[NG_MODEL]);

            // The value of the `spillman-autocomplete` attribute should be a table from which to retrieve values.
            let table = scope.spillmanAutocomplete;

            // Create the options for this instance. Any user-defined options will override the defaults.
            let options = <AutocompleteOptions>angular.extend({}, defaultOptions, scope.spillmanAutocompleteOptions);

            let scrollDelegate = $ionicScrollDelegate.$getByHandle(SCROLLER_HANDLE);
            let inputEventHandler: CancellableFunction = undefined;

            input
                .on('focus', () => {
                    if (!inputEventHandler) {
                        // When the input is focused, create the event handler.
                        inputEventHandler = createDebouncedInputEventHandler(input, table, scope, scrollDelegate, options);
                        input.on('input', inputEventHandler);

                        // Invoke the function immediately so that if the input already has text, then the matching items will be shown.
                        inputEventHandler();
                    }
                })
                .on('blur', () => {
                    // When the input loses focus, destroy the event handler.
                    if (inputEventHandler) {
                        input.off('input', inputEventHandler);
                        inputEventHandler.cancel(); // Since the handler is debounced, it needs to be cancelled.
                        inputEventHandler = undefined;

                        // We're outside of Angular's digest cycle, so we need to wrap this is scope.$apply.
                        scope.$applyAsync(() => {
                            scope.showPopup = false;
                            scope.results = [];
                        });
                    }
                });

            scope.select = (value: string) => {
                scope.showPopup = false;
                scope.results = [];

                // Remove the handler so that the user doesn't immediately get the popup again after having just selected an item.
                input.off('input', inputEventHandler);

                // Assigning the value to the model (as is done below) should update the input's value. So this line of code should be unnecessary.
                // However, I've found that if the input's current value differs from the new value by only whitespace, then it won't be updated.
                input.val(value);

                // Assign the value to the model.
                model.assign(parentScope, value);

                scope.$emit(autocompleteValueSelectedEvent, value);

                // Replace the handler, but inside of a timeout so that it won't happen until after the next digest cycle (after the model has been updated).
                $timeout(() => input.on('input', inputEventHandler), 0, false);
            };
        }
    };
}

/**
 * An interface that defines an item that is shown in the autocomplete dropdown.
 */
interface AutocompleteItem {

    /**
     * The text that is displayed in the dropdown.
     */
    label: string;

    /**
     * The value that will be used to populate the input if this item is selected.
     */
    value: string;
}

/**
 * An interface that defines the scope for the Autocomplete directive.
 */
interface AutocompleteScope extends IScope {

    /**
     * This is the actual attribute that causes this directive to be applied.
     * It also serves the dual purpose of providing the name of the database table
     * from which to retrieve the items.
     */
    spillmanAutocomplete: string;

    /**
     * The options that allow for customization of the Autocomplete directive.
     */
    spillmanAutocompleteOptions: AutocompleteOptions;

    /**
     * A flag that indicates if the popup is shown or not.
     */
    showPopup: boolean;

    /**
     * A message to display instead of displaying the results.
     */
    message: string;

    /**
     * An array of items from which the user can select.
     */
    results: AutocompleteItem[];

    /**
     * Applies the given value to the input and closes the popup.
     *
     * @param value The value to apply to the input.
     */
    select: (value: string) => void;
}

/**
 * An interface that defines the set of configurable options for the Autocomplete directive.
 */
interface AutocompleteOptions {

    /**
     * The amount of time to delay going to the server for results after the text has changed.
     * This helps to limit the amount of network traffic.
     */
    debounceTime?: number;

    /**
     * A flag that specifies whether a search should be made even if the text is blank.
     */
    doSearchWithBlankText?: boolean;

    /**
     * The message that will be shown if there are no results returned by the server.
     */
    noResultsMessage?: string;

    /**
     * The message that will be shown if there is an error in communicating with the server.
     */
    errorMessage?: string;

    /**
     * The sprintf style format string that specifies how the label fields are to be displayed.
     * Example: '%s - %s'
     */
    labelFormat?: string;

    /**
     * The array of field names that should be retrieved from each item returned by the server,
     * formatted using the `labelFormat`, and then used as the label displayed in the dropdown.
     */
    labelFields?: string[];

    /**
     * The sprintf style format string that specifies how the value fields are to be displayed.
     * Example: '%s - %s'
     */
    valueFormat?: string;

    /**
     * The array of field names that should be retrieved from each item returned by the server,
     * formatted using the `valueFormat`, and then used as the value of the text box.
     */
    valueFields?: string[];
}
