/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IResourceClass = angular.resource.IResourceClass;
import IResourceArray = angular.resource.IResourceArray;
import IHttpService = angular.IHttpService;
import Name from '../../schema/Name';
import OtherTelephone from '../../schema/OtherTelephone';
import ResourceUtils from '../../resource/ResourceUtils';
import MultiQueryResourceDecorator from '../../resource/multiQuery/MultiQueryResourceDecorator';
import MultiQueryConfig from '../../resource/multiQuery/MultiQueryConfig';
import MultiQueryResourceClass from '../../resource/multiQuery/MultiQueryResourceClass';
import DecoratorToResponseTransformAdapter from '../../shared/transforms/DecoratorToResponseTransformAdapter';
import NameQueryTransform from './enums/NameQueryTransform';
import NameGetTransform from './enums/NameGetTransform';
import NameGetAlertsTransform from './enums/NameGetAlertsTransform';
import ComputedUrlResource from '../../resource/computedUrl/ComputedUrlResource';
import IPromise = angular.IPromise;
import IHttpResponseTransformer = angular.IHttpResponseTransformer;
import uniqBy = require('lodash/uniqBy');

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
NameAPI.$inject = [
    'computedUrlResource',
    'multiQueryResourceDecorator',
    '$http',
    'getFirstElementTransform',
    'resourceUtils',
    'otherTelephoneAPI',
    'decoratorToResponseTransformAdapter',
    'defaultPageSize'
];

/**
 * A factory function that creates an API for retrieving Names from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @param multiQueryResourceDecorator A function that converts a standard resource into a multi-query resource.
 * @param $http The Angular service that makes http requests.
 * @param getFirstElementTransform A response transform that returns only the first element from the array.
 * @param resourceUtils Provides utility methods for working with the Angular $resource service.
 * @param otherTelephoneAPI The API for retrieving other telephones from the server.
 * @param decoratorToResponseTransformAdapter An adapter that creates a response transform that loops through an array of decorator transforms.
 * @param defaultPageSize The default page size used when displaying a list of records.
 * @returns {MultiQueryResourceClass<Name>} A resource class that knows how to load Names from the REST server.
 */
function NameAPI(
    computedUrlResource: ComputedUrlResource,
    multiQueryResourceDecorator: MultiQueryResourceDecorator,
    $http: IHttpService,
    getFirstElementTransform: IHttpResponseTransformer,
    resourceUtils: ResourceUtils,
    otherTelephoneAPI: IResourceClass<OtherTelephone>,
    decoratorToResponseTransformAdapter: DecoratorToResponseTransformAdapter<Name>,
    defaultPageSize: number
): MultiQueryResourceClass<Name> {
    let unDecoratedAPI = computedUrlResource<Name>({ path: '/tables/nmmain/:id' }, {}, {
        get: {
            method: 'GET',
            params: {
                expand: 'nmadadrs.aatype,nminter.iatype,nmalert.code,nmmodop.factor,nmmodop.method,hair,eyes,race,glasses,hairsty,facial,complx,speech,teeth,build,ethnic,nametyp,somain.risklvl,nmcrtnot.cntype,nmsmt.type,nmsmt.bodp,nmsmt.item,nminter.ptype',
                include: 'nmadadrs,nmalert,nminter,nmsmt,nmmodop,nmmdesc,somain,nmcrtnot'
            },
            transformResponse: angular.appendTransform($http.defaults.transformResponse, getFirstElementTransform, decoratorToResponseTransformAdapter.createTransform(NameGetTransform))
        },
        query: {
            method: 'GET',
            isArray: true,
            params: {
                include: 'somain,nmcrtnot',
                sort: 'last,first',
                pageSize: defaultPageSize
            },
            transformResponse: angular.appendTransform($http.defaults.transformResponse, decoratorToResponseTransformAdapter.createTransform(NameQueryTransform))
        },
        getAlerts: {
            method: 'GET',
            params: {
                fields: 'number',
                include: 'somain,nmcrtnot'
            },
            transformResponse: angular.appendTransform($http.defaults.transformResponse, getFirstElementTransform, decoratorToResponseTransformAdapter.createTransform(NameGetAlertsTransform))
        }
    });

    let multiQueryConfig: MultiQueryConfig<Name> = {
        specialParameterName: 'magicPhoneNumber',
        queries: [
            queryNamesUsingPhone,
            queryNamesUsingWorkPhone,
            queryNamesViaOtherTelephones
        ],
        transform: names => uniqBy(names, 'number'),
        pageSize: defaultPageSize
    };

    let nameAPI = multiQueryResourceDecorator(unDecoratedAPI, multiQueryConfig);

    /**
     * Searches for Names by directly querying the nmmain table using the phone filter
     * in addition to the other search params.
     *
     * @param params The search params, other than the phone, entered by the user.
     * @param phoneNumber The phone number entered by the user.
     * @returns {IResourceArray<Name>} The unresolved resource array of names.
     */
    function queryNamesUsingPhone(params: any, phoneNumber: string): IResourceArray<Name> {
        let phoneFilter = 'phone=' + phoneNumber;
        let searchParams = resourceUtils.addFilter(params, phoneFilter);
        return nameAPI.query(searchParams);
    }

    /**
     * Searches for Names by directly querying the nmmain table using a filter on the work phone
     * in addition to the other search params.
     *
     * @param params The search params, other than the phone, entered by the user.
     * @param phoneNumber The phone number entered by the user. It will be used to filter by work phone.
     * @returns {IResourceArray<Name>} The unresolved resource array of names.
     */
    function queryNamesUsingWorkPhone(params: any, phoneNumber: string): IResourceArray<Name> {
        let workPhoneFilter = 'wrkphn=' + phoneNumber;
        let searchParams = resourceUtils.addFilter(params, workPhoneFilter);
        return nameAPI.query(searchParams);
    }

    /**
     * Searches for names via a complex algorithm that involves looking at the nmotel table.
     * The steps are as follows:
     *   1. Search the nmotel table using the provided phone filter
     *   2. Collect all of the returned `num` field values (which reference nmmain.number)
     *   3. Create a new filter that uses those numbers
     *   4. Now search the nmmain table using the number filter in addition to the other search params
     *
     * @param params The search params, other than the phone, entered by the user.
     * @param phoneNumber The phone number entered by the user.
     * @returns {IResourceArray<Name>} The unresolved resource array of names.
     */
    function queryNamesViaOtherTelephones(params: any, phoneNumber: string): IResourceArray<Name> {
        let phoneFilter = 'phone=' + phoneNumber;

        return resourceUtils.createResourceArray(<Name[] | IPromise<Name[]>>otherTelephoneAPI.query({ filter: phoneFilter }).$promise
            .then<Name[] | IPromise<Name[]>>(otherPhones => {
            // If there are no other phones, then we can avoid doing the second query and return an empty array instead.
            if (otherPhones.length < 1) {
                return [];
            }

            // Collect the numbers into a comma-separated string.
            let csvNameIds = otherPhones.map(p => p.num).join(',');

            // Add a new filter to the params.
            let searchParams = resourceUtils.addFilter(params, 'number=' + csvNameIds);

            // Search names and return the promise.
            return nameAPI.query(searchParams).$promise;
        }));
    }

    return nameAPI;
}

export default NameAPI;
