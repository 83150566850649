/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import PermissionsRepository from './PermissionsRepository';
import { upgradeAdapter } from '../upgrade-adapter';
import { PermissionsLinkComponent } from './permissions-link/permissions-link.component';

export const permissionsModule = angular.module('permissions', [])
    .service('permissionsRepository', PermissionsRepository)
    .directive('sdsPermissionsLink', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(PermissionsLinkComponent));

upgradeAdapter.upgradeNg1Provider('permissionsRepository');
