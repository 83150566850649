/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Inject, OnInit } from '@angular/core';
import { BaseSearchController } from '../../../searching/BaseSearchController';
import { IncidentType } from '../incident-type';
import Option from '../../../settings/Option';
import { PermissionsRepository } from '../../../permissions/PermissionsRepository';
import { IncidentSearchMetadata, incidentSearchMetadataMap } from './incident-search-metadata';
import { DurationInputObject } from 'moment';

const dispositionParameterName = 'dispos';

/**
 * The component that allows the user to search for incidents in the database.
 */
@Component({
    selector: 'sds-incident-search',
    template: require('./incident-search.component.html')
})
export class IncidentSearchComponent extends BaseSearchController implements OnInit {

    /**
     * Adding the IncidentType enum as a public property of this component
     * so that it's available within the html template.
     */
    public IncidentType = IncidentType; // eslint-disable-line @typescript-eslint/naming-convention,no-underscore-dangle,id-denylist,id-match

    /**
     * An array of incident metadata for all incidents to which the user has permission to view.
     */
    public permittedIncidents: IncidentSearchMetadata[];

    /**
     * The private backing property for the activeIncidentType getter/setter.
     */
    private _activeIncidentType: IncidentType;

    /**
     * Constructs a new instance of the IncidentSearchComponent class.
     *
     * @param $state The service that transitions between states.
     * @param serverDateTimeFormat The constant that defines the format in which both a date and time are sent by the server.
     * @param standardDateOptions The standard options from which the user can select when performing a search on a date field.
     * @param permissionsRepository The repository which stores permissions of a user.
     */
    constructor(
    @Inject('$state') $state: angular.ui.IStateService,
        @Inject('serverDateTimeFormat') serverDateTimeFormat: string,
        @Inject('standardDateOptions') public standardDateOptions: Option<DurationInputObject>[],
        @Inject('permissionsRepository') private permissionsRepository: PermissionsRepository
    ) {
        super($state, serverDateTimeFormat);
    }

    /**
     * @inheritdoc
     */
    public ngOnInit(): void {
        this.permittedIncidents = [...incidentSearchMetadataMap.values()].filter(i => this.permissionsRepository.processPermissionsWithDefault(i.permission));
        this.activeIncidentType = this.permittedIncidents[0].type;
    }

    /**
     * Gets the active incident type that was selected by the user.
     */
    public get activeIncidentType(): IncidentType {
        return this._activeIncidentType;
    }

    /**
     * Sets the active incident type.
     */
    public set activeIncidentType(activeIncidentType: IncidentType) {
        if (activeIncidentType !== this._activeIncidentType) {
            this._activeIncidentType = activeIncidentType;

            // We need to clear out the previous disposition codes, because
            // each type of incident has it's own separate disposition table.
            // So the previous disposition code would be invalid.
            this.searchParams[dispositionParameterName] = undefined;
        }
    }

    /**
     * Gets metadata about the active incident type.
     */
    public get activeIncidentMetadata(): IncidentSearchMetadata {
        return incidentSearchMetadataMap.get(this.activeIncidentType);
    }

    /**
     * @inheritdoc
     */
    protected get listStateName(): string {
        return this.activeIncidentMetadata.listState;
    }
}
