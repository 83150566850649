/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import CustomFilterByTypes from '../../../custom-filters/CustomFilterByTypes';

import TypeScope from './TypeScope';
import TypeItem from './TypeItem';
import { IRootScopeService } from 'angular';
import Session from '../../../../../login/Session';
import { FilterActions } from '../../../consts';
import { UNSELECT_ALL, SELECT_ALL } from './strings';

/**
 * The controller for the TypeComponentController.
 */
export default abstract class TypeComponentController {
    public selectedAll: boolean = undefined;
    public userType: string;

    constructor(
        $rootScope: IRootScopeService,
        $scope: TypeScope,
        customFilterByTypes: CustomFilterByTypes,
        session: Session,
        actions: FilterActions,
    ) {
        this.userType = session.data.unitType;

        $scope.items = [
            new TypeItem('Law', 'l', false, false),
            new TypeItem('EMS', 'e', false, false),
            new TypeItem('Fire', 'f', false, false),
            new TypeItem('Misc', 'm', false, false)
        ];

        $scope.$watch(() => {
            return this.selectedAll;
        }, (newVal: boolean, oldVal: boolean) => {
            if (newVal === undefined && oldVal === undefined) {
                return;
            }
            if (newVal) {
                $scope.items = $scope.items.map((item: TypeItem) => {
                    item.checked = true;
                    return item;
                });
            } else {
                $scope.items.map((item: TypeItem) => {
                    item.checked = false;
                    return item;
                });
            }
        }, true);

        const preSelectedValues = customFilterByTypes.getEditingValues();

        if (preSelectedValues && preSelectedValues.length > 0) {
            $scope.items = $scope.items.map((item: TypeItem) => {
                item.checked = preSelectedValues.find((subItem: FilterModel) => subItem.id === item.value && subItem.applied) !== undefined;
                item.applied = item.checked;
                return item;
            });
        }

        $scope.isDisabled = false;

        $scope.$watch(() => {
            return $scope.items;
        }, (newVal: any[], oldVal: any[]) => {
            customFilterByTypes.setValues(newVal);
            if ($scope.items.every((item: TypeItem) => item.checked === true)) {
                this.selectedAll = true;
            }
        }, true);

        $rootScope.$on(actions.CLEAN_FIELDS, () => {
            for (let item of $scope.items) {
                item.checked = false;
            }
            this.selectedAll = false;
        });

        $rootScope.$on(actions.DISABLE_FIELDS, () => {
            $scope.isDisabled = true;
        });

        $rootScope.$on(actions.ENABLE_FIELDS, () => {
            $scope.isDisabled = false;
        });
    }

    public getSelectLabelTitle(): string {
        return this.selectedAll ? UNSELECT_ALL : SELECT_ALL;
    }

    public $onInit = () => {};
}

