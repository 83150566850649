/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../../shared/database/decorators';

/**
 * A Modus Operandi (M.O.).
 */
@table('lwmodop')
export class ModusOperandi {

    @expand('factor', 'desc')
    public factor: string;

    @expand('method', 'desc')
    public method: string;
}
