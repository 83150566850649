/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ILogService = angular.ILogService;
import BaseJoinedTablesDecoratorTransform from '../../../shared/transforms/BaseJoinedTablesDecoratorTransform';
import JoinedTableMap from '../../../shared/transforms/JoinedTableMap';
import Name from '../../../schema/Name';

/**
 * A DecoratorTransform that will add a new property to the Name for each joined table.
 */
export default class NameJoinedTablesDecoratorTransform extends BaseJoinedTablesDecoratorTransform<Name> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$log'];

    private static JOINED_TABLE_MAP: JoinedTableMap = {
        nmcrtnot: {
            propertyName: 'criticalNotices'
        },
        somain: {
            propertyName: 'sexOffenders'
        },
        nmadadrs: {
            propertyName: 'nameAddresses'
        },
        nmalert: {
            propertyName: 'nameAlerts'
        },
        nminter: {
            propertyName: 'internets'
        },
        nmsmt: {
            propertyName: 'scarMarkTattoos'
        },
        nmmodop: {
            propertyName: 'MOs'
        },
        nmmdesc: {
            propertyName: 'nameComment',
            isOneToOne: true
        }
    };

    /**
     * Constructs a new instance of the NameJoinedTablesDecoratorTransform class.
     *
     * @param $log The Angular service that performs logging.
     */
    constructor($log: ILogService) {
        super($log);
    }

    // @Override
    protected get joinedTableMap(): JoinedTableMap {
        return NameJoinedTablesDecoratorTransform.JOINED_TABLE_MAP;
    }
}
