/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ILogService = angular.ILogService;
import BaseJoinedTablesDecoratorTransform from '../../../shared/transforms/BaseJoinedTablesDecoratorTransform';
import JoinedTableMap from '../../../shared/transforms/JoinedTableMap';
import Property from '../../../schema/Property';

/**
 * A DecoratorTransform that will add a new property to the Property for each joined table.
 */
export default class PropertyJoinedTablesDecoratorTransform extends BaseJoinedTablesDecoratorTransform<Property> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$log'];

    private static JOINED_TABLE_MAP: JoinedTableMap = {
        prlocal: {
            propertyName: 'localIdentifications'
        },
        prmdesc: {
            propertyName: 'propertyComment',
            isOneToOne: true
        }
    };

    /**
     * Constructs a new instance of the PropertyJoinedTablesDecoratorTransform class.
     *
     * @param $log The Angular service that performs logging.
     */
    constructor($log: ILogService) {
        super($log);
    }

    // @Override
    protected get joinedTableMap(): JoinedTableMap {
        return PropertyJoinedTablesDecoratorTransform.JOINED_TABLE_MAP;
    }
}
