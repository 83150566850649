/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../../../shared/database/decorators';
import { CadCallReference, NameReference } from '../../../../shared/model';
import { Involvement } from '../../../../../schema/Involvement';
import { Circumstance } from './circumstance';
import { Condition } from './condition';
import { Patient } from './patient';
import { Responder } from './responder';
import { Supplement } from './supplement';

/**
 * The ems incident model
 */
@table('emmain')
export class EmsIncident {

    @field('number')
    public id: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @includeMany('emccirc', Circumstance)
    public circumstances: Circumstance[];

    @includeMany('emcond', Condition)
    public conditions: Condition[];

    @includeMany('empatien', Patient)
    public patients: Patient[];

    @includeMany('emrespo', Responder)
    public responders: Responder[];

    @includeMany('emsupl', Supplement)
    public supplements: Supplement[];

    @field('agency')
    public agency: string;

    @expand('locatn', 'desc')
    public area: string;

    @expand('callid', CadCallReference)
    public cadCall: CadCallReference | string;

    @expand('nameid', NameReference)
    public caller: NameReference | string;

    @includeOne('emnarr', 'narratv')
    public comments: string;

    @field('contact')
    public contact: string;

    @expand('dispos', 'desc')
    public disposition: string;

    @field('dispdat')
    public dispositionDate: Date;

    @expand('howrc', 'desc')
    public howReceived: string;

    @field('misc')
    public miscellaneousEntry: string;

    @expand('nature', 'desc')
    public nature: string;

    @expand('condobs', 'desc')
    public observedCondition: string;

    @field('ocurdt1')
    public occurredFrom: Date;

    @field('ocurdt2')
    public occurredTo: Date;

    @expand('rcvby', 'fullnam')
    public receivedBy: string;

    @expand('condtkn', 'desc')
    public reported: string;

    @expand('respoff', 'fullnam')
    public responsibleOfficer: string;

    @field('dtrepor')
    public whenReported: Date;
}
