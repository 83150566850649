/* Copyright © 2021 Motorola Solutions, Inc. All rights reserved. */

import OtherPageScope from './OtherPageScope';

declare const cordova: any;
export const MSI_PRIVACY_POLICY_PAGE_LINK = 'https://www.motorolasolutions.com/en_xp/about/privacy-policy.html#privacystatement';

/**
 * The controller for the Other page.
 */
export default class OtherPageController {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */

    public static $inject = ['$scope'];

    /** *
     * @param $scope The Angular scope object that provides data to the view.
     */
    constructor(protected $scope: OtherPageScope) {
        $scope.openPrivacyPolicy = () => {
            if (typeof cordova !== 'undefined') {
                cordova.InAppBrowser.open(MSI_PRIVACY_POLICY_PAGE_LINK, '_blank', 'location=yes,toolbarposition=top');
                return;
            }

            const newWindow = window.open();
            newWindow.location.assign(MSI_PRIVACY_POLICY_PAGE_LINK);
        };
    }

    public $onInit = () => {};
}
