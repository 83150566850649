/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../../../shared/database/database.service';
import { DetailsStateParams } from '../../../../shared/interfaces/DetailsStateParams';
import { EmsIncident, Responder } from './model';
import { SlideSourceInfo } from '../../../../schema/SlideSourceInfo';
import { PatientAssessmentsBatchRequest } from './data/patient-assessments-batch-request.service';
import { PatientDrugsBatchRequest } from './data/patient-drugs-batch-request.service';
import { PatientTreatmentsBatchRequest } from './data/patient-treatments-batch-request.service';

/**
 * A component that displays the details of an ems incident.
 */
@Component({
    selector: 'sds-ems-incident-detail',
    template: require('./ems-incident-detail.component.html')
})
export class EmsIncidentDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        ['Narrative', { srcHtml: 'app/rms/shared/slides/comments.html' }],
        ['Supplements', { srcHtml: 'app/rms/shared/slides/supplements.html' }],
        ['Patients', { srcHtml: 'app/rms/incident/ems/detail/slides/patients.html' }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The ems incident model to display.
     */
    public emsIncident: EmsIncident;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the EmsIncidentDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param involvementsSlideSourceInfo inject the involvement slide from a value.
     * @param assessmentsBatchRequest The service that retrieves the assessments for all ems incident patients.
     * @param drugsBatchRequest The service that retrieves the drugs administered for all ems incident patients.
     * @param treatmentsBatchRequest The service that retrieves the treatments for all ems incident patients.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        private assessmentsBatchRequest: PatientAssessmentsBatchRequest,
        private drugsBatchRequest: PatientDrugsBatchRequest,
        private treatmentsBatchRequest: PatientTreatmentsBatchRequest
    ) {
    }

    /**
     * Format functions needed to display a list of items correctly inside of a popup.
     */
    public singleItemFormatter = (model: any) => model.description;
    public responderFormatter = (responder: Responder) => responder.name;

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(EmsIncident, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => {
                this.assessmentsBatchRequest.query(detailModel.patients);
                this.drugsBatchRequest.query(detailModel.patients);
                this.treatmentsBatchRequest.query(detailModel.patients);
                this.emsIncident = detailModel;
            }, () => this.onError.emit());
    }
}
