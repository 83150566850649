/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { MODEL_CREATION_PROVIDERS } from './model-creation/model-creation-providers';
import { NODE_PROVIDERS } from './nodes/node-providers';
import { REQUEST_CREATION_PROVIDERS } from './request-creation/request-creation-providers';

/**
 * An array of all providers in the "model-creation" folder.
 */
export const MODEL_TRAVERSAL_PROVIDERS = Array.prototype.concat.call(
    [],
    MODEL_CREATION_PROVIDERS,
    NODE_PROVIDERS,
    REQUEST_CREATION_PROVIDERS
);
