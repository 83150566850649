/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { PhoneIdentifier } from '../../phoneValidator';

/**
 * Text part is used to split a text using phone numbers, and each part of the string could be or a normal text or a phone number
 */
export default class TextParts {
    constructor(private text: string) {
        this._isNumber = undefined;
    }

    private _isNumber: boolean;

    public get isNumber () {
        if (this._isNumber === undefined) {
            this._isNumber = PhoneIdentifier.isValidNumber(this.text);
        }
        return this._isNumber;
    };

    public toString = (): string => {
        return this.text;
    };

    public static convertFromStringToTextParts = (texts: string[]) => {
        let textParts: TextParts[] = [];

        if (!texts || texts.length === 0){
            return textParts;
        }

        for (let text of texts) {
            textParts.push(new TextParts(text));
        }

        return textParts;
    };
}
