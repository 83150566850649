/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../shared/shared.module';
import { AddRadioLogComponent } from './add-radio-log/add-radio-log.component';
import { MyUnitProvider } from './my-unit-provider.service';
import { StatusControlComponent } from './status-control/status-control.component';
import { StatusHistoryStorage } from './status-control/status-history-storage.service';
import { StatusService } from './status-control/status.service';

@NgModule({
    imports: [
        SharedModule,
        FormsModule
    ],
    declarations: [
        AddRadioLogComponent,
        StatusControlComponent
    ],
    providers: [
        MyUnitProvider,
        StatusHistoryStorage,
        StatusService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CadModule { }
