/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * The Animal model
 */
@table('lpanimal')
export class Animal {

    @field('number')
    public id: string;

    @field('weight')
    public weight: number;

    @field('value')
    public value: number;

    @field('type')
    public type: string;

    @field('name')
    public name: string;

    @field('breed')
    public breed: string;

    @expand('color1', 'desc')
    public color1: string;

    @expand('color2', 'desc')
    public color2: string;

    @field('gender')
    public gender: string;

    @field('comment')
    public comment: string;
}
