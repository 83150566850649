/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IPromise = angular.IPromise;
import IResourceArray = angular.resource.IResourceArray;
import ErrorHandlingController from '../../../errorHandling/ErrorHandlingController';
import Property from '../../../schema/Property';
import MultiQueryResourceClass from '../../../resource/multiQuery/MultiQueryResourceClass';
import PropertyListStateParams from './PropertyListStateParams';
import ApplicationScope from '../../../ApplicationScope';
import IonicLoadingService = ionic.loading.IonicLoadingService;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;

/**
 * The controller that displays a list of properties based on the search criteria entered by user.
 */
export default class PropertyListController extends ErrorHandlingController<IResourceArray<Property>> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$scope', '$ionicLoading', '$timeout', 'propertyAPI', '$stateParams', '$ionicScrollDelegate'];

    /**
     * The array of properties to display.
     */
    public properties: IResourceArray<Property>;

    /**
     * Constructs a new instance of the PropertyListController class.
     *
     * @param $scope The Angular scope object that can listen to and raise events.
     * @param $ionicLoading An overlay that displays a spinner while waiting for data to be loaded.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param propertyAPI The service that retrieves properties from the server.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     * @param scrollDelegate The delegate that controls the ion-scroll directive.
     */
    constructor($scope: ApplicationScope,
        $ionicLoading: IonicLoadingService,
        $timeout: angular.ITimeoutService,
        private propertyAPI: MultiQueryResourceClass<Property>,
        private $stateParams: PropertyListStateParams,
        private scrollDelegate: IonicScrollDelegate) {
        super($scope, $ionicLoading, $timeout);
    }

    public $onInit = () => {};

    // @Override
    protected performRequest(): IPromise<IResourceArray<Property>> {
        let filter = this.$stateParams.filter;
        let magicColor = this.$stateParams.color;
        return this.propertyAPI.multiQuery({magicColor: magicColor}, {filter: filter}).$promise;
    }

    // @Override
    protected requestComplete(properties: IResourceArray<Property>): void {
        this.scrollDelegate.scrollTop();
        this.properties = properties;
    }
}
