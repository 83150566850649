/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from '../../../shared/database/database.service';
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import { OffenderTrackingVehicle } from './model/offender-tracking-vehicle';
import { SlideSourceInfo } from '../../../schema/SlideSourceInfo';

/**
 * A component that displays the details of an offender tracking vehicle.
 */
@Component({
    selector: 'sds-offender-tracking-vehicle-detail',
    template: require('./offender-tracking-vehicle-detail.component.html')
})
export class OffenderTrackingVehicleDetailComponent implements OnInit {

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        this.commentsSlideSourceInfo,
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The offender tracking vehicle model to display.
     */
    public offenderTrackingVehicle: OffenderTrackingVehicle;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the OffenderTrackingVehicleDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param involvementsSlideSourceInfo The SlideSourceInfo for the involvements slide.
     * @param commentsSlideSourceInfo inject the comments slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(OffenderTrackingVehicle, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.offenderTrackingVehicle = detailModel);
    }
}
