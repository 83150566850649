/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IPromise = angular.IPromise;
import IonicLoadingService = ionic.loading.IonicLoadingService;
import ApplicationScope from '../ApplicationScope';
import IAngularEvent = angular.IAngularEvent;

/**
 * A base controller class that handles any errors that may occur when making API calls to get data.
 * It also sets a timeout so that the API calls won't run too long.
 */
abstract class ErrorHandlingController<T> {

    /**
     * A flag that indicates whether an error occurred or not when requesting data from the server.
     */
    public error: boolean;

    /**
     * Constructs a new instance of the ErrorHandlingController class.
     *
     * @param $scope The Angular scope object that can listen to and raise events.
     * @param $ionicLoading An overlay that displays a spinner while waiting for data to be loaded.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     */
    constructor(private $scope: ApplicationScope,
        private $ionicLoading: IonicLoadingService,
        private $timeout: angular.ITimeoutService) {

        $scope.$on('$ionicView.beforeEnter', (_event: IAngularEvent, viewData: any) => {

            // Don't re-request the data if the user hit the back button.
            // This allows the app to maintain the scroll position of lists.
            if (viewData.direction !== 'back') {
                this.requestData(true);
            } else {
                let allowDataRequest = false;
                // eslint-disable-next-line dot-notation,@typescript-eslint/dot-notation
                const currentController = _event.currentScope['vm'];
                // Re-request the data if moved to previous ionicHistory element after data update.
                try {
                    allowDataRequest = currentController &&
                    currentController.$state &&
                    currentController.$state.current.params.allowDataRequest;
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                }

                if (allowDataRequest) {
                    this.requestData(false);
                }
            }
        });
    }

    /**
     * Requests (or re-requests) the data that the controller should display.
     *
     * @param isInitialRequest A flag that indicates if this is the initial request as opposed to a refresh.
     */
    public requestData = (isInitialRequest: boolean = false): void => {
        if (isInitialRequest) {
            this.$ionicLoading.show({ template: '<ion-spinner></ion-spinner>' });
        }

        this.performRequest()
            .then(model => {
                this.error = false;
                this.requestComplete(model);
            })

            .catch(() => {
                this.error = true;
            })

            .finally(() => {
                if (isInitialRequest) {
                    // If you look at Ionic's source code, you'll see that the `$ionicLoading.show` method is asynchronous.
                    // In some cases, that means that the call to `$ionicLoading.hide` will happen before the spinner is shown.
                    // When that happens, then the spinner will spin indefinitely.
                    // We can fix it by wrapping the call to `hide` within a timeout.
                    this.$timeout(() => {
                        this.$ionicLoading.hide();
                    });
                } else {
                    this.$scope.$broadcast('scroll.refreshComplete');
                }
            });
    };

    /**
     * Performs the actual request and returns the promise.
     *
     * @returns {IPromise<T>} A promise that, when resolved, will return the model.
     */
    protected abstract performRequest(): IPromise<T>;

    /**
     * Called after the model has been successfully retrieved from the server.
     *
     * @param model The model that was retrieved from the server.
     */
    protected abstract requestComplete(model: T): void;
}

export default ErrorHandlingController;
