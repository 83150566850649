/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component } from '@angular/core';

/**
 * A component that displays the details of Spillman's Privacy Policy.
 */
@Component({
    selector: 'sds-privacy-policy',
    template: require('./privacy-policy.component.html'),
    styles: [
        require('./privacy-policy.component.scss')
    ]
})
export class PrivacyPolicyComponent {

    /**
     * Opens an URL address in external browser.
     *
     * @param linkUrl The url address.
     * @returns false Which disables the default behavior.
     */
    public openLink(link: string): boolean {
        window.open(link, '_system');
        return false;
    }
}
