/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * A person's birth place.
 */
@table('nmextra')
export class BirthPlace {

    @field('bcity')
    public city: string;

    @expand('bstate', 'name')
    public state: string;

    @expand('bcntry', 'desc')
    public country: string;
}
