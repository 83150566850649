/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import { Http, Response, RequestOptionsArgs } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { originalHttpToken } from './original-http-token';
import { ReAuthenticationService } from './re-authentication.service';

/**
 * The max time allowed for a GET request before timing out.
 */
export const maxGetRequestTimeout = 20 * 1000;

/**
 * A Service which wraps the Http service and allows logic to be performed before a request is made.
 */
@Injectable()
export class SpillmanHttp {

    /**
     * Creates a new instance of the SpillmanHttp.
     *
     * @param http The Angular service that makes http requests.
     * @param reAuthenticationService Handles re-authenticating the user with the server.
     */
    constructor(
        @Inject(originalHttpToken) private http: Http,
        private reAuthenticationService: ReAuthenticationService<any>
    ) {
    }

    /**
     * A wrapper around the Http 'get' method.
     *
     * @param url The url string.
     * @param options The request options.
     */
    public get(url: string, options?: RequestOptionsArgs): Observable<Response> {
        return this.handleRequest(() => this.http.get(url, options));
    }

    /**
     * A wrapper around the Http 'post' method.
     *
     * @param url The url string.
     * @param body The request body.
     * @param options The request options.
     */
    public post(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.handleRequest(() => this.http.post(url, body, options));
    }

    /**
     * A wrapper around the Http 'put' method.
     *
     * @param url The url string.
     * @param body The request body.
     * @param options The request options.
     */
    public put(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        return this.handleRequest(() => this.http.put(url, body, options));
    }

    /**
     * A wrapper around the Http 'delete' method.
     *
     * @param url The url string.
     * @param options The request options.
     */
    public delete(url: string, options: RequestOptionsArgs): Observable<Response> {
        return this.handleRequest(() => this.http.delete(url, options));
    }

    /**
     * A wrapper around the Http 'patch' method.
     *
     * @param url The url string.
     * @param body The request body.
     * @param options The request options.
     */
    public patch(url: string, body: any, options: RequestOptionsArgs): Observable<Response> {
        return this.handleRequest(() => this.http.patch(url, body, options));
    }

    /**
     * A wrapper around the Http 'head' method.
     *
     * @param url The url string.
     * @param options The request options.
     */
    public head(url: string, options: RequestOptionsArgs): Observable<Response> {
        return this.handleRequest(() => this.http.head(url, options));
    }

    /**
     * A wrapper around the Http 'options' method.
     *
     * @param url The url string.
     * @param options The request options.
     */
    public options(url: string, options: RequestOptionsArgs): Observable<Response> {
        return this.handleRequest(() => this.http.options(url, options));
    }

    /**
     * Performs the request and handles any response error logic.
     *
     * @param performRequest: The function that actually performs the request.
     */
    private handleRequest(performRequest: () => Observable<Response>): Observable<Response> {
        return performRequest()
            .catch(responseError => {
                // If there is an error status of 401 it should attempt to re-authenticate and then retry the request.
                if (responseError && responseError.status === 401) {
                    return this.reAuthenticationService.reAuthenticate()
                        .flatMap(() => performRequest());
                }
                return Observable.throw(responseError);
            });
    }
}
