/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { RequestOptions } from '@angular/http';

/**
 * The name of the authorization header.
 */
export const authorizationHeaderName = 'Authorization';

/**
 * A class which will set the default security headers for angular 1 and 2.
 */
@Injectable()
export class SetAuthenticationHeadersService {

    /**
     * Constructs a new instance of SetAuthenticationHeadersService.
     */
    constructor(
        private requestOptions: RequestOptions
    ) {
    }

    /**
     * Sets the default security headers for angular 1 and 2.
     *
     * @param token The security header value.
     */
    public setHeaders(token: string): void {
        this.requestOptions.headers.set(authorizationHeaderName, token);
    }
}
