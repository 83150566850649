/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyPolicyComponent } from './privacy-policy.component';

@NgModule({
    imports: [CommonModule],
    declarations: [PrivacyPolicyComponent]
})
export class PrivacyPolicyModule { }
