/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import { sharedModule } from '../shared/shared.module.ng1';
import PortDirective from './PortDirective';
import CredentialsStorage from './CredentialsStorage';
import { AuthenticationService } from './authentication.service';
import LoginController from './LoginController';
import LogoutController from './LogoutController';
import Session from './Session';
import UnitVerification from './verification/UnitVerification';
import RegeneratableContainer from './regeneratable/RegeneratableContainer';
import TimeoutVerification from './verification/TimeoutVerification';
import SymoduleVerificationFactory from './verification/SymoduleVerificationFactory';
import UserVerificationFactory from './verification/UserVerificationFactory';
import { upgradeAdapter } from '../upgrade-adapter';
import { LogoutService, ReAuthenticationService, SetAuthenticationHeadersService, UrlFactory, CredentialsRepository } from '../authentication';
import { SessionTimeout } from './session-timeout';
import { PasswordExpirationService } from '../authentication/password-expiration.service';
import { ApiPasswordExpirationService } from '../authentication/api-password-expiration.service';
import { PushNotificationService } from './push-notification/push-notification.service';
import { PushNotificationApi } from './push-notification/push-notification-api';
import { CustomNotificationBuilder } from '../notifications/custom-notification-builder/custom-notification-builder';

export const loginModule = angular.module('login', [
    'ionic',
    'ngMessages',
    sharedModule.name
])
    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('login', {
                url: '/login',
                template: require('./login.html'),
                controller: 'loginController',
                data: {
                    allowAnonymousAccess: true
                },
                cache: false
            })
            .state('logout', {
                url: '/logout/:message',
                template: '',
                controller: 'logoutController',
                cache: false
            });
    }])
    // Controllers
    .controller('loginController', LoginController)
    .controller('logoutController', LogoutController)

    // Directives
    .directive('port', PortDirective)

    // Factories
    .factory('userVerification', UserVerificationFactory)
    .factory('symoduleVerification', SymoduleVerificationFactory)
    .factory('logoutService', upgradeAdapter.downgradeNg2Provider(LogoutService))
    .factory('reAuthenticationService', upgradeAdapter.downgradeNg2Provider(ReAuthenticationService))
    .factory('setAuthenticationHeadersService', upgradeAdapter.downgradeNg2Provider(SetAuthenticationHeadersService))
    .factory('urlFactory', upgradeAdapter.downgradeNg2Provider(UrlFactory))
    .factory('credentialsRepository', upgradeAdapter.downgradeNg2Provider(CredentialsRepository))
    .factory('sessionTimeout', upgradeAdapter.downgradeNg2Provider(SessionTimeout))
    .factory('passwordExpirationService', upgradeAdapter.downgradeNg2Provider(PasswordExpirationService))
    .factory('apiPasswordExpirationService', upgradeAdapter.downgradeNg2Provider(ApiPasswordExpirationService))

    // Services
    .service('pushNotificationService', PushNotificationService)
    .service('pushNotificationApi', PushNotificationApi)
    .service('customNotificationBuilder', CustomNotificationBuilder)
    .service('authenticationService', AuthenticationService)
    .service('credentialsStorage', CredentialsStorage)
    .service('session', Session)
    .service('unitVerification', UnitVerification)
    .service('timeoutVerification', TimeoutVerification)
    .service('regeneratableContainer', RegeneratableContainer);

upgradeAdapter.upgradeNg1Provider('session');
