/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Type } from '@angular/core';
import { GeneralTreeNodeVisitor, TypeSpecificTreeNodeVisitor } from './tree-node-visitor';
import { TreeNode } from './tree-node';

/**
 * A general base class that visits all nodes in the tree by delegating to a more specific
 * visitor that is tailored to the type of node being visited.
 *
 * This is an adaptation of the visitor pattern: https://en.wikipedia.org/wiki/Visitor_pattern#Java_example
 * Unlike Java, however, in JavaScript (and TypeScript) we cannot have overloaded methods with compile-time binding.
 * Therefore, instead of having an overloaded `visit` method, we have a map of type-specific visitors.
 */
export abstract class BaseNodeVisitor<TData> implements GeneralTreeNodeVisitor<TData> {

    /**
     * The Map that maps a particular type of node to its corresponding visitor.
     */
    private visitorMap = new Map<Type<TreeNode>, TypeSpecificTreeNodeVisitor<TreeNode, TData>>();

    /**
     * Constructs a new instance of the BaseNodeVisitor class.
     *
     * @param typeSpecificVisitors The set of type-specific visitors for each type of node.
     */
    constructor(typeSpecificVisitors: TypeSpecificTreeNodeVisitor<TreeNode, TData>[]) {
        // Convert the array of visitors into a map for quick lookups.
        for (let visitor of typeSpecificVisitors) {
            for (let type of visitor.nodeTypes) {
                if (this.visitorMap.has(type)) {
                    throw new Error(`You cannot have multiple visitors for the ${type.name} type`);
                }
                this.visitorMap.set(type, visitor);
            }
        }
    }

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: TreeNode, data: TData): void {
        const visitor = this.getVisitor(node);
        visitor.preOrderVisit(node, data);
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(node: TreeNode, data: TData): void {
        const visitor = this.getVisitor(node);
        visitor.postOrderVisit(node, data);
    }

    /**
     * Gets the specific visitor for the given node.
     *
     * @param node The node for which to retrieve its visitor.
     * @returns The specific visitor for the node.
     */
    private getVisitor(node: TreeNode): TypeSpecificTreeNodeVisitor<TreeNode, TData> {
        const nodeType = Object.getPrototypeOf(node).constructor;
        const visitor = this.visitorMap.get(nodeType);

        if (!visitor) {
            throw new Error(`No visitor has been defined for nodes of type ${nodeType.name}`);
        }

        return visitor;
    }
}
