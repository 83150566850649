/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IRootScopeService = angular.IRootScopeService;
import ILogService = angular.ILogService;
import Setting from '../settings/Setting';
import SettingKey from '../settings/SettingKey';
import FontSize from './FontSize';

export const FONTSIZE = 'fontSize';

const fontSizeBase = {
    font_xx_small: {
        small: '10px',
        medium: '14px',
        large: '16px'
    },
    font_x_small: {
        small: '11px',
        medium: '15px',
        large: '16px'
    },
    font_x_small_for_tables: {
        small: '11px',
        medium: '13px',
        large: '15px'
    },
    font_small: {
        small: '12px',
        medium: '16px',
        large: '18px'
    },
    font_x_medium: {
        small: '13px',
        medium: '17px',
        large: '19px'
    },
    font_medium: {
        small: '14px',
        medium: '18px',
        large: '19px'
    },
    font_large: {
        small: '15px',
        medium: '19px',
        large: '21px'
    },
    font_x_large: {
        small: '16px',
        medium: '20px',
        large: '22px'
    },
    font_xx_large: {
        small: '17px',
        medium: '21px',
        large: '23px'
    },
    font_xxx_large: {
        small: '20px',
        medium: '22px',
        large: '24px'
    }
};

const fontSizeVariables = {
    font_xx_small: '--font_xx_small',
    font_x_small: '--font_x_small',
    font_x_small_for_tables: '--font_x_small_for_tables',
    font_small: '--font_small',
    font_x_medium: '--font_x_medium',
    font_medium: '--font_medium',
    font_large: '--font_large',
    font_x_large: '--font_x_large',
    font_xx_large: '--font_xx_large',
    font_xxx_large: '--font_xxx_large'
};

export class FontSizeChanger {

    public static $inject = ['$rootScope', 'fontSizeSetting', '$log'];

    constructor(private $rootScope: IRootScopeService,
        private fontSizeSetting: Setting<FontSize>,
        private $log: ILogService) {

        // Listen for the font size setting to change, and then update the app's font size accordingly.
        $rootScope.$on(`${Setting.SETTING_CHANGED}:${SettingKey.fontSizeSetting}`, () => this.setFontSize());
    }

    public setFontSize(fontSize?: FontSize): void {
        // If a font size isn't explicitly specified, then check the setting.
        if (!angular.isNumber(fontSize)) {
            fontSize = this.fontSizeSetting.value;
        }

        // Enums in TypeScript are a two-way map: the number maps to the name and the name maps to the number.
        // Here we're getting the name using the number.
        let fontSizeName = FontSize[fontSize];

        if (fontSizeName) {
            this.$rootScope[FONTSIZE] = fontSizeName;
        } else {
            this.$log.warn(`Invalid font size: ${fontSize}. Font size not changed.`);
        }

        Object.keys(fontSizeVariables).forEach(function(key: any) {
            document.documentElement.style.setProperty(fontSizeVariables[key], fontSizeBase[key][fontSizeName]);
        });
    }
}
