/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import { Involvement } from '../../../../schema/Involvement';

/**
 * A component that displays a list of involvements.
 */
@Component({
    selector: 'sds-involvement-list',
    template: require('./involvement-list.component.html'),
    styles: [
        require('./involvement-list.component.scss'),
        require('./involvement-list.component.day.scss'),
        require('./involvement-list.component.night.scss')
    ]
})
export class InvolvementListComponent {

    /**
     * The array of involvements to display.
     */
    @Input()
    public involvements: Involvement[];

    /**
     * The array of selected involvements to display.
     */
    public selectedInvolvements: Involvement[];

    /**
     * The array of strings and regular expressions.
     */
    public cleanables = [', [Undefined Action]'];

    /**
     * The array of involvement types.
     */
    public allTypes: any = [];

    /**
     * The filter value of involvement types for filtering.
     */
    public typesFilter = {
        type: 'All'
    };

    /**
     * Returns a type identifier for the involvement.
     *
     * @param index The index of the type within the list.
     * @param involvement The involvement to identify.
     * @returns The type identifier for the involvement.
     */
    public identifyType(): void {
        const types = this.involvements.map(involvement => involvement.type);
        this.allTypes = Array.from(new Set(types));
    }

    /**
     * The function make changes after changing in the dropdown menu and selecting new type.
     */
    public onSelectInvolvementType(): void {
        this.filterInvolvements();
    }

    /**
     * The filter function, that returns the array of selected involvements to display.
     */
    public filterInvolvements = () => {
        this.selectedInvolvements = (this.typesFilter.type === 'All') ?
            this.involvements :
            this.involvements.filter(involvement => involvement.type === this.typesFilter.type);
    };

    /**
     * Items available after component initialized
     */
    public ngOnInit() {
        this.identifyType();
        this.selectedInvolvements = this.involvements;
    }
}
