/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IHttpService = angular.IHttpService;
import FireIncident from '../schema/FireIncident';
import ComputedUrlResource from '../resource/computedUrl/ComputedUrlResource';
import IHttpResponseTransformer = angular.IHttpResponseTransformer;
import { Involvable } from '../schema/interfaces/involvable';
import { InvolvementAlertsBatchRequest } from './involvement/involvement-alerts-batch-request.service';
import ExtendedResourceClass from './incident/ExtendedResourceClass';

/**
 * The involvement type number for fire incidents (frmain).
 */
const fireIncidentInvolvementTypeNumber = 1000;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
FireIncidentAPI.$inject = [
    'computedUrlResource',
    '$http',
    'getFirstElementTransform',
    'defaultPageSize',
    'addInvolvementTypeTransform',
    'involvementAlertsBatchRequest'
];

/**
 * A factory function that creates an API for retrieving fire incidents from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @param $http The Angular service that makes http requests.
 * @param getFirstElementTransform A response transform that returns only the first element from the array.
 * @param defaultPageSize The default page size used when displaying a list of records.
 * @param addInvolvementTypeTransform A curried function that can be used to add the involvement type number to domain models.
 * @param involvementAlertsBatchRequest The service that retrieves the involvement alerts for an entire list of involvable models.
 * @returns {IResourceClass<FireIncident>} A resource class that knows how to load Fire Incidents from the REST server.
 */
function FireIncidentAPI(
    computedUrlResource: ComputedUrlResource,
    $http: IHttpService,
    getFirstElementTransform: IHttpResponseTransformer,
    defaultPageSize: number,
    addInvolvementTypeTransform: _.CurriedFunction2<number, Involvable | Involvable[], Involvable | Involvable[]>,
    involvementAlertsBatchRequest: InvolvementAlertsBatchRequest
): ExtendedResourceClass<FireIncident> {
    const addFireIncidentInvolvementTypeTransform = addInvolvementTypeTransform(fireIncidentInvolvementTypeNumber);
    const addIncidentTypeParam = (data: Array<FireIncident>) => {
        data.map((item: FireIncident) => item.incidentType = 'fire');
        return data;
    };

    return <ExtendedResourceClass<FireIncident>>computedUrlResource<FireIncident>({ path: '/tables/frmain/:id' }, {}, {
        get: {
            method: 'GET',
            transformResponse: angular.appendTransform($http.defaults.transformResponse, getFirstElementTransform)
        },
        query: {
            method: 'GET',
            isArray: true,
            params: {
                sort: '-dtrepor', // Sort by the reported date, descending.
                pageSize: defaultPageSize
            },
            transformResponse: angular.appendTransform(
                $http.defaults.transformResponse,
                addFireIncidentInvolvementTypeTransform,
                involvementAlertsBatchRequest.query
            )
        },
        callQuery: {
            method: 'GET',
            isArray: true,
            params: {
                sort: '-dtrepor', // Sort by the reported date, descending.
                pageSize: defaultPageSize
            },
            transformResponse: angular.appendTransform(
                $http.defaults.transformResponse,
                addIncidentTypeParam
            )
        }
    });
}

export default FireIncidentAPI;
