/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import CadListFilter from '../../filtering/CadListFilter';
import AggregatorUnit from '../../../schema/AggregatorUnit';
import UnitFilter from './UnitFilter';
import SettingsRepository from '../../../settings/SettingsRepository';
import IInjectorService = angular.auto.IInjectorService;
import SettingKey from '../../../settings/SettingKey';
import Setting from '../../../settings/Setting';
import CustomFilterAllFilters from '../../filtering/custom-filters/CustomFilterAllFilters';
import FiltersStorage from '../../filtering/storage/FiltersStorage';
import { IRootScopeService } from 'angular';
import { UNIT_ACTIONS } from '../../../cad/filtering/consts';

/**
 * An actual Angular filter that can be used in the view to select a subset of units from an array.
 * This filter reads the `unitFilterSetting` and uses that value to determine how the units should be filtered.
 *
 * Example:
 * <div ng-repeat="unit in units | unitListFilter"></div>
 */
export default class UnitListFilter extends CadListFilter<AggregatorUnit, UnitFilter> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['settingsRepository', '$injector'];

    /**
     * Constructs a new instance of the UnitListFilter class.
     *
     * @param settingsRepository The repository that stores all settings.
     * @param $injector The Angular injector service.
     */
    constructor(settingsRepository: SettingsRepository, $injector: IInjectorService) {
        super(settingsRepository, $injector);

        const setting = <Setting<UnitFilter>> settingsRepository.get(this.settingKey);

        if (setting.value === UnitFilter.unitCustomFilter) {
            const customFilter = $injector.get<CustomFilterAllFilters>('unitCustomFilterAllFilters');
            const filtersStorage = $injector.get<FiltersStorage>('unitFiltersStorage');

            filtersStorage.setCurrentFilter(setting.selectedOption.text);
            customFilter.restoreAllFilters();
            // If last selected option is custom filter then trigger ADVANCED_FILTER_CHANGED to update filter header

            const $rootScope = $injector.get<IRootScopeService>('$rootScope');
            $rootScope.$broadcast(`${UNIT_ACTIONS.ADVANCED_FILTER_CHANGED}`);
        }
    }

    // @Override
    protected get settingKey(): SettingKey {
        return SettingKey.unitFilterSetting;
    }

    // @Override
    protected getFilterName(value: UnitFilter): string {
        return UnitFilter[value];
    }
}
