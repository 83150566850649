/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../shared/database/database.service';
import { DetailsStateParams } from '../../shared/interfaces/DetailsStateParams';
import { Premise } from './model/premise';
import { Alarm } from './model/alarm';
import { Area } from './model/area';
import { Description } from './model/description';
import { JoinPipe } from '../../shared/pipes';
import { SlideSourceInfo } from '../../schema/SlideSourceInfo';
import PermissionName from '../../permissions/enums/PermissionName';

/**
 * A component that displays the details of a premise.
 */
@Component({
    selector: 'sds-premise-detail',
    template: require('./premise-detail.component.html')
})
export class PremiseDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        this.commentsSlideSourceInfo,
        ['Businesses', { srcHtml: 'app/rms/premise/slides/businesses.html', permissions: PermissionName.business }],
        ['Contacts', { srcHtml: 'app/rms/shared/slides/business-contacts.html', permissions: PermissionName.names }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The premise model to display.
     */
    public premise: Premise;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the PremiseDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     * @param involvementsSlideSourceInfo inject the involvement slide from a value.
     * @param commentsSlideSourceInfo inject the comments slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        private joinPipe: JoinPipe,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * The formatter that knows how to convert an PremiseLocalId to a string.
     */
    public alarmFormatter = (alarm: Alarm) => this.joinPipe.transform([alarm.type, alarm.location], ' - ');
    public areaFormatter = (area: Area) => area.location;
    public descriptionFormatter = (description: Description) => this.joinPipe.transform([description.subject, description.narrative], ' - ');

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(Premise, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.premise = detailModel, () => this.onError.emit());
    }
}
