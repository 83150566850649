/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IResourceArray = angular.resource.IResourceArray;
import IQService = angular.IQService;
import IPromise = angular.IPromise;
import IResource = angular.resource.IResource;
import ResourceArrayPager = angular.resource.ResourceArrayPager;
import MultiResourceArrayPager from './paging/MultiResourceArrayPager';
import DummyResourceArrayPager from './paging/DummyResourceArrayPager';
import IInjectorService = angular.auto.IInjectorService;
type AsyncArrayTransform<T> = (combinedArray: T[]) => T[]|IPromise<T[]>;

/**
 * A class that provides utility methods for working with the Angular $resource service.
 */
export default class ResourceUtils {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$q', '$injector'];

    /**
     * Constructs a new instance of the ResourceUtils class.
     *
     * @param _$q The angular service that handles creating and working with promises.
     * @param _$injector The Angular injector service.
     */
    constructor(private _$q: IQService, private _$injector: IInjectorService) {
    }

    /**
     * Creates a new IResourceArray of type T.
     *
     * @param valueOrPromise Either an array of type T or a promise that returns an array of type T.
     *                       This will be used to populate the new IResourceArray.
     * @param pager The object that is responsible for paging the resource array.
     *              The default pager will simply show all records (i.e. no paging).
     * @returns {IResourceArray<T>} A newly created resource array of type T.
     */
    public createResourceArray<T extends IResource<any>>(valueOrPromise?: T[]|IPromise<T[]>,
        pager?: ResourceArrayPager<T>): IResourceArray<T> {
        const resourceArray = <IResourceArray<T>>[];
        const promise = this._$q.when(valueOrPromise)
            .then(results => {
                resourceArray.pushAll(results || []);
                return resourceArray;
            })
            .finally(() => {
                resourceArray.$resolved = true;
            });

        resourceArray.$resolved = false;
        resourceArray.$promise = promise;
        resourceArray.pager = pager || this._$injector.instantiate<ResourceArrayPager<T>>(DummyResourceArrayPager);
        return resourceArray;
    }

    /**
     * Combines multiple resource arrays into a single resource array.
     *
     * @param resourceArrays An array of resource arrays.
     * @param transform (optional) A function (such as filtering or sorting) to apply to the combined results
     *                             before adding them to the resource array.
     * @param pageSize The size of each page. By default it is infinity -- meaning that all records will be shown.
     * @returns {IResourceArray<T>} A single resource that is comprised of the combined data of the original resource arrays.
     */
    public combineResourceArrays<T extends IResource<any>>(resourceArrays: IResourceArray<T>[],
        transform: AsyncArrayTransform<T> = combinedArray => combinedArray,
        pageSize: number = Infinity): MultiResourceArrayPager<T> {
        const multiResourceArrayPager = this._$injector.instantiate<MultiResourceArrayPager<T>>(MultiResourceArrayPager, {
            subResourceArrays: resourceArrays,
            transform,
            pageSize
        });
        return multiResourceArrayPager;
    }

    /**
     * Creates a copy of the params and then appends the given filter.
     *
     * @param params The params used for searching.
     * @param filter An additional filter to add to the copy of the params.
     * @returns {any} A copy of the params with the given filter appended.
     */
    public addFilter(params: any, filter: string) {
        let paramsCopy = angular.copy(params) || {};
        if (paramsCopy.filter) {
            paramsCopy.filter += ';' + filter;
        } else {
            paramsCopy.filter = filter;
        }
        return paramsCopy;
    }
}
