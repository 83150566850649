/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { DatabaseService } from '../../../../shared/database/database.service';
import { AttachFilesTables } from './model/attach-files-tables';

/**
 * A store containing a string of tables which can have attachments.
 */
@Injectable()
export class AttachFilesTablesStore {

    /**
     * The string containing a list of tables which can have attachments.
     */
    private _tablesStore: string;

    /**
     * Gets the tablesStore.
     */
    public get tablesStore(): string {
        return this._tablesStore;
    }

    /**
     * Constructs a new instance of the AttachFilesTablesStore
     */
    constructor(
        private databaseService: DatabaseService
    ) {
    }

    /**
     * Checks to see if the table can have attachments.
     *
     * @param table The table name to check.
     */
    public isAttachable(table: string): Boolean {
        return !!table && this.tablesStore.search(new RegExp('\\b' + table + '\\b')) >= 0;
    }

    /**
     * Requests and sets the initial data from the 'apparam' table using the 'aftables' as the primary key.
     *
     * @returns A promise that, when resolved, will return the list of tables that can have attachments.
     */
    public loadInitialData(): Promise<string> {
        return this.databaseService.get(AttachFilesTables, 'aftables')
            .timeout(20 * 1000)
            .map(response => this._tablesStore = response.value)
            .toPromise();
    }
}
