/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IRootScopeService = angular.IRootScopeService;
import Setting from '../Setting';
import SettingKey from '../SettingKey';
import SettingStorage from '../SettingStorage';
import { LoggingLevel } from '../../logging';
import Option from '../Option';

/**
 * The setting that determines which logging level to use by the Logger service.
 */
export class LoggingLevelSetting extends Setting<LoggingLevel> {

    public static $inject = ['$q', 'settingStorage', '$rootScope'];

    /**
     * Constructs a new instance of the LoggingLevelSetting class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param settingStorage The object that handles persistent storage of the setting value.
     * @param $rootScope The Angular $rootScope that can be used to broadcast events throughout the app.
     */
    constructor($q: IQService, settingStorage: SettingStorage, $rootScope: IRootScopeService) {
        super($q, settingStorage, $rootScope, SettingKey.loggingLevelSetting, 'Logging Level', [
            new Option<LoggingLevel>('None', undefined),
            new Option<LoggingLevel>('Error', LoggingLevel.Error),
            new Option<LoggingLevel>('Warn', LoggingLevel.Warn),
            new Option<LoggingLevel>('Info', LoggingLevel.Info),
            new Option<LoggingLevel>('Debug', LoggingLevel.Debug),
            new Option<LoggingLevel>('Trace', LoggingLevel.Trace)
        ]);
    }
}
