/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IRootScopeService = angular.IRootScopeService;
import IState = angular.ui.IState;
import { upgradeAdapter } from '../upgrade-adapter';
import { HomeStateProvider } from './state-history/home-state-provider.service';
import { StateHistoryStorage } from './state-history/state-history-storage.service';

export const historyModule = angular.module('history', ['shared'])
    .factory('stateHistoryStorage', upgradeAdapter.downgradeNg2Provider(StateHistoryStorage))
    .factory('homeStateProvider', upgradeAdapter.downgradeNg2Provider(HomeStateProvider))
    .value('isPotentialHomeState', (state: IState) => !!state.data && !state.abstract && typeof state.data.homePageIndex === 'number')

    .run(['$rootScope', '$injector', 'isPotentialHomeState',
        function ($rootScope: IRootScopeService, $injector: angular.auto.IInjectorService, isPotentialHomeState: (state: IState) => boolean) {
            $rootScope.$on('$stateChangeSuccess', (_event, toState) => {
                if (isPotentialHomeState(toState)) {

                    // NOTE: Because the `stateHistoryStorage` depends on an Angular 2 service,
                    // we have to manually inject it sometime after the application has started running.
                    // This is to work around the following bug with the UpgradeAdapter:
                    // https://github.com/angular/angular/issues/10992
                    const stateHistoryStorage = $injector.get<StateHistoryStorage>('stateHistoryStorage');
                    stateHistoryStorage.append(toState.name);
                }
            });
        }]);

upgradeAdapter.upgradeNg1Provider('isPotentialHomeState');
