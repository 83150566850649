/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import Session from '../login/Session';
import UnitRepository from './units/repository/UnitRepository';

/**
 * A service that returns the current user's unit.
 */
@Injectable()
export class MyUnitProvider {

    /**
     * Constructs a new instance of the MyUnitProvider class.
     *
     * @param session
     * @param unitRepository
     */
    constructor( @Inject('session') private session: Session,
        @Inject('unitRepository') private unitRepository: UnitRepository) {
    }

    /**
     * Gets the current user's unit number.
     *
     * Note: This method will throw an error if a session does not exist.
     *
     * @returns The current user's unit number.
     */
    public get number(): string {
        return this.session.exists() ? this.session.data.unitNumber : undefined;
    }

    /**
     * Gets the current user's unit.
     *
     * Note: it is possible that `undefined` will be returned if the user's unit
     * has not been active for some time (i.e. 2 or more weeks).
     *
     * @returns The current user's unit.
     */
    public get unit() {
        return this.unitRepository.modelMap[this.number];
    }
}
