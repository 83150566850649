/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * The premise alarm model
 */
@table('bialarms')
export class Alarm {

    @expand('altype', 'desc')
    public type: string;

    @field('locatn')
    public location: string;
}
