/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import angular, { IRootScopeService, IScope } from 'angular';

import IonicHistoryService = ionic.navigation.IonicHistoryService;
import IonicPopupService = ionic.popup.IonicPopupService;
import FilterOptionsSubHeaderComponentController from '../../../filtering/components/baseClasses/filter-options-subheader/filter-options-subheader-component-controller';
import CustomFilterAllFilters from '../../../filtering/custom-filters/CustomFilterAllFilters';
import CallFilterSetting from '../../../../settings/implementations/CallFilterSetting';
import FiltersStorage from '../../../filtering/storage/FiltersStorage';
import { callAnalyticsEvents } from '../../../filtering/analytics/events';
import { CALL_ACTIONS } from '../../../filtering/consts';
import CallFilter from '../../filtering/CallFilter';

export const callFilterOptionsSubheader: angular.IComponentOptions = {
    template: require('../../../filtering/components/baseClasses/filter-options-subheader/filter-options-subheader-component.component.html'),
    bindings: {
        isWideScreen: '<',
    },
    controller: class CallFilterOptionsSubheader extends FilterOptionsSubHeaderComponentController<CallFilter> {
        public static $inject = [
            '$rootScope',
            '$ionicHistory',
            '$scope',
            'callCustomFilterAllFilters',
            '$ionicPopup',
            'callFilterSetting',
            'callFiltersStorage',
        ];

        constructor(
            $rootScope: IRootScopeService,
            $ionicHistory: IonicHistoryService,
            $scope: IScope,
            customFilterAllFilters: CustomFilterAllFilters,
            $ionicPopup: IonicPopupService,
            callFilterSetting: CallFilterSetting,
            filtersStorage: FiltersStorage,

        ) {
            super(
                filtersStorage,
                $rootScope,
                $ionicHistory,
                $scope,
                customFilterAllFilters,
                $ionicPopup,
                callFilterSetting,
                callAnalyticsEvents,
                CALL_ACTIONS
            );
        }
    }
};
