/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';
import { NameReference } from '../../../shared/model/name-reference';
import { VehicleReference } from '../../../shared/model/vehicle-reference';

/**
 * The accident-vehicle model
 */
@table('acvhdtl')
export class AccidentVehicle {

    @field('speed')
    public actualSpeed: number;

    @expand('halign', 'descrpt')
    public roadAlignment: string;

    @field('vhsplim')
    public authorizedSpeed: number;

    @expand('cbdytyp', 'desc')
    public cargoBody: string;

    @expand('carrier', NameReference)
    public carrier: NameReference | string;

    @expand('typeveh', 'desc')
    public commercialVehicleType: string;

    @field('comment')
    public comments: string;

    @expand('extdmg', 'descrpt')
    public damageExtent: string;

    @expand('dirc', 'desc')
    public direction: string;

    @field('emeruse')
    public emergencyUse: boolean;

    @expand('seqevt1', 'desc')
    public event1: string;

    @expand('seqevt2', 'desc')
    public event2: string;

    @expand('seqevt3', 'desc')
    public event3: string;

    @expand('seqevt4', 'desc')
    public event4: string;

    public eventSequence: string[];

    @expand('grade', 'descrpt')
    public roadGrade: string;

    @expand('gvwr', 'descrpt')
    public grossVehicleWeightRating: string;

    @expand('cgvwr', 'descrpt')
    public combinedGrossVehicleWeightRating: string;

    @field('hazmt1d')
    public hazmat1DigitNumber: string;

    @field('hazmt4d')
    public hazmat4DigitNumber: string;

    @field('hitrun')
    public hitAndRun: boolean;

    @expand('pimpact', 'desc')
    public impactPoint: string;

    @field('numlns')
    public lanes: number;

    @expand('movem', 'desc')
    public maneuver: string;

    @expand('mostdmg', 'desc')
    public mostDamagedArea: string;

    @expand('harmevt', 'desc')
    public mostHarmfulEvent: string;

    @field('numoccu')
    public occupants: number;

    @expand('ped', 'desc')
    public pedestrian: string;

    @field('ident')
    public relationshipId: string;

    @field('hazmtrl')
    public hazardousMaterialsReleased: boolean;

    @expand('spcfunc', 'desc')
    public specialUse: string;

    @expand('tcd', 'desc')
    public trafficControl: string;

    @field('tcdwork')
    public trafficControlWorking: boolean;

    @expand('twydesc', 'descrpt')
    public trafficwayDescription: string;

    @expand('undrovr', 'desc')
    public underrideOrOverride: string;

    @expand('vehicle', VehicleReference)
    public vehicle: VehicleReference | string;

    @expand('mvcc1', 'desc')
    public vehicleContributingCircumstance1: string;

    @expand('mvcc2', 'desc')
    public vehicleContributingCircumstance2: string;

    @expand('unittyp', 'descrpt')
    public vehicleUnitType: string;
}
