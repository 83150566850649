/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptionsArgs, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { UrlFactory } from '../../authentication';
import { SpillmanLocalStorage } from '../../shared/storage/spillman-local-storage';
import ShortUniqueId from 'short-unique-id';
import { DEVICE_ID_LENGTH, DEVICE_ID_KEY } from './constants';

export const systemUseNotificationPath = '/systemUseNotification';

/**
 * A service which handles the retrieval and logging of the System Use Notification.
 */
@Injectable()
export class SystemUseNotification {

    public static $inject = [
        'spillmanLocalStorage'
    ];

    /**
     *  uuid Represents the Device ID.
     *  The Device ID is created upon the installation of the app.
     *  The Device ID must be a certain length in order to match the database requirements.
     *  The uuid is created if the Device ID is either non-existent or it does not match the length requirement.
     */
    private uuid: string;

    /**
     * Constructs a new instance of the SystemUseNotification class.
     *
     * @param http The Angular service that makes http requests.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     */
    constructor(
        private spillmanLocalStorage: SpillmanLocalStorage,
        private http: Http,
        private urlFactory: UrlFactory
    ) {
        this.uuid = this.spillmanLocalStorage.getObject({ key: DEVICE_ID_KEY });
        if (this.uuid === undefined || this.uuid.length !== DEVICE_ID_LENGTH) {
            const uniqueIdGenerator = new ShortUniqueId({ length: DEVICE_ID_LENGTH });
            this.uuid = uniqueIdGenerator();
            this.spillmanLocalStorage.setObject({ key: DEVICE_ID_KEY }, this.uuid);
        }
    }

    /**
     * Retrieves the System Use Notification from the server.
     *
     * @returns An Observable containing the data retrieved from the server.
     */
    public getNotification(): Observable<SystemUseModel> {

        const systemUseUrl = this.urlFactory.create({ path: systemUseNotificationPath });

        return this.http.get(systemUseUrl).map(response => response.json().data);
    }

    /**
     * Logs to the server the user's response to the system use notification.
     *
     * @param response A boolean indicating if the user has aggreed to the notification.
     * @returns An Observable containing the user's response.
     */
    public saveResponse(response: boolean): Observable<Boolean> {

        const url = this.urlFactory.create({ path: systemUseNotificationPath });
        const myRequestOptions = this.createOptions(response);

        return this.http.post(url, undefined, myRequestOptions).map(() => response);
    }

    /**
     * Creates the http options for submitting a post request to the server.
     *
     * @param response A boolean indicating if the user has aggreed to the notification.
     * @returns The created options which will be used for the request.
     */
    private createOptions(accepted: boolean): RequestOptionsArgs {

        const params = new URLSearchParams();
        params.set('accepted', accepted.toString());
        params.set('computerName', this.uuid);

        const options = {
            headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }),
            search: params
        };
        return options;
    }
}

/**
 * An interface representing the response from the server when retrieving the system use notification.
 */
export interface SystemUseModel {
    /**
     * A flag indicating if the user needs to respond to the aggreement.
     */
    required: boolean;

    /**
     * The system use notification text.
     */
    message: string;
}
