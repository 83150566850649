/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import angular, { IRootScopeService, IScope } from 'angular';

import CustomFilterByZones from '../../../../filtering/custom-filters/CustomFilterByZones';
import CustomFilterByAgencies from '../../../../filtering/custom-filters/CustomFilterByAgencies';
import { ZoneService } from '../../../../services/zones/zone-service';
import Session from '../../../../../login/Session';
import ZoneComponentController from '../../../../filtering/components/baseClasses/zone/zone-component.component';
import { UNIT_ACTIONS } from '../../../../filtering/consts';

/**
 * A component of zones for Unit filtering
 */
export const unitZoneComponent: angular.IComponentOptions = {
    template: require('./unit-zone-component.component.html'),

    controller: class UnitZoneComponentController extends ZoneComponentController {
        public static $inject = ['$scope', '$rootScope', 'unitCustomFilterByZones', 'zoneService', 'unitCustomFilterByAgencies', 'session'];

        constructor(
            protected $scope: IScope,
            protected $rootScope: IRootScopeService,
            public customFilterByZones: CustomFilterByZones,
            public zoneService: ZoneService,
            protected customFilterByAgencies: CustomFilterByAgencies,
            protected session: Session
        ) {
            super($scope, $rootScope, customFilterByZones,zoneService,customFilterByAgencies,session, UNIT_ACTIONS.RETRY_FILTER);
        }
    }
};
