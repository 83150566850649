/* Copyright © 2021 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import debounce = require('lodash/debounce');
import IncidentHistoryScope from './incidentHistoryScope';
import { gridColumns } from './IncidentHistoryConstants';
import { CallHistoryAdvancedFilterService } from '../components/call-history-advanced-filter/call-history-advanced-filter.service';

IncidentHistorySortingDirective.$inject = ['callHistoryAdvancedFilterService'];
/**
 * A directive that displays a list of incident history records on the CAD Calls screen.
 */
export default function IncidentHistorySortingDirective(callHistoryAdvancedFilterService: CallHistoryAdvancedFilterService): IDirective {
    return {
        restrict: 'E',
        scope: {
            incidentsList: '<',
            incidentType: '<'
        },
        link: function (scope: IncidentHistoryScope) {
            scope.gridColumns = gridColumns;
            // set default sorting values
            scope.sortingMap = {
                nature: '',
                dtrepor: '',
                dispos: '',
                agency: '',
                respoff: ''
            };
            /**
             * handles grid columns header click
             * @param colName name of clicked column
             */
            scope.onSort = (colName: string) => {
                // eslint-disable-next-line curly
                if (!scope.incidentsList.length) return;
                const sortingState = scope.sortingMap[colName];
                scope.sortingMap[colName] = sortingState === 'asc' ? 'desc' : sortingState === 'desc' ? '' : 'asc';
                scope.setSortingParams(colName);
                callHistoryAdvancedFilterService.isSpinnerShown = true;
                callHistoryAdvancedFilterService.sortingMap[scope.incidentType] = scope.sortingMap;
                // make only one request in case of few clicks by column header
                if (!scope.sortData) {
                    scope.sortData = debounce(() => {
                        callHistoryAdvancedFilterService.onSort(scope.incidentType).then(
                            (data: any) => {
                                scope.$emit('incidentsListUpdate', { data });
                            },
                            (error: any) => {
                                scope.$emit('incidentsListUpdate', { error });
                            })
                            .finally(() => {
                                callHistoryAdvancedFilterService.isSpinnerShown = false;
                            });
                        scope.sortData = undefined;
                    }, 2000);
                    scope.sortData();
                }
            };

            /**
             * Defines sorting parameters for each incident type separately
             * @param colName name of column
             */
            scope.setSortingParams = (colName: string) => {
                const sortingState = scope.sortingMap[colName];
                let currentSortParameters = callHistoryAdvancedFilterService.sortParameters[scope.incidentType] || '';
                let newSortParameter = '';
                currentSortParameters = scope.filterSortingParam(currentSortParameters, colName);
                if (sortingState) {
                    newSortParameter = sortingState === 'asc' ? `${colName}` : `-${colName}`;
                }
                if (currentSortParameters && newSortParameter) {
                    currentSortParameters = `${currentSortParameters},${newSortParameter}`;
                } else if (!currentSortParameters) {
                    currentSortParameters = newSortParameter;
                }
                callHistoryAdvancedFilterService.sortParameters[scope.incidentType] = currentSortParameters;
            };

            /**
             * Removes sort parameter from query string in case sort order of column is updated
             * @param currentSortParameters previous sort parameters
             * @param colName name of column
             */
            scope.filterSortingParam = (currentSortParameters: string, colName: string) => {
                return currentSortParameters.split(',').filter((elem: string) => elem && !elem.includes(colName)).join(',');
            };
        },
        template: require('./incidentHistorySorting.html')
    };
}
