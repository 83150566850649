/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import CallActionViewState from './viewState/CallActionViewState';
import CallActionScope from './CallActionScope';
import IInjectorService = angular.auto.IInjectorService;
import AggregatorUnit from '../../../../schema/AggregatorUnit';
import UpdateStatusCallActionViewState from './viewState/UpdateStatusCallActionViewState';
import SelectCallActionViewState from './viewState/SelectCallActionViewState';
import AssignCallActionViewState from './viewState/AssignCallActionViewState';
import AssistCallActionViewState from './viewState/AssistCallActionViewState';
import PermissionsRepository from '../../../../permissions/PermissionsRepository';

CallActionDirective.$inject = ['$injector', 'permissionsRepository'];

/**
 * A factory function that creates a directive that will display the current action that the user can perform
 * in relation to the call. If the user cannot perform any action, then the directive will not be shown.
 *
 * @param $injector The Angular injector service.
 * @returns {IDirective} A directive that will display the current action that the user can perform in relation to the call.
 *                       If the user cannot perform any action, then the directive will not be shown.
 */
function CallActionDirective($injector: IInjectorService, permissionsRepository: PermissionsRepository): IDirective {

    /**
     * Instantiates a particular CallActionViewState using it's constructor.
     *
     * @param constructor The constructor for the CallActionViewState.
     * @param scope The scope that will be injected into the constructor.
     * @returns {CallActionViewState} A new instance of the particular CallActionViewState.
     */
    function instantiate(constructor: new (...args: any[]) => CallActionViewState, scope: CallActionScope) {
        return $injector.instantiate<CallActionViewState>(constructor, { '$scope': scope });
    }

    /**
     * Creates the array of all CallActionViewStates.
     *
     * @param scope The scope that will be used to construct the CallActionViewStates.
     * @returns {CallActionViewState[]} The array of all CallActionViewStates.
     */
    function createViewStates(scope: CallActionScope) {
        return [
            instantiate(UpdateStatusCallActionViewState, scope),
            instantiate(SelectCallActionViewState, scope),
            instantiate(AssignCallActionViewState, scope),
            instantiate(AssistCallActionViewState, scope)
        ];
    }

    return {
        template: require('./callAction.html'),
        scope: true,
        link: function (scope: CallActionScope) {
            let viewStates = createViewStates(scope);
            scope.$watch('call.tryGetMyUnit()', (myUnit: AggregatorUnit) => scope.myUnit = myUnit);
            scope.$watch('myUnit.status', () => scope.disableButton = false);
            scope.$watch(() => viewStates.find(v => v.isActive), viewState => {

                // If the state exists also check if any permissions were specified otherwise leave it undefined.
                let allowsAccess = viewState ? viewState.statePermission === undefined || permissionsRepository.processPermissions(viewState.statePermission) : undefined;

                if (allowsAccess) {
                    scope.disableButton = false;
                    scope.viewState = viewState;
                    scope.show = true;
                } else {
                    scope.show = false;
                }
            });
        }
    };
}

export default CallActionDirective;
