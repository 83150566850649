/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import Session from '../login/Session';
import Option from './Option';

/**
 * A filter that examines the metadata associated with each option in order to decide
 * if the option should be displayed.
 */
class RequiresUnitFilter {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['session'];

    /**
     * Constructs a new instance of the MetadataFilter class.
     *
     * @param session The object that stores information about the current user's session.
     */
    constructor(private session: Session) {
    }

    /**
     * Filters the options by examining the metadata in order to decide if it should be displayed.
     *
     * @param options The array of options to filter.
     * @returns {Option<T>[]} The subset of options that should be displayed.
     */
    public filter<T>(options: Option<T>[]): Option<T>[] {
        let userHasUnit = this.session.exists() && !!this.session.data.unitNumber;
        return userHasUnit ? options : options.filter(o => !o.metadata || !o.metadata.requiresUnit);
    }
}

export default RequiresUnitFilter;
