/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { ZoneModel } from '../../services/zones/zone-model';
import CADModel from '../../../schema/interfaces/CADModel';
import CustomDataCadFilter from '../baseClasses/CustomDataCadFilter';
import FiltersStorage from '../storage/FiltersStorage';

export default abstract class CustomFilterByZones extends CustomDataCadFilter<CADModel> {
    public static STORAGE_KEY = 'ZONES';
    public static $inject = ['callFiltersStorage'];

    public STORAGE_KEY = CustomFilterByZones.STORAGE_KEY;

    private selectedValues: ZoneModel[];

    constructor(filtersStorage: FiltersStorage) {
        super(filtersStorage);
        this.selectedValues = [];
        this.isFilterApplied = false;
    }

    public include(model: CADModel): boolean {
        const selectedValues = this.getSelectedValues().filter(value => value.applied === true);

        if (selectedValues === undefined || selectedValues.length === 0) {
            return false;
        }

        const result = selectedValues.find((data: FilterModel) => data.id === this.getModelValue(model));

        return result !== undefined;

    }

    public setValues(selectedValues: ZoneModel[]) {
        this.selectedValues = selectedValues;
    }

    public getFilterLabel(): string {
        return this.isFilterApplied ? `Zones (${this.selectedValues.filter(value => value.applied === true).length})` : undefined;
    }

    public applyFilter() {
        this.isFilterApplied = false;
        this.selectedValues = this.selectedValues.map(item => {
            item.applied = item.checked;
            return item;
        });

        this.isFilterApplied = this.selectedValues.some(item => item.checked === true);
    }

    public getSelectedValues(): ZoneModel[] {
        return this.selectedValues.filter(value => value.applied === true);
    }

    protected getModelValue(model: CADModel): string {
        return model.zone;
    }
}
