/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../../shared/database/decorators';
import { NameReference } from '../../../shared/model/name-reference';
import { Involvement } from '../../../../schema/Involvement';
import { OffenseJoin } from './offense-join';
import { BookingJoin } from './booking-join';

/**
 * The model of an arrest.
 */
@table('jlarrest')
export class JailArrest {

    @field('num')
    public number: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @includeMany('jlofxar', OffenseJoin)
    public offenseJoins: OffenseJoin[];

    @includeMany('jlbkxar', BookingJoin)
    public bookingJoins: BookingJoin[];

    @expand('area', 'desc')
    public area: string;

    @field('date')
    public date: Date;

    @field('judage')
    public judicialAgeStatus: string;

    @field('locatn')
    public location: string;

    @field('agency')
    public agency: string;

    @expand('inmate.namenum', NameReference)
    public inmate: NameReference | string;

    @expand('inmate.namenum', 'birthd')
    public dob: Date;

    @expand('type', 'descrpt')
    public arrestType: string;

    @expand('dispo', 'descrpt')
    public disposition: string;

    @expand('class', 'descrpt')
    public preSentenceClass: string;

    @expand('officer', 'fullnam')
    public officer: string;

    @field('aragncy')
    public arrestingAgency: string;

    @field('ref')
    public reference: string;

    @field('track')
    public tracking: string;

    @field('securid')
    public securityId: string;

    // Additional Info
    @includeOne('jlarnarr', 'text')
    public comments: string;

    public ageAtArrest: string;
}
