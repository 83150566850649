/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { Involvement } from '../../../schema/Involvement';
import { NameReference } from '../../shared/model';
import { PawnedDescription } from './pawned-description';

/**
 * The pawned property model
 */
@table('pnmain')
export class PawnedProperty {

    @field('number')
    public id: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @includeMany('pndesc', PawnedDescription)
    public descriptions: PawnedDescription[];

    @field('brand')
    public brand: string;

    @expand('categ', 'desc')
    public category: string;

    @expand('color', 'desc')
    public color: string;

    @expand('color2', 'desc')
    public color2: string;

    @field('id')
    public identityUsed: string;

    @field('item')
    public item: string;

    @field('loan')
    public loanValue: number;

    @field('model')
    public model: string;

    @expand('pcode', 'desc')
    public pawnCode: string;

    @expand('pawnby', NameReference)
    public pawnedBy: NameReference | string;

    @expand('shop', 'desc')
    public pawnShop: string;

    @expand('shop', 'shoptel')
    public phoneNumber: string;

    @field('receivd')
    public receivedBy: string;

    @field('serial')
    public serialNumber: string;

    @field('descrip')
    public description: string;

    @field('when')
    public whenPawned: Date;

    @field('ticket')
    public ticketNumber: string;
}
