/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ILogService = angular.ILogService;
import DecoratorTransform from './DecoratorTransform';
import DomainModel from '../../schema/interfaces/DomainModel';
import JoinedTableMap from './JoinedTableMap';

/**
 * The base class for those decorator transforms that loop through all of the model's joined tables,
 * groups them by table name, and then adds those groups as properties of the model.
 */
export default class BaseJoinedTablesDecoratorTransform<T extends DomainModel<any>> implements DecoratorTransform<T> {

    /**
     * Constructs a new instance of the BaseJoinedTablesDecoratorTransform class.
     *
     * @param $log The Angular service that performs logging.
     */
    constructor(private $log: ILogService) {
    }

    // @Override
    public invoke(model: T): void {
        for (let join of (model.joins || [])) {

            // Get the specification for the current join.
            let joinSpec = this.joinedTableMap[join.table];

            // Warn if no specification was found.
            if (!joinSpec) {
                this.$log.warn(`No specification was found for joined table: '${join.table}'`);
                continue;
            }

            // If it's a one-to-one join, then just set the property directly.
            if (joinSpec.isOneToOne) {
                model[joinSpec.propertyName] = join.fields;
            } else {
            // If it's one-to-many, then we need to ensure the array exists before adding the new joined model.
                if (!model[joinSpec.propertyName]) {
                    model[joinSpec.propertyName] = [];
                }
                model[joinSpec.propertyName].push(join.fields);
            }
        }
    }

    /**
     * Gets the JoinedTableMap that contains specifications for each type of joined table,
     * such as if it is one-to-one or one-to-many.
     *
     * @returns {JoinedTableMap} The map that contains specifications for each joined table.
     */
    protected get joinedTableMap(): JoinedTableMap {
        throw Error('Abstract property');
    }
}
