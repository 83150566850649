/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import Involvement from '../../schema/Involvement';
import InvolvementResourceClass from './InvolvementResourceClass';
import ComputedUrlResource from '../../resource/computedUrl/ComputedUrlResource';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
InvolvementAPI.$inject = ['computedUrlResource'];

/**
 * A factory function that creates an API for retrieving involvements from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @returns {InvolvementResourceClass} A resource class that knows how to load involvements from the REST server.
 */
function InvolvementAPI(computedUrlResource: ComputedUrlResource): InvolvementResourceClass {
    const typeSort = '-typ';

    return <InvolvementResourceClass>computedUrlResource<Involvement>({ path: '/tables/:table/:id/involvements' }, {}, {
        query: {
            method: 'GET',
            isArray: true,
            params: {
                sort: `-alert,${typeSort}`
            }
        },
        alerts: {
            method: 'GET',
            isArray: true,
            params: {
                filter: 'alert=1',
                sort: typeSort
            }
        }
    });
}

export default InvolvementAPI;
