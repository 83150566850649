/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import Property from '../../../schema/Property';
import Involvement from '../../../schema/Involvement';
import DecoratorTransform from '../../../shared/transforms/DecoratorTransform';

/**
 * A decorator transform that will add the involvements to a property.
 */
export default class PropertyInvolvementDecoratorTransform implements DecoratorTransform<Property> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['involvementAPI'];

    /**
     * Constructs a new instance of the PropertyInvolvementDecoratorTransform class.
     *
     * @param involvementAPI The API for retrieving involvements from the server.
     */
    constructor(private involvementAPI: IResourceClass<Involvement>) {
    }

    // @Override
    public invoke(property: Property): void {
        if (property.number) {
            property.involvements = this.involvementAPI.query({
                table: 'prmain',
                id: property.number
            });
        }
    }
}
