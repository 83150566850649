/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * The metadata key used to associate a set of properties with a particular class.
 * The properties just have to declared in TypeScript; they don't have to have been initialized at runtime.
 */
export const declaredPropertiesMetadataKey = Symbol('declared-properties');

/**
 * A decorator that adds the specified property to the set of declared properties on the target.
 *
 * @param target Either (1) the constructor function for static properties or (2) the prototype of the class for instance properties.
 * @param propertyKey The name or symbol of the property to add to the set of declared properties.
 */
export function declareProperty(target: Object, propertyKey: string | symbol): void {
    let declaredProperties: Set<string | symbol> = Reflect.getMetadata(declaredPropertiesMetadataKey, target);
    if (!declaredProperties) {
        declaredProperties = new Set<string | symbol>();
        Reflect.defineMetadata(declaredPropertiesMetadataKey, declaredProperties, target);
    }
    declaredProperties.add(propertyKey);
}
