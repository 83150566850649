/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import ImageMetadata from '../schema/ImageMetadata';
import ComputedUrlResource from '../resource/computedUrl/ComputedUrlResource';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
ImageAPI.$inject = ['computedUrlResource'];

/**
 * A factory function that creates an API for retrieving image metadata from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @returns {IResourceClass<ImageMetadata>} A resource class that knows how to load image metadata from the REST server.
 */
function ImageAPI(computedUrlResource: ComputedUrlResource): IResourceClass<ImageMetadata> {
    return computedUrlResource<ImageMetadata>({ path: '/tables/:table/:id/images' });
}

export default ImageAPI;
