/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { Defendant } from './defendant';
import { Event } from './event';
import { Offense } from './offense';
import { Witness } from './witness';
import { IncidentReference } from '../../shared/model/incident-reference';
import { JailBookingReference } from '../../shared/model/jail-booking-reference';
import { Involvement } from '../../../schema/Involvement';

/**
 * The citation model.
 */
@table('pcmain')
export class CaseFile {

    @field('text')
    public comments: number;

    @field('cmpdate')
    public completed: Date;

    @field('courtno')
    public courtNumber: string;

    @includeMany('pcdfdtl', Defendant)
    public defendants: Defendant[];

    @includeMany('pcdfaty', 'attynam')
    public defenseAttorneys: string[];

    @includeMany('pchist', Event)
    public eventHistory: Event[];

    @includeMany('pcsched', Event)
    public eventSchedule: Event[];

    @expand('disp', 'desc')
    public disposition: string;

    @field('number')
    public id: string;

    @field('intdate')
    public initiated: Date;

    @field('judge')
    public judge: string;

    @includeMany('pcofdtl', Offense)
    public offenses: Offense[];

    @field('prsatty')
    public prosecutor: string;

    @expand('booking', JailBookingReference)
    public relatedBooking: JailBookingReference | string;

    @expand('incdnum', IncidentReference)
    public relatedIncident: IncidentReference | string;

    @expand('casetyp', 'desc')
    public type: string;

    @includeMany('pcwitns', Witness)
    public witnesses: Witness[];

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
