/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAnimateService = angular.animate.IAnimateService;
import IQService = angular.IQService;
import IonicPlatformService = ionic.platform.IonicPlatformService;
import IRootScopeService = angular.IRootScopeService;

/**
 * The prefix for all events emitted by the ExpandingPopupDirective.
 */
const eventPrefix = 'expanding-popup';

/**
 * An object that contains the names of the events that are emitted by the ExpandingPopupDirective.
 */
export const expandingPopupEvents = Object.freeze({
    beforeExpand: `${eventPrefix}:before-expand`,
    afterExpand: `${eventPrefix}:after-expand`,
    beforeCollapse: `${eventPrefix}:before-collapse`,
    afterCollapse: `${eventPrefix}:after-collapse`
});

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
ExpandingPopupDirective.$inject = ['$animate', '$q', '$ionicPlatform', '$rootScope'];

/**
 * A factory function that creates the directive. The directive has an HTML element that can be clicked
 * to open the popup (the launcher). When clicked, the popup will initially be the same size as the launcher,
 * but will then expand to its full size.
 *
 * If the popup contains a copy of the launcher, then it can appear as if the launcher expanded into the popup,
 * which is a cool effect. However, it is up to users of this popup to ensure that the launcher and popup are
 * styled appropriately to achieve that effect.
 *
 * @param $animate The Angular animation service.
 * @param $q The angular service that handles creating and working with promises.
 * @param $ionicPlatform The Ionic service that is used to detect the current platform as well as handle the hardware back button.
 * @param $rootScope The Angular $rootScope that can be used to listen to application events.
 * @returns {IDirective} A directive that has an HTML element that, when clicked, will open a popup.
 *                       The popup will initially be the same size as the launcher, but will then expand to its full size.
 */
export function ExpandingPopupDirective(
    $animate: IAnimateService,
    $q: IQService,
    $ionicPlatform: IonicPlatformService,
    $rootScope: IRootScopeService
): IDirective {

    /**
     * Positions the popup so that it covers the referenceElement exactly.
     * It will have the same width, height, left, and top as the referenceElement.
     *
     * @param popup The popup that will be positioned over the referenceElement.
     * @param referenceElement The element to use as a reference when positioning the popup.
     */
    function setPopupPosition(popup: JQuery, referenceElement: JQuery): void {
        // `getBoundingClientRect` is more accurate than jQuery's width/height functions.
        // See http://stackoverflow.com/questions/11907514/getting-the-actual-floating-point-width-of-an-element
        let boundingRect = referenceElement[0].getBoundingClientRect();

        // `offset()` is used rather than `position()` because the popup should have no positioned ancestors.
        let offset = referenceElement.offset();

        // Place the popup so that it is exactly on top of the `referenceElement`.
        popup.css({
            top: offset.top + 'px',
            left: offset.left + 'px',
            width: Math.ceil(boundingRect.width) + 'px',
            height: Math.ceil(boundingRect.height) + 'px'
        });
    }

    return {
        restrict: 'EA',
        transclude: {
            launcher: 'sdsExpandingPopupLauncher',
            content: 'sdsExpandingPopupContent'
        },
        template: require('./expandingPopup.html'),
        scope: {
            cssClass: '@',
            deactivated: '<'
        },
        link: (scope: ExpandingPopupScope, element: IAugmentedJQuery) => {
            let launcher = element.find('.expanding-popup-launcher');
            let popup = element.find('.expanding-popup');
            let backdrop = element.find('.expanding-popup-backdrop');
            let popupAndBackdrop = popup.add(backdrop);

            // Move the popup and the backdrop so that they are direct children of the body.
            // This ensures that they will not be constrained by any positioned ancestor elements.
            angular.element('body').append(popupAndBackdrop);

            // Hide them until the `expand` method is called.
            popupAndBackdrop.hide();

            let deregisterBackButtonAction: Function;

            scope.expand = () => {
                if (!scope.deactivated) {
                    scope.$emit(expandingPopupEvents.beforeExpand);

                    setPopupPosition(popup, launcher);
                    popupAndBackdrop.show();

                    // Use `visibility: hidden;` instead of `display: none;` so that the layout doesn't change.
                    launcher.css('visibility', 'hidden');

                    // Trigger the popup to expand to its full size.
                    $q.all([
                        $animate.addClass(popup, 'expanded'),
                        $animate.addClass(backdrop, 'expanded')
                    ]).then(() => scope.$emit(expandingPopupEvents.afterExpand));

                    // Register the collapse method to be executed if the user presses the hardware back button.
                    deregisterBackButtonAction = $ionicPlatform.registerBackButtonAction(() => {
                        scope.$apply(scope.collapse);
                    }, 201);
                }
            };

            scope.collapse = () => {
                scope.$emit(expandingPopupEvents.beforeCollapse);

                // Reset the popup's position in case the user switched from portrait to landscape or vice versa.
                setPopupPosition(popup, launcher);

                // Wait for both the popup and backdrop to stop animating.
                $q.all([
                    $animate.removeClass(popup, 'expanded'),
                    $animate.removeClass(backdrop, 'expanded')
                ]).then(() => {
                    launcher.css('visibility', 'visible');
                    popupAndBackdrop.hide();
                    scope.$emit(expandingPopupEvents.afterCollapse);
                });

                // No longer listen for the hardware back button to be pressed.
                if (deregisterBackButtonAction) {
                    deregisterBackButtonAction();
                    deregisterBackButtonAction = undefined;
                }
            };

            // Remove the popup and backdrop when the scope is destroyed.
            scope.$on('$destroy', () => popupAndBackdrop.remove());

            // Automatically close the popup if the state has been changed (i.e. programmatically - such as the session timed out).
            $rootScope.$on('$stateChangeSuccess', scope.collapse);
        }
    };
}

/**
 * Defines the scope for the ExpandingPopupDirective.
 */
interface ExpandingPopupScope extends IScope {

    /**
     * A custom css class that will be applied to the launcher, popup, and backdrop
     * so that the directive can be fully customized.
     */
    cssClass: string;

    /**
     * A flag that if set to true, will deactivate the popup --
     * thus preventing it from opening.
     */
    deactivated: boolean;

    /**
     * Expands the popup.
     */
    expand: () => void;

    /**
     * Collapses the popup.
     */
    collapse: () => void;
}
