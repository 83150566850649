/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * The set of CallFilters that are available for choosing on the settings page.
 *
 * Note: These values must match exactly the names of the instances that are registered
 * with the angular module, because they will be loaded using the `$injector` service.
 */
enum CallFilter {
    filterNone,
    callFilterByAgency,
    callFilterByType,
    callFilterByUnit,
    callFilterByZone,
    newFilter,
    customFilter
}

export default CallFilter;
