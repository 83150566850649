/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import { upgradeAdapter } from '../upgrade-adapter';
import { PrivacyPolicyComponent } from './privacy-policy.component';

export const privacyPolicyModule = angular.module('privacy-policy', [
])
    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.privacyPolicy', {
                url: '/privacy-policy',
                views: {
                    other: {
                        template: `
                            <ion-view>
                                <ion-nav-title>Privacy Policy</ion-nav-title>

                                <ion-content ng-class="{'has-subheader': isWideScreen}">
                                    <sds-privacy-policy></sds-privacy-policy>
                                </ion-content>
                            </ion-view>
                        `
                    }
                }
            });
    }])

    .directive('sdsPrivacyPolicy', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(PrivacyPolicyComponent));
