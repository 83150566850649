/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ApplicationScope from './ApplicationScope';
import Session from './login/Session';
import { showIonicBackButton } from './shared/utilities/IonicUIUtilites';
import { INCIDENT_SEARCH_STATE_TYPES } from './shared/constants/constants';

/**
 * The controller for the abstract 'app' state. This serves as the parent controller for
 * all states while the user is logged in.
 */
class ApplicationController {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$scope', 'session', 'screenSize'];

    /**
     * Constructs a new instance of the ApplicationController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param session The object that stores information about the current user's session.
     * @param screenSize The match media object for detecting device screen size info.
     */
    constructor(
        $scope: ApplicationScope,
        session: Session,
        screenSize: any
    ) {
        $scope.$watch(() => session.exists() && !!session.data.unitNumber, (userHasUnit: boolean) => $scope.userHasUnit = userHasUnit);
        $scope.isWideScreen = screenSize.is('md, lg');
        screenSize.on('md, lg', function (match: boolean) {
            $scope.isWideScreen = match;
        });

        $scope.$on('$stateChangeSuccess', (_event, toState) => {
            $scope.showMainNavBar = true;
            if (INCIDENT_SEARCH_STATE_TYPES.find((item: string) => item === toState.name)) {
                showIonicBackButton();
            } else if (toState.name.includes('app.search.forms') && !$scope.isWideScreen) {
                $scope.showMainNavBar = false;
            }
        });
    }
}

export default ApplicationController;
