/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IPromise = angular.IPromise;
import IQService = angular.IQService;
import Join from '../../schema/interfaces/Join';
import OfficerName from '../../schema/OfficerName';
import UnitLongTerm from '../../schema/UnitLongTerm';
import IonicPopupService = ionic.popup.IonicPopupService;
import { logEvent } from '../../api/analytics/firebase-analytics-service';
import { AUTH_EVENTS } from '../authentication/events/authentication-events';

/**
 * A class which verifies or prompts for a unit.
 */
export default class UnitVerification {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        '$q',
        '$ionicPopup'
    ];

    /**
     * Constructs a new instance of the UnitVerification class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param $ionicPopup The service that displays a native-looking dialog.
     */
    constructor(private $q: IQService,
        private $ionicPopup: IonicPopupService) {
    }

    /**
     * Retrieves the unit for the current user.
     *
     * @param user An object which represents the user.
     * @param inputUnit The unit number the user attempted to use to log in.
     *
     * @returns {IPromise<UnitLongTerm>} A promise which, when resolved, returns the unit object.
     */
    public retrieveUnit(user: OfficerName, inputUnit: string): IPromise<UnitLongTerm> {
        let deferred = this.$q.defer<UnitLongTerm>();

        // A sorted array of units the officer is authorized to use.
        // If there are no units, it creates an empty array.
        let userUnits = user.joins ? user.joins.filter(this.hasUnit).map<UnitLongTerm>(j => j.fields.unitno.fields).sort(this.sortByUnitNumber) : [];

        // A blank inputUnit field.
        if (!inputUnit) {
            if (userUnits.length <= 0) {
                // Log into app as 'View Only'.
                deferred.resolve(undefined);
            } else if (userUnits.length === 1) {
                // Log in the user with the only authorized unit.
                deferred.resolve(userUnits[0]);
            } else {
                // Display popup list of possible units.
                this.unitsListPopup(userUnits).then(unit => {
                    deferred.resolve(unit);
                });
            }
        } else {
            // Perform a case-insensitive search.
            let chosenUnit = userUnits.find(u => u.unitno.toUpperCase() === inputUnit.toUpperCase());
            if (!chosenUnit) {
                // The inputUnit is not a valid choice.
                logEvent(AUTH_EVENTS.not_assigned_to_unit);
                deferred.reject('You are not assigned to unit ' + inputUnit);
            } else {
                // Log in with the unit chosen by the user.
                deferred.resolve(chosenUnit);
            }
        }

        return deferred.promise;
    }

    /**
     * Verify that a unit exists for the user.
     *
     * @param join The unit join to check.
     * @returns True if a unit was part of the join otherwise false.
     */
    private hasUnit = (join: Join): boolean => {
        try {
            const unit = join.fields.unitno.fields;
            return !!unit;
        } catch (e) {
            return false;
        }
    };

    /**
     * Sorts the two units alphabetically by their unit number.
     *
     * @param unit1 The first unit to be compared.
     * @param unit2 The second unit to be compared.
     * @returns {number} An integer that indicates how the first unit compares to the second.
     */
    private sortByUnitNumber(unit1: UnitLongTerm, unit2: UnitLongTerm): number {
        if (unit1.unitno > unit2.unitno) {
            return 1;
        }
        if (unit1.unitno < unit2.unitno) {
            return -1;
        }
        return 0;
    }

    /**
     * A popup displaying a list of possible units to choose from.
     *
     * @param units An Array of units which a user is able to use.
     *
     * @returns {IPromise<UnitLongTerm>} A promise containing the chosen unit.
     */
    private unitsListPopup(units: Array<UnitLongTerm>): IPromise<UnitLongTerm> {
        let buttonArray = units.map(unit => ({
            text: unit.unitno,
            type: 'button-default',
            onTap: () => unit
        }));

        return this.$ionicPopup.show({
            title: 'Select Unit',
            cssClass: 'unit-list-popup',
            buttons: buttonArray
        });
    }
}
