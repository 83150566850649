/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import Incident from '../../../schema/Incident';
import RadioLog from '../../../schema/RadioLog';
import ResourceUtils from '../../../resource/ResourceUtils';
import DecoratorTransform from '../../../shared/transforms/DecoratorTransform';

/**
 * A decorator transform that will add the radio logs to an incident.
 */
export default class IncidentRadioLogsDecoratorTransform implements DecoratorTransform<Incident> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['radioLogAPI', 'resourceUtils'];

    /**
     * Constructs a new instance of the IncidentRadioLogsDecoratorTransform class.
     *
     * @param radioLogAPI The API for retrieving radio logs from the server.
     * @param resourceUtils Provides utility methods for working with the Angular $resource service.
     */
    constructor(private radioLogAPI: IResourceClass<RadioLog>, private resourceUtils: ResourceUtils) {
    }

    // @Override
    public invoke(incident: Incident): void {
        incident.radioLogs = (incident.callid) ? this.radioLogAPI.query({filter: `callid=${incident.callid}`}) : this.resourceUtils.createResourceArray<RadioLog>();
    }
}
