/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideBasedOnCondition } from '../../../conditional-injection';
import { AttachFilesTablesStore } from './attach-files-tables/attach-files-tables-store.service';
import { AttachmentLinkComponent } from './attachment-link/attachment-link.component';
import { ImageComponent } from './image/image.component';
import { BlobRequester } from './blob-requester.service';
import { FileDownloader, BrowserFileDownloader, CordovaFileDownloader } from './file-downloader';
import { Transfer } from 'ionic-native/dist/es5/plugins/filetransfer';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        AttachmentLinkComponent,
        ImageComponent
    ],
    exports: [
        AttachmentLinkComponent,
        ImageComponent
    ],
    providers: [
        AttachFilesTablesStore,
        BlobRequester,
        Transfer,
        provideBasedOnCondition(
            FileDownloader,
            BrowserFileDownloader,
            CordovaFileDownloader
        )
    ]
})
export class AttachmentModule { }
