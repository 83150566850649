/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as moment from 'moment';
import IResourceClass = angular.resource.IResourceClass;
import IResourceArray = angular.resource.IResourceArray;
import RadioLog from '../../../../schema/RadioLog';
import ResourceUtils from '../../../../resource/ResourceUtils';
import UnitQuery from './UnitQuery';

/**
 * Requests the Radio Logs associated with the unit.
 */
export default class RadioLogsUnitQuery extends UnitQuery<IResourceArray<RadioLog>> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['radioLogAPI', 'resourceUtils', 'serverDateTimeFormat'];

    /**
     * Constructs a new instance of the RadioLogsQuery class.
     *
     * @param radioLogAPI The API for retrieving radio logs from the server.
     * @param resourceUtils Provides utility methods for working with the Angular $resource service.
     * @param serverDateTimeFormat The format in which dates are sent from the server.
     */
    constructor(
        private radioLogAPI: IResourceClass<RadioLog>,
        private resourceUtils: ResourceUtils,
        private serverDateTimeFormat: string
    ) {
        super();
    }

    // @Override
    protected get watchPropertyNames(): string | string[] {
        return ['unit', 'status'];
    }

    // @Override
    protected get updatePropertyName(): string {
        return 'radioLogs';
    }

    // @Override
    protected get defaultValue(): IResourceArray<RadioLog> {
        return this.resourceUtils.createResourceArray<RadioLog>();
    }

    // @Override
    protected performQuery(newValues: any[]): IResourceArray<RadioLog> {
        let yesterday = moment().subtract(1, 'day').format(this.serverDateTimeFormat);
        return this.radioLogAPI.query({ filter: `unit=${newValues[0]};logdate>=${yesterday}` });
    }
}
