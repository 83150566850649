/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { MyUnitProvider } from '../my-unit-provider.service';

// Used to provide the color code for unit status.
import ColorAssigner from '../ColorAssigner';

@Component({
    selector: 'sds-add-radio-log',
    template: require('./add-radio-log.component.html'),
    styles: [
        require('./add-radio-log.component.scss'),
        require('./add-radio-log.component.day.scss'),
        require('./add-radio-log.component.night.scss')
    ]
})

export class AddRadioLogComponent {

    /**
     * The comment to be submitted with a status change.
     */
    public comment: string;

    /**
     * Constructs a new instance of the AddRadioLogComponent class.
     *
     * @param myUnitProvider Provides the current user's unit.
     * @param changeDetectorRef
     * @param colorAssigner The object that assigns a color based on the status of the CAD call.
     */
    constructor(
        public myUnitProvider: MyUnitProvider,
        private changeDetectorRef: ChangeDetectorRef,
        @Inject('colorAssigner') public colorAssigner: ColorAssigner
    ) {
    }

    /**
     * Clears the text inside of the textbox for a comment.
     *
     * @param clear A boolean which states if the comment should be cleared.
     */
    public onClearComment(clear: boolean): void {
        if (clear) {
            this.clearComment();
        }
    };

    /**
     * Clears the text inside of the textbox for a comment.
     */
    public clearComment(): void {
        this.comment = '';
    }

    /**
     * Run change detection for component on input text.
     */
    public onValueChange() {
        this.changeDetectorRef.detectChanges();
    }
}
