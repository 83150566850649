/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { URLSearchParams } from '@angular/http';

import { ApiAuthenticationConfig } from '../api-authentication-config';
import { Credentials } from '../credentials';

/**
 * A service that provides the parameters for authenticating with the Spillman API.
 */
export abstract class AuthenticationParameterService {

    constructor(private config: ApiAuthenticationConfig) { }

    /**
     * Gets the parameters.
     *
     * @param credentials The credentials entered by the user.
     * @returns A URLSearchParams object that contains the parameters
     */
    public getParameters(credentials: Credentials, withoutClientIdSecret: Boolean = false): URLSearchParams {
        const params = new URLSearchParams();
        const pairs = this.getRequiredPairs(credentials, withoutClientIdSecret).concat(this.getAdditionalPairs() || []);
        pairs.forEach(p => params.set(p[0], p[1]));
        return params;
    }

    /**
     * Gets additional key/value pairs (other than the required pairs).
     *
     * @returns The array of additional key/value pairs.
     */
    protected abstract getAdditionalPairs(): [string, string][];

    /**
     * Gets the array of required key/value pairs.
     *
     * @param credentials The credentials entered by the user.
     * @returns The array of required key/value pairs.
     */
    private getRequiredPairs(credentials: Credentials, withoutClientIdSecret: Boolean = false): [string, string][] {

        let parametersArray: [string, string][] = [
            ['grant_type', 'password'],
            ['username', credentials.username],
            ['password', credentials.password]
        ];

        if (withoutClientIdSecret) {
            return parametersArray;
        } else {
            parametersArray.pushAll(
                [
                    ['client_id', this.config.clientId],
                    ['client_secret', this.config.clientSecret]
                ]
            );
            return parametersArray;
        }
    }
}
