/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IRootScopeService = angular.IRootScopeService;
import SettingKey from '../SettingKey';
import Setting from '../Setting';
import SettingStorage from '../SettingStorage';
import Option from '../Option';
import Theme from '../../theme/Theme';

/**
 * The setting that determines which CSS theme is currently used.
 */
export default class ThemeSetting extends Setting<Theme> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$q', 'settingStorage', '$rootScope'];

    /**
     * Constructs a new instance of the ThemeSetting class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param settingStorage The object that handles persistent storage of the setting value.
     * @param $rootScope The Angular $rootScope that can be used to broadcast events throughout the app.
     */
    constructor($q: IQService, settingStorage: SettingStorage, $rootScope: IRootScopeService) {
        super($q, settingStorage, $rootScope, SettingKey.themeSetting, 'Theme', [
            new Option('Day', Theme.day),
            new Option('Night', Theme.night)
        ]);
    }
}
