/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Inject, Injectable } from '@angular/core';
import { Headers, Http, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { Credentials } from './credentials';
import { AuthenticationParameterService } from './parameters/authentication-parameter.service';
import { UrlFactory } from './url';
import { PasswordExpirationService } from './password-expiration.service';
import IonicPopupService = ionic.popup.IonicPopupService;
import { errorMessages, statusToErrorMessageMap, passwordExpirationStatuses, passwordExpirationMessages, passwordPolicyStatuses,
    changePasswordStatuses, changePasswordTitles, changePasswordMessages, pathMap, formValidationErrors, accountStateMessages } from './api-password-expiration.constants';
import { logErrorIfLoginIsVerbose, logIfLoginIsVerbose } from '../api/analytics/firebase-crashlytics-service';

/**
 * An implementation of the the PasswordExpirationService that check password expiration info from the Spillman API.
 */
@Injectable()
export class ApiPasswordExpirationService implements PasswordExpirationService<Credentials> {
    /**
     * The stored response status to show an alert message
     */
    public respStatus: string;
    /**
     * Constructs a new instance of the PasswordExpirationService.
     */
    constructor(
        private http: Http,
        private urlFactory: UrlFactory,
        private parameterService: AuthenticationParameterService,
        @Inject('$ionicPopup') private $ionicPopup: IonicPopupService
    ) {
    }

    /**
     * @inheritdoc
     */
    public checkPassword(credentials: Credentials): Observable<{ message: string }>  {
        const url = this.urlFactory.create({
            server: credentials.server,
            port: credentials.port,
            secureConnection: credentials.secureConnection,
            path: pathMap.passexp
        });
        logIfLoginIsVerbose('checking password');

        const params = this.parameterService.getParameters(credentials);
        const headers = this.setHeaders();

        return this.http.post(url, params, {headers})
            .timeout(20 * 1000)
            .map((response: Response) => {
                const passwordStatus: { status: string } = response.json();
                return this.checkPasswordStatus(passwordStatus, credentials);
            })
            .catch(err => Observable.throw(this.getErrorMessage(err)));
    }

    /**
     * Callback on clicked 'change password" button, subscribe on password change request
     * @param credentials object that contains saved credentials
     */
    public onChangePassword = (credentials: any) => {
        const serviceScope = this;
        const formTemplate = require('./password-change/password-change-template.html');
        const options = {
            template: formTemplate,
            title: changePasswordTitles.passwordChange,
            buttons: [
                { text: 'Cancel' },
                { text: 'Save', type: 'button-positive',
                    onTap: function(event: any) {

                        const isValid = serviceScope.validatePassword(this.scope.changePasswordForm, credentials.password);
                        if (!isValid) {
                            event.preventDefault();
                            return;
                        }
                        const params = serviceScope.parameterService.getParameters(credentials);
                        const pairs = [
                            ['username', credentials.username],
                            ['password', credentials.password],
                            ['new_password', this.scope.changePasswordForm.password.$modelValue],
                            ['client_id', params.paramsMap.get('client_id')[0]],
                            ['client_secret', params.paramsMap.get('client_secret')[0]]
                        ];
                        const newParams = new URLSearchParams();
                        pairs.forEach(p => newParams.set(p[0], p[1]));
                        serviceScope.onSavePassword(newParams, credentials).subscribe((opt: any) => {
                            const optLowercase = opt.template.toLowerCase().trim();
                            if (passwordPolicyStatuses.indexOf(optLowercase) !== -1) {
                                const passStatus = opt.template;
                                options.template =  `<div class='custom-info-msg error popup-info'>
                                                        ${changePasswordMessages.passwordWasNotChanged} ${passStatus}.
                                                    </div>` + formTemplate;

                                serviceScope.$ionicPopup.alert(options);
                            } else {
                                serviceScope.$ionicPopup.alert(opt);
                            }
                        });
                    }
                }
            ]
        };
        this.$ionicPopup.alert(options);
    };

    /**
     * Send request for password change
     * @param params URLSearchParams that contains query parameters for password change request
     */
    public onSavePassword = (params: any, credentials: Credentials) => {
        const url = this.urlFactory.create({
            server: credentials.server,
            port: credentials.port,
            secureConnection: credentials.secureConnection,
            path: pathMap.passchange
        });
        const headers = this.setHeaders();

        let errOptions = {
            template: '',
            title: changePasswordTitles.passwordChange
        };

        return this.http.post(url, params, { headers })
            .map((response: Response) => {
                const status: { status: string } = response.json();
                const passwordStatus = this.checkPasswordStatus(status, params, true) || {};

                // eslint-disable-next-line dot-notation,@typescript-eslint/dot-notation
                return passwordStatus['options'];
            })
            .catch(err => Observable.throw(
                errOptions.template = (err.error && err.error.includes('404')) ? errorMessages.status404 : this.getCommonErrorMessage(err)
            ))
            .finally(() => {
                if (errOptions.template.length > 0) {
                    this.$ionicPopup.alert(errOptions);
                }
            });
    };

    /**
     * Set request headers
     */
    public setHeaders = () => {
        const headers = new Headers();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');
        return headers;
    };

    /**
     * A helper method that converts the a raw error to a human-friendly error message.
     *
     * @param error The raw error.
     * @returns The human-friendly error message.
     */
    private getErrorMessage(error: any): string {
        logErrorIfLoginIsVerbose('Password expiration error', error);

        if (error instanceof Response && typeof error.status === 'number') {
            return statusToErrorMessageMap.get(error.status) || errorMessages.default;
        }
        return errorMessages.default;
    }

    private getCommonErrorMessage(error: any): string {
        if (error instanceof Response && typeof error.status === 'number') {
            return statusToErrorMessageMap.get(error.status) || errorMessages.defaultCommon;
        }
        return errorMessages.defaultCommon;
    }
    /**
     * A helper method that parse server response and return message according to user password or credentials status.
     *
     * @param passwordStatus The status received from the server.
     * @returns The human-friendly error message.
     */

    private checkPasswordStatus(passwordStatus: { status: string }, credentials: any, isPasswordChangeMode?: boolean): Object {
        const status = passwordStatus.status.toLowerCase().trim();
        let error = '';
        let options = {
            title: '',
            template: '',
            buttons: <any>[
                {
                    text: 'OK',
                    type: 'button-positive'
                }
            ],
            cssClass: ''
        };
        let enableModal = true;
        switch (true) {
            case status.includes(passwordExpirationStatuses.ok):
                options.template = '';
                enableModal = false;
                break;
            case status.includes(passwordExpirationStatuses.requestIncorrect) && !isPasswordChangeMode:
                options.template = '';
                enableModal = false;
                break;
            case status.includes(passwordExpirationStatuses.accountLocked):
                options.template = accountStateMessages.accountLocked;
                error = status;
                break;
            case status.includes(passwordExpirationStatuses.passwordExpired):
                options.template = passwordExpirationMessages.passwordExpired;
                error = status;
                break;
            case status.includes(passwordExpirationStatuses.expireIn):
                const days = Number(status.match(/\d/g).join(''));
                const dayWord = days === 1 ? 'day' : 'days';
                options.template = days === 0 ? passwordExpirationMessages.passwordExpiresToday
                    : `You have ${days}-${dayWord} before your password expires.`;
                options.buttons.push({
                    text: 'Change Password',
                    type: 'button-positive',
                    onTap: () => {
                        this.onChangePassword(credentials);
                    }
                });
                options.title = changePasswordTitles.passwordExpire;
                options.cssClass = 'psw-expiration-dialog';
                break;
            case status === changePasswordStatuses.passwordChanged:
                options.title = changePasswordTitles.passwordChange;
                options.template = changePasswordMessages.passwordChanged;
                break;
            case status === changePasswordStatuses.passwordNotChanged:
                options.title = changePasswordTitles.passwordChange;
                options.template = changePasswordMessages.passwordNotChanged;
                break;
            case status === changePasswordStatuses.requestIncorrect:
                options.title = changePasswordTitles.passwordChange;
                options.template = changePasswordMessages.passwordNotChanged;
                break;
            case status === changePasswordStatuses.tRecordLock:
                options.title = changePasswordTitles.passwordChange;
                options.template = changePasswordMessages.passwordNotChanged;
                break;
            case status === changePasswordStatuses.notAllowChangePass:
                options.title = changePasswordTitles.passwordChange;
                options.template = changePasswordMessages.passwordNotAllowed + ' ' + changePasswordMessages.pleaseContactAdmin;
                break;
            case passwordPolicyStatuses.indexOf(status) !== -1:
                options.template = passwordStatus.status;
                break;
            default:
                options.template = (enableModal) ? '' : passwordStatus.status + ' ' + changePasswordMessages.pleaseContactAdmin;
                break;
        }
        return  { options, error };
    }

    /**
     * Change password form validation logic
     * @param form change password form object
     * @param password old password
     */
    private validatePassword = (form: any, password: string) => {
        switch (true) {
            // in case some field is empty
            case form.$invalid:
                if (!form.password.$modelValue) {
                    form.password.error = true;
                }
                if (!form.confirmPassword.$modelValue) {
                    form.confirmPassword.error = true;
                }
                form.message = formValidationErrors.required;
                return false;
            // in case passwords not match
            case form.password.$modelValue !== form.confirmPassword.$modelValue:
                form.message = formValidationErrors.match;
                form.confirmPassword.error = true;
                form.password.error = true;
                return false;
            // in case new password is the same as old
            case password === form.password.$modelValue || password === form.confirmPassword.$modelValue:
                form.message = formValidationErrors.new;
                form.password.error = password === form.password.$modelValue;
                form.confirmPassword.error = password === form.confirmPassword.$modelValue;
                return false;
            default:
                return true;
        }
    };
}
