/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IScope = angular.IScope;
import Location from './Location';
import MappingUtilities from './MappingUtilities';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
MapLinkDirective.$inject = ['mappingUtilities'];

/**
 *
 * A factory function that creates the MapLinkDirective.
 *
 * @param mappingUtilities A utility class that provides methods for working with maps and locations.
 * @returns {IDirective} A directive that creates a link that will open the device's native maps app to a specified location.
 */
export default function MapLinkDirective(mappingUtilities: MappingUtilities): IDirective {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            location: '='
        },
        template: `<a ng-href="{{mapUrl}}">
                     <ng-transclude></ng-transclude>
                   </a>`,
        link: ($scope: MapLinkScope) => {
            $scope.$watch('location', (newLocation: Location) => {
                let address = mappingUtilities.tryGetAddressOrLatLng(newLocation, true);
                $scope.mapUrl = address ? mappingUtilities.getMapsAppProtocol() + encodeURIComponent(address) : undefined;
            }, true);
        }
    };
}

/**
 * An interface that defines the scope for the MapLinkDirective.
 */
interface MapLinkScope extends IScope {

    /**
     * The location to which to open the native maps app.
     */
    location: Location;

    /**
     * The URL used to open the native maps app.
     */
    mapUrl: string;
}
