/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import { SlideSourceInfo } from '../../../schema/SlideSourceInfo';

/**
 * An angular 2 wrapper component for sds-detail-slides which creates an ionic slide control.
 */
@Component({
    selector: 'sds-display-slides',
    template: '<sds-detail-slides [slides]="slides" name="{{name}}" [model]="model"></sds-detail-slides>',
    styles: [
        require('./display-slides.component.scss'),
        require('./display-slides.component.day.scss'),
        require('./display-slides.component.night.scss')
    ]
})
export class DisplaySlidesComponent {

    /**
     * The slides to use.
     */
    @Input() public slides: Map<string, SlideSourceInfo>;

    /**
     * The name of the model.
     */
    @Input() public name: string;

    /**
     * The model to use.
     */
    @Input() public model: Object;
}
