/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { SpillmanLocalStorage } from '../../../shared/storage/spillman-local-storage';
import FiltersStorage from '../../filtering/storage/FiltersStorage';
import { CALL_BUCKET } from '../../filtering/consts';

/**
 * A class that handles persistent storage of the user's custom filter configuration.
 */
export default class CallFiltersStorage extends FiltersStorage {

    public BUCKET: string;

    // @override
    public ALL_SAVED_FILTER_NAMES = 'all_saved_filter_names';

    /**
     * Constructs a new instance of the FiltersStorage class.
     *
     * @param spillmanLocalStorage The object that handles storing Spillman-specific data in local storage.
     */
    constructor(spillmanLocalStorage: SpillmanLocalStorage) {
        super(spillmanLocalStorage);
        this.BUCKET = CALL_BUCKET;
        this.deleteUnsavedFilterIfExist();
    }
}
