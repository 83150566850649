/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */
import { AfterViewInit, Directive, ElementRef, Inject } from '@angular/core';
import { createPopover } from './popover-create-helpers';

@Directive({
    selector: '[sdsTextWrappedPopover]'
})
/**
 * Creates a directive that will validate that some text is wrapped an by click on it some
 * popover with fill text will appear.
 *
 *  A directive that validates that the text is wrapped.
 *
 * This directive is used for Angular 2 components
 */
export class TextWrappedPopoverDirective implements AfterViewInit {
    constructor(private elementRef: ElementRef,
        @Inject('$ionicPopover') private $ionicPopover: ionic.popover.IonicPopoverService
    ) {}

    public ngAfterViewInit(): void {

        const element = this.elementRef.nativeElement;
        createPopover({element}, this.$ionicPopover);
    }
}
