/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { UpgradeAdapter } from '@angular/upgrade';

// TODO: Currently, there's an issue where we can't have circular references when using
// the UpgradeAdapter and nested ngModules. One way to work around this is to use a DummyModule
// initially and then replace it with the real module later on.
// See https://github.com/angular/angular/issues/11069#issuecomment-244549445
@NgModule({})
class DummyModule { }

export const upgradeAdapter = new UpgradeAdapter(DummyModule);
