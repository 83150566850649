/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import ILogService = angular.ILogService;
import IUploadPromise = angular.angularFileUpload.IUploadPromise;
import IUploadService = angular.angularFileUpload.IUploadService;
import IStateService = angular.ui.IStateService;
import AddAttachmentStateParams from './AddAttachmentStateParams';
import AddAttachmentScope from './AddAttachmentScope';
import TableCache from '../../../../shared/TableCache';
import UploadData from './UploadData';
import { UrlFactory } from '../../../../authentication';
import AddAttachmentConfig from './AddAttachmentConfig';
import TimedAlert from '../../../../shared/TimedAlert';
import IonicLoadingService = ionic.loading.IonicLoadingService;
import IonicPopupService = ionic.popup.IonicPopupService;
import IonicHistoryService = ionic.navigation.IonicHistoryService;

/**
 * A base class for controllers that save attachments to the database.
 */
export default class AddAttachmentController<T> {

    /**
     * The URL to which to upload the attachments.
     */
    private url: string;

    /**
     * Constructs a new instance of the AddAttachmentController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param $state The service that transitions between states.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     * @param $q The angular service that handles creating and working with promises.
     * @param $log The Angular service that performs logging.
     * @param timedAlert An alert that will automatically close after a specified amount of time.
     * @param typeCache The cache that stores types that the user can choose from for this particular kind of attachment.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param uploadService A service provided by the ng-file-upload library that handles file uploads.
     * @param $ionicLoading An overlay that displays a spinner while waiting for the incidents to be retrieved.
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param $ionicHistory The service that manages the user's browsing history.
     * @param config The configuration that specifies how certain things are
     */
    constructor(
        $scope: AddAttachmentScope<T>,
        private $state: IStateService,
        private $stateParams: AddAttachmentStateParams,
        private $q: IQService,
        private $log: ILogService,
        private timedAlert: TimedAlert,
        typeCache: TableCache<T>,
        urlFactory: UrlFactory,
        private uploadService: IUploadService,
        private $ionicLoading: IonicLoadingService,
        private $ionicPopup: IonicPopupService,
        private $ionicHistory: IonicHistoryService,
        private config: AddAttachmentConfig
    ) {
        this.url = urlFactory.create({
            path: `/tables/${$stateParams.table}/${$stateParams.id}/${config.apiSuffix}`
        });
        $scope.form = {};
        $scope.attachments = $stateParams.attachments;
        $scope.data = <UploadData>{};
        $scope.types = typeCache.array;
        $scope.config = config;
        $scope.save = this.save;
        $scope.cancel = () => $ionicHistory.goBack();
        $scope.table = $stateParams.table;
        $scope.title = $stateParams.title;
    }

    /**
     * Saves the data entered by the user.
     *
     * @param data The form data entered by the user.
     */
    private save = (data: UploadData): void => {
        this.$ionicLoading.show({ template: '<ion-spinner></ion-spinner>' });
        let uploadPromises = this.$stateParams.attachments.map(a => this.upload(a, data.type, data.description));

        this.$q.all(uploadPromises)
            .then(this.successHandler)
            .catch(this.errorHandler)
            .finally(() => this.$ionicLoading.hide());
    };

    /**
     * Uploads a single file to the server.
     *
     * @param file The file to be uploaded.
     * @param type The code that identifies the image or file type
     *             (i.e. a value from either the imgtype table for images or the fctype table for files).
     * @param description A description for the image for file.
     * @returns {IUploadPromise<T>} A promise that will be resolved when the file has been uploaded.
     */
    private upload(file: File, type: string, description: string): IUploadPromise<any> {
        return this.uploadService.upload({
            url: this.url,
            method: 'POST',
            data: {
                file: file
            },
            params: {
                type: type,
                description: description
            }
        });
    }

    /**
     * A handler that is called after the attachments have been successfully saved.
     */
    private successHandler = (): void => {
        // Figure out which type of attachment was saved.
        let attachmentType = this.config.title;

        // Make it plural if necessary.
        if (this.$stateParams.attachments.length > 1) {
            attachmentType += 's';
        }

        this.timedAlert.show(`${attachmentType} saved`, () => {
            // Set allowDataRequest as true to indicate that request by data should be done
            // $state.go can't be used here as creates view with new id and causes incorrect construction of ionicHistory tree
            this.$state.get('app.attachments.attachmentsList').params.allowDataRequest = true;

            this.$ionicHistory.goBack();
        });
    };

    /**
     * A handler that is called if the attachments failed to be saved.
     *
     * @param error The error object that contains information about what went wrong.
     */
    private errorHandler = (error: any): void => {
        this.$log.error(error);
        this.$ionicPopup.alert({ template: 'An error occurred. Please try again.' });
    };
}
