/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import EnumEnumerator from './EnumEnumerator';
import ShowWithCallback from './directives/ShowWithCallback';
import AutoTabDirective from './directives/AutoTabDirective';
import TabbableIfCurrentSlideDirective from './directives/TabbableIfCurrentSlideDirective';
import RadioLogDirective from './directives/RadioLogDirective';
import RadioLogListDirective from './directives/RadioLogListDirective';
import LocalStorage from './storage/local-storage';
import { SpillmanLocalStorage } from './storage/spillman-local-storage';
import CityCache from './caching/CityCache';
import FileTypeCache from './caching/FileTypeCache';
import ImageTypeCache from './caching/ImageTypeCache';
import TenCodeCache from './caching/TenCodeCache';
import AndroidDocumentsDirectoryFinder from './fileManagement/AndroidDocumentsDirectoryFinder';
import IOSDocumentsDirectoryFinder from './fileManagement/IOSDocumentsDirectoryFinder';
import DocumentsDirectoryFinder from './fileManagement/DocumentsDirectoryFinder';
import FileUtilities from './fileManagement/FileUtilities';
import ThumbnailDirective from './directives/ThumbnailDirective';
import GoogleStaticMapDirective from './mapping/GoogleStaticMapDirective';
import MapLinkDirective from './mapping/MapLinkDirective';
import MappingUtilities from './mapping/MappingUtilities';
import MultiInjector from './MultiInjector';
import DecoratorToResponseTransformAdapter from './transforms/DecoratorToResponseTransformAdapter';
import TimedAlert from './TimedAlert';
import { AutocompleteDirective } from './directives/AutocompleteDirective';
import TimeAgoDirective from './directives/TimeAgoDirective';
import EvalDirective from './directives/EvalDirective';
import IfValidPhoneNumberDirective from './directives/IfValidPhoneNumberDirective';
import AddressAlertsPopupDirective from './directives/AddressAlertsPopupDirective';
import { ExpandingPopupDirective } from './directives/ExpandingPopupDirective';
import FalsyEqualityComparer from './comparison/FalsyEqualityComparer';
import StandardEqualityComparer from './comparison/StandardEqualityComparer';
import CollapsibleContainerDirective from './directives/CollapsibleContainerDirective';
import FilterBarDirective from './directives/FilterBarDirective';
import ContainsTextFilter from './filters/ContainsTextFilter';
import InfiniteScrollDirective from './directives/InfiniteScrollDirective';
import GuaranteedTimeoutFactory from './guaranteedTimeout/GuaranteedTimeoutFactory';
import CodeDescriptionFilter from './filters/CodeDescriptionFilter';
import DetailSlidesDirective from './directives/DetailSlidesDirective';
import PermissionsHideDirective from './directives/PermissionsHideDirective';
import PermissionName from '../permissions/enums/PermissionName';
import HeaderTabDirective from './directives/HeaderTabDirective';
import HeaderTabsDirective from './directives/HeaderTabsDirective';
import messageComponent from './components/message/message.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { upgradeAdapter } from '../upgrade-adapter';
import { TabsDirective } from './directives/TabsDirective';
import { AlertsDisplayComponent } from './alerts/alerts-display.component';
import { displayListComponent } from './layout/display-list/display-list.component';
import { scrollableListComponent } from './components/scrollable-list/scrollable-list.component';
import { AddressPipe, DatePipe, DateTimePipe, GenderPipe, JoinPipe, TimePipe, BooleanPipe, CleanPipe } from './pipes';
import { PipeFilter } from './filters/pipe.filter';
import { CallAddressComponent } from './components/call-address/call-address.component';
import  TextWrappedPopoverDirective from './directives/TextWrappedPopoverDirectives/TextWrappedPopoverDirective';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { DeepLinkService } from './deeplink/deep-link-service';
import { textWithPhoneLinkComponent } from './integration-1021/components/text-with-phone-link/text-with-phone-link.component';

export const sharedModule = angular.module('shared', [])
    // The format in which the server sends date/time information.
    .constant('serverDateTimeFormat', 'YYYY-MM-DD HH:mm:ss.SSSZ')

    // A configurable used with the TimeAgoDirective.
    .constant('timeAgoPrecision', { 'mm': 1, 'hh': 1, 'dd': 1, 'yy': 1 })

    // The default amount of time to show the TimedAlert.
    .constant('timedAlertDefaultDuration', 2 * 1000) // 2 seconds

    // Components
    .component('sdsMessage', messageComponent)
    .component('sdsDisplayList', displayListComponent)
    .component('sdsScrollableList', scrollableListComponent)
    .component('sdsTextWithPhoneLink', textWithPhoneLinkComponent)

    // directives
    .directive('showWithCallback', ShowWithCallback)
    .directive('autoTab', AutoTabDirective)
    .directive('tabbableIfCurrentSlide', TabbableIfCurrentSlideDirective)
    .directive('radioLog', RadioLogDirective)
    .directive('radioLogList', RadioLogListDirective)
    .directive('thumbnail', ThumbnailDirective)
    .directive('googleStaticMap', GoogleStaticMapDirective)
    .directive('mapLink', MapLinkDirective)
    .directive('spillmanAutocomplete', AutocompleteDirective)
    .directive('sdsTimeAgo', TimeAgoDirective)
    .directive('sdsEval', EvalDirective)
    .directive('sdsIfValidPhoneNumber', IfValidPhoneNumberDirective)
    .directive('sdsAddressAlertsPopup', AddressAlertsPopupDirective)
    .directive('sdsExpandingPopup', ExpandingPopupDirective)
    .directive('sdsCollapsibleContainer', CollapsibleContainerDirective)
    .directive('sdsFilterBar', FilterBarDirective)
    .directive('sdsInfiniteScroll', InfiniteScrollDirective)
    .directive('sdsDetailSlides', DetailSlidesDirective)
    .directive('sdsPermissionsHide', PermissionsHideDirective)
    .directive('sdsHeaderTab', HeaderTabDirective)
    .directive('sdsHeaderTabs', HeaderTabsDirective)
    .directive('sdsTabs', TabsDirective)
    .directive('sdsAlertsDisplay', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(AlertsDisplayComponent))
    .directive('sdsNoResults', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(NoResultsComponent))
    .directive('sdsCallAddress', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(CallAddressComponent))
    .directive('sdsScrollToTop', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(ScrollToTopComponent))
    .directive('sdsTextWrappedPopover', TextWrappedPopoverDirective)
    // filters
    .serviceAsFilter('containsTextFilter', ContainsTextFilter)
    .serviceAsFilter('codeDescription', CodeDescriptionFilter, 'display')
    .serviceAsFilter('sdsPipe', PipeFilter, 'transform')

    // Shareable Slides
    .value('involvementsSlideSourceInfo', ['Involvements', { srcHtml: 'app/rms/shared/slides/involvements.html', permissions: { or: true, permissions: [PermissionName.invl] } }])

    // Services
    .value('enumEnumerator', EnumEnumerator)
    .service('localStorage', LocalStorage)
    .factory('spillmanLocalStorage', upgradeAdapter.downgradeNg2Provider(SpillmanLocalStorage))
    .service('cityCache', CityCache)
    .service('fileTypeCache', FileTypeCache)
    .service('imageTypeCache', ImageTypeCache)
    .service('tenCodeCache', TenCodeCache)
    .service('androidDocumentsDirectoryFinder', AndroidDocumentsDirectoryFinder)
    .service('iOSDocumentsDirectoryFinder', IOSDocumentsDirectoryFinder)
    .service('documentsDirectoryFinder', DocumentsDirectoryFinder)
    .service('fileUtilities', FileUtilities)
    .service('mappingUtilities', MappingUtilities)
    .service('multiInjector', MultiInjector)
    .service('decoratorToResponseTransformAdapter', DecoratorToResponseTransformAdapter)
    .service('timedAlert', TimedAlert)
    .service('falsyEqualityComparer', FalsyEqualityComparer)
    .service('standardEqualityComparer', StandardEqualityComparer)
    .service('guaranteedTimeoutFactory', GuaranteedTimeoutFactory)
    .service('sdsAddress', upgradeAdapter.downgradeNg2Provider(AddressPipe))
    .service('sdsGender', upgradeAdapter.downgradeNg2Provider(GenderPipe))
    .service('sdsJoin', upgradeAdapter.downgradeNg2Provider(JoinPipe))
    .service('sdsDate', upgradeAdapter.downgradeNg2Provider(DatePipe))
    .service('sdsDateTime', upgradeAdapter.downgradeNg2Provider(DateTimePipe))
    .service('sdsTime', upgradeAdapter.downgradeNg2Provider(TimePipe))
    .service('sdsBoolean', upgradeAdapter.downgradeNg2Provider(BooleanPipe))
    .service('sdsClean', upgradeAdapter.downgradeNg2Provider(CleanPipe))
    .service('deepLinkService', upgradeAdapter.downgradeNg2Provider(DeepLinkService));

upgradeAdapter.upgradeNg1Provider('tenCodeCache');
upgradeAdapter.upgradeNg1Provider('involvementsSlideSourceInfo');
upgradeAdapter.upgradeNg1Provider('serverDateTimeFormat');
upgradeAdapter.upgradeNg1Provider('fileUtilities');
upgradeAdapter.upgradeNg1Provider('documentsDirectoryFinder');
upgradeAdapter.upgradeNg1Provider('textWrappedPopover');
