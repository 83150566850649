/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Type } from '@angular/core';
import { ConditionalInjectionFilter } from './conditional-injection-filter';
import { PlatformDetector } from '../platform-detection/platform-detector.service';
import { Platform } from '../platform-detection/platform';
import { InjectionCondition } from '../injection-condition';

/**
 * A filter that returns those services that can be used on the current platform.
 */
@Injectable()
export class PlatformConditionalInjectionFilter extends ConditionalInjectionFilter<'platforms', Platform[]> {

    /**
     * @inheritdoc
     */
    protected readonly propertyName = 'platforms';

    /**
     * @inheritdoc
     */
    protected readonly defaultValue: Platform[] = ['android', 'browser', 'ios'];

    /**
     * Constructs a new instance of the PlatformConditionalInjectionFilter class.
     *
     * @param platformDetector The object that detects the current platform.
     */
    constructor(private platformDetector: PlatformDetector) {
        super();
    }

    /**
     * @inheritdoc
     */
    protected doParsing(rawValue: InjectionCondition['platforms']): Platform[] {
        return rawValue;
    }

    /**
     * @inheritdoc
     */
    protected doFiltering(tuples: [Platform[], Type<any>][]): [Platform[], Type<any>][] {
        const platform = this.platformDetector.getPlatform();
        return tuples.filter(t => t[0].some(p => p === platform));
    }
}
