/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import LogicalOrFilter from '../../filtering/baseClasses/LogicalOrFilter';
import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import MyCallsFilter from './MyCallsFilter';
import FilterByZone from '../../filtering/filters/FilterByZone';

/**
 * Filters the calls so that only those whose zone is the same as the logged-in user's, will be shown.
 * As with all call filters, any calls that are assigned to the user will be shown no matter what -
 * they cannot be filtered out.
 */
export default class CallFilterByZone extends LogicalOrFilter<AggregatorCadCall> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['myCallsFilter', 'filterByZone'];

    /**
     * Constructs a new instance of the CallFilterByZone class.
     *
     * @param myCallsFilter The filter that includes any calls assigned to the current user.
     * @param filterByZone The filter that includes any calls that have the same zone as the current user.
     */
    constructor(myCallsFilter: MyCallsFilter, filterByZone: FilterByZone) {
        super(myCallsFilter, filterByZone);
    }
}
