/* Copyright © 2021 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { CustomNotificationStyle } from './custom-notification-style';
import { CustomNotificationOptions } from './custom-notification-options';

/**
 * CustomNotificationBuilder this service created to display notifications
 */

@Injectable()
export class CustomNotificationBuilder {
    // DOM element for displaying notifications
    private readonly toast: HTMLElement;
    // notification default styles
    private readonly defaultStyles = {
        fontSize: 10,
        opacity: 1,
        backgroundColor: '#fff',
        color: '#000',
        horizontalPadding: 10,
        verticalPadding: 10,
        textAlign: 'left'
    };
    // Notification element position top or bottom
    private toastElPositionClass: string;
    // Class that added to the toast element to display notification from the top or bottom
    private appearElPositionClass: string;
    // duration timeout
    private timeout: any;

    constructor() {
        // create and add the toast element in the DOM
        this.toast = document.createElement('div');
        this.toast.classList.add('custom-toast');
        this.toast.classList.add(this.toastElPositionClass);
        document.body.appendChild(this.toast);
    }

    /**
     * showNotification - used to create and display the toast notification to the user
     * @param options - parameters and data that used for creating the toast notification
     * @param onNotificationClick - callback function that can be called if the user click/tap on the toast notification
     */
    public showNotification(options: CustomNotificationOptions, onNotificationClick: Function) {
        // timeForStyleReRender - this time interval in ms needed to rerender style at the page
        const timeForStyleReRender = 50;
        if (this.toastElPositionClass) {
            this.toast.classList.remove(this.toastElPositionClass);
        }
        this.toastElPositionClass = options.position === 'bottom' ? 'custom-toast__position-bottom' : 'custom-toast__position-top';
        this.toast.classList.add(this.toastElPositionClass);

        this.appearElPositionClass = options.position === 'bottom' ? 'custom-toast__appear-bottom' : 'custom-toast__appear-top';

        this.toast.innerText = options.message;

        this.setNotificationStyles(options.styling);

        setTimeout(() => {
            this.toast.classList.add(this.appearElPositionClass);
        }, timeForStyleReRender);

        const durationTimeout = (options.duration ? options.duration : 3000) + timeForStyleReRender;

        this.timeout = setTimeout(() => {
            this.disappear();
        }, durationTimeout);

        this.toast.ontouchstart = () => {
            onNotificationClick();
            this.disappear();
            clearTimeout(this.timeout);
        };
    }

    /**
     *  hideNotification - used to clear notification timeout and hide the notification
     */
    public hideNotification() {
        clearTimeout(this.timeout);
        this.disappear();
    }

    /**
     *  hideNotification - used to hide the notification
     */
    private disappear() {
        if (this.appearElPositionClass) {
            this.toast.classList.remove(this.appearElPositionClass);
        }

        if (this.toast) {
            // eslint-disable-next-line no-empty,no-empty-function,@typescript-eslint/no-empty-function
            this.toast.ontouchstart = () => {};
        }
    }

    /**
     *  hideNotification - used to hide the notification
     *  @param styling - CSS styles to customize the notification style
     */
    private setNotificationStyles(styling: CustomNotificationStyle) {
        let toastStyle: CustomNotificationStyle = {};
        Object.keys(this.defaultStyles).forEach((key: string) => {
            toastStyle[key] = styling && styling.hasOwnProperty(key) ? styling[key] : this.defaultStyles[key];
        });

        this.toast.style.fontSize = `${toastStyle.fontSize}px`;
        this.toast.style.paddingLeft = `${toastStyle.horizontalPadding}px`;
        this.toast.style.paddingRight = `${toastStyle.horizontalPadding}px`;
        this.toast.style.paddingTop = `${toastStyle.verticalPadding}px`;
        this.toast.style.paddingBottom = `${toastStyle.verticalPadding}px`;
        this.toast.style.background = toastStyle.backgroundColor;
        this.toast.style.color = toastStyle.color;
        this.toast.style.textAlign = toastStyle.textAlign;
    }
}
