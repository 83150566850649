/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IInjectorService = angular.auto.IInjectorService;
import { QueryFactory } from '../../../query-factory';
import UnitQuery from './UnitQuery';
import AgencyUnitQuery from './AgencyUnitQuery';
import GeoCoordinatesUnitQuery from './GeoCoordinatesUnitQuery';
import OfficersUnitQuery from './OfficersUnitQuery';
import RadioLogsUnitQuery from './RadioLogsUnitQuery';
import ZoneUnitQuery from './ZoneUnitQuery';

/**
 * The array of constructor functions for all unit queries.
 */
const unitQueryTypes = [
    AgencyUnitQuery,
    GeoCoordinatesUnitQuery,
    OfficersUnitQuery,
    RadioLogsUnitQuery,
    ZoneUnitQuery
];

/**
 * The factory that creates all queries for the unit details screen.
 */
export class UnitQueryFactory implements QueryFactory<UnitQuery<any>> {

    public static $inject = ['$injector'];

    constructor(private $injector: IInjectorService) { }

    /**
     * @inheritdoc
     */
    public create(): UnitQuery<any>[] {
        // It is essential that we use `$injector.instantiate` rather than `$injector.get`
        // so that new instances are created each time.
        return unitQueryTypes.map(type => this.$injector.instantiate<UnitQuery<any>>(type));
    }
}
