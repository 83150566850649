/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { upgradeAdapter } from '../upgrade-adapter';
import { SpillmanLocalStorage } from './storage/spillman-local-storage';
import { DepthFirstTraversal } from './data-structures/depth-first-traversal';
import { MODEL_TRAVERSAL_PROVIDERS } from './database/model-traversal/model-traversal-providers';
import { DatabaseService } from './database/database.service';
import { SHARED_PIPE_PROVIDERS } from './pipes/providers';
import { AddressPipe, BooleanPipe, CleanPipe, ContainsWordPipe, DatePipe, DatePipePolyfill, DateTimePipe, GenderPipe, JoinPipe, TimePipe } from './pipes';
import { AlertsDisplayComponent } from './alerts/alerts-display.component';
import { DisplayFieldComponent, DisplaySectionComponent, DisplaySlidesComponent } from './layout';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { CallAddressComponent } from './components/call-address/call-address.component';
import { TextWrappedPopoverDirective } from './directives/TextWrappedPopoverDirectives/TextWrappedPopoverDirectiveNg2';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { CacheService } from './services/cacheService';
import { DeepLinkService } from './deeplink/deep-link-service';
import { CacheExpireService } from './services/cacheExpireService';
/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-denylist,id-match */
const DetailSlidesComponent = upgradeAdapter.upgradeNg1Component('sdsDetailSlides');
const DisplayListComponent = upgradeAdapter.upgradeNg1Component('sdsDisplayList');
const MessageComponent = upgradeAdapter.upgradeNg1Component('sdsMessage');
const ScrollableListComponent = upgradeAdapter.upgradeNg1Component('sdsScrollableList');
/* eslint-enable @typescript-eslint/naming-convention,no-underscore-dangle,id-denylist,id-match */

@NgModule({
    imports: [CommonModule],
    declarations: [
        AddressPipe,
        AlertsDisplayComponent,
        BooleanPipe,
        CallAddressComponent,
        CleanPipe,
        ContainsWordPipe,
        DatePipe,
        DatePipePolyfill,
        DateTimePipe,
        DetailSlidesComponent, // Does not need to be exported.
        DisplayFieldComponent,
        DisplayListComponent,
        DisplaySectionComponent,
        DisplaySlidesComponent,
        GenderPipe,
        JoinPipe,
        MessageComponent,
        NoResultsComponent,
        ScrollableListComponent,
        TimePipe,
        TextWrappedPopoverDirective,
        ScrollToTopComponent
    ],
    exports: [
        CommonModule,
        AddressPipe,
        AlertsDisplayComponent,
        BooleanPipe,
        CallAddressComponent,
        CleanPipe,
        ContainsWordPipe,
        DatePipe,
        DatePipePolyfill,
        DateTimePipe,
        DisplayFieldComponent,
        DisplayListComponent,
        DisplaySectionComponent,
        DisplaySlidesComponent,
        GenderPipe,
        JoinPipe,
        MessageComponent,
        NoResultsComponent,
        ScrollableListComponent,
        TimePipe,
        TextWrappedPopoverDirective,
        ScrollToTopComponent
    ],
    providers: [
        SpillmanLocalStorage,
        DepthFirstTraversal,
        MODEL_TRAVERSAL_PROVIDERS,
        DatabaseService,
        SHARED_PIPE_PROVIDERS,
        CacheService,
        CacheExpireService,
        DeepLinkService
    ]
})
export class SharedModule { }
