/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * The Bike model
 */
@table('lpbike')
export class Bike {

    @field('number')
    public id: string;

    @field('year')
    public year: number;

    @field('tire')
    public tireSize: string;

    @field('speeds')
    public speeds: number;

    @field('value')
    public value: number;

    @field('make')
    public make: string;

    @field('model')
    public model: string;

    @expand('framcol', 'desc')
    public frameColor: string;

    @expand('fendcol', 'desc')
    public fenderColor: string;

    @expand('trimcol', 'desc')
    public trimColor: string;

    @field('serial')
    public serialNumber: string;

    @field('applnum')
    public appliedNumber: string;

    @field('tiretyp')
    public tireType: string;

    @field('lights')
    public lights: string;

    @field('comment')
    public comment: string;
}
