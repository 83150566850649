/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import { CadCallReference } from '../../model/cad-call-reference';

/**
 * A component which displays a field which links to a cad call.
 */
@Component({
    selector: 'sds-cad-call-link',
    template: `
        <sds-permissions-link state="app.search.callDetails" [id]="cadCall.id" *ngIf="cadCall && cadCall.id">
            <span>{{cadCall.nature || cadCall.id}}</span>
        </sds-permissions-link>
        <span *ngIf="cadCall && !cadCall.id">{{cadCall}}</span>
        <span *ngIf="!cadCall">&nbsp;</span>
    `,
    styles: [
        require('./link.component.scss')
    ]
})
export class CadCallLinkComponent {

    /**
     * The cad call to display.
     */
    @Input() public cadCall: CadCallReference;
}
