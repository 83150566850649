/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import IResourceArray = angular.resource.IResourceArray;
import RadioLog from '../../../../schema/RadioLog';
import ResourceUtils from '../../../../resource/ResourceUtils';
import CallQuery from './CallQuery';

/**
 * Requests the Radio Logs associated with the call.
 */
export default class RadioLogsCallQuery extends CallQuery<IResourceArray<RadioLog>> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['radioLogAPI', 'resourceUtils'];

    /**
     * Constructs a new instance of the RadioLogsQuery class.
     *
     * @param radioLogAPI The API for retrieving radio logs from the server.
     * @param resourceUtils Provides utility methods for working with the Angular $resource service.
     */
    constructor(private radioLogAPI: IResourceClass<RadioLog>, private resourceUtils: ResourceUtils) {
        super();
    }

    // @Override
    protected get watchPropertyNames(): string | string[] {
        return ['callIdAndType', 'status'];
    }

    // @Override
    protected get updatePropertyName(): string {
        return 'radioLogs';
    }

    // @Override
    protected get defaultValue(): IResourceArray<RadioLog> {
        return this.resourceUtils.createResourceArray<RadioLog>();
    }

    // @Override
    protected performQuery(newValues: any[]): IResourceArray<RadioLog> {
        return this.radioLogAPI.query({ filter: 'callid=' + newValues[0].slice(0, -1) });
    }
}
