/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IncidentHistoryScope from './incidentHistoryScope';
import { gridColumns } from './IncidentHistoryConstants';
import { CallHistoryAdvancedFilterService } from '../components/call-history-advanced-filter/call-history-advanced-filter.service';

IncidentHistoryListDirective.$inject = ['callHistoryAdvancedFilterService'];
/**
 * A directive that displays a list of incident history records on the CAD Calls screen.
 */
export default function IncidentHistoryListDirective(callHistoryAdvancedFilterService: CallHistoryAdvancedFilterService): IDirective {
    return {
        restrict: 'E',
        scope: {
            incidents: '='
        },
        link: function (scope: IncidentHistoryScope) {

            scope.gridColumns = gridColumns;
            scope.isFilterEnabled = false;
            scope.isSpinnerShown = false;
            scope.LAW_INCIDENT = 'Law';
            scope.FIRE_INCIDENT = 'Fire';
            scope.EMS_INCIDENT = 'EMS';

            // set default filter values
            scope.incidentTypeState = {
                Law: false,
                Fire: false,
                EMS: false
            };

            // on receiving incidents list
            scope.$watch(() => {
                return scope.incidents;
            }, () => {
                if (scope.incidents && scope.incidents.length) {
                    callHistoryAdvancedFilterService.incidentsLocation = <any>scope.incidents[0].geoaddr;
                    scope.setIncidentsByType();
                    scope.setInitialIncidentsByType();
                    // define current incident type
                    scope.incidentType = Object.keys(scope.incidentsByType).find(key => scope.incidentsByType[key].length);
                    scope.setupTabView(scope.incidentType);
                }
            }, true);

            /**
             * set isSpinnerShown value on change
             */
            scope.$watch(() => {
                return callHistoryAdvancedFilterService.isSpinnerShown;
            }, (value: boolean) => {
                scope.isSpinnerShown = value;
            });

            /**
             * on receiving filtered / sorted list
             */
            scope.$on('incidentsListUpdate', (_event, response) => {
                if (response.data) {
                    scope.incidentsByType[scope.incidentType] = response.data;
                    scope.setupTabView(scope.incidentType);
                } else {
                    throw new Error(response.error);
                }
            });

            /**
             * incidents division by types
             */
            scope.setIncidentsByType = () => {
                /* eslint-disable dot-notation,@typescript-eslint/dot-notation */
                scope.incidentsByType = <any>{
                    [scope.LAW_INCIDENT]: scope.incidents['pager'].subResourceArrays[0],
                    [scope.FIRE_INCIDENT]: scope.incidents['pager'].subResourceArrays[1],
                    [scope.EMS_INCIDENT]: scope.incidents['pager'].subResourceArrays[2]
                };
            };

            /**
             * set initial incidents by type
             */
            scope.setInitialIncidentsByType = () => {
                scope.initialIncidentsByType = <any>{};
                Object.assign(scope.initialIncidentsByType, scope.incidentsByType);
            };

            /**
             * get incidents of certain type
             * @param incidentType string value of incident type
             */
            scope.getIncidentsByType = (incidentType: string) => {
                return scope.incidentsByType ? scope.incidentsByType[incidentType] : undefined;
            };

            /**
             * Set active tab
             * @param incidentType string value of incident type
             */
            scope.setActiveTab = (incidentType: string) => {
                Object.keys(scope.incidentTypeState).forEach(key => {
                    scope.incidentTypeState[key] = false; 
                });
                scope.incidentTypeState[incidentType] = true;
            };

            /**
             * Define active tub and it content
             * @param incidentType string value of incident type
             */
            scope.setupTabView = (incidentType: string) => {
                scope.setActiveTab(incidentType);
                scope.incidentsList = scope.getIncidentsByType(incidentType);
            };

            /**
             * define state url for each incidentin the list
             * @param incidentType string value of incident type
             */
            scope.getStateURL = (incidentType: string): string => {
                let link = 'app.search.';
                switch (incidentType) {
                    case scope.LAW_INCIDENT.toLowerCase():
                        link += 'lawIncidentDetails';
                        break;
                    case scope.FIRE_INCIDENT.toLowerCase():
                        link += 'fireIncidentDetails';
                        break;
                    case scope.EMS_INCIDENT.toLowerCase():
                        link += 'emsIncidentDetails';
                        break;
                    default: break;
                }
                return link;
            };

            /**
             * hide / show advanced filter
             */
            scope.toggleFilter = (): void => {
                scope.isFilterEnabled = !scope.isFilterEnabled;
            };

            /**
             * get count of incidents of certain type
             * @param incidentType string value of incident type
             */
            scope.getIncidentsCountByType = (incidentType: string): number => {
                if (!scope.getIncidentsByType(incidentType)) {
                    return 0;
                }
                const isNextPageExist = scope.getIncidentsByType(incidentType).pager.nextId;
                return isNextPageExist ? `${scope.getIncidentsByType(incidentType).length}+` : scope.getIncidentsByType(incidentType).length;
            };

            /**
             * applies selected filters to incidents list
             * @param incidentType string value of incident type
             */
            scope.applyFilter = (incidentType: string) => {
                if (!scope.initialIncidentsByType[incidentType].length) {
                    return;
                }
                scope.incidentType = incidentType;
                scope.setActiveTab(incidentType);
                scope.incidentsList = scope.getIncidentsByType(incidentType);
            };
        },
        template: require('./incidentHistoryList.html')
    };
}
