/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as moment from 'moment';
import Name from '../../../schema/Name';
import CriticalNotice from '../../../schema/CriticalNotice';
import DecoratorTransform from '../../../shared/transforms/DecoratorTransform';

/**
 * A decorator transform that will add critical notices to the Name.
 */
export default class NameCriticalNoticesDecoratorTransform implements DecoratorTransform<Name> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['serverDateTimeFormat'];

    /**
     * Constructs a new instance of the NameCriticalNoticesDecoratorTransform class.
     *
     * @param serverDateTimeFormat The constant that defines the format in which dates are sent by the server.
     */
    constructor(private serverDateTimeFormat: string) {
    }

    // @Override
    public invoke(name: Name): void {
        if (name.criticalNotices) {
            name.criticalNotices = name.criticalNotices.filter(this.isCriticalNoticeActive);
        }
    }

    /**
     * Determines if the critical notice is active by looking at its beginning and ending dates.
     *
     * @param criticalNotice The critical notice for which it needs to be determined if it's active or not.
     * @returns {boolean} true if the current date is between the beginning and ending dates (inclusive), false otherwise.
     */
    private isCriticalNoticeActive = (criticalNotice: CriticalNotice): boolean => {
        let now = moment().startOf('day').toDate();
        let beginningDate = this.parseDate(criticalNotice.begdate);

        // If the beginning date is null, undefined, or after `now`, then return false.
        if (!beginningDate || beginningDate > now) {
            return false;
        }

        let endingDate = this.parseDate(criticalNotice.expdate);

        // If the ending date is null, undefined, or on or after `now`, then return true;
        return !endingDate || now <= endingDate;
    };

    /**
     * Parses the given date if it is a string.  Simply returns the original date if it is already of type Date.
     *
     * @param date The date that could be of type string or Date.
     * @returns {Date} The parsed Date object.
     */
    private parseDate(date: string | Date): Date {
        return (typeof date === 'string') ? moment(date, this.serverDateTimeFormat).toDate() : date;
    }
}
