/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';

/**
 * The demographic contact type detail model
 */
@table('rcreasdl')
export class ContactType {

    @expand('abbr', 'desc')
    public type: string;
}
