/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IPromise = angular.IPromise;
import DirectoryFinder from './DirectoryFinder';

/**
 * Finds the Documents directory on the specific platform of the current device (e.g. iOS or Android).
 */
export default class DocumentsDirectoryFinder implements DirectoryFinder {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['ionic', '$q', 'androidDocumentsDirectoryFinder', 'iOSDocumentsDirectoryFinder'];

    /**
     * Constructs a new instance of the PlatformSpecificDocumentsDirectoryProvider class.
     *
     * @param ionic A reference to the ionic framework.
     * @param $q The angular service that handles creating and working with promises.
     * @param androidDocumentsDirectoryFinder Finds the Documents directory on an Android device.
     * @param iOSDocumentsDirectoryFinder Finds the Documents directory on an iOS device.
     */
    constructor(private ionic: IonicStatic,
        private $q: IQService,
        private androidDocumentsDirectoryFinder: DirectoryFinder,
        private iOSDocumentsDirectoryFinder: DirectoryFinder) {
    }

    // @Override
    public findDirectory(): IPromise<string> {
        let deferred = this.$q.defer<string>();
        let finder = this.getPlatformSpecificDirectoryFinder();

        if (!finder) {
            deferred.reject('Attachments are not supported on this device');
        } else {
            finder.findDirectory()
                .then(directory => deferred.resolve(directory))
                .catch(error => deferred.reject(error));
        }

        return deferred.promise;
    }

    /**
     * Gets the directory finder that is specific to the current operating system.
     *
     * @returns {DirectoryFinder} The directory finder for the current operating system.
     */
    private getPlatformSpecificDirectoryFinder(): DirectoryFinder {
        const platform = this.ionic.Platform.device().platform;
        if (platform === 'iOS') {
            return this.iOSDocumentsDirectoryFinder;
        } else if (platform === 'Android') {
            return this.androidDocumentsDirectoryFinder;
        }
        return undefined;
    }
}
