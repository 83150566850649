/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IFormController = angular.IFormController;
import IStateService = angular.ui.IStateService;
import * as moment from 'moment';
import DurationInputObject = moment.DurationInputObject;
import Set from '../shared/interfaces/Set';
import AssociativeArray from '../shared/interfaces/AssociativeArray';
import { isCodeTableAutocompleteSuggestion } from '../rms/shared/components/code-table-autocomplete/code-table-autocomplete.component';
import partition = require('lodash/partition');

/**
 * The base controller for views that perform searching.
 */
export abstract class BaseSearchController {

    /**
     * The symbol that's used as a wildcard when searching.
     */
    public static WILDCARD = '*';

    /**
     * The associative array of search parameters.
     */
    public searchParams: AssociativeArray<any> = {};

    protected filter: string;

    /**
     * The set of properties that the DurationInputObject interface exposes.
     */
    private durationInputPropertiesSet: Set = {
        years: true,
        months: true,
        weeks: true,
        days: true,
        hours: true,
        minutes: true,
        seconds: true,
        milliseconds: true
    };

    /**
     * The set that stores the names of special parameters. These parameters won't be
     * included in the filter, but will be sent separately to the list state.
     */
    private _specialParameterSet: Set;

    /**
     * Constructs a new instance of the BaseSearchController class.
     *
     * @param $state The service that transitions between states.
     * @param serverDateTimeFormat The constant that defines the format in which both a date and time are sent by the server.
     * @param specialParameterSet (optional) The set that stores the names of special parameters. These parameters won't be
     *                            included in the filter, but will be sent separately to the list state.
     */
    constructor(
        protected $state: IStateService,
        private serverDateTimeFormat: string,
        specialParameterSet?: Set
    ) {
        this._specialParameterSet = specialParameterSet || {};
    }

    /**
     * Resets the form to it's pristine condition and clears out the search parameters.
     *
     * @param form The form to be reset.
     */
    public reset(form: IFormController): void {
        this.searchParams = {};
        form.$setPristine();
        form.$setUntouched();
    }

    /**
     * Determines if currently there are NO search parameters.
     * This means that the user has not entered anything into the form.
     *
     * @returns {boolean} true if there are no search parameters,
     *                    false if there is at least one search parameter.
     */
    public thereAreNoSearchParameters(presetSearchValuesCount?: number): boolean {
        const count = presetSearchValuesCount ? presetSearchValuesCount : 0;
        return this.getSearchParameters().length <= count;
    }

    /**
     * Gets all of the search parameters, then partitions them into special vs ordinary parameters.
     * The ordinary parameters are converted into a combined filter whereas the special parameters are handled individually.
     * All parameters are then passed along when transitioning to the list state.
     */
    public search(shouldRedirect: boolean = true, isDateRange: boolean = false): void {
        let parameters = this.getSearchParameters();

        // Partition the parameters into special vs ordinary parameters.
        let [specialParameters, ordinaryParameters] = partition<[string, any]>(parameters, ([key]) => this._specialParameterSet[key]);

        // Convert the ordinary parameters to a semicolon delimited string as excepted by the Tables API.
        let filter = ordinaryParameters.map(pair => this.convertToFilter(pair, isDateRange)).join(';');

        // Create the params to send to the list state.
        let params = { filter: filter };

        // Add each special parameter as a separate property.
        for (let [key, value] of specialParameters) {
            params[key] = value;
        }
        if (!shouldRedirect) {
            this.filter = filter;
            return;
        }
        // Transition to the list state.
        this.$state.go(this.listStateName, params);
    }

    /**
     * Handles a bug in the Angular 2 forms framework where it's currently not possible to have a
     * null or undefined option in a drop-down: https://github.com/angular/angular/issues/10349
     * When that bug is fixed, this method can be removed. The html may need to be reworked based
     * on how the bug was fixed.
     *
     * @param fieldName The name of the field that may be set to undefined.
     * @param value The new value that the user chose from the drop-down.
     */
    public undefinedFix(fieldName: string, value: any): void {
        if (value === 'undefined') {
            this.searchParams[fieldName] = undefined;
        }
    }

    /**
     * Gets the name of the state that displays the list of results.
     *
     * @returns The name of the list state.
     */
    protected abstract get listStateName(): string;

    /**
     * Gets the raw kay/value pairs before determining if they should be included or not.
     *
     * @returns {[string, any][]} An array of key/value pairs that contain the user's data.
     */
    protected getKeyValuePairs(): [string, any][] {
        return Object.keys(this.searchParams)

            // Create a tuple of key/value pairs.
            .map<[string, any]>(key => [key, this.searchParams[key]]);
    }

    /**
     * Determines if the search parameter should be included in the filter sent to the server.
     * This filters out parameters that are empty, null, etc.
     *
     * @param key The key that identifies a field in the database.
     * @param value The value chosen by the user with which to filter.
     * @returns {boolean} True if the parameter should be included, false otherwise.
     */
    protected shouldIncludeParameter = ([, value]: [string, any]): boolean => {
        return angular.isNumber(value) || !!value; // The `isNumber` check is to make sure that 0 (zero) is included.
    };

    /**
     * Converts the given key/value pair to a string filter that can be passed to the Tables API.
     *
     * @param key The key that identifies a field in the database.
     * @param value The value chosen by the user with which to filter.
     * @returns {string} A string filter that can be passed to the Tables API.
     */
    protected convertToFilter([key, value]: [string, any], isDateRange: boolean): string {
        let operator = '=';

        if (value instanceof Date) {
            // Dates must be formatted in the way that the server expects.
            value = moment(value).format(this.serverDateTimeFormat);
            if (isDateRange) {
                operator = '>=';
            }
        } else if (this.isDurationInputObject(value)) {
            // A DurationInputObject represents a time duration. So we need to calculate the start date of that duration.
            value = moment().subtract(value).format(this.serverDateTimeFormat);
            operator = '>=';
        } else if (isCodeTableAutocompleteSuggestion(value)) {
            value = value.value;
        }
        return key + operator + value;
    }

    /**
     * Gets all of the key/value pairs that will be used to filter the results when searching.
     *
     * @returns {[string, any][]} An array of key/value pairs that will be used to filter the results when searching.
     */
    private getSearchParameters(): [string, any][] {
        return this.getKeyValuePairs().filter(this.shouldIncludeParameter).map(this.appendWildcard);
    }

    /**
     * Appends an asterisk as a wildcard to the end of the value if it is a string.
     *
     * @param key The key that identifies a field in the database.
     * @param value The value chosen by the user with which to filter.
     * @returns {[string, any]} A key/value pair that contain the user's data.
     */
    private appendWildcard = ([key, value]: [string, any]): [string, any] => {
        if (typeof value === 'string' && !value.endsWith(BaseSearchController.WILDCARD) && key !== 'geoaddr') {
            value += BaseSearchController.WILDCARD;
        }
        return [key, value];
    };

    /**
     * Determines if the given value implements the DurationInputObject interface.
     *
     * @param value The value to test if it is a DurationInputObject.
     * @returns {boolean} True if the value is a DurationInputObject, false if it is not.
     */
    private isDurationInputObject(value: any): value is DurationInputObject {
        if (value && typeof value === 'object') {
            let keys = Object.keys(value);

            // There must be at least one key and every non-angular key must be contained in the `durationInputPropertiesSet`.
            return keys.length > 0 && keys.filter(key => key.indexOf('$') !== 0).every(key => this.durationInputPropertiesSet[key]);
        }
        return false;
    }
}
