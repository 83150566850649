/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { ActionTaken } from './action-taken';
import { ContactType } from './contact-type';
import { MiscellaneousCode } from './miscellaneous-code';
import { OfficerInvolved } from './officer-involved';
import { Outcome } from './outcome';
import { GroupMember } from './group-member';
import { Involvement } from '../../../schema/Involvement';
import { TrafficStopReference } from '../../shared/model/traffic-stop-reference';

/**
 * The demographic model
 */
@table('rcmain')
export class Demographic {

    @includeMany('rcactdl', ActionTaken)
    public actions: ActionTaken[];

    @field('addr')
    public street: string;

    @field('age')
    public age: string;

    @field('agency')
    public agency: string;

    @expand('city', 'name')
    public city: string;

    @includeMany('rcreasdl', ContactType)
    public contactTypes: ContactType[];

    @expand('div', 'desc')
    public division: string;

    @expand('ethnic', 'desc')
    public ethnicity: string;

    @field('gender')
    public gender: string;

    @includeMany('rcpassdl', GroupMember)
    public groupMembers: GroupMember[];

    @includeMany('rcmiscdl', MiscellaneousCode)
    public miscCodes: MiscellaneousCode[];

    @includeMany('rcoffrdl', OfficerInvolved)
    public officers: OfficerInvolved[];

    @includeMany('rcocdl', Outcome)
    public outcomes: Outcome[];

    @expand('race', 'desc')
    public race: string;

    @expand('trstop', TrafficStopReference)
    public relatedTrafficStop: TrafficStopReference | string;

    @field('enddt')
    public released: Date;

    @field('shift')
    public shift: string;

    @expand('city', 'state')
    public state: string;

    @field('stopdt')
    public stopped: Date;

    @expand('city', 'zip')
    public zip: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
