/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CadCall, Incident } from '../model';
import { DatabaseService } from '../../../shared/database/database.service';

/**
 * A service which retrieves and attaches radio logs to an incident.
 */
@Injectable()
export class RadioLogService<T extends Incident> {

    /**
     * Constructs a new instance of the RadioLogService.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     */
    constructor(
        private databaseService: DatabaseService
    ) {
    }

    /**
     * Attaches the radio logs to the incident.
     *
     * @param incident The incident which to attach the radio logs.
     * @returns An observable that returns the original incident that has been
     *          modified to include the radio logs.
     */
    public attachRadioLogs = (incident: T): Observable<T> => {
        if (incident && incident.cadCall) {
            const cadCallId = (typeof incident.cadCall === 'string') ? incident.cadCall : incident.cadCall.id;

            return this.databaseService.get(CadCall, cadCallId)
                .map(cadCall => {
                    incident.radioLogs = cadCall.radioLogs || [];
                    return incident;
                })
                .catch(() => Observable.of(incident));
        }

        return Observable.of(incident);
    };
}
