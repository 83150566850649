/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IScope = angular.IScope;
import IDirective = angular.IDirective;
import IAttributes = angular.IAttributes;
import ITranscludeFunction = angular.ITranscludeFunction;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import PermissionAttributeGrouping from '../../schema/PermissionAttributeGrouping';
import PermissionName from '../../permissions/enums/PermissionName';
import PermissionGrouping from '../../schema/PermissionGrouping';
import PermissionsRepository from '../../permissions/PermissionsRepository';

PermissionsHideDirective.$inject = [
    'permissionsRepository'
];

/**
 * A factory function which creates the permission directive.
 *
 * @param permissionsRepository The repository which stores permissions of a user.
 *
 * @returns {IDirective} A directive which will transform the html into a link to another state.
 */
export default function PermissionsHideDirective(
    permissionsRepository: PermissionsRepository): IDirective {

    return {
        restrict: 'E',
        transclude: true,
        scope: {
            stateName: '@',
            permissions: '='
        },
        link: (scope: PermissionsHideScope, element: IAugmentedJQuery, _attributes: IAttributes, _controller: Object, transclude: ITranscludeFunction) => {

            let permissions = scope.permissions;

            // Validate a user's permissions.
            let allowAccess = permissions !== undefined ? permissionsRepository.processPermissions(permissions) : permissionsRepository.checkStatePermissions(scope.stateName);

            // If the element should not be hidden, resinsert a clone back into the DOM.
            if (allowAccess) {
                transclude(transElement => element.append(transElement));
            } else {

                // Remove the sds-permissions-hide element from the DOM.
                element.remove();
            }
        }
    };
}

/**
 * An interface that defines the scope for the PermissionsHide directive.
 */
interface PermissionsHideScope extends IScope {

    /**
     * The state name of the destination view.
     */
    stateName: string;

    /**
     * The specified permissions of the user.
     */
    permissions: PermissionName | PermissionAttributeGrouping | PermissionGrouping | (PermissionName | PermissionAttributeGrouping | PermissionGrouping)[];
}
