/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IInjectorService = angular.auto.IInjectorService;
import IRootScopeService = angular.IRootScopeService;
import IPromise = angular.IPromise;
import IonicLoadingService = ionic.loading.IonicLoadingService;
import IonicPopupService = ionic.popup.IonicPopupService;
import Session from './Session';
import RegeneratableContainer from './regeneratable/RegeneratableContainer';
import UnitVerification from './verification/UnitVerification';
import OfficerName from '../schema/OfficerName';
import UnitLongTerm from '../schema/UnitLongTerm';
import Credentials from './Credentials';
import UserVerification from './verification/interfaces/UserVerification';
import SymoduleVerification from './verification/interfaces/SymoduleVerification';
import { AttachFilesTablesStore } from '../rms/shared/attachments/attach-files-tables/attach-files-tables-store.service';
import { CookieCleaner } from './cookie-cleanup/cookie-cleaner';
import { AuthenticationTokenBuilder } from './authentication/authentication-token-builder';
import { ConditionalServiceLoader, VersionService } from '../conditional-injection';
import { CredentialsRepository, SetAuthenticationHeadersService } from '../authentication';
import { SystemUse } from './system-use';
import { SessionTimeout } from './session-timeout';
import { PasswordExpirationService } from '../authentication/password-expiration.service';
import { PushNotificationService } from './push-notification/push-notification.service';
import { logIfLoginIsVerbose, logErrorIfLoginIsVerbose } from '../api/analytics/firebase-crashlytics-service';
import { logEvent } from '../api/analytics/firebase-analytics-service';
import { AUTH_EVENTS } from './authentication/events/authentication-events';

declare const cordova: any;

/**
 * The name of the event that is broadcast when the user has logged in.
 */
export const LOGGED_IN_EVENT = 'Spillman:UserLoggedIn';

/**
 * A class that handles authenticating the user with the Spillman server.
 *
 * It also ensures that if the user has not interacted with the app within a set amount of time,
 * then the user is shown a dialog that forces him/her to choose whether to stay logged in or to logout.
 */
export class AuthenticationService {

    /**
     * $inject annotation.  See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        '$rootScope',
        'session',
        'regeneratableContainer',
        'unitVerification',
        'userVerification',
        'symoduleVerification',
        '$ionicLoading',
        '$ionicPopup',
        '$q',
        'versionService',
        'conditionalServiceLoader',
        'setAuthenticationHeadersService',
        '$injector',
        'credentialsRepository',
        'passwordExpirationService',
        'pushNotificationService'
    ];

    public isVersionServiceError = false;
    public isPasswordExpired = false;
    public passwordExpirationState: {};

    /**
     * Constructs a new instance of the AuthenticationService class.
     *
     * @param $rootScope The Angular $rootScope that can be used to listen to application events.
     * @param session The object that stores information about the current user's session.
     * @param regeneratableContainer A container that stores all regeneratable services.
     * @param unitVerification A service which provides methods for verifying a valid unit.
     * @param userVerification A factory to verify if the UserName and Password are valid.
     * @param symoduleVerification A factory to verify if the Symodule is enabled.
     * @param $ionicLoading An overlay that displays a spinner while waiting for the incidents to be retrieved.
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param $q The angular service that handles creating and working with promises.
     * @param versionService The service that provides the server version.
     * @param conditionalServiceLoader The object that knows how to load the correct service based on current conditions (platform, server version, etc).
     * @param setAuthenticationHeadersService The service which sets the security headers for angular 1 and 2.
     * @param $injector The Angular injector service.
     * @param credentialsRepository
     * @param passwordExpirationService The service that check user password expiration info
     * @param pushNotificationService The service which configure and works with push-notifications
     */
    constructor(
        private $rootScope: IRootScopeService,
        private session: Session,
        private regeneratableContainer: RegeneratableContainer,
        private unitVerification: UnitVerification,
        private userVerification: UserVerification,
        private symoduleVerification: SymoduleVerification,
        private $ionicLoading: IonicLoadingService,
        private $ionicPopup: IonicPopupService,
        private $q: angular.IQService,
        private versionService: VersionService,
        private conditionalServiceLoader: ConditionalServiceLoader,
        private setAuthenticationHeadersService: SetAuthenticationHeadersService,
        private $injector: IInjectorService,
        private credentialsRepository: CredentialsRepository,
        private passwordExpirationService: PasswordExpirationService<Credentials>,
        private pushNotificationService: PushNotificationService
    ) {
    }

    /**
     * Logs the current user into the app using the provided credentials.
     *
     * @param credentials The credentials that contain the username, password, server, and port.
     *                    They cannot be null and should have already been validated.
     * @returns {IPromise<any>} A promise that, when resolved, will return the current user.
     */
    public async login(credentials: Credentials): Promise<IPromise<OfficerName>> {
        // Display an overlay with a loading spinner while authenticating.
        this.$ionicLoading.show({
            template: '<ion-spinner icon="spiral"></ion-spinner>'
        });

        let verifiedUser: OfficerName;
        let authenticationToken: string;

        let flexVersion = '';
        const passExpFixFlexVersion = '2021.3';

        // check the availability of the feature÷
        function checkAvailabilityFeature (serverVersion: string, fixVersion: string) {
            if (!flexVersion) {
                flexVersion = serverVersion;
            }
            return serverVersion >= fixVersion;
        }

        if (typeof cordova !== 'undefined' && cordova.plugin && cordova.plugin.http) {
            logEvent(AUTH_EVENTS.init_set_trust_to_default);
            cordova.plugin.http.setServerTrustMode('default', () => {
                logEvent(AUTH_EVENTS.success_set_trust_to_default);

                // eslint-disable-next-line no-console
                console.log('successfully set server-trust mode to default');
            }, () => {
                logEvent(AUTH_EVENTS.fail_set_trust_to_default);

                // eslint-disable-next-line no-console
                console.log('failed to set server-trust mode to default');
            });


            logEvent(AUTH_EVENTS.init_get_version);
            await this.versionService.initialize(credentials).then(()=>{
                logEvent(AUTH_EVENTS.success_get_version);
            }).catch(({error}) => {
                logEvent(AUTH_EVENTS.fail_get_version, {error});
                logErrorIfLoginIsVerbose('versionService.initialize', error);

                logIfLoginIsVerbose('setting the server trust mode to no check');
                logEvent(AUTH_EVENTS.init_set_trust_to_no_check);
                cordova.plugin.http.setServerTrustMode('nocheck', () => {
                    logEvent(AUTH_EVENTS.success_set_trust_to_no_check);
                    logIfLoginIsVerbose('successfully set the server trust mode to no check');

                    // eslint-disable-next-line no-console
                    console.log('successfully set server-trust mode to nocheck');
                }, () => {
                    logEvent(AUTH_EVENTS.fail_set_trust_to_no_check);
                    logIfLoginIsVerbose('failed to set server-trust mode to nocheck');
                    // eslint-disable-next-line no-console
                    console.log('failed to set server-trust mode to nocheck');
                });

                const tlsErrors = [
                    'TLS connection could not be established',
                    'The certificate for this server is invalid'];
                if (typeof error === 'string') {
                    if (!error.includes(tlsErrors[0]) && !error.includes(tlsErrors[1])) {
                        logEvent(AUTH_EVENTS.new_service_error, {error});

                        logErrorIfLoginIsVerbose('new versionService.initialize log', error);
                    }
                }
            });
        }

        // Retrieve the server version.
        logEvent(AUTH_EVENTS.init_get_version_2);
        return this.$q.when(<any> this.versionService.initialize(credentials).catch((error) => {
            logEvent(AUTH_EVENTS.fail_get_version_2, {error});

            logErrorIfLoginIsVerbose('versionService.initialize', error);

            this.isVersionServiceError = true;

            return Promise.reject('Unable to login');
        }))
        // We need to use manual injection here because we don't know which implementation of the AuthenticationTokenBuilder
        // interface to use until the server version has been determined.
            .then(() => {
                logIfLoginIsVerbose('version successfully initialized');
                logEvent(AUTH_EVENTS.success_get_version_2);

                this.isVersionServiceError = false;
                return this.conditionalServiceLoader.loadService(AuthenticationTokenBuilder);
            })

        // Build the Authentication token which is the value that is sent to the server in the "Authorization" header.
            .then((authenticationTokenBuilder: AuthenticationTokenBuilder) => this.$q.when<string>(<any>authenticationTokenBuilder.buildAuthenticationToken(credentials).toPromise()))

        // Store off the authentication token.
            .then(token => authenticationToken = token)

        // Retrieve the user.
            .then(() => this.userVerification(credentials, authenticationToken))

        // Store off the verified user so we don't have to keep passing it along.
            .then(user => verifiedUser = user)

        // Verify the symodule.
            .then(() => this.symoduleVerification(verifiedUser, credentials, authenticationToken))

        // Always ensure the spinner is hidden after the user has been authenticated.
            .finally(() => this.$ionicLoading.hide())

        // Retrieve the unit.
            .then(() => this.unitVerification.retrieveUnit(verifiedUser, credentials.unit))

        // Set authentication headers
            .then((unit: UnitLongTerm) => {
                logEvent(AUTH_EVENTS.unit_check_ok);
                logIfLoginIsVerbose('unit successfully retrieved');

                this.$ionicLoading.show({
                    template: '<ion-spinner icon="spiral"></ion-spinner>'
                });

                // Set the unit for the credentials object.
                credentials.unit = unit && unit.unitno;

                // Create a session for the user.
                this.session.create(credentials, verifiedUser, unit);
                this.credentialsRepository.initialize(credentials);

                // Set authentication headers.
                return this.setAuthenticationHeadersService.setHeaders(authenticationToken);
            })

        // System Use Notification
            .then(() => this.conditionalServiceLoader.loadService(SystemUse))
            .then((systemUse: SystemUse) => this.$q.when<any>(<any>systemUse.getNotificationPopup()
                .toPromise()
                .then(result => {
                    logEvent(AUTH_EVENTS.system_use_success);

                    return result || (result === undefined) ? this.$q.resolve() : this.$q.reject();
                })
            ))
        // Retrieve Session Timeout
            .then(() => {
                logEvent(AUTH_EVENTS.session_timeout_init);

                return this.conditionalServiceLoader.loadService(SessionTimeout);
            })
            .then((sessionTimeout: SessionTimeout) => this.$q.when<any>(<any>sessionTimeout.initialize().toPromise()))

        // Initialize push-notification service
            .then(() => {
                logEvent(AUTH_EVENTS.session_timeout_success);
                logIfLoginIsVerbose('sessionTimeout service successfully initialized');

                logEvent(AUTH_EVENTS.push_notification_set);
                this.pushNotificationService.initializeCadCallNotifications(credentials.unit, credentials.username);
            })

        // Initialize all regeneratable services.
            .then(() => {
                logEvent(AUTH_EVENTS.push_notification_set_success);

                logIfLoginIsVerbose('pushNotificationService cad call notifications successfully initialized');

                return this.regeneratableContainer.initialize().catch((error) => {
                    logErrorIfLoginIsVerbose('regeneratableContainer.initialize', error);
                    logEvent(AUTH_EVENTS.regeneratable_error);

                    return this.regeneratableContainer.destroy().then(() => this.$q.reject('Unable to login'));
                })

                // Manually injected to avoid a circular dependency.
                    .then(() => this.$injector.get<AttachFilesTablesStore>('attachFilesTablesStore'))

                    .then(attachFilesTablesStore => this.$q.when<string>(<any>attachFilesTablesStore.loadInitialData()))

                    .then(() => {
                        logIfLoginIsVerbose('attachFilesTablesStore successfully loaded initial data');
                        logEvent(AUTH_EVENTS.attach_files_tables_store_success);

                        // Broadcast the event to let other services know that the user is now logged in.
                        this.$rootScope.$broadcast(LOGGED_IN_EVENT);

                        return verifiedUser;
                    });
            })
        // Always hidden spinner after the user has been authenticated.
            .finally(() => this.$ionicLoading.hide())
            .catch((error: any) => {
                logErrorIfLoginIsVerbose('finallyCatch', error);
                logEvent(AUTH_EVENTS.generic_auth_error, {error});

                this.$ionicLoading.hide();
                if (this.versionService.isInitialized) {
                    this.conditionalServiceLoader.loadService(CookieCleaner).deleteCookies(credentials);
                }
                return this.$q.reject(`${this.safeTrim(error)}`);
            })

        // In case the credentials are ok, check the user password expiration info to change password in case password is expired
        // It is required to handle the account lock scenario as well
            .then(() => {
                logEvent(AUTH_EVENTS.checking_password_expiration);
                logIfLoginIsVerbose('checking password expiration status');
                // Set current server version.
                flexVersion = this.versionService.serverVersion.raw;

                if (checkAvailabilityFeature(flexVersion, passExpFixFlexVersion)) {
                    return this.$q.when(<any> this.passwordExpirationService.checkPassword(credentials).toPromise());
                }
            })
        // Verification of Password Expiration status
            .then((data) => {
                if (data) {
                    if (data.error) {
                        logEvent(AUTH_EVENTS.error_password_expiration);

                        logIfLoginIsVerbose('password expiration status error' + this.safeTrim(data.error));
                        return this.$q.reject(`${data.error.trim()}${data.error ? ': ' + data.error : ''}`);
                    }
                    if (data.options.template) {
                        logEvent(AUTH_EVENTS.expired_password_expiration);
                        logIfLoginIsVerbose('password expired');

                        this.isPasswordExpired = true;
                        this.passwordExpirationState = data.options;
                    } else {
                        logEvent(AUTH_EVENTS.checked_password_expiration);
                        logIfLoginIsVerbose('password checked. Everything is fine');

                        this.isPasswordExpired = false;
                    }
                }
            })
        // Show password expiration message in case password will be expired soon before other successes requests
            .then(() => {
                if (this.isPasswordExpired) {
                    logEvent(AUTH_EVENTS.open_password_expiration_popup);
                    logIfLoginIsVerbose('will open password expiration popup');
                    this.$ionicPopup.alert(this.passwordExpirationState);
                }
            })
            .catch((error: any) => {
                logEvent(AUTH_EVENTS.generic_auth_error);
                logErrorIfLoginIsVerbose('generalError', {error});

                return this.$q.reject(`${this.safeTrim(error)}${error.message ? ': ' + error.message : ''}`);
            });
    }

    private safeTrim(error: any): string {
        return error?.trim ? error.trim() : '';
    }
}
