/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { IncludeManyNode } from '../../nodes/property/include-many-node';
import { ModelCreationData, ModelPair } from '../model-creation-data';
import { TablesApiHelper } from '../tables-api-helper';

/**
 * The parent property.
 */
const PARENT_PROPERTY = '_parent_';

/**
 * Performs a "visit" on an IncludeManyNode during the process of creating the human-friendly model.
 */
@Injectable()
export class IncludeManyNodeModelCreationVisitor implements TypeSpecificTreeNodeVisitor<IncludeManyNode, ModelCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [IncludeManyNode];

    /**
     * Constructs a new instance of the IncludeManyNodeModelCreationVisitor class.
     *
     * @param tablesApiHelper A helper that knows how to work with the database models returned by the Tables API.
     */
    constructor(private tablesApiHelper: TablesApiHelper) {
    }

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: IncludeManyNode, data: ModelCreationData): void {
        const childPairs: ModelPair[] = [];

        for (let pair of data.modelPairs.peek()) {
            const joins = this.tablesApiHelper.getJoinsByTableName(pair.databaseModel, node.table);

            for (let join of joins) {
                const childPair: ModelPair = { databaseModel: join.fields };

                // Sneak the parent into the pair object.
                childPair[PARENT_PROPERTY] = pair;
                childPairs.push(childPair);
            }
        }

        data.modelPairs.push(childPairs);
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(node: IncludeManyNode, data: ModelCreationData): void {
        const childPairs = data.modelPairs.pop();

        for (let { humanFriendlyModel } of data.modelPairs.peek()) {
            humanFriendlyModel[node.propertyKey] = [];
        }

        for (let childPair of childPairs) {
            const parent: ModelPair = childPair[PARENT_PROPERTY];
            parent.humanFriendlyModel[node.propertyKey].push(childPair.humanFriendlyModel);
        }
    }
}
