/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { IRootScopeService, IScope } from 'angular';
import { FilterActions } from 'app/cad/filtering/consts';

export default abstract class AccordionComponentController {
    public static $inject = ['$rootScope', '$scope'];

    public title: string;
    public unavailableMessage: string;

    public userData: string;

    public options: any[];
    private originalList: any[];
    private displayList: any[];

    public selected: any;

    public isLoading: boolean;
    public selectedAll: boolean;

    public error: boolean;
    public wasSorted = false;

    constructor(
        $rootScope: IRootScopeService,
        private $scope: IScope,
        private actions: FilterActions,
    ) {
        $scope.active = true;
        $scope.quantity = 0;
        $scope.isDisabled = false;
        $scope.selectedItemsQuantity = 1;

        this.originalList = this.options;

        $scope.$watch(() => {
            return this.selectedAll;
        }, (newVal: boolean, oldVal: boolean) => {
            if (newVal === undefined && oldVal === undefined) {
                return;
            }

            if (newVal) {
                this.options = this.options.map(item => {
                    item.checked = true;
                    return item;
                });
            } else {
                this.options.map(item => {
                    item.checked = false;
                    return item;
                });
            }
        }, true);

        $scope.$watch(() => {
            return this.options;
        }, (newVal: any[], oldVal: any[]) => {
            if ( (!this.wasSorted || newVal.length !== oldVal.length) && this.userData !== undefined) {
                this.options = this.sortAndApplyUserLabel(newVal);
            }
            this.originalList = this.options;
            this.filterOptions();

            $scope.quantity = this.originalList.filter(item => item.checked === true).length;
            this.selected({ data: this.originalList });

            // the values changed
            if (newVal && oldVal && newVal.length !== oldVal.length) {
                this.scrollToTop();
            }

            if (this.originalList.length > 0 && this.originalList.every((item: {checked: boolean}) => item.checked === true)) {
                this.selectedAll = true;
            }
        }, true);

        $scope.$watch(() => {
            return $scope.isDisabled;
        }, (newVal: any) => {
            if (newVal) {
                $scope.active = true;
            }
        });

        $scope.$watch(() => {
            return this.isLoading;
        }, (newVal: any) => {
            if (newVal !== undefined) {
                $scope.isLoading = newVal;
            }
        }, true);

        $scope.$watch(() => {
            return this.error;
        }, (newVal: any) => {
            if (newVal !== undefined) {
                $scope.error = newVal;
            }
        }, true);

        $scope.retryOnError = () => $rootScope.$broadcast(actions.RETRY_FILTER);

        $rootScope.$on(actions.CLEAN_FIELDS, () => {
            for (let item of this.originalList) {
                item.checked = false;
            }
            this.options = this.originalList;
            this.selectedAll = false;
        });

        $rootScope.$on(actions.DISABLE_FIELDS, () => {
            $scope.isDisabled = true;
        });

        $rootScope.$on(actions.ENABLE_FIELDS, () => {
            $scope.isDisabled = false;
        });

        $rootScope.$on(actions.CLEAN_FOCUS, () => {
            this.searchFilterLoseFocus();
        });
    }

    public getSelectLabelTitle(): string {
        return this.selectedAll ? 'Unselect all' : 'Select all';
    }

    public cleanFilterText() {
        this.$scope.filterText = undefined;
        this.displayList = this.originalList;
    }

    public searchFilterLoseFocus() {
        document.getElementById(`search-${this.title}`).blur();
    }

    public filterOptions() {
        if (this.$scope.filterText !== undefined) {
            this.displayList = this.originalList.filter(option => option.accordionLabel.toString().containsCaseInsensitive(this.$scope.filterText));
            this.scrollToTop();
        } else {
            this.displayList = this.originalList;
        }
    }

    public $onInit = () => { };

    private scrollToTop() {
        let scrollContent = document.getElementById(`optionsContainer-${this.title}`);
        if (scrollContent) {
            scrollContent.getElementsByClassName('scroll')[0].setAttribute('style', 'transform: translate3d(0px, 0px, 0px) scale(1);');
        }
    }

    private sortAndApplyUserLabel(data: any[]): any[] {
        this.wasSorted =  true;
        if (this.userData !== undefined) {
            data.unshift(data.splice(data.findIndex((item: FilterModel) => item.verifyAndApplyUserLabel(this.userData)), 1)[0]);
        }
        return data;
    }
}
