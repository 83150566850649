/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import LogicalOrFilter from '../../filtering/baseClasses/LogicalOrFilter';
import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import MyCallsFilter from '../../calls/filtering/MyCallsFilter';
import CustomFilterByTypes from '../custom-filters/CustomFilterByTypes';
import CustomFilterByAgencies from './CustomFilterByAgencies';
import CallFilterByUnit from 'app/cad/calls/filtering/CallFilterByUnit';
import CustomFilterOnlyMyCalls from './CustomFilterOnlyMyCalls';
import CustomFilterByZones from './CustomFilterByZones';
import CustomDataCadFilter from '../baseClasses/CustomDataCadFilter';
import FiltersStorage from '../storage/FiltersStorage';
import MyCallsItem from '../../calls/components/filter-components/my-calls/MyCallsItem';
import CADModel from 'app/schema/interfaces/CADModel';
import FilterByUnit from '../filters/FilterByUnit';

/**
 * Filters the calls so that only those whose agency, type or zone is the same as the selected ones, will be shown.
 * As with all call filters, any calls that are assigned to the user will be shown no matter what -
 * they cannot be filtered out.
 */
export default abstract class CustomFilterAllFilters extends LogicalOrFilter<AggregatorCadCall> {
    /**
     * Constructs a new instance of the CustomFilterAllFilters class.
     *
     * @param myCallsFilter The filter that includes any calls assigned to the current user.
     * @param callFilterByUnit The filter that includes only calls assigned to the current unit.
     * @param customFilterByTypes The filter that includes any calls that have the selected types.
     * @param customFilterByAgencies The filter that includes any calls that have the selected agencies.
     * @param customFilterOnlyMyCalls The filter that includes only calls assigned to the current user.
     * @param customFilterByZones The filter that includes calls from the selected zones.
     * @param filtersStorage The filter storage manager that saves and loads saved filters.
     *///
    constructor(
        private myCallsFilter: MyCallsFilter,
        private callFilterByUnit: CallFilterByUnit,
        private customFilterByTypes: CustomFilterByTypes,
        private customFilterByAgencies: CustomFilterByAgencies,
        private customFilterOnlyMyCalls: CustomFilterOnlyMyCalls,
        private customFilterByZones: CustomFilterByZones,
        private filtersStorage: FiltersStorage,
        private filterByUnit: FilterByUnit
    ) {
        super(myCallsFilter, callFilterByUnit, customFilterByTypes, customFilterByAgencies, customFilterOnlyMyCalls, customFilterByZones);
    }

    /**
     * Triggers method applyFilter of every filter of type CustomDataCadFilter
     */
    public applyAllFilters() {
        let appliedFilters: any[] = [];
        for (let filter of this.filters) {
            if (filter instanceof CustomDataCadFilter) {
                filter.applyFilter();
                if (filter instanceof CustomFilterOnlyMyCalls) {
                    appliedFilters = [...appliedFilters, filter.getSelectedValues()];
                } else {
                    appliedFilters = [...appliedFilters, ...filter.getSelectedValues()];
                }
            }
        }

        this.filtersStorage.saveOnCurrentFilter(appliedFilters);
    }

    public restoreAllFilters() {
        for (let filter of this.filters) {
            if (filter instanceof CustomDataCadFilter) {
                if (filter instanceof CustomFilterOnlyMyCalls) {
                    filter.setValues(filter.getStoredValues()[0] ?? new MyCallsItem(false, false));
                } else {
                    filter.setValues(filter.getStoredValues());
                }
                filter.applyFilter();
            }
        }
        this.applyAllFilters();
    }

    /**
     * Get all filters and decide if they are applied or not based on their isFilterApplied attribute.
     *
     * @returns If any custom filter is being applied.
     */
    public isFilteringCustom(): boolean {
        for (let filter of this.filters) {
            if (filter instanceof CustomDataCadFilter) {
                if (filter.isFilterApplied) {
                    return true;
                }
            }
        }
        return false;
    }

    // @Override
    public include(model: CADModel): boolean {
        if (this.isAssignedToMeOrMyUnit(model)) {
            return true;
        }

        if (this.customFilterOnlyMyCalls?.getSelectedValues().applied) {
            return this.isAssignedToMeOrMyUnit(model);
        }

        if (!this.isFilteringCustom()) {
            return true;
        }

        let isIncluded =  false;

        isIncluded = this.customFilterByAgencies.include(model);

        if (this.customFilterByZones.isFilterApplied) {
            isIncluded = (isIncluded || !this.customFilterByAgencies.isFilterApplied) && this.customFilterByZones.include(model);
        }

        if (this.customFilterByTypes.isFilterApplied) {
            isIncluded = (isIncluded || (!this.customFilterByAgencies.isFilterApplied && !this.customFilterByZones.isFilterApplied)) && this.customFilterByTypes.include(model);
        }

        return isIncluded;
    }

    private isAssignedToMeOrMyUnit = (model: CADModel) => {
        return this.myCallsFilter?.include(model) || this.callFilterByUnit?.include(model) || this.filterByUnit?.include(model);
    };
}
