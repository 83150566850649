/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { IncludeManySelectNode } from '../../nodes/property/include-many-select-node';
import { ModelCreationData } from '../model-creation-data';
import { TablesApiHelper } from '../tables-api-helper';
import { PropertyDeserializer } from '../property-deserializer';

/**
 * Performs a "visit" on an IncludeManySelectNode during the process of creating the human-friendly model.
 */
@Injectable()
export class IncludeManySelectNodeModelCreationVisitor implements TypeSpecificTreeNodeVisitor<IncludeManySelectNode, ModelCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [IncludeManySelectNode];

    /**
     * Constructs a new instance of the IncludeManySelectNodeModelCreationVisitor class.
     *
     * @param tablesApiHelper A helper that knows how to work with the database models returned by the Tables API.
     * @param propertyDeserializer Derializes a property from the JSON response received from the database.
     */
    constructor(private tablesApiHelper: TablesApiHelper, private propertyDeserializer: PropertyDeserializer) {
    }

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: IncludeManySelectNode, data: ModelCreationData): void {
        for (let { databaseModel, humanFriendlyModel } of data.modelPairs.peek()) {
            const joins = this.tablesApiHelper.getJoinsByTableName(databaseModel, node.table);
            humanFriendlyModel[node.propertyKey] = !joins ? [] : joins
                .map(join => join.fields)
                .filter(fields => !!fields)
                .map(fields => this.propertyDeserializer.deserialize(node, fields[node.select]));
        }
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: IncludeManySelectNode, _data: ModelCreationData): void {
        // Nothing to do here.
    }
}
