/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand, field } from '../../../shared/database/decorators';
import { VehicleReference } from '../../shared/model/vehicle-reference';

/**
 * An offender tracking vehicle.
 */
@table('sovehic')
export class OffenderTrackingVehicle {

    @field('num')
    public id: string;

    @expand('vhnum', VehicleReference)
    public vehicle: VehicleReference | string;

    @expand('status', 'descrpt')
    public status: string;

    @field('stdate')
    public date: Date;

    public comments: string;
}
