/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import { PropertyReference } from '../../model/property-reference';

/**
 * A component which displays a field which links to a property.
 */
@Component({
    selector: 'sds-property-link',
    template: `
        <sds-permissions-link state="app.search.propertiesDetails" [id]="property.id" *ngIf="property && property.id">
            <span>{{property.color}}</span>
            <span>{{property.brand}}</span>
            <span>{{property.model}}</span>
            <span>{{property.item}}</span>
        </sds-permissions-link>
        <span *ngIf="property && !property.id">{{property}}</span>
        <span *ngIf="!property">&nbsp;</span>
    `,
    styles: [
        require('./link.component.scss')
    ]
})
export class PropertyLinkComponent {

    /**
     * The property to display.
     */
    @Input() public property: PropertyReference;
}
