/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { isCrashlyticsAvailable } from './firebase-validators';
// import { isLoginExceptionVerbose } from './firebase-remote-config-service';

const MIN_LEN_ERROR_MESSAGE = 3;

const log = async (logMessage: string): Promise<void> =>  {
    try {
        if (isCrashlyticsAvailable() && logMessage !== undefined && logMessage.length !== 0) {
            return cordova.plugins.firebase.crashlytics.log(logMessage);
        }
    } catch (e) { }
};

const logError =  async (logErrorMessage: string): Promise<void> => {
    try {
        if (isCrashlyticsAvailable() && logErrorMessage !== undefined && logErrorMessage.length > MIN_LEN_ERROR_MESSAGE) {
            return cordova.plugins.firebase.crashlytics.logError(logErrorMessage);
        }
    } catch (e) { }
};

const logIfLoginIsVerbose = async (logMessage: string): Promise<void> =>  {
    // Removing the check on remote config to amplify the quantity of logs that we get
    log(logMessage);

    // try {
    //     const isVerbose = await isLoginExceptionVerbose();
    //     if (isVerbose) {
    //         if (logMessage !== undefined) {
    //             log(logMessage);
    //         }
    //     }
    // } catch (e) {}
};

const logErrorIfLoginIsVerbose = async (lastRequest: string, error: any): Promise<void> => {
    // Removing the check on remote config to amplify the quantity of logs that we get
    logError(error);

    // try {
    //     const isVerbose = await isLoginExceptionVerbose();
    //     if (isVerbose) {
    //         if (lastRequest !== undefined) {
    //             log(lastRequest);
    //         }

    //         if (error !== undefined) {
    //             log(error);
    //             logError(error);
    //         }
    //     }
    // } catch (e) {}
};

export { log, logError, logIfLoginIsVerbose, logErrorIfLoginIsVerbose};
