/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { HistoryStorage } from '../../history/history-storage.service';
import { SpillmanLocalStorage } from '../../shared/storage/spillman-local-storage';
import { HistoryJsonSerializer } from '../../history/history-json-serializer';

/**
 * A service that stores the history of statuses that the user has chosen from the status control component.
 */
@Injectable()
export class StatusHistoryStorage extends HistoryStorage<string> {

    /**
     * Constructs a new instance of the StatusHistoryStorage class.
     *
     * @param spillmanLocalStorage The object that handles storing Spillman-specific data in local storage.
     * @param serializer Handles both serialization to and deserialization from JSON.
     */
    constructor(spillmanLocalStorage: SpillmanLocalStorage,
        serializer: HistoryJsonSerializer) {

        super(spillmanLocalStorage, serializer, {
            key: 'statusHistory',
            userSpecific: true
        });
    }
}
