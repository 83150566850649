/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { PropertyNode } from './property-node';
import { ClassNode } from '../class/class-node';
import { TreeNode } from '../../../../data-structures/tree-node';

/**
 * A node that associates an array of simple values (e.g. strings) with a property of a JavaScript class.
 * The values are selected from a specified column from included objects.
 * This node pertains when you have a one-to-many relationship between database tables.
 */
export class IncludeManySelectNode extends PropertyNode {

    /**
     * Constructs a new instance of the IncludeManySelectNode class.
     *
     * @param parent The parent ClassNode.
     * @param propertyKey The property key (or name if you prefer).
     * @param table The database table to include.
     * @param select The column from the included objects to select.
     */
    constructor(parent: ClassNode, propertyKey: string | symbol, public table: string, public select: string) {
        super(parent, propertyKey);
    }

    /**
     * @inheritdoc
     */
    protected *initializeChildren(): Iterable<TreeNode> {
        // No children
    }
}
