/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../shared/database/database.service';
import { DetailsStateParams } from '../../shared/interfaces/DetailsStateParams';
import { CaseFile } from './model/case-file';
import { Event } from './model/event';
import { DateTimePipe, JoinPipe } from '../../shared/pipes';
import { SlideSourceInfo } from '../../schema/SlideSourceInfo';
import PermissionName from '../../permissions/enums/PermissionName';

/**
 * A component that displays the details of a case file.
 */
@Component({
    selector: 'sds-case-file-detail',
    template: require('./case-file-detail.component.html')
})
export class CaseFileDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        this.commentsSlideSourceInfo,
        ['Defendants', { srcHtml: 'app/rms/case-file/slides/defendants.html', permissions: [PermissionName.names] }],
        ['Offenses', { srcHtml: 'app/rms/case-file/slides/offenses.html' }],
        ['Witnesses', { srcHtml: 'app/rms/case-file/slides/witnesses.html', permissions: [PermissionName.names] }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The case file model to display.
     */
    public caseFile: CaseFile;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the CaseFileDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param dateTimePipe A pipe that converts a Date type to a string in the standard Spillman date/time format.
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     * @param involvementsSlideSourceInfo inject the involvement slide from a value.
     * @param commentsSlideSourceInfo inject the comments slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        private dateTimePipe: DateTimePipe,
        private joinPipe: JoinPipe,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * Format functions needed to display a list of items correctly inside of a popup.
     */
    public eventFormatter = (event: Event) => this.joinPipe.transform([this.dateTimePipe.transform(event.when), event.type], ' - ');

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(CaseFile, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.caseFile = detailModel, () => this.onError.emit());
    }
}
