/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

export default class DeepLinkConstants {
    public static readonly IOS_SCHEMA_NAME = 'com.callyo.phone1021://app';
    public static readonly ANDROID_SCHEMA_NAME = 'com.callyo.police_phone_1021';
    public static readonly INTENT_ACTION = 'com.callyo.phone1021://call/';
    public static readonly MOTOROLA_1021_APP = '10-21';
    public static readonly DEFAULT_DIAL_APP = 'Phone';
    public static readonly BROWSER_MESSAGE = 'You can not dial phone from browser.';
    public static readonly REDIRECT_TITLE = 'Which application would you like to use to call';
}
