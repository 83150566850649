/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import IStateService = angular.ui.IStateService;
import { TabsScope } from '../../interfaces/TabsScope';
import { NotificationService } from '../../../notifications/notification-service';
import { CALL_DETAILS_STATE, updateTypes } from './constants';

/**
 * Function which adds the in app notification watchers in the tabs scope
 *
 * @param scope The scope that will be injected the notification watchers
 * @param $state The service that transitions between states
 * @param notificationService The service to provide  in-app notifications logic
 */
export function addNotificationWatcher(scope: TabsScope, $state: IStateService, notificationService: NotificationService) {
    scope.changeState = (state) => {
        $state.go(state);
        if ((state === CALL_DETAILS_STATE) && !scope.hasMissedUpdates()) {
            scope.callUpdateType = updateTypes.NONE;
        } else if (scope.hasMissedUpdates() && !scope.checkIfMissedAssignmentExist()) {
            scope.callUpdateType = updateTypes.UPDATE;
        }
    };

    scope.hasMissedUpdates = () => {
        let missedUpdates = 0;
        Object.keys(notificationService.missedUpdatesList).forEach(key => {
            if (notificationService.missedUpdatesList[key].data) {
                missedUpdates++;
            }
        });
        return !!missedUpdates;
    };

    scope.checkIfMissedAssignmentExist = () => {
        return Object.keys(notificationService.missedUpdatesList).find(callId => (notificationService.missedUpdatesList[callId].assign));
    };

    scope.$watch(() => {
        return notificationService.missedUpdatesList;
    }, (newVal: any, _oldVal: any) => {
        const activeCall = location.href.split('/')[location.href.split('/').length - 1];

        if (!!Object.keys(newVal).length) {
            const isActiveCallUpdate = !!newVal[activeCall];

            if (!isActiveCallUpdate) {
                scope.callUpdateType = scope.checkIfMissedAssignmentExist() ? updateTypes.ASSIGN : updateTypes.UPDATE;
            }
        } else if (!scope.hasMissedUpdates()) {
            scope.callUpdateType = updateTypes.NONE;
        }
    }, true);
}
