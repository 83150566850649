/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { DatabaseService } from '../../../shared/database/database.service';
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import { JailArrest } from './model/jail-arrest';
import { SlideSourceInfo } from '../../../schema/SlideSourceInfo';
import PermissionName from '../../../permissions/enums/PermissionName';

/**
 * A component that displays the details of an arrest.
 */
@Component({
    selector: 'sds-jail-arrest-detail',
    template: require('./jail-arrest-detail.component.html')
})
export class JailArrestDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        ['Addtional Info', { srcHtml: 'app/rms/shared/slides/comments.html' }],
        ['Offenses', { srcHtml: 'app/rms/jail/shared/slides/offenses.html', permissions: PermissionName.offense }],
        ['Booking', { srcHtml: 'app/rms/jail/shared/slides/bookings.html', permissions: PermissionName.booking }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The jail arrest model to display.
     */
    public jailArrest: JailArrest;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the JailArrestDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate the arrest model.
     * @param $stateParams The passed in url parameters.
     * @param involvementsSlideSourceInfo The SlideSourceInfo for the involvements slide.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(JailArrest, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => {
                this.jailArrest = detailModel;
                this.jailArrest.ageAtArrest = this.getAgeAtJailArrest(detailModel.date, detailModel.dob);
            }, () => this.onError.emit());
    }

    /**
     * Calculates the age at arrest.
     *
     * @param arrestDate The date of arrest.
     * @param dob The date of birth.
     */
    private getAgeAtJailArrest(arrestDate: Date, dob: Date): string {
        return arrestDate && dob ? moment(arrestDate).diff(moment(dob), 'years').toString() : undefined;
    }
}
