/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { PlatformDetector } from '../../../app/conditional-injection/platform-detection/platform-detector.service';
import { AppAvailability } from '@ionic-native/app-availability';
import { logEvent } from '../../../app/api/analytics/firebase-analytics-service';

import DeepLinkEvents from './analytics/events';
import DeepLinkConstants from './deep-link-constants';
import { Injectable, Inject } from '@angular/core';
import IonicPopupService = ionic.popup.IonicPopupService;
import { PhoneIdentifier } from '../integration-1021/phoneValidator';

declare const cordova: any;

/**
 * A service to handle external deep link
 */
@Injectable()
export class DeepLinkService {

    /**
     * Constructs a new instance of the DeepLinkService class.
     *
     * @param platformDetector The service that detects which platform app is running.
     */
    constructor(
        private platformDetector: PlatformDetector,
        @Inject('$ionicPopup') private $ionicPopup: IonicPopupService
    ) {}

    /**
     * Detects if custom app is available and triggers specific url to dial in the available app.
     * If custom app is not available, then it opens the phone number in default dial app.
     *
     * @param iosSchemaName App package name of iOS.
     * @param androidPackageName App package name for Android.
     * @param intentUri The intent uri to actually trigger the dial action.
     * @param phoneNumber The phone number to be dialed.
     * @returns void.
     */
    private launchExternalAppPhone(iosSchemaName: string, androidPackageName: string, intentUri: string, phoneNumber: string) {
        let app: string;
        if (this.platformDetector.getPlatform() === 'ios') {
            app = iosSchemaName;
        } else if (this.platformDetector.getPlatform() === 'android') {
            app = androidPackageName;
        } else {
            this.showBrowserPopup();
            return;
        }

        const options = [
            {
                text: DeepLinkConstants.DEFAULT_DIAL_APP,
                onTap: () => {
                    this.handleDefaultDialApp(phoneNumber);
                }
            }
        ];

        AppAvailability.check(app)
            .then(() => {
                let usFormat = PhoneIdentifier.extractPhoneNumber(phoneNumber);

                options.push(
                    {
                        text: DeepLinkConstants.MOTOROLA_1021_APP,
                        onTap: () => {
                            // clean special chars
                            cordova.InAppBrowser.open(intentUri + usFormat, '_system');
                            logEvent(DeepLinkEvents.open_10_21_app);
                        }
                    }
                );
            })
            .finally(() => {
                this.showRedirectPopup(
                    phoneNumber,
                    options
                );
            });
    }

    private handleDefaultDialApp(phoneNumber: string) {
        cordova.InAppBrowser.open('tel:' + phoneNumber, '_system');
        logEvent(DeepLinkEvents.open_default_dial_app);
    }

    private showBrowserPopup() {
        const options = {
            title: DeepLinkConstants.BROWSER_MESSAGE,
            cssClass: 'popup-vertical-buttons',
            buttons: [
                {
                    text: 'Okay'
                }
            ]
        };

        logEvent(DeepLinkEvents.browser_no_dial_action);
        this.$ionicPopup.show(options);
    }

    private showRedirectPopup(phoneNumber: string, options: any[]) {
        // Formats the phoneNumber into the (XXX) XXX-XXXX format
        let formattedPhone = PhoneIdentifier.formatToUSFormat(phoneNumber);

        const tempalte = {
            title: this.getTitle(formattedPhone),
            cssClass: 'popup-vertical-buttons',
            buttons: [
                ...options,
                {
                    text: 'Cancel',
                    onTap: () => {
                        logEvent(DeepLinkEvents.cancel_10_21_action);
                    }
                }
            ]
        };

        this.$ionicPopup.show(tempalte);
    }

    private getTitle(formattedPhone: string) {
        return `${DeepLinkConstants.REDIRECT_TITLE} ${formattedPhone}?`;
    }

    /**
     * Tries to dial the phoneNumber with 10-21 app.
     * If not possible it will dial phoneNumber with default dial app.
     *
     * @param phoneNumber The phone number to be dialed.
     * @returns void.
     */
    public open1021PhoneNumber(phoneNumber: string) {
        // Replace all non-digit caracters with empty string
        const onlyNumbers = phoneNumber.replace(/[^0-9]/g, '');

        logEvent(DeepLinkEvents.trigger_10_21_action);
        this.launchExternalAppPhone(DeepLinkConstants.IOS_SCHEMA_NAME, DeepLinkConstants.ANDROID_SCHEMA_NAME, DeepLinkConstants.INTENT_ACTION, onlyNumbers);
    }
}
