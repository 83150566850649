/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { PropertyNodeFactory } from './property-node-factory';
import { ClassNode } from '../class/class-node';
import { PropertyNode } from './property-node';
import { involvementsAlertsMetadataKey } from '../../../decorators';
import { InvolvementsAlertsNode } from './involvements-alerts-node';

/**
 * A factory that knows how to create InvolvementsAlertsNodes.
 */
@Injectable()
export class InvolvementsAlertsNodeFactory implements PropertyNodeFactory {

    /**
     * @inheritdoc
     */
    public create(parent: ClassNode, propertyKey: string | symbol): PropertyNode {
        const involvementAlertMetadata: symbol = Reflect.getMetadata(involvementsAlertsMetadataKey, parent.classConstructor.prototype, propertyKey);

        if (involvementAlertMetadata) {
            return new InvolvementsAlertsNode(parent, propertyKey);
        }
    }
}
