/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';

/**
 * A component which displays a label and field.
 */
@Component({
    selector: 'sds-display-field',
    template: `
        <dl class="display-field">
            <dt>{{label}}:</dt>
            <dd #ref><ng-content></ng-content></dd>
            <dd *ngIf="ref.innerHTML === ''">&nbsp;</dd>
        </dl>
    `,
    styles: [
        require('./display-field.component.scss'),
        require('./display-field.component.day.scss'),
        require('./display-field.component.night.scss')
    ]
})
export class DisplayFieldComponent {
    /**
     * The label of the field.
     */
    @Input() public label: string;
}
