/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IIntervalService = angular.IIntervalService;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;
import ColorAssigner from '../../ColorAssigner';
import UnitRepository from '../repository/UnitRepository';
import CADListController from '../../CADListController';
import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import AggregatorUnit from '../../../schema/AggregatorUnit';
import ApplicationScope from '../../../ApplicationScope';
import UnitFilterSetting from '../../../settings/implementations/UnitFilterSetting';
import { MyUnitProvider } from '../../my-unit-provider.service';
import { AddressAlertsBatchRequest } from '../../../api/address-alerts/address-alerts-batch-request.service';

/**
 * The controller for the Unit List page.
 */
export default class UnitListController extends CADListController {

    /**
     * The names of the properties that are displayed on the screen.
     */
    public static DISPLAY_PROPERTIES = [
        'unit',
        'status',
        'officer',
        'desc',
        'call.location',
        'call.fullCity.name',
        'call.fullCity.state'
    ];

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        '$scope',
        'colorAssigner',
        'addressAlertsBatchRequest',
        '$interval',
        'unitRepository',
        'unitFilterSetting',
        '$ionicScrollDelegate',
        'myUnitProvider'
    ];

    /**
     * Constructs a new instance of the UnitListController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param colorAssigner The object that assigns a color based on the status of the CAD call.
     * @param addressAlertsBatchRequest The service that retrieves the address alerts for all cad calls.
     * @param $interval The Angular service that repeatedly performs some action on a regular interval.
     * @param unitRepository The repository that stores units pushed from the server.
     * @param unitFilterSetting The setting that determines which units are shown.
     * @param $ionicScrollDelegate A delegate used to control the ion-scroll directive.
     * @param myUnitProvider Provides the current user's unit.
     */
    constructor(
        $scope: ApplicationScope,
        colorAssigner: ColorAssigner,
        addressAlertsBatchRequest: AddressAlertsBatchRequest<AggregatorCadCall>,
        $interval: IIntervalService,
        private unitRepository: UnitRepository,
        public unitFilterSetting: UnitFilterSetting,
        $ionicScrollDelegate: IonicScrollDelegate,
        public myUnitProvider: MyUnitProvider
    ) {
        super($scope, colorAssigner, addressAlertsBatchRequest, $interval, unitRepository.updateEventName, $ionicScrollDelegate);
    }

    /**
     * Gets the array of all units.
     *
     * @returns The array of all units.
     */
    public get units(): AggregatorUnit[] {
        return this.unitRepository.models;
    }

    // @Override
    public get displayProperties(): string[] {
        return UnitListController.DISPLAY_PROPERTIES;
    }

    public $onInit = () => {};

    // @Override
    protected getCalls(): AggregatorCadCall[] {
        return this.units.map(u => u.call).filter(c => !!c);
    }
}
