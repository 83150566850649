/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * A decorator factory that configures the enumerability of the property on which it is placed.
 *
 * @param value Whether the property should be enumerable.
 * @param returns A decorator that configures the enumerability of a property.
 */
export function enumerable(value: boolean = true) {
    return function (_target: any, _propertyKey: string, descriptor: PropertyDescriptor) {
        descriptor.enumerable = value;
    };
}
