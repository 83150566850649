/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ILogService = angular.ILogService;
import BaseJoinedTablesDecoratorTransform from '../../../shared/transforms/BaseJoinedTablesDecoratorTransform';
import JoinedTableMap from '../../../shared/transforms/JoinedTableMap';
import Incident from '../../../schema/Incident';

/**
 * A DecoratorTransform that will add a new property to the Incident for each joined table.
 */
export default class IncidentJoinedTablesDecoratorTransform extends BaseJoinedTablesDecoratorTransform<Incident> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$log'];

    private static JOINED_TABLE_MAP: JoinedTableMap = {
        lwnarr: {
            propertyName: 'narrative',
            isOneToOne: true
        },
        lwoffs: {
            propertyName: 'offenses'
        },
        lwrespo: {
            propertyName: 'respondingOfficers'
        },
        lwstatut: {
            propertyName: 'statutes'
        },
        lwsupl: {
            propertyName: 'supplements'
        },
        lwcasnum: {
            propertyName: 'caseNumbers'
        }
    };

    /**
     * Constructs a new instance of the IncidentJoinedTablesDecoratorTransform class.
     *
     * @param $log The Angular service that performs logging.
     */
    constructor($log: ILogService) {
        super($log);
    }

    // @Override
    protected get joinedTableMap(): JoinedTableMap {
        return IncidentJoinedTablesDecoratorTransform.JOINED_TABLE_MAP;
    }
}
