/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IScope = angular.IScope;
import FileUtilities from '../../../../shared/fileManagement/FileUtilities';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
FileDisplayDirective.$inject = ['fileUtilities'];

/**
 * A directive that displays information about a file.
 *
 * @param fileUtilities A class that provides utility methods for working with files.
 * @returns {IDirective} A directive that displays information about a file.
 */
export default function FileDisplayDirective(fileUtilities: FileUtilities): IDirective {
    return {
        restrict: 'E',
        scope: {
            fileName: '='
        },
        template: require('./fileDisplay.html'),
        link: function ($scope: FileDisplayScope) {
            $scope.$watch('fileName', (newFileName: string) => {
                $scope.extension = fileUtilities.getExtension(newFileName);
            });
        }
    };
}

/**
 * An interface that defines the scope for the FileDisplayDirective.
 */
interface FileDisplayScope extends IScope {

    /**
     * The name of the file to display.
     */
    fileName: string;

    /**
     * The file's extension.
     */
    extension: string;
}
