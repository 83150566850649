/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import angular, { IRootScopeService, IScope } from 'angular';
import CustomFilterOnlyMyCalls from 'app/cad/filtering/custom-filters/CustomFilterOnlyMyCalls';
import MyCallsItem from './MyCallsItem';
import { CALL_ACTIONS } from '../../../../filtering/consts';

/**
 * A component for selecting my calls only in call filtering
 */
export const myCallsComponent: angular.IComponentOptions = {
    template: require('./my-calls-component.component.html'),
    controller:

        /**
         * The controller for the MyCallsComponent.
         */
        class MyCallsComponentController {

            public static $inject = ['$rootScope', '$scope', 'customFilterOnlyMyCalls'];

            constructor(
                $rootScope: IRootScopeService,
                $scope: IScope,
                customFilterOnlyMyCalls: CustomFilterOnlyMyCalls
            ) {
                $scope.$watch(() => {
                    return $scope.myCallsItem;
                }, (newVal: MyCallsItem, oldVal: MyCallsItem) => {
                    if (newVal?.checked) {
                        $rootScope.$broadcast(`${CALL_ACTIONS.DISABLE_FIELDS}`);
                    } else {
                        $rootScope.$broadcast(`${CALL_ACTIONS.ENABLE_FIELDS}`);
                    }
                    customFilterOnlyMyCalls.setValues(newVal);
                }, true);

                $rootScope.$on(CALL_ACTIONS.CLEAN_FIELDS, function() {
                    $scope.myCallsItem.checked = false;
                });

                const preSelectedValues = customFilterOnlyMyCalls.getEditingValues()[0] ;
                if (preSelectedValues !== undefined) {
                    $scope.myCallsItem = new MyCallsItem(preSelectedValues.applied, preSelectedValues.applied);
                } else {
                    $scope.myCallsItem = new MyCallsItem(false, false);
                }
            }

            public $onInit = () => {};
        }
};
