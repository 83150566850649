/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * The set of UnitFilters that are available for choosing on the settings page.
 *
 * Note: These values must match exactly the names of the instances that are registered
 * with the angular module, because they will be loaded using the `$injector` service.
 */
enum UnitFilter {
    filterNone,
    unitFilterByAgency,
    unitFilterByType,
    unitFilterByZone,
    unitNewFilter,
    unitCustomFilter
}

export default UnitFilter;
