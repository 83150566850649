/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipePolyfill as FrameworkDatePipe } from './polyfill-date.pipe';
import { STANDARD_DATE_FORMAT } from './date.pipe';
import { STANDARD_TIME_FORMAT } from './time.pipe';

/**
 * The standardized format in which all date/times will be displayed.
 */
export const STANDARD_DATE_TIME_FORMAT = `${STANDARD_TIME_FORMAT} ${STANDARD_DATE_FORMAT}`;

/**
 * A pipe that formats a date/time in a standardized way.
 */
@Pipe({
    name: 'sdsDateTime'
})
export class DateTimePipe implements PipeTransform {

    /**
     * Constructs a new instance of the DateTimePipe class.
     *
     * @param datePipe The pipe that knows how to format dates.
     */
    constructor(private datePipe: FrameworkDatePipe) {
    }

    /**
     * Displays a date/time in a standardized, human-friendly format.
     *
     * @param value Either a Date or a string representation of a date in the Spillman format.
     */
    public transform(value: string | Date): string {
        return this.datePipe.transform(value, STANDARD_DATE_TIME_FORMAT);
    }
}
