/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import LimitedSizeQueue from '../../shared/data-structures/limited-size-queue';
import countBy = require('lodash/fp/countBy');
import identity = require('lodash/fp/identity');
import toPairs = require('lodash/fp/toPairs');
import orderBy = require('lodash/fp/orderBy');
import map = require('lodash/fp/map');
import flow = require('lodash/fp/flow');

/**
 * Transforms the history into an array where the elements are ordered by how
 * many times the element showed up in the original history.
 * Also, each element shows up only once in the final result.
 *
 * For example:
 *
 * const history = ['A', 'B', 'A', 'C', 'A', 'A', 'C', 'C']; // 4 A's, 3 C's, and 1 B
 * const results = transform(history);
 * // results = ['A', 'C', 'B'];
 */
@Injectable()
export class OrderByCountHistoryTransformation {

    /**
     * Creates an array where the elements are ordered by the number of times
     * that each element showed up in the original history.
     *
     * @param history The history.
     * @returns The new ordered array.
     */
    public transform(history: LimitedSizeQueue<string>): string[] {
        const composedSequence = flow(
            countBy(identity),
            toPairs,
            orderBy(([, count]: [string, number]) => count, 'desc'),
            map(([state]: [string]) => state)
        );

        return composedSequence(Array.from(history));
    }
}
