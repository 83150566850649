/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Type } from '@angular/core';
import { upgradeAdapter } from './upgrade-adapter';

export function bootstrap(appModule: Type<any>) {

    // TODO: Currently, there's an issue where we can't have circular references when using
    // the UpgradeAdapter and nested ngModules. One way to work around this is to use a DummyModule
    // initially and then replace it with the real module later on.
    // See https://github.com/angular/angular/issues/11069#issuecomment-244549445
    // Here we are replacing the DummyModule with the real one.
    upgradeAdapter['ng2AppModule'] = appModule; // eslint-disable-line dot-notation,@typescript-eslint/dot-notation
    upgradeAdapter.bootstrap(document.documentElement, ['patrol'], { strictDi: true });
}
