/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';

/**
 * A wrapper around the Cordova Cookie Master Plugin. All method calls have been promisified,
 * meaning they return promises instead of using callbacks.
 */
@Injectable()
export class CookieMasterWrapper {

    /**
     * Gets the cookie with the specified name for the given URL.
     *
     * @param url The URL for which the cookie was set.
     * @param cookieName The cookie's name.
     * @returns A promise that will be resolved with the cookie's value.
     */
    public getCookieValue(url: string, cookieName: string): Promise<string> {
        return new Promise<string>((resolve, reject) => cookieMaster.getCookieValue(url, cookieName, data => resolve(data.cookieValue), reject));
    }

    /**
     * Sets a cookie with the specified name for the given URL.
     *
     * @param url The URL for which to set the cookie.
     * @param cookieName The cookie's name.
     * @param cookieValue The cookie's value.
     * @returns A promise whose completion indicates that the cookie was set.
     */
    public setCookieValue(url: string, cookieName: string, cookieValue: string): Promise<void> {
        return new Promise<void>((resolve, reject) => cookieMaster.setCookieValue(url, cookieName, cookieValue, resolve, reject));
    }

    /**
     * Clears all cookies.
     *
     * @returns A promise whose completion indicates that all cookies were removed.
     */
    public clear(): Promise<void> {
        return new Promise<void>(resolve => cookieMaster.clear(resolve));
    }
}
