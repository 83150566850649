/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { OffenderTrackingDetailComponent } from './offender-tracking-detail.component';
import { OffenderTrackingOffenseDetailComponent } from './offense/offender-tracking-offense-detail.component';
import { OffenderTrackingVehicleDetailComponent } from './vehicle/offender-tracking-vehicle-detail.component';
import { OffenderTrackingVehicleCommentsBatchRequest } from './data/offender-tracking-vehicle-comments-batch-request.service';
import { OffenderTrackingVehicleCommentsRequestCreator } from './data/offender-tracking-vehicle-comments-request-creator.service';

@NgModule({
    imports: [SharedModule],
    declarations: [
        OffenderTrackingDetailComponent,
        OffenderTrackingOffenseDetailComponent,
        OffenderTrackingVehicleDetailComponent
    ],
    providers: [
        OffenderTrackingVehicleCommentsBatchRequest,
        OffenderTrackingVehicleCommentsRequestCreator
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OffenderTrackingModule { }
