/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { combinePropertyDecorators } from './decorator-helpers';
import { declareProperty } from './declare-property.decorator';

/**
 * The metadata key used to associate the involvements with an object property.
 */
export const involvementsMetadataKey = Symbol('involvements');

/**
 * A decorator factory that associates the involvements with the property on which it is applied.
 *
 * @returns The actual decorator that associates the involvements with the property.
 */
export function involvements() {
    return combinePropertyDecorators(
        declareProperty,

        // Note: The involvementsMetadataKey is used as both the key and the value.
        //       We could have used anything as the value - we really only care about the key.
        Reflect.metadata(involvementsMetadataKey, involvementsMetadataKey)
    );
}
