/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../shared/database/database.service';
import { DetailsStateParams } from '../../shared/interfaces/DetailsStateParams';
import { Business } from './model/business';
import { Alert } from '../name/detail/model/alert';
import { NameLocalId } from '../name/detail/model/name-local-id';
import { JoinPipe } from '../../shared/pipes';
import { SlideSourceInfo } from '../../schema/SlideSourceInfo';
import PermissionName from '../../permissions/enums/PermissionName';

/**
 * A component that displays the details of a business.
 */
@Component({
    selector: 'sds-business-detail',
    template: require('./business-detail.component.html')
})
export class BusinessDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        this.commentsSlideSourceInfo,
        ['Contacts', { srcHtml: 'app/rms/shared/slides/business-contacts.html', permissions: PermissionName.names }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The business model to display.
     */
    public business: Business;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the BusinessDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     * @param involvementsSlideSourceInfo inject the involvement slide from a value.
     * @param commentsSlideSourceInfo inject the comments slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        private joinPipe: JoinPipe,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * Format functions needed to display a list of items correctly inside of a popup.
     */
    public localIdFormatter = (localId: NameLocalId) => this.joinPipe.transform([localId.type, localId.id], ' - ');
    public alertFormatter = (alert: Alert) => alert.description;

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(Business, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => {
                this.business = detailModel;
                this.business.name = {
                    id: this.business.id,
                    first: this.business.first,
                    middle: this.business.middle,
                    last: this.business.last
                };
            }, () => this.onError.emit());
    }
}
