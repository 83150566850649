/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * An alert.
 */
@table('nmalert')
export class Alert {

    @field('code')
    public code: string;

    @expand('code', 'desc')
    public description: string;
}
