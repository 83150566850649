/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../shared/database/database.service';
import { DetailsStateParams } from '../../shared/interfaces/DetailsStateParams';
import { Citation } from './model/citation';
import { JoinPipe } from '../../shared/pipes';
import { SlideSourceInfo } from '../../schema/SlideSourceInfo';
import { Offense } from './model/offense';
import { Circumstance } from './model/circumstance';

/**
 * A component that displays the details of a citation.
 */
@Component({
    selector: 'sds-citation-detail',
    template: require('./citation-detail.component.html')
})
export class CitationDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        this.commentsSlideSourceInfo,
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The citation model to display.
     */
    public citation: Citation;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the CitationDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     * @param involvementsSlideSourceInfo inject the involvement slide from a value.
     * @param commentsSlideSourceInfo inject the comments slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        private joinPipe: JoinPipe,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * Format functions needed to display a list of items correctly inside of a popup.
     */
    public offenseFormatter = (offense: Offense) => this.joinPipe.transform([offense.code, offense.description, offense.disposition], ' - ');
    public circumstanceFormatter = (circumstance: Circumstance) => circumstance.description;

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(Citation, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.citation = detailModel, () => this.onError.emit());
    }
}
