/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../../shared/database/decorators';
import { NameReference } from '../../../shared/model/name-reference';
import { OffenseJoin } from './offense-join';
import { ArrestJoin } from './arrest-join';
import { Involvement } from '../../../../schema/Involvement';

/**
 * The jail-booking model
 */
@table('jlbook')
export class JailBooking {

    @expand('bkagncy', 'desc')
    public bookingAgency: string;

    @expand('dispo', 'descrpt')
    public disposition: string;

    @expand('type', 'descrpt')
    public bookingType: string;

    @field('casenum')
    public caseNumber: string;

    @field('intdate')
    public initialBookingDate: Date;

    @field('curdate')
    public lastIntakeDate: string;

    @field('locnum')
    public localNumber: string;

    @expand('inmate.namenum', NameReference)
    public inmate: NameReference | string;

    @includeOne('jlbknarr', 'text')
    public comments: string;

    @includeMany('jlbkxof', OffenseJoin)
    public offenseJoins: OffenseJoin[];

    @includeMany('jlbkxar', ArrestJoin)
    public arrestJoins: ArrestJoin[];

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
