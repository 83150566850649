/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import { DatabaseService } from '../../../shared/database/database.service';
import { Observable } from 'rxjs/Observable';
import { AddressPipe, JoinPipe } from '../../../shared/pipes';
import { SlideSourceInfo } from '../../../schema/SlideSourceInfo';
import {
    Address,
    Alert,
    CriticalNotice,
    DriverLicenseInfo,
    DriverLicenseType,
    Endorsement,
    InternetAddress,
    ModusOperandi,
    Name,
    NameLocalId,
    NameSubType,
    PhoneNumber,
    Restriction,
    ScarMarkTattoo
} from './model';
import * as moment from 'moment';
import { DeepLinkService } from '../../../../app/shared/deeplink/deep-link-service';

/**
 * A component that displays the details of a name.
 */
@Component({
    selector: 'sds-name-detail',
    template: require('./name-detail.component.html')
})
export class NameDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        this.commentsSlideSourceInfo,
        ['Notices', { srcHtml: 'app/rms/name/detail/slides/notices.html' }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The name model to display.
     */
    public name: Name;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading = false;

    /**
     * Constructs a new instance of the NameDetailComponent.
     *
     * @param $stateParams The passed in url parameters.
     * @param databaseService A service that will auto-magically create a query to get the data needed to populate a model.
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     * @param involvementsSlideSourceInfo The SlideSourceInfo for the involvements slide.
     * @param commentsSlideSourceInfo The SlideSourceInfo for the comments slide.
     * @param changeDetectorRef The ChangeDetectorRef to detect changes to update the view.
     */
    constructor(
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        private databaseService: DatabaseService,
        private joinPipe: JoinPipe,
        private addressPipe: AddressPipe,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo],
        private changeDetectorRef: ChangeDetectorRef,
        public deepLinkService: DeepLinkService
    ) {
    }

    /**
     * Format functions needed to display a list of items correctly inside of a popup.
     */
    public smtFormatter = (smt: ScarMarkTattoo) => this.joinPipe.transform([smt.ncicCode, smt.description], ' - ');
    public moFormatter = (mo: ModusOperandi) => this.joinPipe.transform([mo.factor, mo.method], ' - ');
    public localIdFormatter = (localId: NameLocalId) => this.joinPipe.transform([localId.id, localId.type], ' - ');
    public internetFormatter = (internet: InternetAddress) => this.joinPipe.transform([internet.type, internet.address], ': ');
    public phoneFormatter = (phone: PhoneNumber) => this.joinPipe.transform([phone.phone, phone.type, phone.priority], ' ');
    public licenseFormatter = (license: (Endorsement | Restriction)) => license.description;
    public licenseTypeFormatter = (license: DriverLicenseType) => license.description;
    public alertFormatter = (alert: Alert) => alert.description;
    public nameSubTypeFormatter = (nameSubType: NameSubType) => nameSubType.description;
    public addressFormatter = (address: Address) => this.addressPipe.transform(address);
    public phoneLinkFormatter = (phone: PhoneNumber, isLink: boolean) => {
        const displayValue = this.joinPipe.transform([phone.phone, phone.type, phone.priority], ' ');
        if (!isLink) {
            return displayValue;
        }

        return `<a>${displayValue}</a>`;
    };

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(Name, this.$stateParams.id).concat(
            this.databaseService.get(DriverLicenseInfo, this.$stateParams.id).catch(() => Observable.empty())
        )
            .reduce((acc, model) => {
                return Object.assign(acc, model);
            })
            .finally(() => this.finishedLoading = true)
            .subscribe((model: Name) => {
                this.name = model;
                this.name.phones = this.groupPhoneNumbers(<Name>model);
                this.name.addresses = [<Address>{ city: model.city, state: model.state, street: model.street, zip: model.zip }].concat(this.name.otherAddresses);
                this.name.noticeAlerts = model.notices
                    .filter(notice => this.isDateActive(notice))
                    .sort((noticeA, noticeB) => noticeA.typeCode < noticeB.typeCode ? -1 : 1)
                    .map(notice => notice.type) || undefined;
                this.name.offenderAlerts = model.offenders
                    .filter(offender => offender.disposition === 'Active')
                    .map(offender => offender.riskLevel)
                    .sort()
                    .reverse() || undefined;
            },
            () => this.onError.emit(),
            () => {
                this.finishedLoading = true; this.changeDetectorRef.detectChanges();
            }
            );
    }

    /**
     * Combines the various phone numbers into a single PhoneNumber Array.
     *
     * @param name The model which contains all the phone numbers.
     */
    public groupPhoneNumbers(name: Name): PhoneNumber[] {
        let phones: PhoneNumber[] = name.otherPhones.slice();

        if (name.workPhone) {
            phones.unshift({ phone: name.workPhone, priority: undefined, type: 'Work' });
        }

        if (name.phone) {
            phones.unshift({ phone: name.phone, priority: undefined, type: undefined });
        }

        return phones;
    }

    /**
     * Triggers DeepLinkService to dial phone into 10-21 app or default dial app.
     *
     * @param phone phone to be called.
     */
    public callItem(phone: string) {
        this.deepLinkService.open1021PhoneNumber(phone);
    }

    /**
     * Verifies if the alert's date is active by using its beginning and ending dates.
     *
     * @param alert The CriticalNotice alert.
     */
    private isDateActive = (alert: CriticalNotice): boolean => {
        let now = moment().startOf('day');

        if (alert.beginningDate && now < moment(alert.beginningDate)) {
            return false;
        }

        return !alert.endingDate || now <= moment(alert.endingDate);
    };
}
