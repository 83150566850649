/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { IncidentType } from '../incident-type';
import { PermissionName } from '../../../permissions/enums/PermissionName';

/**
 * An interface that identifies metadata that needs to be known about each incident type.
 */
export interface IncidentSearchMetadata {

    /**
     * The type of incident.
     */
    type: IncidentType;

    /**
     * The name of the state that displays a list of search results.
     */
    listState: string;

    /**
     * The name of the database table that contains the disposition codes.
     */
    dispositionTable: string;

    /**
     * The permission associated with this incident type.
     */
    permission: PermissionName;
}

/**
 * A map that associates metadata with each incident type.
 */
export const incidentSearchMetadataMap = new Map<IncidentType, IncidentSearchMetadata>([
    [IncidentType.Law, {
        type: IncidentType.Law,
        listState: 'app.search.lawIncidentList',
        dispositionTable: 'lwtbdisp',
        permission: PermissionName.law
    }],
    [IncidentType.Fire, {
        type: IncidentType.Fire,
        listState: 'app.search.fireIncidentList',
        dispositionTable: 'frtbdisp',
        permission: PermissionName.fire
    }],
    [IncidentType.EMS, {
        type: IncidentType.EMS,
        listState: 'app.search.emsIncidentList',
        dispositionTable: 'emtbdisp',
        permission: PermissionName.ems
    }]
]);
