/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import Name from '../../../schema/Name';
import Involvement from '../../../schema/Involvement';
import DecoratorTransform from '../../../shared/transforms/DecoratorTransform';

/**
 * A decorator transform that will add the involvements to a name.
 */
export default class NameInvolvementDecoratorTransform implements DecoratorTransform<Name> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['involvementAPI'];

    /**
     * Constructs a new instance of the NameInvolvementDecoratorTransform class.
     *
     * @param involvementAPI The API for retrieving involvements from the server.
     */
    constructor(private involvementAPI: IResourceClass<Involvement>) {
    }

    // @Override
    public invoke(name: Name): void {
        if (name.number) {
            name.involvements = this.involvementAPI.query({
                table: 'nmmain',
                id: name.number
            });
        }
    }
}
