/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../../shared/database/decorators';

/**
 * A fire incident supplemental narrative.
 */
@table('frsupl')
export class Supplement {

    @field('date')
    public date: Date;

    @expand('name', 'fullnam')
    public officer: string;

    @field('narratv')
    public narrative: string;
}
