/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import angular, { IRootScopeService, IScope } from 'angular';

import IonicHistoryService = ionic.navigation.IonicHistoryService;
import IonicPopupService = ionic.popup.IonicPopupService;
import FilterOptionsSubHeaderComponentController from '../../../../filtering/components/baseClasses/filter-options-subheader/filter-options-subheader-component-controller';
import CustomFilterAllFilters from '../../../../filtering/custom-filters/CustomFilterAllFilters';
import FiltersStorage from '../../../../filtering/storage/FiltersStorage';
import UnitFilterSetting from '../../../../../settings/implementations/UnitFilterSetting';
import { unitsAnalyticsEvents } from '../../../../filtering/analytics/events';
import { UNIT_ACTIONS } from '../../../../filtering/consts';
import UnitFilter from '../../../filtering/UnitFilter';

export const unitFilterOptionsSubheader: angular.IComponentOptions = {
    template: require('../../../../filtering/components/baseClasses/filter-options-subheader/filter-options-subheader-component.component.html'),
    bindings: {
        isWideScreen: '<',
    },
    controller: class UnitFilterOptionsSubheader extends FilterOptionsSubHeaderComponentController<UnitFilter> {
        public static $inject = [
            '$rootScope',
            '$ionicHistory',
            '$scope',
            'unitCustomFilterAllFilters',
            '$ionicPopup',
            'unitFilterSetting',
            'unitFiltersStorage',
        ];

        constructor(
            $rootScope: IRootScopeService,
            $ionicHistory: IonicHistoryService,
            $scope: IScope,
            customFilterAllFilters: CustomFilterAllFilters,
            $ionicPopup: IonicPopupService,
            unitFilterSetting: UnitFilterSetting,
            filtersStorage: FiltersStorage,

        ) {
            super(
                filtersStorage,
                $rootScope,
                $ionicHistory,
                $scope,
                customFilterAllFilters,
                $ionicPopup,
                unitFilterSetting,
                unitsAnalyticsEvents,
                UNIT_ACTIONS
            );
        }
    }
};
