/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BatchRequest } from '../batch-request/batch-request.service';
import AddressAlert from '../../schema/AddressAlert';
import { AddressAlertsRequestCreator } from '../address-alerts/address-alerts-request-creator.service';
import { ResponseParser } from '../../api/response-parser.service';
import { Logger } from '../../logging';

/**
 * The interface that a model must implement in order to use the AddressAlertsBatchRequest.
 *
 * TODO: This interface assumes that it's possible to always name the properties "geoid" and "addressAlerts".
 *       If we find that assumption is not true, then we'll most likely need to add abstract methods to the
 *       AddressAlertsBatchRequest to handle different property names.
 */
export interface CanHaveAddressAlerts {

    /**
     * The ID for the geo-verified address.
     */
    geoid: string;

    /**
     * The array of address alerts.
     */
    addressAlerts: AddressAlert[];
}

/**
 * A service that loads all of the address alerts for an entire list of cad calls
 * by making only one request to the server.
 */
@Injectable()
export class AddressAlertsBatchRequest<T extends CanHaveAddressAlerts> extends BatchRequest<T, AddressAlert> {

    /**
     * Constructs a new instance of the AddressAlertsBatchRequest class.
     *
     * @param http The Angular service that makes http requests.
     * @param requestCreator The object that creates the request to retrieve
     *                       the joined items for the list of models.
     * @param responseParser The object that knows how to parse a response from Spillman's API.
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param logger Logs errors to the appropriate location based on the environment.
     */
    constructor(
        http: Http,
        requestCreator: AddressAlertsRequestCreator,
        responseParser: ResponseParser,
        logger: Logger
    ) {
        super(http, requestCreator, responseParser, logger);
    }

    /**
     * @inheritdoc
     */
    protected getPrimaryKey(model: T): string {
        return model.geoid;
    }

    /**
     * @inheritdoc
     */
    protected getForeignKey(alert: AddressAlert): string {
        let alertNumber = alert.number;
        return (typeof alertNumber === 'string') ? alertNumber : alertNumber.value;
    }

    /**
     * @inheritdoc
     */
    protected updateModelWithJoins(model: T, alertsPromise: Promise<AddressAlert[]>): void {
        alertsPromise.then(alerts => model.addressAlerts = alerts);
    }
}
