/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../shared/database/database.service';
import { DetailsStateParams } from '../../shared/interfaces/DetailsStateParams';
import { Want } from './model/want';
import { WantLocalId } from './model/want-local-id';
import { JoinPipe } from '../../shared/pipes';
import { SlideSourceInfo } from '../../schema/SlideSourceInfo';

/**
 * A component that displays the details of a want.
 */
@Component({
    selector: 'sds-want-detail',
    template: require('./want-detail.component.html')
})
export class WantDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        this.commentsSlideSourceInfo,
        ['Offenses', { srcHtml: 'app/rms/want/slides/offenses.html' }],
        ['Attempts', { srcHtml: 'app/rms/want/slides/attempts.html' }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The want model to display.
     */
    public want: Want;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * The formatter that knows how to convert an WantLocalId to a string.
     */
    public localIdFormatter: (localId: WantLocalId) => string;

    /**
     * Constructs a new instance of the WantDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate the want model.
     * @param $stateParams The passed in url parameters.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        joinPipe: JoinPipe,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
        this.localIdFormatter = localId => joinPipe.transform([localId.type, localId.id], ' - ');
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(Want, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.want = detailModel, () => this.onError.emit());
    }
}
