/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import angular, { IRootScopeService, IScope } from 'angular';

import { AgencyService } from '../../../../services/agency/agency-service';
import Session from '../../../../../login/Session';
import AgencyComponentController from '../../../../filtering/components/baseClasses/agency/agency-component.component';
import { UNIT_ACTIONS } from '../../../../filtering/consts';
import UnitCustomFilterByAgencies from '../../../../filtering/units/UnitCustomFilterByAgencies';

/**
 * A component of agencies for call filtering
 */
export const unitsAgencyComponent: angular.IComponentOptions = {
    template: require('./unit-component.component.html'),

    controller: class UnitAgencyComponentController extends AgencyComponentController  {
        public static $inject = ['$scope', '$rootScope', 'unitCustomFilterByAgencies', 'agencyService', 'session'];

        constructor(
            protected $scope: IScope,
            protected $rootScope: IRootScopeService,
            public unitCustomFilterByAgencies: UnitCustomFilterByAgencies,
            public agencyService: AgencyService,
            protected session: Session
        ) {
            super($scope, $rootScope, unitCustomFilterByAgencies, agencyService, session, UNIT_ACTIONS.RETRY_FILTER);
        }
    }
};
