/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand, field } from '../../../shared/database/decorators';

/**
 * A miscellaneous ID for an offender.
 */
@table('somiscid')
export class MiscellaneousId {

    @expand('mitype', 'descrpt')
    public type: string;

    @field('descrpt')
    public description: string;
}
