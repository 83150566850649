/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { OpaqueToken, Provider } from '@angular/core';
import { DefaultFormatter } from './default-formatter.service';
import { ErrorFormatter } from './error-formatter.service';

export * from './formatter';
export const formatterArrayToken = new OpaqueToken('Formatter Array');
export const formatterProviders: Provider[] = [
    // You may add other formatters here, but the ErrorFormatter and DefaultFormatter should be the last two.
    { provide: formatterArrayToken, useClass: ErrorFormatter, multi: true },
    { provide: formatterArrayToken, useClass: DefaultFormatter, multi: true }
];
