/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { NameReference } from '../../shared/model';
import { Animal } from './animal';
import { Bike } from './bike';
import { Contact } from './contact';
import { Vehicle } from './vehicle';
import { Weapon } from './weapon';
import { Involvement } from '../../../schema/Involvement';

/**
 * The license permit model
 */
@table('lpmain')
export class LicensePermit {

    @field('number')
    public id: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @includeMany('lpvehic', Vehicle)
    public vehicles: Vehicle[];

    @includeMany('lpcontac', Contact)
    public contacts: Contact[];

    @includeOne('lpanimal')
    public animal: Animal;

    @includeOne('lpbike')
    public bike: Bike;

    @includeOne('lpweapon')
    public weapon: Weapon;

    @field('agency')
    public agency: string;

    @expand('area', 'desc')
    public area: string;

    @expand('holder', NameReference)
    public permitHolder: NameReference | string;

    @expand('issby', 'fullnam')
    public issuedBy: string;

    @field('locatn')
    public location: string;

    @field('licnum')
    public permitNumber: string;

    @field('type')
    public permitType: string;

    @expand('type', 'action')
    public actionCode: number;

    @expand('status', 'desc')
    public status: string;

    @field('valid')
    public validFrom: Date;

    @field('expires')
    public validTo: Date;

    @includeOne('lpcomm', 'comment')
    public comments: string;
}
