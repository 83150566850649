/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import {
    AddressPipe,
    BooleanPipe,
    CleanPipe,
    DatePipe,
    DatePipePolyfill,
    DateTimePipe,
    GenderPipe,
    JoinPipe,
    TimePipe
} from './index';

/**
 * An array containing all shared pipes.
 * This is the same pattern used by the HTTP_PROVIDERS within the '@angular/http' module.
 * It makes it easy to register all providers.
 */
export const SHARED_PIPE_PROVIDERS = [
    AddressPipe,
    BooleanPipe,
    CleanPipe,
    DatePipe,
    DatePipePolyfill,
    DateTimePipe,
    GenderPipe,
    JoinPipe,
    TimePipe
];
