/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * The license class.
 */
@table('nmdltyp')
export class DriverLicenseType {

    @field('type')
    public type: string;

    @expand('type', 'desc')
    public description: string;
}
