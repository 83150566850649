/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../../shared/database/decorators';

/**
 * A person's job.
 */
@table('nmextra')
export class Job {

    @field('jobdesc')
    public description: string;

    @field('jobphon')
    public phone: string;

    @field('jobloc')
    public location: string;
}
