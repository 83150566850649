/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import { NameReference } from '../../model/name-reference';

/**
 * A component which displays a field which links to a name.
 */
@Component({
    selector: 'sds-name-link',
    template: `
        <sds-permissions-link state="{{businessView ? 'app.search.businessDetails' : 'app.search.namesDetails'}}" [id]="name?.id">
            <sds-name-display [name]="name"></sds-name-display>
        </sds-permissions-link>
    `,
    styles: [
        require('./link.component.scss')
    ]
})
export class NameLinkComponent {

    /**
     * The name to display.
     */
    @Input() public name: NameReference;

    /**
     * View this name as a business
     */
    @Input() public businessView = false;
}
