/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IHttpService = angular.IHttpService;
import CadCall from '../../schema/CadCall';
import CadCallResourceClass from '../CadCall/CadCallResourceClass';
import ComputedUrlResource from '../../resource/computedUrl/ComputedUrlResource';
import IHttpResponseTransformer = angular.IHttpResponseTransformer;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
CadCallAPI.$inject = ['computedUrlResource', '$http', 'getFirstElementTransform'];

/**
 * A factory function that creates an API for retrieving CAD Calls from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @param $http The Angular service that makes http requests.
 * @param getFirstElementTransform A response transform that returns only the first element from the array.
 * @returns {IResourceClass<CadCall>} A resource class that knows how to load CAD Calls from the REST server.
 */
function CadCallAPI(
    computedUrlResource: ComputedUrlResource,
    $http: IHttpService,
    getFirstElementTransform: IHttpResponseTransformer
): CadCallResourceClass {
    return <CadCallResourceClass>computedUrlResource<CadCall>({ path: '/tables/sycad' }, {}, {
        getFirst: {
            method: 'GET',
            transformResponse: angular.appendTransform($http.defaults.transformResponse, getFirstElementTransform)
        }
    });
}

export default CadCallAPI;
