/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IInjectorService = angular.auto.IInjectorService;
import AddPropertiesTransform from '../../../../shared/transforms/AddPropertiesTransform';
import CallRepository from '../../../calls/repository/CallRepository';
import AggregatorUnit from '../../../../schema/AggregatorUnit';
import UnitRepository from '../../../units/repository/UnitRepository';
import Session from '../../../../login/Session';

/**
 * A transform which adds properties to a Unit.
 */
export default class UnitPropertiesTransform extends AddPropertiesTransform<AggregatorUnit> {

    public static $inject = [
        '$injector',
        'session'
    ];

    private _callRepository: CallRepository;
    private _unitRepository: UnitRepository;

    /**
     * Constructs a new instance of the UnitPropertiesTransform class.
     *
     * @param $injector The Angular injector service.
     * @param session The object that stores information about the current user's session.
     */
    constructor(
        private $injector: IInjectorService,
        private session: Session) {
        super();
    }

    // @Override
    protected getPropertyDescriptorMap(unit: AggregatorUnit): PropertyDescriptorMap {
        return {
            call: {
                get: () => {
                    return this.callRepository.modelMap[unit.callIdAndType];
                },
                enumerable: true
            },
            isUsersActiveUnit: {
                get: () => this.isUsersActiveUnit(unit),
                enumerable: true
            }
        };
    }

    /**
     * Gets the call repository.
     *
     * Note: The unit repository depends on this class which depends on the call repository,
     * and the call repository depends on the unit repository. So we have a circular dependency.
     * Angular's dependency injection framework does not allow circular dependencies. So we have to
     * circumvent it by lazily injecting the call repository.
     *
     * @returns {CallRepository} The call repository.
     */
    private get callRepository(): CallRepository {
        if (!this._callRepository) {
            this._callRepository = this.$injector.get<CallRepository>('callRepository');
        }
        return this._callRepository;
    }

    /**
     * Gets the unit repository.
     *
     * Note: The call repository depends on this class which depends on the unit repository,
     * and the unit repository depends on the call repository. So we have a circular dependency.
     * Angular's dependency injection framework does not allow circular dependencies. So we have to
     * circumvent it by lazily injecting the unit repository.
     *
     * @returns {UnitRepository} The unit repository.
     */
    private get unitRepository(): UnitRepository {
        if (!this._unitRepository) {
            this._unitRepository = this.$injector.get<UnitRepository>('unitRepository');
        }
        return this._unitRepository;
    }

    /**
     * Determines if the given unit is the current user's active unit.
     *
     * @param unit The unit to evaluate.
     * @returns True if the given unit is the current user's active unit, false otherwise.
     */
    private isUsersActiveUnit(unit: AggregatorUnit): boolean {
        const myUnit = this.unitRepository.modelMap[this.session.data.unitNumber];
        return !!myUnit && myUnit === unit;
    }
}
