/* Copyright © 2021 Motorola Solutions, Inc. All rights reserved. */

import { PipeTransform } from '@angular/core';

/**
 * An Angular 1 filter that can invoke any Angular 2 pipe.
 */
export class PipeFilter {

    public static $inject = ['$injector'];

    /**
     * Constructs a new instance of the PipeFilter class.
     *
     * @param $injector The Angular injector service.
     */
    constructor(private $injector: angular.auto.IInjectorService) {
    }

    /**
     * Transforms the value by passing it through the specified pipe.
     *
     * @param value The value to the left of the filter.
     * @param pipeName The name of the pipe to invoke.
     * @param args Any collection of arguments to pass to the pipe.
     */
    public transform(value: any, pipeName: string, ...args: any[]): any {
        let pipe;

        try {
            pipe = this.$injector.get<PipeTransform>(pipeName);
        } catch (e) {
            throw new Error(`No provider found for ${pipeName}`);
        }

        return pipe.transform(value, ...args);
    }
}
