/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';

import { Call } from '../../../rms/call/list/model/call';

/**
 * A component which displays the address of a call.
 */
@Component({
    selector: 'sds-call-address',
    template: require('./call-address.component.html')
})
export class CallAddressComponent {

    /**
     * The call which contains the address.
     */
    @Input() public call: Call;
}
