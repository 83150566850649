/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../shared/database/decorators';

/**
 * The traffic stop reference model.
 */
@table('cdtrstop')
export class TrafficStopReference {

    @field('number')
    public id: string;

    @field('plate')
    public plate: string;
}
