/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { AuthenticationTokenBuilder } from './authentication-token-builder';
import { Credentials } from '../Credentials';
import { Observable } from 'rxjs/Observable';

/**
 * An implementation of the `AuthenticationTokenBuilder` interface that builds
 * a token for basic authentication.
 */
@Injectable()
export class BasicAuthenticationTokenBuilder implements AuthenticationTokenBuilder {

    /**
     * @inheritdoc
     */
    public buildAuthenticationToken({ username, password }: Credentials): Observable<string> {
        return Observable.of(`Basic ${btoa(username + ':' + password)}`);
    }
}
