/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';
import { NameReference } from '../../shared/model/name-reference';

/**
 * The Contact model
 */
@table('lpcontac')
export class Contact {

    @field('number')
    public id: string;

    @expand('nameid', NameReference)
    public name: NameReference | string;

    @field('relat')
    public relationship: string;
}
