/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import CallQuery from './CallQuery';
import Name from '../../../../schema/Name';
import NameResourceClass from '../../../../api/name/NameResourceClass';

/**
 * Requests the Complainant associated with the call.
 */
export default class ComplainantAlertsCallQuery extends CallQuery<Name> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['nameAPI'];

    /**
     * Constructs a new instance of the ComplainantAlertsCallQuery class.
     *
     * @param nameAPI The API for retrieving a name from the server.
     */
    constructor(private nameAPI: NameResourceClass) {
        super();
    }

    // @Override
    protected get watchPropertyNames(): string | string[] {
        return ['complainantNumber'];
    }

    // @Override
    protected get updatePropertyName(): string {
        return 'complainant';
    }

    // @Override
    protected performQuery(newValues: any[]): Name {
        return this.nameAPI.getAlerts({ id: newValues[0] });
    }
}
