/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IInjectorService = angular.auto.IInjectorService;
import { QueryFactory } from '../../../query-factory';
import CallQuery from './CallQuery';
import AddressAlertsCallQuery from './AddressAlertsCallQuery';
import CommentsCallQuery from './CommentsCallQuery';
import ComplainantAlertsCallQuery from './ComplainantAlertsCallQuery';
import IncidentHistoryCallQuery from './IncidentHistoryCallQuery';
import RadioLogsCallQuery from './RadioLogsCallQuery';

/**
 * The array of constructor functions for all call queries.
 */
const callQueryTypes = [
    AddressAlertsCallQuery,
    CommentsCallQuery,
    ComplainantAlertsCallQuery,
    RadioLogsCallQuery
];

/**
 * The factory that creates all queries for the call details screen.
 */
export class CallQueryFactory implements QueryFactory<CallQuery<any>> {

    public static $inject = ['$injector'];

    constructor(private $injector: IInjectorService) { }

    /**
     * @inheritdoc
     */
    public create(isInitialQuery?: boolean): CallQuery<any>[] {
        // It is essential that we use `$injector.instantiate` rather than `$injector.get`
        // so that new instances are created each time.
        const initialCallQueryTypes = [...callQueryTypes, IncidentHistoryCallQuery];
        const callQuery = isInitialQuery ? initialCallQueryTypes : callQueryTypes;
        return callQuery.map(type => this.$injector.instantiate<CallQuery<any>>(type));
    }
}
