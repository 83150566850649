/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { JsonSerializer } from './json-serializer';
import LimitedSizeQueue from '../data-structures/limited-size-queue';

/**
 * A base class that handles the serialization/deserialization of a LimitedSizeQueue.
 * Subclasses are responsible for ensuring that each element of the queue is of type T.
 */
export abstract class LimitedSizeQueueJsonSerializer<T> implements JsonSerializer {

    /**
     * Constructs a new instance of the LimitedSizeQueueJsonSerializer class.
     *
     * @param _maxSize The maximum number of items that may be enqueued.
     */
    constructor(private _maxSize: number) {
    }

    /**
     * @returns The maximum number of items that may be enqueued in any
     *          LimitedSizeQueue created by this serializer.
     */
    public get maxSize() {
        return this._maxSize;
    }

    /**
     * @inheritdoc
     */
    public replace = (key: any, value: any) => {
        // If we're at the topmost level of serialization, then return the queue as an array.
        if (key === '') {
            return Array.from(value);
        } else {
            // Otherwise (we're not at the topmost level), so just return the property value.
            return value;
        }
    };

    /**
     * @inheritdoc
     */
    public revive = (key: any, value: any) => {
        // If the key === '', then we're at the topmost level of deserialization.
        if (key === '') {
            this.assertIsArrayOfCorrectType(value);
            return new LimitedSizeQueue<T>(this.maxSize, value);
        } else {
            // If we got here, then we're not yet at the topmost level of deserialization,
            // so just return the value.
            return value;
        }
    };

    /**
     * Determines if the given item is of type T.
     *
     * @param item The item to test.
     * @returns True if the item is of type T, false otherwise.
     */
    protected abstract itemIsCorrectType(item: any): item is T;

    /**
     * Asserts that the value is an array of type T by throwing an error if it is not.
     *
     * @param value The value to assert that it is indeed an array of type T.
     */
    private assertIsArrayOfCorrectType(value: any) {
        if (!Array.isArray(value) || !value.every(this.itemIsCorrectType)) {
            throw new Error('Not a valid LimitedSizeQueue');
        }
    }
}
