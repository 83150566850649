/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { SpillmanLocalStorage } from '../shared/storage/spillman-local-storage';
import KeyMetadata from '../shared/storage/key-metadata';
import LimitedSizeQueue from '../shared/data-structures/limited-size-queue';
import { HistoryJsonSerializer } from './history-json-serializer';

/**
 * A class that handles the storage and retrieval of history items.
 */
export abstract class HistoryStorage<T> {

    /**
     * Constructs a new instance of the HistoryStorage class.
     *
     * @param spillmanLocalStorage The object that handles storing Spillman-specific data in local storage.
     * @param key The key that identifies the value in local storage.
     * @param serializer Handles both serialization to and deserialization from JSON.
     */
    constructor(private spillmanLocalStorage: SpillmanLocalStorage,
        private serializer: HistoryJsonSerializer,
        private key: KeyMetadata) {
    }

    /**
     * Adds the given item as the most recent record in history.
     *
     * @param item The item to add as the most recent record.
     */
    public append(item: T) {
        const history = this.load();
        history.enqueue(item);
        this.spillmanLocalStorage.setObject(this.key, history, this.serializer.replace);
    }

    /**
     * Loads the history from local storage.
     * If the data stored in local storage cannot be parsed, then an empty queue will be returned.
     *
     * @returns The history that was stored in local storage.
     */
    public load(): LimitedSizeQueue<T> {
        let storedQueue: LimitedSizeQueue<T>;

        try {
            storedQueue = <LimitedSizeQueue<T>> this.spillmanLocalStorage.getObject(this.key, this.serializer.revive);
        } catch (error) {
            // TODO: Figure out why this error is being thrown even when we know for a fact that local history wasn't tampered with.
            // TODO: Inject the logger then do: this.logger.warn('The history in local storage cannot be parsed. Most likely, it has been tampered with.');
        }

        return storedQueue || new LimitedSizeQueue<T>(this.serializer.maxSize);
    }

    /**
     * Clears out the history.
     */
    public clear() {
        this.spillmanLocalStorage.remove(this.key);
    }
}
