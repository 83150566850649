/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IScope = angular.IScope;
import { UrlFactory } from '../../../../authentication';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
ImageAttachmentDirective.$inject = ['urlFactory'];

/**
 * A directive that displays an image attachment.
 *
 * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
 * @returns {IDirective} A directive that displays an image attachment.
 */
function ImageAttachmentDirective(urlFactory: UrlFactory): IDirective {
    return {
        restrict: 'E',
        scope: {
            image: '='
        },
        template: require('./imageAttachment.html'),
        link: function ($scope: ImageAttachmentScope) {
            $scope.getThumbnailUrl = (imageId: string) => {
                return !imageId ? undefined : urlFactory.create({
                    path: `/images/${imageId}/thumbnail`
                });
            };
        }
    };
}

export default ImageAttachmentDirective;

/**
 * An interface that defines the scope for the ImageAttachmentDirective.
 */
interface ImageAttachmentScope extends IScope {

    /**
     * Get the URL that points to the image's thumbnail.
     *
     * @param imageId The image to get the URL of.
     * @returns The image URL.
     */
    getThumbnailUrl(imageId: string): string;
}
