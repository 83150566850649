/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * A restriction.
 */
@table('nmdlrest')
export class Restriction {
    @field('restrct')
    public code: string;

    @expand('restrct', 'descrpt')
    public description: string;
}
