/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import 'rxjs/add/operator/switchMap';

import { DetailsStateParams } from '../../../../shared/interfaces/DetailsStateParams';
import { DatabaseService } from '../../../../shared/database/database.service';
import { JoinPipe } from '../../../../shared/pipes';
import { SlideSourceInfo } from '../../../../schema/SlideSourceInfo';
import PermissionName from '../../../../permissions/enums/PermissionName';
import { RadioLogService } from '../../../shared/radio-log/radio-log.service';
import {
    Circumstance,
    LawIncident,
    ModusOperandi,
    Offense,
    RespondingOfficer
} from './model';
import { StatuteService } from './statutes/statute.service';

/**
 * A component that displays the details of a law incident.
 */
@Component({
    selector: 'sds-law-incident-detail',
    template: require('./law-incident-detail.component.html'),
    providers: [DatabaseService, RadioLogService]
})
export class LawIncidentDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * The law incident model to display.
     */
    public law: LawIncident;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading = false;

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        ['Narrative', { srcHtml: 'app/rms/shared/slides/comments.html', permissions: [PermissionName.lwnarr] }],
        ['Supplements', { srcHtml: 'app/rms/shared/slides/supplements.html', permissions: [PermissionName.lwsupl] }],
        this.radioLogsSlideSourceInfo,
        this.involvementsSlideSourceInfo
    ]);

    /**
     * Constructs a new instance of the LawIncidentDetailComponent.
     *
     * @param databaseService A service that will retrieve the data to populate a model.
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     * @param radioLogService A service that retrieves and attaches radio logs to an incident.
     * @param statuteService A service that retrieves and attaches the statutes to the incident.
     * @param $stateParams The passed in url parameters.
     * @param involvementsSlideSourceInfo The SlideSourceInfo for involvements which is appended to slideUrlMap.
     * @param radioLogsSlideSourceInfo The SlideSourceInfo for radio logs which is appended to slideUrlMap.
     * @param changeDetectorRef The ChangeDetectorRef to detect changes to update the view.
     */
    constructor(
        private databaseService: DatabaseService,
        private joinPipe: JoinPipe,
        private radioLogService: RadioLogService<LawIncident>,
        private statuteService: StatuteService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('radioLogsSlideSourceInfo') private radioLogsSlideSourceInfo: [string, SlideSourceInfo],
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Format functions needed to display a list of items correctly inside of a popup.
     */
    public modusOperandiFormatter = (mo: ModusOperandi) => this.joinPipe.transform([mo.factor, mo.method], ' - ');
    public circumstanceFormatter = (circumstance: Circumstance) => circumstance.description;
    public offenseFormatter = (offense: Offense) => offense.description;
    public respondingOfficerFormatter = (respondingOfficer: RespondingOfficer) => respondingOfficer.name;

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(LawIncident, this.$stateParams.id)
            .switchMap(this.radioLogService.attachRadioLogs)
            .switchMap(this.statuteService.attachStatutes)
            .finally(() => this.finishedLoading = true)
            .subscribe(
                detailModel => this.law = detailModel,
                () => this.onError.emit(),
                () => {
                    this.finishedLoading = true; this.changeDetectorRef.detectChanges(); 
                }
            );
    }
}
