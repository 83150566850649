/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import { upgradeAdapter } from '../upgrade-adapter';
import { accidentModule } from './accident/accident.module.ng1';
import { jailModule } from './jail/jail.module.ng1';
import { incidentModule } from './incident/incident.module.ng1';
import { nameModule } from './name/name.module.ng1';
import { propertyModule } from './property/property.module.ng1';
import { vehicleModule } from './vehicle/vehicle.module.ng1';
import { attachmentModule } from './shared/attachments/attachment.module.ng1';
import { callModule } from './call/call.module.ng1';
import { EvidenceDetailComponent } from './evidence/evidence-detail.component';
import { TrafficWarningDetailComponent } from './traffic-warning/traffic-warning-detail.component';
import { TrafficStopDetailComponent } from './traffic-stop/traffic-stop-detail.component';
import { CivilProcessDetailComponent } from './civil-process/civil-process-detail.component';
import { FieldInterviewDetailComponent } from './field-interview/field-interview-detail.component';
import { WantDetailComponent } from './want/want-detail.component';
import { DemographicDetailComponent } from './demographic/demographic-detail.component';
import { MiscellaneousDetailComponent } from './miscellaneous/miscellaneous-detail.component';
import { CitationDetailComponent } from './citation/citation-detail.component';
import { DisseminationDetailComponent } from './dissemination/dissemination-detail.component';
import { FireFieldInterviewDetailComponent } from './fire-field-interview/fire-field-interview-detail.component';
import PermissionName from '../permissions/enums/PermissionName';
import { createDetailState } from './state-definition-helpers';

import { SuspectNameDetailComponent } from './suspect-name/suspect-name-detail.component';
import { SuspectVehicleDetailComponent } from './suspect-vehicle/suspect-vehicle-detail.component';
import { OffenderTrackingDetailComponent } from './offender-tracking/offender-tracking-detail.component';
import { OffenderTrackingOffenseDetailComponent } from './offender-tracking/offense/offender-tracking-offense-detail.component';
import { OffenderTrackingVehicleDetailComponent } from './offender-tracking/vehicle/offender-tracking-vehicle-detail.component';
import { VehicleImpoundDetailComponent } from './vehicle-impound/vehicle-impound-detail.component';
import { NameAssociationDetailComponent } from './name-association/name-association-detail.component';
import { LicensePermitDetailComponent } from './license-permit/license-permit-detail.component';
import { PawnedPropertyDetailComponent } from './pawned-property/pawned-property-detail.component';
import { PawnActivityDetailComponent } from './pawn-activity/pawn-activity-detail.component';
import * as Links from './shared/components/links';
import { BusinessDetailComponent } from './business/business-detail.component';
import { PremiseDetailComponent } from './premise/premise-detail.component';
import { CaseFileDetailComponent } from './case-file/case-file-detail.component';
import { TableInfo } from './table-info';
import { TableInfoPipe } from './shared/pipes/table-info.pipe';
import { InvolvementListComponent } from './shared/components/involvement-list/involvement-list.component';
import { AlertComponent } from './shared/components/alert/alert.component';

export const rmsModule = angular.module('rms', [
    accidentModule.name,
    attachmentModule.name,
    callModule.name,
    jailModule.name,
    incidentModule.name,
    nameModule.name,
    propertyModule.name,
    vehicleModule.name
])
    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.search.businessDetails', createDetailState('business', 'nmmain', { permission: PermissionName.business, title: 'Business' }))
            .state('app.search.caseFileDetails', createDetailState('case-file', 'pcmain', { permission: PermissionName.pcmain }))
            .state('app.search.citationDetails', createDetailState('citation', 'ctmain', { permission: PermissionName.citation }))
            .state('app.search.civilProcessDetails', createDetailState('civil-process', 'cpmain', { permission: PermissionName.process }))
            .state('app.search.demographicDetails', createDetailState('demographic', 'rcmain', { permission: PermissionName.rcmain }))
            .state('app.search.disseminationDetails', createDetailState('dissemination', 'dsmain', { permission: PermissionName.dsmain }))
            .state('app.search.evidenceDetails', createDetailState('evidence', 'evmain', { permission: PermissionName.evidence }))
            .state('app.search.fieldInterviewDetails', createDetailState('field-interview', 'fimain', { permission: PermissionName.fldint }))
            .state('app.search.fireFieldInterviewDetails', createDetailState('fire-field-interview', 'frfimain', { permission: PermissionName.frfldint }))
            .state('app.search.licensePermitDetails', createDetailState('license-permit', 'lpmain', { permission: PermissionName.license }))
            .state('app.search.miscellaneousDetails', createDetailState('miscellaneous', 'inmisc', { permission: PermissionName.inmisc }))
            .state('app.search.nameAssociationDetails', createDetailState('name-association', 'nmassoc', { permission: PermissionName.nmassoc }))
            .state('app.search.offenderTrackingDetails', createDetailState('offender-tracking', 'somain', { permission: PermissionName.offenders }))
            .state('app.search.offenderTrackingOffenseDetails', createDetailState('offender-tracking-offense', 'sooffs', { permission: PermissionName.sooffs }))
            .state('app.search.offenderTrackingVehicleDetails', createDetailState('offender-tracking-vehicle', 'sovehic', { permission: PermissionName.sovehic }))
            .state('app.search.pawnedPropertyDetails', createDetailState('pawned-property', 'pnmain', { permission: PermissionName.pawns }))
            .state('app.search.pawnActivityDetails', createDetailState('pawn-activity', 'pnactiv', { permission: PermissionName.pawnact }))
            .state('app.search.premiseDetails', createDetailState('premise', 'bimain', { permission: PermissionName.premises }))
            .state('app.search.suspectNameDetails', createDetailState('suspect-name', 'nmspmain', { permission: PermissionName.snames }))
            .state('app.search.suspectVehicleDetails', createDetailState('suspect-vehicle', 'vhspmain', { permission: PermissionName.svehicle }))
            .state('app.search.trafficWarningDetails', createDetailState('traffic-warning', 'twmain', { permission: PermissionName.warning }))
            .state('app.search.trafficStopDetails', createDetailState('traffic-stop', 'cdtrstop', { permission: PermissionName.cdtrstop }))
            .state('app.search.vehicleImpoundDetails', createDetailState('vehicle-impound', 'vimain', { permission: PermissionName.impound }))
            .state('app.search.wantDetails', createDetailState('want', 'wamain', { permission: PermissionName.wants }));
    }])

    .constant('tableInfoMap', new Map<string, TableInfo>([
        ['acmain', { name: 'Accident', mainState: 'app.search.accidentDetails' }],
        ['acnmdtl', { name: 'Accident Name', mainState: 'app.search.accidentNameDetails' }],
        ['acrddtl', { name: 'Accident Roadway', mainState: 'app.search.accidentRoadwayDetails' }],
        ['acvhdtl', { name: 'Accident Vehicle', mainState: 'app.search.accidentVehicleDetails' }],
        ['bibusin', { name: 'Business' }],
        ['biinsp', { name: 'Premise - Inspection' }],
        ['bimain', { name: 'Premises', mainState: 'app.search.premiseDetails' }],
        ['cdcall', { name: 'Cad Call', mainState: 'app.search.callDetails' }],
        ['cdtrstop', { name: 'Traffic Stop', mainState: 'app.search.trafficStopDetails' }],
        ['cdunit', { name: 'Units' }],
        ['cpmain', { name: 'Civil Process', mainState: 'app.search.civilProcessDetails' }],
        ['ctmain', { name: 'Citation', mainState: 'app.search.citationDetails' }],
        ['dsmain', { name: 'Dissemination', mainState: 'app.search.disseminationDetails' }],
        ['emmain', { name: 'EMS Incident', mainState: 'app.search.emsIncidentDetails' }],
        ['evmain', { name: 'Evidence', mainState: 'app.search.evidenceDetails' }],
        ['fcmain', { name: 'File Attachments' }],
        ['fimain', { name: 'Field Interview', mainState: 'app.search.fieldInterviewDetails' }],
        ['frfimain', { name: 'Fire Field Interview', mainState: 'app.search.fireFieldInterviewDetails' }],
        ['frmain', { name: 'Fire Incident', mainState: 'app.search.fireIncidentDetails' }],
        ['image', { name: 'Images' }],
        ['inmisc', { name: 'Miscellaneous', mainState: 'app.search.miscellaneousDetails' }],
        ['jlarrest', { name: 'Jail Arrest', mainState: 'app.search.jailArrestDetails' }],
        ['jlbook', { name: 'Jail Booking', mainState: 'app.search.jailBookingDetails' }],
        ['jlincdnt', { name: 'Jail Incident', mainState: 'app.search.jailIncidentDetails' }],
        ['jloffens', { name: 'Jail Offense', mainState: 'app.search.jailOffenseDetails' }],
        ['jmmain', { name: 'Legacy Jail'}],
        ['lpmain', { name: 'License Permit', mainState: 'app.search.licensePermitDetails' }],
        ['lwcase', { name: 'Case Management' }],
        ['lwmain', { name: 'Law Incident', mainState: 'app.search.lawIncidentDetails' }],
        ['lwsupl', { name: 'Supplemental Narratives' }],
        ['nmassoc', { name: 'Name Association', mainState: 'app.search.nameAssociationDetails' }],
        ['nmcrtnot', { name: 'Critical Notices' }],
        ['nmmain', { name: 'Name', mainState: 'app.search.namesDetails' }],
        ['nmspmain', { name: 'Suspect Name', mainState: 'app.search.suspectNameDetails' }],
        ['pcmain', { name: 'Case File', mainState: 'app.search.caseFileDetails' }],
        ['pnactiv', { name: 'Pawn Activity', mainState: 'app.search.pawnActivityDetails' }],
        ['pnmain', { name: 'Pawned Property', mainState: 'app.search.pawnedPropertyDetails' }],
        ['prmain', { name: 'Property', mainState: 'app.search.propertiesDetails' }],
        ['rcmain', { name: 'Demographic Summary', mainState: 'app.search.demographicDetails' }],
        ['sooffs', { name: 'Offender Offense', mainState: 'app.search.offenderTrackingOffenseDetails' }],
        ['somain', { name: 'Offender Tracking', mainState: 'app.search.offenderTrackingDetails' }],
        ['sovehic', { name: 'Offender Vehicle', mainState: 'app.search.offenderTrackingVehicleDetails' }],
        ['syinvolv', { name: 'Involvements' }],
        ['twmain', { name: 'Traffic Warning', mainState: 'app.search.trafficWarningDetails' }],
        ['vhmain', { name: 'Vehicle', mainState: 'app.search.vehiclesDetails' }],
        ['vhspmain', { name: 'Suspect Vehicle', mainState: 'app.search.suspectVehicleDetails' }],
        ['vimain', { name: 'Impounded Vehicle', mainState: 'app.search.vehicleImpoundDetails' }],
        ['wamain', { name: 'Wanted Person', mainState: 'app.search.wantDetails' }]
    ]))

    // Detail Screen Directives
    .directive('sdsBusinessDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(BusinessDetailComponent))
    .directive('sdsCaseFileDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(CaseFileDetailComponent))
    .directive('sdsCitationDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(CitationDetailComponent))
    .directive('sdsCivilProcessDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(CivilProcessDetailComponent))
    .directive('sdsDemographicDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(DemographicDetailComponent))
    .directive('sdsDisseminationDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(DisseminationDetailComponent))
    .directive('sdsEvidenceDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(EvidenceDetailComponent))
    .directive('sdsFieldInterviewDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(FieldInterviewDetailComponent))
    .directive('sdsFireFieldInterviewDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(FireFieldInterviewDetailComponent))
    .directive('sdsLicensePermitDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(LicensePermitDetailComponent))
    .directive('sdsMiscellaneousDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(MiscellaneousDetailComponent))
    .directive('sdsNameAssociationDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(NameAssociationDetailComponent))
    .directive('sdsOffenderTrackingDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(OffenderTrackingDetailComponent))
    .directive('sdsOffenderTrackingOffenseDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(OffenderTrackingOffenseDetailComponent))
    .directive('sdsOffenderTrackingVehicleDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(OffenderTrackingVehicleDetailComponent))
    .directive('sdsPawnedPropertyDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(PawnedPropertyDetailComponent))
    .directive('sdsPawnActivityDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(PawnActivityDetailComponent))
    .directive('sdsPremiseDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(PremiseDetailComponent))
    .directive('sdsSuspectNameDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(SuspectNameDetailComponent))
    .directive('sdsSuspectVehicleDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(SuspectVehicleDetailComponent))
    .directive('sdsTrafficWarningDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(TrafficWarningDetailComponent))
    .directive('sdsTrafficStopDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(TrafficStopDetailComponent))
    .directive('sdsVehicleImpoundDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(VehicleImpoundDetailComponent))
    .directive('sdsWantDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(WantDetailComponent))

    // Link Directives
    .directive('sdsNameDisplay', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(Links.NameDisplayComponent))
    .directive('sdsNameLink', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(Links.NameLinkComponent))
    .directive('sdsVehicleDisplay', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(Links.VehicleDisplayComponent))
    .directive('sdsVehicleLink', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(Links.VehicleLinkComponent))

    // Other Directives
    .directive('sdsInvolvementList', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(InvolvementListComponent))
    .directive('sdsAlert', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(AlertComponent))

    // Shareable Slides
    .value('commentsSlideSourceInfo', ['Comments', { srcHtml: 'app/rms/shared/slides/comments.html' }])
    .value('radioLogsSlideSourceInfo', ['Radio Logs', { srcHtml: 'app/rms/shared/slides/radio-logs.html' }])

    // Services
    .service('sdsTableInfo', upgradeAdapter.downgradeNg2Provider(TableInfoPipe));

upgradeAdapter.upgradeNg1Provider('$stateParams');
upgradeAdapter.upgradeNg1Provider('commentsSlideSourceInfo');
upgradeAdapter.upgradeNg1Provider('radioLogsSlideSourceInfo');
upgradeAdapter.upgradeNg1Provider('tableInfoMap');
