/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ExtendedWebSocketConfig from './ExtendedWebSocketConfig';
import ExponentialBackoffReconnectTiming from './../reconnectTiming/ExponentialBackoffReconnectTiming';

const persistentWebSocketDefaultConfig: ExtendedWebSocketConfig = {
    nativeEventPrefix: 'ws:',
    reconnectTiming: new ExponentialBackoffReconnectTiming(1000, 3 * 60 * 1000), // initial = 1s, max = 3min
    heartbeatConfig: {
        messageName: 'heartbeat',
        sendInterval: 15 * 1000,
        connectionLostTimeout: 30 * 1000
    }
};

export default persistentWebSocketDefaultConfig;
