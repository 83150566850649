/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { DatabaseService } from '../../../../../shared/database/database.service';
import { Statute } from './statute';
import { StatuteService } from './statute.service';

/**
 * Gets the statutes from the old 'lwstatut' table.
 * This service is used if the server version is older than 1701.
 */
@Injectable()
export class OriginalStatuteService extends StatuteService {

    constructor(private databaseService: DatabaseService) {
        super();
    }

    /**
     * @inheritdoc
     */
    protected getStatutes(incidentId: string): Observable<string[]> {
        return this.databaseService.getAll(Statute, `number=${incidentId}`)
            .map(({ results: statutes }) => statutes.map(s => s.description));
    }
}
