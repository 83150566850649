/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import { Http, URLSearchParams, RequestOptions } from '@angular/http';
import { Logger } from './logger';
import { LoggerConfig } from './logger-config.service';
import { UrlFactory } from '../authentication';
import { injectionCondition } from '../conditional-injection';
import { Formatter, formatterArrayToken } from './formatters';

/**
 * Logs errors to the server when the app is run in production mode.
 */
@Injectable()
@injectionCondition({ environment: 'production' })
export class ProductionLogger extends Logger {

    /**
     * Constructs a new instance of the ProductionLogger class.
     *
     * @param config The configuration that determines which logging levels will actually be logged.
     * @param formatters The array of formatters that will convert the message to a human-friendly string.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param http The Angular service that makes http requests.
     * @param globalRequestOptions The request options that get passed to every http call made in Angular 2.
     */
    constructor(
        config: LoggerConfig,
        @Inject(formatterArrayToken) formatters: Formatter<any>[],
        private urlFactory: UrlFactory,
        private http: Http,
        private globalRequestOptions: RequestOptions
    ) {
        super(config, formatters);
    }

    /**
     * @inheritdoc
     */
    protected performLogging(level: string, message: string): void {
        const url = this.urlFactory.create({ path: '/logging/log' });

        const params = new URLSearchParams();
        params.set('type', level);
        params.set('message', message);

        // We must make a copy of the global request options so that we pick up the Authorization header.
        // We can then add additional headers and search parameters.
        const requestOptions = this.globalRequestOptions.merge();
        requestOptions.headers.set('Content-Type', 'application/x-www-form-urlencoded');
        requestOptions.search = params;

        this.http.post(url, undefined, requestOptions).subscribe({
            error: () => {
                // We need to catch the error so it doesn't recursively call the `LoggingErrorHandler`,
                // but otherwise there is nothing to do here.
            }
        });
    }
}
