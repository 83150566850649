/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import CustomFilterAllFilters from '../custom-filters/CustomFilterAllFilters';
import FiltersStorage from '../storage/FiltersStorage';
import CustomFilterByZones from '../custom-filters/CustomFilterByZones';
import CustomFilterByAgencies from '../custom-filters/CustomFilterByAgencies';
import CustomFilterByTypes from '../custom-filters/CustomFilterByTypes';
import FilterByUnit from '../filters/FilterByUnit';

/**
 * Filters the calls so that only those whose agency, type or zone is the same as the selected ones, will be shown.
 * As with all call filters, any calls that are assigned to the user will be shown no matter what -
 * they cannot be filtered out.
 */
export default class UnitCustomFilterAllFilters extends CustomFilterAllFilters {
    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['unitCustomFilterByTypes', 'unitCustomFilterByAgencies', 'unitCustomFilterByZones', 'unitFiltersStorage', 'filterByUnit'];

    /**
     * Constructs a new instance of the UnitCustomFilterAllFilters class.
     *
     * @param customFilterByTypes The filter that includes any calls that have the selected types.
     * @param customFilterByAgencies The filter that includes any calls that have the selected agencies.
     * @param customFilterByZones The filter that includes calls from the selected zones.
     * @param filtersStorage The filter storage manager that saves and loads saved filters.
     *///
    constructor(
        customFilterByTypes: CustomFilterByTypes,
        customFilterByAgencies: CustomFilterByAgencies,
        customFilterByZones: CustomFilterByZones,
        filtersStorage: FiltersStorage,
        filterByUnit: FilterByUnit
    ) {
        super(undefined, undefined, customFilterByTypes, customFilterByAgencies, undefined, customFilterByZones, filtersStorage, filterByUnit);
    }
}
