/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Response } from '@angular/http';

/**
 * The exception that will be thrown if there is any error parsing the server response.
 */
export const INVALID_RESPONSE_ERROR_MESSAGE = 'Invalid server response';

/**
 * This class simplifies parsing the result that is returned by Spillman's API.
 * For example
 */
export class ResponseParser {

    /**
     * Use this method to parse the server response when an array can be returned.
     *
     * @param response The response returned by the server.
     * @returns The array of results returned by the server.
     */
    public parseArray = <T>(response: Response): T[] => {
        let data: any;

        try {
            // Responses from the server typically come wrapped in an object with a `data` property.
            // This is the Spillman Tables API convention -- not Angular's.
            //
            // However, there is a discrepancy between the `/tables/{table}/{primaryKey}` endpoint
            // and the `/tables/{table}` endpoint.
            //
            // The `/tables/{table}/{primaryKey}` endpoint returns an empty object (no `data` property)
            // if the requested record does not exist.
            //
            // However, the `/tables/{table}` endpoint still returns an object with a `data` property
            // that is set to an empty array if the requested record does not exist.
            //
            // So we're normalizing it here by ensuring an empty array is returned in all cases.
            data = response.json().data || [];
        } catch (err) {
            throw new Error(INVALID_RESPONSE_ERROR_MESSAGE);
        }

        if (!Array.isArray(data)) {
            throw new Error(INVALID_RESPONSE_ERROR_MESSAGE);
        }

        // It is possible at this point to loop through each element of the array
        // and ensure that it is indeed of type T.
        // However that would require us to create a type guard for each schema
        // model that we query for. That would require a lot of development time.
        // So even though it is possible, we won't implement it at this time.

        return <T[]>data;
    };

    /**
     * Use this method to parse the server response when there can be at most one result returned.
     * Such is the case if you perform a query using a single primary key.
     * It is an error to try to use this method when multiple objects may be returned.
     *
     * @param response The response returned by the server.
     * @returns The object returned by the server or undefined if no object was found.
     */
    public parse = <T>(response: Response): T => {
        // The `data` property is always an array. So we'll first get the array
        // and then return (what should be) the only element.
        const objectArray = this.parseArray<T>(response);

        if (objectArray.length > 1) {
            throw new Error(INVALID_RESPONSE_ERROR_MESSAGE);
        }

        // It is possible that no result was found, in which case, this will return undefined.
        return objectArray[0];
    };
}
