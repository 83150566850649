/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * An efficient implementation of a queue data structure based on the code by Stephen Morley
 * http://code.stephenmorley.org/javascript/queues/
 */
export class Queue<T> {

    /**
     * The internal array that is used to store the items.
     */
    private innerArray: T[] = [];

    /**
     * An index that points to the start of the queue.
     */
    private _offset = 0;

    /**
     * Constructs a new instance of the Queue class.
     *
     * @param initialItems An initial array of items to enqueue.
     */
    constructor(initialItems?: T[]) {
        if (initialItems) {
            initialItems.forEach(i => this.enqueue(i));
        }
    }

    /**
     * The iterator for this class.
     */
    public [Symbol.iterator](): IterableIterator<T> {
        return this.getEnqueuedItems()[Symbol.iterator]();
    }

    /**
     * Adds an item at the end of the queue.
     *
     * @param item The item to enqueue.
     */
    public enqueue(item: T): void {
        this.innerArray.push(item);
    }

    /**
     * Removes the item at the beginning of the queue.
     * `undefined` is returned if the queue is empty.
     *
     * @returns {T} The item to dequeue.
     */
    public dequeue(): T {
        if (this.isEmpty) {
            return undefined;
        }

        // Save off the item to be returned.
        let item = this.innerArray[this._offset];

        // Increment the offset, and if the array is at least half empty, then remove the free space.
        if (++this._offset * 2 >= this.innerArray.length) {
            this.innerArray = this.getEnqueuedItems();
            this._offset = 0;
        }

        return item;
    }

    /**
     * Returns, but does not remove, the item at the start of the queue.
     * `undefined` is returned if the queue is empty.
     *
     * @returns {T} The item at the beginning of the queue.
     */
    public peek(): T {
        if (this.isEmpty) {
            return undefined;
        }

        return this.innerArray[this._offset];
    }

    /**
     * Gets the number of items in the queue.
     *
     * @returns {number} The number of items in the queue.
     */
    public get size(): number {
        return this.innerArray.length - this._offset;
    }

    /**
     * Determines if the queue is empty.
     *
     * @returns {boolean} True if the queue is empty, false otherwise.
     */
    public get isEmpty(): boolean {
        return this.size === 0;
    }

    /**
     * Gets an array that contains those items that have been enqueued, but not dequeued.
     *
     * Note: This is a subset of the `innerArray`, because the `innerArray` may still
     * contain items that have been dequeued but not yet cleaned up.
     *
     * @returns An array that contains those items that have been enqueued, but not dequeued.
     */
    private getEnqueuedItems() {
        return this.innerArray.slice(this._offset);
    }
}
