/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';

/**
 * The demographic officer involved detail model
 */
@table('rcoffrdl')
export class OfficerInvolved {

    @expand('offr', 'fullnam')
    public fullName: string;
}
