/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import CustomFilterByAgencies from '../../../cad/filtering/custom-filters/CustomFilterByAgencies';

export class AgencyModel implements FilterModel {

    public abbreviation: string;
    public description: string;
    public ceoname: string;
    public totil: string;
    public name: string;
    public nameid: string;
    public city: string;
    public county: string;
    public state: string;
    public ori: string;
    public privid: string;
    public type: string;
    public dtype: string;
    public rpthead: string;
    public zip: string;
    public accordionLabel: string;
    public checked: boolean;
    public applied: boolean;
    public storageKey: string = CustomFilterByAgencies.STORAGE_KEY;
    public id: string;

    constructor({ abbreviation = '', description = '', name = '', state = '', ori = '', checked = false, applied = false  }: any) {
        this.abbreviation = abbreviation;
        this.description = description;
        this.name = name;
        this.accordionLabel = this.getAccordionAgencyLabel();
        this.checked = checked;
        this.applied = applied;
        this.id = abbreviation;
    }

    public static createModelFromObject = (data: Object): AgencyModel => {
        if (data === undefined) {
            return undefined;
        }

        const tableNames = { abbreviation: 'abbr', description: 'desc', name: 'name' };

        const abbreviation = data[tableNames.abbreviation];
        const description = data[tableNames.description];
        const name = data[tableNames.name];

        return new AgencyModel({ abbreviation: abbreviation, description: description, name: name });
    };

    public static orderListByAbbr = (listOfAgencies: AgencyModel[]) => {
        return listOfAgencies.sort(function (a: AgencyModel, b: AgencyModel) {
            let textA = a.abbreviation.toUpperCase();
            let textB = b.abbreviation.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    };

    public getAccordionAgencyLabel = () => {
        return `${this.abbreviation} - ${this.description}`;
    };


    public verifyAndApplyUserLabel(userLabel: string): boolean {
        const isUserData = this.abbreviation === userLabel;

        if (isUserData) {
            this.accordionLabel += ' (My Agency)';
        }

        return isUserData;
    }
}
