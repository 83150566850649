/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../shared/database/decorators';

/**
 * The sub type of a name.
 */
@table('nmsbtyp')
export class NameSubType {

    @expand('subtype', 'descrpt')
    public description: string;
}
