import FiltersStorage from '../../../../calls/filtering/CallFiltersStorage';

export default class SaveFilterStrings {
    public static readonly confirm = 'CONFIRM';
    public static readonly cancel = 'CANCEL';

    public static readonly save_filter_title = 'SAVE FILTER';
    public static readonly save_confirm = 'SAVE';

    public static readonly delete_filter_title = 'DELETE FILTER';
    public static readonly delete_filter_subtitle1 = 'Are you sure you want to delete the custom filter \'';
    public static readonly delete_filter_subtitle2 = '\'?';

    public static readonly save_filter_generic_error = 'Filter not saved. Try Again Later.';
    public static readonly save_filter_duplicated_name = "You can't save two filters with the same name.";
    public static readonly invalid_name = 'Invalid name, please try again.';
    public static readonly too_long = `Filter name should not be more than ${FiltersStorage.MAXIMUM_LENGTH_FILTER_NAME} characters.`;

    public static readonly delete_filter_generic_error = 'Unable to delete filter. Try Again Later.';

    public static readonly rename_filter_title = 'RENAME FILTER';
    public static readonly rename_confirm = 'RENAME';
    public static readonly rename_filter_subtitle1 = "Enter the new name for the filter '";
    public static readonly rename_filter_subtitle2 = "'";
    public static readonly rename_filter_generic_error = 'Unable to rename filter. Try Again Later.';

    public static readonly save_as_filter_title = 'SAVE FILTER AS';
    public static readonly save_as_filter_subtitle = 'Enter the name of the new filter ';
    public static readonly save_as_filter_generic_error = 'Unable to save the new filter. Try Again Later.';
}
