/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import FiltersStorage from '../../calls/filtering/CallFiltersStorage';
import CustomFilterByAgencies from '../custom-filters/CustomFilterByAgencies';

export default class CallCustomFilterByAgencies extends CustomFilterByAgencies {
    public static $inject = ['callFiltersStorage'];

    constructor( filtersStorage: FiltersStorage) {
        super(filtersStorage);
    }
}
