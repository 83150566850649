/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import LocationAdapter from '../../shared/mapping/LocationAdapter';
import GeneralIncident from '../../schema/interfaces/GeneralIncident';
import Location from '../../shared/mapping/Location';

/**
 * A LocationAdapter that obtains the location from an Incident.
 */
export class IncidentLocationAdapter implements LocationAdapter<GeneralIncident<any>> {

    // @Override
    public getLocation(incident: GeneralIncident<any>): Location {
        if (!incident) {
            throw Error('The incident cannot be null or undefined.');
        }

        let location: Location = {
            streetAddress: incident.address,
            zip: incident.zip
        };

        // City
        let city = incident.city;
        if (city) {
            location.city = (typeof city === 'string') ? city : city.value;
        }

        // State
        let state = incident.state;
        if (state) {
            location.state = (typeof state === 'string') ? state : state.value;
        }

        // Latitude and longitude
        let geoAddress = incident.geoaddr;
        if (geoAddress) {
            location.geoid = (typeof geoAddress === 'string') ? geoAddress : geoAddress.value;
        }
        if (geoAddress && typeof geoAddress !== 'string' && geoAddress.fields) {
            let latitude = geoAddress.fields.latitude;
            if (angular.isNumber(latitude)) {
                location.latitude = latitude;
            }

            let longitude = geoAddress.fields.longitude;
            if (angular.isNumber(longitude)) {
                location.longitude = longitude;
            }
        }

        return location;
    }
}

export default IncidentLocationAdapter;
