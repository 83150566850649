/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import MyCallsItem from '../../../../app/cad/calls/components/filter-components/my-calls/MyCallsItem';
import CallFilterByUnit from '../../../../app/cad/calls/filtering/CallFilterByUnit';
import CADModel from '../../../../app/schema/interfaces/CADModel';
import CustomDataCadFilter from '../baseClasses/CustomDataCadFilter';
import AggregatorCadCall from '../../../../app/schema/AggregatorCadCall';
import FiltersStorage from '../storage/FiltersStorage';

export default class CustomFilterOnlyMyCalls extends CustomDataCadFilter<CADModel> {
    public BUCKET: string;

    public static STORAGE_KEY = 'ONLY_MY_CALLS';
    public static $inject = ['callFilterByUnit', 'callFiltersStorage'];

    public STORAGE_KEY = CustomFilterOnlyMyCalls.STORAGE_KEY;

    private callFilterByUnit: CallFilterByUnit;
    private isOnlyMyCalls: MyCallsItem;

    constructor(
        callFilterByUnit: CallFilterByUnit,
        filtersStorage: FiltersStorage
    ) {
        super(filtersStorage);
        this.isOnlyMyCalls = new MyCallsItem(false, false);
        this.callFilterByUnit = callFilterByUnit;
        this.isFilterApplied = false;
    }

    public include(model: AggregatorCadCall): boolean {
        if (this.getSelectedValues().applied) {
            return this.callFilterByUnit.include(model);
        }
        return false;
    }

    public applyFilter() {
        this.isFilterApplied = false;
        this.isOnlyMyCalls = new MyCallsItem(this.isOnlyMyCalls.checked, this.isOnlyMyCalls.checked);
        this.isFilterApplied = this.isOnlyMyCalls.applied;
    }

    public getFilterLabel(): string {
        return this.isOnlyMyCalls.applied ? 'Only My Calls' : undefined;
    }

    public setValues(isOnlyMyCalls: MyCallsItem) {
        this.isOnlyMyCalls = isOnlyMyCalls;
    }

    public getSelectedValues(): MyCallsItem {
        return this.isOnlyMyCalls;
    }

    protected getModelValue(model: CADModel): string {
        return model.unit;
    }
}
