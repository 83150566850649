/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { UrlFactory } from '../../authentication';
import { VersionParser } from './version-parser.service';
import { ResponseParser } from '../../api/response-parser.service';
import { Credentials } from '../../login/Credentials';
import { SemVer } from 'semver';

/**
 * The service that retrieves and stores the server version.
 */
@Injectable()
export class VersionService {

    /**
     * The private backing property for the `serverVersion` getter.
     */
    private _serverVersion: SemVer;

    /**
     * Constructs a new instance of the VersionService class.
     *
     * @param http The Angular service that makes http requests.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param responseParser The object that knows how to parse a response from Spillman's API.
     * @param versionParser The service that parses a string in order to create a Version object.
     */
    constructor(
        private http: Http,
        private urlFactory: UrlFactory,
        private responseParser: ResponseParser,
        private versionParser: VersionParser
    ) {
    }

    /**
     * Gets the server version.
     *
     * @returns The server version.
     */
    public get serverVersion(): SemVer {
        if (!this.isInitialized) {
            throw new Error('The VersionService has not been initialized.');
        }
        return this._serverVersion;
    }

    /**
     * Returns whether this service has been initialized.
     *
     * @returns True if this service was initialized, false otherwise.
     */
    public get isInitialized(): boolean {
        return !!this._serverVersion;
    }

    /**
     * Initializes this service by obtaining the server version.
     *
     * @param credentials The credentials that contain the server and port to use when calling the server.
     * @returns A promise that, when resolved, indicates that the `serverVersion` property
     *          has been initialized. It also returns the server version for convenience.
     */
    public initialize({ server, port, secureConnection }: Credentials): Promise<SemVer> {
        const url = this.urlFactory.create({
            server,
            port,
            secureConnection,
            path: '/../version'
        });

        return this.http.get(url)
            .timeout(20 * 1000)
            .map<Response, { version: string }>(this.responseParser.parse)
            .map(data => this._serverVersion = this.versionParser.parse(data.version))
            .do({ error: () => {
                this._serverVersion = undefined;
            } })
            .toPromise();
    }
}
