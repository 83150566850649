/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IRootScopeService = angular.IRootScopeService;
import { ThemeChanger, THEME } from './ThemeChanger';
import Theme from './Theme';
import { LOGGED_IN_EVENT } from '../login/authentication.service';

export const themeModule = angular.module('theme', [])
    .service('themeChanger', ThemeChanger)

    .run(['$rootScope', '$injector', function ($rootScope: IRootScopeService, $injector: angular.auto.IInjectorService): void {
        // Before the user has logged in, the theme will always be day.
        $rootScope[THEME] = Theme[Theme.day];

        // Once the user has logged in, then we can set the theme based on their particular setting.
        $rootScope.$on(LOGGED_IN_EVENT, () => {

            // NOTE: Because the `themeChanger` depends on an Angular 2 service,
            // we have to manually inject it sometime after the application has started running.
            // This is to work around the following bug with the UpgradeAdapter:
            // https://github.com/angular/angular/issues/10992
            const themeChanger = $injector.get<ThemeChanger>('themeChanger');
            themeChanger.setTheme();
        });
    }]);
