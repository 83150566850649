/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import VehicleListController from './list/VehicleListController';
import PermissionName from '../../permissions/enums/PermissionName';
import { upgradeAdapter } from '../../upgrade-adapter';
import { VehicleDetailComponent } from './detail/vehicle-detail.component';
import { createDetailState } from '../state-definition-helpers';
import { vehicleSearchComponent } from './search/vehicle-search.component';

export const vehicleModule = angular.module('vehicle', [])
    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.search.vehiclesList', {
                url: '/results/vehicles?filter&color',
                template: require('./list/list.html'),
                controller: 'vehicleListController as vm',
                data: {
                    permissions: [PermissionName.vehicle]
                }
            })
            .state('app.search.vehiclesDetails', createDetailState('vehicle', 'vhmain', { permission: PermissionName.vehicle, customUrl: '/results/vehicles/:id' }));
    }])

    // Components
    .component('sdsVehicleSearch', vehicleSearchComponent)

    // Controllers
    .controller('vehicleListController', VehicleListController)

    // Directives
    .directive('sdsVehicleDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(VehicleDetailComponent));
