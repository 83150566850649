/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * The names of the properties that are displayed on the call list screen.
 * This list is shared by both the CallListController and MyCallListController.
 */
const callListDisplayProperties = Object.freeze([
    'nature',
    'location',
    'fullCity.name',
    'fullCity.state',
    'callNumberAndType',
    'unit',
    'activeUnits.length',
    'status'
]);

export default callListDisplayProperties;
