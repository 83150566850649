/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * An enumeration of the keys for all settings. The key uniquely identifies the setting.
 *
 * As new settings are added, their keys must be added to this enum in order
 * for the app to know about them.
 *
 * IMPORTANT: new settings must also be added to the SettingModule.
 */
enum SettingKey {
    callFilterSetting,
    loggingLevelSetting,
    myIncidentsDurationSetting,
    themeSetting,
    unitFilterSetting,
    fontSizeSetting,
    commentRowsCountSetting
}

export default SettingKey;
