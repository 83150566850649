/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * A string-based enum of protocols.
 *
 * Note: This should not include the secure version of the protocol (e.g. 'https' or 'wss').
 * That is handled by the `secureConnection` flag on the UrlConfig.
 */
export type Protocol = 'http' | 'ws';

/**
 * The configuration for the UrlFactory.
 */
export abstract class UrlConfig {
    /**
     * The protocol to use.
     *
     * The default is 'http'.
     */
    public abstract protocol?: Protocol;

    /**
     * Whether the server supports a secure connection (SSL).
     *
     * By default the value stored in the Session will be used.
     * An error will be raised if not specified and no session exists.
     */
    public abstract secureConnection?: boolean;

    /**
     * The name of the server.
     *
     * By default the server stored in the Session will be used.
     * An error will be raised if not specified and no session exists.
     */
    public abstract server?: string;

    /**
     * The port to use.
     *
     * By default the port stored in the Session will be used.
     * An error will be raised if not specified and no session exists.
     */
    public abstract port?: string | number;

    /**
     * The base url to access the desired api.
     *
     * The default is 'SpillmanApi/api'.
     */
    public abstract baseApiUrl?: string;

    /**
     * The path to append to the URL. If the app's web service is used, then the path comes after both
     * the web service name and the api path. Otherwise, the path will come immediately after the port.
     */
    public abstract path: string;
}
