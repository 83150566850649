/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';

/**
 * A component which links to a traffic stop.
 */
@Component({
    selector: 'sds-traffic-stop-link',
    template: `
        <sds-permissions-link state="app.search.trafficStopDetails" [id]="trafficStop.id" *ngIf="trafficStop && trafficStop.id">
            <span>{{trafficStop.plate || trafficStop.id}}</span>
        </sds-permissions-link>
        <span *ngIf="trafficStop && !trafficStop.id">{{trafficStop}}</span>
        <span *ngIf="!trafficStop">&nbsp;</span>
    `,
    styles: [
        require('./link.component.scss')
    ]
})
export class TrafficStopLinkComponent {

    /**
     * The incident to display.
     */
    @Input() public trafficStop: string;
}
