/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import BaseLocalStorage from './base-local-storage';

/**
 * A higher-level wrapper around the native localStorage API. The primary advantage is that
 * this class allows you to store and retrieve an object as opposed to strings only.
 */
export default class LocalStorage extends BaseLocalStorage<string> {

    /**
     * Constructs a new instance of the LocalStorage class.
     */
    constructor() {
        super();
    }

    /**
     * @inheritdoc
     */
    protected createFinalKey(originalKey: string): string {
        return originalKey;
    }
}
