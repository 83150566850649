/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TreeNode } from './tree-node';
import { GeneralTreeNodeVisitor } from './tree-node-visitor';

/**
 * Performs a depth first traversal on a tree (represented by its root node).
 * This traversal supports both pre-order and post-order traversal.
 * See https://en.wikipedia.org/wiki/Tree_traversal#Depth-first_search
 *
 * Note that in-order traversal cannot be supported because this algorithm applies
 * to all trees not just search trees.
 */
@Injectable()
export class DepthFirstTraversal {

    /**
     * Performs a depth-first traversal on the tree, which is represented by its root node.
     *
     * @param root The tree's root node.
     * @param data The data that is passed from node to node during the traversal.
     * @param visitor The object that performs a "visit" on each node during the traversal.
     */
    public traverse<TData>(root: TreeNode, data: TData, visitor: GeneralTreeNodeVisitor<TData>): void {
        visitor.preOrderVisit(root, data);

        for (let child of root.children) {
            this.traverse(child, data, visitor);
        }

        visitor.postOrderVisit(root, data);
    }
}
