/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';

/**
 * A helper that knows how to work with the database models returned by the Tables API.
 */
@Injectable()
export class TablesApiHelper {

    /**
     * Gets the actual value from the given object taking into account
     * whether the value is an expanded object or not.
     *
     * For example: if the value has been expanded, then it would look something like this:
     * {
     *   "value": "FOO",
     *   "table": "nmwhatever",
     *   "fields": {
     *     "abbr": "FOO",
     *     "desc": "some made up value for demo purposes"
     *   }
     * }
     *
     * On the other hand, if the value has not been expanded, then it would look like this:
     * "FOO"
     *
     * This method would return "FOO" regardless of whether the value has been expanded or not.
     *
     * @param The value that may or may not be an expanded object.
     * @returns The actual value.
     */
    public getValue(value: any): any {
        return (value && typeof value === 'object') ? value.value : value;
    }

    /**
     * Walks the dot-separated path of expands in order to retrieve the database object
     * referenced by that path.
     *
     * For example: 'nmmain.nametyp' would result in an 'nmtbntyp' object being returned
     * because the 'nametyp' property references the 'nmtbntyp' table.
     *
     * @param databaseObject The current database object that is to be expanded.
     * @param dotSeparatedColumns The dot-separated path of objects to expand.
     * @returns The new object referenced by that dot-separated path.
     */
    public getExpandedObject(databaseObject: any, dotSeparatedColumns: string): any {
        const columns = dotSeparatedColumns.split('.').filter(c => !!c);
        let currentObject = databaseObject;

        for (let column of columns) {
            if (!currentObject) {
                break;
            }
            currentObject = this.getChildObject(currentObject, column);
        }
        return currentObject;
    }

    /**
     * Gets the array of joins from the database object that belong to the given table.
     *
     * @param databaseObject The database object as returned by the Tables API.
     * @param tableName The name of the joined database table.
     * @returns An array of joins that all belong to the given table.
     *          The array is guaranteed to neither be null nor undefined.
     */
    public getJoinsByTableName(databaseObject: any, tableName: string): any[] {
        const unfilteredJoins: any[] = databaseObject ? databaseObject.joins : undefined;
        return unfilteredJoins ? unfilteredJoins.filter((join: any) => join.table === tableName) : [];
    }

    /**
     * Gets the child object that is referenced by the given field of the parent object
     * taking into account the fact that the Tables API wraps child objects in a `fields` object.
     *
     * @param parentObject The parent object from which to retrieve a child object.
     * @param field The name of the field that references the child object.
     * @returns The child object referenced by the given field of the parent object.
     */
    private getChildObject(parentObject: any, field: string): any {
        const childObject = parentObject[field];
        return (childObject && childObject.fields) ? childObject.fields : undefined;
    }
}
