/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import angular from 'angular';
import { textWithPhoneLinkController } from './text-with-phone-link-controller';

/**
 * A component to display the text with hyperlink for the phones that are present in that text
 * @param text the specific text
 */
export const textWithPhoneLinkComponent: angular.IComponentOptions = {
    template: require('./text-with-phone-link.component.html'),
    bindings: {
        text: '@',
        class: '<'
    },
    controller: textWithPhoneLinkController
};

