/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IHttpInterceptor = angular.IHttpInterceptor;
import IInjectorService = angular.auto.IInjectorService;
import IHttpResponseTransformer = angular.IHttpResponseTransformer;
import { ReAuthenticationService, Credentials, authorizationHeaderName } from '../authentication';
import * as jwt_decode from 'jwt-decode';

ReAuthenticationInterceptor.$inject = ['$q', '$injector'];

let requests: any[] = [];
let deferredRequests: any[] = [];
let reauthAttempts = 0;

/**
 * A factory function that creates an http interceptor which will retry the request should it fail with an error 401.
 *
 * @param $q The angular service that handles creating and working with promises.
 * @param $injector The Angular injector service.
 */
export function ReAuthenticationInterceptor($q: IQService, $injector: IInjectorService): IHttpInterceptor {
    return {
        request: request => {
            const original: IHttpResponseTransformer | IHttpResponseTransformer[] = request.transformResponse;
            const reAuthenticationService = $injector.get<ReAuthenticationService<Credentials>>('reAuthenticationService');
            const currentToken = request.headers[authorizationHeaderName];
            const decoded: { exp: number; iat: number } = jwt_decode(currentToken) as { exp: number; iat: number };
            const currentDate = Date.now();
            const isTokenExpired = decoded.exp < currentDate / 1000;
            const $http = $injector.get('$http');
            /**
             * Wrap the transform in another which will process the server response and avoid doing all the transforms
             * if the server returns an error.
             */
            const transformResponse = (data: any, headersGetter: any, status: number) => {
                if (status === 200) {

                    // If the original is an array of transforms execute each request otherwise return the original.
                    if (Array.isArray(original)) {
                        return original.reduce((response, responseTransform) => responseTransform(response, headersGetter, status), data);
                    } else {
                        return original(data, headersGetter, status);
                    }
                } else {
                    return undefined;
                }
            };

            /**
             * Update auth header of request
             */
            const setRequestAuthHeader = (_request: any) => {
                _request.headers[authorizationHeaderName] = $http.defaults.headers.common[authorizationHeaderName];
            };

            // If token is expired send request by new token and after response
            // update auth header of deferred requests, send requests
            if (isTokenExpired) {
                deferredRequests[reauthAttempts]  = $q.defer();
                if (!reauthAttempts) {
                    reAuthenticationService.reAuthenticate()
                        .toPromise()
                        .then(() => {
                            request.transformResponse = transformResponse;
                            setRequestAuthHeader(request);

                            requests.forEach((item: any, index: number) => {
                                item.transformResponse = transformResponse;
                                setRequestAuthHeader(item);
                                deferredRequests[index + 1].resolve(item);
                            });
                            deferredRequests[0].resolve(request);
                            deferredRequests = [];
                            reauthAttempts = 0;
                            requests = [];
                        },
                        (e: any) => deferredRequests[reauthAttempts - 1].reject(e));
                } else {
                    requests.push(request);
                }
                reauthAttempts++;
                return deferredRequests[reauthAttempts - 1].promise;
            } else {
                request.transformResponse = transformResponse;
                return request;
            }
        },
        responseError: responseError => {
            return $q.reject(responseError);
        }
    };
}
