/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import BaseLocalStorage from './base-local-storage';
import Session from '../../login/Session';
import KeyMetadata from './key-metadata';

/**
 * A specialized wrapper around local storage that prepends a Spillman-specific prefix to every key
 * before the key/value pair is added to local storage. This makes it so that Spillman's data will
 * not collide with third-party libraries that also store data in local storage.
 *
 * This class also provides the ability to add the username of the logged-on user as a sub-prefix
 * so that distinct users can each have their own data stored in local storage.
 */
@Injectable()
export class SpillmanLocalStorage extends BaseLocalStorage<KeyMetadata> {

    /**
     * The prefix to apply to every key before storing the data in local storage.
     */
    public static SPILLMAN_PREFIX = 'spillman';

    /**
     * Constructs a new instance of the SpillmanLocalStorage class.
     *
     * @param $window The Angular wrapper around the window object.
     * @param session The object that stores information about the current user's session.
     */
    constructor( @Inject('session') private session: Session) {
        super();
    }

    /**
     * @inheritdoc
     */
    protected createFinalKey(originalKey: KeyMetadata): string {
        let prefix = SpillmanLocalStorage.SPILLMAN_PREFIX;

        // Add the username as a sub-prefix if the `userSpecific` flag is set to true.
        if (originalKey.userSpecific) {
            if (!this.session.exists()) {
                throw new Error('You cannot create a user-specific entry in local storage when the user is not logged in.');
            }
            prefix += '.' + this.session.data.loginName;
        }
        return `${prefix}.${originalKey.key}`;
    }
}
