/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Credentials } from './credentials';
import { AuthenticationService } from './authentication.service';
import { ApiAuthenticationTokenService } from './api-authentication-token.service';
import { SetAuthenticationHeadersService } from './set-authentication-headers.service';

/**
 * An authentication service that authenticates by calling the Spillman API.
 */
@Injectable()
export class ApiAuthenticationService extends AuthenticationService<Credentials> {

    /**
     * Constructs a new instance of the SpillmanApiAuthenticationService.
     *
     * @param tokenService
     * @param requestOptions
     */
    constructor(
        tokenService: ApiAuthenticationTokenService,
        setAuthenticationHeadersService: SetAuthenticationHeadersService
    ) {
        super(tokenService, setAuthenticationHeadersService);
    }
}
