/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import FiltersStorage from '../storage/FiltersStorage';
import TypeItem from '../components/baseClasses/type/TypeItem';
import CADModel from '../../../../app/schema/interfaces/CADModel';
import CustomDataCadFilter from '../baseClasses/CustomDataCadFilter';

export default abstract class CustomFilterByTypes extends CustomDataCadFilter<CADModel> {
    public static STORAGE_KEY = 'TYPES';
    public static $inject = ['callFiltersStorage'];

    public STORAGE_KEY = CustomFilterByTypes.STORAGE_KEY;

    private selectedValues: TypeItem[];

    constructor(
        filtersStorage: FiltersStorage
    ) {
        super(filtersStorage);

        this.selectedValues = [];
        this.isFilterApplied = false;
    }

    public include(model: CADModel): boolean {
        const selectedValues = this.getSelectedValues().filter(value => value.applied === true);

        if (selectedValues === undefined || selectedValues.length === 0) {
            return false;
        }

        const result = selectedValues.find((data: FilterModel) => data.id === this.getModelValue(model));

        return result !== undefined;
    }

    public getFilterLabel(): string {
        return this.isFilterApplied ? `Types (${this.selectedValues.filter(value => value.applied === true).length})` : undefined;
    }

    public applyFilter() {
        this.isFilterApplied = false;
        this.selectedValues = this.selectedValues.map(item => {
            item.applied = item.checked;
            return item;
        });

        this.isFilterApplied = this.selectedValues.some(item => item.checked === true);
    }
    public setValues(selectedValues: TypeItem[]) {
        this.selectedValues = selectedValues;
    }

    public getSelectedValues(): TypeItem[] {
        return this.selectedValues.filter(value => value.applied === true);
    }

    protected getModelValue(model: CADModel): string {
        return model.type;
    }
}
