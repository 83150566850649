/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';

import { MyUnitProvider } from '../my-unit-provider.service';
import { StatusHistoryStorage } from './status-history-storage.service';
import { StatusService } from './status.service';

/**
 * The error messages that may be shown for the StatusControlComponent.
 */
export const errorMessages = {
    statusUpdateFailed: 'An error occurred while attempting to update your status.'
};

/**
 * A component that allows the user to easily change their unit's status.
 * The user can either choose to hit the button to quickly change their status to the
 * next defined status or choose from a dropdown list of all potential statuses.
 */
@Component({
    selector: 'sds-status-control',
    template: require('./status-control.component.html'),
    styles: [
        require('./status-control.component.scss'),
        require('./status-control.component.day.scss'),
        require('./status-control.component.night.scss')
    ]
})
export class StatusControlComponent implements OnInit {

    /**
     * The comment to be submitted with a radio log.
     */
    @Input() public comment: string;

    /**
     * A notify event indicating the comment should be cleared.
     */
    @Output() public notifyClearComment: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * A flag that indicates whether the dropdown is visible.
     */
    public isDropdownVisible = false;

    /**
     * The text entered by the user that is used to filter the dropdown list.
     */
    public filterText: string;

    /**
     * The list of statuses to display.
     */
    public statuses: string[];

    /**
     * Constructs a new instance of the StatusControlComponent class.
     *
     * @param elementRef - A reference to this component's HTML element.
     * @param myUnitProvider - Provides the current user's unit.
     * @param $ionicPopup - The service that displays a native-looking dialog.
     * @param statusHistoryStorage - The service that saves the history of status changes.
     * @param statusService - The service that provides status and allows updates to an officer's status.
     */
    constructor(
        private elementRef: ElementRef,
        private myUnitProvider: MyUnitProvider,
        @Inject('$ionicPopup') private $ionicPopup: ionic.popup.IonicPopupService,
        private statusHistoryStorage: StatusHistoryStorage,
        private statusService: StatusService
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit(): void {
        this.statuses = this.statusService.statuses;
    }

    /**
     * Handles any click that happens on the document.
     * If a click happens outside of the StatusControlComponent,
     * then the dropdown will be closed.
     *
     * @param event - The event object.
     */
    @HostListener('document:click', ['$event'])
    public handleDocumentClick(event: MouseEvent): void {
        const element = this.elementRef.nativeElement as Element;

        if (element && !element.contains(event.target as Node)) {
            this.closeDropdown();
        }
    }

    /**
     * @returns The next defined status for the user's unit.
     */
    public get nextStatus(): string {
        const unit = this.myUnitProvider.unit;
        return unit ? unit.nextStatus : undefined;
    }

    /**
     * Toggles the visibility of the dropdown.
     * When the dropdown is not visible, then the "next status" button will be visible instead.
     */
    public toggleDropdown(): void {
        if (this.isDropdownVisible) {
            this.closeDropdown();
        } else {
            this.isDropdownVisible = true;
        }
    }

    /**
     * Updates the status using the status that was selected from the dropdown.
     * This method will also save the status in the history whereas the updateStatus method will not.
     *
     * @param status - The status that was selected from the dropdown.
     */
    public selectStatus(status: string): void {
        this.updateStatus(status);
        this.statusHistoryStorage.append(status);
        this.closeDropdown();
    }

    /**
     * Updates the current user's unit's status.
     *
     * @param newStatus - The new status for the user's unit.
     */
    public updateStatus(newStatus: string): void {
        this.statusService.updateCurrentUserStatus(newStatus, this.comment).subscribe(
            () => this.notifyClearComment.emit(true),
            this.displayError
        );
    }

    /**
     * Displays an error to the user if the status could not be updated.
     */
    private displayError = () => {
        this.$ionicPopup.alert({ template: errorMessages.statusUpdateFailed });
    };

    /**
     * Closes the dropdown and clears out the filter text.
     */
    private closeDropdown(): void {
        this.isDropdownVisible = false;
        this.filterText = undefined;
    }
}
