/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IInjectorService = angular.auto.IInjectorService;
import ObjectEnumerator from '../../../../shared/interfaces/ObjectEnumerator';
import AggregatorCadCall from '../../../../schema/AggregatorCadCall';
import DecoratorTransform from '../../../../shared/transforms/DecoratorTransform';

/**
 * An enum that specifies the set of transforms that need to be performed when either
 * a new Call is added to the CallRepository or an existing Call is modified.
 */
enum CallTransforms {
    callDateTransform,
    callFullCityTransform,
    callUnitPropertiesTransform,
    callUnitMethodsTransform
}

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
AllCallTransforms.$inject = ['$injector', 'enumEnumerator'];

/**
 * A factory function that creates an array of DecoratorTransforms that need to be performed for CAD calls
 * contained within the CallRepository.
 *
 * @param $injector The Angular injector service.
 * @param enumEnumerator The function that enumerates the values of an enum.
 * @returns {DecoratorTransform<AggregatorCadCall>[]} The array of transforms to apply to CAD calls within the CallRepository.
 */
function AllCallTransforms($injector: IInjectorService, enumEnumerator: ObjectEnumerator): DecoratorTransform<AggregatorCadCall>[] {
    return enumEnumerator(CallTransforms).map(val => $injector.get<DecoratorTransform<AggregatorCadCall>>(val));
}

export default AllCallTransforms;
