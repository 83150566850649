/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;

/**
 * A directive that simply displays a Radio Log entry.
 */
export default function RadioLogDirective(): IDirective {
    return {
        restrict: 'E',
        scope: {
            radioLog: '=model'
        },
        template: require('./radioLog.html')
    };
}
