/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { MemoizedTreeNode } from '../../../../data-structures/tree-node';
import { Constructor } from '../../../../interfaces/constructor';

/**
 * A node that contains a reference to a particular class -- not an instance
 * of the class, but the actual class itself.
 */
export abstract class ClassNode extends MemoizedTreeNode {

    /**
     * Constructs a new instance of the ClassNode class.
     *
     * @param classConstructor The constructor of the class that this node represents.
     */
    constructor(public classConstructor: Constructor<any>) {
        super();
    }
}
