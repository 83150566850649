/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IStateService = angular.ui.IStateService;
import { BaseSearchController } from '../../../searching/BaseSearchController';
import { PhoneBuilder } from './phone-builder.service';
import remove = require('lodash/remove');
import NameSearchScope from './NameSearchScope';
import IResourceClass = angular.resource.IResourceClass;
import Sex from '../../../schema/Sex';
import { VersionService } from '../../../conditional-injection';

const PHONE_AREA_CODE = 'phone_areaCode';
const PHONE_3DIGITS = 'phone_3Digits';
const PHONE_4DIGITS = 'phone_4Digits';
const PHONE_EXTENSION = 'phone_extension';

/**
 * The component that allows the user to search for names in the database.
 */
export const nameSearchComponent: angular.IComponentOptions = {
    template: require('./name-search.component.html'),
    controller: class NameSearchController extends BaseSearchController {

        public static $inject = [
            '$state',
            'serverDateTimeFormat',
            'phoneBuilder',
            '$scope',
            'sexCodeAPI',
            'versionService'
        ];

        public $onInit = () => {};

        /**
         * The string that that represent current Flex-server version.
         */
        private flexVersion: string;

        /**
         * The string that that represent the Sex Code fix Flex-server version.
         */
        private sexCodeFixVersion = '2022.4';
        /**
         * Constructs a new instance of the NameSearchController class.
         *
         * @param $state The service that transitions between states.
         * @param serverDateTimeFormat The constant that defines the format in which both a date and time are sent by the server.
         * @param phoneBuilder Builds the complete phone number from the individual components.
         * @param $scope The Angular scope object that provides data to the view.
         * @param sexCodeAPI The service that get Sex Code Data from the server.
         * @param versionService The service that retrieves and stores the server version.
         */
        constructor(
            $state: IStateService,
            serverDateTimeFormat: string,
            private phoneBuilder: PhoneBuilder,
            $scope: NameSearchScope,
            private avlDeviceAPI: IResourceClass<Sex>,
            private versionService: VersionService

        ) {
            super($state, serverDateTimeFormat, { phone: true });

            this.avlDeviceAPI.get({
            }, {}).$promise
                .then((data) => {
                    $scope.sexCodes = data.codedValues;
                });

            // Set current server version.
            this.flexVersion = this.versionService.serverVersion.raw;

            const isSexCode = this.checkServerVersion(this.sexCodeFixVersion, this.flexVersion);
            $scope.isSexCode = isSexCode;
        }

        protected checkServerVersion(fixVer: string, currentVer: string) {
            return (currentVer >= fixVer);
        }
        /**
         * @inheritdoc
         */
        protected get listStateName(): string {
            return 'app.search.namesList';
        }

        /**
         * @inheritdoc
         */
        protected getKeyValuePairs(): [string, any][] {
            let pairs = super.getKeyValuePairs();

            // Remove the phone components from the pairs.
            remove(pairs, ([key]: [string, any]) => key.indexOf('phone_') === 0);

            // Build the phone number.
            let phone = this.phoneBuilder.build(
                this.searchParams[PHONE_AREA_CODE],
                this.searchParams[PHONE_3DIGITS],
                this.searchParams[PHONE_4DIGITS],
                this.searchParams[PHONE_EXTENSION]
            );

            // If there is a phone number, add it to the list of pairs.
            if (phone) {
                pairs.push(['phone', phone]);
            }
            return pairs;
        }
    }
};
