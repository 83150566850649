/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { DatabaseService } from '../../../shared/database/database.service';
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import { AccidentName } from './model/accident-name';
import { SlideSourceInfo } from '../../../schema/SlideSourceInfo';
import { VehicleReference } from '../../shared/model/vehicle-reference';

/**
 * A component that displays the details of a accident name.
 */
@Component({
    selector: 'sds-accident-name-detail',
    template: require('./accident-name-detail.component.html')
})
export class AccidentNameDetailComponent implements OnInit {

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([this.commentsSlideSourceInfo]);

    /**
     * The accident name model to display.
     */
    public accidentName: AccidentName;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the AccidentNameDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param commentsSlideSourceInfo inject the comments slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: AccidentNameStateParams,
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(AccidentName, {
            number: this.$stateParams.id,
            name: this.$stateParams.nameId
        })
            .map(this.populateArrays)
            .map(this.populateVehicle)
            .switch()
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.accidentName = detailModel);
    }

    /**
     * Populates several arrays where each one is composed of two or more
     * other properties on the accident name.
     *
     * @param accidentName The accident name model whose array properties will be populated.
     * @returns The original accident name that has been modified.
     */
    private populateArrays = (accidentName: AccidentName): AccidentName => {
        if (accidentName) {
            accidentName.dlRestrictions = [
                accidentName.dlRestriction1,
                accidentName.dlRestriction2,
                accidentName.dlRestriction3
            ].filter(i => !!i);

            accidentName.driverActions = [
                accidentName.driverAction1,
                accidentName.driverAction2,
                accidentName.driverAction3,
                accidentName.driverAction4
            ].filter(i => !!i);

            accidentName.drugTestResults = [
                accidentName.drugTestResult1,
                accidentName.drugTestResult2,
                accidentName.drugTestResult3,
                accidentName.drugTestResult4
            ].filter(i => !!i);

            accidentName.nonMotoristActionsAtTimeOfCrash = [
                accidentName.nonMotoristActionAtTimeOfCrash1,
                accidentName.nonMotoristActionAtTimeOfCrash2
            ].filter(i => !!i);

            accidentName.nonMotoristSafetyEquipment = [
                accidentName.nonMotoristSafetyEquipment1,
                accidentName.nonMotoristSafetyEquipment2
            ].filter(i => !!i);

            accidentName.violationCodes = [
                accidentName.violationCode1,
                accidentName.violationCode2,
                accidentName.violationCode3,
                accidentName.violationCode4
            ].filter(i => !!i);
        }

        return accidentName;
    };

    /**
     * Populates the related vehicle on the accident name.
     *
     * NOTE: The reason why this method is needed is because there is no foreign key relationship
     * between acnmdtl and vhmain even though the acnmdtl table stores a vhmain primary key.
     * Because no foreign key relationship exists, we cannot use `expand` to get the vehicle,
     * but instead must perform a second query.
     *
     * @param accidentName The accident name model to which to add the related vehicle.
     * @returns An observable that returns the original accident name that has been modified
     *          to include the related vehicle.
     */
    private populateVehicle = (accidentName: AccidentName): Observable<AccidentName> => {
        if (accidentName && accidentName.relatedVehicleId) {
            return this.databaseService.get(VehicleReference, accidentName.relatedVehicleId)
                .map(vehicle => {
                    accidentName.relatedVehicle = vehicle;
                    return accidentName;
                })
                .catch(() => Observable.of(accidentName));
        }

        return Observable.of(accidentName);
    };
}

/**
 * Defines the properties that are available on the `$stateParams` service
 * when passed to the `AccidentNameDetailComponent`.
 */
interface AccidentNameStateParams extends DetailsStateParams {

    /**
     * The ID of the name that was involved in the accident.
     */
    nameId: string;
}
