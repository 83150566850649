/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ILogService = angular.ILogService;
import IQService = angular.IQService;
import IHttpInterceptor = angular.IHttpInterceptor;

/**
 * The maximum number of milliseconds to wait for an HTTP GET request to complete.
 * This applies to all GET requests throughout the entire app.
 */
export const MAX_GET_REQUEST_TIMEOUT = 20 * 1000;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
RequestTimeoutInterceptor.$inject = ['$q', '$log'];

/**
 * A factory function that creates an http interceptor that sets a timeout on GET requests
 * and logs any errors caused by that timeout.
 *
 * @param $q The angular service that handles creating and working with promises.
 * @param $log The Angular service that performs logging.
 * @returns {IHttpInterceptor} An http interceptor that sets a timeout on GET requests and logs
 *                             any errors caused by that timeout.
 */
export function RequestTimeoutInterceptor($q: IQService, $log: ILogService): IHttpInterceptor {
    return {
        request: config => {
            let method = config.method;

            // For now, we're only going to set a timeout on GET requests.
            if (typeof method === 'string' && method.toUpperCase() === 'GET') {
                config.timeout = MAX_GET_REQUEST_TIMEOUT;
            }
            return config;
        },
        responseError: error => {
            // If the request times out, then an error with a status of 0 will be thrown.
            // Angular automatically logs HTTP errors such as 404 or 500, but not timeout errors.
            // So we are going to log it ourselves.
            if (error.status === 0) {
                $log.error(error);
            }
            return $q.reject(error);
        }
    };
}
