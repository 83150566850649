/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IWindowService = angular.IWindowService;
import IPromise = angular.IPromise;

/**
 * A class that contains utilities for working with files.
 */
export default class FileUtilities {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$q', '$window'];

    /**
     * Constructs a new instance of the FileUtilities class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param $window The Angular wrapper around the window object.
     */
    constructor(private $q: IQService,
        private $window: IWindowService) {
    }

    /**
     * Finds the absolute path on the device to the directory specified by the given relative path.
     *
     * @param relativePath The relative path to the directory.
     * @param persistentStorage A flag that indicates if the directory is located in persistent or temporary storage.
     *                          true means to search in persistent storage, false means to search in temporary storage.
     * @returns {IPromise<string>} A promise that, when resolve, will return the absolute path to the directory on the device.
     */
    public findDirectory(relativePath: string, persistentStorage: boolean): IPromise<string> {
        let deferred = this.$q.defer<string>();
        let fileSystemType = persistentStorage ? this.$window.PERSISTENT : this.$window.TEMPORARY;

        // First, get access to the file system.
        this.$window.requestFileSystem(fileSystemType, 0, fileSystem => {

            // Next, find the documents directory.
            fileSystem.root.getDirectory(relativePath, {create: true}, directoryEntry => {

                // Finally, return the nativeURL, which a funky path that you wouldn't have been able to guess.
                deferred.resolve(directoryEntry.nativeURL);
            },
            () => deferred.reject(`Unable to access the ${relativePath} directory`));
        },
        () => deferred.reject('Unable to access the file system'));

        return deferred.promise;
    }

    /**
     * Gets the extension from the file name.
     *
     * @param fileName The file name.
     * @returns {string} The extension or an empty string if there is no extension.
     */
    public getExtension(fileName: string): string {
        let dotIndex = fileName ? fileName.lastIndexOf('.') : -1;
        return (dotIndex >= 0) ? fileName.substr(dotIndex) : '';
    }

    /**
     * Makes the file name download safe by removing whitespace and ensuring the extension is lowercase.
     *
     * @param fileName The file name to make download safe.
     * @returns {string} The file name where whitespace has been removed and the extension is lowercase.
     */
    public makeDownloadSafe(fileName: string): string {
        if (fileName) {
            // Remove all whitespace.
            fileName = fileName.replace(/\s+/g, '_');

            // Find where the extension starts.
            let dotIndex = fileName.lastIndexOf('.');
            if (dotIndex >= 0) {
                let name = fileName.substr(0, dotIndex);

                // Lowercase the extension.
                let extension = fileName.substr(dotIndex).toLowerCase();
                return name + extension;
            }
        }
        return fileName;
    }
}
