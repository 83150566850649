/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { ResponseContentType } from '@angular/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';

/**
 * A blob requester service for requesting a local URL to an image or file.
 */
@Injectable()
export class BlobRequester {

    /**
     * Constructs a new blob requester service.
     *
     * @param http The Angular service that makes http requests.
     * @param sanitizer The Angular service that sanitizes URLs.
     */
    constructor(
        private http: Http,
        private sanitizer: DomSanitizer) {
    }

    /**
     * Get the safe URL to the response blob.
     *
     * @param url The URL of the blob request.
     * @returns An observable containing a safe URL.
     */
    public getSafeUrl(url: string): Observable<SafeUrl> {
        if (url) {
            return this.http.get(url, { responseType: ResponseContentType.Blob })
                .map(response => response.blob())
                .map(blob => URL.createObjectURL(blob))
                .map(localUrl => this.sanitizer.bypassSecurityTrustUrl(localUrl));
        }

        return Observable.of(undefined);
    }
}
