/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import { NameReference } from '../../model/name-reference';

/**
 * A component which displays a field which links to a name.
 */
@Component({
    selector: 'sds-name-display',
    template: `
        <span *ngIf="name && name.id">{{[name.last, [name.first, name.middle] | sdsJoin:' '] | sdsJoin:', '}}</span>
        <span *ngIf="name && !name.id">{{name}}</span>
        <span *ngIf="!name">&nbsp;</span>
    `
})
export class NameDisplayComponent {

    /**
     * The name to display.
     */
    @Input() public name: NameReference;
}
