/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';

import { provideBasedOnCondition } from '../../conditional-injection/provide-based-on-condition';
import { SystemUse } from './system-use';
import { SystemUseService } from './system-use.service';
import { SystemUseDefaultService } from './system-use-default.service';
import { SystemUseNotification } from './system-use-notification.service';

@NgModule({
    providers: [
        SystemUseNotification,
        provideBasedOnCondition(
            SystemUse,
            SystemUseDefaultService,
            SystemUseService
        )
    ]
})
export class SystemUseModule { }
