/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import Incident from '../../../schema/Incident';
import InvolvementResourceClass from '../../involvement/InvolvementResourceClass';
import DecoratorTransform from '../../../shared/transforms/DecoratorTransform';

/**
 * A decorator transform that will add the involvements to the incident.
 */
export default class IncidentInvolvementsDecoratorTransform implements DecoratorTransform<Incident> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['involvementAPI'];

    /**
     * Constructs a new instance of the IncidentInvolvementsDecoratorTransform class.
     *
     * @param involvementAPI The API for retrieving involvements from the server.
     */
    constructor(private involvementAPI: InvolvementResourceClass) {
    }

    // @Override
    public invoke(incident: Incident): void {
        if (incident.number) {
            incident.involvements = this.involvementAPI.query({
                table: 'lwmain',
                id: incident.number
            });
        }
    }
}
