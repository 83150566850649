/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import ContainsSessionDataCadFilter from '../../filtering/baseClasses/ContainsSessionDataCadFilter';
import Session from '../../../login/Session';
import SessionData from '../../../login/SessionData';
import StandardEqualityComparer from '../../../shared/comparison/StandardEqualityComparer';
import CADModel from 'app/schema/interfaces/CADModel';

/**
 * Filters the calls so that those that are assigned to the current user will be shown.
 */
export default class MyCallsFilter extends ContainsSessionDataCadFilter<CADModel, string> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['session', 'standardEqualityComparer'];

    /**
     * Constructs a new instance of the MyCallsFilter class.
     *
     * @param session The object that stores information about the current user's session.
     * @param standardEqualityComparer The equality comparer that uses the standard triple equals.
     */
    constructor(session: Session, standardEqualityComparer: StandardEqualityComparer<string>) {
        super(session, standardEqualityComparer);
    }

    // @Override
    protected getModelValue(model: AggregatorCadCall): string[] {
        // Combine all unit numbers into a single array.
        // Start with the responsible unit and then add the other units.
        let units = [model.unit];
        if (model.otherUnits) {
            units = units.concat(model.otherUnits.split(',').map(u => u.trim()));
        }
        return units;
    }

    // @Override
    protected getSessionValue(sessionData: SessionData): string {
        return sessionData.unitNumber;
    }
}
