/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import IResourceArray = angular.resource.IResourceArray;
import IResource = angular.resource.IResource;
import ResourceUtils from '../ResourceUtils';
import MultiQueryResourceClass from './MultiQueryResourceClass';
import MultiQueryConfig from './MultiQueryConfig';
import MultiQueryResourceDecorator from './MultiQueryResourceDecorator';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
MultiQueryResourceDecoratorFactory.$inject = ['resourceUtils'];

/**
 * A factory function that creates the MultiQueryResourceDecorator.
 *
 * @param resourceUtils Provides utility methods for working with the Angular $resource service.
 * @returns {MultiQueryResourceDecorator} A function that converts a standard resource into a multi-query resource.
 */
function MultiQueryResourceDecoratorFactory(resourceUtils: ResourceUtils): MultiQueryResourceDecorator {

    // Return a function that satisfies the `MultiQueryResourceDecorator` interface.
    const multiQueryResourceResult = function <T extends IResource<any>>(resource: IResourceClass<T>, multiQueryConfig: MultiQueryConfig<T>): MultiQueryResourceClass<T> {
        // "Cast" the resource so that it's a MultiQuery resource.
        let multiQueryResource = <MultiQueryResourceClass<T>>resource;

        // Add the implementation of the `multiQuery` method.
        multiQueryResource.multiQuery = function (specialParams: any, params: any): IResourceArray<T> {
            if (specialParams) {
                let specialParamValue = specialParams[multiQueryConfig.specialParameterName];
                if (specialParamValue) {

                    // Execute each of the queries and store the return values in an array.
                    let resources = multiQueryConfig.queries.map(q => q(params, specialParamValue));

                    // Then combine all of the resources - passing in the transform function.
                    return resourceUtils.combineResourceArrays(resources, multiQueryConfig.transform, multiQueryConfig.pageSize).resourceArray;
                }
            }
            return multiQueryResource.query(params);
        };

        // Return the original resource that has been decorated with the `multiQuery` method.
        return multiQueryResource;
    };

    return <MultiQueryResourceDecorator>multiQueryResourceResult;
}

export default MultiQueryResourceDecoratorFactory;
