/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import { Response } from '@angular/http';
import { CacheService } from './cacheService';
import Session from '../../../app/login/Session';
import { getNumber } from '../../../app/api/analytics/firebase-remote-config-service';
import { PlatformDetector } from '../../../app/conditional-injection/platform-detection/platform-detector.service';

/**
 * Service that persistes data for an amount of time interval (expiration).
 */
@Injectable()
export class CacheExpireService {

    private MINUTE_IN_SECONDS = 60;
    private EXPIRATION_INTERVAL_SECONDS = 20 * this.MINUTE_IN_SECONDS;
    private ADVANCED_FILTER_CACHE_DATA_EXPIRE_SECONDS = 'advanced_filter_cache_data_expire_seconds';

    private resultByLoginName = {};

    constructor(
        private cacheService: CacheService,
        private platformDetector: PlatformDetector,
        @Inject('session') private session: Session
    ) {}

    /**
     * Gets the feature flag for time interval.
     *
     * @return result of time interval.
     */
    private getCacheExpireTimeSeconds = async (): Promise<number> => {
        if (this.platformDetector.getPlatform() === 'browser') {
            return Promise.resolve(this.EXPIRATION_INTERVAL_SECONDS);
        }

        return getNumber(this.ADVANCED_FILTER_CACHE_DATA_EXPIRE_SECONDS);
    };

    private hasExpired = (consultDate: Date, expireTime: number) => {
        const then = consultDate;
        const now = new Date();
        let interval = now.getTime() - then.getTime();
        return (interval / 1000) >= expireTime;
    };

    private hasDataForParams(tableName: string, text?: string, format?: string): boolean {
        const key = this.generateKeyForLocalCache(tableName);
        if (this.resultByLoginName[key]?.data?.values?.length > 0) {
            return this.resultByLoginName[key]?.text === text && this.resultByLoginName[key]?.format === format;
        }
        return false;
    }

    private getLocalCache(tableName: string): any {
        const key = this.generateKeyForLocalCache(tableName);
        const data = this.resultByLoginName[key]?.data;
        return data;
    }

    private addToLocalCache(data: any[], tableName: string, text?: string, format?: string){
        const date = new Date();
        this.resultByLoginName[this.generateKeyForLocalCache(tableName)] = {text: text, format: format, data: {values: data, date: date}};
    };

    private generateKeyForLocalCache = (tableName: string) => {
        return this.session.data.server + '-' + this.session.data.loginName + '-' + tableName;
    };

    private get = (table: string, text: string, format?: string): Promise<Response> => {
        const options = this.cacheService.createRequestOptions(text, format);
        return this.cacheService.get(table, options).toPromise();
    };

    /**
     * Gets data from CacheService, depending on a expiration (defined by Remote Config) time
     * it will decide if new data should be requested or if persisted data should be returned.
     *
     * @returns Promise with requested or persisted data.
     *
     * @param tableName Name of the table of persisted data. Should be unique for each context.
     * @param text The text to match (optional).
     * @param format A sprintf-style format string that determines how the code and description are combined (optional).
     */
    public getCachedData = async (tableName: string, text?: string, format?: string): Promise<any[]> => {
        const hasCachedData = this.hasDataForParams(tableName, text, format);

        if (hasCachedData) {
            const data = this.getLocalCache(tableName);
            const result = await this.getCacheExpireTimeSeconds().then((response: number) => {
                if (data && data?.values && !this.hasExpired(data?.date, response)) {
                    return data?.values;
                } else {
                    return undefined;
                }
            });

            if (result !== undefined) {
                return result;
            }
        }

        return this.get(tableName, text, format)
            .then(response => {
                const data = response.json();
                this.addToLocalCache(data, tableName, text, format);
                return data;
            });
    };
}
