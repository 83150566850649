/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceArray = angular.resource.IResourceArray;
import IResourceClass = angular.resource.IResourceClass;
import ResourceUtils from '../../../../resource/ResourceUtils';
import AddressAlert from '../../../../schema/AddressAlert';
import CallQuery from './CallQuery';

/**
 * Requests the Address Alerts associated with the call.
 */
export default class AddressAlertsCallQuery extends CallQuery<IResourceArray<AddressAlert>> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['addressAlertAPI', 'resourceUtils'];

    /**
     * Constructs a new instance of the AddressAlertsCallQuery class.
     *
     * @param addressAlertAPI The API for retrieving address alerts from the server.
     * @param resourceUtils Provides utility methods for working with the Angular $resource service.
     */
    constructor(private addressAlertAPI: IResourceClass<AddressAlert>, private resourceUtils: ResourceUtils) {
        super();
    }

    // @Override
    protected get watchPropertyNames(): string | string[] {
        return 'geoid';
    }

    // @Override
    protected get updatePropertyName(): string {
        return 'addressAlerts';
    }

    // @Override
    protected get defaultValue(): IResourceArray<AddressAlert> {
        return this.resourceUtils.createResourceArray<AddressAlert>();
    }

    // @Override
    protected performQuery(newValues: any[]): IResourceArray<AddressAlert> {
        return this.addressAlertAPI.query({ filter: `number=${newValues[0]}` });
    }
}
