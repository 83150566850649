/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * A want offense.
 */
@table('waoffs')
export class Offense {

    @field('offcode')
    public statute: string;

    @expand('offcode', 'desc')
    public description: string;

    @expand('odisp', 'desc')
    public disposition: string;
}
