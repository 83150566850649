/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { IRootScopeService, IScope } from 'angular';

import CustomFilterByZones from '../../../../../../app/cad/filtering/custom-filters/CustomFilterByZones';
import { log, logError } from '../../../../../../app/api/analytics/firebase-crashlytics-service';
import CustomFilterByAgencies from '../../../../../../app/cad/filtering/custom-filters/CustomFilterByAgencies';
import { AgencyModel } from '../../../../services/agency/agencyModel';
import { ZoneService } from '../../../../services/zones/zone-service';
import { ZoneModel } from '../../../../services/zones/zone-model';
import Session from '../../../../../../app/login/Session';

/**
 * The controller for the ZoneComponent.
 */
export default abstract class ZoneComponentController {
    private zoneFullList: ZoneModel[];

    private preSelectedValues: FilterModel[];

    constructor(
        protected $scope: IScope,
        protected $rootScope: IRootScopeService,
        public customFilterByZones: CustomFilterByZones,
        public zoneService: ZoneService,
        protected customFilterByAgencies: CustomFilterByAgencies,
        protected session: Session,
        private retry: string,
    ) {
        this.zoneFullList = [];
        $scope.isFeatureAvailable = zoneService.isZonesApiAvailable();

        $scope.userZone = this.session.data.zone;

        this.preSelectedValues = this.customFilterByZones.getEditingValues();

        $scope.zones = [];

        $scope.newItemSelected = this.newItemSelected;

        $scope.isLoading = false;

        $scope.error = false;

        $scope.unavailableLabel = `Requires Flex ${zoneService.ZONES_API_MIN_VERSION}+`;

        $scope.isBeingFilteredByAgencies = false;

        $rootScope.$on(this.retry, () => {
            this.loadAllZones();
        });

        this.handleAgenciesChanges(this.customFilterByAgencies.getAllValues());

        this.customFilterByAgencies.selectedAgenciesObservable.subscribe(this.handleAgenciesChanges);
    }

    private loadAllZones = () => {
        this.$scope.error = false;
        this.$scope.isLoading = true;
        this.$scope.zones = [];

        this.zoneService.getZones(this.session.data.agencyCode).then(this.handleLoadZoneSuccess).catch(this.handleLoadZoneError);
    };

    private handleLoadZoneSuccess = (data: ZoneModel[]) => {
        if (this.preSelectedValues !== undefined && this.preSelectedValues.length > 0) {
            data.map((item: any) => {
                item.checked = this.preSelectedValues.find((subItem: FilterModel) => subItem.id === item.abbreviation && subItem.applied) !== undefined;
                item.applied = item.checked;
                return item;
            });
        }

        // Sort it by getting applied items fisrt
        data.sort((a, b) => {
            if ((a.applied && !b.applied) || (a.abbreviation === this.$scope.userZone)) {
                return -1;
            } else if ((!a.applied && b.applied) || (b.abbreviation === this.$scope.userZone)) {
                return 1;
            } else {
                return 0;
            }
        });

        this.$scope.zones = data;
        this.zoneFullList = data.map((x) => x);
        this.$scope.error = false;
        this.$scope.isLoading = false;
        this.handleAgenciesChanges(this.customFilterByAgencies.getAllValues());
    };

    private handleAgenciesChanges = (agencies: AgencyModel[]) => {
        const checkedAgencies = agencies.filter(value => value.checked === true);
        this.$scope.isBeingFilteredByAgencies = checkedAgencies !== undefined && checkedAgencies.length > 0;

        this.filterZonesBasedOnAgencies(checkedAgencies);
    };

    private filterZonesBasedOnAgencies = (agencies: AgencyModel[]) => {
        this.$scope.zones = this.zoneFullList.map((x) => x);
        this.$scope.zones = ZoneModel.filterZonesBasedOnAgencies(this.$scope.zones, agencies);
    };

    private handleLoadZoneError = (error: any) => {
        this.$scope.error = true;
        this.$scope.isLoading = false;

        log('load zones error');
        logError(error);
    };

    private newItemSelected = (data: any) => {
        this.customFilterByZones.setValues(data);
    };

    public $onInit = () => {
        this.loadAllZones();
    };
}

