/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * Creates a new decorator that is the combination of all of the given decorators.
 *
 * @param decorators Any collection of property decorators that will be combined into a single decorator.
 * @returns A new decorators that will call each of the original decorators.
 */
export function combinePropertyDecorators(...decorators: PropertyDecorator[]): PropertyDecorator {
    return (target, propertyKey) => decorators.forEach(d => d(target, propertyKey));
}
