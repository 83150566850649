/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { LoggingLevel } from './logging-level';

/**
 * The configuration that determines which logging levels will actually
 * be logged vs just ignored.
 */
@Injectable()
export class LoggerConfig {

    /**
     * This sets the (minimum) enabled logging level.
     * If set to undefined (the default), then no logging will occur.
     *
     * For example:
     * If enabledLoggingLevel === 'error', then only 'error' will be logged.
     * If enabledLoggingLevel === 'warn', then both 'warn' and 'error' will be logged.
     * If enabledLoggingLevel === 'info', then 'info', 'warn' and 'error' will be logged.
     * etc...
     */
    public enabledLoggingLevel: LoggingLevel;
}
