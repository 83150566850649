/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import Zone from '../../../../schema/Zone';
import UnitQuery from './UnitQuery';

/**
 * Requests all data about the unit's zone.
 */
export default class ZoneUnitQuery extends UnitQuery<Zone> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['zoneAPI'];

    /**
     * Constructs a new instance of the ZoneQuery class.
     *
     * @param zoneAPI The API for retrieving Zones from the server.
     */
    constructor(private zoneAPI: IResourceClass<Zone>) {
        super();
    }

    // @Override
    protected get watchPropertyNames(): string | string[] {
        return 'zone';
    }

    // @Override
    protected get updatePropertyName(): string {
        return 'fullZone';
    }

    // @Override
    protected get defaultValue(): Zone {
        return undefined;
    }

    // @Override
    protected performQuery(newValues: any[]): Zone {
        return this.zoneAPI.get({ id: newValues[0] });
    }
}
