/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Device } from 'ionic-native/dist/es5/plugins/device';

import { ApiAuthenticationConfig } from '../api-authentication-config';
import { injectionCondition } from '../../conditional-injection/injection-condition.decorator';
import { AuthenticationParameterService } from './authentication-parameter.service';

/**
 * Provides the authentication parameters for an Android or iOS device (as opposed to the browser).
 */
@Injectable()
@injectionCondition({ platforms: ['android', 'ios'] })
export class DeviceAuthenticationParameterService extends AuthenticationParameterService {

    constructor(config: ApiAuthenticationConfig) {
        super(config);
    }

    /**
     * @inheritdoc
     */
    protected getAdditionalPairs(): [string, string][] {
        return [
            ['computer_name', Device.platform],
            ['computer_id', Device.uuid]
        ];
    }
}
