/* eslint-disable dot-notation */
/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IQService = angular.IQService;
import IResourceClass = angular.resource.IResourceClass;
import IResourceArray = angular.resource.IResourceArray;
import IStateService = angular.ui.IStateService;
import IPromise = angular.IPromise;
import ErrorHandlingController from '../../../../errorHandling/ErrorHandlingController';
import FileMetadata from '../../../../schema/FileMetadata';
import ImageMetadata from '../../../../schema/ImageMetadata';
import AttachmentStateParams from '../AttachmentStateParams';
import { UrlFactory } from '../../../../authentication';
import ApplicationScope from '../../../../ApplicationScope';
import PermissionAttribute from '../../../../permissions/enums/PermissionAttribute';
import PermissionName from '../../../../permissions/enums/PermissionName';
import PermissionsRepository from '../../../../permissions/PermissionsRepository';
import PermissionConfiguration from '../../../../permissions/PermissionConfiguration';
import IonicLoadingService = ionic.loading.IonicLoadingService;
import IonicModalService = ionic.modal.IonicModalService;
import IonicPopoverService = ionic.popover.IonicPopoverService;
import IonicSlideBoxDelegate = ionic.slideBox.IonicSlideBoxDelegate;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;
import IAngularEvent = angular.IAngularEvent;
import IWindowService = angular.IWindowService;
import { PlatformDetector } from '../../../../conditional-injection/platform-detection/platform-detector.service';
import { ChangeDetectorRef } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const MediaPicker: any;
/**
 * The controller for the attachment list page.
 */
export default class AttachmentListController extends ErrorHandlingController<[IResourceArray<ImageMetadata>, IResourceArray<FileMetadata>]> {
    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        '$scope',
        '$ionicLoading',
        '$timeout',
        '$q',
        '$window',
        'imageAPI',
        'fileAPI',
        '$stateParams',
        '$state',
        'urlFactory',
        '$ionicModal',
        '$ionicPopover',
        '$ionicSlideBoxDelegate',
        '$ionicScrollDelegate',
        'permissionsRepository',
        'platformDetector'
    ];
    /**
     * The name of the database table.
     */
    public table: string;

    /**
     * The timeout that is binded with Angular
     */
    public timeoutAngular: angular.ITimeoutService;

    /**
     * The custom title of the screen.
     */
    public title: string;

    /**
     * The array of metadata about each of the images to display.
     */
    public images: IResourceArray<ImageMetadata>;

    /**
     * The array of metadata about each of the files to display.
     */
    public files: IResourceArray<FileMetadata>;

    /**
     * Determines if the attachments should be arranged in a list as opposed to a grid.
     */
    public isListMode: boolean;

    /**
     * The index that determines which type of items are currently shown
     * (and which are filtered out).
     */
    public currentFilterIndex: number;

    /**
     * The title that explains which items are currently being displayed.
     */
    public currentFilterTitle: string;

    /**
     * The list of titles that explain which items are currently being displayed.
     * Created from processAttachmentPermissions()
     */
    public filterTitles: string[] = [];

    /**
     * A Map containing a list of tab names and their permissions.
     */
    public attachmentPermissionMap = new Map<string, { permissions: PermissionConfiguration }>([
        ['Photos', { permissions: [PermissionName.imaging] }],
        ['Attachments', { permissions: [PermissionName.filecapture] }],
        ['All', {
            permissions:
            {
                or: true, permissions: [
                    { or: false, permissionName: PermissionName.imaging, attributes: [PermissionAttribute.access, PermissionAttribute.add] },
                    { or: false, permissionName: PermissionName.filecapture, attributes: [PermissionAttribute.access, PermissionAttribute.add] }]
            }
        }
        ]]);

    public customCameraSettings = <any>{};

    public availableScaleOptions = <any>[];

    public photoPreview: any;

    /**
     * A Promise for selecting images from the gallery of phone device.
     */

    public selectImages: Promise<any>;

    /**
     * The base URL from which to retrieve an image.
     * Only the image number needs to be appended in order to construct the full URL.
     */
    private baseImageUrl: string;

    /**
     * The controller for the ionicModal that is used to display a larger view of the images.
     */
    private imageDisplayModalController: ionic.modal.IonicModalController;

    /**
     * The controller for the ionicPopover that allows the user to choose whether to add an image or a file.
     */
    private attachmentChoicePopoverController: ionic.popover.IonicPopoverController;

    private fileGalleryChoicePopoverController: ionic.popover.IonicPopoverController;

    private errorThubnail: any;

    /**
     * A delegate that is used to control the slide box that displays images inside of the modal dialog.
     */
    private imageSlideBoxDelegate: any;

    private isCustomCameraStarted = false;

    private isCameraVisible = false;

    /**
     * Constructs a new instance of the AttachmentListController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param $ionicLoading An overlay that displays a spinner while waiting for data to be loaded.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param $q The angular service that handles creating and working with promises.
     * @param $window The Angular wrapper around the window object.
     * @param imageAPI The service that retrieves image metadata from the server.
     * @param fileAPI The service that retrieves file metadata from the server.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     * @param $state The service that transitions between states.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param $ionicModal A class that is used to create modal dialogs.
     * @param $ionicPopover The service that displays a popover, a popup that hovers over the app's content.
     * @param $ionicSlideBoxDelegate A delegate that is used to control ionic slide boxes.
     * @param $ionicScrollDelegate A delegate that is used to control ionic scroll views.
     * @param permissionsRepository
     * @param platformDetector
     */
    constructor(
        $scope: ApplicationScope,
        $ionicLoading: IonicLoadingService,
        $timeout: angular.ITimeoutService,
        private $q: IQService,
        $window: IWindowService,
        private imageAPI: IResourceClass<ImageMetadata>,
        private fileAPI: IResourceClass<FileMetadata>,
        private $stateParams: AttachmentStateParams,
        private $state: IStateService,
        urlFactory: UrlFactory,
        $ionicModal: IonicModalService,
        $ionicPopover: IonicPopoverService,
        private $ionicSlideBoxDelegate: IonicSlideBoxDelegate,
        private $ionicScrollDelegate: IonicScrollDelegate,
        private permissionsRepository: PermissionsRepository,
        private platformDetector: PlatformDetector,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super($scope, $ionicLoading, $timeout);
        this.timeoutAngular = $timeout;
        this.table = $stateParams.table;
        this.title = $stateParams.title;
        this.baseImageUrl = urlFactory.create({ path: '/images' });

        // Image display
        this.imageDisplayModalController = $ionicModal.fromTemplate(require('../modalDialogs/imageDisplay.html'), { scope: $scope });

        // list vs grid
        this.isListMode = false;

        // Add Attachments
        this.attachmentChoicePopoverController = $ionicPopover.fromTemplate(require('../modalDialogs/attachmentChoice.html'), { scope: $scope });

        // File or Gallery chooser for Add Image button
        this.fileGalleryChoicePopoverController = $ionicPopover.fromTemplate(require('../modalDialogs/imagesGalleryFileChoice.html'), { scope: $scope });

        // Process and create arrays used for attachments.
        this.processAttachmentPermissions();

        // If there is only one slide the button bar will be hidden so set the currentFilterTitle,
        // otherwise initialize the 'All' tab.
        this.filterTitles.length === 1 ? this.currentFilterTitle = this.filterTitles[0] : this.changeFilter(2);

        $scope.$on('$stateChangeStart', () => {
            this.stopCamera();
        });

        angular.element($window).on('resize', () => {
            this.onScreenOrientationChange();
        });
    }

    public $onInit = () => { };
    /**
     * Toggles `isListMode` between true and false.
     */
    public toggleListMode = (): void => {
        this.isListMode = !this.isListMode;
        this.$ionicScrollDelegate.resize();
    };

    /**
     * Displays a larger view of an image in a modal dialog.
     *
     * @param imageIndex The index of the image to display.
     */
    public showModal = (imageIndex: number): void => {
        if (!this.imageSlideBoxDelegate) {
            this.imageSlideBoxDelegate = this.$ionicSlideBoxDelegate.$getByHandle('modal-image-slide-box');
        }

        let count = this.imageSlideBoxDelegate.slidesCount();

        // If there's a count, then the slides are already loaded and we can change the index before showing the modal.
        if (count) {
            this.imageSlideBoxDelegate.slide(imageIndex);
            this.imageDisplayModalController.show();
        } else {
            // Otherwise, we have to wait for the modal to be loaded before changing the index.
            this.imageDisplayModalController.show().then(() => {
                this.imageSlideBoxDelegate.slide(imageIndex);
            });
        }
    };

    /**
     * Hides the modal dialog that displays larger images.
     */
    public hideModal = (): void => {
        this.imageDisplayModalController.hide();
    };

    /**
     * Displays a popup that lets the user choose whether to add an image or a file.
     */
    public chooseAttachmentType = ($event: IAngularEvent): void => {
        this.attachmentChoicePopoverController.show($event);
    };

    public chooseDirectoryType = ($event: IAngularEvent): void => {
        this.fileGalleryChoicePopoverController.show($event);
    };

    /**
     * Get the image URL for the given image ID.
     *
     * @param imageId the image to get the URL of.
     * @returns the image URL.
     */
    public getImageUrl = (imageId: string): string => {
        return this.baseImageUrl && imageId ? this.baseImageUrl + '/' + imageId + '/medium' : undefined;
    };

    /**
     * Transitions to the 'addImage' state - passing along the given images so that they may be saved.
     *
     * @param images The images to save.
     */
    public addImages = (images: File[]): void => {
        this.getFiles(images, 'image/*');
        this.fileGalleryChoicePopoverController.hide();
    };

    /**
     * Transitions to the 'addFile' state - passing along the given files so that they may be saved.
     *
     * @param files The files to save.
     */
    public addFiles = (files: File[]): void => {
        this.getFiles(files, '*/*');
    };

    /**
     * Opens device camera, get made photo
     */
    public takePhoto() {
        this.attachmentChoicePopoverController.hide();

        const onSuccess = (uri: any) => {
            const name = /[^/]*$/.exec(uri)[0];
            const type = name.split('.')[1];
            const mediaType = `image/${type}`;
            const photo = [{
                uri,
                mediaType,
                name
            }];

            this.getFilePath(photo).then(() => {
                this.onSelectFiles(photo, 'image/*');
            });
        };
        const onError = (message: string) => {
            // eslint-disable-next-line no-console
            console.log(message);
        };

        navigator['camera'].getPicture(onSuccess, onError);
    }

    /**
     * Opens custom camera (Android)
     */
    public startCamera(width?: number, height?: number, onSuccess?: Function) {
        if (!width && !height) {
            this.setDefaultCameraSettings();
        }
        const defaultScale = this.getScaleParams(this.customCameraSettings.currentScale);
        const defaultHeight = defaultScale && defaultScale.height;
        const defaultWidth = defaultScale && defaultScale.width;
        const config = {
            x: 0,
            y: 35,
            width: width || window.screen.width,
            height: height || defaultHeight ? window.screen.width * Number(defaultHeight) / Number(defaultWidth) : window.screen.height,
            camera: 'back',
            toBack: true,
            tapFocus: true,
            storeToFile: true
        };
        window['CameraPreview'].startCamera(config, () => {
            this.onCameraStateChange(true);
            this.attachmentChoicePopoverController.hide();

            if (angular.isFunction(onSuccess)) {
                onSuccess();
            }
        },
        (error: any) => {
            // eslint-disable-next-line no-console
            console.log(error);
        });
    }

    /**
     * Stop custom camera (Android)
     */
    public stopCamera() {
        window['CameraPreview'].stopCamera();
        this.photoPreview = undefined;
        this.onCameraStateChange(false);
        this.setDefaultCameraSettings();
    }

    /**
     * Take the picture (Android)
     */
    public takePicture() {
        const config = {
            storeToFile: true
        };
        window['CameraPreview'].takePicture(config, (imageData: any) => {
            const path = `file://${imageData[0]}`;
            const link = window['Ionic'].WebView.convertFileSrc(path);
            const name = /[^/]*$/.exec(imageData[0])[0];
            const type = name.split('.')[1];
            const mediaType = `image/${type}`;
            const photo = [{
                path,
                link,
                mediaType,
                name
            }];
            this.photoPreview = photo;
            this.isCameraVisible = true;
            this.onSelectFiles(photo, 'image/*');
        }, (err: any) => {
            // eslint-disable-next-line no-console
            console.log(err);
        });
    }

    public changeCameraPreviewVisibility(state: boolean) {
        if (state) {
            window['CameraPreview'].show();
            this.photoPreview = undefined;
        } else {
            window['CameraPreview'].hide();
        }
        this.isCameraVisible = state;
    }

    /**
     * Switch custom camera direction (Android)
     */
    public switchCamera() {
        window['CameraPreview'].switchCamera();
    }

    /**
     * Change camera flash mode (Android)
     */
    public changeFlashMode() {
        window['CameraPreview'].getFlashMode((currentFlashMode: string) => {
            const nextFlasModeIndex = this.customCameraSettings.flashOptions.indexOf(currentFlashMode) < 2 ? this.customCameraSettings.flashOptions.indexOf(currentFlashMode) + 1 : 0;
            const flashMode = this.customCameraSettings.flashOptions[nextFlasModeIndex];
            window['CameraPreview'].setFlashMode(flashMode);
            this.customCameraSettings.flashMode = `icon-flash_${flashMode}`;
        });
    }

    /**
     * Change camera zoom (Android)
     */
    public changeZoom() {
        this.customCameraSettings.setZoom = this.customCameraSettings.setZoom < 20 ? this.customCameraSettings.setZoom + 5 : 0;
        window['CameraPreview'].setZoom(this.customCameraSettings.setZoom);
    }

    /**
     * Change camera scale (Android)
     */
    public changeScale(scale: string) {
        const scaleParams = this.getScaleParams(scale);
        const width = window.screen.width;
        const height = window.screen.width * Number(scaleParams.height) / Number(scaleParams.width);

        if (this.customCameraSettings.scalePanelOpened && this.customCameraSettings.currentScale !== scale) {
            this.customCameraSettings.currentScale = scale;
            const onSuccess = () => {
                this.customCameraSettings.scalePanelOpened = false;
                this.availableScaleOptions = this.customCameraSettings.scaleOptions;
            };
            window['CameraPreview'].stopCamera(() => {
                this.startCamera(width, height, onSuccess);
            });
        } else {
            this.customCameraSettings.scalePanelOpened = !this.customCameraSettings.scalePanelOpened;
            this.availableScaleOptions = [...this.customCameraSettings.scaleOptions];
            this.availableScaleOptions.splice(this.customCameraSettings.scaleOptions.indexOf(this.customCameraSettings.currentScale), 1);
        }
    }

    /**
     * Check if app runned from device, called in template
     */
    public isDevice() {
        return window.cordova;
    }

    /**
     * Check if camera plugin is available, called in template
     */
    public isCameraAvailable() {
        return navigator['camera'];
    }

    /**
     * Check if custom camera plugin is available, platform is android
     */
    public isCustomCameraAvailable() {
        return window['CameraPreview'] && this.platformDetector.getPlatform() === 'android';
    }

    /**
     * Show 'Take photo' if platform is iOS, called in template
     */
    public isShowTakePhoto() {
        return this.platformDetector.getPlatform() === 'ios';
    }

    /**
     * Restart camera on screen orientation change to update screen proportion
     */
    public onScreenOrientationChange() {
        if (this.isCustomCameraStarted) {
            setTimeout(() => {
                window['CameraPreview'].stopCamera(() => {
                    this.startCamera();
                });
            }, 100);

        }
    }

    /**
     * functions for changing images data
     */

    public selectGalleryImages(type: string) {
        if (this.platformDetector.getPlatform() === 'android') {
            this.addImages([]);
        } else {
            const args = {
                'selectMode': 100, // 101=picker image and video , 100=image , 102=video
                'maxSelectCount': 10, // default 40 (Optional)
                'maxSelectSize': 188743680 // 188743680=180M (Optional)
            };
            if (type === 'image/*') {
                this.fileGalleryChoicePopoverController.hide();
            }
            this.attachmentChoicePopoverController.hide();
            this.selectImages = new Promise(function (res: any, rej: any) {
                MediaPicker.getMedias(args, function (medias: any) {
                    // medias [{mediaType: "image", path:'/storage/emulated/0/DCIM/Camera/2017.jpg', uri:"android return uri,ios return URL" size: 21993}]
                    res(medias);
                }, function (e: any) {
                    rej(e);
                });
            }).then((data: any) => {
                this.getImageLink(data).then(() => {
                    setTimeout(() => {
                        this.imageDataChanger(data, type);
                    }, 410);
                });
            });
        }
    }

    // @Override
    protected performRequest(): IPromise<[IResourceArray<ImageMetadata>, IResourceArray<FileMetadata>]> {
        return this.$q.all([
            this.imageAPI.query(this.$stateParams).$promise,
            this.fileAPI.query(this.$stateParams).$promise
        ]);
    }

    // @Override
    protected requestComplete([images, files]: [IResourceArray<ImageMetadata>, IResourceArray<FileMetadata>]): void {
        this.images = images;
        this.files = files;
        this.timeoutAngular(() => this.changeDetectorRef?.detectChanges(), 3000);
    }

    private changeImageData = (medias: any, type: string): any[] => {
        let imageNewData = medias.map((image: any) => {
            let imagePathInd: number;
            let imageType: string;
            let imageTypeArr: string[] = [];
            let imagePathArr = image.path.split('');
            imagePathArr.forEach((path: string, index: number) => {
                if (path === '.') {
                    imagePathInd = index + 1;
                }
            });
            for (let i = imagePathInd; i < imagePathArr.length; i++) {
                imageTypeArr.push(imagePathArr[i]);
            }
            imageType = imageTypeArr.join('');
            if (type === 'image/*') {
                image.mediaType = `image/${imageType}`;
                image.name = `images.${imageType.toLowerCase()}`;
            }
            if (type === '*/*') {
                image.mediaType = `file/${imageType}`;
                image.name = `file.${imageType.toLowerCase()}`;
            }
            image.thumbnailBase64 = `data:image/jpeg;base64,${image.thumbnailBase64}`;
            return image;
        });

        return imageNewData;
    };

    private imageObjGenerate = (medias: any[]): any[] => {
        const imageArray: any[] = [];
        medias.forEach((image: any) => {
            let imageObj = {
                $$hashKey: image.index.toString(),
                link: image.thumbnailBase64,
                mediaType: image.mediaType,
                name: image.name,
                path: image.uri,
                uri: image.path
            };
            imageArray.push(imageObj);
        });
        return imageArray;
    };

    private imageDataChanger = async (medias: any, type: string) => {
        let imageData = this.changeImageData(medias, type);
        let imageDataArray = this.imageObjGenerate(imageData);
        this.onSelectFiles(imageDataArray, type);
    };

    /**
     * Get multiple images from the gallery of the phone device
     */

    private getImageLink = async (images: any) => {
        let imageValue = await images.forEach((image: any) => {
            MediaPicker.extractThumbnail(image, function (data: any) {
                image.thumbnailBase64 = data.thumbnailBase64;
            }, function (e: any) {
                this.errorThubnail = e;
            });
        });
        return await imageValue;
    };

    /**
     * Restore default camera settings (Android)
     */
    private setDefaultCameraSettings() {
        this.customCameraSettings = {
            setZoom: 0,
            zoomOptions: [0, 5, 10, 15, 20],
            flashOptions: ['off', 'on', 'auto'],
            flashMode: 'icon-flash_off',
            scalePanelOpened: false,
            scaleOptions: ['18:9', '16:9', '4:3']
        };
        const ratio = window.screen.height / window.screen.width;
        if (ratio < 2 && ratio >= 1.7) {
            this.customCameraSettings.scaleOptions = ['16:9', '4:3'];
        } else if (ratio < 1.7) {
            this.customCameraSettings.scaleOptions = [];
        }
        this.customCameraSettings.currentScale = this.customCameraSettings.scaleOptions[0];
    }

    /**
     * Add class to body element on camera start (Android)
     */
    private onCameraStateChange(state: boolean) {
        const bodyEl = angular.element(document.querySelector('body'));
        this.isCustomCameraStarted = state;
        state ? bodyEl.addClass('camera-on') : bodyEl.removeClass('camera-on');
    }

    private getScaleParams(scale: string) {
        const scaleParams = scale && scale.split(':');
        return {
            height: scaleParams && scaleParams[0],
            width: scaleParams && scaleParams[1]
        };
    }

    /**
     * Get files in case angular-file-uploader used (for browser)
     * or fires method getFile of cordova-plugin-simple-file-chooser (for app)
     *
     * @param selectedFiles The files to save.
     * @param type Type of selected file.
     */
    private getFiles = (selectedFiles: File[], type: string) => {
        this.attachmentChoicePopoverController.hide();
        if (window['chooser']) {
            window['chooser'].getFile(type).then((files: any) => {
                if (!files.length) {
                    files = [files];
                }
                this.getFilePath(files).then(() => {
                    this.onSelectFiles(files, type);
                });
            }, (error: any) => {
                // eslint-disable-next-line no-console
                console.log('error on getFile:', error);
            });
        } else {
            this.onSelectFiles(selectedFiles, type);
        }
    };

    /**
     * Used file path is saved in uri param
     *
     * @param files The files to save.
     */
    private getFilePath = async (files: any) => {
        return await files.forEach((file: { uri: string; path: string; link: string }) => {
            file.path = file.uri;
            file.link = window['Ionic'].WebView.convertFileSrc(file.uri);
        });
    };

    /**
     * Fires addAttachment method with params dependent on file type
     *
     * @param files The files to save.
     * @param type Type of selected file.
     */
    private onSelectFiles = (files: any[], type: string) => {
        this.addAttachments(files, `${type === 'image/*' ? 'app.attachments.addImage' : 'app.attachments.addFile'}`);
    };

    /**
     * Changes the current filtering. The indices are as follows:
     *   0 = images only
     *   1 = files only
     *   2 = images and files
     *
     * @param newFilterIndex The index that specifies which types of items are shown.
     */
    private changeFilter = (newFilterIndex: number): void => {
        this.currentFilterIndex = newFilterIndex;
        // If 'All' is selected swap the title name with 'Photos/Attachments'
        this.currentFilterTitle = this.filterTitles[newFilterIndex] === 'All' ? 'Photos/Attachments' : this.filterTitles[newFilterIndex];
        this.$ionicScrollDelegate.resize();
    };

    /**
     * Transitions to the specified state - passing along the given attachments so that they may be saved.
     *
     * @param attachments The attachments to save.
     * @param state The state to which to transition.
     */
    private addAttachments(attachments: File[], state: string): void {
        if (!attachments || attachments.length < 1) {
            throw Error('At least one attachment is required.');
        }

        if (this.isCustomCameraStarted && this.isCameraVisible) {
            this.changeCameraPreviewVisibility(false);
            return;
        } else {
            this.photoPreview = undefined;
        }

        this.$state.go(state, {
            table: this.$stateParams.table,
            title: this.$stateParams.title,
            id: this.$stateParams.id,
            attachments: attachments
        });
    }

    /**
     * Process the permissions for images/attachments and create an array if the user has access.
     */
    private processAttachmentPermissions() {
        for (let [k, v] of this.attachmentPermissionMap.entries()) {
            if (k !== 'All' && this.permissionsRepository.processPermissions(v.permissions)) {
                this.filterTitles.push(k);
            }
        }

        // Include the 'All' tab if the user has access to all others.
        if (this.filterTitles.length > 1) {
            this.filterTitles.push('All');
        }
    }
}
