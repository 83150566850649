/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * A contact for a person.
 */
@table('nmextra')
export class Contact {

    @field('contact')
    public name: string;

    @expand('crelshp', 'desc')
    public relationship: string;

    @field('caddr')
    public address: string;

    @field('cphone')
    public phone: string;
}
