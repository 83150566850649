/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Type } from '@angular/core';
import { SemVer, rcompare } from 'semver';
import { ConditionalInjectionFilter } from './conditional-injection-filter';
import { VersionService } from '../versioning/version.service';
import { VersionParser } from '../versioning/version-parser.service';
import groupBy = require('lodash/groupBy');
import { InjectionCondition } from '../injection-condition';

/**
 * A filter that returns either
 * (1) the services that match the server version EXACTLY or
 * (2) the services that have the highest version number just below the server version.
 *
 * We assume that if a service is introduced in 1701, then it's still valid in 1702, 1801, etc.
 * It stays valid until a newer service is introduced in, say 1802, for example.
 */
@Injectable()
export class ServerVersionConditionalInjectionFilter extends ConditionalInjectionFilter<'minServerVersion', SemVer> {

    /**
     * @inheritdoc
     */
    protected readonly propertyName = 'minServerVersion';

    /**
     * @inheritdoc
     */
    protected readonly defaultValue = new SemVer('0.0.0');

    /**
     * Constructs a new instance of the ServerVersionConditionalInjectionFilter class.
     *
     * @param versionService The service that provides the server version.
     * @param parser The parser that creates a Version object from a string.
     */
    constructor(
        private versionService: VersionService,
        private parser: VersionParser
    ) {
        super();
    }

    /**
     * @inheritdoc
     */
    protected doParsing(rawValue: InjectionCondition['minServerVersion']): SemVer {
        return this.parser.parse(rawValue);
    }

    /**
     * @inheritdoc
     */
    protected doFiltering(tuples: [SemVer, Type<any>][]): [SemVer, Type<any>][] {
        const groups = groupBy(tuples, t => t[0]);

        // The pairs are sorted in descending order so that the most up-to-date service comes first.
        const descendingVersions = Object.keys(groups).sort(rcompare);

        // As soon as the server version is greater than or equal to any service version, then we will use that service.
        // This works because we assume that once a service is introduced, it's valid from then on into the future.
        // It only becomes invalid when a new service is introduced that has a higher version associated with it.
        for (let version of descendingVersions) {
            if (this.versionService.serverVersion.compare(<any>version) >= 0) { // TODO: Fix semver typings
                return groups[version];
            }
        }

        return [];
    }
}
