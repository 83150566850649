/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import GetFirstElementTransform from './responseTransforms/GetFirstElementTransform';
import InvolvementAPI from './involvement/InvolvementAPI';
import RadioLogAPI from './RadioLogAPI';
import FireIncidentAPI from './FireIncidentAPI';
import EMSIncidentAPI from './EMSIncidentAPI';
import LawFireEmsAPI from './LawFireEmsAPI';
import AgencyAPI from './AgencyAPI';
import CadCallAPI from './CadCall/CadCallAPI';
import ZoneAPI from './ZoneAPI';
import CityAPI from './CityAPI';
import AddressAlertAPI from './address-alerts/AddressAlertAPI';
import ImageAPI from './ImageAPI';
import FileAPI from './FileAPI';
import FileTypeAPI from './FileTypeAPI';
import ImageTypeAPI from './ImageTypeAPI';
import CadCallCommentAPI from './CadCallCommentAPI';
import TenCodeAPI from './TenCodeAPI';
import IncidentAPI from './incident/IncidentAPI';
import IncidentJoinedTablesDecoratorTransform from './incident/responseTransforms/IncidentJoinedTablesDecoratorTransform';
import IncidentRadioLogsDecoratorTransform from './incident/responseTransforms/IncidentRadioLogsDecoratorTransform';
import IncidentInvolvementsDecoratorTransform from './incident/responseTransforms/IncidentInvolvementsDecoratorTransform';
import NameAPI from './name/NameAPI';
import NameAgeDecoratorTransform from './name/responseTransforms/NameAgeDecoratorTransform';
import NameJoinedTablesDecoratorTransform from './name/responseTransforms/NameJoinedTablesDecoratorTransform';
import NameAlertsDecoratorTransform from './name/responseTransforms/NameAlertsDecoratorTransform';
import NameInvolvementDecoratorTransform from './name/responseTransforms/NameInvolvementDecoratorTransform';
import NameCriticalNoticesDecoratorTransform from './name/responseTransforms/NameCriticalNoticesDecoratorTransform';
import OtherTelephoneAPI from './OtherTelephoneAPI';
import PropertyAPI from './property/PropertyAPI';
import PropertyJoinedTablesDecoratorTransform from './property/responseTransforms/PropertyJoinedTablesDecoratorTransform';
import PropertyInvolvementDecoratorTransform from './property/responseTransforms/PropertyInvolvementDecoratorTransform';
import SexCodeAPI from './codedValueList/SexCodeAPI';
import UnitAPI from './unit/UnitAPI';
import UnitFirstOfficerDecoratorTransform from './unit/responseTransforms/UnitFirstOfficerDecoratorTransform';
import UnitOfficerAPI from './unitOfficer/UnitOfficerAPI';
import VehicleAPI from './vehicle/VehicleAPI';
import VehicleJoinedTablesDecoratorTransform from './vehicle/responseTransforms/VehicleJoinedTablesDecoratorTransform';
import VehicleInvolvementDecoratorTransform from './vehicle/responseTransforms/VehicleInvolvementDecoratorTransform';
import { upgradeAdapter } from '../upgrade-adapter';
import { AddressAlertsBatchRequest } from './address-alerts/address-alerts-batch-request.service';
import { InvolvementAlertsBatchRequest } from './involvement/involvement-alerts-batch-request.service';
import { addInvolvementTypeTransform } from './responseTransforms/add-involvement-type-transform';

export const apiModule = angular.module('api', [])
    .constant('defaultPageSize', 20)
    .value('getFirstElementTransform', GetFirstElementTransform)
    .factory('involvementAPI', InvolvementAPI)
    .factory('radioLogAPI', RadioLogAPI)
    .factory('fireIncidentAPI', FireIncidentAPI)
    .factory('emsIncidentAPI', EMSIncidentAPI)
    .factory('cityAPI', CityAPI)
    .service('lawFireEmsAPI', LawFireEmsAPI)
    .factory('agencyAPI', AgencyAPI)
    .factory('cadCallAPI', CadCallAPI)
    .factory('zoneAPI', ZoneAPI)
    .factory('addressAlertAPI', AddressAlertAPI)
    .factory('imageAPI', ImageAPI)
    .factory('fileAPI', FileAPI)
    .factory('fileTypeAPI', FileTypeAPI)
    .factory('imageTypeAPI', ImageTypeAPI)
    .factory('cadCallCommentAPI', CadCallCommentAPI)
    .factory('tenCodeAPI', TenCodeAPI)
    .factory('incidentAPI', IncidentAPI)
    .service('incidentJoinedTablesDecoratorTransform', IncidentJoinedTablesDecoratorTransform)
    .service('incidentRadioLogsDecoratorTransform', IncidentRadioLogsDecoratorTransform)
    .service('incidentInvolvementsDecoratorTransform', IncidentInvolvementsDecoratorTransform)
    .factory('nameAPI', NameAPI)
    .service('nameJoinedTablesDecoratorTransform', NameJoinedTablesDecoratorTransform)
    .service('nameAgeDecoratorTransform', NameAgeDecoratorTransform)
    .service('nameAlertsDecoratorTransform', NameAlertsDecoratorTransform)
    .service('nameInvolvementDecoratorTransform', NameInvolvementDecoratorTransform)
    .service('nameCriticalNoticesDecoratorTransform', NameCriticalNoticesDecoratorTransform)
    .factory('otherTelephoneAPI', OtherTelephoneAPI)
    .factory('propertyAPI', PropertyAPI)
    .service('propertyJoinedTablesDecoratorTransform', PropertyJoinedTablesDecoratorTransform)
    .service('propertyInvolvementDecoratorTransform', PropertyInvolvementDecoratorTransform)
    .factory('sexCodeAPI', SexCodeAPI)
    .factory('unitAPI', UnitAPI)
    .service('unitFirstOfficerDecoratorTransform', UnitFirstOfficerDecoratorTransform)
    .factory('unitOfficerAPI', UnitOfficerAPI)
    .factory('vehicleAPI', VehicleAPI)
    .service('vehicleJoinedTablesDecoratorTransform', VehicleJoinedTablesDecoratorTransform)
    .service('vehicleInvolvementDecoratorTransform', VehicleInvolvementDecoratorTransform)
    .factory('addressAlertsBatchRequest', upgradeAdapter.downgradeNg2Provider(AddressAlertsBatchRequest))
    .factory('involvementAlertsBatchRequest', upgradeAdapter.downgradeNg2Provider(InvolvementAlertsBatchRequest))
    .value('addInvolvementTypeTransform', addInvolvementTypeTransform);
