/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import CustomFilterByTypes from '../../../../filtering/custom-filters/CustomFilterByTypes';
import TypeComponentController from '../../../../filtering/components/baseClasses/type/TypeComponentController';

import TypeScope from '../../../../filtering/components/baseClasses/type/TypeScope';
import { IRootScopeService } from 'angular';
import Session from '../../../../../../app/login/Session';
import { CALL_ACTIONS } from '../../../../filtering/consts';

/**
 * A component of types for call filtering
 */
export const callTypeComponent: angular.IComponentOptions = {
    template: require('../../../../filtering/components/baseClasses/type/type-component.component.html'),

    controller: class CallTypeComponentController extends TypeComponentController {
        public static $inject = ['$rootScope', '$scope', 'callCustomFilterByTypes', 'session'];
        constructor(
            $rootScope: IRootScopeService,
            $scope: TypeScope,
            customFilterByTypes: CustomFilterByTypes,
            session: Session,
        ) {
            super($rootScope, $scope, customFilterByTypes, session, CALL_ACTIONS);
        }
    }
};
