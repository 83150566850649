/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';

import { ApiAuthenticationConfig } from '../api-authentication-config';
import { injectionCondition } from '../../conditional-injection/injection-condition.decorator';
import { AuthenticationParameterService } from './authentication-parameter.service';

/**
 * Provides the authentication parameters for the browser (as opposed to an Android or iOS device).
 */
@Injectable()
@injectionCondition({ platforms: ['browser'] })
export class BrowserAuthenticationParameterService extends AuthenticationParameterService {

    constructor(config: ApiAuthenticationConfig) {
        super(config);
    }

    /**
     * @inheritdoc
     */
    protected getAdditionalPairs(): [string, string][] {
        return [];
    }
}
