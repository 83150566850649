/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import {
    FallbackHomeStatePolicy,
    FirstAllowedHomeStatePolicy,
    HOME_STATE_POLICY_TOKEN,
    MostVisitedHomeStatePolicy
} from './state-history/home-state-policies';
import { HomeStateProvider } from './state-history/home-state-provider.service';
import { StateHistoryStorage } from './state-history/state-history-storage.service';
import { HistoryJsonSerializer } from './history-json-serializer';
import { OrderByCountHistoryTransformation } from './transformations/order-by-count-history-transformation';

@NgModule({
    providers: [
        { provide: HOME_STATE_POLICY_TOKEN, useClass: MostVisitedHomeStatePolicy, multi: true },
        { provide: HOME_STATE_POLICY_TOKEN, useClass: FirstAllowedHomeStatePolicy, multi: true },
        { provide: HOME_STATE_POLICY_TOKEN, useClass: FallbackHomeStatePolicy, multi: true },
        HomeStateProvider,
        StateHistoryStorage,
        HistoryJsonSerializer,
        OrderByCountHistoryTransformation
    ]
})
export class HistoryModule { }
