/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from '../../../shared/database/database.service';
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import { AccidentVehicle } from './model/accident-vehicle';
import { SlideSourceInfo } from '../../../schema/SlideSourceInfo';

/**
 * A component that displays the details of a accident vehicle.
 */
@Component({
    selector: 'sds-accident-vehicle-detail',
    template: require('./accident-vehicle-detail.component.html')
})
export class AccidentVehicleDetailComponent implements OnInit {

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([this.commentsSlideSourceInfo]);

    /**
     * The accident vehicle model to display.
     */
    public accidentVehicle: AccidentVehicle;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the AccidentVehicleDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param commentsSlideSourceInfo inject the comments slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: AccidentVehicleStateParams,
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(AccidentVehicle, {
            number: this.$stateParams.id,
            vehicle: this.$stateParams.vehicleId
        })
            .map(this.populateArrays)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.accidentVehicle = detailModel);
    }

    /**
     * Populates several arrays where each one is composed of two or more
     * other properties on the accident vehicle.
     *
     * @param accidentVehicle The accident vehicle model whose array properties will be populated.
     * @returns The original accident vehicle that has been modified.
     */
    private populateArrays = (accidentVehicle: AccidentVehicle): AccidentVehicle => {
        if (accidentVehicle) {
            accidentVehicle.eventSequence = [
                accidentVehicle.event1,
                accidentVehicle.event2,
                accidentVehicle.event3,
                accidentVehicle.event4
            ].filter(i => !!i);
        }

        return accidentVehicle;
    };
}

/**
 * Defines the properties that are available on the `$stateParams` service
 * when passed to the `AccidentVehicleDetailComponent`.
 */
interface AccidentVehicleStateParams extends DetailsStateParams {

    /**
     * The ID of the vehicle that was involved in the accident.
     */
    vehicleId: string;
}
