/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input, ViewChild, Inject, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoComplete } from 'primeng/components/autocomplete/autocomplete';
import { CodeTableService } from './code-table.service';
import { Logger } from '../../../../logging';

/**
 * In order to use this component with ngModel, it must be registered as a "value accessor".
 * We're using the same approach used by PrimeNG.
 * See https://github.com/primefaces/primeng/blob/master/components/autocomplete/autocomplete.ts
 */
const autocompleteValueAccessor: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CodeTableAutocompleteComponent), // eslint-disable-line @angular-eslint/no-forward-ref
    multi: true
};

/**
 * A specialized autocomplete that knows how to retrieve and display codes from a database table.
 *
 * Note: For a more general-purpose autocomplete, see the AutoComplete provided by PrimeNG,
 * which is the autocomplete that this class uses internally.
 */
@Component({
    selector: 'sds-code-table-autocomplete',
    template: require('./code-table-autocomplete.component.html'),
    providers: [autocompleteValueAccessor]
})
export class CodeTableAutocompleteComponent implements ControlValueAccessor {

    /**
     * A reference to the inner autocomplete to which this component delegates
     * the actual work of showing the suggestions to the user.
     */
    @ViewChild(AutoComplete)
    public innerAutocomplete: AutoComplete;

    /**
     * The database table from which to retrieve the codes.
     */
    @Input()
    public table: string;

    /**
     * The name of the field whose value will be displayed in the textbox.
     *
     * The default is 'abbr'.
     */
    @Input()
    public valueField = 'abbr';

    /**
     * The comma-delimited list of field names whose values will be displayed in the drop-down.
     *
     * The default is 'abbr, desc'.
     */
    @Input()
    public dropDownFields = 'abbr, desc';

    /**
     * The sprintf-style format string that specifies how the `dropDownFields` should be displayed
     * to the user.
     *
     * The default is '%s - %s'.
     */
    @Input()
    public dropDownFormat = '%s - %s';

    /**
     * The formatted suggestions to pass along to the `innerAutocomplete`.
     */
    public suggestions: CodeTableAutocompleteSuggestion[];

    /**
     * Constructs a new instance of the CodeTableAutocompleteComponent class.
     *
     * @param codeTableService The service that knows how to load codes from a database table.
     * @param vsprintf A function that formats a string using sprintf notation.
     * @param logger Logs errors to the appropriate location based on the environment.
     * @param cdRef Instance of ChangeDetectorRef (responsible to detect changes in view tree)
     */
    constructor(
        private codeTableService: CodeTableService,
        @Inject('vsprintf') private vsprintf: (format: string, args: any[]) => string,
        private logger: Logger,
        private cdRef: ChangeDetectorRef
    ) {
    }

    /**
     * @inheritdoc
     */
    public writeValue(obj: any): void {
        this.innerAutocomplete.writeValue(obj);
    }

    /**
     * @inheritdoc
     */
    public registerOnChange(fn: any): void {
        this.innerAutocomplete.registerOnChange(fn);
    }

    /**
     * @inheritdoc
     */
    public registerOnTouched(fn: any): void {
        this.innerAutocomplete.registerOnTouched(fn);
    }

    /**
     * @inheritdoc
     */
    public setDisabledState?(isDisabled: boolean): void {
        this.innerAutocomplete.setDisabledState(isDisabled);
    }

    /**
     * Retrieves the suggestions from the server.
     *
     * @param text The text that the user entered.
     */
    public getSuggestions(text: string): void {
        this.codeTableService.get(this.table, text, this.dropDownFormat)
            .map(values => values.map(this.createSuggestion))
            .subscribe(
                values => {
                    this.suggestions = values;
                    this.cdRef.detectChanges();
                },
                error => {
                    this.suggestions = undefined;
                    this.logger.error(error);
                    this.cdRef.detectChanges();
                }
            );
    }

    /**
     * Creates a formatted suggestion when given a raw database object.
     *
     * @param databaseObject The raw object that represents a row in the database.
     * @returns A formatted suggestion.
     */
    private createSuggestion = (databaseObject: any): CodeTableAutocompleteSuggestion => {
        return {
            value: databaseObject[this.valueField],
            label: this.createDropDownDisplayValue(databaseObject)
        };
    };

    /**
     * Creates the formatted value to display in the drop-down.
     *
     * @param databaseObject The raw object that represents a row in the database.
     * @returns The formatted string to display in the drop-down.
     */
    private createDropDownDisplayValue(databaseObject: any): string {
        if (this.dropDownFields) {

            // First convert the comma-delimited string of field names to a proper array,
            // then get the values that correspond to those field names.
            const dropDownValues = this.dropDownFields.split(',').map(field => databaseObject[field.trim()]);

            // Finally return the formatted display value.
            return this.vsprintf(this.dropDownFormat, dropDownValues);
        }
        return undefined;
    }
}

/**
 * An interface that represents a suggestion to display to the user.
 */
export interface CodeTableAutocompleteSuggestion {

    /**
     * The value that will be used to populate the textbox if the user selects this suggestion.
     */
    value: string;

    /**
     * The value to display to the user in the drop-down list.
     */
    label: string;
}

/**
 * A type guard that determines if a given value is a CodeTableAutocompleteSuggestion.
 *
 * @param value The value to test whether or not it is a CodeTableAutocompleteSuggestion.
 * @return True if the value is a CodeTableAutocompleteSuggestion, false otherwise.
 */
export function isCodeTableAutocompleteSuggestion(value: any): value is CodeTableAutocompleteSuggestion {
    return !!value
        && typeof value === 'object'
        && 'value' in value
        && 'label' in value;
}
