export const DISABLE_INFINITY_SCROLL_EVENT = 'scroll.disableInfinityScroll';
export const INFINITY_SCROLL_COMPLETE_EVENT = 'scroll.infiniteScrollComplete';
export const CALL_HISTORY_PAGE_COUNT = 20;

// Minimum screen width (in px) for UI hybrid behavior
export const MIN_WIDTH_TO_FIT = 390;
export const MIN_WIDTH_TO_FIT_LARGE_FONT = 410;

export const HEIGHT_FOR_ACTIVE_TAB = 'auto';
export const HEIGHT_FOR_INACTIVE_TAB = '100px';

export const TIME_FOR_ANIMATION_COMPLETE = 250;
