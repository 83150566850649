/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';

/**
 * A component which displays the count and first item of an alert array
 */
@Component({
    selector: 'sds-alert',
    template: `
        <div *ngIf="alerts.length > 0" class="alert-description" sdsTextWrappedPopover>{{alerts[0] || 'Alert'}}</div>
        <div *ngIf="alerts.length > 1" class="alert-count" sdsTextWrappedPopover>{{alerts.length - 1}}</div>
    `,
    styles: [
        require('./alert.component.scss'),
        require('./alert.component.day.scss'),
        require('./alert.component.night.scss')]
})
export class AlertComponent {

    /**
     * The array of alerts.
     */
    @Input() public alerts: string[];
}
