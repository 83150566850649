/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * The set of permissions that are related to the RMS (records management system).
 */
export enum RmsPermissionName {
    accident,
    arrest,
    biinsp,
    booking,
    business,
    calls,
    casemgt,
    cdtrstop,
    citation,
    dsmain,
    ems,
    evidence,
    filecapture,
    fire,
    fldint,
    frfldint,
    imaging,
    impound,
    incident,
    inmisc,
    invl,
    law,
    license,
    lwnarr,
    lwsupl,
    names,
    nmassoc,
    notices,
    offenders,
    offense,
    pawnact,
    pawns,
    pcmain,
    premises,
    process,
    property,
    rcmain,
    snames,
    sooffs,
    sovehic,
    svehicle,
    vehicle,
    wants,
    warning
}

/**
 * The set of permissions that are not related to RMS (records management system).
 */
export enum NonRmsPermissionName {
    touchassignself = 100, // The set value 100 is used to provide padding in case other permissions are added to the RmsPermissionName enum above.
    touchcalls,
    touchcomments,
    touchrms,
    touchunit
}

/**
 * The compile-time type of the PermissionName enum.
 */
export type PermissionName = RmsPermissionName | NonRmsPermissionName;

/**
 * The enum that specifies the names of available permissions that may be called.
 */
export const PermissionName = Object.assign({}, RmsPermissionName, NonRmsPermissionName); // eslint-disable-line @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, no-redeclare, id-match

export default PermissionName;
