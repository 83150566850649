/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Injector } from '@angular/core';
import { PropertyNodeFactory } from './property-node-factory';
import { TableNodeFactory } from '../class/table-node-factory';
import { ClassNodeFactory } from '../class/class-node-factory';
import { ClassNode } from '../class/class-node';
import { PropertyNode } from './property-node';
import { expandMetadataKey, ExpandData } from '../../../decorators';
import { ExpandNode } from './expand-node';
import { ExpandSelectNode } from './expand-select-node';

/**
 * A factory that knows how to create ExpandNodes and ExpandSelectNodes.
 */
@Injectable()
export class ExpandNodeFactory implements PropertyNodeFactory {

    /**
     * The backing property for the `nodeFactory` getter.
     */
    private _nodeFactory: ClassNodeFactory;

    /**
     * Constructs a new instance of the ExpandNodeFactory class.
     *
     * @param injector The dependency injector provided by the Angular framework.
     */
    constructor(private injector: Injector) {
    }

    /**
     * Gets a factory that knows how to create ClassNodes.
     *
     * Note: The factory is lazy-loaded because of an unavoidable circular dependency.
     * The TableNodeFactory depends upon the MasterPropertyNodeFactory which in turn depends upon this class.
     *
     * @returns The factory that knows how to create ClassNodes.
     */
    private get nodeFactory(): ClassNodeFactory {
        if (!this._nodeFactory) {
            this._nodeFactory = this.injector.get(TableNodeFactory);
        }
        return this._nodeFactory;
    }

    /**
     * @inheritdoc
     */
    public create(parent: ClassNode, propertyKey: string | symbol): PropertyNode {
        const expandData: ExpandData = Reflect.getMetadata(expandMetadataKey, parent.classConstructor.prototype, propertyKey);

        if (expandData) {
            if (expandData.select) {
                return new ExpandSelectNode(parent, propertyKey, expandData.column, expandData.select);
            }
            return new ExpandNode(parent, propertyKey, expandData.column, expandData.childConstructor, this.nodeFactory);
        }
    }
}
