/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import CustomFilterByTypes from '../../../custom-filters/CustomFilterByTypes';

export default class TypeItem implements FilterModel {
    public name: string;
    public value: string;
    public checked = false;
    public applied = false;
    public storageKey: string = CustomFilterByTypes.STORAGE_KEY;
    public id: string;

    constructor(name: string, value: string, checked: boolean, applied: boolean) {
        this.name = name;
        this.value = value;
        this.checked = checked;
        this.applied = applied;
        this.id = value;
    }

    public verifyAndApplyUserLabel(userLabel: string): boolean {
        return false;
    }
}
