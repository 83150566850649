/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * The metadata key used to associated a database table with a particular class.
 */
export const tableMetadataKey = Symbol('table');

/**
 * A decorator factory that associates a database table with the class on which it is applied.
 *
 * @param name The name of the database table.
 * @returns The actual decorator that associates the database table with the class.
 */
export function table(name: string) {
    return Reflect.metadata(tableMetadataKey, name);
}
