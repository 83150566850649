/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import SettingsRepository from './SettingsRepository';
import SettingKey from './SettingKey';
import Setting from './Setting';
import { PlatformDetector } from '../conditional-injection/platform-detection/platform-detector.service';
import { VersionService } from '../conditional-injection';
import { TOUCH_WEB_APP_VERSION } from '../shared/constants/constants';
import { SpillmanLocalStorage } from '../shared/storage/spillman-local-storage';
declare const cordova: any;

/**
 * The controller for the list of settings.
 */
export default class SettingsController {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['settingsRepository', 'platformDetector', 'versionService', 'spillmanLocalStorage'];

    /**
     * The array of settings to display.
     */
    public settings: Setting<any>[];

    /**
     * The array of keys that represent the settings that should be displayed.
     */
    private keysToDisplay = [
        SettingKey.myIncidentsDurationSetting,
        SettingKey.commentRowsCountSetting,
        SettingKey.themeSetting,
        SettingKey.loggingLevelSetting,
        SettingKey.fontSizeSetting
    ];
    /**
     * The string that represent current Touch-app version.
     */
    private touchVersion: string;
    /**
     * The string that represent current Flex-server version.
     */
    private flexVersion: string;
    /**
     * The string that represent current Device's Unique ID
     */
    private deviceId: string;
    /**
     * Constructs a new instance of the SettingsController class.
     *
     * @param settingsRepository The repository that stores all settings.
     * @param platformDetector The object that detects the current platform.
     * @param versionService The service that retrieves and stores the server version.
     */
    constructor(
        settingsRepository: SettingsRepository,
        private platformDetector: PlatformDetector,
        private versionService: VersionService,
        private spillmanLocalStorage: SpillmanLocalStorage
    ) {
        // Check if this is mobile platform and set current app version.
        if (this.platformDetector.getPlatform() !== 'browser') {
            cordova.getAppVersion.getVersionNumber((version: string) => this.touchVersion = version);
        } else {
            this.touchVersion = TOUCH_WEB_APP_VERSION;
        }
        // Set current server version.
        this.flexVersion = this.versionService.serverVersion.raw;
        // Get current Device's Unique ID
        this.deviceId = this.spillmanLocalStorage.getObject({ key: 'deviceId' });
        // Filter the settings to only display those that should be displayed.
        if (window.screen.width < 375) {
            this.keysToDisplay.pop();
        }
        this.settings = this.keysToDisplay.map(settingsRepository.get);
    }

    public $onInit = () => { };
}
