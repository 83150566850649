/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, includeOne, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { AccidentName } from './accident-name';
import { AccidentVehicle } from './accident-vehicle';
import { OtherCause } from './other-cause';
import { Involvement } from '../../../schema/Involvement';
import { RoadContributingCircumstance } from './road-contributing-circumstance';
import { EnvironmentContributingCircumstance } from './environment-contributing-circumstance';
import { IncidentReference } from '../../shared/model/incident-reference';

/**
 * The accident model
 */
@table('acmain')
export class Accident {

    @field('number')
    public id: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @includeMany('acnmdtl', AccidentName)
    public accidentNames: AccidentName[];

    @includeMany('acvhdtl', AccidentVehicle)
    public accidentVehicles: AccidentVehicle[];

    @includeMany('accause', OtherCause)
    public otherCauses: OtherCause[];

    @includeMany('acrdcc', RoadContributingCircumstance)
    public roadContributingCircumstances: RoadContributingCircumstance[];

    @includeMany('acenvcc', EnvironmentContributingCircumstance)
    public environmentContributingCircumstances: EnvironmentContributingCircumstance[];

    @field('advsp')
    public advisedSpeed: number;

    @field('agency')
    public agency: string;

    @field('city')
    public city: string;

    @expand('collt', 'desc')
    public collisionType: string;

    @includeOne('acmdesc', 'text')
    public comments: string;

    @field('adate')
    public date: Date;

    @expand('firsloc', 'desc')
    public eventLocation: string;

    @expand('firshar', 'desc')
    public firstHarmfulEvent: string;

    @expand('sourcin', 'desc')
    public informationSource: string;

    @expand('inttyp', 'desc')
    public intersection: string;

    @expand('lcond', 'desc')
    public lightConditions: string;

    @expand('localt', 'desc')
    public localityType: string;

    @expand('locat', 'desc')
    public locationCode: string;

    @field('wrkzon')
    public nearConstruction: string;

    @field('nearl')
    public nearestLandmark: string;

    @expand('officer', 'fullnam')
    public officer: string;

    @field('injured')
    public peopleInjured: number;

    @field('killed')
    public peopleKilled: number;

    @expand('relinc', IncidentReference)
    public relatedIncident: IncidentReference | string;

    @expand('reljct', 'desc')
    public relatedToJunction: string;

    @expand('roadc', 'desc')
    public roadCharacter: string;

    @expand('roads', 'desc')
    public roadSurface: string;

    @expand('busrela', 'desc')
    public schoolBusRelated: string;

    @expand('sev', 'desc')
    public severity: string;

    @field('speed')
    public speedLimit: string;

    @field('state')
    public state: string;

    @includeOne('acinfo', 'acstid')
    public stateCrash: string;

    @field('street')
    public street: string;

    @field('atime')
    public time: string;

    @field('totdam')
    public totalDamage: number;

    @expand('traffc', 'desc')
    public trafficControl: string;

    @expand('wcond', 'desc')
    public weatherCondition1: string;

    @expand('wcond2', 'desc')
    public weatherCondition2: string;

    @expand('wrkloc', 'desc')
    public workZoneLocation: string;

    @expand('wrktyp', 'desc')
    public workZoneType: string;

    @field('wrkpres')
    public workersPresent: string;

    @field('zip')
    public zip: string;
}
