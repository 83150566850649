/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../shared/database/decorators';

/**
 * Descriptions for extra name fields.
 */
@table('nmextra')
export class NameExtra {

    @expand('citiz', 'desc')
    public citizenship: string;

    @expand('relig', 'desc')
    public religion: string;

    @expand('marital', 'desc')
    public marital: string;
}
