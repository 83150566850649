/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * A evidence local identification.
 */
@table('evlocal')
export class EvidenceLocalId {

    @field('localid')
    public id: string;

    @expand('type', 'desc')
    public type: string;
}
