/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { InvolvementsNode } from '../../nodes/property/involvements-node';
import { ModelCreationData } from '../model-creation-data';

/**
 * Performs a "visit" on an InvolvementsNode during the process of creating the human-friendly model.
 */
@Injectable()
export class InvolvementsNodeModelCreationVisitor implements TypeSpecificTreeNodeVisitor<InvolvementsNode, ModelCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [InvolvementsNode];

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: InvolvementsNode, data: ModelCreationData): void {
        for (let { databaseModel, humanFriendlyModel } of data.modelPairs.peek()) {
            humanFriendlyModel[node.propertyKey] = databaseModel.involvements || [];
        }
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: InvolvementsNode, _data: ModelCreationData): void {
        // Nothing to do here.
    }
}
