/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';
import { NameReference } from '../../../shared/model/name-reference';
import { VehicleReference } from '../../../shared/model/vehicle-reference';

/**
 * The accident-name model
 */
@table('acnmdtl')
export class AccidentName {

    @expand('airbdep', 'descrpt')
    public airBagDeployment: string;

    @field('altstrs')
    public alcoholTestResults: string;

    @field('altstst')
    public alcoholTestStatus: string;

    @field('altsttp')
    public alcoholTestType: string;

    @field('comment')
    public comments: string;

    @expand('comvend', 'desc')
    public commercialEndorsement: string;

    @field('dlatyp')
    public dlApplicableType: string;

    @expand('dlrest1', 'desc')
    public dlRestriction1: string;

    @expand('dlrest2', 'desc')
    public dlRestriction2: string;

    @expand('dlrest3', 'desc')
    public dlRestriction3: string;

    public dlRestrictions: string[];

    @field('dlstat')
    public dlStatus: string;

    @expand('drvact1', 'desc')
    public driverAction1: string;

    @expand('drvact2', 'desc')
    public driverAction2: string;

    @expand('drvact3', 'desc')
    public driverAction3: string;

    @expand('drvact4', 'desc')
    public driverAction4: string;

    public driverActions: string[];

    @expand('distby', 'descrpt')
    public driverDistraction: string;

    @field('drtstst')
    public drugTestStatus: string;

    @field('drtsttp')
    public drugTestType: string;

    @expand('dtestr1', 'descrpt')
    public drugTestResult1: string;

    @expand('dtestr2', 'descrpt')
    public drugTestResult2: string;

    @expand('dtestr3', 'descrpt')
    public drugTestResult3: string;

    @expand('dtestr4', 'descrpt')
    public drugTestResult4: string;

    public drugTestResults: string[];

    @expand('ejectn', 'descrpt')
    public ejection: string;

    @field('tranems')
    public emsId: string;

    @field('tranern')
    public emsRunNumber: string;

    @expand('injarea', 'desc')
    public injuryArea: string;

    @expand('injdesc', 'desc')
    public injuryDescription: string;

    @expand('tranmed', NameReference)
    public medicalFacility: NameReference | string;

    @expand('name', NameReference)
    public name: NameReference | string;

    @expand('nmactp', 'desc')
    public nonMotoristActionPriorToCrash: string;

    @expand('nmactd1', 'desc')
    public nonMotoristActionAtTimeOfCrash1: string;

    @expand('nmactd2', 'desc')
    public nonMotoristActionAtTimeOfCrash2: string;

    public nonMotoristActionsAtTimeOfCrash: string[];

    @expand('nmloc', 'desc')
    public nonMotoristLocation: string;

    @expand('nmsafe1', 'descrpt')
    public nonMotoristSafetyEquipment1: string;

    @expand('nmsafe2', 'descrpt')
    public nonMotoristSafetyEquipment2: string;

    public nonMotoristSafetyEquipment: string[];

    @expand('pcond', 'descrpt')
    public personCondition: string;

    @expand('ptype', 'descrpt')
    public personType: string;

    @expand('positin', 'desc')
    public position: string;

    @field('vhrelat')
    public relatedVehicleId: string;

    public relatedVehicle: VehicleReference | string;

    @field('ident')
    public relationshipId: string;

    @expand('safety', 'desc')
    public safetyEquipment: string;

    @expand('seat', 'descrpt')
    public seat: string;

    @expand('severe', 'desc')
    public severity: string;

    @expand('tranby', 'descrpt')
    public transportedBy: string;

    @expand('citev1', 'desc')
    public violationCode1: string;

    @expand('citev2', 'desc')
    public violationCode2: string;

    @expand('citev3', 'desc')
    public violationCode3: string;

    @expand('citev4', 'desc')
    public violationCode4: string;

    public violationCodes: string[];
}
