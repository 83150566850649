/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import LocationAdapter from '../../shared/mapping/LocationAdapter';
import AggregatorUnit from '../../schema/AggregatorUnit';
import Location from '../../shared/mapping/Location';

/**
 * A LocationAdapter that obtains the location from a CAD Unit.
 */
export default class UnitLocationAdapter implements LocationAdapter<AggregatorUnit> {

    // @Override
    public getLocation(unit: AggregatorUnit): Location {
        if (!unit) {
            throw Error('The unit cannot be null or undefined.');
        }

        let location: Location = {};
        let geoCoordinates = unit.geoCoordinates;

        if (geoCoordinates) {
            let latitude = geoCoordinates.latitude;
            if (angular.isNumber(latitude) && isFinite(latitude)) {
                location.latitude = latitude;
            }

            let longitude = geoCoordinates.longitude;
            if (angular.isNumber(longitude) && isFinite(longitude)) {
                location.longitude = longitude;
            }
        }
        return location;
    }
}
