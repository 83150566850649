/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * The circumstance model
 */
@table('jliccirc')
export class Circumstance {

    @expand('crccode', 'desc')
    public description: string;

    @field('commnt')
    public comment: string;
}
