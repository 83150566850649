/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { OpaqueToken } from '@angular/core';
import { ExpandNodeRequestCreationVisitor } from './expand-node-request-creation-visitor';
import { FieldNodeRequestCreationVisitor } from './field-node-request-creation-visitor';
import { IncludeNodeRequestCreationVisitor } from './include-node-request-creation-visitor';
import { TableNodeRequestCreationVisitor } from './table-node-request-creation-visitor';
import { InvolvementsNodeRequestCreationVisitor } from './involvements-node-request-creation-visitor';

/**
 * A token that allows us to bundle up all of the type-specific visitors pertaining to request creation.
 * The visitors can then be injected into another class as an array instead of one-by-one.
 */
export const REQUEST_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN = new OpaqueToken('RequestCreationTypeSpecificVisitors');

/**
 * An array that contains all providers within the "request-creation/type-specific-visitors" folder.
 */
export const REQUEST_CREATION_TYPE_SPECIFIC_VISITOR_PROVIDERS: any[] = [
    { provide: REQUEST_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: ExpandNodeRequestCreationVisitor, multi: true },
    { provide: REQUEST_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: FieldNodeRequestCreationVisitor, multi: true },
    { provide: REQUEST_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: IncludeNodeRequestCreationVisitor, multi: true },
    { provide: REQUEST_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: TableNodeRequestCreationVisitor, multi: true },
    { provide: REQUEST_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: InvolvementsNodeRequestCreationVisitor, multi: true }
];
