/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from './authentication.service';
import { LogoutService } from './logout.service';

/**
 * A class which handles re-authenticating the user with the Spillman server.
 */
export abstract class ReAuthenticationService<T> {

    /**
     * Constructs a new instance of the ReAuthenticationService class.
     *
     * @param authenticationService The service that handles authenticating the user with the Spillman server.
     * @param logoutService The service which handles logging the user out of the app.
     */
    constructor(
        private authenticationService: AuthenticationService<T>,
        private logoutService: LogoutService
    ) {
    }

    /**
     * Re-authenticates the user and sets the default security http headers for angular 1 and 2.
     */
    public reAuthenticate(): Observable<any> {
        return this.authenticationService.authenticate(this.getAuthenticationParameter())
            .catch(() => Observable.throw(this.logoutService.logout()));
    }

    /**
     * Gets the authentication parameter.
     *
     * @returns The authentication parameter.
     */
    protected abstract getAuthenticationParameter(): T;
}
