/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IRootScopeService = angular.IRootScopeService;
import { DurationInputObject } from 'moment';
import SettingKey from '../SettingKey';
import Setting from '../Setting';
import SettingStorage from '../SettingStorage';
import Option from '../Option';

/**
 * The setting that determines how far back we go in order to get the user's
 * incidents when he/she clicks on the "My Incidents" button.
 */
export default class MyIncidentsDurationSetting extends Setting<DurationInputObject> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$q', 'settingStorage', '$rootScope'];

    /**
     * Constructs a new instance of the MyIncidentsDurationSetting class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param settingStorage The object that handles persistent storage of the setting value.
     * @param $rootScope The Angular $rootScope that can be used to broadcast events throughout the app.
     */
    constructor($q: IQService, settingStorage: SettingStorage, $rootScope: IRootScopeService) {
        super($q, settingStorage, $rootScope, SettingKey.myIncidentsDurationSetting, 'My Incidents Duration', [
            new Option<DurationInputObject>('1 Day', { days: 1 }),
            new Option<DurationInputObject>('3 Days', { days: 3 }),

            // TODO: Remove the "cast" when this bug is fixed: https://github.com/moment/moment/issues/3702
            new Option<DurationInputObject>('1 Week', <DurationInputObject>{ weeks: 1 }),
            new Option<DurationInputObject>('2 Weeks', <DurationInputObject>{ weeks: 2 }),
            new Option<DurationInputObject>('3 Weeks', <DurationInputObject>{ weeks: 3 }),
            new Option<DurationInputObject>('1 Month', { months: 1 })
        ], 3);
    }
}
