/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import angular, { IRootScopeService, IScope } from 'angular';

import { AgencyService } from '../../../../services/agency/agency-service';
import Session from '../../../../../../app/login/Session';
import AgencyComponentController from '../../../../filtering/components/baseClasses/agency/agency-component.component';
import { CALL_ACTIONS } from '../../../../filtering/consts';
import CallCustomFilterByAgencies from '../../../../filtering/calls/CallCustomFilterByAgencies';

/**
 * A component of agencies for call filtering
 */
export const callAgencyComponent: angular.IComponentOptions = {
    template: require('./agency-component.component.html'),
    controller:
        class CallsAgencyComponentController extends AgencyComponentController  {
            public static $inject = ['$scope', '$rootScope', 'callCustomFilterByAgencies', 'agencyService', 'session'];

            constructor(
                protected $scope: IScope,
                protected $rootScope: IRootScopeService,
                public callCustomFilterByAgencies: CallCustomFilterByAgencies,
                public agencyService: AgencyService,
                protected session: Session
            ) {
                super($scope, $rootScope, callCustomFilterByAgencies, agencyService, session, CALL_ACTIONS.RETRY_FILTER);
            }
        }
};
