/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import Unit from '../../../schema/Unit';
import DecoratorTransform from '../../../shared/transforms/DecoratorTransform';
import UnitOfficerResourceClass from '../../unitOfficer/UnitOfficerResourceClass';

/**
 * A decorator transform that will add the first assigned officer to the Unit.
 */
export default class UnitFirstOfficerDecoratorTransform implements DecoratorTransform<Unit> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['unitOfficerAPI'];

    /**
     * Constructs a new instance of the UnitFirstOfficerDecoratorTransform class.
     *
     * @param unitOfficerAPI The API for retrieving Unit Officers from the server.
     */
    constructor(private unitOfficerAPI: UnitOfficerResourceClass) {
    }

    // @Override
    public invoke(unit: Unit): void {
        if (unit) {
            unit.firstOfficer = this.unitOfficerAPI.getFirst({filter: `unitno=${unit.unit}`});
        }
    }
}
