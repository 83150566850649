/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { provideBasedOnCondition } from '../conditional-injection/provide-based-on-condition';
import { AuthenticationTokenBuilder } from './authentication/authentication-token-builder';
import { BasicAuthenticationTokenBuilder } from './authentication/basic-authentication-token-builder.service';
import { OauthAuthenticationTokenBuilder } from './authentication/oauth-authentication-token-builder.service';
import { CookieCleaner } from './cookie-cleanup/cookie-cleaner';
import { BrowserBasicAuthenticationCookieCleaner } from './cookie-cleanup/browser-basic-authentication-cookie-cleaner.service';
import { CordovaBasicAuthenticationCookieCleaner } from './cookie-cleanup/cordova-basic-authentication-cookie-cleaner.service';
import { OauthAuthenticationCookieCleaner } from './cookie-cleanup/oauth-authentication-cookie-cleaner.service';
import { CookieMasterWrapper } from './cookie-master-wrapper';
import { SystemUseModule } from './system-use';
import { SessionTimeoutModule } from './session-timeout';

@NgModule({
    imports: [
        SessionTimeoutModule,
        SystemUseModule
    ],
    providers: [
        CookieMasterWrapper,
        provideBasedOnCondition(
            AuthenticationTokenBuilder,
            BasicAuthenticationTokenBuilder,
            OauthAuthenticationTokenBuilder
        ),
        provideBasedOnCondition(
            CookieCleaner,
            BrowserBasicAuthenticationCookieCleaner,
            CordovaBasicAuthenticationCookieCleaner,
            OauthAuthenticationCookieCleaner
        )
    ]
})
export class LoginModule { }
