/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import CallFilter from '../../cad/calls/filtering/CallFilter';
import Option from '../Option';
import FiltersStorage from '../../cad/filtering/storage/FiltersStorage';
import FilterScreenController from '../../../app/cad/calls/components/filter-screen/FilterScreenController';
import { getBoolean } from '../../../app/api/analytics/firebase-remote-config-service';
import { logError } from '../../../app/api/analytics/firebase-crashlytics-service';
import { logEvent } from '../../api/analytics/firebase-analytics-service';
import { callAnalyticsEvents } from '../../cad/filtering/analytics/events';
import { NEW_FILTER_TITLE } from '../../cad/filtering/consts';

/**
 * A class that handles call filter versioning according to remote config feature toggle.
 */
export default class CallFilterVersion {

    public static $inject = ['callFiltersStorage', '$state'];

    private ADVANCED_CALL_FILTER_FEATURE_TOGGLE = 'advanced_call_filter_feature_toggle';

    /**
     * Constructs a new instance of the CallFilterVersion class.
     *
     * @param filtersStorage The object that handles storing filter specific data in local storage.
     * @param $state The object that handles this class state.
     */
    constructor(
        private filtersStorage: FiltersStorage,
        public $state: angular.ui.IStateService
    ) { }

    private newFilterOptions = [
        new Option('All', CallFilter.filterNone),
        new Option(NEW_FILTER_TITLE, CallFilter.newFilter, { requiresUnit: true }, () => {
            this.openFilterScreen();
        })
    ];

    private oldFilterOptions = [
        new Option('All', CallFilter.filterNone),
        new Option('My Calls', CallFilter.callFilterByUnit, { requiresUnit: true }),
        new Option('Agency', CallFilter.callFilterByAgency),
        new Option('Zone', CallFilter.callFilterByZone, { requiresUnit: true }),
        new Option('Type', CallFilter.callFilterByType, { requiresUnit: true })
    ];

    public getNewFilterOptions = this.newFilterOptions;

    /**
     * Gets the feature flag for unit filter toggle and decides which options to use.
     *
     * @return list of options to be shown in filter dropdown of unit screen.
     */
    public getFilterOptions = (): Promise<Option<CallFilter>[]> => {
        return getBoolean(this.ADVANCED_CALL_FILTER_FEATURE_TOGGLE).then((newFilterFeatureToggle: any) => {
            if (newFilterFeatureToggle) {
                return this.newFilterOptions;
            } else {
                return this.oldFilterOptions;
            }
        }).catch((error: any) => {
            logError(error);
            return this.oldFilterOptions;
        });
    };

    private openFilterScreen = () => {
        this.filtersStorage.clearEditingFilter();
        const routeName = FilterScreenController.routeName;
        logEvent(callAnalyticsEvents.open_filter_screen);
        this.$state.go(routeName);
    };
}
