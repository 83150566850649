/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { UrlFactory } from '../../../../authentication';
import { FileMetadata } from '../../../../schema/FileMetadata';

/**
 * Downloads a file from the server.
 */
export abstract class FileDownloader {

    /**
     * Constructs a new instance of the FileDownloader class.
     *
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     */
    constructor(private urlFactory: UrlFactory) {
    }

    /**
     * Downloads the specified file.
     *
     * @param file The file to download.
     */
    public download(file: FileMetadata): void {
        const fileUrl = this.urlFactory.create({
            path: `/files/${file.number}`
        });

        this.downloadFromUrl(fileUrl, file);
    }

    /**
     * Downloads the file from the specified url.
     *
     * @param url The url from which to retrieve the file.
     * @param file The file to download.
     */
    protected abstract downloadFromUrl(url: string, file: FileMetadata): void;
}
