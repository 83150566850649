/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../../shared/database/decorators';

/**
 * A statute.
 */
@table('lwstatut')
export class Statute {

    @expand('statute', 'desc')
    public description: string;
}
