/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as moment from 'moment';
import DecoratorTransform from './DecoratorTransform';

/**
 * A transform that parses string properties of the model and converts each one to a Date.
 */
export default class DateTransform<T> implements DecoratorTransform<T> {

    /**
     * Constructs a new instance of the DateParserTransform class.
     *
     * @param serverDateTimeFormat The format in which dates are sent from the server.
     * @param propertiesToParse An array of property names that specify which properties to parse to Dates.
     */
    constructor(private serverDateTimeFormat: string, private propertiesToParse: string[]) {
    }

    // @Override
    public invoke(model: T): void {
        this.propertiesToParse.forEach(p => this.convertToDate(model, p));
    }

    /**
     * Converts the specified property to a Date if it is currently a string.
     *
     * @param model The model whose property may be converted to a Date.
     * @param propertyName The name of the property to convert to a Date.
     */
    private convertToDate(model: T, propertyName: string): void {
        let originalValue = model[propertyName];
        if (typeof originalValue === 'string') {
            model[propertyName] = moment(originalValue, this.serverDateTimeFormat).toDate();
        }
    }
}
