/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, includeMany } from '../../../shared/database/decorators';
import { RadioLog } from './radio-log';

/**
 * The CAD Call model associated with radio logs.
 */
@table('cdcall')
export class CadCall {

    @field('number')
    public id: string;

    @includeMany('rlmain', RadioLog)
    public radioLogs: RadioLog[];
}
