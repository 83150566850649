/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { EvidenceLocalId } from './evidence-local-id';
import { NameReference } from '../../shared/model/name-reference';
import { PropertyReference } from '../../shared/model/property-reference';
import { VehicleReference } from '../../shared/model/vehicle-reference';
import { IncidentReference } from '../../shared/model/incident-reference';
import { Involvement } from '../../../schema/Involvement';

/**
 * The evidence model
 */
@table('evmain')
export class Evidence {

    @field('agency')
    public agency: string;

    @includeOne('evmdesc', 'misc')
    public comments: string;

    @expand('cust', 'fullnam')
    public custodian: string;

    @field('desc')
    public description: string;

    @expand('type', 'desc')
    public evidenceType: string;

    @field('number')
    public id: string;

    @expand('incino', IncidentReference)
    public incident: IncidentReference | string;

    @field('seq')
    public itemNumber: number;

    @includeMany('evlocal', EvidenceLocalId)
    public localIds: EvidenceLocalId[];

    @expand('lctn', 'desc')
    public location: string;

    @expand('pcolor1', 'desc')
    public propertyColor1: string;

    @expand('pcolor2', 'desc')
    public propertyColor2: string;

    @field('brand')
    public propertyBrand: string;

    @field('item')
    public propertyItem: string;

    @expand('meas', 'desc')
    public propertyMeasure: string;

    @field('pmodel')
    public propertyModel: string;

    @field('quant')
    public propertyQuantity: number;

    @field('serial')
    public propertySerialNumber: string;

    @field('reason')
    public reason: string;

    @expand('propno', PropertyReference)
    public relatedProperty: PropertyReference | string;

    @expand('vehino', VehicleReference)
    public relatedVehicle: VehicleReference | string;

    @field('viewdt')
    public reviewDate: Date;

    @expand('status', 'desc')
    public status: string;

    @field('statdt')
    public statusDate: Date;

    @expand('trans', 'desc')
    public transaction: string;

    @field('tdate')
    public transactionDate: Date;

    @expand('vcolor1', 'desc')
    public vehicleColor1: string;

    @expand('vcolor2', 'desc')
    public vehicleColor2: string;

    @expand('make', 'desc')
    public vehicleMake: string;

    @field('vmodel')
    public vehicleModel: string;

    @expand('owner', NameReference)
    public vehicleOwner: NameReference | string;

    @field('year')
    public vehicleYear: number;

    @expand('officer', 'fullnam')
    public whoFromTo: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
