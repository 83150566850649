/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { PropertyNode } from './property-node';
import { ClassNode } from '../class/class-node';
import { ClassNodeFactory } from '../class/class-node-factory';
import { TreeNode } from '../../../../data-structures/tree-node';
import { Constructor } from '../../../../interfaces/constructor';

/**
 * A node that associates an array of included database objects with a property of a JavaScript class.
 * This node pertains when you have a one-to-many relationship between database tables.
 */
export class IncludeManyNode extends PropertyNode {

    /**
     * Constructs a new instance of the IncludeManyNode class.
     *
     * @param parent The parent ClassNode.
     * @param propertyKey The property key (or name if you prefer).
     * @param table The database table to include.
     * @param childConstructor The constructor of the child model. This will be passed onto the child ClassNode.
     *                         This is needed because of a limitation with TypeScript and the Reflect Metadata API.
     *                         The type of the array isn't stored in the metadata -- only the fact that it's an array.
     * @param nodeFactory A factory that knows how to create ClassNodes.
     */
    constructor(parent: ClassNode, propertyKey: string | symbol, public table: string, public childConstructor: Constructor<any>, private nodeFactory: ClassNodeFactory) {
        super(parent, propertyKey);
    }

    /**
     * @inheritdoc
     */
    protected *initializeChildren(): Iterable<TreeNode> {
        const propertyType = Reflect.getMetadata('design:type', this.parent.classConstructor.prototype, this.propertyKey);

        if (propertyType !== Array) {
            throw new Error(`The ${this.parent.classConstructor.name}.${this.propertyKey as string} property must be an array since the @includeMany decorator was used`);
        }

        yield this.nodeFactory.create(this.childConstructor);
    }
}
