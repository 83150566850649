/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * A record of a change of custoday.
 */
@table('prcrec')
export class CustodyChange {

    @field('when')
    public date: Date;

    @expand('whom', 'fullnam')
    public toWhom: string;

    @expand('custid', 'fullnam')
    public custodian: string;

    @field('reason')
    public reason: string;
}
