/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../../shared/database/decorators';

/**
 * The fire incident responder model
 */
@table('frrespo')
export class Responder {

    @expand('respond', 'fullnam')
    public name: string;
}
