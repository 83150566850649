/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, includeMany, expand, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { Alert } from '../../name/detail/model/alert';
import { NameLocalId } from '../../name/detail/model/name-local-id';
import { NameReference } from '../../shared/model/name-reference';
import { Contact } from './contact';
import { Involvement } from '../../../schema/Involvement';

/**
 * The business model
 */
@table('nmmain')
export class Business {

    @includeMany('nmalert', Alert)
    public alertCodes: Alert[];

    @expand('aka', NameReference)
    public alias: NameReference | string;

    @field('city')
    public city: string;

    @includeMany('nmcontct', Contact)
    public contacts: Contact[];

    @field('first')
    public first: string;

    @field('number')
    public id: string;

    @field('phone')
    public homePhone: string;

    @field('last')
    public last: string;

    @includeMany('nmlocal', NameLocalId)
    public localId: NameLocalId[];

    @field('middle')
    public middle: string;

    public name: NameReference;

    @field('wrkphn')
    public workPhone: string;

    @field('state')
    public state: string;

    @field('street')
    public street: string;

    @expand('nametyp', 'desc')
    public type: string;

    @field('zip')
    public zip: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
