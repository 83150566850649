/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { AddressAlertsBatchRequest } from './address-alerts/address-alerts-batch-request.service';
import { AddressAlertsRequestCreator } from './address-alerts/address-alerts-request-creator.service';
import { InvolvementAlertsBatchRequest } from './involvement/involvement-alerts-batch-request.service';
import { InvolvementAlertsRequestCreator } from './involvement/involvement-alerts-request-creator.service';
import { ResponseParser } from './response-parser.service';

@NgModule({
    providers: [
        AddressAlertsBatchRequest,
        AddressAlertsRequestCreator,
        InvolvementAlertsBatchRequest,
        InvolvementAlertsRequestCreator,
        ResponseParser
    ]
})
export class ApiModule { }
