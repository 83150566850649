/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import IInjectorService = angular.auto.IInjectorService;
import IRootScopeService = angular.IRootScopeService;
import { sharedModule } from '../shared/shared.module.ng1';
import SettingStorage from './SettingStorage';
import SettingsRepository from './SettingsRepository';
import SettingsController from './SettingsController';
import CallFilterSetting from './implementations/CallFilterSetting';
import UnitFilterSetting from './implementations/UnitFilterSetting';
import UnitFilterVersion from './implementations/UnitFilterVersion';
import MyIncidentsDurationSetting from './implementations/MyIncidentsDurationSetting';
import ThemeSetting from './implementations/ThemeSetting';
import FontSizeSetting from './implementations/FontSizeSetting';
import RequiresUnitFilter from './RequiresUnitFilter';
import RequiresUnitSettingAdjuster from './RequiresUnitSettingAdjuster';
import settingModelDirective from './directives/SettingModelDirective';
import Setting from './Setting';
import SettingKey from './SettingKey';
import { LoggingLevelSetting } from './implementations/logging-level-setting';
import { LoggerConfig } from '../logging';
import { upgradeAdapter } from '../upgrade-adapter';
import { LOGGED_IN_EVENT } from '../login/authentication.service';
import CommentRowsCountSetting from './implementations/CommentRowsCountSetting';
import CallFilterVersion from './implementations/CallFilterVersion';

export const settingsModule = angular.module('settings', [sharedModule.name])

    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider.state('app.settings', {
            url: '/settings',
            views: {
                other: {
                    template: require('./settings.html'),
                    controller: 'settingsController as vm'
                }
            }
        });
    }])

    .service('settingStorage', SettingStorage)
    .service('settingsRepository', SettingsRepository)
    .controller('settingsController', SettingsController)

    /* The services below are concrete implementations of the Setting class. As new settings are added,
     * they will need to be added below so that the SettingsRepository will know about them.
     *
     * IMPORTANT: the settings' keys must also be added to the SettingKey enum.
     */
    .service('callFilterSetting', CallFilterSetting)
    .service('callFilterVersion', CallFilterVersion)
    .service('unitFilterSetting', UnitFilterSetting)
    .service('unitFilterVersion', UnitFilterVersion)
    .service('loggingLevelSetting', LoggingLevelSetting)
    .service('myIncidentsDurationSetting', MyIncidentsDurationSetting)
    .service('themeSetting', ThemeSetting)
    .service('fontSizeSetting', FontSizeSetting)
    .service('commentRowsCountSetting', CommentRowsCountSetting)
    .serviceAsFilter('requiresUnitFilter', RequiresUnitFilter, 'filter')
    .service('requiresUnitSettingAdjuster', RequiresUnitSettingAdjuster)
    .factory('loggerConfig', upgradeAdapter.downgradeNg2Provider(LoggerConfig))

    // Directives
    .directive('sdsSettingModel', settingModelDirective)

    .run(['$injector', '$rootScope', ($injector: IInjectorService, $rootScope: IRootScopeService) => {
        function updateLoggerConfig() {
            // We have to manually inject the loggerConfig and loggingLevelSetting sometime after the application has started running.
            // This is to work around the following bug with the UpgradeAdapter: https://github.com/angular/angular/issues/10992
            const loggerConfig = $injector.get<LoggerConfig>('loggerConfig');
            const loggingLevelSetting = $injector.get<LoggingLevelSetting>('loggingLevelSetting');

            loggerConfig.enabledLoggingLevel = loggingLevelSetting.value;
        };

        // Update the LoggerConfig when the user logs in as well as whenever the setting changes.
        $rootScope.$on(LOGGED_IN_EVENT, updateLoggerConfig);
        $rootScope.$on(`${Setting.SETTING_CHANGED}:${SettingKey.loggingLevelSetting}`, updateLoggerConfig);
    }]);
