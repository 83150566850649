/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';

/**
 * A citation circumstance.
 */
@table('ctccirc')
export class Circumstance {

    @expand('ccode', 'desc')
    public description: string;
}
