/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Formatter } from './formatters';
import { LoggingLevel } from './logging-level';
import { LoggerConfig } from './logger-config.service';
/**
 * An abstract logger class where derived classes decide where the log is written to.
 *
 * Compare this interface with the `Console` interface in the lib.dom.d.ts file.
 * You can see that this interface is a stripped-down version of that one.
 */
export abstract class Logger {

    /**
     * Constructs a new instance of the Logger class.
     *
     * @param config The configuration that determines which logging levels will actually be logged.
     * @param formatters The array of formatters that will convert the message to a human-friendly string.
     */
    constructor(private config: LoggerConfig, private formatters: Formatter<any>[]) {
    }

    /**
     * Writes a message to the log with a level of 'Error'.
     *
     * @param message The message to log.
     */
    public error(message: any): any {
        this.formatAndLog(LoggingLevel.Error, message);
    }

    /**
     * Writes a message to the log with a level of 'Warn'.
     *
     * @param message The message to log.
     */
    public warn(message: any): void {
        this.formatAndLog(LoggingLevel.Warn, message);
    }

    /**
     * Writes a message to the log with a level of 'Info'.
     *
     * @param message The message to log.
     */
    public info(message: any): void {
        this.formatAndLog(LoggingLevel.Info, message);
    }

    /**
     * Writes a message to the log with a level of 'Debug'.
     *
     * @param message The message to log.
     */
    public debug(message: any): void {
        this.formatAndLog(LoggingLevel.Debug, message);
    }

    /**
     * Writes a message to the log with a level of 'Trace'.
     *
     * @param message The message to log.
     */
    public trace(message: any): void {
        this.formatAndLog(LoggingLevel.Trace, message);
    }

    /**
     * Actually writes the message to the log.
     *
     * @param level The log level as a string.
     * @param message The message to log.
     */
    protected abstract performLogging(level: string, message: string): void;

    /**
     * Formats and then logs the message.
     *
     * @param level The log level.
     * @param message The message to log.
     */
    private formatAndLog(level: LoggingLevel, message: any): void {
        if (this.config.enabledLoggingLevel !== undefined && level <= this.config.enabledLoggingLevel) {
            const formattedMessage = this.format(message);
            this.performLogging(LoggingLevel[level].toLowerCase(), formattedMessage);
        }
    }

    /**
     * Formats the message as a human-readable string.
     *
     * @param message The unformatted message.
     * @returns The human-readable string.
     */
    private format(message: any): string {
        for (let formatter of this.formatters) {
            if (formatter.canFormat(message)) {
                return formatter.format(message);
            }
        }

        // This will never be hit unless the default formatter is missing or broken.
        throw new Error('The default formatter is either missing or broken.');
    }
}
