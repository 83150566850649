/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';

import { provideBasedOnCondition } from '../../conditional-injection/provide-based-on-condition';
import { SessionTimeout } from './session-timeout';
import { SessionTimeoutService } from './session-timeout.service';
import { SessionTimeoutDefaultService } from './session-timeout-default.service';

@NgModule({
    providers: [
        provideBasedOnCondition(
            SessionTimeout,
            SessionTimeoutDefaultService,
            SessionTimeoutService
        )
    ]
})
export class SessionTimeoutModule { }
