/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, includeOne, involvements, involvementsAlerts } from '../../../../shared/database/decorators';
import { NameReference } from '../../../shared/model/name-reference';
import { VehicleLocalId } from './vehicle-local-id';
import { GarageAddress } from './garage-address';
import { FrequentAddress } from './frequent-address';
import Involvement from '../../../../schema/Involvement';
import { TypedAddress } from './typed-address';
import { IncidentReference } from '../../../shared/model/incident-reference';
import { Characteristic } from './characteristic';

/**
 * The vehicle model
 */
@table('vhmain')
export class Vehicle {

    /**
     * The combined array of garage + frequent addresses.
     */
    public addresses: TypedAddress[];

    @field('agency')
    public agency: string;

    @field('amtrecv')
    public amountRecovered: number;

    @expand('area', 'desc')
    public area: string;

    @includeMany('vhmchar', Characteristic)
    public characteristics: Characteristic[];

    @expand('color1', 'desc')
    public color1: string;

    @expand('color2', 'desc')
    public color2: string;

    @includeOne('vhmdesc', 'comment')
    public comments: string;

    @field('rcdate')
    public dateRecovered: Date;

    @field('rldate')
    public dateReleased: Date;

    @field('doors')
    public doors: number;

    @field('expire')
    public expires: Date;

    @includeMany('vhfreloc', FrequentAddress)
    public frequentAddresses: FrequentAddress[];

    @includeOne('vhaddr')
    public garageAddress: GarageAddress;

    @expand('status', 'desc')
    public ibrUcrStatus: string;

    @expand('lptype', 'desc')
    public licenseType: string;

    @includeMany('vhlocal', VehicleLocalId)
    public localIds: VehicleLocalId[];

    @expand('lstatus', 'desc')
    public localStatus: string;

    @expand('make', 'desc')
    public make: string;

    @field('model')
    public model: string;

    @expand('officer', 'fullnam')
    public officer: string;

    @expand('ownerid', NameReference)
    public owner: NameReference | string;

    @field('lpnum')
    public plate: string;

    @expand('lpst', 'name')
    public state: string;

    @field('stdate')
    public statusDate: Date;

    @field('storloc')
    public storageLocation: string;

    @expand('incnumb', IncidentReference)
    public ucrIncident: IncidentReference | string;

    @field('value')
    public value: number;

    @expand('vehityp', 'desc')
    public vehicleType: string;

    @field('vin')
    public vin: string;

    @expand('wrecker', 'desc')
    public wreckerService: string;

    @field('year')
    public year: number;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
