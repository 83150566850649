/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand, field } from '../../../shared/database/decorators';

/**
 * An offender tracking activity.
 */
@table('soactiv')
export class Activity {

    @field('actdate')
    public date: Date;

    @expand('actuser', 'fullnam')
    public enteredBy: string;

    @expand('actype', 'descrpt')
    public action: string;

    @field('text')
    public notes: string;
}
