/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';

/**
 * Other cause.
 */
@table('accause')
export class OtherCause {

    @expand('cause', 'desc')
    public cause: string;
}
