/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import BaseRegeneratable from '../login/regeneratable/BaseRegeneratable';
import IRootScopeService = angular.IRootScopeService;
import PersistentWebSocket from '../persistentWebSocket/PersistentWebSocket';
import PersistentWebSocketFactory from '../persistentWebSocket/PersistentWebSocketFactory';
import { UrlFactory } from '../authentication';
import IQService = angular.IQService;

const CAD_CONNECTED = 'cadConnected';

/**
 * A regeneratable service that creates/destroys a connection to the Mobile aggregator.
 */
class CadConnection extends BaseRegeneratable {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        '$q',
        '$rootScope',
        'persistentWebSocketFactory',
        'urlFactory'
    ];

    /**
     * The current WebSocket (if connected).
     */
    private _socket: PersistentWebSocket;

    /**
     * Constructs a new instance of the CadPersistentWebSocketFactory class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param $rootScope The Angular $rootScope that can be used to listen to application events.
     * @param persistentWebSocketFactory
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     */
    constructor($q: IQService,
        private $rootScope: IRootScopeService,
        private persistentWebSocketFactory: PersistentWebSocketFactory,
        private urlFactory: UrlFactory) {
        super($q);

        // This makes it possible to know on any screen whether the CAD WebSocket is connected or not.
        // Note that before the WebSocket is initiated `cadConnected` will be undefined, which is falsy, just like we want.
        this.$rootScope.$watch(() => !!this._socket && this._socket.isOpen, (connected: boolean) => this.$rootScope[CAD_CONNECTED] = connected);
    }

    /**
     * Gets the PersistentWebSocket that's connected to the Mobile aggregator.
     *
     * @returns {PersistentWebSocket} The PersistentWebSocket that's connected to the Mobile aggregator.
     */
    public get socket(): PersistentWebSocket {
        return this._socket;
    }

    // @Override
    protected performInitialization(): void {
        let url = this.urlFactory.create({
            protocol: 'ws',
            baseApiUrl: 'Mobile',
            path: '/aggregatorWebSocket'
        });
        this._socket = this.persistentWebSocketFactory.create({ url: url });
        this._socket.connect();
    }

    // @Override
    protected performDestruction(): void {
        this._socket.disconnect();
        this._socket = undefined;
    }
}

export default CadConnection;
