/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { JailArrestDetailComponent } from './arrest/jail-arrest-detail.component';
import { JailBookingDetailComponent } from './booking/jail-booking-detail.component';
import { JailIncidentDetailComponent } from './incident/jail-incident-detail.component';
import { JailOffenseDetailComponent } from './offense/jail-offense-detail.component';

@NgModule({
    imports: [SharedModule],
    declarations: [
        JailArrestDetailComponent,
        JailBookingDetailComponent,
        JailIncidentDetailComponent,
        JailOffenseDetailComponent
    ]
})
export class JailModule { }
