/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import Agency from '../../../../schema/Agency';
import UnitQuery from './UnitQuery';

/**
 * Requests all data about the Agency to which the unit belongs.
 */
export default class AgencyUnitQuery extends UnitQuery<Agency> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['agencyAPI'];

    /**
     * Constructs a new instance of the AgencyQuery class.
     *
     * @param agencyAPI The API for retrieving Agencies from the server.
     */
    constructor(private agencyAPI: IResourceClass<Agency>) {
        super();
    }

    // @Override
    protected get watchPropertyNames(): string | string[] {
        return 'agency';
    }

    // @Override
    protected get updatePropertyName(): string {
        return 'fullAgency';
    }

    // @Override
    protected get defaultValue(): Agency {
        return undefined;
    }

    // @Override
    protected performQuery(newValues: any[]): Agency {
        return this.agencyAPI.get({ id: newValues[0] });
    }
}
