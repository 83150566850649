/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

// Modules
import { SharedModule } from './shared/shared.module';
import { AccidentModule } from './accident/accident.module';
import { IncidentModule } from './incident/incident.module';
import { JailModule } from './jail/jail.module';
import { NameModule } from './name/name.module';
import { PropertyModule } from './property/property.module';
import { VehicleModule } from './vehicle/vehicle.module';
import { CallModule } from './call/call.module';

// Components
import { OffenderTrackingModule } from './offender-tracking/offender-tracking.module';
import { BusinessDetailComponent } from './business/business-detail.component';
import { CaseFileDetailComponent } from './case-file/case-file-detail.component';
import { CitationDetailComponent } from './citation/citation-detail.component';
import { CivilProcessDetailComponent } from './civil-process/civil-process-detail.component';
import { DemographicDetailComponent } from './demographic/demographic-detail.component';
import { DisseminationDetailComponent } from './dissemination/dissemination-detail.component';
import { EvidenceDetailComponent } from './evidence/evidence-detail.component';
import { FieldInterviewDetailComponent } from './field-interview/field-interview-detail.component';
import { FireFieldInterviewDetailComponent } from './fire-field-interview/fire-field-interview-detail.component';
import { LicensePermitDetailComponent } from './license-permit/license-permit-detail.component';
import { MiscellaneousDetailComponent } from './miscellaneous/miscellaneous-detail.component';
import { NameAssociationDetailComponent } from './name-association/name-association-detail.component';
import { PawnActivityDetailComponent } from './pawn-activity/pawn-activity-detail.component';
import { PawnedPropertyDetailComponent } from './pawned-property/pawned-property-detail.component';
import { PremiseDetailComponent } from './premise/premise-detail.component';
import { SuspectNameDetailComponent } from './suspect-name/suspect-name-detail.component';
import { SuspectVehicleDetailComponent } from './suspect-vehicle/suspect-vehicle-detail.component';
import { TrafficStopDetailComponent } from './traffic-stop/traffic-stop-detail.component';
import { TrafficWarningDetailComponent } from './traffic-warning/traffic-warning-detail.component';
import { VehicleImpoundDetailComponent } from './vehicle-impound/vehicle-impound-detail.component';
import { WantDetailComponent } from './want/want-detail.component';

@NgModule({
    imports: [
        SharedModule,
        AccidentModule,
        CallModule,
        IncidentModule,
        JailModule,
        NameModule,
        OffenderTrackingModule,
        PropertyModule,
        VehicleModule
    ],
    declarations: [
        BusinessDetailComponent,
        CaseFileDetailComponent,
        CitationDetailComponent,
        CivilProcessDetailComponent,
        DemographicDetailComponent,
        DisseminationDetailComponent,
        EvidenceDetailComponent,
        FieldInterviewDetailComponent,
        FireFieldInterviewDetailComponent,
        LicensePermitDetailComponent,
        MiscellaneousDetailComponent,
        NameAssociationDetailComponent,
        PawnActivityDetailComponent,
        PawnedPropertyDetailComponent,
        PremiseDetailComponent,
        SuspectNameDetailComponent,
        SuspectVehicleDetailComponent,
        TrafficStopDetailComponent,
        TrafficWarningDetailComponent,
        VehicleImpoundDetailComponent,
        WantDetailComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RmsModule { }
