/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IPromise = angular.IPromise;
import IResourceArray = angular.resource.IResourceArray;
import ErrorHandlingController from '../../../errorHandling/ErrorHandlingController';
import Name from '../../../schema/Name';
import MultiQueryResourceClass from '../../../resource/multiQuery/MultiQueryResourceClass';
import NameListStateParams from './NameListStateParams';
import ApplicationScope from '../../../ApplicationScope';
import IonicLoadingService = ionic.loading.IonicLoadingService;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;

/**
 * The controller that displays a list of names based on the search criteria entered by user.
 */
export default class NameListController extends ErrorHandlingController<IResourceArray<Name>> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$scope', '$ionicLoading', '$timeout', 'nameAPI', '$stateParams', '$ionicScrollDelegate'];

    /**
     * The array of names to display.
     */
    public names: IResourceArray<Name>;

    /**
     * Constructs a new instance of the NameListController class.
     *
     * @param $scope The Angular scope object that can listen to and raise events.
     * @param $ionicLoading An overlay that displays a spinner while waiting for data to be loaded.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param nameAPI The service that retrieves names from the server.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     * @param scrollDelegate The delegate that controls the ion-scroll directive.
     */
    constructor($scope: ApplicationScope,
        $ionicLoading: IonicLoadingService,
        $timeout: angular.ITimeoutService,
        private nameAPI: MultiQueryResourceClass<Name>,
        private $stateParams: NameListStateParams,
        private scrollDelegate: IonicScrollDelegate) {
        super($scope, $ionicLoading, $timeout);
    }

    public $onInit = () => {};

    // @Override
    protected performRequest(): IPromise<IResourceArray<Name>> {
        let filter = this.$stateParams.filter;
        let magicPhoneNumber = this.$stateParams.phone;
        return this.nameAPI.multiQuery({magicPhoneNumber: magicPhoneNumber}, {filter: filter}).$promise;
    }

    // @Override
    protected requestComplete(names: IResourceArray<Name>): void {
        this.scrollDelegate.scrollTop();
        this.names = names;
    }
}
