/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Logger } from '../logging';
import IExceptionHandlerService = angular.IExceptionHandlerService;
import IInjectorService = angular.auto.IInjectorService;
import { log, logError } from '../api/analytics/firebase-crashlytics-service';

loggingExceptionHandler.$inject = ['$injector'];

/**
 * A factory that returns a function that will log all uncaught Angular 1 errors.
 *
 * @param $injector The Angular injector service.
 * @returns A function that will log Angular 1 errors.
 */
export function loggingExceptionHandler($injector: IInjectorService): IExceptionHandlerService {
    return (exception: Error, cause) => {
        if (typeof cause !== 'undefined') {
            log(cause);
        }
        if (typeof exception !== 'undefined') {
            if (exception.stack !== undefined) {
                log(String(exception.stack));
            }
            if (exception.name !== undefined) {
                log(String(exception.name));
            }
            if (exception.toString !== undefined) {
                logError(exception.toString());
            } else {
                logError(exception.message);
            }
        }

        const logger = $injector.get<Logger>('logger');
        logger.error(exception);
    };
}
