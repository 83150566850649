/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../shared/database/decorators';

/**
 * The offense code model
 */
@table('jlicoffs')
export class OffenseCode {

    @expand('offcode', 'desc')
    public description: string;
}
