/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { InvolvementsNode } from '../../nodes/property/involvements-node';
import { InvolvementsAlertsNode } from '../../nodes/property/involvements-alerts-node';
import { RequestCreationData } from '../request-creation-data';

/**
 * Performs a "visit" on either an InvolvementsNode during the process of building a request.
 */
@Injectable()
export class InvolvementsNodeRequestCreationVisitor implements TypeSpecificTreeNodeVisitor<InvolvementsNode, RequestCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [InvolvementsNode, InvolvementsAlertsNode];

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: InvolvementsNode, data: RequestCreationData): void {
        if (data.currentPath.length > 0) {
            throw new Error(`The @involvements decorator on the ${node.parent.classConstructor.name}.${node.propertyKey as string} property is not supported since it is not on the top-most model`);
        }

        data.request.getInvolvements = true;
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: InvolvementsNode, _data: RequestCreationData): void {
        // Nothing to do here.
    }
}
