/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { isRemoteConfigAvailable, isOsSupported } from './firebase-validators';
import { logError, log } from './firebase-crashlytics-service';
import { IS_LOGIN_FAILED_ATTEMPTS_VERBOSE } from './remote-config-keys';

let activated = false;

const MINIMUM_FETCH_INTERVAL = 4 * 3600;
const _FEATURE_NOT_AVAILABLE_ERROR = 'Remote Config plugin not available or not fully loaded where it should be';

const fetchAndActivate = async(): Promise<void> => {
    try {
        if (isRemoteConfigAvailable() && !activated) {
            await _fetch();
            await _activate();
        }
    } catch (e) {
        activated = false;
        log('EXCEPTION WHILE FETCHING AND ACTIVATING REMOTE CONFIG');
        logError(e);
    }
};

const getBoolean = async (key: string, defaultValue = false): Promise<boolean> => {
    try {
        if (isRemoteConfigAvailable()) {
            await fetchAndActivate();

            const result = await cordova.plugins.firebase.config.getBoolean(key);
            return result;
        }

        return defaultValue;
    } catch (e) {
        log('ERROR WHILE GETTING BOOLEAN FROM REMOTE CONFIG');
        log('value: ' + key);

        logError(e);
        return defaultValue;
    }
};

const getNumber = async (key: string, defaultValue = 1200): Promise<number> => {
    try {
        if (isRemoteConfigAvailable()) {
            await fetchAndActivate();

            const result = await cordova.plugins.firebase.config.getNumber(key);
            return result;
        }
    } catch (e) {
        log('ERROR WHILE GETTING NUMBER FROM REMOTE CONFIG');
        log('value: ' + key);

        logError(e);
    }

    return defaultValue;
};

const isLoginExceptionVerbose = async (): Promise<boolean> => {
    return getBoolean(IS_LOGIN_FAILED_ATTEMPTS_VERBOSE, true);
};

const _fetch = async (): Promise<void> => {
    if (isRemoteConfigAvailable()) {
        return cordova.plugins.firebase.config.fetch(MINIMUM_FETCH_INTERVAL);
    } else if (isOsSupported()) {
        return Promise.reject(_FEATURE_NOT_AVAILABLE_ERROR);
    }
};

const _activate = async (): Promise<boolean> => {
    if (isRemoteConfigAvailable()) {
        try {
            activated = await cordova.plugins.firebase.config.activate();
            return activated;
        } catch (error){
            logError(error);
            activated = false;
            return false;
        }
    }

    return false;
};

export { getBoolean, getNumber, fetchAndActivate, isLoginExceptionVerbose};
