/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import Vehicle from '../../../schema/Vehicle';
import Involvement from '../../../schema/Involvement';
import DecoratorTransform from '../../../shared/transforms/DecoratorTransform';

/**
 * A decorator transform that will add the involvements to a vehicle.
 */
export default class VehicleInvolvementDecoratorTransform implements DecoratorTransform<Vehicle> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['involvementAPI'];

    /**
     * Constructs a new instance of the VehicleInvolvementDecoratorTransform class.
     *
     * @param involvementAPI The API for retrieving involvements from the server.
     */
    constructor(private involvementAPI: IResourceClass<Involvement>) {
    }

    // @Override
    public invoke(vehicle: Vehicle): void {
        if (vehicle.number) {
            vehicle.involvements = this.involvementAPI.query({
                table: 'vhmain',
                id: vehicle.number
            });
        }
    }
}
