/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import CustomFilterByZones from '../../../cad/filtering/custom-filters/CustomFilterByZones';
import { AgencyModel } from '../agency/agencyModel';

export class ZoneModel implements FilterModel {

    public abbreviation: string;
    public description: string;
    public agency: string;
    public accordionLabel: string;
    public checked: boolean;
    public applied: boolean;
    public storageKey: string = CustomFilterByZones.STORAGE_KEY;
    public id: string;

    constructor({ abbreviation = '', description = '', agency = '', checked = false, applied = false }: any = {}) {
        this.abbreviation = abbreviation;
        this.description = description;
        this.agency = agency;
        this.checked = checked;
        this.accordionLabel = this.getAccordionAgencyLabel();
        this.applied = applied;
        this.id = this.abbreviation;
    }

    public static createModelFromObject = (data: Object): ZoneModel => {
        if (data === undefined) {
            return undefined;
        }

        const tableNames = { abbreviation: 'abbr', description: 'desc', agency: 'agency' };

        const abbreviation = data[tableNames.abbreviation];
        const description = data[tableNames.description];
        const agency = data[tableNames.agency];

        return new ZoneModel({ abbreviation: abbreviation, description: description, agency: agency });
    };

    public static filterZonesBasedOnAgencies = (listOfZones: ZoneModel[], listOfAgencies: AgencyModel[]) => {
        if (listOfAgencies === undefined || listOfAgencies.length === 0) {
            return listOfZones;
        }

        const agencies: string[] = [];
        listOfAgencies.forEach(
            (currentAgency) => agencies.push(currentAgency.id)
        );

        return listOfZones.filter((zone: { agency: any }) => (zone.agency === undefined || zone.agency.length === 0) || agencies.indexOf(zone.agency) !== -1);
    };

    public static orderListByAbbr = (listOfZones: ZoneModel[], userAgency?: string) => {
        return listOfZones.sort(function (a: ZoneModel, b: ZoneModel) {

            // if no agency is present then it should go to back of the list
            if (a.agency !== '' && b.agency === '') {
                return -1;
            } else if (a.agency === '' && b.agency !== '') {
                return 1;
            } else {
                let textA: string;
                let textB: string;

                if (a.agency === b.agency) {
                    // if the same agency, it should be ordered by abbr
                    textA = a.abbreviation.toUpperCase();
                    textB = b.abbreviation.toUpperCase();
                } else {
                    // if different agencies, it should be ordered by agency first then by abbr
                    textA = a.agency.toUpperCase();
                    textB = b.agency.toUpperCase();
                }

                // prioritize user's agency
                if (a.agency === userAgency && b.agency !== userAgency) {
                    return -1; // a comes before b
                } else if (a.agency !== userAgency && b.agency === userAgency) {
                    return 1; // a comes after b
                }

                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }
        });
    };

    public getAccordionAgencyLabel = () => {
        return `${this.abbreviation} - ${this.agency}`;
    };

    public verifyAndApplyUserLabel(userLabel: string): boolean {

        const isUserData = this.abbreviation === userLabel;

        if (isUserData) {
            this.accordionLabel += ' (My Zone)';
        }

        return isUserData;
    }
}
