/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Inject } from '@angular/core';
import { HomeStatePolicy } from './home-state-policy';
import LimitedSizeQueue from '../../../shared/data-structures/limited-size-queue';
import PermissionsRepository from '../../../permissions/PermissionsRepository';
import IState = angular.ui.IState;

/**
 * A policy that sets the home screen to the first state (ordered by the `homePageIndex` property)
 * that the user has permission to access.
 */
export class FirstAllowedHomeStatePolicy implements HomeStatePolicy {

    /**
     * Constructs a new instance of the first-allowed-home-state-policy class.
     *
     * @param $state The service that transitions between states.
     * @param permissionsRepository The repository which stores permissions of a user.
     * @param isPotentialHomeState A predicate that determines if a given state is a potential home state.
     */
    constructor( @Inject('$state') private $state: angular.ui.IStateService,
        @Inject('permissionsRepository') private permissionsRepository: PermissionsRepository,
        @Inject('isPotentialHomeState') private isPotentialHomeState: (state: IState) => boolean) {
    }

    /**
     * @inheritdoc
     */
    public getHomeState(_history: LimitedSizeQueue<string>): string {
        const firstAllowed = this.$state.get()
            .filter(this.isPotentialHomeState)
            .sort((s1, s2) => s1.data.homePageIndex - s2.data.homePageIndex)
            .find(s => this.permissionsRepository.checkStatePermissions(s.name));

        return firstAllowed ? firstAllowed.name : undefined;
    }
}
