/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import CustomFilterByTypes from '../../../../filtering/custom-filters/CustomFilterByTypes';
import TypeComponentController from '../../../../filtering/components/baseClasses/type/TypeComponentController';

import TypeScope from '../../../../filtering/components/baseClasses/type/TypeScope';
import { IRootScopeService } from 'angular';
import Session from '../../../../../login/Session';
import { UNIT_ACTIONS } from '../../../../filtering/consts';

/**
 * A component of types for call filtering
 */
export const unitTypeComponent: angular.IComponentOptions = {
    template: require('../../../../filtering/components/baseClasses/type/type-component.component.html'),

    controller: class CallTypeComponentController extends TypeComponentController {
        public static $inject = ['$rootScope', '$scope', 'unitCustomFilterByTypes', 'session'];
        constructor(
            $rootScope: IRootScopeService,
            $scope: TypeScope,
            customFilterByTypes: CustomFilterByTypes,
            session: Session,
        ) {
            super($rootScope, $scope, customFilterByTypes, session, UNIT_ACTIONS);
        }
    }
};
