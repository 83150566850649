/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';

/**
 * The demographic action taken detail model
 */
@table('rcactdl')
export class ActionTaken {

    @expand('abbr', 'desc')
    public action: string;
}
