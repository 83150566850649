/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import { Vehicle } from './model/vehicle';
import { DatabaseService } from '../../../shared/database/database.service';
import { SlideSourceInfo } from '../../../schema/SlideSourceInfo';
import { JoinPipe } from '../../../shared/pipes';
import { VehicleLocalId } from './model/vehicle-local-id';
import { TypedAddress } from './model/typed-address';
import { Characteristic } from './model/characteristic';

/**
 * Since garage addresses don't have a type in the database,
 * we are going to manually assign one to them for display purposes.
 */
const GARAGE_ADDRESS_TYPE = 'Garage';

/**
 * A component that displays the details of a vehicle.
 */
@Component({
    selector: 'sds-vehicle-detail',
    template: require('./vehicle-detail.component.html')
})
export class VehicleDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * The vehicle to display.
     */
    public vehicle: Vehicle;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading = false;

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        this.commentsSlideSourceInfo,
        ['Addresses', { srcHtml: 'app/rms/vehicle/detail/slides/addresses.html' }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * Constructs a new instance of the VehicleDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param commentsSlideSourceInfo The SlideSourceInfo for the comments slide.
     * @param involvementsSlideSourceInfo The SlideSourceInfo for the involvements slide.
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     * @param changeDetectorRef The ChangeDetectorRef to detect changes to update the view.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        private joinPipe: JoinPipe,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Format functions needed to display a list of items correctly inside of a popup.
     */
    public localIdFormatter = (localId: VehicleLocalId) => this.joinPipe.transform([localId.type, localId.id], ' - ');
    public characteristicFormatter = (characteristic: Characteristic) => characteristic.description;

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(Vehicle, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => {
                detailModel.addresses = this.combineAddresses(detailModel);
                this.vehicle = detailModel;
            },
            () => this.onError.emit(),
            () => {
                this.finishedLoading = true; this.changeDetectorRef.detectChanges(); 
            }
            );
    }

    /**
     * Combines all of the addresses from the given vehicle.
     *
     * @param vehicle The vehicle from which to retrieve the addresses.
     * @returns An array that contains all addresses from the vehicle.
     */
    private combineAddresses(vehicle: Vehicle): TypedAddress[] {
        const addresses: TypedAddress[] = vehicle.frequentAddresses.slice();

        // Add the garage address if it exists.
        if (vehicle.garageAddress) {
            addresses.unshift(Object.assign({ type: GARAGE_ADDRESS_TYPE }, vehicle.garageAddress));
        }

        return addresses;
    }
}
