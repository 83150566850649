/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../../shared/database/database.service';
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import { JailBooking } from './model/jail-booking';
import { SlideSourceInfo } from '../../../schema/SlideSourceInfo';
import PermissionName from '../../../permissions/enums/PermissionName';

/**
 * A component that displays the details of a jail booking.
 */
@Component({
    selector: 'sds-jail-booking-detail',
    template: require('./jail-booking-detail.component.html')
})
export class JailBookingDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        ['Addtional Info', { srcHtml: 'app/rms/shared/slides/comments.html' }],
        ['Offenses', { srcHtml: 'app/rms/jail/shared/slides/offenses.html', permissions: PermissionName.offense }],
        ['Arrests', { srcHtml: 'app/rms/jail/shared/slides/arrests.html', permissions: PermissionName.arrest }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The jail booking model to display.
     */
    public jailBooking: JailBooking;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the JailBookingDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param involvementsSlideSourceInfo inject the involvement slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(JailBooking, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.jailBooking = detailModel, () => this.onError.emit());
    }
}
