/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { TableNode } from '../../nodes/class/table-node';
import { ModelCreationData } from '../model-creation-data';

/**
 * Performs a "visit" on a TableNode during the process of creating the human-friendly model.
 */
@Injectable()
export class TableNodeModelCreationVisitor implements TypeSpecificTreeNodeVisitor<TableNode, ModelCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [TableNode];

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: TableNode, data: ModelCreationData): void {
        // We need to create a corresponding human-friendly TypeScript model for each database model.
        // If the database model is undefined, then the human-friendly model will be undefined as well.
        data.modelPairs.peek().forEach(p => p.humanFriendlyModel = p.databaseModel ? new node.classConstructor() : undefined);
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: TableNode, _data: ModelCreationData): void {
        // Nothing to do here.
    }
}
