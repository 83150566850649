/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import PermissionName from '../permissions/enums/PermissionName';
import { DetailsStateParams } from '../shared/interfaces/DetailsStateParams';

/**
 * Creates a state definition that displays the details of a single record.
 *
 * @param name The human-friendly name of the state (kebab case).
 * @param tableName The database table name.
 * @param options (optional) The configurable options for optional settings for the state.
 * @returns A state definition.
 */
export function createDetailState(name: string, tableName: string, options?: DetailStateOptions): angular.ui.IState {
    const customTitle = options && options.title ? options.title : '';
    const attachmentTitle = options && options.title ? ' title=' + customTitle : '';
    const stateDefinition: angular.ui.IState = {
        url: options && options.customUrl ? options.customUrl : `/results/${name}/:id`,
        controller: ['$stateParams', function ($stateParams: DetailsStateParams) {
            this.id = $stateParams.id;
            this.tableName = tableName;
            this.error = false;
            this.onConnectionError = () => {
                this.error = true;
            };
        }],
        controllerAs: '$ctrl',
        template: `
            <ion-view>
                <ion-nav-title>{{'${customTitle}' || ('${tableName}' | sdsPipe:'sdsTableInfo':'name')}}</ion-nav-title>

                <ion-nav-buttons side="secondary" $ctrl.table="${tableName}">
                    <sds-attachment-link ng-if="!isWideScreen" [key]="$ctrl.id" [table]="$ctrl.tableName"${attachmentTitle}></sds-attachment-link>
                </ion-nav-buttons>

                <ion-header-bar class="bar bar-subheader widescreen-attachments-bar" ng-if="isWideScreen">
                    <sds-attachment-link [key]="$ctrl.id" [table]="$ctrl.tableName"${attachmentTitle}></sds-attachment-link>
                </ion-header-bar>

                <sds-connection-error-bar connected="!$ctrl.error"></sds-connection-error-bar>

                <ion-content ng-class="{'has-subheader': isWideScreen}">
                    <!-- TODO: Needs to work with this component. -->
                    <!-- <ion-refresher on-refresh="vm.requestData()"></ion-refresher> -->
                    <!-- <sds-message ng-if="!example">Unable to load the Name</sds-message> -->

                    <sds-${name}-detail (on-error)="$ctrl.onConnectionError()" ></sds-${name}-detail>
                </ion-content>
            </ion-view>
        `
    };

    if (options && options.permission !== undefined) {
        stateDefinition.data = {
            permissions: [options.permission]
        };
    }
    return stateDefinition;
}

/**
 * An interface that defines the configurable options for the detail state.
 */
export interface DetailStateOptions {

    /**
     * The permission that is required in order to view the record.
     */
    permission?: PermissionName;

    /**
     * A custom URL if the state does not follow the conventional pattern of
     * `/results/${name}/:id`.
     */
    customUrl?: string;

    /**
     * The custom title of the associated screen.
     */
    title?: string;
}
