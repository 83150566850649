/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Injector } from '@angular/core';
import { PropertyNodeFactory } from './property-node-factory';
import { TableNodeFactory } from '../class/table-node-factory';
import { ClassNodeFactory } from '../class/class-node-factory';
import { ClassNode } from '../class/class-node';
import { PropertyNode } from './property-node';
import { includeManyMetadataKey, IncludeManyData } from '../../../decorators';
import { IncludeManyNode } from './include-many-node';
import { IncludeManySelectNode } from './include-many-select-node';

/**
 * A factory that knows how to create IncludeManyNodes and IncludeManySelectNodes.
 */
@Injectable()
export class IncludeManyNodeFactory implements PropertyNodeFactory {

    /**
     * The backing property for the `nodeFactory` getter.
     */
    private _nodeFactory: ClassNodeFactory;

    /**
     * Constructs a new instance of the IncludeManyNodeFactory class.
     *
     * @param injector The dependency injector provided by the Angular framework.
     */
    constructor(private injector: Injector) {
    }

    /**
     * Gets a factory that knows how to create ClassNodes.
     *
     * Note: The factory is lazy-loaded because of an unavoidable circular dependency.
     * The TableNodeFactory depends upon the MasterPropertyNodeFactory which in turn depends upon this class.
     *
     * @returns The factory that knows how to create ClassNodes.
     */
    private get nodeFactory(): ClassNodeFactory {
        if (!this._nodeFactory) {
            this._nodeFactory = this.injector.get(TableNodeFactory);
        }
        return this._nodeFactory;
    }

    /**
     * @inheritdoc
     */
    public create(parent: ClassNode, propertyKey: string | symbol): PropertyNode {
        const includeData: IncludeManyData = Reflect.getMetadata(includeManyMetadataKey, parent.classConstructor.prototype, propertyKey);

        if (includeData) {
            if (includeData.select) {
                return new IncludeManySelectNode(parent, propertyKey, includeData.table, includeData.select);
            }
            return new IncludeManyNode(parent, propertyKey, includeData.table, includeData.childConstructor, this.nodeFactory);
        }
    }
}
