/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { IncludeOneSelectNode } from '../../nodes/property/include-one-select-node';
import { ModelCreationData } from '../model-creation-data';
import { TablesApiHelper } from '../tables-api-helper';
import { PropertyDeserializer } from '../property-deserializer';

/**
 * Performs a "visit" on an IncludeOneSelectNode during the process of creating the human-friendly model.
 */
@Injectable()
export class IncludeOneSelectNodeModelCreationVisitor implements TypeSpecificTreeNodeVisitor<IncludeOneSelectNode, ModelCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [IncludeOneSelectNode];

    /**
     * Constructs a new instance of the IncludeOneSelectNodeModelCreationVisitor class.
     *
     * @param tablesApiHelper A helper that knows how to work with the database models returned by the Tables API.
     * @param propertyDeserializer Derializes a property from the JSON response received from the database.
     */
    constructor(private tablesApiHelper: TablesApiHelper, private propertyDeserializer: PropertyDeserializer) {
    }

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: IncludeOneSelectNode, data: ModelCreationData): void {
        for (let { databaseModel, humanFriendlyModel } of data.modelPairs.peek()) {
            const joins = this.tablesApiHelper.getJoinsByTableName(databaseModel, node.table);

            if (joins.length > 1) {
                throw new Error(`The ${node.parent.classConstructor.name}.${node.propertyKey as string} property must use the @includeMany decorator because there can be many models of type ${node.table}`);
            }

            const join = joins[0];
            if (join && join.fields) {
                humanFriendlyModel[node.propertyKey] = this.propertyDeserializer.deserialize(node, join.fields[node.select]);
            }
        }
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: IncludeOneSelectNode, _data: ModelCreationData): void {
        // Nothing to do here.
    }
}
