/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { combinePropertyDecorators } from './decorator-helpers';
import { declareProperty } from './declare-property.decorator';

/**
 * The metadata key used to associate the involvementsAlerts with an object property.
 */
export const involvementsAlertsMetadataKey = Symbol('involvements-alerts');

/**
 * A decorator factory that associates the involvementsAlerts with the property on which it is applied.
 *
 * @returns The actual decorator that associates the involvementsAlerts with the property.
 */
export function involvementsAlerts() {
    return combinePropertyDecorators(
        declareProperty,

        // Note: The involvementsAlertsMetadataKey is used as both the key and the value.
        //       We could have used anything as the value - we really only care about the key.
        Reflect.metadata(involvementsAlertsMetadataKey, involvementsAlertsMetadataKey)
    );
}
