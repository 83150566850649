/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { SystemUse } from './system-use';

/**
 * An implementation of the abstract class SystemUse and serves as the default
 * implementation should the injection conditions not be met.
 */
@Injectable()
export class SystemUseDefaultService implements SystemUse {

    /**
     * @inheritdoc
     */
    public getNotificationPopup(): Observable<any> {

        return Observable.empty();
    }
}
