/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;

/**
 * A factory function that creates the NameAddressDirective.
 *
 * @returns {IDirective} A directive that displays the address associated with a name record.
 */
export default function NameAddressDirective(): IDirective {
    return {
        template: require('./nameAddress.html'),
        scope: {
            name: '='
        }
    };
}
