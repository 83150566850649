/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import SettingKey from './SettingKey';
import { SpillmanLocalStorage } from '../shared/storage/spillman-local-storage';

/**
 * A class that persists the value of a setting by saving it in the browser's localStorage.
 */
export default class SettingStorage {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['spillmanLocalStorage'];

    /**
     * Constructs a new instance of the SettingStorage class.
     *
     * @param spillmanLocalStorage The object that handles storing Spillman-specific data in local storage.
     */
    constructor(private spillmanLocalStorage: SpillmanLocalStorage) {
    }

    /**
     * Gets the stored value of the setting that has the specified key.
     *
     * @param key The key that uniquely identifies the setting.
     * @returns {any} The stored value or null if there is not one.
     */
    public get(key: SettingKey): any {
        return this.spillmanLocalStorage.getObject({ key: SettingKey[key], userSpecific: true });
    }

    /**
     * Sets the stored value of the setting that has the specified key.
     *
     * @param key The key that uniquely identifies the setting.
     * @param value The value to store.
     */
    public set(key: SettingKey, value: any): void {
        this.spillmanLocalStorage.setObject({ key: SettingKey[key], userSpecific: true }, value);
    }
}
