/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import ITimeoutService = angular.ITimeoutService;
import IIntervalService = angular.IIntervalService;
import ILogService = angular.ILogService;
import PersistentWebSocket from './PersistentWebSocket';
import PersistentWebSocketConfig from './configuration/PersistentWebSocketConfig';
import ExtendedWebSocketConfig from './configuration/ExtendedWebSocketConfig';
import { NotificationService } from '../notifications/notification-service';

/**
 * A factory that creates PersistentWebSocket instances.
 *
 * Note: By design, this factory is registered with Angular's dependency injection framework
 *       whereas the actual PersistentWebSocket is not. The reason for that is that it greatly
 *       simplifies the instantiation of the PersistentWebSocket as well as allowing multiple
 *       instances, each with a unique configuration.
 */
export default class PersistentWebSocketFactory {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$timeout', '$interval', '$log', 'persistentWebSocketDefaultConfig', 'notificationService'];

    /**
     * The constructor for the PersistentWebSocketFactory.
     *
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param $interval The Angular service that repeatedly performs some action on a regular interval.
     * @param $log The Angular service that performs logging.
     * @param persistentWebSocketDefaultConfig The default config values to use if others are not explicitly specified.
     */
    constructor(
        private $timeout: ITimeoutService,
        private $interval: IIntervalService,
        private $log: ILogService,
        private persistentWebSocketDefaultConfig: ExtendedWebSocketConfig,
        private notificationService: NotificationService
    ) {
    }

    /**
     * A factory method that creates a new PersistentWebSocket.
     *
     * @param config The configuration for the new PersistentWebSocket.
     * @returns {PersistentWebSocket} A new PersistentWebSocket.
     */
    public create(config: PersistentWebSocketConfig): PersistentWebSocket {
        return new PersistentWebSocket(
            this.$timeout,
            this.$interval,
            this.$log,
            this.applyDefaultValues(config),
            this.notificationService
        );
    }

    /**
     * Applies the default values for each "extended" property only if given values are falsy.
     *
     * @param config The original configuration object.
     * @returns {PersistentWebSocketConfig} A copy of the original configuration with default values applied to falsy properties.
     */
    private applyDefaultValues(config: PersistentWebSocketConfig): PersistentWebSocketConfig {
        // Make a copy so that the original is not modified.
        let finalConfig = angular.copy(config);
        this.applyDefaultValue(finalConfig, 'nativeEventPrefix');
        this.applyDefaultValue(finalConfig, 'reconnectTiming');
        this.applyDefaultValue(finalConfig, 'heartbeatConfig');
        return finalConfig;
    }

    /**
     * Applies the default value by modifying the config in place if it has a falsy value.
     *
     * @param finalConfig The final configuration that will be passed to the new PersistentWebSocket.
     * @param propertyName The name of the property whose default value will be applied if the config has a falsy value.
     */
    private applyDefaultValue(finalConfig: PersistentWebSocketConfig, propertyName: string): void {
        if (!finalConfig[propertyName]) {
            finalConfig[propertyName] = this.persistentWebSocketDefaultConfig[propertyName];
        }
    }
}
