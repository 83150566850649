/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';

/**
 * A traffic warning offense.
 */
@table('twoffs')
export class Offense {

    @expand('offcode', 'desc')
    public description: string;
}
