/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';
import { VehicleReference } from '../../shared/model/vehicle-reference';

/**
 * The Vehicle model
 */
@table('lpvehic')
export class Vehicle {

    @field('number')
    public id: string;

    @expand('vehid', VehicleReference)
    public vehicle: VehicleReference | string;

    @field('relat')
    public relationship: string;
}
