/* Copyright © 2022 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IResourceClass = angular.resource.IResourceClass;
import IHttpService = angular.IHttpService;
import ComputedUrlResource from '../../resource/computedUrl/ComputedUrlResource';
import IHttpResponseTransformer = angular.IHttpResponseTransformer;
import Sex from '../../schema/Sex';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
SexCodeAPI.$inject = ['computedUrlResource', '$http', 'getFirstElementTransform'];

/**
 * A factory function that creates an API for sending AVL Device data to the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @param $http The Angular service that makes http requests.
 * @param getFirstElementTransform A response transform that returns only the first element from the array.
 * @returns {IResourceClass<avlDevice>} A resource class which knows how to interact with AVL Device on the REST server.
 */
function SexCodeAPI(computedUrlResource: ComputedUrlResource,
    $http: IHttpService,
    getFirstElementTransform: IHttpResponseTransformer): IResourceClass<Sex> {
    return computedUrlResource<Sex>(
        {
            path: '/codedValueList/sex'
        }, {}, {
            get: {
                method: 'GET',
                transformResponse: angular.appendTransform($http.defaults.transformResponse, getFirstElementTransform)
            }
        }
    );
}

export default SexCodeAPI;
