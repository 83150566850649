/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Provider, TypeProvider } from '@angular/core';
import { conditionalInjectionMap } from './conditional-injection-map';
import { ConditionalServiceLoader } from './conditional-service-loader.service';

/**
 * A helper function that provides the correct service based on the conditions
 * specified in the `@injectionCondition` decorator.
 *
 * @param token The public injection token.
 * @param types One or more classes (services) that have been decorated with the `@injectionCondition` decorator.
 * @returns An array of providers to be registered with an NgModule.
 */
export function provideBasedOnCondition(token: any, ...types: TypeProvider[]): Provider[] {

    // For convenience, we store all of the types associated with the token in a map so that
    // developers only need to pass in the token to the ConditionalServiceLoader -- nothing more.
    conditionalInjectionMap.set(token, types);

    return [
        ...types,
        {
            provide: token,
            useFactory: (loader: ConditionalServiceLoader) => loader.loadService(token),
            deps: [ConditionalServiceLoader]
        }
    ];
}
