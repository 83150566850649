/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../shared/database/decorators';

/**
 * Accident name.
 */
@table('cdproqa')
export class Determinant {

    @field('typ')
    public type: string;

    @field('determ')
    public code: string;
}
