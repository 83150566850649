/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IRootScopeService = angular.IRootScopeService;
import IQService = angular.IQService;
import ILogService = angular.ILogService;
import Session from '../../../login/Session';
import DecoratorTransform from '../../../shared/transforms/DecoratorTransform';
import Repository from '../../repository/Repository';
import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import CadConnection from '../../CadConnection';

/**
 * A repository that stores CAD calls that have been pushed from the server via a WebSocket.
 */
export default class CallRepository extends Repository<AggregatorCadCall> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        'cadConnection',
        '$rootScope',
        '$q',
        '$log',
        'session',
        'allCallTransforms'
    ];

    /**
     * Constructs a new CallRepository.
     *
     * @param cadConnection The connection to the CAD data feed.
     * @param $rootScope The Angular $rootScope that can be used to listen to application events.
     * @param $q The angular service that handles creating and working with promises.
     * @param $log The Angular service that performs logging.
     * @param session The object that stores information about the current user's session.
     * @param allCallTransforms The array of transforms to apply to a Call whenever it is added or modified.
     */
    constructor(
        cadConnection: CadConnection,
        $rootScope: IRootScopeService,
        $q: IQService,
        $log: ILogService,
        session: Session,
        allCallTransforms: DecoratorTransform<AggregatorCadCall>[]
    ) {
        super(cadConnection, $rootScope, $q, $log, session, allCallTransforms, {
            dataProperty: 'callData',
            refreshFlag: 'fullCall',
            idProperty: 'callIdAndType',
            dataFeedType: 'C',
            repositoryName: 'CallRepository'
        });
    }
}
