/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, includeOne, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { NameReference } from '../../shared/model/name-reference';
import { MiscellaneousId } from './miscellaneous-id';
import { Involvement } from '../../../schema/Involvement';
import { Offense } from './offense';
import { OffenderTrackingVehicle } from './offender-tracking-vehicle';
import { Activity } from './activity';

/**
 * The offender tracking model.
 */
@table('somain')
export class OffenderTracking {

    @expand('action', 'descrpt')
    public action: string;

    @includeOne('socmnts', 'text')
    public actionComments: string;

    @includeMany('soactiv', Activity)
    public activities: Activity[];

    @field('agency')
    public agency: string;

    @expand('assgnto', 'fullnam')
    public assignedTo: string;

    @expand('classif', 'descrpt')
    public classification: string;

    @includeOne('somcmnts', 'text')
    public comments: string;

    @expand('cmplnce', 'descrpt')
    public compliance: string;

    @expand('dispos', 'descrpt')
    public disposition: string;

    @field('datedue')
    public dueDate: Date;

    @field('enddate')
    public endDate: Date;

    @expand('gangid', NameReference)
    public gang: NameReference | string;

    @expand('gangrol', 'descrpt')
    public gangRole: string;

    @field('lifetim')
    public lifetime: string;

    @includeMany('somiscid', MiscellaneousId)
    public miscellaneousIds: MiscellaneousId[];

    @field('nxtrnew')
    public nextRenewal: Date;

    @expand('namenum', NameReference)
    public offender: NameReference | string;

    @includeMany('sooffs', Offense)
    public offenses: Offense[];

    @expand('officer', 'fullnam')
    public officer: string;

    @field('otherdt')
    public otherDate: Date;

    @field('predtor')
    public predator: string;

    @expand('preprer', 'fullnam')
    public preparer: string;

    @expand('disprsn', 'descrpt')
    public reason: string;

    @expand('risklvl', 'descrpt')
    public riskLevel: string;

    @field('begdate')
    public startDate: Date;

    @expand('status', 'descrpt')
    public status: string;

    @expand('type', 'descrpt')
    public type: string;

    @includeMany('sovehic', OffenderTrackingVehicle)
    public offenderVehicles: OffenderTrackingVehicle[];

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
