/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import { createDetailState } from '../state-definition-helpers';
import { PermissionName } from '../../permissions/enums/PermissionName';
import { upgradeAdapter } from '../../upgrade-adapter';
import { IncidentSearchComponent } from './search/incident-search.component';
import { IncidentLocationAdapter } from './IncidentLocationAdapter';
import { IncidentOfficerComponent } from './list/incident-officer/incident-officer.component';
import { LawIncidentDetailComponent } from './law/detail/law-incident-detail.component';
import { EmsIncidentDetailComponent } from './ems/detail/ems-incident-detail.component';
import { FireIncidentDetailComponent } from './fire/detail/fire-incident-detail.component';
import { LawIncidentListController } from './law/list/law-incident-list-controller';
import { EmsIncidentListController } from './ems/list/ems-incident-list-controller';
import { FireIncidentListController } from './fire/list/fire-incident-list-controller';
import { MyIncidentListController } from './list/my-incident-list-controller';

export const incidentModule = angular.module('incident', [])
    .config(['$stateProvider', ($stateProvider: angular.ui.IStateProvider) => {
        $stateProvider
            // My Incidents
            .state('app.myIncidents', {
                url: '/myIncidents',
                abstract: true,
                views: {
                    myIncidents: {
                        template: '<ion-nav-view></ion-nav-view>'
                    }
                },
                data: {
                    permissions: [PermissionName.law]
                }
            })
            .state('app.myIncidents.myIncidentsList', {
                url: '/list',
                template: require('./list/list.html'),
                controller: 'myIncidentListController as vm',
                data: {
                    homePageIndex: 2
                }
            })
            .state('app.myIncidents.myIncidentsDetails', createDetailState('law-incident', 'lwmain', { customUrl: '/:id' }))

            // Law
            .state('app.search.lawIncidentList', {
                url: '/results/law-incidents?filter',
                template: require('./list/list.html'),
                controller: 'lawIncidentListController as vm',
                data: {
                    permissions: [PermissionName.law]
                }
            })
            .state('app.search.lawIncidentDetails', createDetailState('law-incident', 'lwmain', {
                permission: PermissionName.law,
                customUrl: '/results/law-incidents/:id'
            }))

            // EMS
            .state('app.search.emsIncidentList', {
                url: '/results/ems-incidents?filter',
                template: require('./list/list.html'),
                controller: 'emsIncidentListController as vm',
                data: {
                    permissions: [PermissionName.ems]
                }
            })
            .state('app.search.emsIncidentDetails', createDetailState('ems-incident', 'emmain', {
                permission: PermissionName.ems
            }))

            // Fire
            .state('app.search.fireIncidentList', {
                url: '/results/fire-incidents?filter',
                template: require('./list/list.html'),
                controller: 'fireIncidentListController as vm',
                data: {
                    permissions: [PermissionName.fire]
                }
            })
            .state('app.search.fireIncidentDetails', createDetailState('fire-incident', 'frmain', {
                permission: PermissionName.fire
            }));
    }])

    // Services
    .service('incidentLocationAdapter', IncidentLocationAdapter)

    // Controllers
    .controller('myIncidentListController', MyIncidentListController)
    .controller('lawIncidentListController', LawIncidentListController)
    .controller('emsIncidentListController', EmsIncidentListController)
    .controller('fireIncidentListController', FireIncidentListController)

    // Directives
    .directive('sdsIncidentOfficer', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(IncidentOfficerComponent))
    .directive('sdsLawIncidentDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(LawIncidentDetailComponent))
    .directive('sdsEmsIncidentDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(EmsIncidentDetailComponent))
    .directive('sdsFireIncidentDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(FireIncidentDetailComponent))
    .directive('sdsIncidentSearch', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(IncidentSearchComponent));
