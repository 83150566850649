/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { Determinant } from './determinant';
import { NameReference } from '../../shared/model/name-reference';
import { Involvement } from '../../../schema/Involvement';
import { RadioLog } from '../../shared/model/radio-log';

/**
 * The CAD call model
 */
@table('cdcall')
export class Call {

    @field('rtaddr')
    public street: string;

    @expand('rtcity', 'name')
    public city: string;

    @expand('alarm', 'desc')
    public alarm: string;

    @includeOne('cdcdesc', 'comnt')
    public comments: string;

    @expand('compln', NameReference)
    public complainant: NameReference | string;

    @field('contact')
    public contact: string;

    @field('caddr')
    public contactLocation: string;

    @includeMany('cdproqa', Determinant)
    public determinants: Determinant[];

    @expand('nature', 'desc')
    public nature: string;

    @expand('hwrcvd', 'desc')
    public howReceived: string;

    @includeMany('rlmain', RadioLog)
    public radioLogs: RadioLog[];

    @expand('rcvdby', 'fullnam')
    public receivedBy: string;

    @expand('rtcity', 'state')
    public state: string;

    @field('cphone')
    public telephone: string;

    @field('reptd')
    public whenReported: Date;

    @expand('rtcity', 'zip')
    public zip: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
