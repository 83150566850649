/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import ConnectionErrorBar from './ConnectionErrorBar';
import { RequestTimeoutInterceptor } from './RequestTimeoutInterceptor';
import { ReAuthenticationInterceptor } from './ReAuthenticationInterceptor';
import { upgradeAdapter } from '../upgrade-adapter';
import { Logger } from '../logging';
import { loggingExceptionHandler } from './logging-exception-handler';

export const errorHandlingModule = angular.module('errorHandling', [])

    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider.state('app.error', {
            url: '/error',
            template: require('./error.html')
        });
    }])

    .directive('sdsConnectionErrorBar', ConnectionErrorBar)
    .factory('reAuthenticationInterceptor', ReAuthenticationInterceptor)
    .factory('requestTimeoutInterceptor', RequestTimeoutInterceptor)
    .factory('logger', upgradeAdapter.downgradeNg2Provider(Logger))

    // Replace Angular's default $exceptionHandler with our own.
    .factory('$exceptionHandler', loggingExceptionHandler);
