/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { SpinnerDialog } from 'ionic-native/dist/es5/plugins/spinnerdialog';
import { PlatformDetector } from '../conditional-injection/platform-detection/platform-detector.service';
import { LogoutService } from '../authentication';
import RegeneratableContainer from './regeneratable/RegeneratableContainer';
import { PushNotificationService } from './push-notification/push-notification.service';

/**
 * The default message that will be shown if no other message is specified.
 */
const DEFAULT_LOGOUT_MESSAGE = 'Logging out';

/**
 * The controller for the logout page.
 */
export default class LogoutController {

    public static $inject = [
        '$ionicLoading',
        '$stateParams',
        'platformDetector',
        '$cordovaSpinnerDialog',
        'logoutService',
        'regeneratableContainer',
        'pushNotificationService'
    ];

    /**
     * Constructs a new instance of the LogoutController class.
     *
     * @param $ionicLoading The service that displays an overlay while loading data.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     * @param platformDetector The object that detects the current platform.
     * @param regeneratableContainer A container that stores all regeneratable services.
     * @param $cordovaSpinnerDialog Shows or hides a native spinner dialog.
     * @param logoutService The service which handles logging the user out of the app.
     * @param pushNotificationService The service which configure and works with push-notifications
     */
    constructor(
        $ionicLoading: ionic.loading.IonicLoadingService,
        $stateParams: LogoutStateParams,
        platformDetector: PlatformDetector,
        $cordovaSpinnerDialog: typeof SpinnerDialog,
        logoutService: LogoutService,
        regeneratableContainer: RegeneratableContainer,
        pushNotificationService: PushNotificationService
    ) {
        if (platformDetector.getPlatform() === 'browser') {
            $ionicLoading.show({
                template: '<ion-spinner></ion-spinner>',
                hideOnStateChange: true
            });
        } else {
            const message = $stateParams.message || DEFAULT_LOGOUT_MESSAGE;
            $cordovaSpinnerDialog.show('', message, true);
        }

        /**
         * Unsubscribe from all push notifications
         */
        pushNotificationService.unsubscribeFromPushNotification().then(() => {
            /**
             * Destroy WebSocket connection and logout after 250ms
             */
            regeneratableContainer.destroy().then(() => {
                setTimeout(() => {
                    logoutService.logout();
                }, 250);
            });
        });
    }
}

/**
 * An interface that defines the state params that can be passed into the LogoutController.
 */
interface LogoutStateParams extends angular.ui.IStateParamsService {

    /**
     * The message to display when logging out.
     * Only shown when run as an app -- not in the browser.
     */
    message: string;
}
