/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import CallActionViewState from './CallActionViewState';
import CallActionScope from '../CallActionScope';
import IHttpService = angular.IHttpService;
import { UrlFactory } from '../../../../../authentication';
import IonicPopupService = ionic.popup.IonicPopupService;
import Session from '../../../../../login/Session';
import PermissionName from '../../../../../permissions/enums/PermissionName';

/**
 * The base class for all CallActionViewStates that dispatch the current unit to the call.
 */
abstract class BaseDispatchCallActionViewState implements CallActionViewState {

    /**
     * Constructs a new instance of the BaseDispatchCallActionViewState class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param $http The Angular service that makes http requests.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param session The object that stores information about the current user's session.
     */
    constructor(
        protected $scope: CallActionScope,
        protected $http: IHttpService,
        protected urlFactory: UrlFactory,
        protected $ionicPopup: IonicPopupService,
        protected session: Session
    ) {
    }

    public get isUsersActiveCall(): boolean {
        throw new Error('Abstract Property');
    };

    public get buttonText(): string {
        throw new Error('Abstract Property');
    };

    public get isActive(): boolean {
        throw new Error('Abstract Property');
    };

    public get statePermission(): PermissionName[] {
        return undefined;
    };

    public performAction() {
        let unitNumber = this.session.data.unitNumber;
        if (!unitNumber) {
            throw new Error('You must be assigned to a unit in order to be dispatched to a call.');
        }

        this.$scope.disableButton = true;

        let url = this.urlFactory.create({
            path: `/CAD/units/${unitNumber}/call/${this.$scope.call.callIdAndType}`
        });
        this.$http.put(url, {})
            .catch(() => {
                this.$scope.disableButton = false;
                this.$ionicPopup.alert({ template: 'An error occurred while attempting to assign you to the call.' });
            });
    }
}

export default BaseDispatchCallActionViewState;
