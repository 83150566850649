/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BatchRequest } from '../../../api/batch-request/batch-request.service';
import { OffenderTrackingVehicle } from '../model/offender-tracking-vehicle';
import { OffenderTrackingVehicleComments } from '../model/offender-tracking-vehicle-comments';
import { OffenderTrackingVehicleCommentsRequestCreator } from './offender-tracking-vehicle-comments-request-creator.service';
import { ResponseParser } from '../../../api/response-parser.service';
import { Logger } from '../../../logging';

/**
 * A service that loads the comments for an entire list of offender tracking vehicles
 * by making only one request to the server.
 */
@Injectable()
export class OffenderTrackingVehicleCommentsBatchRequest extends BatchRequest<OffenderTrackingVehicle, OffenderTrackingVehicleComments> {

    /**
     * Constructs a new instance of the OffenderTrackingVehicleCommentsBatchRequest class.
     *
     * @param http The Angular service that makes http requests.
     * @param requestCreator The object that creates the request to retrieve
     *                       the joined items for the list of models.
     * @param responseParser The object that knows how to parse a response from Spillman's API.
     * @param logger Logs errors to the appropriate location based on the environment.
     */
    constructor(
        http: Http,
        requestCreator: OffenderTrackingVehicleCommentsRequestCreator,
        responseParser: ResponseParser,
        logger: Logger
    ) {
        super(http, requestCreator, responseParser, logger);
    }

    /**
     * @inheritdoc
     */
    protected getPrimaryKey(vehicle: OffenderTrackingVehicle): string {
        return vehicle.id;
    }

    /**
     * @inheritdoc
     */
    protected getForeignKey(comments: OffenderTrackingVehicleComments): string {
        return comments.num;
    }

    /**
     * @inheritdoc
     */
    protected updateModelWithJoins(vehicle: OffenderTrackingVehicle, commentsPromise: Promise<OffenderTrackingVehicleComments[]>): void {
        commentsPromise.then(comments => {
            if (comments && comments.length) {
                vehicle.comments = comments[0].text;
            }
        });
    }
}
