/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import Involvement from '../../../schema/Involvement';
import { CadCallReference } from '../../shared/model/cad-call-reference';

/**
 * The traffic-stop model
 */
@table('cdtrstop')
export class TrafficStop {

    @field('addr')
    public street: string;

    @expand('city', 'name')
    public city: string;

    @expand('city', 'state')
    public state: string;

    @expand('city', 'zip')
    public zip: string;

    @field('agency')
    public agency: string;

    @field('clrnce')
    public clearance: string;

    @field('text')
    public comments: string;

    @expand('rcvdby', 'fullnam')
    public dispatcher: string;

    @field('plate')
    public licensePlate: string;

    @field('state')
    public licenseState: string;

    @field('unit')
    public unit: string;

    @field('date')
    public whenOccurred: Date;

    @expand('zone', 'desc')
    public zone: string;

    @expand('call', CadCallReference)
    public relatedCall: CadCallReference | string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
