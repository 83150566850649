/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import FiltersStorage from '../storage/FiltersStorage';
import CustomFilterByTypes from '../custom-filters/CustomFilterByTypes';

export default class UnitCustomFilterByTypes extends CustomFilterByTypes {
    public static $inject = ['unitFiltersStorage'];

    constructor(filtersStorage: FiltersStorage) {
        super(filtersStorage);
    }
}
