/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import * as moment from 'moment';
import IHttpService = angular.IHttpService;
import Session from '../../login/Session';
import DecoratorToResponseTransformAdapter from '../../shared/transforms/DecoratorToResponseTransformAdapter';
import Incident from '../../schema/Incident';
import SettingKey from '../../settings/SettingKey';
import SettingsRepository from '../../settings/SettingsRepository';
import IncidentGetTransform from './enums/IncidentGetTransform';
import ComputedUrlResource from '../../resource/computedUrl/ComputedUrlResource';
import IHttpResponseTransformer = angular.IHttpResponseTransformer;
import { Involvable } from '../../schema/interfaces/involvable';
import { InvolvementAlertsBatchRequest } from '../involvement/involvement-alerts-batch-request.service';
import ExtendedResourceClass from './ExtendedResourceClass';

/**
 * The involvement type number for law incidents (lwmain).
 */
const INCIDENT_INVOLVEMENT_TYPE_NUMBER = 1200;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
IncidentAPI.$inject = [
    'computedUrlResource',
    '$http',
    'getFirstElementTransform',
    'decoratorToResponseTransformAdapter',
    'settingsRepository',
    'serverDateTimeFormat',
    'session',
    'defaultPageSize',
    'addInvolvementTypeTransform',
    'involvementAlertsBatchRequest'
];

/**
 * A factory function that creates an API for retrieving incidents from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @param $http The Angular service that makes http requests.
 * @param getFirstElementTransform A response transform that returns only the first element from the array.
 * @param decoratorToResponseTransformAdapter An adapter that creates a response transform that loops through an array of decorator transforms.
 * @param settingsRepository The repository that stores all settings.
 * @param serverDateTimeFormat The constant that defines the format in which dates are sent by the server.
 * @param session The object that stores information about the current user's session.
 * @param defaultPageSize The default page size used when displaying a list of records.
 * @param addInvolvementTypeTransform A curried function that can be used to add the involvement type number to domain models.
 * @param involvementAlertsBatchRequest The service that retrieves the involvement alerts for an entire list of involvable models.
 * @returns {ExtendedResourceClass} A resource class that knows how to load Incidents from the REST server.
 */
function IncidentAPI(
    computedUrlResource: ComputedUrlResource,
    $http: IHttpService,
    getFirstElementTransform: IHttpResponseTransformer,
    decoratorToResponseTransformAdapter: DecoratorToResponseTransformAdapter<Incident>,
    settingsRepository: SettingsRepository,
    serverDateTimeFormat: string,
    session: Session,
    defaultPageSize: number,
    addInvolvementTypeTransform: _.CurriedFunction2<number, Involvable | Involvable[], Involvable | Involvable[]>,
    involvementAlertsBatchRequest: InvolvementAlertsBatchRequest
): ExtendedResourceClass<Incident> {

    /**
     * Creates a filter that limits the incidents to only those that were created recently.
     * How far back in time to go is determined by the 'myIncidentsDuration' setting.
     *
     * @returns {string} A string that filters the incidents to only those that were created recently.
     */
    function getRecentIncidentsFilter(): string {
        // Get the value of the setting that specifies how far back in time to go for recent incidents.
        let duration = settingsRepository.get(SettingKey.myIncidentsDurationSetting).value;

        // Calculate the cutoff date based on that setting and format it as the server expects.
        let cutoffDate = moment().subtract(duration).format(serverDateTimeFormat);

        // Create the filter which limits the incidents to only those after the cutoff date.
        return 'dtrepor>' + cutoffDate;
    }

    /**
     * Creates a filter that limits the incidents to only those where the current user
     * is the responsible officer.
     *
     * @returns {string} A string that filters the incidents to only those where the
     *                   current user is the responsible officer.
     */
    function getCurrentOfficerFilter(): string {
        return 'respoff=' + session.data.shortName;
    }

    // The params to use when retrieving a list from the API.
    const listParams = {
        sort: '-dtrepor', // Sort by the reported date, descending.
        pageSize: defaultPageSize
    };

    const addIncidentInvolvementTypeTransform = addInvolvementTypeTransform(INCIDENT_INVOLVEMENT_TYPE_NUMBER);
    const addIncidentTypeParam = (data: Array<Incident>) => {
        data.map((item: Incident) => item.incidentType = 'law');
        return data;
    };

    return <ExtendedResourceClass<Incident>>computedUrlResource<Incident>({ path: '/tables/lwmain/:id/:items' }, {}, {
        get: {
            method: 'GET',
            params: {
                expand: 'nameid,locatn,dispos,howrc,offetkn,offeobs,geoaddr,ccode,jstat,lwstatut.statute,lwoffs.offcode',
                include: 'lwnarr,lwsupl,lwrespo,lwstatut,lwoffs,lwcasnum'
            },
            transformResponse: angular.appendTransform(
                $http.defaults.transformResponse,
                getFirstElementTransform,
                addIncidentInvolvementTypeTransform,
                decoratorToResponseTransformAdapter.createTransform(IncidentGetTransform)
            )
        },
        query: {
            method: 'GET',
            isArray: true,
            params: listParams,
            transformResponse: angular.appendTransform(
                $http.defaults.transformResponse,
                addIncidentInvolvementTypeTransform,
                involvementAlertsBatchRequest.query
            )
        },
        callQuery: {
            method: 'GET',
            isArray: true,
            params: listParams,
            transformResponse: angular.appendTransform(
                $http.defaults.transformResponse,
                addIncidentTypeParam
            )
        },
        recent: {
            method: 'GET',
            isArray: true,
            params: angular.extend({}, listParams, {
                filter: () => getRecentIncidentsFilter() + ';' + getCurrentOfficerFilter()
            }),
            transformResponse: angular.appendTransform(
                $http.defaults.transformResponse,
                addIncidentInvolvementTypeTransform,
                involvementAlertsBatchRequest.query
            )
        }
    });
}

export default IncidentAPI;
