/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import BaseDispatchCallActionViewState from './BaseDispatchCallActionViewState';
import CallActionScope from '../CallActionScope';
import IHttpService = angular.IHttpService;
import { UrlFactory } from '../../../../../authentication';
import IonicPopupService = ionic.popup.IonicPopupService;
import Session from '../../../../../login/Session';

/**
 * The view state that indicates that the user can select this call because he/she is already assigned
 * but not actively working on it.
 */
class SelectCallActionViewState extends BaseDispatchCallActionViewState {

    public static $inject = ['$scope', '$http', 'urlFactory', '$ionicPopup', 'session'];

    /**
     * Constructs a new instance of the AssistCallActionViewState class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param $http The Angular service that makes http requests.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param session The object that stores information about the current user's session.
     */
    constructor(
        $scope: CallActionScope,
        $http: IHttpService,
        urlFactory: UrlFactory,
        $ionicPopup: IonicPopupService,
        session: Session
    ) {
        super($scope, $http, urlFactory, $ionicPopup, session);
    }

    public get isUsersActiveCall() {
        return false;
    }

    public get buttonText() {
        return 'Select Call';
    }

    public get isActive() {
        let removed = this.$scope.removed;
        let call = this.$scope.call;
        let myUnit = this.$scope.myUnit;
        return !removed && call && myUnit && myUnit.callIdAndType !== call.callIdAndType;
    }
}

export default SelectCallActionViewState;
