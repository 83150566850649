/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../shared/database/decorators';

/**
 * The modus operandi model
 */
@table('jlicmodt')
export class ModusOperandi {

    @expand('factor', 'desc')
    public factor: string;

    @expand('method', 'desc')
    public description: string;
}
