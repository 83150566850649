/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

export class FilterAnalytics {
    public open_filter_screen: string;
    public edit_filter_click: string;
    public clear_all_filters: string;
    public apply_all_filters: string;
    public save_filter_screen_action: string;
    public save_list_screen_action: string;
    public delete_filter_action: string;
    public rename_filter_action: string;
    public save_as_filter_action: string;

    constructor(
        open_filter_screen: string,
        edit_filter_click: string,
        clear_all_filters: string,
        apply_all_filters: string,
        save_filter_screen_action: string,
        delete_filter_action: string,
        save_list_screen_action: string,
        rename_filter_action: string,
        save_as_filter_action: string
    ){
        this.open_filter_screen = open_filter_screen;
        this.edit_filter_click = edit_filter_click;
        this.clear_all_filters = clear_all_filters;
        this.apply_all_filters = apply_all_filters;
        this.save_filter_screen_action = save_filter_screen_action;
        this.delete_filter_action = delete_filter_action;
        this.save_list_screen_action = save_list_screen_action;
        this.rename_filter_action = rename_filter_action;
        this.save_as_filter_action = save_as_filter_action;
    }
}

export const callAnalyticsEvents = new FilterAnalytics(
    'open_cad_filter_screen',
    'edit_cad_filter_click',
    'clear_cad_filter_click',
    'apply_cad_filter_click',
    'save_cad_filter_screen',
    'delete_cad_filter',
    'save_cad_list_screen',
    'rename_cad_filter',
    'save_as_cad_filter'
);

export const unitsAnalyticsEvents = new FilterAnalytics(
    'open_unit_filter_screen',
    'edit_unit_filter_click',
    'clear_unit_filter_click',
    'apply_unit_filter_click',
    'save_unit_filter_screen',
    'delete_unit_filter',
    'save_unit_list_screen',
    'rename_unit_filter',
    'save_as_unit_filter'
);
