/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { expandingPopupEvents } from '../../directives/ExpandingPopupDirective';
import { DeepLinkService } from 'app/shared/deeplink/deep-link-service';

/**
 * The default number of items that the list must contain in order
 * for the popup to be shown. This can be overridden on a case-by-case
 * basis by passing in a different number in the `showPopupCount` binding.
 */
const DEFAULT_SHOW_POPUP_COUNT = 2;

/**
 * The handle (or name) that is assigned to the ion-content within this component.
 * We need the handle so that the ion-content can be controlled via the `$ionicScrollDelegate`.
 */
const SCROLL_DELEGATE_HANDLE = 'display-list-scroller';

/**
 * A component that displays an entire list of values.
 */
export const displayListComponent: angular.IComponentOptions = {
    template: require('./display-list.component.html'),
    bindings: {
        label: '@',
        list: '<',
        formatter: '<',
        showPopupCount: '<',
        type: '<'
    },
    controller:
    /**
     * The controller for the DisplayListComponent.
     */
    class DisplayListComponentController {

        public static $inject = ['$scope', '$ionicScrollDelegate', 'deepLinkService'];

        /**
         * The label that describes the list.
         */
        public label: string;

        /**
         * The type of the values in the list.
         */
        public type: string = undefined;

        /**
         * The minimum number of items that the list must contain in order to show the popup.
         *
         * If the list has fewer items, then all items will be displayed and there will be no popup.
         * If the list has at least this many items, then only the first item will be shown initially
         * but all items will be visible inside of the popup.
         */
        public showPopupCount = DEFAULT_SHOW_POPUP_COUNT;

        /**
         * The un-formatted list to display. The list can be of any type as long
         * as there is a formatter that knows how to convert each item to a string.
         */
        public list: any[];

        public $onInit = () => {};

        /**
         * The private backing property for the `formatter` getter/setter.
         */
        private _formatter: (value: any, isLink?: boolean) => string;

        /**
         * Constructs a new instance of the DisplayListComponentController class.
         *
         * @param $scope The Angular scope object that can listen for events.
         * @param $ionicScrollDelegate A delegate used to control the ion-scroll directive.
         */
        constructor(
            $scope: angular.IScope,
            $ionicScrollDelegate: ionic.scroll.IonicScrollDelegate,
            public deepLinkService: DeepLinkService
        ) {
            $scope.$on(expandingPopupEvents.beforeCollapse, () => {
                $ionicScrollDelegate.$getByHandle(SCROLL_DELEGATE_HANDLE).scrollTop();
            });
        }

        /**
         * Gets the formatter that knows how to convert each item
         * in the list to a string.
         *
         * @returns the formatter that knows how to convert each item
         * in the list to a string.
         */
        public get formatter(): (value: any, isLink?: boolean) => string {
            if (!this._formatter) {
                // By default, just return the value that was given without any formatting.
                this._formatter = value => value;
            }
            return this._formatter;
        }

        /**
         * Sets the formatter that knows how to convert each item
         * in the list to a string.
         *
         * @param formatter A custom formatter to convert each item in the list to a string.
         */
        public set formatter(formatter: (value: any, isLink?: boolean) => string) {
            this._formatter = formatter;
        }

        /**
         * The formatted list to display.
         *
         * @returns The formatted list of strings.
         */
        public get formattedList(): string[] {
            return this.list ? this.list.map(item => this.formatter(item, true)).filter(item => item) : undefined;
        }

        /**
         * The formatted item to display outside of the popup.
         */
        public get formattedItem(): string {
            return this.list && this.list.length ? this.formatter(this.list[0], false) : undefined;
        }

        /**
         * Triggers DeepLinkService to dial phone into 10-21 app or default dial app.
         *
         * @param phone phone to be called.
         */
        public callItem(phone: string) {
            this.deepLinkService.open1021PhoneNumber(phone);
        }
    }
};
