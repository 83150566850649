/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IInjectorService = angular.auto.IInjectorService;
import ObjectEnumerator from '../../../../shared/interfaces/ObjectEnumerator';
import DecoratorTransform from '../../../../shared/transforms/DecoratorTransform';
import AggregatorUnit from '../../../../schema/AggregatorUnit';

/**
 * An enum that specifies the set of transforms that need to be performed when either
 * a new Unit is added to the UnitRepository or an existing Unit is modified.
 */
enum UnitTransforms {
    unitPropertiesTransform
}

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
AllUnitTransforms.$inject = ['$injector', 'enumEnumerator'];

/**
 * A factory function that creates an array of DecoratorTransforms that need to be performed for Units
 * contained within the UnitRepository.
 *
 * @param $injector The Angular injector service.
 * @param enumEnumerator The function that enumerates the values of an enum.
 * @returns {DecoratorTransform<AggregatorUnit>[]} The array of transforms to apply to CAD calls within CallRepository.
 */
export default function AllUnitTransforms($injector: IInjectorService, enumEnumerator: ObjectEnumerator): DecoratorTransform<AggregatorUnit>[] {
    return enumEnumerator(UnitTransforms).map(val => $injector.get<DecoratorTransform<AggregatorUnit>>(val));
}
