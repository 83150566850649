/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { HistoryStorage } from '../history-storage.service';
import { SpillmanLocalStorage } from '../../shared/storage/spillman-local-storage';
import { HistoryJsonSerializer } from '../history-json-serializer';

/**
 * Stores the history of states that the user visits as they navigate throughout the app.
 */
@Injectable()
export class StateHistoryStorage extends HistoryStorage<string> {

    /**
     * Constructs a new instance of the StateHistoryStorage class.
     *
     * @param spillmanLocalStorage The object that handles storing Spillman-specific data in local storage.
     * @param serializer Handles both serialization to and deserialization from JSON.
     */
    constructor(spillmanLocalStorage: SpillmanLocalStorage,
        serializer: HistoryJsonSerializer) {

        super(spillmanLocalStorage, serializer, {
            key: 'stateHistory',
            userSpecific: true
        });
    }
}
