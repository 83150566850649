/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * An enum that defines the types of incidents.
 */
export enum IncidentType {
    EMS,
    Fire,
    Law
}
