/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsLinkComponent } from './permissions-link/permissions-link.component';

@NgModule({
    imports: [CommonModule],
    declarations: [PermissionsLinkComponent],
    exports: [PermissionsLinkComponent]
})
export class PermissionsModule { }
