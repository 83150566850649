/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import CadFilter from '../CadFilter';
import CADModel from '../../../schema/interfaces/CADModel';
import FiltersStorage from '../../calls/filtering/CallFiltersStorage';

/**
 * A filter that accepts an array of other filters. If any one of those others filters returns true,
 * then the final result will be true. If all of them return false, then the final result will be false.
 * In other words, this class performs a logical OR operation on those filters.
 */
abstract class CustomDataCadFilter<T extends CADModel> implements CadFilter<CADModel> {

    public abstract STORAGE_KEY: string;

    public isFilterApplied = false;

    constructor(private filtersStorage: FiltersStorage) {}

    public abstract applyFilter(): void;

    public abstract include(model: T): boolean;

    public abstract setValues(values: any): void;

    public abstract getSelectedValues(): any;

    public getStoredValues(): FilterModel[] {
        const result = this.filtersStorage.retrieveCurrentFilter(this.STORAGE_KEY);
        return result;
    }

    public getEditingValues(): FilterModel[] {
        const result = this.filtersStorage.retrieveEditingFilter(this.STORAGE_KEY);
        return result;
    }

    protected abstract getModelValue(model: CADModel): any;

    public abstract getFilterLabel(): string;
}

export default CustomDataCadFilter;
