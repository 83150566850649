/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ITimeoutService = angular.ITimeoutService;
import IPromise = angular.IPromise;
import IDocumentService = angular.IDocumentService;
import * as moment from 'moment';
import Moment = moment.Moment;

/**
 * A specialized timeout that is guaranteed to execute even if the phone is put to sleep or the app
 * is moved into the background.
 *
 * Normally, when the browser is not active, any timeouts created by setTimeout (same with Angular's $timeout service)
 * are slowed down or stopped.
 * See http://stackoverflow.com/questions/6346849/what-happens-to-settimeout-when-the-computer-goes-to-sleep#answer-6347336
 *
 * This class fixes that problem by calculating the exact date/time when the timeout should have been executed and then
 * checking for missed execution whenever the app is made active again.
 */
export default class GuaranteedTimeout {

    /**
     * A space-delimited list of events that, when raised, will cause the GuaranteedTimeout to check
     * to see if it has missed its execution, and if so, then it will be executed.
     */
    public static CHECK_FOR_MISSED_EXECUTION_EVENTS = 'resume';

    /**
     * The exact date/time when the timeout should be executed.
     */
    private _expiration: Moment;

    /**
     * A flag that tracks if the timeout can be executed. This ensures that it is only executed at most once.
     * If cancelled, then it won't be executed at all.
     */
    private _canExecute = true;

    /**
     * The promise returned by Angular's `$timeout` service. This can be used to cancel the timeout as necessary.
     */
    private _timeoutPromise: IPromise<any>;

    /**
     * Constructs a new instance of the GuaranteedTimeout class.
     *
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param $document The Angular wrapper around the document object.
     * @param func The function to execute after `delay` milliseconds.
     * @param delay The number of milliseconds to wait before executing `func`.
     */
    constructor(
        private $timeout: ITimeoutService,
        private $document: IDocumentService,
        private func: Function,
        delay: number
    ) {
        // Create a MomentInstance that stores the exact day/time when the timeout should be executed.
        this._expiration = moment().add(delay, 'ms');

        // Set a timeout to call `execute` at the appointed time.
        // This will work as long as the browser isn't put into the background.
        this.createTimeout();

        // Listen for certain events that signal that we need to check if we missed execution.
        $document.on(GuaranteedTimeout.CHECK_FOR_MISSED_EXECUTION_EVENTS, this.checkForMissedExecution);
    }

    /**
     * Gets the exact date/time when the timeout should be executed.
     *
     * @returns The exact date/time when the timeout should be executed.
     */
    public get expiration(): Moment {
        return this._expiration;
    }

    /**
     * Cancels the timeout. This will do nothing if the timeout was already executed or cancelled.
     */
    public cancel() {
        if (this._canExecute) {
            this.$timeout.cancel(this._timeoutPromise);
            this._canExecute = false;
            this.$document.off(GuaranteedTimeout.CHECK_FOR_MISSED_EXECUTION_EVENTS, this.checkForMissedExecution);
        }
    }

    /**
     * Creates a $timeout that will call `execute` at the appointed time.
     * This will only work as long as the browser remains active.
     */
    private createTimeout(): void {
        const now = moment();

        if (this._expiration.isBefore(now)) {
            throw new Error('Cannot create a timeout if the expiration has already passed.');
        }

        const delay = this._expiration.diff(now);
        this._timeoutPromise = this.$timeout(this.execute.bind(this), delay);
    }

    /**
     * Executes the `func` that was passed into the constructor.
     */
    private execute() {
        if (this._canExecute) {
            this.func(...arguments);
            this.cancel();
        }
    }

    /**
     * Checks to see if `execute` should have been called, but was not due to the browser being asleep.
     * This can happen, for example, if the user switches to another app on their phone.
     */
    private checkForMissedExecution = () => {
        if (this._canExecute) {
            if (moment().isSameOrAfter(this._expiration)) {
                this.execute();
            } else {
                // We have to restart the timeout since the browser will have lost track if it went to sleep.
                this.$timeout.cancel(this._timeoutPromise);
                this.createTimeout();
            }
        }
    };
}
