/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CacheService } from '../../../../shared/services/cacheService';

/**
 * A service for retrieving values from a "Code Table", which is a
 * database table that stores codes and descriptions (e.g. 'abbr', 'desc').
 */
@Injectable()
export class CodeTableService {
    /**
     * Constructs a new instance of the CodeTableService class.
     *
     * @param http The Angular service that makes http requests.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     */
    constructor(
        private cacheService: CacheService
    ) {
    }

    /**
     * Gets the codes/descriptions that match the text from the specified database table.
     *
     * @param table The name of the database table.
     * @param text The text to match.
     * @param format A sprintf-style format string that determines how the code and description are combined.
     * @returns An Observable that contains the codes/descriptions.
     */
    public get(table: string, text: string, format?: string): Observable<any[]> {
        const searchOptions = this.cacheService.createRequestOptions(text, format);

        return this.cacheService.get(table, searchOptions).map(response => response.json());
    }
}
