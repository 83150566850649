/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { PropertyNode } from './property-node';
import { ClassNode } from '../class/class-node';
import { TreeNode } from '../../../../data-structures/tree-node';

/**
 * A node that associates a single field from an included database object with a property of a JavaScript class.
 * This node pertains when you have a one-to-one relationship between database tables.
 */
export class IncludeOneSelectNode extends PropertyNode {

    /**
     * Constructs a new instance of the IncludeOneSelectNode class.
     *
     * @param parent The parent ClassNode.
     * @param propertyKey The property key (or name if you prefer).
     * @param table The database table to include.
     * @param select The column from the included object to select.
     */
    constructor(parent: ClassNode, propertyKey: string | symbol, public table: string, public select: string) {
        super(parent, propertyKey);
    }

    /**
     * @inheritdoc
     */
    protected *initializeChildren(): Iterable<TreeNode> {
        // No children
    }
}
