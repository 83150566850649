/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import DecoratorTransform from './DecoratorTransform';
import MultiInjector from '../MultiInjector';
import IHttpResponseTransformer = angular.IHttpResponseTransformer;

/**
 * An adapter that converts an array of decorator transforms into a standard Angular response transform.
 *
 * The difference between the two types of transforms is that an Angular response transform is expected to
 * return a value whereas the decorator transforms modify the data in place and have no return value.
 *
 * This class also handles the data regardless of whether it is a single object or an array of objects.
 */
export default class DecoratorToResponseTransformAdapter<T> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['multiInjector'];

    /**
     * Constructs a new instance of the DecoratorToResponseTransformAdapter class.
     *
     * @param multiInjector Injects multiple services at once whose names are specified by an enum.
     */
    constructor(private multiInjector: MultiInjector) {
    }

    /**
     * Converts an array of decorator transforms into an Angular response transform.
     *
     * @param enumType The enum type whose values specify the names of the decorator transforms.
     * @returns {function((T[]|T)): T[]|T} An Angular response transform function.
     */
    public createTransform(enumType: Object): IHttpResponseTransformer {
        let decoratorTransforms = this.multiInjector.getAll<DecoratorTransform<T>>(enumType);

        // Return a function which is the response transform.
        return (data: T | T[]) => {

            // Loop through all decorator transform and apply each one.
            decoratorTransforms.forEach(transform => {

                // If it's an array, apply the transform to each individual element.
                if (data instanceof Array) {
                    data.forEach(d => transform.invoke(d));
                } else {
                    transform.invoke(<T>data);
                }
            });
            // Return the original data that has been modified by the decorator transforms.
            return data;
        };
    }
}
