/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IIntervalService = angular.IIntervalService;
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import ColorAssigner from '../../ColorAssigner';
import CADDetailsController from '../../CADDetailsController';
import AggregatorUnit from '../../../schema/AggregatorUnit';
import UnitRepository from '../repository/UnitRepository';
import { UnitQueryFactory } from './queries/unit-query-factory.service';
import CADDetailsScope from '../../CADDetailsScope';
import LocationAdapter from '../../../shared/mapping/LocationAdapter';

/**
 * The controller for the Unit Details page.
 */
export default class UnitDetailsController extends CADDetailsController<AggregatorUnit> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        '$scope',
        'unitRepository',
        'colorAssigner',
        'unitQueryFactory',
        '$interval',
        'unitLocationAdapter',
        '$stateParams'
    ];

    /**
     * Constructs a new instance of the UnitDetailsController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param unitRepository The repository that stores units pushed from the server.
     * @param colorAssigner The object that assigns a color based on the status of the CAD call.
     * @param unitQueryFactory The factory that creates the queries used to get data not pushed out by the aggregator.
     * @param $interval The Angular service that repeatedly performs some action on a regular interval.
     * @param unitLocationAdapter The adapter that obtains a location from a CAD Unit.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     */
    constructor(
        $scope: UnitDetailsScope,
        unitRepository: UnitRepository,
        colorAssigner: ColorAssigner,
        unitQueryFactory: UnitQueryFactory,
        $interval: IIntervalService,
        unitLocationAdapter: LocationAdapter<AggregatorUnit>,
        $stateParams: DetailsStateParams
    ) {
        super($scope, unitRepository, colorAssigner, unitQueryFactory, $interval, unitLocationAdapter, $stateParams.id, 'unit', [
            'geoCoordinates.latitude',
            'geoCoordinates.longitude'
        ]);

        $scope.showOtherOfficers = false;
        $scope.toggleOtherOfficers = () => $scope.showOtherOfficers = !$scope.showOtherOfficers;
    }

    public $onInit = () => {};
}

/**
 * The scope for the UnitDetailsController.
 */
interface UnitDetailsScope extends CADDetailsScope {

    /**
     * A flag that indicates if the other officers (i.e. those that aren't
     * the primary officer) are shown.
     */
    showOtherOfficers: boolean;

    /**
     * Toggles the `showOtherOfficers` flag.
     */
    toggleOtherOfficers: () => void;
}
