/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;

/**
 * A directive that allows input elements to be tabbable (able to receive focus via the tab key)
 * only if the containing `ion-slide` is active.
 * The input elements contained within non-active ion-slides will not be able to receive focus.
 */
export default function TabbableIfCurrentSlideDirective(): IDirective {
    let TAB_INDEX = 'tabindex';

    /**
     * Makes an input element tabbable, that is, able to receive focus via the tab key.
     *
     * @param input The input element to make tabbable.
     */
    function makeTabbable(input: Element): void {
        let $input = angular.element(input);
        let currentTabIndex = <number>$input.prop(TAB_INDEX);

        // Only if the current tab index is -1 do we need to make it tabbable.
        // Otherwise, it's already tabbable.
        if (currentTabIndex < 0) {
            let savedTabIndex = $input.data(TAB_INDEX);

            // If the saved tab index is undefined or negative, then set it to zero.
            if (!savedTabIndex || savedTabIndex < 0) {
                savedTabIndex = 0;
            }

            $input.prop(TAB_INDEX, savedTabIndex);
            $input.removeData(TAB_INDEX); // Clean up the data.
        }
    }

    /**
     * Makes an input element un-tabbable, that is, unable to receive focus via the tab key.
     *
     * @param input The input element to make un-tabbable.
     */
    function makeUntabbable(input: Element): void {
        let $input = angular.element(input);
        let currentTabIndex = <number>$input.prop(TAB_INDEX);

        // Only if the current tab index is non-negative do we need to make it un-tabbable.
        if (currentTabIndex >= 0) {
            $input.data(TAB_INDEX, currentTabIndex);
            $input.prop(TAB_INDEX, -1);
        }
    }

    return {
        restrict: 'A',
        require: '^ionSlideBox',
        link: function ($scope: IScope, $element: IAugmentedJQuery) {
            // Find the index of the slide that is the parent of this element.
            let parentSlideIndex = $element.closest('ion-slide').index();

            // If the element is not an ion-slide or contained within an ion-slide, then simiply return.
            if (parentSlideIndex < 0) {
                return;
            }

            /**
             * Updates the tab indexes, thus making the inputs either tabbable or untabbable,
             * based on whether they are contained within the current slide or not.
             *
             * @param currentSlideIndex The index of the current slide within the ion-slide-box.
             */
            function updateTabIndexes(currentSlideIndex: number): void {
                $element.find(':input').addBack(':input').each((_i, e) => {
                    if (currentSlideIndex === parentSlideIndex) {
                        makeTabbable(e);
                    } else {
                        makeUntabbable(e);
                    }
                });
            }

            // Set the initial state. Since the ion-slide-box doesn't expose the currentSlide property,
            // we will just assume it is the slide at index 0.
            updateTabIndexes(0);

            // Listen for future slide changes.
            $scope.$on('slideBox.slideChanged', (_event, slideIndex) => updateTabIndexes(slideIndex));
        }
    };
}
