/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import LogicalOrFilter from '../../filtering/baseClasses/LogicalOrFilter';
import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import MyCallsFilter from './MyCallsFilter';
import FilterByType from '../../filtering/filters/FilterByType';

/**
 * Filters the calls so that only those whose type is the same as the logged-in user's, will be shown.
 * As with all call filters, any calls that are assigned to the user will be shown no matter what -
 * they cannot be filtered out.
 */
export default class CallFilterByType extends LogicalOrFilter<AggregatorCadCall> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['myCallsFilter', 'filterByType'];

    /**
     * Constructs a new instance of the CallFilterByType class.
     *
     * @param myCallsFilter The filter that includes any calls assigned to the current user.
     * @param filterByType The filter that includes any calls that have the same type as the current user.
     */
    constructor(myCallsFilter: MyCallsFilter, filterByType: FilterByType) {
        super(myCallsFilter, filterByType);
    }
}
