/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Response, Http } from '@angular/http';
import { RequestCreator } from '../request-creator.service';
import { BatchRequestConfig } from './batch-request-config';
import AssociativeArray from '../../shared/interfaces/AssociativeArray';
import { ResponseParser } from '../../api/response-parser.service';
import uniq = require('lodash/uniq');
import groupBy = require('lodash/groupBy');
import { Logger } from '../../logging';

/**
 * The delimiter used by the API in order to separate items in a list.
 */
export const API_DELIMITER = '|';

/**
 * A service that does a "batch" request to the server. In other words,
 * this class requests data for an entire list by making one request to the server.
 *
 * Examples include:
 *   1. retrieving the address alerts for all CAD calls
 *   2. retrieving the involvement alerts for all vehicles, properties, or incidents
 */
export abstract class BatchRequest<TModel, TJoin> {

    /**
     * Constructs a new instance of the BatchRequest class.
     *
     * @param http The Angular service that makes http requests.
     * @param requestCreator The object that creates the request to retrieve
     *                       the joined items for the list of models.
     * @param responseParser The object that knows how to parse a response from Spillman's API.
     * @param logger Logs errors to the appropriate location based on the environment.
     */
    constructor(
        private http: Http,
        private requestCreator: RequestCreator<BatchRequestConfig>,
        private responseParser: ResponseParser,
        private logger: Logger
    ) {
    }

    /**
     * Performs a batch request to get the joined items for the given array of models.
     *
     * @param models The array of models
     * @returns The original models - they will asynchronously have the joins added to them
     *          when the request for the joins completes.
     */
    public query = (models: TModel[]): TModel[] => {
        if (!models) {
            throw new Error('`models` cannot be undefined or null');
        }

        const delimitedKeys = uniq(models.map(this.getPrimaryKey).filter(k => !!k)).join(API_DELIMITER);
        const joinsPromise = delimitedKeys ? this.requestJoins(delimitedKeys, models) : Promise.resolve({});
        this.updateModels(models, joinsPromise);
        return models;
    };

    /**
     * Gets the value of a primary key from the model.
     *
     * Note that the primary key doesn't necessarily have to be the model's own primary key.
     * For example, in the case of address alerts, the CAD Call's geoid is used, which is
     * the primary key of the gbaddr table -- not sycad.
     *
     * @param model The model from which to retrieve the primary key.
     * @returns The value of the primary key.
     */
    protected abstract getPrimaryKey(model: TModel): string;

    /**
     * Gets the value of the foreign key from the joined item.
     *
     * @param join The joined item.
     * @returns The foreign key from the joined item.
     */
    protected abstract getForeignKey(join: TJoin): string;

    /**
     * Updates the model with those joined items that reference it.
     *
     * @param model The model to update with joined items.
     * @param joinsPromise The promise that will return the list of joined items that reference the model.
     */
    protected abstract updateModelWithJoins(model: TModel, joinsPromise: Promise<TJoin[]>): void;

    /**
     * A hook method that allows subclasses to provide additional data to the RequestCreator.
     *
     * @param The array of all models. They can be used to obtain additional information.
     * @returns An associative array of any additional data needed by the RequestCreator.
     */
    protected createAdditionalData(_models: TModel[]): AssociativeArray<any> {
        // Returns undefined by default.
        // Subclasses can override this method to return something else.
        return;
    }

    /**
     * Performs the request to the server to retrieve the joins.
     *
     * @param delimitedKeys The string that contains the delimited list of keys.
     * @param models The array of models that were passed into the `query` method.
     * @returns A Promise that, when resolved, will provide the associative array of joins.
     */
    private requestJoins(delimitedKeys: string, models: TModel[]): Promise<AssociativeArray<TJoin[]>> {
        const additionalData = this.createAdditionalData(models);
        const request = this.requestCreator.create({ delimitedKeys, additionalData });

        return this.http.get(request.url, request)
            .map<Response, TJoin[]>(this.responseParser.parseArray)
            .map(joins => groupBy(joins, this.getForeignKey))
            .toPromise()
            .catch(err => this.logger.error(err));
    }

    /**
     * Updates each model in the array with those joins that correspond to its primary key.
     *
     * @param models The array of all models.
     * @param joinMapPromise A promise that returns an associative array that maps a particular
     *                       foreign key to an array of joins that all share that foreign key.
     */
    private updateModels(models: TModel[], joinMapPromise: Promise<AssociativeArray<TJoin[]>>): void {
        for (let model of models) {
            const primaryKey = this.getPrimaryKey(model);
            this.updateModelWithJoins(model, joinMapPromise.then(joinMap => joinMap ? (joinMap[primaryKey] || []) : undefined));
        }
    }
}
