/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { UrlConfig, Protocol } from './url-config';
import { CredentialsRepository } from '../credentials-repository.service';
import { enumerable } from '../enumerable.decorator';

/**
 * The default configuration options which is used by the url factory.
 */
@Injectable()
export class DefaultUrlConfig implements UrlConfig {

    /**
     * @inheritdoc
     */
    public protocol: Protocol = 'http';

    /**
     * @inheritdoc
     */
    public baseApiUrl = 'SpillmanApi/api';

    /**
     * @inheritdoc
     */
    public path = '';

    /**
     * Constructs a new instance of the DefaultUrlConfig.
     *
     * @param credentialsRepository
     */
    constructor(private credentialsRepository: CredentialsRepository) {
    }

    /**
     * @inheritdoc
     */
    @enumerable()
    public get secureConnection() {
        return this.credentialsRepository.credentials.secureConnection;
    }

    /**
     * @inheritdoc
     */
    @enumerable()
    public get server() {
        return this.credentialsRepository.credentials.server;
    }

    /**
     * @inheritdoc
     */
    @enumerable()
    public get port() {
        return this.credentialsRepository.credentials.port;
    }
}
