/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import EqualsSessionDataCadFilter from '../baseClasses/EqualsSessionDataCadFilter';
import Session from '../../../login/Session';
import CADModel from '../../../schema/interfaces/CADModel';
import SessionData from '../../../login/SessionData';
import FalsyEqualityComparer from '../../../shared/comparison/FalsyEqualityComparer';

/**
 * Filters the cad models so that only those whose zone is the same as the logged-in user's, will be shown.
 */
export default class FilterByZone extends EqualsSessionDataCadFilter<CADModel, string> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['session', 'falsyEqualityComparer'];

    /**
     * Constructs a new instance of the FilterByZone class.
     *
     * @param session The object that stores information about the current user's session.
     * @param falsyEqualityComparer An equality comparer that considers falsy strings to be equal.
     */
    constructor(session: Session, falsyEqualityComparer: FalsyEqualityComparer<string>) {
        super(session, falsyEqualityComparer);
    }

    // @Override
    protected getModelValue(model: CADModel): string {
        return model.zone;
    }

    // @Override
    protected getSessionValue(sessionData: SessionData): string {
        return sessionData.zone;
    }
}
