/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IRootScopeService = angular.IRootScopeService;
import IQService = angular.IQService;
import IDeferred = angular.IDeferred;
import IPromise = angular.IPromise;
import ILogService = angular.ILogService;
import Session from '../../login/Session';
import DecoratorTransform from '../../shared/transforms/DecoratorTransform';
import RepositoryConfig from './RepositoryConfig';
import PersistentWebSocket from '../../persistentWebSocket/PersistentWebSocket';
import AssociativeArray from '../../shared/interfaces/AssociativeArray';
import BaseRegeneratable from '../../login/regeneratable/BaseRegeneratable';
import CadConnection from '../CadConnection';
import remove = require('lodash/remove');

/**
 * An abstract base class for repositories that store cached data that was pushed
 * from the server via a WebSocket.
 */
export default class Repository<T> extends BaseRegeneratable {

    /**
     * The name of the event used by the Spillman server when sending push notifications.
     */
    public static DATA_FEED = 'dataFeed';

    private _models: T[] = [];
    private _modelMap: AssociativeArray<T> = {};
    private _readyDeferred: IDeferred<void>;

    /**
     * Constructs a new Repository.
     *
     * @param cadConnection The connection to the CAD data feed.
     * @param $rootScope The Angular $rootScope that can be used to listen to application events.
     * @param $q The angular service that handles creating and working with promises.
     * @param $log The Angular service that performs logging.
     * @param session The object that stores information about the current user's session.
     * @param transforms The array of transforms to apply to a model whenever it is added or modified.
     * @param config An object that contains configuration parameters for this repository.
     */
    constructor(private cadConnection: CadConnection,
        private $rootScope: IRootScopeService,
        $q: IQService,
        private $log: ILogService,
        private session: Session,
        private transforms: DecoratorTransform<T>[],
        private config: RepositoryConfig) {
        super($q);

        this._readyDeferred = $q.defer<void>();
    }

    /**
     * Gets the ordered array of models.
     */
    public get models(): T[] {
        return this._models;
    }

    /**
     * Gets the map that stores the models using each model's id as the key.
     * This map allows us to efficiently access an individual model.
     */
    public get modelMap(): AssociativeArray<T> {
        return this._modelMap;
    }

    /**
     * Gets a promise that will be resolved once this repository has received data.
     *
     * @returns {IPromise<void>} A promise that will be resolve once this repository
     *                           has received data from the server.
     */
    public get ready(): IPromise<void> {
        return this._readyDeferred.promise;
    }

    /**
     * Gets the name of this repository.
     *
     * @returns {string} The name of this repository.
     */
    public get name(): string {
        return this.config.repositoryName;
    }

    /**
     * Gets the name of the event that is broadcast when this repository has been updated.
     *
     * @returns {string} The name of the update event.
     */
    public get updateEventName(): string {
        return `Spillman:${this.name}:updated`;
    }

    // @Override
    protected performInitialization(): void {
        // Add a handler for receiving the data feed from the server.
        this.socket.on(Repository.DATA_FEED, this.receiveDataFeed);

        // Because the connection may be broken at any time and a new one started,
        // we must re-initiate the data feed each time the connection opens.
        this.socket.on('ws:open', this.initiateDataFeed);

        // The connection may already be open, in which case we missed the 'ws:open' event.
        // So we need to go ahead and initiate the data feed now.
        this.initiateDataFeedIfReady();
    }

    // @Override
    protected performDestruction(): void {
        // Create a new deferred here so that the `ready` promise can be used (and be valid)
        // even before `start` has been called.
        this._readyDeferred = this.$q.defer<void>();

        this.socket.off('ws:open', this.initiateDataFeed);
        this.socket.off(Repository.DATA_FEED, this.receiveDataFeed);

        // We need to broadcast that this repository was updated after clearing it.
        this.clear();
        this.$rootScope.$broadcast(this.updateEventName);
    }

    /**
     * Initiates the data feed if the connection is in the ready state.
     *
     * This method is protected so that derived classes can call it after
     * they have initialized their own fields.
     */
    private initiateDataFeedIfReady(): void {
        if (this.socket.isOpen) {
            this.initiateDataFeed();
        }
    }

    /**
     * Initiates the data feed by sending a message to the server.
     */
    private initiateDataFeed = (): void => {
        let sessionData = this.session.data;

        this.socket.emit(Repository.DATA_FEED, {
            // Type: C = calls, U = units
            type: this.config.dataFeedType,

            // User info
            agency: sessionData.agencyCode,
            user: sessionData.loginName,
            unit: sessionData.unitNumber,
            unitType: sessionData.unitType,
            zone: sessionData.zone,

            // Filtering
            // Note: we will not have the server do any client side filtering, but will instead
            // do filtering ourselves on the client. This is so that we have access to all calls
            // and units.
            callFilter: 0,
            callFilterValues: '',
            unitFilter: 0,
            unitFilterValues: '',

            // Server side filtering will be enforced so it behaves like Mobile
            useServerFilter: true,

            // Request all detail information
            detailId: '*'
        });
    };

    /**
     * Receives the data sent by the server in the data feed message.
     *
     * @param data The updated data.
     */
    private receiveDataFeed = (data: any): void => {
        let models = data[this.config.dataProperty];
        if (!models) {
            return;
        }

        // If the refresh flag is present, then that means that we're getting a complete refresh
        // of data from the server. So clear out all existing models before inserting the new ones.
        if (data[this.config.refreshFlag] !== undefined) {
            this.clear();
        }

        for (let i = 0; i < models.length; i++) {
            let model = models[i];
            let id = model[this.config.idProperty];

            // Ensure that the model has a primary key.
            if (!id) {
                this.$log.warn(`The following model has no primary key. It will be excluded from the ${this.config.repositoryName}`, model);
                continue;
            }

            let existingModel = this.modelMap[id];

            if (model.removed !== undefined) {
                this.deleteModel(id);
            } else if (existingModel) {
                this.updateModel(existingModel, model);
                this.transform(existingModel);
            } else {
                this.addModel(id, model);
                this.transform(model);
            }
        }

        this.$rootScope.$broadcast(this.updateEventName);
        this._readyDeferred.resolve();
    };

    /**
     * Clears out all models from the `models` array as well as the `modelMap`.
     */
    private clear(): void {
        // Clear out the array.
        this.models.length = 0;

        // Clear out the map.
        for (let prop in this.modelMap) {
            delete this.modelMap[prop];
        }
    }

    /**
     * Deletes an existing model from this repository.
     *
     * @param id The unique id of the model to delete.
     */
    private deleteModel(id: string): void {
        // Remove from the map.
        delete this.modelMap[id];

        // Remove from the array.
        remove(this.models, m => m[this.config.idProperty] === id);
    }

    /**
     * Updates an existing model.
     *
     * @param existingModel The existing model to update.
     * @param newModel The new model that contains updated information to add to the existing model.
     */
    private updateModel(existingModel: T, newModel: T): void {
        angular.extend(existingModel, newModel);
    }

    /**
     * Adds a model to this repository.
     *
     * @param id The unique id of the model.
     * @param model The new model to add.
     */
    private addModel(id: string, model: T): void {
        this.models.push(model);
        this.modelMap[id] = model;
    }

    /**
     * Applies all transforms to the model that was recently added or modified.
     *
     * @param model The model that was either added or modified.
     */
    private transform(model: T): void {
        this.transforms.forEach(t => t.invoke(model));
    }

    /**
     * Gets a reference to the cadConnection's socket.
     * An error is thrown if the cadConnection has not been initialized.
     *
     * @returns {PersistentWebSocket} A reference to the cadConnection's socket.
     */
    private get socket(): PersistentWebSocket {
        let socket = this.cadConnection.socket;
        if (!socket) {
            throw new Error(`The cadConnection must be initialized before the ${this.name}`);
        }
        return socket;
    }
}
