/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../../shared/database/decorators';

/**
 * A person's employer.
 */
@table('nmextra')
export class Employer {

    @field('emplyr')
    public name: string;

    @field('empphon')
    public phone: string;

    @field('empaddr')
    public address: string;
}
