/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import ComputedUrlResourceFactory from './computedUrl/ComputedUrlResourceFactory';
import MultiQueryResourceDecoratorFactory from './multiQuery/MultiQueryResourceDecoratorFactory';
import ResourceUtils from './ResourceUtils';
import IProvideService = angular.auto.IProvideService;
import IResourceServiceProvider = angular.resource.IResourceServiceProvider;
import ResourceArrayPagerLinker from './paging/ResourceArrayPagerLinker';

export const resourceModule = angular.module('resource', [])
    .config(['$provide', '$resourceProvider', ($provide: IProvideService, $resourceProvider: IResourceServiceProvider) => {
        $provide.constant('defaultResourceActions', Object.freeze($resourceProvider.defaults.actions));
    }])
    .factory('computedUrlResource', ComputedUrlResourceFactory)
    .factory('multiQueryResourceDecorator', MultiQueryResourceDecoratorFactory)
    .service('resourceUtils', ResourceUtils)
    .service('resourceArrayPagerLinker', ResourceArrayPagerLinker);
