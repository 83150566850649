/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import Location from './Location';

/**
 * A class that provides utility methods for working with maps and locations.
 */
export default class MappingUtilities {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['ionic'];

    /**
     * Constructs a new instance of the MappingUtilites class.
     *
     * @param ionic The ionic object that contains information about the current platform.
     */
    constructor(private ionic: IonicStatic) {
    }

    /**
     * Gets the platform specific protocol that will cause a link to be opened in the maps app
     * rather than the browser.
     *
     * @returns {string} The protocol (it may include other characters as needed).
     */
    public getMapsAppProtocol() {
        return this.ionic.Platform.device().platform === 'iOS' ? 'maps:q=' : 'geo:0,0?q=';
    }

    /**
     * Attempts to get a human-friendly address form the location.
     * Returns undefined if the location does not contain enough information to create the address.
     *
     * @param location The location from which to retrieve the human-friendly address.
     * @returns {string} The human-friendly address if the location contains enough information, undefined otherwise.
     */
    public tryGetHumanFriendlyAddress(location: Location): string {
        if (location) {
            // The zip code is ignored because it may be inaccurate due to the fact that in apcity
            // only one zip code is stored per city, whereas in reality a city may have multiple zip codes.
            if (location.streetAddress && location.city && location.state) {
                return `${location.streetAddress}, ${location.city}, ${location.state}`;
            }
        }
        return undefined;
    }

    /**
     * Attempts to get a comma-delimited latitude/longitude pair from the location.
     * Returns undefined if the location does not contain both a valid latitude and longitude.
     *
     * @param location The location from which to retrieve the latitude/longitude pair.
     * @returns {string} The comma-delimited latitude/longitude pair if both are valid, undefined otherwise.
     */
    public tryGetLatLngPair(location: Location): string {
        if (location && location.geoid && location.geoid !== 'NOGEO') {
            if (this.isFiniteNumber(location.latitude) && this.isFiniteNumber(location.longitude)) {
                return `${location.latitude},${location.longitude}`;
            }
        }
        return undefined;
    }

    /**
     * Attempts to get either a human-friendly address or a comma-delimited latitude/longitude pair.
     * If neither can be obtained, then undefined will be returned.
     *
     * @param location The location from which to retrieve either the address or the latitude/longitude pair.
     * @param preferLatLng (optional) This flag is only important if both the address and the latitude/longitude pair can be obtained.
     *                                If this flag is true, then the latitude/longitude pair will be returned.
     *                                If it's false, then the address will be returned. The default is false.
     */
    public tryGetAddressOrLatLng(location: Location, preferLatLng?: boolean): string {
        let address = this.tryGetHumanFriendlyAddress(location);
        let pair = this.tryGetLatLngPair(location);

        return preferLatLng ? pair || address : address || pair;
    }

    /**
     * Checks if the given number is a finite number.
     *
     * @param n The number to check.
     * @returns {boolean} True if the number is finite, false otherwise.
     */
    private isFiniteNumber(n: number): boolean {
        return angular.isNumber(n) && isFinite(n);
    }
}
