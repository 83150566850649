/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IAngularEvent = angular.IAngularEvent;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;
import ApplicationScope from '../../../ApplicationScope';

/**
 * The call history controller that acts as the go between for ionic/angularJS and the
 *  call history angular2 component.
 */
export class CallHistoryController {

    public static $inject = [
        '$scope',
        '$ionicScrollDelegate'
    ];

    /**
     * The text that is used to filter the items in the list.
     * Only those items that contain the text will remain.
     */
    public filterText: string;

    /**
     * A flag used to tell the call history component to refresh its list.
     */
    public refresh = false;

    /**
     * A flag used to tell the call history component to load the next page of results.
     */
    public loadNextPage = false;

    /**
     * Constructs a new instance of the CallHistoryController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param scrollDelegate The delegate that controls the ion-scroll directive.
     */
    constructor(
        private $scope: ApplicationScope,
        scrollDelegate: IonicScrollDelegate
    ) {
        this.$scope.$on('$ionicView.beforeEnter', (_event: IAngularEvent, viewData: any) => {

            // Don't re-request the data if the user hit the back button.
            // This allows the app to maintain the scroll position of lists.
            if (viewData.direction !== 'back') {
                scrollDelegate.scrollTop();
                this.requestData();
            }
        });
    }

    /**
     * Notify the call history component to refresh its list.
     */
    public requestData(): void {
        this.refresh = !this.refresh;
    }

    /**
     * Event handler for when the list data refresh is complete.
     */
    public refreshComplete(_value: boolean): void {
        // turn off the ionic refresh animation
        this.$scope.$broadcast('scroll.refreshComplete');
    }

    /**
     * Notify the call history component to load the next page of results.
     */
    public requestNextPage(): void {
        this.loadNextPage = !this.loadNextPage;
    }

    /**
     * Event handler for when the next page has been loaded.
     */
    public nextPageLoaded(_value: boolean): void {
        // turn off the ionic refresh animation
        this.$scope.$broadcast('scroll.infiniteScrollComplete');
    }

    public $onInit = () => {};
}
