/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import OtherPageController from './OtherPageController';

export const otherModule = angular.module('other', [])

    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.other', {
                url: '/other',
                abstract: true,
                views: {
                    other: {
                        template: '<ion-nav-view></ion-nav-view>'
                    }
                }
            })
            .state('app.other.otherDetails', {
                url: '/details',
                template: require('./details.html'),
                controller: 'otherPageController'
            });
    }])
    .controller('otherPageController', OtherPageController);
