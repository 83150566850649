/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';

/**
 * The civil process type model
 */
@table('cptypes')
export class CivilProcessType {

    @expand('cptype', 'desc')
    public type: string;
}
