/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IRootScopeService = angular.IRootScopeService;
import ILogService = angular.ILogService;
import Setting from '../settings/Setting';
import SettingKey from '../settings/SettingKey';
import Theme from './Theme';

export const THEME = 'theme';

/**
 * A class that provides the ability to change the app's CSS theme.
 */
export class ThemeChanger {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$rootScope', 'themeSetting', '$log'];

    /**
     * Constructs a new instance of the ThemeChanger.
     *
     * @param $rootScope The Angular $rootScope that can be used to listen to application events.
     * @param themeSetting The setting that determines which CSS theme is currently used.
     * @param $log The Angular service that performs logging.
     */
    constructor(private $rootScope: IRootScopeService,
        private themeSetting: Setting<Theme>,
        private $log: ILogService) {

        // Listen for the theme setting to change, and then update the app's theme accordingly.
        $rootScope.$on(`${Setting.SETTING_CHANGED}:${SettingKey.themeSetting}`, () => this.setTheme());
    }

    /**
     * Sets the app's theme.
     *
     * @param theme (optional) The enum value that specifies which theme to use.
     *              If not specified, then the current value of the theme setting is used.
     */
    public setTheme(theme?: Theme): void {
        // If a theme isn't explicitly specified, then check the setting.
        if (!angular.isNumber(theme)) {
            theme = this.themeSetting.value;
        }

        // Enums in TypeScript are a two-way map: the number maps to the name and the name maps to the number.
        // Here we're getting the name using the number.
        let themeName = Theme[theme];

        if (themeName) {
            this.$rootScope[THEME] = themeName;
        } else {
            this.$log.warn(`Invalid theme: ${theme}. Theme not changed.`);
        }
    }
}
