/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import StandardDateOptions from './StandardDateOptions';
import PermissionName from '../permissions/enums/PermissionName';
import SearchingController from './SearchingController';
import { upgradeAdapter } from '../upgrade-adapter';

export const searchingModule = angular.module('searching', [])

    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.search', {
                url: '/search',
                abstract: true,
                views: {
                    search: {
                        template: '<ion-nav-view></ion-nav-view>'
                    }
                },
                data: {
                    permissions: { or: true, permissions: [PermissionName.names, PermissionName.vehicle, PermissionName.property, PermissionName.law, PermissionName.fire, PermissionName.ems] }
                }
            })
            .state('app.search.forms', {
                url: '',
                template: require('./search.html'),
                controller: 'searchingController as vm'
            })
            .state('app.search.forms.names', {
                url: '/names',
                data: {
                    permissions: [PermissionName.names],
                    homePageIndex: 3
                }
            })
            .state('app.search.forms.vehicles', {
                url: '/vehicles',
                data: {
                    permissions: [PermissionName.vehicle],
                    homePageIndex: 4
                }
            })
            .state('app.search.forms.properties', {
                url: '/properties',
                data: {
                    permissions: [PermissionName.property],
                    homePageIndex: 5
                }
            })
            .state('app.search.forms.incidents', {
                url: '/incidents',
                data: {
                    permissions: { or: true, permissions: [PermissionName.law, PermissionName.fire, PermissionName.ems] },
                    homePageIndex: 6
                }
            });
    }])

    .value('standardDateOptions', StandardDateOptions)

    .controller('searchingController', SearchingController);

upgradeAdapter.upgradeNg1Provider('standardDateOptions');
