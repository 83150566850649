/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import RadioLog from '../schema/RadioLog';
import ComputedUrlResource from '../resource/computedUrl/ComputedUrlResource';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
RadioLogAPI.$inject = ['computedUrlResource'];

/**
 * A factory function that creates an API for retrieving radio logs from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @returns {IResourceClass<RadioLog>} A resource class that knows how to load Radio Logs from the REST server.
 */
function RadioLogAPI(computedUrlResource: ComputedUrlResource): IResourceClass<RadioLog> {
    return computedUrlResource<RadioLog>({ path: '/tables/rlmain' });
}

export default RadioLogAPI;
