/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../shared/database/decorators';

/**
 * The jail booking reference model.
 */
@table('jlbook')
export class JailBookingReference {

    @field('num')
    public id: string;
}
