/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import DecoratorTransform from '../../../../shared/transforms/DecoratorTransform';
import AggregatorCadCall from '../../../../schema/AggregatorCadCall';
import Session from '../../../../login/Session';

/**
 * A transform that adds methods, related to Units, to a given CAD Call.
 */
export default class CallUnitMethodsTransform implements DecoratorTransform<AggregatorCadCall> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['session'];

    /**
     * Constructs a new instance of the CallUnitMethodsTransform class.
     *
     * @param session The object that stores information about the current user's session.
     */
    constructor(private session: Session) {
    }

    // @Override
    public invoke(call: AggregatorCadCall): void {
        if (!call.tryGetUnit) {
            call.tryGetUnit = (unitNumber: string) => !unitNumber ? undefined : call.allUnits.find(u => u.unit === unitNumber);
        }

        if (!call.tryGetMyUnit) {
            call.tryGetMyUnit = () => {
                if (this.session.exists()) {
                    return call.tryGetUnit(this.session.data.unitNumber);
                }
                return undefined;
            };
        }
    }
}
