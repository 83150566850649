/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import { upgradeAdapter } from '../upgrade-adapter';
import { VersionService } from './versioning/version.service';
import { ConditionalServiceLoader } from './conditional-service-loader.service';
import { PlatformDetector } from './platform-detection/platform-detector.service';

export const conditionalInjectionModule = angular.module('conditionalInjection', [])
    .factory('platformDetector', upgradeAdapter.downgradeNg2Provider(PlatformDetector))
    .factory('versionService', upgradeAdapter.downgradeNg2Provider(VersionService))
    .factory('conditionalServiceLoader', upgradeAdapter.downgradeNg2Provider(ConditionalServiceLoader));
