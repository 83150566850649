/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import SessionData from './SessionData';
import OfficerName from '../schema/OfficerName';
import UnitLongTerm from '../schema/UnitLongTerm';
import Credentials from './Credentials';

/**
 * A class that contains information about the logged-in user.
 */
export class Session {

    private _data: SessionData;

    /**
     * Creates a new session using the given credentials and user.
     *
     * @param credentials The credentials with which the user logged in.
     * @param user An object that represents the user.
     * @param unit An object which represents the user's CAD unit.
     */
    public create(credentials: Credentials, user: OfficerName, unit: UnitLongTerm): void {
        this._data = new SessionData(credentials, user, unit);
    }

    /**
     * Destroys the session by setting the stored data to undefined.
     */
    public destroy(): void {
        this._data = undefined;
    }

    /**
     * Determines if a session exists. This is determined by whether
     * or not the user's data exists.
     *
     * @returns {boolean} true if a session exists, false otherwise.
     */
    public exists(): boolean {
        return !!this._data;
    }

    /**
     * Gets the session data. This will throw an error if the session doesn't exist.
     *
     * @returns {SessionData} The stored session data.
     */
    public get data(): SessionData {
        if (!this.exists()) {
            throw Error('Cannot access data, because no session exists');
        }
        return this._data;
    }
}

export default Session;
