/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { BaseRequestOptions, Headers, RequestOptions, RequestOptionsArgs } from '@angular/http';

/**
 * Extends the BaseRequestOptions and overrides the `merge` method so that
 * the headers are merged rather than simply overwritten.
 */
export class ImprovedMergeBaseRequestOptions extends BaseRequestOptions {

    /**
     * @inheritdoc
     * Enables the merge function to better handle the merging of Http headers.
     */
    public merge(options?: RequestOptionsArgs): RequestOptions {
        const optionsCopy = Object.assign({}, options);

        if (options && options.headers) {
            optionsCopy.headers = this.mergeHeaders(this.headers || new Headers(), options.headers);
        }

        return this.createFinalOptions(optionsCopy);
    }

    /**
     * Merges the provided headers into a single value.
     *
     * @param defaultHeaders The headers which are already present on RequestOptions.
     * @param headers The headers which have been passed into the merge function.
     * @returns A Header containing the merged result of the two header values.
     */
    private mergeHeaders(defaultHeaders: Headers, headers: Headers): Headers {
        const finalHeaders = new Headers();

        defaultHeaders.forEach((values, name) => values.forEach(v => finalHeaders.append(name, v)));

        headers.forEach((values, name) => {
            if (finalHeaders.has(name)) {
                finalHeaders.delete(name);
            }
            return values.forEach(v => finalHeaders.append(name, v));
        });

        return finalHeaders;
    }

    /**
     * Creates the final request options object for use in a request.
     *
     * @param copy The copy of the options object used for the request.
     * @returns An Object which has all combined properties.
     */
    private createFinalOptions(copy: RequestOptionsArgs): ImprovedMergeBaseRequestOptions {
        const mergedOptions = super.merge(copy);
        const finalOptions = new ImprovedMergeBaseRequestOptions();

        for (let property in finalOptions) {
            if (mergedOptions.hasOwnProperty(property)) {
                finalOptions[property] = mergedOptions[property];
            }
        }

        return finalOptions;
    }
}
