/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import { VehicleReference } from '../../model/vehicle-reference';
import { JoinPipe } from '../../../../shared/pipes';

/**
 * A component that displays the standard vehicle text.
 */
@Component({
    selector: 'sds-vehicle-display',
    template: `
        <span *ngIf="vehicle">{{vehicleDescription}}</span>
        <span *ngIf="!vehicle">&nbsp;</span>
    `
})
export class VehicleDisplayComponent {

    /**
     * The vehicle to display.
     */
    @Input() public vehicle: VehicleReference;

    /**
     * Constructs a new instance of the VehicleDisplayComponent class.
     *
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     */
    constructor(
        private joinPipe: JoinPipe
    ) {
    }

    /**
     * Gets the vehicle description
     *
     * @returns The description of the vehicle.
     */
    public get vehicleDescription(): string {
        return typeof this.vehicle === 'string' ? this.vehicle :
            this.joinPipe.transform([
                this.vehicle.color,
                this.vehicle.year || '',
                this.vehicle.make,
                this.vehicle.model
            ], ' ') || this.vehicle.id;
    }
}
