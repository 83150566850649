/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IIntervalService = angular.IIntervalService;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;
import ColorAssigner from '../../ColorAssigner';
import CallRepository from '../repository/CallRepository';
import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import CADListController from '../../CADListController';
import ApplicationScope from '../../../ApplicationScope';
import CallFilterSetting from '../../../settings/implementations/CallFilterSetting';
import { AddressAlertsBatchRequest } from '../../../api/address-alerts/address-alerts-batch-request.service';
import PermissionsRepository from '../../../permissions/PermissionsRepository';
import { NotificationService } from '../../../notifications/notification-service';

/**
 * The controller for the Call List page.
 */
export default class CallListController extends CADListController {
    public static $inject = [
        '$scope',
        'colorAssigner',
        'addressAlertsBatchRequest',
        '$interval',
        'callRepository',
        'callListDisplayProperties',
        'callFilterSetting',
        '$ionicScrollDelegate',
        'permissionsRepository',
        'notificationService'
    ];

    /**
     * Indicates if the user has access to the calls history screen.
     */
    public callHistoryAccess: boolean;

    public updatedCallsId: Array<string> = [];

    /**
     * Constructs a new instance of the CallListController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param colorAssigner The object that assigns a color based on the status of the CAD call.
     * @param addressAlertsBatchRequest The service that retrieves the address alerts for all cad calls.
     * @param $interval The Angular service that repeatedly performs some action on a regular interval.
     * @param callRepository The repository that stores CAD calls pushed from the server.
     * @param callListDisplayProperties The names of the properties that are displayed on the screen.
     * @param callFilterSetting The setting that determines which calls are shown.
     * @param $ionicScrollDelegate A delegate used to control the ion-scroll directive.
     * @param permissionsRepository A repository which stores permission objects.
     */
    constructor(
        $scope: ApplicationScope,
        colorAssigner: ColorAssigner,
        addressAlertsBatchRequest: AddressAlertsBatchRequest<AggregatorCadCall>,
        $interval: IIntervalService,
        private callRepository: CallRepository,
        private callListDisplayProperties: string[],
        public callFilterSetting: CallFilterSetting,
        $ionicScrollDelegate: IonicScrollDelegate,
        permissionsRepository: PermissionsRepository,
        public notificationService: NotificationService
    ) {
        super($scope, colorAssigner, addressAlertsBatchRequest, $interval, callRepository.updateEventName, $ionicScrollDelegate);
        this.callHistoryAccess = permissionsRepository.checkStatePermissions('app.search.callsHistory');

        $scope.$watch(() => {
            return notificationService.missedUpdatesList;
        }, (newVal: any, _oldVal: any) => {
            const activeCall = location.href.split('/')[location.href.split('/').length - 1];
            if (newVal) {
                if (activeCall !== Object.keys(newVal)[0]) {
                    this.updatedCallsId.push(...Object.keys(newVal));
                    this.checkIfUpdatedCallExist();
                }
            }
        }, true);
    }

    public getUpdateType(call: any) {
        const updatedCall = this.notificationService.missedUpdatesList[call.callIdAndType];

        if (updatedCall) {
            return updatedCall.assign ? 'assigned-call' : 'updated-call';
        } else {
            return;
        }
    }

    public onRedirect(call: any) {
        this.updatedCallsId.splice(this.updatedCallsId.indexOf(call.callIdAndType), 1);
        this.notificationService.updatedCallId = '';
    }

    /**
     * Gets the array of all calls.
     *
     * @returns The array of all calls.
     */
    public get calls(): AggregatorCadCall[] {
        return this.callRepository.models;
    }

    // @Override
    public get displayProperties(): string[] {
        return this.callListDisplayProperties;
    }

    public $onInit = () => {};

    // @Override
    protected getCalls(): AggregatorCadCall[] {
        return this.calls;
    }

    private checkIfUpdatedCallExist() {
        if (this.calls) {
            const calls = this.calls.map(item => item.callIdAndType);
            const missedCallsUpdates = Object.keys(this.notificationService.missedUpdatesList);
            missedCallsUpdates.forEach(id => {
                if (calls.indexOf(id) === -1) {
                    delete this.notificationService.missedUpdatesList[id];
                }
            });
        }
    }
}
