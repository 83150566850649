/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * showIonicBackButton The method used to show the back button and overwrite
 * the default behavior of the Ionic Framework
 */

export function showIonicBackButton(): void {
    setTimeout(() => {
        const navBar = document.querySelector('div[nav-bar=active]');
        if (navBar) {
            const backBtn = navBar.getElementsByClassName('back-button')[0];
            if (backBtn) {
                backBtn.className = backBtn.className.replace('hide', '');
            }
        } else {
            showIonicBackButton();
        }
    }, 250);
}
