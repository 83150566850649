/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Credentials } from './credentials';

/**
 * A class that stores the credentials with which the user authenticated..
 */
@Injectable()
export class CredentialsRepository {

    private _credentials: Credentials;
    private initialized = false;

    /**
     * The data used to log into the application.
     */
    public get credentials(): Credentials {
        this.assertInitialized();
        return this._credentials;
    };

    /**
     * Initializes this class with the credentials.
     *
     * @param credentials The credentials with which the user logged in.
     */
    public initialize(credentials: Credentials): void {
        this._credentials = credentials;
        this.initialized = true;
    }

    /**
     * Asserts that the session has been initialized.
     */
    private assertInitialized(): void {
        if (!this.initialized) {
            throw new Error('The session has not been initialized');
        }
    }
}
