/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * The disposition model
 */
@table('jlicdsdt')
export class Disposition {

    @expand('code', 'descrpt')
    public description: string;

    @field('disdate')
    public date: Date;
}
