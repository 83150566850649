/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAttributes = angular.IAttributes;
import INgModelController = angular.INgModelController;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
PortDirective.$inject = <any>[];

/**
 * Creates a directive that will validate that some data is a valid port.
 *
 * @returns {IDirective} A directive that validates that the data is a valid port.
 */
export default function PortDirective(): IDirective {
    let positiveIntegerRegex = /^[0-9]+$/;
    let minPort = 1;
    let maxPort = 65535;

    return <IDirective> {
        require: '?ngModel',
        restrict: 'A',
        link: function (_scope: IScope, _element: IAugmentedJQuery, _attributes: IAttributes, $modelController: INgModelController) {
            if (!$modelController) {
                return;
            }

            let lastValidPort = '';

            /**
             * Restores the last valid port.
             *
             * @returns {string} The last valid port.
             */
            function restoreValidPort(): string {
                $modelController.$setViewValue(lastValidPort);
                $modelController.$render();
                return lastValidPort;
            }

            /**
             * Validates the new value entered by the user to determine if it is a valid port.
             * If it is not a valid port, then the last valid port will be restored.
             *
             * @param viewValue The new value entered by the user.
             * @returns {string} The given viewValue if it is a valid port, otherwise the last known valid port.
             */
            function validateNewValue(viewValue: string): string {
                // An empty port will be considered valid, since it is not the job of this
                // directive to validate whether the port is required or not.
                if (!$modelController.$isEmpty(viewValue)) {

                    // Check that it can be converted to a number.
                    if (!positiveIntegerRegex.test(viewValue)) {
                        return restoreValidPort();
                    }

                    let portNumber = Number(viewValue);

                    // Check that it is in the correct range.
                    if (portNumber < minPort || portNumber > maxPort) {
                        return restoreValidPort();
                    }
                }

                lastValidPort = viewValue;
                return viewValue;
            }

            $modelController.$parsers.unshift(validateNewValue);
        }
    };
}
