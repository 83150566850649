/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Injector } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { AuthenticationTokenService } from './authentication-token.service';
import { Credentials } from './credentials';
import { AuthenticationParameterService } from './parameters/authentication-parameter.service';
import { UrlFactory } from './url';
import { VersionService } from '../../app/conditional-injection/versioning/version.service';
import { logIfLoginIsVerbose, logErrorIfLoginIsVerbose } from '../../app/api/analytics/firebase-crashlytics-service';

/**
 * An associative array of error messages that this class may return.
 */
export const errorMessages = {
    default: 'Login failed',
    status400: 'Invalid username or password'
};

/**
 * A map that maps a status code to a custom error message.
 */
export const statusToErrorMessageMap = new Map<number, string>([
    [400, errorMessages.status400]
]);

/**
 * An implementation of the the AuthenticationTokenService that retrieves a token from the Spillman API.
 */
@Injectable()
export class ApiAuthenticationTokenService implements AuthenticationTokenService<Credentials> {
    private versionService: VersionService;

    private MIN_VERSION_NEW_TOKEN_ENDPOINT = '2024.1.0';

    /**
     * Constructs a new instance of the AuthenticationTokenService.
     */
    constructor(
        private http: Http,
        private urlFactory: UrlFactory,
        private parameterService: AuthenticationParameterService,
        private injector: Injector
    ) {
    }

    /**
     * @inheritdoc
     */
    public getToken(credentials: Credentials): Observable<string> {
        if (!this.versionService) {
            this.versionService = this.injector.get(VersionService) as VersionService;
        }

        return this.getOldToken(credentials);
    }

    public getOldToken(credentials: Credentials): Observable<string> {
        logIfLoginIsVerbose('getOldToken request');

        const url = this.urlFactory.create({
            server: credentials.server,
            port: credentials.port,
            secureConnection: credentials.secureConnection,
            path: '/oauth2/token'
        });

        const params = this.parameterService.getParameters(credentials);

        const headers = new Headers();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');

        return this.http.post(url, params, { headers })
            .timeout(20 * 1000)
            .map(response => `Bearer ${response.json().access_token}`)
            .catch(err => Observable.throw(this.getErrorMessage(err)));
    }

    public getNewToken(credentials: Credentials): Observable<string> {
        logIfLoginIsVerbose('getNewToken request');

        const url = this.urlFactory.create({
            server: credentials.server,
            port: credentials.port,
            secureConnection: credentials.secureConnection,
            path: '/../token'
        });

        const params = this.parameterService.getParameters(credentials, true);

        const headers = new Headers();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');

        return this.http.post(url, params, { headers })
            .timeout(20 * 1000)
            .map(response => {
                if (response.text().includes('HTTP Status: 401')) {
                    throw Error('Authentication required for the requested URL.');
                }
                if (response.text().includes('400 Bad Request')) {
                    throw Error('400');
                }
                return `Bearer ${response.json().access_token}`;
            })
            .catch(err => Observable.throw(this.getErrorMessage(err)));
    }

    /**
     * A helper method that converts the a raw error to a human-friendly error message.
     *
     * @param error The raw error.
     * @returns The human-friendly error message.
     */
    private getErrorMessage(error: any): string {
        logErrorIfLoginIsVerbose('Authentication token error', error);

        if (error instanceof Response && typeof error.status === 'number') {
            return statusToErrorMessageMap.get(error.status) || errorMessages.default;
        }

        if (error instanceof Error && error.message.includes('400')) {
            return statusToErrorMessageMap.get(400) || errorMessages.default;
        }

        return errorMessages.default;
    }
}
