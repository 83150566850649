/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * The permission attributes which determine a user's access.
 */
enum PermissionAttribute {
    delete,
    modify,
    read,
    add,
    access
}

export default PermissionAttribute;
