/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import { Property } from './model/property';
import { PropertyLocalId } from './model/property-local-id';
import { JoinPipe } from '../../../shared/pipes';
import { DatabaseService } from '../../../shared/database/database.service';
import { SlideSourceInfo } from '../../../schema/SlideSourceInfo';
import { Characteristic } from './model/characteristic';

/**
 * A component that displays the details of a property.
 */
@Component({
    selector: 'sds-property-detail',
    template: require('./property-detail.component.html')
})
export class PropertyDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * The property to display.
     */
    public property: Property;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading = false;

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        this.commentsSlideSourceInfo,
        ['Custody', { srcHtml: 'app/rms/property/detail/slides/custody-changes.html' }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * Constructs a new instance of the PropertyDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     * @param commentsSlideSourceInfo The SlideSourceInfo for the comments slide.
     * @param involvementsSlideSourceInfo The SlideSourceInfo for the involvements slide.
     * @param changeDetectorRef The ChangeDetectorRef to detect changes to update the view.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        private joinPipe: JoinPipe,
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Format functions needed to display a list of items correctly inside of a popup.
     */
    public localIdFormatter = (localId: PropertyLocalId) => this.joinPipe.transform([localId.type, localId.id], ' - ');
    public characteristicFormatter = (characteristic: Characteristic) => characteristic.description;

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(Property, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(
                detailModel => this.property = detailModel,
                () => this.onError.emit(),
                () => {
                    this.finishedLoading = true; this.changeDetectorRef.detectChanges(); 
                }
            );
    }
}
