/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import Expand from '../../schema/interfaces/Expand';

/**
 * A filter that displays a code and description with a separator in between.
 *
 * Examples:
 *   BLK - Black (tbcolor)
 *   AZ - Arizona (apstate)
 *   BIGA - Bigamy (tboff)
 */
export default class CodeDescriptionFilter {

    /**
     * Displays the code and description contained within the object.
     *
     * @param {string|Expand} object The object that potentially contains both a code and description.
     * @param {string} descriptionPropertyName The name of the description property.
     * @param {string} separator The string that will be used to separate the code and description.
     * @returns {string} The formatted code and description.
     */
    public display(object: string|Expand, descriptionPropertyName: string = 'desc', separator: string = ' - '): string {
        if (this.isExpand(object)) {
            const code = object.value;
            const description = object.fields[descriptionPropertyName];
            return description ? (code + separator + description) : code;
        } else {
            return object;
        }
    }

    /**
     * A type guard that checks whether or not the object is an Expand.
     *
     * @param object The object to check.
     * @returns {boolean} True if the object is an Expand, false otherwise.
     */
    private isExpand(object: any): object is Expand {
        return !!object && typeof object.value === 'string' && typeof object.table === 'string' && !!object.fields;
    }
}
