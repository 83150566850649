/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { IncludeOneNode } from '../../nodes/property/include-one-node';
import { ModelCreationData, ModelPair } from '../model-creation-data';
import { TablesApiHelper } from '../tables-api-helper';

/**
 * Performs a "visit" on an IncludeOneNode during the process of creating the human-friendly model.
 */
@Injectable()
export class IncludeOneNodeModelCreationVisitor implements TypeSpecificTreeNodeVisitor<IncludeOneNode, ModelCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [IncludeOneNode];

    /**
     * Constructs a new instance of the IncludeOneNodeModelCreationVisitor class.
     *
     * @param tablesApiHelper A helper that knows how to work with the database models returned by the Tables API.
     */
    constructor(private tablesApiHelper: TablesApiHelper) {
    }

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: IncludeOneNode, data: ModelCreationData): void {
        const newPairs: ModelPair[] = [];

        for (let { databaseModel } of data.modelPairs.peek()) {
            const joins = this.tablesApiHelper.getJoinsByTableName(databaseModel, node.table);

            if (joins.length > 1) {
                throw new Error(`The ${node.parent.classConstructor.name}.${node.propertyKey as string} property must use the @includeMany decorator because there can be many models of type ${node.table}`);
            }

            const join = joins[0];
            newPairs.push({ databaseModel: join ? join.fields : undefined });
        }
        data.modelPairs.push(newPairs);
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(node: IncludeOneNode, data: ModelCreationData): void {
        const childPairs = data.modelPairs.pop();
        const parentPairs = data.modelPairs.peek();

        for (let i = 0; i < childPairs.length; i++) {
            parentPairs[i].humanFriendlyModel[node.propertyKey] = childPairs[i].humanFriendlyModel;
        }
    }
}
