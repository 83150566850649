/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { RequestArgs } from '@angular/http/src/interfaces';
import { RequestCreator } from '../request-creator.service';
import { BatchRequestConfig } from '../batch-request/batch-request-config';
import { UrlFactory } from '../../authentication';

/**
 * Creates the request for the AddressAlertsBatchRequest service.
 * The request will load the address alerts for an entire list of cad calls.
 */
@Injectable()
export class AddressAlertsRequestCreator extends RequestCreator<BatchRequestConfig> {

    /**
     * Constructs a new instance of the AddressAlertRequestCreator class.
     *
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     */
    constructor(urlFactory: UrlFactory) {
        super(urlFactory);
    }

    /**
     * @inheritdoc
     */
    public create(config: BatchRequestConfig): RequestArgs {
        const url = this.urlFactory.create({
            path: '/tables/gbadalrt'
        });

        const search = new URLSearchParams();
        search.set('filter', `number=${config.delimitedKeys}`);
        search.set('expand', 'code');

        return { url, search };
    }
}
