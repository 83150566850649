/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAttributes = angular.IAttributes;

const SDS_IF_VALID_PHONE_NUMBER = 'sdsIfValidPhoneNumber';
const NG_IF = 'ngIf';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
IfValidPhoneNumberDirective.$inject = ['ngIfDirective'];

/**
 * A factory function that creates a directive that will conditionally show/hide an element
 * if the expression is a valid phone number.
 *
 * @param ngIfDirective The ngIf directive definition to which to delegate.
 * @returns {IDirective} A directive that will conditionally show/hide an element
 *                       if the expression is a valid phone number.
 */
function IfValidPhoneNumberDirective(ngIfDirective: any[]): IDirective {

    /**
     * The ngIf directive is actually an array. The first element is what we want.
     */
    const ngIf = ngIfDirective[0];

    /**
     * The regex that determines if the phone number is valid.
     *
     * For simplicity, we are just checking that the phone number contains at least one digit.
     * This handles the case where the server only sends the formatting: "()- ".
     */
    const validPhoneNumberRegex = /\d+/;

    // We are extending the ngIf directive and overriding the `link` function so that it will have
    // built into it the logic that checks if the phone number is valid. The idea was taken from
    // http://stackoverflow.com/questions/20325480/angularjs-whats-the-best-practice-to-add-ngif-to-a-directive-programmatically
    return angular.extend({}, ngIf, {

        // We don't want ngIf's name or compile function.
        name: undefined,
        compile: undefined,

        link: function (scope: IScope, _element: IAugmentedJQuery, attributes: IAttributes) {
            let expression = attributes[SDS_IF_VALID_PHONE_NUMBER];

            // Here we are programmatically assigning the watch function that ngIf will use.
            attributes[NG_IF] = () => {
                let phoneNumber = scope.$eval(expression);
                return validPhoneNumberRegex.test(phoneNumber);
            };

            ngIf.link.apply(ngIf, arguments);
        }
    });
}

export default IfValidPhoneNumberDirective;
