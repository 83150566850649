/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Observable } from 'rxjs/Observable';

/**
 * An abstract class which represents the session timeout in minutes for a user.
 */
export abstract class SessionTimeout {

    /**
     * The number of minutes before the user is logged out.
     */
    private _sessionTimeout: number;

    /**
     * Gets the session timeout.
     */
    public get sessionTimeout(): number {
        return this._sessionTimeout;
    }

    /**
     * A method which initializes this service.
     */
    public initialize(): Observable<any> {
        return this.getSessionTimout()
            .map(this.setSystemTimeout)
            .onErrorResumeNext(Observable.of(this.setSystemTimeout(30)));
    }

    /**
     * @returns An Observable that when resolved retrieves the session timeout in minutes.
     */
    protected abstract getSessionTimout(): Observable<any>;

    /**
     * Sets the session timeout in minutes.
     *
     * @param minutes - The number of minutes.
     */
    private setSystemTimeout = (minutes: number) => this._sessionTimeout = minutes * 60 * 1000;
}
