/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AgencyModel } from './agencyModel';
import Session from '../../../login/Session';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { CacheExpireService } from '../../../../app/shared/services/cacheExpireService';

/**
 * A service for retrieving values from the "apagncy", which is a
 * table that stores agency data.
 */
@Injectable()
export class AgencyService {
    private readonly AGENCY_TABLE = 'apagncy';

    public isLoading: Observable<boolean>;
    private isLoadingBehavior: BehaviorSubject<boolean>;

    /**
     * Constructs a new instance of the AgencyService class.
     *
     * @param cacheService The service that request cache requests.
     */
    constructor(
        private cacheExpireService: CacheExpireService,
        @Inject('session') private session: Session
    ) {
        this.isLoadingBehavior = new BehaviorSubject(false);
        this.isLoading = this.isLoadingBehavior.asObservable();
    }

    /**
     * Gets the codes/descriptions that match the text from the specified database table.
     *
     * @param text The text to match.
     * @param format A sprintf-style format string that determines how the code and description are combined.
     * @returns An Promise that contains the Agency Models.
     */
    public async getAgencies(text?: string, format?: string): Promise<AgencyModel[]> {
        try {
            this.isLoadingBehavior.next(true);

            const agenciesJson = await this.cacheExpireService.getCachedData(this.AGENCY_TABLE, text, format);
            const agencies = this.toAgencyModel(agenciesJson);
            return agencies;
        } finally {
            this.isLoadingBehavior.next(false);
        }
    }

    private toAgencyModel(data: any[]): AgencyModel[] {
        let agencies: AgencyModel[] = [];

        for (const agency of data) {
            agencies.push(AgencyModel.createModelFromObject(agency));
        }

        agencies = AgencyModel.orderListByAbbr(agencies);

        return agencies;
    }
}
