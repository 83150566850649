/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * The supplemental narrative model
 */
@table('jlicsupp')
export class Supplement {

    @field('num')
    public id: string;

    @expand('officer', 'fullnam')
    public officer: string;

    @field('addtime')
    public date: Date;

    @field('text')
    public narrative: string;
}
