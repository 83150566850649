/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Type } from '@angular/core';
import { injectionConditionMetadataKey } from '../injection-condition.decorator';
import { InjectionCondition } from '../injection-condition';

/**
 * A base class for filters that help determine which service to inject based on certain conditions.
 *
 * @template P The property from the InjectionCondition that this filter handles.
 * @template T The type of parsed metadata used by this filter.
 */
export abstract class ConditionalInjectionFilter<P extends keyof InjectionCondition, T> {

    /**
     * The name of the property that this filter checks.
     */
    protected abstract readonly propertyName: P;

    /**
     * The default value to use if no other value is specified in the @injectionCondition decorator.
     * The default value should be as inclusive as possible (e.g. all platforms, all versions, etc).
     */
    protected abstract readonly defaultValue: T;

    /**
     * Filters the service types.
     *
     * @param serviceTypes The array of all service types to filter.
     * @returns Those service types that pass the filter.
     */
    public filter(serviceTypes: Type<any>[]): Type<any>[] {
        const rawTuples = serviceTypes.map<[InjectionCondition[P], Type<any>]>(s => [this.getRawMetadata(s), s]);

        // If the metadata is undefined for all tuples, then short-circuit the filtering and just return
        // all of the service types, because (as far as this filter is concerned) there is no constraint on the services.
        if (rawTuples.every(t => t[0] === undefined)) {
            return serviceTypes;
        }

        const tuples = rawTuples.map<[T, Type<any>]>(t => [this.parse(t[0]), t[1]]);
        return this.doFiltering(tuples).map(t => t[1]);
    }

    /**
     * Parses the raw value from the InjectionCondition.
     *
     * @param metadata The raw metadata obtained from the InjectionCondition.
     * @returns The parsed value which may be of a different type than the raw value.
     */
    protected abstract doParsing(metadata: InjectionCondition[P]): T;

    /**
     * Performs the actual filtering, given the metadata in addition to the service type.
     *
     * @param tuples An array of tuples that store both the metadata as well as the service type.
     * @returns Those tuples that pass the filter.
     */
    protected abstract doFiltering(tuples: [T, Type<any>][]): [T, Type<any>][];

    /**
     * Gets a single property value from the metadata associated with the service type.
     *
     * @param serviceType The service type for which to retrieve the metadata.
     * @returns The value that corresponds to the `propertyName` in the metadata.
     */
    private getRawMetadata(serviceType: Type<any>): InjectionCondition[P] {
        const conditionObject: InjectionCondition = Reflect.getMetadata(injectionConditionMetadataKey, serviceType) || {};
        return conditionObject[this.propertyName];
    }

    /**
     * Parses the raw metadata in order to convert it to a form that is easier for comparing.
     *
     * @param metadata The raw metadata.
     * @returns The parsed data which is in a form that is easier to compare.
     */
    private parse(metadata: InjectionCondition[P]): T {
        return metadata ? this.doParsing(metadata) : this.defaultValue;
    }
}
