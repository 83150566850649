/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResource = angular.resource.IResource;
import IResourceArray = angular.resource.IResourceArray;
import PropertyChangedQuery from '../../../../shared/PropertyChangedQuery';

/**
 * The base class for all Call queries.
 */
abstract class CallQuery<T extends IResource<any> | IResourceArray<any>> extends PropertyChangedQuery<T> {
    // @Override
    protected get objectName(): string {
        return 'call';
    }
}

export default CallQuery;
