/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { ClassNode } from './class-node';
import { Constructor } from '../../../../interfaces/constructor';
import { PropertyNodeFactory } from '../property/property-node-factory';
import { TreeNode } from '../../../../data-structures/tree-node';
import { declaredPropertiesMetadataKey } from '../../../decorators';

/**
 * A node that associates a particular database table with a JavaScript class.
 */
export class TableNode extends ClassNode {

    /**
     * Constructs a new instance of the TableNode class.
     *
     * @param classConstructor The constructor of the class that this node represents.
     * @param table The database table that corresponds to the class.
     * @param nodeFactory A factory that knows how to create PropertyNodes.
     */
    constructor(classConstructor: Constructor<any>, public table: string, private nodeFactory: PropertyNodeFactory) {
        super(classConstructor);
    }

    /**
     * @inheritdoc
     */
    protected *initializeChildren(): Iterable<TreeNode> {
        const declaredProperties: Set<string | symbol> = Reflect.getMetadata(declaredPropertiesMetadataKey, this.classConstructor.prototype);

        if (!declaredProperties) {
            throw new Error(`The ${this.classConstructor.name} class must have at least one property with a decorator`);
        }

        for (let property of declaredProperties) {
            yield this.nodeFactory.create(this, property);
        }
    }
}
