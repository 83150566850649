/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import PersistentWebSocketFactory from './PersistentWebSocketFactory';
import persistentWebSocketDefaultConfig from './configuration/PersistentWebSocketDefaultConfig';
import { NotificationService } from '../notifications/notification-service';

export const persistentWebSocketModule = angular.module('persistentWebSocket', [])
    .constant('persistentWebSocketDefaultConfig', persistentWebSocketDefaultConfig)
    .service('persistentWebSocketFactory', PersistentWebSocketFactory)
    .service('notificationService', NotificationService);
