/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { SessionTimeout } from './session-timeout';

/**
 * A service which gives a set default value for the session timeout.
 */
@Injectable()
export class SessionTimeoutDefaultService extends SessionTimeout {

    constructor() {
        super();
    }

    /**
     * @inheritdoc
     */
    protected getSessionTimout() {
        return Observable.of(30);
    }
}
