/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../../shared/database/decorators';

/**
 * The ems circumstance model
 */
@table('emccirc')
export class Circumstance {

    @expand('ccode', 'desc')
    public description: string;
}
