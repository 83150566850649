/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../../shared/database/decorators';

/**
 * An address.
 */
@table('nmadadrs')
export class Address {

    @field('num')
    public id: string;

    @field('aatype')
    public aatype: string;

    @field('city')
    public city: string;

    @field('state')
    public state: string;

    @field('street')
    public street: string;

    @field('zip')
    public zip: string;
}
