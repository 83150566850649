/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import FileDisplayDirective from './directives/FileDisplayDirective';
import FileAttachmentDirective from './directives/FileAttachmentDirective';
import ImageAttachmentDirective from './directives/ImageAttachmentDirective';
import AttachmentListController from './list/AttachmentListController';
import AddImageController from './add/images/AddImageController';
import AddFileController from './add/files/AddFileController';
import PermissionName from '../../../permissions/enums/PermissionName';
import PermissionAttribute from '../../../permissions/enums/PermissionAttribute';
import { upgradeAdapter } from '../../../upgrade-adapter';
import { AttachmentLinkComponent } from './attachment-link/attachment-link.component';
import { AttachFilesTablesStore } from './attach-files-tables/attach-files-tables-store.service';
import { ImageComponent } from './image/image.component';
import { FileDownloader } from './file-downloader';

export const attachmentModule = angular.module('attachments', [])

    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.attachments', {
                url: '/attachments',
                abstract: true,
                views: {
                    search: {
                        template: '<ion-nav-view name="search"></ion-nav-view>'
                    }
                },
                data: {
                    permissions: { or: true, permissions: [PermissionName.imaging, PermissionName.filecapture] }
                }
            })
            .state('app.attachments.attachmentsList', {
                url: '/:table/:id?:title',
                params: {
                    allowDataRequest: false
                },
                views: {
                    search: {
                        template: require('./list/list.html'),
                        controller: 'attachmentListController as vm'
                    }
                }
            })
            .state('app.attachments.addImage', {
                url: '/addImage/:table/:id?:title',
                params: {
                    attachments: undefined
                },
                views: {
                    search: {
                        template: require('./add/addAttachment.html'),
                        controller: 'addImageController'
                    }
                },
                data: {
                    permissions: { or: false, permissionName: PermissionName.imaging, attributes: [PermissionAttribute.access, PermissionAttribute.add] }
                }
            })
            .state('app.attachments.addFile', {
                url: '/addFile/:table/:id?:title',
                params: {
                    attachments: undefined
                },
                views: {
                    search: {
                        template: require('./add/addAttachment.html'),
                        controller: 'addFileController'
                    }
                },
                data: {
                    permissions: { or: false, permissionName: PermissionName.filecapture, attributes: [PermissionAttribute.access, PermissionAttribute.add] }
                }
            });
    }])

    // Directives
    .directive('fileDisplay', FileDisplayDirective)
    .directive('fileAttachment', FileAttachmentDirective)
    .directive('imageAttachment', ImageAttachmentDirective)
    .directive('sdsAttachmentLink', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(AttachmentLinkComponent))
    .directive('sdsImage', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(ImageComponent))

    // Controllers
    .controller('attachmentListController', AttachmentListController)
    .controller('addImageController', AddImageController)
    .controller('addFileController', AddFileController)

    // Services
    .factory('attachFilesTablesStore', upgradeAdapter.downgradeNg2Provider(AttachFilesTablesStore))
    .factory('fileDownloader', upgradeAdapter.downgradeNg2Provider(FileDownloader));
