/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule, ErrorHandler } from '@angular/core';
import { provideBasedOnCondition } from '../conditional-injection';
import { LoggingErrorHandler } from './logging-error-handler.service';
import { Logger } from './logger';
import { LoggerConfig } from './logger-config.service';
import { DevelopmentLogger } from './development-logger.service';
import { ProductionLogger } from './production-logger.service';
import { formatterProviders } from './formatters';

@NgModule({
    providers: [
        { provide: ErrorHandler, useClass: LoggingErrorHandler },
        provideBasedOnCondition(
            Logger,
            DevelopmentLogger,
            ProductionLogger
        ),
        LoggerConfig,
        formatterProviders
    ]
})
export class LoggingModule { }
