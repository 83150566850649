/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * A suspect vehicle characteristic.
 */
@table('vhspchar')
export class Characteristic {

    @expand('ccode', 'desc')
    public description: string;

    @field('misc')
    public comment: string;
}
