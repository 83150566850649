/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../shared/database/decorators';
import { Booking } from '../../shared/model/booking';

/**
 * The class that joins a booking with an offense.
 *
 * Note: The reason this class exists is because there is a many-to-many relationship
 *       between offenses and bookings. So there has to be a joining table in between.
 *       Currently the DatabaseService (and decorators) don't allow you to flatten
 *       joining tables such as this, but it would be a great feature to add.
 */
@table('jlbkxof')
export class BookingJoin {

    @expand('booknum', Booking)
    public booking: Booking | string;
}
