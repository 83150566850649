/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */
import { FontSizeChanger } from './FontSizeChanger';
import FontSize from './FontSize';
import { SpillmanLocalStorage } from '../shared/storage/spillman-local-storage';
import * as angular from 'angular';

// Set font size for current user to local storage
export function setFontSizeToStorage (userName: string, spillmanLocalStorage: SpillmanLocalStorage, $injector: angular.auto.IInjectorService) {
    const storageFontSize = spillmanLocalStorage.getObject({ key: `${userName}.fontSizeSetting` });
    const fontSizeChanger = $injector.get<FontSizeChanger>('fontSizeChanger');
    if (window.screen.width < 375) {
        fontSizeChanger.setFontSize(FontSize.small);
        spillmanLocalStorage.setObject({ key: `${userName}.fontSizeSetting` }, FontSize.small);
    } else {
        if (storageFontSize === undefined) {
            fontSizeChanger.setFontSize(FontSize.medium);
            spillmanLocalStorage.setObject({ key: `${userName}.fontSizeSetting` }, FontSize.medium);
        } else {
            fontSizeChanger.setFontSize(storageFontSize);
        }
    }
}
