/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import PermissionName from '../../permissions/enums/PermissionName';
import { upgradeAdapter } from '../../upgrade-adapter';
import { CallDetailComponent } from './call-detail.component';
import { CallHistoryComponent } from './history/call-history.component';
import { createDetailState } from '../state-definition-helpers';
import { CallHistoryController } from './history/call-history-controller';

export const callModule = angular.module('rms-call', [])
    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.search.callsHistory', {
                url: '/call-history',
                template: `
                    <ion-view id="callsHistory" hide-nav-bar="true" class="result-list">
                        <sds-filter-bar filter-text="vm.filterText"></sds-filter-bar>
                        <sds-connection-error-bar connected="true"></sds-connection-error-bar>
                        <ion-content>
                            <ion-refresher on-refresh="vm.requestData($event)"></ion-refresher>
                            <sds-call-history [filter]="vm.filterText"
                                              [refresh]="vm.refresh"
                                              (refresh-complete)="vm.refreshComplete()"
                                              [load-next-page]="vm.loadNextPage"
                                              (next-page-loaded)="vm.nextPageLoaded()"></sds-call-history>
                            <ion-infinite-scroll on-infinite="vm.requestNextPage()" immediate-check="false"></ion-infinite-scroll>
                        </ion-content>
                    </ion-view>
                `,
                controller: 'callHistoryController as vm',
                data: {
                    permissions: [PermissionName.calls]
                }
            })
            .state('app.search.callDetails', createDetailState('call', 'cdcall', { permission: PermissionName.calls }));
    }])

    // Directives
    .directive('sdsCallDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(CallDetailComponent))
    .directive('sdsCallHistory', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(CallHistoryComponent))

    // Controllers
    .controller('callHistoryController', CallHistoryController);
