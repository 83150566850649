/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * An enumeration of the names of all services that implement the `Regeneratable` interface.
 *
 * Note: The services will be initialized in the order in which they are listed.
 *       They will then be destroyed in the reverse order.
 */
enum RegeneratableName {
    // Session timeout
    timeoutVerification,

    // Settings
    callFilterSetting,
    unitFilterSetting,
    myIncidentsDurationSetting,
    themeSetting,

    // Setting adjuster
    requiresUnitSettingAdjuster,

    // Caches
    cityCache,
    fileTypeCache,
    imageTypeCache,
    tenCodeCache,

    // Connection to Mobile Aggregator
    cadConnection,

    // Repositories that store Cad data
    callRepository,
    unitRepository,

    // Repository which stores Permissions and their attributes.
    permissionsRepository
}

export default RegeneratableName;
