/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input, Inject } from '@angular/core';
import { Session } from '../../../../login/Session';

/**
 * A component that knows how to display an incidents responsible officer. The
 * officer name will be passed into the component. If the officer is the same
 * as the logged in user then the name is prefixed with a flag icon and is
 * colored differently. Otherwise, the name is displayed normally.
 */
@Component({
    selector: 'sds-incident-officer',
    template: require('./incident-officer.component.html'),
    styles: [
        require('./incident-officer.component.scss'),
        require('./incident-officer.component.day.scss'),
        require('./incident-officer.component.night.scss')
    ]
})
export class IncidentOfficerComponent {

    /**
     * The responsible officer for the incident.
     */
    @Input() public officer: string;

    /**
     * Constructs a new instance of the IncidentOfficerComponent class.
     *
     * @param session the current user's session.
     */
    constructor(
        @Inject('session') private session: Session
    ) {
    }

    /**
     * Is this my incident?
     *
     * @returns {boolean} true if officer is the session officer otherwise false.
     */
    public isMyIncident(): boolean {
        return this.session.exists() && this.officer !== undefined
            && this.session.data.shortName.toUpperCase() === this.officer?.toUpperCase();
    }
}
