/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../shared/database/decorators';

/**
 * The name reference model.
 */
@table('nmmain')
export class NameReference {
    @field('number')
    public id: string;

    @field('first')
    public first: string;

    @field('middle')
    public middle: string;

    @field('last')
    public last: string;
}
