/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';

/**
 * A component which displays a field which links to a roadway.
 */
@Component({
    selector: 'sds-roadway-link',
    template: `
        <sds-permissions-link state="app.search.accidentRoadwayDetails" [id]="roadway" *ngIf="roadway">
            <span>Roadway</span>
        </sds-permissions-link>
        <span *ngIf="!roadway">&nbsp;</span>
    `,
    styles: [
        require('./link.component.scss')
    ]
})
export class RoadwayLinkComponent {

    /**
     * The roadway to display.
     */
    @Input() public roadway: string;
}
