/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * The allowed logging levels in hierarchical order.
 */
export enum LoggingLevel {
    Error,
    Warn,
    Info,
    Debug,
    Trace
}
