/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IResourceService = angular.resource.IResourceService;
import IResourceOptions = angular.resource.IResourceOptions;
import IResourceClass = angular.resource.IResourceClass;
import { UrlFactory, UrlConfig } from '../../authentication';
import Session from '../../login/Session';
import ComputedUrlResource from './ComputedUrlResource';
import ResourceArrayPagerLinker from '../paging/ResourceArrayPagerLinker';
import ActionDescriptorMap = angular.resource.ActionDescriptorMap;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
ComputedUrlResourceFactory.$inject = [
    '$resource',
    'urlFactory',
    'session',
    'resourceArrayPagerLinker'
];

/**
 * A factory function that creates the ComputedUrlResource.
 *
 * @param $resource The Angular service that handles communication with a REST API.
 * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
 * @param session The object that stores information about the current user's session.
 * @param resourceArrayPagerLinker
 * @returns A specialized version of the `$resource` service that automatically computes the URL on each request.
 */
function ComputedUrlResourceFactory<T>($resource: IResourceService,
    urlFactory: UrlFactory,
    session: Session,
    resourceArrayPagerLinker: ResourceArrayPagerLinker<T>): ComputedUrlResource {
    /**
     * The name of the protocol parameter.
     */
    const protocolPlaceholder = 'protocol';

    /**
     * The name of the server parameter.
     */
    const serverPlaceholder = 'server';

    /**
     * The name of the port parameter.
     */
    const portPlaceholder = 'port';

    /**
     * These parameters are session-specific, meaning, that they need to be recomputed each time
     * that the user logs out and back in again. Therefore, they cannot be hard-coded,
     * and are instead functions (lambda expressions) that will be computed each time.
     */
    const sessionSpecificParams = {
        [protocolPlaceholder]: () => 'http' + (session.data.secureConnection ? 's' : ''),
        [serverPlaceholder]: () => session.data.server,
        [portPlaceholder]: () => session.data.port
    };
    // Return a function that satisfies the `ComputedUrlResource` interface.
    const computedUrlResourceResult = function (pathConfig: { path: string }, paramDefaults?: Object, actions?: ActionDescriptorMap, options?: IResourceOptions): IResourceClass<T>{
        const config: UrlConfig = angular.extend({}, pathConfig, {
            protocol: `:${protocolPlaceholder}`,
            server: `:${serverPlaceholder}`,
            port: `:${portPlaceholder}`,

            // Always set to false here. Whether it's a secure connection or not will be decided in the arrow function up above.
            secureConnection: false
        });

        const url = urlFactory.create(config);

        paramDefaults = paramDefaults || {};
        paramDefaults = angular.extend({}, sessionSpecificParams, paramDefaults);
        const resourceClass = $resource<T>(url, paramDefaults, actions, options);
        resourceArrayPagerLinker.link(resourceClass, actions);
        return resourceClass;
    };

    return <ComputedUrlResource>computedUrlResourceResult;
}

export default ComputedUrlResourceFactory;
