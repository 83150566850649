/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../shared/database/database.service';
import { DetailsStateParams } from '../../shared/interfaces/DetailsStateParams';
import { Evidence } from './model/evidence';
import { EvidenceLocalId } from './model/evidence-local-id';
import { JoinPipe } from '../../shared/pipes';
import { SlideSourceInfo } from '../../schema/SlideSourceInfo';

/**
 * A component that displays the details of a evidence.
 */
@Component({
    selector: 'sds-evidence-detail',
    template: require('./evidence-detail.component.html')
})
export class EvidenceDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        this.commentsSlideSourceInfo,
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The evidence model to display.
     */
    public evidence: Evidence;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the EvidenceDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     * @param involvementsSlideSourceInfo The SlideSourceInfo for the involvements slide.
     * @param commentsSlideSourceInfo The SlideSourceInfo for the comments slide.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        private joinPipe: JoinPipe,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * The formatter that knows how to convert an EvidenceLocalId to a string.
     */
    public localIdFormatter = (localId: EvidenceLocalId) => this.joinPipe.transform([localId.type, localId.id], ' - ');

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(Evidence, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.evidence = detailModel, () => this.onError.emit());
    }
}
