/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import CallActionViewState from './CallActionViewState';
import CallActionScope from '../CallActionScope';

/**
 * The view state that indicates that the user is assigned and actively working on the current call.
 */
class UpdateStatusCallActionViewState implements CallActionViewState {

    public static $inject = ['$scope'];

    /**
     * Constructs a new instance of the UpdateStatusCallActionViewState class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     */
    constructor(private $scope: CallActionScope) {
    }

    public get isUsersActiveCall() {
        return true;
    }

    public get buttonText(): string {
        // The button is not shown, so no text is needed.
        return undefined;
    }

    public get isActive() {
        let removed = this.$scope.removed;
        let call = this.$scope.call;
        let myUnit = this.$scope.myUnit;

        return !removed && call && myUnit && myUnit.callIdAndType === call.callIdAndType;
    }

    public performAction() {
        // The action is performed by the status control. No logic is needed here.
    }
}

export default UpdateStatusCallActionViewState;
