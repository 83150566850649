/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;

/**
 * A factory function that creates the unit officer directive.
 *
 * @returns {IDirective} A directive that displays an officer assigned to a unit.
 */
export default function UnitOfficerDirective(): IDirective {
    return {
        template: `<div class="row row-no-padding">
                     <div class="col officer-name">{{officer.desc}}</div>
                     <div class="col col-40">
                       <div class="row row-no-padding">
                         <a ng-href="tel:{{officer.phone}}" class="col phone-number" sds-if-valid-phone-number="officer.phone">{{officer.phone}}</a>
                         <a ng-href="sms:{{officer.phone}}" class="col col-10 phone-number-sms" sds-if-valid-phone-number="officer.phone"><i class="icon-Message"></i></a>
                       </div>
                     </div>
                   </div>`,
        scope: {
            officer: '='
        }
    };
}
