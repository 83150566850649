/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PropertyDetailComponent } from './detail/property-detail.component';

@NgModule({
    imports: [SharedModule],
    declarations: [PropertyDetailComponent]
})
export class PropertyModule { }
