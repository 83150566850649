/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { Http, XHRBackend, RequestOptions, ConnectionBackend } from '@angular/http';

import { SpillmanHttp } from './spillman-http.service';
import { UrlFactory, UrlConfig, DefaultUrlConfig } from './url';
import { CredentialsRepository } from './credentials-repository.service';
import { AuthenticationService } from './authentication.service';
import { AuthenticationTokenService } from './authentication-token.service';
import { ReAuthenticationService } from './re-authentication.service';
import { ApiAuthenticationService } from './api-authentication.service';
import { ApiReAuthenticationService } from './api-re-authentication.service';
import { ImprovedMergeBaseRequestOptions } from './improved-merge-base-request-options';
import { ApiAuthenticationTokenService } from './api-authentication-token.service';
import { SetAuthenticationHeadersService } from './set-authentication-headers.service';
import { originalHttpToken } from './original-http-token';
import { provideBasedOnCondition } from '../conditional-injection/provide-based-on-condition';
import { AuthenticationParameterService } from './parameters/authentication-parameter.service';
import { BrowserAuthenticationParameterService } from './parameters/browser-authentication-parameter.service';
import { DeviceAuthenticationParameterService } from './parameters/device-authentication-parameter.service';
import { PasswordExpirationService } from './password-expiration.service';
import { ApiPasswordExpirationService } from './api-password-expiration.service';

/**
 * Builds the original Angular 2 Http service.
 */
export function httpFactory(backend: ConnectionBackend, requestOptions: RequestOptions): Http {
    return new Http(backend, requestOptions);
}

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        ApiAuthenticationService,
        ApiAuthenticationTokenService,
        ApiPasswordExpirationService,
        ApiReAuthenticationService,
        SetAuthenticationHeadersService,
        UrlFactory,
        CredentialsRepository,
        { provide: UrlConfig, useClass: DefaultUrlConfig },
        { provide: originalHttpToken, useFactory: httpFactory, deps: [XHRBackend, RequestOptions] },
        { provide: Http, useClass: SpillmanHttp },
        { provide: AuthenticationService, useClass: ApiAuthenticationService },
        { provide: AuthenticationTokenService, useClass: ApiAuthenticationTokenService },
        { provide: PasswordExpirationService, useClass: ApiPasswordExpirationService },
        { provide: ReAuthenticationService, useClass: ApiReAuthenticationService },
        { provide: RequestOptions, useClass: ImprovedMergeBaseRequestOptions },
        provideBasedOnCondition(
            AuthenticationParameterService,
            BrowserAuthenticationParameterService,
            DeviceAuthenticationParameterService
        )
    ]
})
export class AuthenticationModule { }
