/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Inject, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
/**
 * A customized date pipe that calls our custom date filter to correctly handle dates.
 * Note: the standard Angular 2 date pipe does not handle the formatting correctly on
 *   Safari. The name of this custom pipe overrides the built-in date pipe so that it
 *   can easily be switched back *if/when* the the Angular team fixes the built in one.
 *
 * Usage:
 *   value | date:'MM/dd/yyyy'
 *
 * Example:
 *   {{ '2016-09-02 17:44:25.000-06:00' |  date:'HH:mm:ss MM/dd/yyyy' }}
 *   outputs: 17:44:25 09/02/2016
 */
@Pipe({
    name: 'date' // eslint-disable-line
})
export class DatePipePolyfill implements PipeTransform {

    /**
     * Constructs a new instance of the DatePipe class.
     *
     * @param serverDateTimeFormat Specifies the format in which dates are sent from the server.
     * @param dateFilter The Angular 1 date filter that knows how to format dates.
     */
    constructor(
        @Inject('serverDateTimeFormat') private serverDateTimeFormat: string,
        @Inject('dateFilter') private dateFilter: angular.IFilterDate
    ) {
    }

    /**
     * Transforms a date into a human friendly format.
     *
     * @param value Either a Date, number in milliseconds or a string representation of a date in the Spillman format.
     * @param format The output format in which to display the parsed date.
     * @param timezone (optional) The timezone to use when displaying the parsed date.
     * @returns The formatted date.
     */
    public transform(value: string | number | Date, format: string, timezone?: string): string {
        // If the value is falsy (undefined, null, empty), then just return undefined.
        if (!value && value !== 0) {
            return undefined;
        }

        let momentValue = (typeof value === 'string') ? moment(value, this.serverDateTimeFormat) : moment(value);
        return momentValue.isValid() ? this.dateFilter(momentValue.toDate(), format, timezone) : undefined;
    }
}
