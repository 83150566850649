/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import {  IScope } from 'angular';
import { DeepLinkService } from '../../../deeplink/deep-link-service';
import { PlatformDetector } from '../../../../conditional-injection/platform-detection/platform-detector.service';
import { PhoneIdentifier } from '../../phoneValidator';
import TextParts from './TextParts';

/**
 * A controller for the text with phone link component
 */
export class textWithPhoneLinkController {
    private readonly BREAK_TAG = '<!break!>';

    public static $inject = ['$scope', 'deepLinkService', 'platformDetector'];

    public text: string;

    constructor(
        private $scope: IScope,
        deepLinkService: DeepLinkService,
        platformDetector: PlatformDetector
    ) {
        $scope.onPhoneButtonClick = (number: string) => {
            deepLinkService.open1021PhoneNumber(number);
        };

        $scope.isBrowser = platformDetector.isBrowser();
    }

    public $onInit = () => {
        this.splitTextInParts();
    };

    private splitTextInParts() {
        const withSuffix = PhoneIdentifier.addPrefixAndSuffixToPhone(this.text, this.BREAK_TAG, this.BREAK_TAG);
        this.$scope.textParts = TextParts.convertFromStringToTextParts(withSuffix?.split(this.BREAK_TAG));
    }
}
