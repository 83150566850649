/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResource = angular.resource.IResource;
import ResourceArrayPager = angular.resource.ResourceArrayPager;
import IResourceArray = angular.resource.IResourceArray;
import IPromise = angular.IPromise;
import ResourceUtils from '../ResourceUtils';
import IQService = angular.IQService;
type AsyncArrayTransform<T> = (combinedArray: T[]) => T[]|IPromise<T[]>;

/**
 * An implementation of the ResourceArrayPager interface that seamlessly combines multiple resource arrays into one
 * and makes it possible to page through the results just as if it were a single resource array.
 */
export default class MultiResourceArrayPager<T extends IResource<any>> implements ResourceArrayPager<T> {

    public static $inject = ['$q', 'resourceUtils', 'subResourceArrays', 'transform', 'pageSize'];

    private _resourceArray: IResourceArray<T>;
    private _masterList: T[] = [];
    private _index = 0;

    /**
     * Constructs a new instance of the MultiResourceArrayPager class.
     *
     * @param _$q The angular service that handles creating and working with promises.
     * @param resourceUtils
     * @param subResourceArrays An array of resource arrays from which to pull to create the combined resource array.
     * @param _transform A function (such as filtering or sorting) to apply to the combined results
     *                   before adding them to the combined resource array.
     * @param _pageSize The size of each page.
     */
    constructor(private _$q: IQService,
        resourceUtils: ResourceUtils,
        public subResourceArrays: IResourceArray<T>[],
        private _transform: AsyncArrayTransform<T>,
        private _pageSize: number) {
        this._resourceArray = resourceUtils.createResourceArray(this.fetchResultsFromResourceArrays(r => r.$promise), this);
    }

    /**
     * Gets the resource array that contains the combined results form the sub resource arrays.
     *
     * @returns {IResourceArray<T>} The resource array that contains the combined results.
     */
    public get resourceArray(): IResourceArray<T> {
        return this._resourceArray;
    }

    /**
     * @override
     */
    public hasNextPage(): boolean {
        return this._resourceArray.$resolved &&
            (this._masterList.length > this._index || this.subResourceArrays.some(r => r.pager.hasNextPage()));
    }

    /**
     * @override
     */
    public appendNextPage(): IPromise<T[]> {
        return this.getNextPage().then(nextPage => {
            this._resourceArray.pushAll(nextPage);
            return nextPage;
        });
    }

    /**
     * Gets the next page by first checking if there are enough records in the master list, and only if there are not,
     * then going to the server to get more results.
     *
     * @returns {IPromise<T[]>} A promise that, when resolved, returns the next page of results.
     */
    private getNextPage(): IPromise<T[]> {
        // First check if we already have a full page ready to go. If so, then we don't
        // need to fetch any more results from the server.
        if (this._masterList.length >= this._index + this._pageSize) {
            return this._$q.when(this.getNextPageFromMasterList());
        }

        // Next check if we can get more from the server. If so, then get the results and recursively call this method.
        if (this.subResourceArrays.some(r => r.pager.hasNextPage())) {
            return this.fetchResultsFromResourceArrays(r => r.pager.appendNextPage());
        }

        // Finally, check if we have more records at all -- even though we don't have a full page.
        // This should only be hit when viewing the last page.
        if (this._masterList.length > this._index) {
            return this._$q.when(this.getNextPageFromMasterList());
        }

        return this._$q.when([]);
    }

    /**
     * Calls upon each of the sub resource arrays to go get results from the server.
     * This could mean getting the original results or additional pages.
     *
     * @param resultSelector A function that calls a method on each sub resource array to get more results.
     * @returns {IPromise<T[]>} A promise that, when resolved, will contain the next page of results.
     */
    private fetchResultsFromResourceArrays(resultSelector: (array: IResourceArray<T>) => IPromise<T[]>): IPromise<T[]> {
        return this._$q.all<T[]>(this.subResourceArrays.map(resultSelector))
            .then(resolvedArrays => this.storeResults(resolvedArrays))
            .then(() => this.getNextPage());
    }

    /**
     * Stores all of the specified results in the master list.
     *
     * @param results A array of arrays containing each of the data sets returned by each of the sub arrays.
     * @returns {IPromise<void>} A promise that, when resolved, indicates that the results have been stored in the master list.
     */
    private storeResults(results: T[][]): IPromise<void> {
        this._masterList.pushAll(results.reduce((r1, r2) => r1.concat(r2)));

        // We must apply the transform to the entire master list to ensure there are no duplicates.
        return this._$q.when(this._transform(this._masterList)).then((transformedResults) => {
            this._masterList = transformedResults;
        });
    }

    /**
     * Gets the next page of results from the master list only. This will not attempt to load more results from the server.
     *
     * @returns {T[]} An array of results that represents the next page.
     */
    private getNextPageFromMasterList(): T[] {
        const startIndex = this._index;
        this._index = Math.min(this._masterList.length, this._index + this._pageSize);
        return this._masterList.slice(startIndex, this._index);
    }
}
