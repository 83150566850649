/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * The CAD Call reference model.
 */
@table('cdcall')
export class CadCallReference {

    @field('number')
    public id: string;

    @expand('nature', 'desc')
    public nature: string;
}
