/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, involvements, involvementsAlerts } from '../../../../shared/database/decorators';
import { Involvement } from '../../../../schema/Involvement';
import { NameReference, VehicleReference } from '../../../shared/model';

/**
 * The offender tracking vehicle model
 */
@table('sovehic')
export class OffenderTrackingVehicle {

    @field('num')
    public id: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @expand('vhnum', VehicleReference)
    public vehicle: VehicleReference | string;

    @expand('sonum.namenum', NameReference)
    public name: NameReference | string;

    @expand('status', 'descrpt')
    public status: string;

    @field('stdate')
    public statusDate: Date;

    @expand('entrdby', 'fullnam')
    public enteredBy: string;

    @includeOne('sovehcm', 'text')
    public comments: string;
}
