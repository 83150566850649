/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { NameReference } from '../../shared/model/name-reference';
import { VehicleReference } from '../../shared/model/vehicle-reference';
import Involvement from '../../../schema/Involvement';
import { IncidentReference } from '../../shared/model/incident-reference';
import { Offense } from './offense';

/**
 * The traffic-warning model.
 */
@table('twmain')
export class TrafficWarning {

    @expand('nameid', NameReference)
    public defendant: NameReference | string;

    @expand('vehiid', VehicleReference)
    public vehicle: VehicleReference | string;

    @field('dtissue')
    public issued: Date;

    @expand('issuoff', 'fullnam')
    public officer: string;

    @expand('incidnt', IncidentReference)
    public relatedIncident: IncidentReference | string;

    @expand('locatn', 'desc')
    public area: string;

    @field('street')
    public street: string;

    @field('city')
    public city: string;

    @expand('state', 'desc')
    public state: string;

    @field('zip')
    public zip: string;

    @includeOne('twmdesc', 'text')
    public comments: string;

    @includeMany('twoffs', Offense)
    public offenses: Offense[];

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
