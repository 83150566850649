/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import * as moment from 'moment';

/**
 * A converter that takes a serialized date string and converts it into an actual Date object.
 */
@Injectable()
export class DateConverter {

    /**
     * Constructs a new instance of the DateConverter class.
     *
     * @param serverDateTimeFormat The constant that defines the format in which dates are sent by the server.
     */
    constructor(
        @Inject('serverDateTimeFormat') private serverDateTimeFormat: string
    ) {
    }

    /**
     * Converts a serialized date, which is string, to a Date object.
     *
     * @param serializedDate The serialized date string.
     * @returns A Date object or undefined if the serialized date was undefined.
     */
    public convert(serializedDate: string): Date {
        return serializedDate ? moment(serializedDate, this.serverDateTimeFormat).toDate() : undefined;
    }
}
