/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import { VehicleReference } from '../../model/vehicle-reference';

/**
 * A component which displays a field which links to a vehicle.
 */
@Component({
    selector: 'sds-vehicle-link',
    template: `
        <sds-permissions-link state="app.search.vehiclesDetails" [id]="vehicle?.id">
            <sds-vehicle-display [vehicle]="vehicle"></sds-vehicle-display>
        </sds-permissions-link>
    `,
    styles: [
        require('./link.component.scss')
    ]
})
export class VehicleLinkComponent {

    /**
     * The vehicle to display.
     */
    @Input() public vehicle: VehicleReference;
}
