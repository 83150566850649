/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import { Http } from '@angular/http';
import { CookieCleaner } from './cookie-cleaner';
import { UrlFactory, originalHttpToken } from '../../authentication';
import { Credentials } from '../Credentials';
import { injectionCondition } from '../../conditional-injection';

/**
 * Deletes all session cookies when using Basic Authentication in the browser.
 */
@Injectable()
@injectionCondition({ platforms: ['browser'] })
export class BrowserBasicAuthenticationCookieCleaner implements CookieCleaner {

    /**
     * Constructs a new instance of the BrowserBasicAuthenticationCookieCleaner class.
     *
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param http The Angular service that makes http requests.
     */
    constructor(
        private urlFactory: UrlFactory,
        @Inject(originalHttpToken) private http: Http
    ) {
    }

    /**
     * @inheritdoc
     */
    public deleteCookies(credentials?: Credentials): Promise<void> {
        const logoutUrl = this.urlFactory.create({
            server: credentials && credentials.server,
            port: credentials && credentials.port,
            secureConnection: credentials && credentials.secureConnection,
            path: '/../logout'
        });
        return this.http.post(logoutUrl, {})
            .timeout(20 * 1000)
            .map(() => { /* void */ })
            .toPromise();
    }
}
