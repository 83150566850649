/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { BaseIncidentListController } from '../../list/base-incident-list-controller';
import ApplicationScope from '../../../../ApplicationScope';
import FireIncident from '../../../../schema/FireIncident';
import IonicLoadingService = ionic.loading.IonicLoadingService;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;
import IResourceClass = angular.resource.IResourceClass;
import ListStateParams from '../../../../shared/interfaces/ListStateParams';

/**
 * The controller that displays a list of fire incidents based on the search criteria entered by user.
 */
export class FireIncidentListController extends BaseIncidentListController<FireIncident> {

    public static $inject = ['$scope', '$ionicLoading', '$timeout', 'fireIncidentAPI', '$stateParams', '$ionicScrollDelegate'];

    /**
     * Constructs a new instance of the FireIncidentListController class.
     *
     * @param $scope The Angular scope object that can listen to and raise events.
     * @param $ionicLoading An overlay that displays a spinner while waiting for data to be loaded.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param incidentAPI The service that retrieves law incidents from the server.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     * @param scrollDelegate The delegate that controls the ion-scroll directive.
     */
    constructor(
        $scope: ApplicationScope,
        $ionicLoading: IonicLoadingService,
        $timeout: angular.ITimeoutService,
        incidentAPI: IResourceClass<FireIncident>,
        $stateParams: ListStateParams,
        scrollDelegate: IonicScrollDelegate
    ) {
        super($scope, $ionicLoading, $timeout, incidentAPI, $stateParams, scrollDelegate, 'app.search.fireIncidentDetails');
    }

    public $onInit = () => {};
}
