/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ITimeoutService = angular.ITimeoutService;
import ILogService = angular.ILogService;
import IPromise = angular.IPromise;
import { Queue } from '../shared/data-structures/queue';
import PersistentWebSocketConfig from './configuration/PersistentWebSocketConfig';
import CancellableFunction from '../shared/interfaces/CancellableFunction';
import IIntervalService = angular.IIntervalService;
import debounce = require('lodash/debounce');
import AdvancedWebSocketOptions from './configuration/AdvancedWebSocketOptions';
import advancedWebSocketDefaultOptions from './configuration/AdvancedWebSocketDefaultOptions';
import { NotificationService } from '../notifications/notification-service';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare let CordovaWebsocketPlugin: any;

/**
 * A WebSocket that will automatically reconnect whenever it loses connection.
 * Only when `disconnect` is explicitly called will it stop trying to reconnect.
 *
 * Note: To create an instance of this class, use the PersistentWebSocketFactory.
 *       This allows multiple instances to be created while also tying into Angular's
 *       dependency injection framework.
 */
export default class PersistentWebSocket {
    /**
     * Unique reference to your WebSocket which is needed for later calls to wsSend and wsClose.
     */
    private socketId: string;

    /**
     * The underlying WebSocket to which to delegate sending and receiving messages.
     */
    private socket: WebSocket;

    /**
     * The underlying advanced Cordova WebSocket to which to delegate sending and receiving messages.
     */
    private advancedWebSocket: any;

    /**
     * A flag that determines if the underlying WebSocket is allowed to close.
     */
    private allowClose = false;

    /**
     * The queue in which outbound messages will be stored while the connection is down.
     * Upon reconnecting, the messages will automatically be sent.
     */
    private messageQueue = new Queue<{ event: string; data: any }>();

    /**
     * A map that stores the set of handlers per event.
     */
    private handlerMap = new Map<string, Set<Function>>();

    /**
     * Tracks the number of attempts that have been made to reconnect.
     * This will be passed to the `reconnectTimingFunc` so that it can determine
     * how long to wait before the next attempt at reconnecting.
     */
    private reconnectAttempts = 0;

    /**
     * The promise returned by the `$timeout` service. It will be used to stop trying
     * to reconnect once a connection has been established.
     */
    private reconnectPromise: IPromise<void>;

    /**
     * The promise returned by $interval. Can be used to cancel the interval when necessary.
     */
    private heartbeatPromise: IPromise<any>;

    /**
     * As long as this function is continually called within the time allotted by the 'connectionLostTimeout', then the 'reconnect' function
     * will never be called. If the time expires, then 'reconnect' will be called, and a new connection will be initiated.
     */
    private keepConnectionAlive: CancellableFunction;

    /**
     * The constructor for the PersistentWebSocket.
     *
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param $interval
     * @param $log The Angular service that performs logging.
     * @param config
     * @param notificationService
     */
    constructor(
        private $timeout: ITimeoutService,
        private $interval: IIntervalService,
        private $log: ILogService,
        private config: PersistentWebSocketConfig,
        private notificationService: NotificationService
    ) {
        this.advancedWebSocket = typeof CordovaWebsocketPlugin !== 'undefined' ? CordovaWebsocketPlugin : undefined;
    }

    /**
     * Connects the WebSocket to the server. The WebSocket will automatically and continuously attempt
     * to reconnect if the connection is ever broken due to network or server issues.
     * Calling this method when a connection already exists will do nothing unless the `force` flag is true.
     *
     * @param force If set to true, forces a new connection regardless of whether one already exists.
     */
    public connect(force?: boolean): void {
        if (this.advancedWebSocket && !this.socketId) {
            this.createAdvancedWebSocket();
        }

        if (!this.socket && !this.advancedWebSocket) {
            this.createSocket();
        } else if (force) {
            this.reconnect();
        }
    }

    /**
     * Disconnects the WebSocket so that it will no longer attempt to reconnect.
     */
    public disconnect(): void {
        if (this.advancedWebSocket) {
            this.advancedWebSocket.wsClose(this.socketId, 1000, 'Log out');
            this.socketId = undefined;
            this.allowClose = true;
        }

        if (this.socket) {
            this.allowClose = true;
            this.socket.close();
        }
    }

    /**
     * Determines if the `connect` method has been called and `disconnect` has not been called since.
     *
     * @returns {boolean} True if the `connect` method has been called and `disconnect` has not been called since;
     *                    false if `connect` has never been called or `disconnect` was called afterwards.
     */
    public get isConnected(): boolean {
        return !!this.socket || !!this.socketId;
    };

    /**
     * Determines if the WebSocket is currently open.
     *
     * @returns {boolean} True if the WebSocket is open, false otherwise.
     */
    public get isOpen(): boolean {
        return (this.isConnected && this.socket && this.socket.readyState === WebSocket.OPEN) || !!this.socketId;
    }

    /**
     * Binds an event handler to the event with the specified name.
     *
     * @param event The name of the event.
     * @param handler A function that will be invoked when the event is raised.
     */
    public on(event: string, handler: (data: any) => void): void {
        let handlers = this.handlerMap.get(event);
        if (!handlers) {
            handlers = new Set<Function>();
            this.handlerMap.set(event, handlers);
        }
        handlers.add(handler);
    }

    /**
     * Un-binds an event handler from the event with the specified name.
     *
     * @param event The name of the event.
     * @param handler The function to un-bind. It will no longer be invoked when the event is raised.
     *                Note that the function must be the same exact instance that was passed to the `on` method.
     */
    public off(event: string, handler: (data: any) => void): void {
        let handlers = this.handlerMap.get(event);
        if (handlers) {
            handlers.delete(handler);
        }
    }

    /**
     * Sends an outbound message to the server with the specified event name and data.
     *
     * @param event The name of the event.
     * @param data The data.
     */
    public emit(event: string, data: any): void {
        let message = {event, data};

        if (this.isOpen) {
            this.advancedWebSocket ?
                this.advancedWebSocket.wsSend(this.socketId, JSON.stringify(message)) :
                this.socket.send(JSON.stringify(message));
        } else {
            this.messageQueue.enqueue(message);
        }
    }

    /**
     * Instantiates a new WebSocket.
     */
    private createSocket(): void {
        if (this.config.protocols) {
            this.socket = new WebSocket(this.config.url, this.config.protocols);
        } else {
            this.socket = new WebSocket(this.config.url);
        }

        this.socket.onclose = (event: CloseEvent) => {
            this.socket = undefined;

            // Stop heartbeats
            this.stopHeartbeat();

            // Reconnect if the socket is not allowed to close.
            if (!this.allowClose) {
                this.reconnectSocket();
            }

            // Set the `allowClose` flag back to false.
            this.allowClose = false;
            this.notifyHandlers(this.config.nativeEventPrefix + 'close', event);
        };

        this.socket.onerror = (event: ErrorEvent) => {
            this.notifyHandlers(this.config.nativeEventPrefix + 'error', event);
        };

        this.socket.onmessage = (event: MessageEvent) => {
            this.notifyHandlers(this.config.nativeEventPrefix + 'message', event);

            if (typeof event.data === 'string') {
                let message = JSON.parse(event.data);
                if (message.event) {
                    this.notifyHandlers(message.event, message.data);
                }
            }
        };

        this.socket.onopen = (event: Event) => {
            this.handleOnSocketOpen(event);

            if (this.config.heartbeatConfig) {
                this.startHeartbeat();
            }
        };
    }

    /**
     * Instantiates a new Advanced WebSocket connection.
     */

    private createAdvancedWebSocket(): void {
        const wsOptions: AdvancedWebSocketOptions = {
            ...advancedWebSocketDefaultOptions,
            url: this.config.url
        };

        /**
         * AdvancedWebsocket is WebSocket plugin that supports custom headers, self-signed certificates,
         * periodical sending of pings (ping-pong to keep connection alive and detect sudden connection loss when
         * no closing frame is received).
         */

        this.advancedWebSocket.wsConnect(wsOptions,
            (receiveEvent: { callbackMethod: string; message: string; webSocketId: string }) => {
                if (receiveEvent.webSocketId !== this.socketId || typeof this.socketId !== 'string') {
                    return;
                }

                if (receiveEvent.callbackMethod === 'onClose' || receiveEvent.callbackMethod === 'onFail') {
                    this.socketId = undefined;

                    // Reconnect if the socket is not allowed to close.
                    if (!this.allowClose) {
                        this.reconnectSocket();
                    }

                    // Set the `allowClose` flag back to false.
                    this.allowClose = false;
                    this.notifyHandlers(this.config.nativeEventPrefix + 'close', event);
                    return;
                }

                this.notifyHandlers(this.config.nativeEventPrefix + 'message', receiveEvent);

                if (typeof receiveEvent.message === 'string') {
                    let message = JSON.parse(receiveEvent.message);
                    if (message.event) {
                        this.notifyHandlers(message.event, message.data);
                    }
                }
            },
            (success: any) => {
                this.socketId = success.webSocketId;

                this.handleOnSocketOpen(success);
            },
            (error: { code: number; exception: string; reason: string }) => {
                // eslint-disable-next-line no-console
                console.log('Failed to connect to WebSocket: ' +
                    'code: ' + error.code +
                    ', reason: ' + error.reason +
                    ', exception: ' + error.exception);

                this.notifyHandlers(this.config.nativeEventPrefix + 'error', error);

                // Reconnect if the socket is not allowed to close.
                if (!this.allowClose) {
                    this.reconnectSocket();
                }
            });
    }

    /**
     * Handle event when WebSocket open connection.
     */

    private handleOnSocketOpen(event: Event): void {
        // Stop trying to reconnect since we're now connected.
        if (this.reconnectPromise) {
            this.$timeout.cancel(this.reconnectPromise);
            this.reconnectPromise = undefined;
        }

        // Set the number of reconnection attempts back to zero.
        this.reconnectAttempts = 0;

        this.notifyHandlers(this.config.nativeEventPrefix + 'open', event);

        // Send all messages in the queue, which are those that were attempted to be sent while the WebSocket was not open.
        // Only send as long as the WebSocket is open, otherwise we'll get into an infinite loop since the `emit`
        // method will put the message back in the queue.
        while (!this.messageQueue.isEmpty && this.isOpen) {
            let message = this.messageQueue.dequeue();
            this.emit(message.event, message.data);
        }
    }

    /**
     * Method for reconnecting to the WebSocket.
     */

    private reconnectSocket(): void {
        let delay = this.config.reconnectTiming.getDelay(this.reconnectAttempts++);
        this.$log.info(`Reconnecting in ${delay / 1000} seconds`);
        this.reconnectPromise = this.$timeout(() => this.connect(), delay);
    }

    /**
     * Notifies all handlers that were registered with the `on` method for the specified event.
     * This is for incoming events (coming from the server).
     *
     * @param event The name of the event.
     * @param data The event data.
     */
    private notifyHandlers(event: string, data: any): void {
        // TODO: perhaps this should be $scope.$evalAsync instead.
        // Anything inside of $timeout will automatically have an $apply run afterwards - thus updating the view.
        // See http://stackoverflow.com/questions/12729122/prevent-error-digest-already-in-progress-when-calling-scope-apply
        this.$timeout(() => {
            let handlers = this.handlerMap.get(event);
            if (handlers) {
                handlers.forEach(handler => handler(data));
            }
            this.notificationService.onUpdate(data);
        });
    }

    /**
     * Forces the socket to reconnect.
     */
    private reconnect = (): void => {
        if (this.socket) {
            // `allowClose` remains false, so a new connection will be started.
            this.socket.close();
        }

        if (this.advancedWebSocket && this.socketId) {
            this.advancedWebSocket.wsClose(this.socketId, 1000, 'Force reconnect');
            this.socketId = undefined;
            this.createAdvancedWebSocket();
        }
    };

    /**
     * Starts sending heartbeats to the server on a regular interval.
     * If the server does not respond in the allotted time, then the socket will reconnect.
     */
    private startHeartbeat(): void {
        if (!!this.heartbeatPromise || !!this.keepConnectionAlive) {
            this.$log.warn('`onopen` called twice without `onclose` being called.');
            this.stopHeartbeat();
        }

        // Create a new debounced function.
        this.keepConnectionAlive = <CancellableFunction>debounce(this.reconnect, this.config.heartbeatConfig.connectionLostTimeout);

        // Every time we get a heartbeat, we need to call the debounced function to keep it alive.
        this.on(this.config.heartbeatConfig.messageName, this.keepConnectionAlive);

        // Call it the first time to start the initial countdown.
        this.keepConnectionAlive();

        // Send the initial heartbeat.
        this.sendHeartbeat();

        // Thereafter, send heartbeats to the server on an interval.
        this.heartbeatPromise = this.$interval(() => this.sendHeartbeat(), this.config.heartbeatConfig.sendInterval);
    }

    /**
     * Sends a single heartbeat message to the server.
     */
    private sendHeartbeat(): void {
        this.emit(this.config.heartbeatConfig.messageName, {timestamp: Date.now()});
    }

    /**
     * Stops sending heartbeats.
     */
    private stopHeartbeat(): void {
        if (this.keepConnectionAlive) {
            this.off(this.config.heartbeatConfig.messageName, this.keepConnectionAlive);
            this.keepConnectionAlive.cancel();
            this.keepConnectionAlive = undefined;
        }

        if (this.heartbeatPromise) {
            this.$interval.cancel(this.heartbeatPromise);
            this.heartbeatPromise = undefined;
        }
    }
}
