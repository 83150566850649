/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;

/**
 * A factory function that creates the collapsible container directive.
 *
 * @returns {IDirective} A directive that provides the ability for an element to animate open and closed.
 */
export default function CollapsibleContainerDirective(): IDirective {
    return {
        scope: {
            open: '=',
            duration: '@'
        },
        link: function (scope: CollapsibleContainerScope, element: IAugmentedJQuery) {
            // Initially, hide the element if the `open` flag is false.
            // This prevents a flicker as the container animates closed.
            if (!scope.open) {
                element.hide();
            }

            scope.$watch('open', (open: boolean) => {
                let method = open ? 'slideDown' : 'slideUp';
                element[method](scope.duration);
            });
        }
    };
}

/**
 * The scope for the CollapsibleContainerDirective.
 */
interface CollapsibleContainerScope extends IScope {

    /**
     * A flag that indicates whether or not the container is open.
     */
    open: boolean;

    /**
     * (optional) How long the animation will run.
     * Per jQuery documentation, the default is 400 ms.
     */
    duration?: number|string;
}
