import { Component, Inject } from '@angular/core';
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;

@Component({
    selector: 'sds-scroll-to-top',
    template: require('./scroll-to-top.component.html'),
    styles: [require('./scroll-to-top.scss')]
})

export class ScrollToTopComponent {
    public isScroll: boolean;

    constructor(@Inject('$ionicScrollDelegate') private $ionicScrollDelegate: IonicScrollDelegate) {
    }

    public goToTop() {
        this.$ionicScrollDelegate.scrollTop(true);
    }

}
