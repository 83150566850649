/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, includeOne, involvements, involvementsAlerts } from '../../../../../shared/database/decorators';
import { Involvement } from '../../../../../schema/Involvement';
import { ModusOperandi } from './modus-operandi';
import { Supplement } from './supplement';
import { Circumstance } from './circumstance';
import { Offense } from './offense';
import { RespondingOfficer } from './responding-officer';
import {
    CadCallReference,
    NameReference,
    Incident,
    RadioLog
} from '../../../../shared/model';

/**
 * The law-incident model
 */
@table('lwmain')
export class LawIncident implements Incident {

    @field('address')
    public street: string;

    @field('city')
    public city: string;

    @field('state')
    public state: string;

    @field('zip')
    public zip: string;

    @field('agency')
    public agency: string;

    @expand('locatn', 'desc')
    public area: string;

    @expand('callid', CadCallReference)
    public cadCall: CadCallReference | string;

    @includeOne('lwcase', 'number')
    public caseManagement: string;

    @includeMany('lwcasnum', 'casenum')
    public caseNumbers: string[];

    @includeMany('lwccirc', Circumstance)
    public circumstances: Circumstance[];

    @expand('ccode', 'desc')
    public clearance: string;

    @expand('nameid', NameReference)
    public complainant: NameReference | string;

    @field('contact')
    public contact: string;

    @expand('dispos', 'desc')
    public disposition: string;

    @field('dispdat')
    public dispositionDate: Date;

    @expand('howrc', 'desc')
    public howReceived: string;

    @expand('jstat', 'desc')
    public judicialStatus: string;

    @field('misc')
    public miscEntry: string;

    @includeMany('lwmodop', ModusOperandi)
    public modusOperandi: ModusOperandi[];

    @includeOne('lwnarr', 'narratv')
    public comments: string;

    @expand('nature', 'desc')
    public nature: string;

    @field('number')
    public number: string;

    @expand('offeobs', 'desc')
    public observed: string;

    @field('ocurdt1')
    public occurredFrom: Date;

    @field('ocurdt2')
    public occurredTo: Date;

    @includeMany('lwoffs', Offense)
    public offenses: Offense[];

    public radioLogs: RadioLog[];

    @expand('rcvby', 'fullnam')
    public receivedBy: string;

    @expand('offetkn', 'desc')
    public reported: string;

    @includeMany('lwrespo', RespondingOfficer)
    public respondingOfficers: RespondingOfficer[];

    @expand('respoff', 'fullnam')
    public responsibleOfficer: string;

    public statutes: string[];

    @includeMany('lwsupl', Supplement)
    public supplements: Supplement[];

    @field('dtrepor')
    public whenReported: Date;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
