/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IHttpService = angular.IHttpService;
import Unit from '../../schema/Unit';
import DecoratorToResponseTransformAdapter from '../../shared/transforms/DecoratorToResponseTransformAdapter';
import UnitGetTransform from './enums/UnitGetTransform';
import UnitQueryTransform from './enums/UnitQueryTransform';
import UnitResourceClass from './UnitResourceClass';
import ComputedUrlResource from '../../resource/computedUrl/ComputedUrlResource';
import IHttpResponseTransformer = angular.IHttpResponseTransformer;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
UnitAPI.$inject = ['computedUrlResource', '$http', 'getFirstElementTransform', 'decoratorToResponseTransformAdapter'];

/**
 * A factory function that creates an API for retrieving CAD Units from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @param $http The Angular service that makes http requests.
 * @param getFirstElementTransform A response transform that returns only the first element from the array.
 * @param decoratorToResponseTransformAdapter An adapter that creates a response transform that loops through an array of decorator transforms.
 * @returns {IResourceClass<Unit>} A resource class that knows how to load CAD Units from the REST server.
 */
function UnitAPI(
    computedUrlResource: ComputedUrlResource,
    $http: IHttpService,
    getFirstElementTransform: IHttpResponseTransformer,
    decoratorToResponseTransformAdapter: DecoratorToResponseTransformAdapter<Unit>
): UnitResourceClass {
    return <UnitResourceClass>computedUrlResource<Unit>({ path: '/tables/syunit/:id' }, {}, {
        get: {
            method: 'GET',
            transformResponse: angular.appendTransform($http.defaults.transformResponse, getFirstElementTransform, decoratorToResponseTransformAdapter.createTransform(UnitGetTransform))
        },
        getCoordinates: {
            method: 'GET',
            params: {
                fields: 'xpos,ypos'
            },
            transformResponse: angular.appendTransform($http.defaults.transformResponse, getFirstElementTransform)
        },
        query: {
            method: 'GET',
            isArray: true,
            transformResponse: angular.appendTransform($http.defaults.transformResponse, decoratorToResponseTransformAdapter.createTransform(UnitQueryTransform))
        }
    });
}

export default UnitAPI;
