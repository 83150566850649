/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IResourceClass = angular.resource.IResourceClass;
import ActionDescriptorMap = angular.resource.ActionDescriptorMap;
import IResourceArray = angular.resource.IResourceArray;
import SingleResourceArrayPager from './SingleResourceArrayPager';
import IInjectorService = angular.auto.IInjectorService;
import toPairs = require('lodash/toPairs');

/**
 * A class that links up a resource class
 */
export default class ResourceArrayPagerLinker<T> {

    public static $inject = ['defaultResourceActions', '$injector'];

    /**
     * Constructs a new instance of the ResourceArrayPagerLinker class.
     *
     * @param _defaultResourceActions The map of default actions that all resources inherit.
     * @param _$injector The Angular injector service.
     */
    constructor(
        private _defaultResourceActions: ActionDescriptorMap,
        private _$injector: IInjectorService
    ) {
    }

    /**
     * Configures the provided resource class so that every method that returns a resource array will automatically
     * have a ResourceArrayPager linked to that resource array.
     *
     * @param resourceClass A resource class that will have a pager linked to any resource arrays it creates.
     * @param actions The array of custom actions that have been defined on the resource class.
     */
    public link(resourceClass: IResourceClass<T>, actions: ActionDescriptorMap) {
        // Make sure that all actions are accounted for by including the default actions.
        const allActions: ActionDescriptorMap = angular.extend({}, this._defaultResourceActions, actions);

        // Create an array that contains the names of those actions that can be paged -- the ones that return an array.
        const pageableActionNames = toPairs(allActions)
            .filter(([, descriptor]) => descriptor.isArray && descriptor.method === 'GET')
            .map(([action]) => action);

        // For each pageable action, save off the original method and replace it with the new one.
        for (let actionName of pageableActionNames) {
            const originalMethod = resourceClass[actionName].bind(resourceClass);
            resourceClass[actionName] = this.interceptAndCallOriginal.bind(resourceClass, originalMethod, this._$injector);
        }
    }

    /**
     * Intercepts the original method call and creates a pager for the resource array before it is returned.
     * The original method is still called - we just do some extra setup before and after.
     *
     * @param originalMethod The original method that creates a ResourceArray.
     * @param $injector
     * @returns {IResourceArray<T>} A resource array that has been decorated with a pager object.
     */
    private interceptAndCallOriginal(originalMethod: Function, $injector: IInjectorService): IResourceArray<T> {
        // Grab all of the other arguments that come after the named arguments.
        const argumentsArray = Array.prototype.slice.call(arguments, 2);

        // Find the index of the success function, which, if it exists at all, is the first argument that is a function.
        let successFunctionIndex = argumentsArray.findIndex(angular.isFunction);

        // Declare the pager variable here so that it can be captured by the success function.
        let pager: SingleResourceArrayPager<T>;

        if (successFunctionIndex >= 0) {
            const originalSuccessFunction = argumentsArray[successFunctionIndex];

            // Replace the existing success function so that it notifies the pager.
            argumentsArray[successFunctionIndex] = function () {
                pager.handleSuccessfulResponse.apply(pager, arguments);
                originalSuccessFunction.apply(this, arguments);
            };
        } else {
            // In this case there is no success function, so add one.
            argumentsArray.push(function () {
                pager.handleSuccessfulResponse.apply(pager, arguments);
            });

            // Update the index of the success function.
            successFunctionIndex = argumentsArray.length - 1;
        }

        // Ensure that there is a parameters object (even if it's empty).
        // We need to be able to add the nextId when retrieving additional pages.
        if (successFunctionIndex === 0) {
            argumentsArray.unshift({});
        }

        // Finally!!! create the resource array.
        const resourceArray = <IResourceArray<T>>originalMethod(...argumentsArray);

        // This function will call the original method that created the resource array in the first place,
        // but it will also pass along the nextId so that the next page of results are returned.
        const queryFunction = (nextId: string, recordIndex: string) => {
            argumentsArray[0].nextId = nextId;
            argumentsArray[0].recordIndex = recordIndex;
            return originalMethod(...argumentsArray);
        };

        // Instantiate the pager.
        pager = $injector.instantiate<SingleResourceArrayPager<T>>(SingleResourceArrayPager, {
            resourceArray,
            queryFunction
        });

        return resourceArray;
    }
}
