/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IPromise = angular.IPromise;
import IIntervalService = angular.IIntervalService;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;
import ColorAssigner from './ColorAssigner';
import AggregatorCadCall from '../schema/AggregatorCadCall';
import ApplicationScope from '../ApplicationScope';
import CADModel from '../schema/interfaces/CADModel';
import { AddressAlertsBatchRequest } from '../api/address-alerts/address-alerts-batch-request.service';

/**
 * The base controller for CAD list screens.
 */
abstract class CADListController {

    /**
     * The interval in which the data will be refreshed.
     * This only applies to the data for which we are explicitly querying -- not the data pushed out by the aggregator.
     */
    public static REFRESH_INTERVAL = 30 * 1000;

    /**
     * The text that is used to filter the items in the list.
     * Only those items that contain the text will remain.
     */
    public filterText: string;

    /**
     * The array of filtered items from a list.
     */
    public filteredItems: CADModel[];

    /**
     * The number of items to display using the "limitTo" filter.
     */
    public itemsToDisplay: number;
    /**
     * The statement that check if scroll to top button should be showed.
     */
    public isScrolling: boolean;

    /**
     * The previous count of filtered items.
     */
    private previousFilteredItemCount: number;

    /**
     * A variable holding the set base value for items to display.
     */
    private pageSize = 10;

    /**
     * The promise that represents the interval task that automatically requests address alerts.
     */
    private intervalPromise: IPromise<any>;

    /**
     * Constructs a new instance of the CallListController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param colorAssigner The object that assigns a color based on the status of the CAD call.
     * @param addressAlertsBatchRequest The service that retrieves the address alerts for all cad calls.
     * @param $interval The Angular service that repeatedly performs some action on a regular interval.
     * @param updateEventName The name of the event that signals that the models in the repository have been updated.
     * @param $ionicScrollDelegate A delegate used to control the ion-scroll directive.
     */
    constructor(
        private $scope: ApplicationScope,
        public colorAssigner: ColorAssigner,
        private addressAlertsBatchRequest: AddressAlertsBatchRequest<AggregatorCadCall>,
        private $interval: IIntervalService,
        updateEventName: string,
        private $ionicScrollDelegate: IonicScrollDelegate
    ) {
        // Listen for changes to the repository so that we can update the view.
        $scope.$on(updateEventName, this.queryForAddressAlerts);

        // Perform the initial update.
        $scope.$on('$ionicView.beforeEnter', this.queryForAddressAlerts);

        // Start the interval whenever this view becomes active.
        $scope.$on('$ionicView.enter', this.startInterval);

        // Stop the interval whenever this is no longer the active view.
        $scope.$on('$stateChangeStart', this.stopInterval);
        $scope.$on('$destroy', this.stopInterval);
    }

    /**
     * Gets the array of properties that are displayed on the screen.
     * Only those properties that are displayed will be searched when filtering.
     */
    public get displayProperties(): string[] {
        throw new Error('Abstract Property');
    }

    /**
     * Calculates if more items can be loaded.
     *
     * @param filteredItems An array of CAD items which can be displayed.
     *
     * @returns {boolean} A boolean signifying if more items can be displayed.
     */
    public canLoadMore = (): boolean => {
        if (this.filteredItems === undefined) {
            this.filteredItems = [];
        }

        // Check if the current number of filtered items matches the previous number.
        if (this.previousFilteredItemCount !== this.filteredItems.length) {
            this.$ionicScrollDelegate.scrollTop();

            // Reset the number of items to display.
            this.itemsToDisplay = this.pageSize;

            // Match the previousFilteredItemCount to the current number of filteredItems.
            this.previousFilteredItemCount = this.filteredItems.length;
        }
        return this.itemsToDisplay < this.filteredItems.length;
    };

    /**
     * Increments the variable used by the "limitTo" filter.
     */
    public loadMore = (): void => {
        if (this.itemsToDisplay < (this.filteredItems?.length ?? 0)) {
            this.itemsToDisplay += this.pageSize;
        }
        this.$scope.$broadcast('scroll.infiniteScrollComplete');
    };

    /**
     * Check scroll position.
     */
    public checkScroll() {
        if (this.$ionicScrollDelegate.getScrollPosition().top > 0) {
            this.isScrolling = true;
        } else {
            this.isScrolling = false;
        }
    }
    /**
     * Gets an array of CAD Calls for which to retrieve address alerts.
     *
     * @returns {AggregatorCadCall[]} The array of CAD Calls for which to retrieve address alerts.
     */
    protected abstract getCalls(): AggregatorCadCall[];

    /**
     * Performs a single query that retrieves the address alerts for all CAD calls at once.
     */
    private queryForAddressAlerts = (): void => {
        const calls = this.getCalls();
        this.addressAlertsBatchRequest.query(calls);
    };

    /**
     * Starts an interval to periodically re-query for the address alerts.
     */
    private startInterval = (): void => {
        if (!this.intervalPromise) {
            this.intervalPromise = this.$interval(this.queryForAddressAlerts, CADListController.REFRESH_INTERVAL);
        }
    };

    /**
     * Stops the interval that queries for address alerts.
     */
    private stopInterval = (): void => {
        if (this.intervalPromise) {
            this.$interval.cancel(this.intervalPromise);
            this.intervalPromise = undefined;
        }
    };
}

export default CADListController;
