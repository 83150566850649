/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IHttpService = angular.IHttpService;
import UnitOfficer from '../../schema/UnitOfficer';
import UnitOfficerResourceClass from './UnitOfficerResourceClass';
import ComputedUrlResource from '../../resource/computedUrl/ComputedUrlResource';
import IHttpResponseTransformer = angular.IHttpResponseTransformer;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
UnitOfficerAPI.$inject = ['computedUrlResource', '$http', 'getFirstElementTransform'];

/**
 * A factory function that creates an API for retrieving Unit Officers from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @param $http The Angular service that makes http requests.
 * @param getFirstElementTransform A response transform that returns only the first element from the array.
 * @returns {UnitOfficerResourceClass} A resource class that knows how to load Unit Officers from the REST server.
 */
function UnitOfficerAPI(
    computedUrlResource: ComputedUrlResource,
    $http: IHttpService,
    getFirstElementTransform: IHttpResponseTransformer
): UnitOfficerResourceClass {
    return <UnitOfficerResourceClass>computedUrlResource<UnitOfficer>({ path: '/tables/cdunito' }, {}, {
        getFirst: {
            method: 'GET',
            params: {
                sort: 'seq',
                pageSize: 1
            },
            transformResponse: angular.appendTransform($http.defaults.transformResponse, getFirstElementTransform)
        }
    });
}

export default UnitOfficerAPI;
