/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { injectionCondition } from '../../conditional-injection';
import { SessionTimeout } from './session-timeout';
import { UrlFactory } from '../../authentication';

/**
 * A service which retrieves the session timeout from the server.
 */
@Injectable()
@injectionCondition({ minServerVersion: '1701' })
export class SessionTimeoutService extends SessionTimeout {

    constructor(
        private http: Http,
        private urlFactory: UrlFactory
    ) {
        super();
    }

    /**
     * @inheritdoc
     */
    protected getSessionTimout() {
        return this.http.get(this.urlFactory.create({ path: '/setting/System.Client.Touch.SessionTimeout' })).map(response => response.json().data);
    }
}
