/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, includeOne, involvements, involvementsAlerts } from '../../../../shared/database/decorators';
import { NameReference } from '../../../shared/model/name-reference';
import { PropertyLocalId } from './property-local-id';
import { CustodyChange } from './custody-change';
import Involvement from '../../../../schema/Involvement';
import { IncidentReference } from '../../../shared/model/incident-reference';
import { Characteristic } from './characteristic';

/**
 * The property model
 */
@table('prmain')
export class Property {

    @field('accuamt')
    public accumulativeAmountRecovered: number;

    @field('agency')
    public agency: string;

    @field('brand')
    public brand: string;

    @includeMany('prmchar', Characteristic)
    public characteristics: Characteristic[];

    @expand('color', 'desc')
    public color1: string;

    @expand('color2', 'desc')
    public color2: string;

    @includeOne('prmdesc', 'text')
    public comments: string;

    @field('labnum')
    public crimeLabNumber: string;

    @includeMany('prcrec', CustodyChange)
    public custodyChanges: CustodyChange[];

    @field('recovdt')
    public dateRecovered: Date;

    @field('reldate')
    public dateReleased: Date;

    @expand('ucr', 'desc')
    public ibrUcrCode: string;

    @field('quant')
    public ibrUcrQuantity: string;

    @expand('status', 'desc')
    public status: string;

    @field('value')
    public ibrUcrTotalValue: number;

    @field('number')
    public id: string;

    @field('item')
    public item: string;

    @includeMany('prlocal', PropertyLocalId)
    public localIds: PropertyLocalId[];

    @expand('lstatus', 'desc')
    public localStatus: string;

    @expand('meas', 'desc')
    public measurement: string;

    @field('model')
    public model: string;

    @expand('respoff', 'fullnam')
    public officer: string;

    @expand('ownerid', NameReference)
    public owner: NameReference | string;

    @field('ownrnum')
    public ownerAppliedNumber: string;

    @expand('relby', 'fullnam')
    public releasedBy: string;

    @expand('relto', 'fullnam')
    public releasedTo: string;

    @field('serial')
    public serialNumber: string;

    @field('stolndt')
    public statusDate: Date;

    @field('storloc')
    public storageLocation: string;

    @field('tagnum')
    public tagNumber: string;

    @expand('incnumb', IncidentReference)
    public ucrIncident: IncidentReference | string;

    @field('year')
    public year: number;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
