/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import { SpillmanLocalStorage } from '../shared/storage/spillman-local-storage';
import Credentials from './Credentials';

/**
 * A class that handles persistent storage of the user's credentials.
 * The credentials are only saved if the `remember` flag is set to true.
 * The password is never saved.
 */
export default class CredentialsStorage {

    /**
     * A constant that specifies the key used when saving the credentials to localStorage.
     */
    public static CREDENTIALS_KEY = 'credentials';

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['spillmanLocalStorage'];

    /**
     * Constructs a new instance of the CredentialsStorage class.
     *
     * @param spillmanLocalStorage The object that handles storing Spillman-specific data in local storage.
     */
    constructor(private spillmanLocalStorage: SpillmanLocalStorage) {
    }

    /**
     * Retrieves the saved credentials.
     *
     * @returns {Credentials} The saved credentials or an empty object if no credentials are saved.
     */
    public retrieve(): Credentials {
        let credentials = this.spillmanLocalStorage.getObject({ key: CredentialsStorage.CREDENTIALS_KEY }) || {};

        // The password should not have been saved, but just in case, delete it.
        delete credentials.password;

        // Only if the remember flag is true, should we use the credentials.
        return credentials.remember ? credentials : {};
    }

    /**
     * Replaces the saved credentials with the new ones if the 'remember' flag is true.
     * Removes the saved credentials if the 'remember' flag is false.
     *
     * @param credentials The new credentials to be saved.
     */
    public update(credentials: Credentials): void {
        // Save or remove the credentials based on the remember flag.
        if (credentials.remember) {
            // Make a copy of the credentials and then delete the password before saving it in localStorage.
            // The copy is necessary because the login may have failed and we don't want to make the user enter the password again.
            let credentialsCopy = angular.copy(credentials);
            delete credentialsCopy.password;
            this.spillmanLocalStorage.setObject({ key: CredentialsStorage.CREDENTIALS_KEY }, credentialsCopy);
        } else {
            this.spillmanLocalStorage.remove({ key: CredentialsStorage.CREDENTIALS_KEY });
        }
    }
}
