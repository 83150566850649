/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IResourceClass = angular.resource.IResourceClass;
import IHttpService = angular.IHttpService;
import CadCallComment from '../schema/CadCallComment';
import ComputedUrlResource from '../resource/computedUrl/ComputedUrlResource';
import IHttpResponseTransformer = angular.IHttpResponseTransformer;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
CadCallCommentAPI.$inject = ['computedUrlResource', '$http', 'getFirstElementTransform'];

/**
 * A factory function that creates an API for retrieving CAD Calls from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @param $http The Angular service that makes http requests.
 * @param getFirstElementTransform A response transform that returns only the first element from the array.
 * @returns {IResourceClass<CadCallComment>} A resource class which knows how to interact with CAD Comments on the REST server.
 */
function CadCallCommentAPI(computedUrlResource: ComputedUrlResource,
    $http: IHttpService,
    getFirstElementTransform: IHttpResponseTransformer): IResourceClass<CadCallComment> {
    return computedUrlResource<CadCallComment>({
        path: '/CAD/calls/:id/comments'
    }, {}, {
        save: {
            method: 'POST',
            transformResponse: angular.appendTransform($http.defaults.transformResponse, getFirstElementTransform)
        }
    });
}

export default CadCallCommentAPI;
