/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { RequestArgs } from '@angular/http/src/interfaces';
import { UrlFactory } from '../authentication';

/**
 * A class that builds a request to send to the server via the Http service.
 */
export abstract class RequestCreator<TConfig> {

    /**
     * Constructs a new instance of the RequestCreator class.
     *
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     */
    constructor(protected urlFactory: UrlFactory) {
    }

    /**
     * Creates the request to send to the server via the Http service.
     *
     * @param config The configuration required to build the request.
     * @returns A Request to send to the server via the Http service.
     */
    public abstract create(config: TConfig): RequestArgs;
}
