/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import FiltersStorage from '../../calls/filtering/CallFiltersStorage';
import CustomFilterByTypes from '../custom-filters/CustomFilterByTypes';

export default class CallCustomFilterByTypes extends CustomFilterByTypes {
    public static $inject = ['callFiltersStorage'];

    constructor(filtersStorage: FiltersStorage ) {
        super(filtersStorage);
    }
}
