/* Copyright © 2021 Motorola Solutions, Inc. All rights reserved. */

import { UrlFactory } from '../../authentication';
import { IHttpPromise, IHttpService } from 'angular';

export class PushNotificationApi {

    public static $inject = ['$http', 'urlFactory'];

    /**
     * Constructs a new instance of the PushNotificationApi class.
     *
     * @param $http The Angular service that makes http requests.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     */

    constructor(private $http: IHttpService,
        private urlFactory: UrlFactory) {
    }

    /**
     * subscribeOnNotifications - the HTTP request to subscribe the user in push notifications
     *
     * @param username - user name
     * @param unit - unit name
     * @param fcmToken - Firebase token
     * @param userCallIds - CAD call id's that Unit currently assigned
     */

    public subscribeOnNotifications(username: string, unit: string, fcmToken: string, userCallIds: string[]): IHttpPromise<any> {
        const params = {
            username,
            unit,
            fb_token: fcmToken,
            calls: userCallIds.join(',')
        };

        const url = this.urlFactory.create({path: '/notifications/add'});

        return this.$http({url, params, method: 'post'});
    }

    /**
     * unsubscribeFromNotifications - the HTTP request to unsubscribe the user from push notifications
     *
     * @param username - user name
     */

    public unsubscribeFromNotifications(username: string): IHttpPromise<any> {
        const url = this.urlFactory.create({path: '/notifications/remove'});

        return this.$http({url, method: 'post', params: {username}});
    }
}
