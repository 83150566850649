/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * The enum that specifies the set of decorator transforms to apply when calling the
 * `query` action on the UnitAPI.
 */
enum UnitQueryTransform {
    unitFirstOfficerDecoratorTransform
}

export default UnitQueryTransform;
