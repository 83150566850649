/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IRootScopeService = angular.IRootScopeService;
import IQService = angular.IQService;
import ILogService = angular.ILogService;
import Session from '../../../login/Session';
import DecoratorTransform from '../../../shared/transforms/DecoratorTransform';
import Repository from '../../repository/Repository';
import AggregatorUnit from '../../../schema/AggregatorUnit';
import CadConnection from '../../CadConnection';

/**
 * A repository that stores CAD units that have been pushed from the server via a WebSocket.
 */
export default class UnitRepository extends Repository<AggregatorUnit> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        'cadConnection',
        '$rootScope',
        '$q',
        '$log',
        'session',
        'allUnitTransforms'
    ];

    /**
     * Constructs a new UnitRepository.
     *
     * @param cadConnection The connection to the CAD data feed.
     * @param $rootScope The Angular $rootScope that can be used to listen to application events.
     * @param $q The angular service that handles creating and working with promises.
     * @param $log The Angular service that performs logging.
     * @param session The object that stores information about the current user's session.
     * @param allUnitTransforms The array of transforms to apply to a Unit whenever it is added or modified.
     */
    constructor(
        cadConnection: CadConnection,
        $rootScope: IRootScopeService,
        $q: IQService,
        $log: ILogService,
        session: Session,
        allUnitTransforms: DecoratorTransform<AggregatorUnit>[]
    ) {
        super(cadConnection, $rootScope, $q, $log, session, allUnitTransforms, {
            dataProperty: 'unitData',
            refreshFlag: 'fullUnit',
            idProperty: 'unit',
            dataFeedType: 'U',
            repositoryName: 'UnitRepository'
        });
    }
}
