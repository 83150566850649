/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../shared/database/decorators';
import { NameReference } from '../../../shared/model';

/**
 * The involved inmate model
 */
@table('jlicinmt')
export class InvolvedInmate {

    @expand('inmate.namenum', NameReference)
    public name: NameReference | string;
}
