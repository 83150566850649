/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

// TODO: Evaluate what our Public API should be for this library.
export * from './url';
export { ApiAuthenticationConfig } from './api-authentication-config';
export { AuthenticationModule } from './authentication.module';
export { AuthenticationService } from './authentication.service';
export { AuthenticationTokenService } from './authentication-token.service';
export { ReAuthenticationService } from './re-authentication.service';
export { Credentials } from './credentials';
export { CredentialsRepository } from './credentials-repository.service';
export { LogoutService } from './logout.service';
export { SetAuthenticationHeadersService, authorizationHeaderName } from './set-authentication-headers.service';
export { originalHttpToken } from './original-http-token';
