/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Observable } from 'rxjs/Observable';
import { AuthenticationTokenService } from './authentication-token.service';
import { SetAuthenticationHeadersService } from './set-authentication-headers.service';

/**
 * The name of the authorization header.
 */
export const authorizationHeaderName = 'Authorization';

/**
 * An abstract base class that defines a service that authenticates and authenticates
 * and then automatically configures the Http service to use the authentication.
 *
 * @template T The type of parameters that needs to be passed in.
 */
export abstract class AuthenticationService<T> {

    /**
     * Constructs a new instance of the AuthenticationService.
     *
     * @param tokenService The service that gets the authentication token.
     * @param setAuthenticationHeadersService The service which sets the security headers for angular 1 and 2.
     */
    constructor(
        private tokenService: AuthenticationTokenService<T>,
        private setAuthenticationHeadersService: SetAuthenticationHeadersService
    ) {
    }

    /**
     * Authenticates the user with the server.
     *
     * @param param The information necessary to retrieve a token.
     */
    public authenticate(param: T): Observable<void> {
        return this.tokenService
            .getToken(param)
            .map(token => this.setAuthenticationHeadersService.setHeaders(token));
    }
}
