/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * The default display values for the three states (including undefined) of the boolean.
 */
export const DEFAULT_TRUE_DISPLAY_VALUE = 'Yes';
export const DEFAULT_FALSE_DISPLAY_VALUE = 'No';
export const DEFAULT_UNDEFINED_DISPLAY_VALUE = '';

/**
 * A pipe that converts a boolean to a string.
 * Three separate values of the boolean are considered:
 * 1. true
 * 2. false
 * 3. undefined
 *
 * Undefined is considered a separate value from false. Of course, if you wish
 * to treat undefined as false, just use the same display value for both.
 *
 * Usage:
 *   boolean | sdsBoolean
 *
 * Example:
 *   {{ true | sdsBoolean }}
 *   outputs: Yes
 *
 *   {{ false | sdsBoolean }}
 *   outputs: No
 *
 *   {{ undefined | sdsBoolean:undefined:undefined:"Look at me, I'm undefined" }}
 *   outputs: Look at me, I'm undefined
 */
@Pipe({
    name: 'sdsBoolean'
})
export class BooleanPipe implements PipeTransform {

    /**
     * Converts a boolean value to a string based which of the three following values it has:
     * (1) true, (2) false, or (3) undefined.
     *
     * @param value The boolean value.
     * @param trueDisplayValue (optional) The string to display if the boolean is true.
     * @param falseDisplayValue (optional) The string to display if the boolean is false.
     * @param undefinedDisplayValue (optional) The string to display if the boolean is undefined.
     * @returns A string that represents the boolean.
     */
    public transform(
        value: boolean,
        trueDisplayValue: string = DEFAULT_TRUE_DISPLAY_VALUE,
        falseDisplayValue: string = DEFAULT_FALSE_DISPLAY_VALUE,
        undefinedDisplayValue: string = DEFAULT_UNDEFINED_DISPLAY_VALUE
    ): string {
        if (value === undefined) {
            return undefinedDisplayValue;
        }
        return value ? trueDisplayValue : falseDisplayValue;
    }
}
