/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * The model of an arrest.
 */
@table('jlarrest')
export class Arrest {

    @field('num')
    public id: string;

    @field('date')
    public date: Date;

    @expand('type', 'descrpt')
    public description: string;
}
