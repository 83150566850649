/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { Associate } from './associate';
import { Involvement } from '../../../schema/Involvement';

/**
 * The name association model
 */
@table('nmassoc')
export class NameAssociation {

    @field('number')
    public id: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @field('active')
    public active: boolean;

    @field('desc')
    public comments: string;

    @expand('type', 'desc')
    public type: string;

    @includeMany('nmassodt', Associate)
    public associates: Associate[];
}
