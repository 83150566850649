/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import BaseRegeneratable from '../login/regeneratable/BaseRegeneratable';
import IQService = angular.IQService;
import Session from '../login/Session';
import SettingsRepository from './SettingsRepository';
import Setting from './Setting';

/**
 * If the current user is not assigned to a unit, then this class will loop through all settings
 * and set any setting to its default option if the current option requires that the user have a unit.
 */
class RequiresUnitSettingAdjuster extends BaseRegeneratable {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$q', 'session', 'settingsRepository'];

    /**
     * Constructs a new instance of the RequiresUnitSettingAdjuster class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param session The object that stores information about the current user's session.
     * @param settingsRepository The repository that stores all settings.
     */
    constructor($q: IQService, private session: Session, private settingsRepository: SettingsRepository) {
        super($q);
    }

    // @Override
    protected performInitialization(): void {
        if (!this.session.data.unitNumber) {
            this.settingsRepository.all.forEach(this.setToDefaultIfUnitRequired);
        }
    }

    // @Override
    protected performDestruction(): void {
        // Nothing to do here.
    }

    /**
     * If the setting's current option requires that the user be assigned to a unit
     * then the default option is selected instead.
     *
     * @param setting The setting that may be set to its default value.
     */
    private setToDefaultIfUnitRequired = (setting: Setting<any>): void => {
        let metadata = setting.selectedOption?.metadata;
        if (metadata && metadata.requiresUnit) {
            setting.selectedIndex = setting.defaultIndex;
        }
    };
}

export default RequiresUnitSettingAdjuster;
