/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IHttpService = angular.IHttpService;
import IPromise = angular.IPromise;
import IQService = angular.IQService;
import IHttpRequestConfigHeaders = angular.IHttpRequestConfigHeaders;
import { UrlFactory } from '../../authentication';
import OfficerName from '../../schema/OfficerName';
import Credentials from '../Credentials';
import UserVerification from './interfaces/UserVerification';
import { logErrorIfLoginIsVerbose } from '../../api/analytics/firebase-crashlytics-service';
import { AUTH_EVENTS } from '../authentication/events/authentication-events';
import { logEvent } from '../../api/analytics/firebase-analytics-service';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
UserVerificationFactory.$inject = [
    '$http',
    '$q',
    'urlFactory'
];

/**
 * A method which verifies if the username and password are valid.
 *
 * @param $http The Angular service that makes http requests.
 * @param $q The angular service that handles creating and working with promises.
 * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
 *
 * @returns {UserVerification} An interface describing a function which when resolved
 * returns a promise containing the user.
 */
function UserVerificationFactory($http: IHttpService,
    $q: IQService,
    urlFactory: UrlFactory): UserVerification {

    return (credentials: Credentials, authorization: string): IPromise<OfficerName> => {

        // Create the URL to which to authenticate.
        let url = urlFactory.create({
            server: credentials.server,
            port: credentials.port,
            secureConnection: credentials.secureConnection,
            path: '/tables/apnames'
        });

        // Create the config object to be used with the $http.get config object.
        let config = {
            headers: <IHttpRequestConfigHeaders>{
                Authorization: authorization
            },
            params: {
                filter: 'uidname=' + credentials.username,
                include: 'cdunito',
                expand: 'cdunito.unitno'
            }
        };
        logEvent(AUTH_EVENTS.init_user_verification);
        return $http.get(url, config)

            // The server returns a valid response but there is not an user, then reject the promise.
            .then(response => {
                logEvent(AUTH_EVENTS.success_user_verification);

                return !response.data[0] ? $q.reject() : response.data[0];
            })
            .catch((error) => {
                logEvent(AUTH_EVENTS.fail_user_verification);

                logErrorIfLoginIsVerbose('UserVerificationFactory: ', error);
                return $q.reject('Login Failed');
            });
    };
}

export default UserVerificationFactory;
