/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import curryRight = require('lodash/curryRight');
import padEnd = require('lodash/padEnd');

/**
 * A class that builds a phone number from the individual components.
 * The phone number will be padded using the PAD_CHAR so that it always has the same length.
 * Examples:
 *     (123)456-7890 ????
 *     (???)???-12?? ????
 *     (55?)12?-345? 123?
 */
export class PhoneBuilder {

    public static PAD_CHAR = '?';

    /**
     * Builds the phone number from the given components.
     *
     * @param areaCode The area code
     * @param threeDigits The first three digits of the seven digit number.
     * @param fourDigits The last four digits of the seven digit number.
     * @param extension The extension.
     * @returns {string} The complete phone number with formatting.
     */
    public build(areaCode: string, threeDigits: string, fourDigits: string, extension: string): string {
        // If all of the components are falsy, then just return undefined because there is no phone number.
        if (!areaCode && !threeDigits && !fourDigits && !extension) {
            return undefined;
        }

        let curriedPad = curryRight(this.pad);
        let pad3 = curriedPad(3);
        let pad4 = curriedPad(4);

        return `(${pad3(areaCode)})${pad3(threeDigits)}-${pad4(fourDigits)} ${pad4(extension)}`;
    }

    /**
     * Pads the given component so that is the as long as the given length.
     *
     * @param component The phone number component to pad.
     * @param length The padding length.
     * @returns {string} The padded phone component.
     */
    private pad(component: string, length: number): string {
        return padEnd(component || '', length, PhoneBuilder.PAD_CHAR);
    }
}
