/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAttributes = angular.IAttributes;
import IonicPopoverService = ionic.popover.IonicPopoverService;
import { createPopover } from './popover-create-helpers';
/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
TextWrappedPopoverDirective.$inject = ['$ionicPopover'];

/**
 * Creates a directive that will validate that some text is wrapped an by click on it some
 * popover with fill text will appear.
 *
 * @returns {IDirective} A directive that validates that the text is wrapped.
 *
 * This directive is used for Angular.js components
 */
export default function TextWrappedPopoverDirective($ionicPopover: IonicPopoverService): IDirective {

    return {
        link: function (_scope: IScope, _element: IAugmentedJQuery, _attributes: IAttributes) {
            const element = _element.get()[0];
            createPopover( {element}, $ionicPopover);

        }
    };
}
