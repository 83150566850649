/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IIntervalService = angular.IIntervalService;
import IPromise = angular.IPromise;
import PropertyChangedQuery from '../shared/PropertyChangedQuery';
import CADModel from '../schema/interfaces/CADModel';
import ColorAssigner from './ColorAssigner';
import Repository from './repository/Repository';
import CADDetailsScope from './CADDetailsScope';
import LocationAdapter from '../shared/mapping/LocationAdapter';
import { QueryFactory } from './query-factory';
import IncidentHistoryCallQuery from './calls/details/queries/IncidentHistoryCallQuery';

/**
 * The base controller for CAD details screens.
 */
export default class CADDetailsController<T extends CADModel> {

    /**
     * The interval in which the data will be refreshed.
     * This only applies to the data for which we are explicitly querying -- not the data pushed out by the aggregator.
     */
    public static REFRESH_INTERVAL = 30 * 1000;

    /**
     * The array of all queries that need to be made to get data not pushed out by the aggregator.
     */
    private allQueries: PropertyChangedQuery<any>[];

    /**
     * The array of all queries that need to be made to get data not pushed out by the aggregator.
     */
    private refreshQueries: PropertyChangedQuery<any>[];

    /**
     * An array of functions that will deregister all $scope.$watches.
     */
    private deregistrationFunctions: Function[];

    /**
     * The promise that can be used to cancel the $interval.
     */
    private intervalPromise: IPromise<any>;

    /**
     * The service that can be used to load incident history data.
     */

    private incidentHistoryCallQuery: PropertyChangedQuery<any>;

    /**
     * Constructs a new instance of the CADDetailsController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param repository The repository that stores models pushed from the server.
     * @param colorAssigner The object that assigns a color based on the status of the model.
     * @param queryFactory The factory that creates the queries used to get data not pushed out by the aggregator.
     * @param $interval The Angular service that repeatedly performs some action on a regular interval.
     * @param locationAdapter The adapter that converts a CADModel to a Location.
     * @param primaryKey The primary key of the CAD model to display.
     * @param modelName The name of the model on the $scope (e.g. 'call' or 'unit').
     * @param locationProperties The array of property names that need to be watched in order to update the location.
     */
    constructor(
        protected $scope: CADDetailsScope,
        repository: Repository<T>,
        colorAssigner: ColorAssigner,
        queryFactory: QueryFactory<PropertyChangedQuery<any>>,
        private $interval: IIntervalService,
        private locationAdapter: LocationAdapter<T>,
        primaryKey: string,
        private modelName: string,
        locationProperties: string[]
    ) {
        this.allQueries = queryFactory.create(true);
        this.refreshQueries = queryFactory.create(false);
        $scope.colorAssigner = colorAssigner;

        // Note that $watch and $watchGroup each return a deregistration function. So we're storing them all in an array.
        this.deregistrationFunctions = [
            // This sets the initial model on the scope. It also watches for the model instance to change.
            // This will happen if the server decides to do a full-refresh (which happens quite regularly).
            $scope.$watch(() => repository.modelMap[primaryKey], this.updateModel),

            // This does not perform a query, but rather just packages up the location information into a Location object.
            $scope.$watchGroup(locationProperties.map(p => `${modelName}.${p}`), this.updateLocation)
        ];

        // Register each of the queries and store the deregistration functions as well.
        this.deregistrationFunctions.pushAll(this.allQueries.map(q => q.register($scope)));
        // Store off the $scope for later.
        this.refreshQueries.map(q => q['_$scope'] = $scope); // eslint-disable-line dot-notation,@typescript-eslint/dot-notation

        // Start the interval whenever this view becomes active.
        $scope.$on('$ionicView.enter', this.startInterval);

        // Stop the interval whenever this is no longer the active view.
        $scope.$on('$stateChangeStart', this.stopInterval);
        $scope.$on('$destroy', this.stopInterval);
    }

    /**
     * Load Incident history data step by step using paginations and incidentHistoryCallQuery service
     */
    public loadHistoricalData = () => {
        if (!this.incidentHistoryCallQuery) {
            this.incidentHistoryCallQuery = this.allQueries.find(item => item instanceof IncidentHistoryCallQuery);
        }

        this.incidentHistoryCallQuery.query();
    };

    /**
     * Sets the model on the scope so that it can be displayed.
     *
     * @param newModel The new model.
     * @param oldModel The old model.
     */
    private updateModel = (newModel: T, oldModel: T): void => {
        if (!newModel) {
            // The model has been removed -- stop the refresh interval and all watches.
            this.stopInterval();
            this.deregistrationFunctions.forEach(f => f());
            this.$scope.removed = true;
            return;
        }

        this.$scope[this.modelName] = newModel;

        // The newModel will equal the oldModel only if this is being called for the first time when this class is instantiated.
        // In that case we don't need to do a refresh here because they will be handled by the $watches in the constructor.
        if (newModel !== oldModel) {
            this.refresh();
        }
    };

    /**
     * Refreshes the data by re-querying for all data that is not pushed out by the aggregator.
     */
    private refresh = (): void => {
        this.refreshQueries.forEach(q => q.query());
    };

    /**
     * Sets the location on the scope which will in-turn update the google map.
     */
    private updateLocation = (): void => {
        let model = <T> this.$scope[this.modelName];
        this.$scope.location = model ? this.locationAdapter.getLocation(model) : undefined;
    };

    /**
     * Starts an interval to periodically re-query for the data.
     */
    private startInterval = (): void => {

        if (!this.intervalPromise) {
            this.intervalPromise = this.$interval(this.refresh, CADDetailsController.REFRESH_INTERVAL);
        }
    };

    /**
     * Stops the interval that queries for data.
     */
    private stopInterval = (): void => {
        if (this.intervalPromise) {
            this.$interval.cancel(this.intervalPromise);
            this.intervalPromise = undefined;
        }
    };
}
