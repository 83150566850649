/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import LocationAdapter from '../../shared/mapping/LocationAdapter';
import AggregatorCadCall from '../../schema/AggregatorCadCall';
import Location from '../../shared/mapping/Location';

/**
 * A LocationAdapter that obtains the location from a CAD Call.
 */
export default class CallLocationAdapter implements LocationAdapter<AggregatorCadCall> {

    // @Override
    public getLocation(call: AggregatorCadCall): Location {
        if (!call) {
            throw Error('The call cannot be null or undefined.');
        }

        let location: Location = {
            streetAddress: call.location,
            geoid: call.geoid
        };

        let fullCity = call.fullCity;
        if (fullCity) {
            location.city = fullCity.name;
            let state = fullCity.state;
            location.state = (typeof state === 'string') ? state : state.value;
            location.zip = fullCity.zip;
        }

        this.addIfValidNumber('latitude', call, location);
        this.addIfValidNumber('longitude', call, location);
        return location;
    }

    /**
     * Updates the location by adding to it a new property whose value is obtained from the call.
     * The value must be parse-able to a finite number, in order for it to be added to the location.
     *
     * @param propertyName The name of the property on the call as well as the location.
     * @param call The call whose property value will be copied to the location.
     * @param location The location that will be updated with the property value from the call.
     */
    private addIfValidNumber(propertyName: string, call: AggregatorCadCall, location: Location): void {
        let originalValue = call[propertyName];
        let parsedNumber = NaN;

        // If it's a string, then parse it.
        if (typeof originalValue === 'string') {
            let trimmedValue = originalValue.trim();
            if (trimmedValue) {
                parsedNumber = Number(originalValue.trim());
            }
        } else if (angular.isNumber(originalValue)) {
        // If it's a number, then no conversion is necessary.
            parsedNumber = originalValue;
        }

        // Only if the parsed number is finite, will we add it to the location.
        if (isFinite(parsedNumber)) {
            location[propertyName] = parsedNumber;
        }
    }
}
