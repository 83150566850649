/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;

/**
 * A factory function that creates the FilterBarDirective.
 *
 * @returns {IDirective} A directive that displays a textbox inside of a header bar
 *                       where the user can enter text to filter a list of records.
 */
function FilterBarDirective(): IDirective {
    return {
        template: require('./filterBar.html'),
        scope: {
            filterText: '=',
            filterSetting: '='
        }
    };
}

export default FilterBarDirective;
