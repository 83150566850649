/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AssociativeArray } from '../../shared/interfaces/AssociativeArray';
import { PermissionsRepository } from '../PermissionsRepository';

/**
 * A component that conditionally enables a link based on whether the user
 * has permissions to view the page linked to.
 */
@Component({
    selector: 'sds-permissions-link',
    template: require('./permissions-link.component.html'),
    styles: [
        require('./permissions-link.component.scss'),
        require('./permissions-link.component.day.scss'),
        require('./permissions-link.component.night.scss')
    ]
})
export class PermissionsLinkComponent implements OnChanges {

    /**
     * The ui-router state to which the link should navigate.
     */
    @Input()
    public state: string;

    /**
     * The private backing property for the public `id` getter/setter.
     */
    private _id: string | AssociativeArray<string>;

    /**
     * A flag that tracks whether the `id` property has been set at any time.
     */
    private idWasSet: boolean;

    /**
     * The private backing property for the public `url` getter.
     */
    private _url: string;

    /**
     * Constructs a new instance of the PermissionsLinkComponent class.
     *
     * @param $state The service that transitions between states.
     * @param permissionsRepository The repository which stores permissions of a user.
     */
    constructor(
        @Inject('$state') private $state: angular.ui.IStateService,
        @Inject('permissionsRepository') private permissionsRepository: PermissionsRepository
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnChanges(_changes: SimpleChanges): any {
        this.setUrl();
    }

    /**
     * Gets the id, which identifies which record to display.
     *
     * @returns The identifier for the particular record.
     */
    public get id() {
        return this._id;
    }

    /**
     * Sets the id, which identifies which record to display.
     *
     * @param The identifier for the particular record.
     */
    @Input()
    public set id(id: string | AssociativeArray<string>) {
        this.idWasSet = true;
        this._id = id;
    }

    /**
     * @returns The URL that corresponds to the given `state` and `id` that were passed in.
     */
    public get url(): string {
        return this._url;
    }

    /**
     * Sets the URL.
     *
     * This method should be called whenever a change has been detected.
     */
    private setUrl(): void {
        // Check if the user has the required permissions to navigate to the state referenced by the link.
        const allowAccess = this.permissionsRepository.checkStatePermissions(this.state);

        // If the `id` was set at all, then it's required. The link will not be created without it.
        // This handles the case where the record being link to is in another partition.
        // If the `id` was not set, then it's assumed that it's not required and the link will still be created.
        const idIsValid = !this.idWasSet || !!this.id;

        this._url = (allowAccess && idIsValid) ? this.getUrl() : undefined;
    }

    /**
     * Gets the URL that corresponds to the `state` and `id` that were passed into this component.
     *
     * @returns The URL that corresponds to the given `state` and `id`.
     */
    private getUrl(): string {
        const params = (typeof this.id === 'string') ? { id: this.id } : this.id;
        return this.$state.href(this.state, params);
    }
}
