/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../../shared/database/decorators';

/**
 * An internet address.
 */
@table('nminter')
export class InternetAddress {

    @field('address')
    public address: string;

    @field('iatype')
    public type: string;
}
