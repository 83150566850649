/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import replace = require('lodash/replace');

/**
 * Class to Identify and handle operations with phone numbers in text format.
 */
export  class PhoneIdentifier {

    /**
     * This regex identifies US phones in the following formats:
     * +1-732-245-3656, 1-732-245-3656,
     * +1 (732) 245-3656, 17322453656 732-245-3656,
     * (732)245-3656, 7322453656, 732-245365
     */
    private static readonly PHONE_REGEX_ALL = /([+]?\d{1,2}[\s]?[-(\s]?|\(|)\d{3}[-)\s]?[\s]?\d{3}[-\s]?\d{4}/;

    /**
     * checks wheter the passed string is a valid phone or not.
     * Example: isValidNumber('732-245-3656') => true
     *          isValidNumber('name') => false
     *
     * @param phone - the string that will be checked.
     * @return boolean.
     */
    public static isValidNumber(phone: string): boolean {
        const matches = this.PHONE_REGEX_ALL.exec(phone);

        return !!matches;
    }


    /**
     * Extract a phone number from a string if it exists, and returns the number if it's found
     * Example: extractPhoneNumber('this is a test phone number (222) 555 1021') => '(222) 555 1021'
     *
     * @param text - the text to be analized in search for a phone number.
     * @return string.
     */
    public static extractPhoneNumber(text: string): string {
        let phoneNumber = PhoneIdentifier.addPrefixAndSuffixToPhone(text, ':', ';').split(':').pop().split(';')[0];

        if (phoneNumber) {
            return phoneNumber;
        }
        return text;
    }

    /**
     * Adds a prefix and suffix around all phone numbers in the passed text
     * Example: addPrefixAndSuffixToPhone('please call 732-245-3656 for more info', '<i>', '</i>') => 'please call <i>732-245-3656</i> for more info'
     *
     * @param text - the string that will be applied the suffix and prefix.
     * @param prefix - the prefix that will be applied in the identified phone numbers.
     * @param suffix - the suffix that will be applied in the identified phone numbers.
     * @return string.
     */
    public static addPrefixAndSuffixToPhone(text: string, prefix: string = '', suffix: string = ''): string {
        const phoneRegex = /([+]?\d{1,2}[\s]?[-(\s]?|\(|)\d{3}[-)\s]?[\s]?\d{3}[-\s]?\d{4}/g;

        const fullText = replace(text, phoneRegex, prefix + '$&' + suffix);

        return fullText;
    }

    /**
     * Formats the phone number into the following format: (XXX) XXX-XXXX
     * Example: formatToUSFormat('5551231234') => '(555) 123-1234'
     *
     * @param textNumber - the string representing the phone number to be formated.
     * @return string.
     */
    public static formatToUSFormat(textNumber: string): string {
        const phoneRegex = /([+]?1{1,2}[\s]?[-(\s]?|\(|)(\d{3})[-)\s]?[\s]?(\d{3})[-\s]?(\d{4})/g;

        const matches = phoneRegex.exec(textNumber);

        return `(${matches[2]}) ${matches[3]}-${matches[4]}`;
    }
}
