/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Involvable } from '../../schema/interfaces/involvable';
import curry = require('lodash/curry');

/**
 * A curried function that must first be passed an involvement type number.
 * After that it can be used as a response transform to add that number to models retrieved from the server.
 *
 * @param involvementType The involvement type number from the syinvtyp table.
 * @param data The raw data returned from the server that is either an involvable model or an array of such models.
 * @returns The original data that has been modified to include the involvment type number.
 */
export const addInvolvementTypeTransform = curry(function (involvementType: number, data: Involvable | Involvable[]): Involvable | Involvable[] {
    let dataArray = Array.isArray(data) ? data : [data];
    dataArray.forEach(d => d.involvementType = involvementType);
    return data;
});
