/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { Involvement } from '../../../schema/Involvement';
import { Characteristic } from './characteristic';

/**
 * The suspect-vehicle model
 */
@table('vhspmain')
export class SuspectVehicle {

    @includeMany('vhspchar', Characteristic)
    public characteristics: Characteristic[];

    @expand('color1', 'desc')
    public color1: string;

    @expand('color2', 'desc')
    public color2: string;

    @includeOne('vhspdesc', 'comment')
    public comments: string;

    @field('entrydt')
    public date: string;

    @field('doors')
    public doors: number;

    @expand('make', 'desc')
    public make: string;

    @field('model')
    public model: string;

    @field('lpnum')
    public partialPlate: string;

    @field('lpst')
    public state: string;

    @expand('vehityp', 'desc')
    public vehicleType: string;

    @field('year')
    public vehicleYear: number;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
