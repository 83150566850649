/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

export const LOGIN_SUCCESS = 'login_success';
export const UNSECURE_LOGIN_USED = 'unsecure_login_used';

export const LOGIN_FAILED = 'login_failed';

export const TYPE_KEY = 'type';

export const BACKEND_VERSION = 'backend_version';

export const LOGIN_FAILED_TYPES = {
    expired_password: 'expired_password',
    account_locked: 'account_locked',
    unknown: 'unknown'
};
