/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import OtherTelephone from '../schema/OtherTelephone';
import ComputedUrlResource from '../resource/computedUrl/ComputedUrlResource';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
OtherTelephoneAPI.$inject = ['computedUrlResource'];

/**
 * A factory function that creates an API for retrieving "other" telephone numbers from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @returns {IResourceClass<OtherTelephone>} A resource class that knows how to load Other Telephones from the REST server.
 */
function OtherTelephoneAPI(computedUrlResource: ComputedUrlResource): IResourceClass<OtherTelephone> {
    return computedUrlResource<OtherTelephone>({ path: '/tables/nmotel' });
}

export default OtherTelephoneAPI;
