/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import BaseRegeneratable from '../regeneratable/BaseRegeneratable';
import GuaranteedTimeout from '../../shared/guaranteedTimeout/GuaranteedTimeout';
import IonicPopupConfirmPromise = ionic.popup.IonicPopupConfirmPromise;
import IQService = angular.IQService;
import IonicPopupService = ionic.popup.IonicPopupService;
import { SpillmanLocalStorage } from '../../shared/storage/spillman-local-storage';
import IStateService = angular.ui.IStateService;
import IDocumentService = angular.IDocumentService;
import GuaranteedTimeoutFactory from '../../shared/guaranteedTimeout/GuaranteedTimeoutFactory';
import ITimeoutService = angular.ITimeoutService;
import IPromise = angular.IPromise;
import IRootScopeService = angular.IRootScopeService;
import IonicPopupConfirmOptions = ionic.popup.IonicPopupConfirmOptions;
import IScope = angular.IScope;
import { SessionTimeout } from '../session-timeout';
type IonicPopupExtendedConfirmOptions = IonicPopupConfirmOptions & { scope: IScope };

/**
 * A class which starts and moderates the timeout event for when a user is idle.
 */
export default class TimeoutVerification extends BaseRegeneratable {

    public static $inject = [
        '$q',
        '$ionicPopup',
        'spillmanLocalStorage',
        '$state',
        '$document',
        '$timeout',
        '$rootScope',
        'guaranteedTimeoutFactory',
        'sessionTimeout'
    ];

    /**
     * The space-delimited list of events that, as long as they are continually raised, will keep the user's session alive.
     * If none of the events are fired for the amount of time specified by the LOGOUT_TIME, then the user will be automatically logged out.
     */
    public static KEEP_ALIVE_EVENTS = 'mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll';

    /**
     * The amount of time in milliseconds to warn the user before automatically logging them out.
     */
    public static WARNING_TIME = 30 * 1000; // 30 seconds

    /**
     * A guaranteed timeout that will automatically logout the user.
     */
    private _logoutTimeout: GuaranteedTimeout;

    /**
     * A guaranteed timeout that will warn the user before they are logged out.
     */
    private _warnTimeout: GuaranteedTimeout;

    /**
     * A reference to the popup that is shown warning the user that they're about to be logged out.
     */
    private _confirmPopup: IonicPopupConfirmPromise;

    constructor(
        $q: IQService,
        private $ionicPopup: IonicPopupService,
        private spillmanLocalStorage: SpillmanLocalStorage,
        private $state: IStateService,
        private $document: IDocumentService,
        private $timeout: ITimeoutService,
        private $rootScope: IRootScopeService,
        private guaranteedTimeoutFactory: GuaranteedTimeoutFactory,
        private sessionTimeout: SessionTimeout) {
        super($q);
    }

    /**
     * @override
     */
    protected performInitialization(): void {
        this.listenForUserEvents();
    }

    /**
     * @override
     */
    protected performDestruction(): IPromise<void> {

        /*
         * Using a timeout handles the edge case where the confirm popup is shown immediately before logging out.
         * That can happen if the user had their phone locked longer than the LOGOUT_TIME.
         * Since Ionic's popups are shown asynchronously, there is a delay before it is shown.
         * Using a timeout ensures that this code is run after the popup is shown.
         */
        return this.$timeout(() => {
            this.$document.off(TimeoutVerification.KEEP_ALIVE_EVENTS, this.restartTimers);
            this.cancelTimers();
            this.closePopup();
        });
    }

    /**
     * Listens for user events in order to keep the session alive.
     */
    private listenForUserEvents(): void {
        this.$document.on(TimeoutVerification.KEEP_ALIVE_EVENTS, this.restartTimers);
        this.restartTimers();
    }

    /**
     * Restarts the timers by cancelling any previously started timers and then starting them again.
     */
    private restartTimers = (): void => {
        this.cancelTimers();
        this._warnTimeout = this.guaranteedTimeoutFactory.timeout(this.confirmStayLoggedIn, this.sessionTimeout.sessionTimeout - TimeoutVerification.WARNING_TIME);
        this._logoutTimeout = this.guaranteedTimeoutFactory.timeout(() => this.logout(true), this.sessionTimeout.sessionTimeout);
    };

    /**
     * Cancels all timers.
     */
    private cancelTimers = (): void => {
        this.cancelWarningTimer();
        this.cancelLogoutTimer();
    };

    /**
     * Cancels the warning timer only.
     */
    private cancelWarningTimer(): void {
        this.cancelTimer('_warnTimeout');
    }

    /**
     * Cancels the logout timer only.
     */
    private cancelLogoutTimer(): void {
        this.cancelTimer('_logoutTimeout');
    }

    /**
     * Cancels the timer specified by name.
     *
     * @param timerPropertyName The name of the timer property.
     */
    private cancelTimer(timerPropertyName: string): void {
        if (this[timerPropertyName]) {
            this[timerPropertyName].cancel();
            this[timerPropertyName] = undefined;
        }
    }

    /**
     * Closes the popup if it exists.
     */
    private closePopup(): void {
        if (this._confirmPopup) {
            this._confirmPopup.close();
        }
    }

    /**
     * Shows a dialog asking the user whether he/she would like to stay logged in or to log out.
     */
    private confirmStayLoggedIn = (): void => {
        // Stop listening for the keep alive events. The user must respond to the popup in order to stay logged in.
        this.$document.off(TimeoutVerification.KEEP_ALIVE_EVENTS, this.restartTimers);

        // Cancel the warning timer (if there is one), but keep the logout timer going.
        this.cancelWarningTimer();

        const popupScope = <ContinueSessionPopupScope> this.$rootScope.$new();
        popupScope.logoutTimer = this._logoutTimeout;

        // Show a popup that the user must respond to in order to stay logged in.
        this._confirmPopup = this.$ionicPopup.confirm(<IonicPopupExtendedConfirmOptions>{
            title: 'Would you like to stay logged in?',
            template: require('../continueSession.html'),
            okText: 'Yes',
            cancelText: 'No, Logout',
            scope: popupScope
        });

        this._confirmPopup.then(shouldContinue => {
            // `shouldContinue` will be undefined when we programmatically close the popup while logging out.
            // In that case, we don't need to do anything here.
            if (shouldContinue !== undefined) {
                shouldContinue ? this.listenForUserEvents() : this.logout(false);
            }
        });
    };

    /**
     * Logs the user out.
     *
     * @param showSessionTimedOut A flag that indicates if a session-timed-out message should be shown on the login page.
     */
    private logout(showSessionTimedOut: boolean): void {
        if (showSessionTimedOut) {
            // Create a flag for the login screen which states the user has timed out.
            this.spillmanLocalStorage.setObject({ key: 'sessionTimeout' }, true);
        }
        const message = showSessionTimedOut ? 'Your session has expired' : undefined;
        this.$state.go('logout', { message });
        super.destroy();
    }
}

/**
 * An interface that defines the scope for the popup that asks the user
 * if they'd like to continue their session.
 */
interface ContinueSessionPopupScope extends IScope {

    /**
     * The timer that will cause the user to logout when it expires.
     */
    logoutTimer: GuaranteedTimeout;
}
