/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import FiltersStorage from '../storage/FiltersStorage';
import CustomFilterByAgencies from '../custom-filters/CustomFilterByAgencies';

export default class UnitCustomFilterByAgencies extends CustomFilterByAgencies {
    public static $inject = ['unitFiltersStorage'];

    constructor(filtersStorage: FiltersStorage) {
        super(filtersStorage);
    }
}
