/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { LimitedSizeQueueJsonSerializer } from './limited-size-queue-json-serializer';

/**
 * A serializer for the LimitedSizeQueue<string> type.
 */
export class StringLimitedSizeQueueJsonSerializer extends LimitedSizeQueueJsonSerializer<string> {

    /**
     * Constructs a new instance of the StringLimitedSizeQueueJsonReviver class.
     *
     * @param maxSize The maximum number of elements that may be enqueued.
     */
    constructor(maxSize: number) {
        super(maxSize);
    }

    /**
     * @inheritdoc
     */
    protected itemIsCorrectType(item: any): item is string {
        return typeof item === 'string';
    }
}
