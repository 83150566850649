/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import Involvement from '../../../schema/Involvement';

/**
 * The miscellaneous model
 */
@table('inmisc')
export class Miscellaneous {

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @field('desc')
    public description: string;
}
