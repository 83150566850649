/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IInjectorService = angular.auto.IInjectorService;
import ObjectEnumerator from './interfaces/ObjectEnumerator';

/**
 * A class that enhances Angular's dependency injection framework by allowing for multiple
 * services to be injected at once. The services to be injected are specified by an enum.
 */
export default class MultiInjector {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$injector', 'enumEnumerator'];

    /**
     * Constructs a new instance of the MultiInjector class.
     *
     * @param $injector The Angular injector service.
     * @param enumEnumerator The function that enumerates the values of an enum.
     */
    constructor(private $injector: IInjectorService, private enumEnumerator: ObjectEnumerator) {
    }

    /**
     * Injects all services whose names are specified by the values of the enum type.
     *
     * @param enumType The enum type whose values specify the services to inject.
     * @returns {T[]} An array of services.
     */
    public getAll<T>(enumType: Object): T[] {
        return this.enumEnumerator(enumType).map(value => this.$injector.get<T>(value));
    }
}
