/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Observable } from 'rxjs/Observable';

/**
 * An abstract base class that defines a service that check password expiration info.
 *
 * @template T The type of parameters that needs to be passed in.
 */
export abstract class PasswordExpirationService<T> {

    /**
     * Gets the authentication token.
     *
     * @param param The parameter that contains any data needed.
     * @returns An observable that will returns the password expiration info when resolved.
     */
    public abstract checkPassword(param: T): Observable<{message: string}>;
}
