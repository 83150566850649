/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import SessionDataCadFilter from './SessionDataCadFilter';
import CADModel from '../../../schema/interfaces/CADModel';
import Session from '../../../login/Session';
import EqualityComparer from '../../../shared/comparison/EqualityComparer';

/**
 * A class that extends the SessionDataCadFilter and checks if the model value (which must be an array) *contains* the session value.
 */
abstract class ContainsSessionDataCadFilter<TModel extends CADModel, TValue> extends SessionDataCadFilter<TModel, TValue[], TValue> {

    /**
     * Constructs a new instance of the ContainsSessionDataCadFilter class.
     *
     * @param session The object that stores information about the current user's session.
     * @param equalityComparer The comparer that determines if two values are equal.
     */
    constructor(session: Session, private equalityComparer: EqualityComparer<TValue>) {
        super(session);
    }

    // @Override
    protected compare(modelValue: TValue[], sessionValue: TValue): boolean {
        // Using `bind` to create a partial function that has the `sessionValue` pre-specified.
        let equalsSessionValue = this.equalityComparer.equals.bind(this.equalityComparer, sessionValue);
        return modelValue.some(equalsSessionValue);
    }
}

export default ContainsSessionDataCadFilter;
