/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { BaseIncidentListController } from '../list/base-incident-list-controller';
import ApplicationScope from '../../../ApplicationScope';
import Incident from '../../../schema/Incident';
import IonicLoadingService = ionic.loading.IonicLoadingService;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;
import ListStateParams from '../../../shared/interfaces/ListStateParams';
import IPromise = angular.IPromise;
import IResourceArray = angular.resource.IResourceArray;
import ExtendedResourceClass from '../../../api/incident/ExtendedResourceClass';

/**
 * The controller that displays a list of the user's own recent incidents (i.e. "My Incidents").
 */
export class MyIncidentListController extends BaseIncidentListController<Incident> {

    public static $inject = ['$scope', '$ionicLoading', '$timeout', 'incidentAPI', '$stateParams', '$ionicScrollDelegate'];

    /**
     * Constructs a new instance of the MyIncidentListController class.
     *
     * @param $scope The Angular scope object that can listen to and raise events.
     * @param $ionicLoading An overlay that displays a spinner while waiting for data to be loaded.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param incidentAPI The service that retrieves law incidents from the server.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     * @param scrollDelegate The delegate that controls the ion-scroll directive.
     */
    constructor(
        $scope: ApplicationScope,
        $ionicLoading: IonicLoadingService,
        $timeout: angular.ITimeoutService,
        incidentAPI: ExtendedResourceClass<Incident>,
        $stateParams: ListStateParams,
        scrollDelegate: IonicScrollDelegate
    ) {
        super($scope, $ionicLoading, $timeout, incidentAPI, $stateParams, scrollDelegate, 'app.myIncidents.myIncidentsDetails');
    }

    public $onInit = () => {};

    // @Override
    protected performRequest(): IPromise<IResourceArray<Incident>> {
        return (<ExtendedResourceClass<Incident>> this.incidentAPI).recent().$promise;
    }
}
