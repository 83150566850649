/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IHttpService = angular.IHttpService;
import IStateService = angular.ui.IStateService;
import ILogService = angular.ILogService;
import { UrlFactory } from '../authentication';
import BaseRegeneratable from '../login/regeneratable/BaseRegeneratable';
import ObjectEnumerator from '../shared/interfaces/ObjectEnumerator';
import AssociativeArray from '../shared/interfaces/AssociativeArray';
import PermissionName from './enums/PermissionName';
import PermissionAttribute from './enums/PermissionAttribute';
import Permission from '../schema/Permission';
import PermissionAttributeGrouping from '../schema/PermissionAttributeGrouping';
import PermissionGrouping from '../schema/PermissionGrouping';
import PermissionConfiguration from './PermissionConfiguration';
import { RmsPermissionName } from './enums/PermissionName';

/**
 * A repository which stores permission objects and their corresponding attributes for a user.
 */
export class PermissionsRepository extends BaseRegeneratable {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        '$q',
        '$http',
        'urlFactory',
        'enumEnumerator',
        '$state',
        '$log'
    ];

    /** Public variable holding Permission objects retrieved using the permissions API. */
    public permissions: AssociativeArray<Permission>;

    /**
     * Constructs a new PermissionsRepository.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param $http The Angular service that makes http requests.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param enumEnumerator The function that enumerates the values of an enum.
     * @param $state The service that transitions between states.
     * @param $log The Angular service that performs logging.
     */
    constructor(
        protected $q: IQService,
        private $http: IHttpService,
        private urlFactory: UrlFactory,
        private enumEnumerator: ObjectEnumerator,
        private $state: IStateService,
        private $log: ILogService) {
        super($q);
    }

    // @Override
    public performInitialization() {

        // Create the URL to retrieve permissions.
        let url = this.urlFactory.create({
            path: `/permissions/${this.enumEnumerator(PermissionName).join()}`
        });

        return this.$http.get<AssociativeArray<Permission>>(url)
            .then(response => {
                this.permissions = response.data;
                this.enumEnumerator(PermissionAttribute).forEach(atr => this.processRMSPermissions(atr));
            });
    }

    // @Override
    public performDestruction() {

        this.permissions = undefined;
    }

    /**
     * Function which retrieves the state and checks if the user has the required permissions.
     *
     * @param stateName The name of the state to which can be transitioned.
     *
     * @returns {boolean} A boolean stating whether the user has access permissions.
     */
    public checkStatePermissions(stateName: string): boolean {
        // If this class has either not been initialized or been destroyed, then the permissions will be null.
        // In that case, just return false because the user has been logged out and no access should be granted.
        if (!this.permissions) {
            return false;
        }

        let state = this.$state.get(stateName);

        try {
            if (state) {
                if (state.data && 'permissions' in state.data) {
                    return this.processPermissions(state.data.permissions);
                }
                return true;
            } else {
                return false;
            }
        } catch (e) {
            this.$log.error('Invalid permissions configuration for the state', state.name);
            return false;
        }
    }

    /**
     * Determines if the user has all permissions specified by the `permissionValue`.
     * This method differs from the `processPermissions` method because it doesn't throw an error if
     * the `permissionValue` is not set up correctly. Instead it will return the default value.
     *
     * @param permissionValue A Permission, PermissionAttributeGrouping, PermissionGrouping or an array of them.
     * @param defaultOnError The default value that will be returned instead of throwing an error.
     * @returns {boolean} The return value indicating if the user has the proper permissions.
     */
    public processPermissionsWithDefault(permissionValue: PermissionConfiguration, defaultOnError: boolean = false) {
        try {
            return this.processPermissions(permissionValue);
        } catch (e) {
            this.$log.error('Invalid permissions configuration.', permissionValue);
            return defaultOnError;
        }
    }

    /**
     * A recursive function which processes and determines if the user's permissions are valid.
     *
     * @param permissionValue A Permission, PermissionAttributeGrouping, PermissionGrouping or an array of them.
     * @returns {boolean} The return value indicating if the user has the proper permissions.
     */
    public processPermissions(permissionValue: PermissionConfiguration): boolean {
        // If this class has not been initialized or it has been destroyed, then the permissions will be null.
        // In that case, just return false because the user has been logged out and no access should be granted.
        if (!this.permissions) {
            return false;
        }

        // Determines if elements should be 'OR'ed or 'AND'ed together.
        let option: string;
        if (!this.isPermissionName(permissionValue) && !Array.isArray(permissionValue)) {
            option = permissionValue.or ? 'some' : 'every';
        }

        if (this.isPermissionName(permissionValue)) {

            // Single permission so return if the user has access.
            return this.permissions[PermissionName[permissionValue]].access;
        } else if (this.isPermissionAttributeGrouping(permissionValue)) {

            // Single permission with multiple attributes
            return permissionValue.attributes[option]((a: PermissionAttribute) => {
                return this.permissions[PermissionName[permissionValue.permissionName]][PermissionAttribute[a]];
            });
        } else if (this.isPermissionGrouping(permissionValue)) {

            // Single Grouping so process its permissions array.
            return permissionValue.permissions[option]((p: PermissionConfiguration) => {
                return this.processPermissions(p);
            });
        } else {

            // Process the array using recursion.
            return permissionValue.every(p => {
                return this.processPermissions(p);
            });
        }
    }

    /**
     * Checks if the value is not an array and in the PermissionName enum.
     * User-defined type guard function.
     *
     * @param a The item whose type needs to be checked.
     *
     * @returns {a is PermissionName} Declares the value as a specific type if multiple.
     * types exist on a single variable.
     */
    private isPermissionName(a: any): a is PermissionName {
        return !Array.isArray(a) && !!PermissionName[a];
    }

    /**
     * Checks if the 'or', 'permissionName' and 'attributes' which make a PermissionAttributeGrouping are not undefined.
     * User-defined type guard function.
     *
     * @param a The item whose type needs to be checked.
     *
     * @returns {a is PermissionAttributeGrouping} Declares the value as a specific type if multiple.
     * types exist on a single variable.
     */
    private isPermissionAttributeGrouping(a: any): a is PermissionAttributeGrouping {
        return a.or !== undefined && a.permissionName !== undefined && a.attributes !== undefined;
    }

    /**
     * Checks if the 'or' and 'permissions' attributes are undefined which are part of a PermissionGrouping.
     * User-defined type guard function.
     *
     * @param a The item whose type needs to be checked.
     *
     * @returns {a is PermissionGrouping} Declares the value as a specific type if multiple.
     * types exist on a single variable.
     */
    private isPermissionGrouping(a: any): a is PermissionGrouping {
        return a.or !== undefined && a.permissions !== undefined;
    }

    /**
     * Process the rms permissions associated with 'touchrms' and disable the permission if the 'touchrms' attribute is also disabled..
     *
     * @param atr - A permission attribute.
     */
    private processRMSPermissions(atr: string) {
        if (!this.permissions[PermissionName[PermissionName.touchrms]][atr]) {
            this.enumEnumerator(RmsPermissionName).forEach(permission => this.permissions[permission][atr] = false);
        }
    }
}

export default PermissionsRepository;
