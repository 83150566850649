/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import IResourceArray = angular.resource.IResourceArray;
import IPromise = angular.IPromise;
import AssociativeArray from './interfaces/AssociativeArray';
import BaseRegeneratable from '../login/regeneratable/BaseRegeneratable';
import IQService = angular.IQService;

/**
 * Caches a database table for quick access.
 */
export default class TableCache<T> extends BaseRegeneratable {

    private _map: AssociativeArray<T> = {};
    private _array: T[] = [];

    /**
     * Constructs a new instance of the TableCache class.
     *
     * @param api The service that retrieves models of type T from the server.
     * @param $q The angular service that handles creating and working with promises.
     * @param primaryKeyProperty The name of the model's primary key property.
     */
    constructor(private api: IResourceClass<T>, $q: IQService, private primaryKeyProperty: string) {
        super($q);
    }

    /**
     * Gets a map of the model where the model's primary key is used as the map's key.
     * Note: This will throw an exception if the cache is not ready.
     *
     * @returns {AssociativeArray<T>} A map of the models.
     */
    public get map(): AssociativeArray<T> {
        return this._map;
    }

    /**
     * Gets the array of models.
     * Note: This will throw an exception if the cache is not ready.
     *
     * @returns {T[]} The array of models.
     */
    public get array(): T[] {
        return this._array;
    }

    // @Override
    protected performInitialization(): IPromise<void> {
        const resourceArray = this.api.query();
        return this.getAllPages(resourceArray, resourceArray.$promise).then(this.updateArrayAndMap);
    }

    // @Override
    protected performDestruction(): void {
        this.clear();
    }

    /**
     * Retrieves all pages of the resource array from the server.
     *
     * @param resourceArray The resource array for which to load all pages.
     * @param promise The promise that loads the next page of results.
     * @returns The promise that, when fulfilled, will include all pages of results.
     */
    private getAllPages(resourceArray: IResourceArray<T>, promise: IPromise<T[]>): IPromise<IResourceArray<T>> {
        return promise.then(() => {
            if (!resourceArray.pager.hasNextPage()) {
                return resourceArray;
            }
            return this.getAllPages(resourceArray, resourceArray.pager.appendNextPage());
        });
    }

    /**
     * Clears the existing data from both the array and map.
     */
    private clear(): void {
        this._array.length = 0;

        for (let prop in this._map) {
            delete this._map[prop];
        }
    }

    /**
     * Updates the array and the map with the new models received from the server.
     *
     * @param newModels The new models received from the server.
     */
    private updateArrayAndMap = (newModels: T[]): void => {
        this._array.pushAll(newModels);

        for (let model of newModels) {
            let primaryKey = model[this.primaryKeyProperty];
            this._map[primaryKey] = model;
        }
    };
}
