/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { VersioningModule } from './versioning/versioning.module';
import {
    ConditionalInjectionFilter,
    PlatformConditionalInjectionFilter,
    ServerVersionConditionalInjectionFilter,
    EnvironmentConditionalInjectionFilter
} from './filters';
import { PlatformDetector } from './platform-detection/platform-detector.service';
import { ConditionalServiceLoader } from './conditional-service-loader.service';
import { conditionalInjectionMap, conditionalInjectionMapToken } from './conditional-injection-map';
@NgModule({
    imports: [
        VersioningModule
    ],
    providers: [
        ConditionalServiceLoader,
        { provide: conditionalInjectionMapToken, useValue: conditionalInjectionMap },
        PlatformDetector,
        { provide: ConditionalInjectionFilter, useClass: EnvironmentConditionalInjectionFilter, multi: true },
        { provide: ConditionalInjectionFilter, useClass: PlatformConditionalInjectionFilter, multi: true },
        { provide: ConditionalInjectionFilter, useClass: ServerVersionConditionalInjectionFilter, multi: true }
    ]
})
export class ConditionalInjectionModule { }
