/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { injectionCondition } from '../../../../conditional-injection';
import { FileDownloader } from './file-downloader';
import { UrlFactory } from '../../../../authentication';
import { BlobRequester } from '../blob-requester.service';
import { FileMetadata } from '../../../../schema/FileMetadata';

/**
 * Downloads a file from the server when run in a standard browser
 * (i.e. outside the cordova environment).
 */
@Injectable()
@injectionCondition({platforms: ['browser']})
export class BrowserFileDownloader extends FileDownloader {

    /** *
     * File extensions for files that can't be opened in the browsers and should be downloaded
     * @private msOfficeFileExtensions
     */

    private unsupportedFileExtensions = ['.doc', '.xls', '.ppt', '.mp3', '.mp4', '.wav'];

    /**
     * Constructs a new instance of the BrowserFileDownloader class.
     *
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param blobRequester The service that retrieves the blob from the server.
     * @param sanitizer The Angular service that sanitizes URLs.
     */
    constructor(
        urlFactory: UrlFactory,
        private blobRequester: BlobRequester,
        private sanitizer: DomSanitizer
    ) {
        super(urlFactory);
    }

    /**
     * @inheritdoc
     */
    protected downloadFromUrl(url: string, _file: FileMetadata): void {
        const isUnsupportedFileType = this.checkIsUnsupportedFileType(_file);

        // Safari requires that the new window be created here, outside of the `subscribe` callback.
        // See http://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari#answer-39387533
        let newWindow: Window;

        if (!isUnsupportedFileType) {
            newWindow = window.open();
        }

        this.blobRequester.getSafeUrl(url)
            .map(safeUrl => this.sanitizer.sanitize(SecurityContext.URL, safeUrl))
            .subscribe(sanitizedUrl => {
                if (!isUnsupportedFileType) {
                    newWindow.location.assign(sanitizedUrl);
                    return;
                }

                this.downloadFile(sanitizedUrl, _file);
            });
    }

    /** *
     * downloadFile - the method to download files with browser unsupported extensions to the user PC
     * @param url The url to download a file
     * @param file The file to download
     */

    private downloadFile (url: string, file: FileMetadata): void {
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name;
        link.target = '_blank';
        document.body.appendChild(link);

        setTimeout(() => {
            const evt = document.createEvent('MouseEvents');
            evt.initMouseEvent('click', true, false, window,
                0, 0, 0, 0, 0, false,
                false, false, false, 0, undefined);
            link.dispatchEvent(evt);

            document.body.removeChild(link);
        }, 50);
    }

    /** *
     * checkIsUnsupportedFileType - the method used to check if file extension related to the browser unsupported extensions
     * @param file The file to download
     */
    private checkIsUnsupportedFileType (file: FileMetadata): boolean {
        return this.unsupportedFileExtensions.some((item: string) => file.name !== undefined && file.name.includes(item));
    }
}
