/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { NameDetailComponent } from './detail/name-detail.component';
import { PhoneBuilder } from './search/phone-builder.service';

@NgModule({
    imports: [SharedModule],
    declarations: [NameDetailComponent],
    providers: [PhoneBuilder]
})
export class NameModule { }
