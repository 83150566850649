/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import CadFilter from '../CadFilter';
import Session from '../../../login/Session';
import CADModel from '../../../schema/interfaces/CADModel';
import SessionData from '../../../login/SessionData';

/**
 * An abstract base class that provides the session data to derived classes.
 * The session data can then be used to decide if the model should be included.
 */
abstract class SessionDataCadFilter<TModel extends CADModel, TModelValue, TSessionValue> implements CadFilter<TModel> {

    /**
     * Constructs a new instance of the SessionDataCadFilter class.
     *
     * @param session The object that stores information about the current user's session.
     */
    constructor(private session: Session) {
    }

    // @Override
    public include(model: TModel): boolean {
        if (this.session.exists()) {
            let modelValue = this.getModelValue(model);
            let sessionValue = this.getSessionValue(this.session.data);
            return this.compare(modelValue, sessionValue);
        }

        // This can be reached if the user logs out on the call or unit list screens.
        // When that happens the session is destroyed, but there may still be a digest run after the user has logged out.
        // In order to avoid an exception being thrown by attempting to access the session data, we'll just return false.
        // If the user is logged out, he/she shouldn't be allowed to see data anyways.
        return false;
    }

    /**
     * Retrieves a value from the given model to be used in the comparison.
     *
     * @param model A cad model.
     * @returns {TModelValue} A value obtained from the model.
     */
    protected abstract getModelValue(model: TModel): TModelValue;

    /**
     * Retrieves a value from the session data.
     *
     * @param sessionData The data stored in the session.
     * @returns {TSessionValue} A value obtained from the session data.
     */
    protected abstract getSessionValue(sessionData: SessionData): TSessionValue;

    /**
     * Compares the value from the model and the value from the session in some way and returns
     * a value that indicates if the model should be included in the results.
     *
     * @param modelValue The value obtained from the model.
     * @param sessionValue The value obtained from the session data.
     * @returns {boolean} True, to indicate that the model should be included;
     *                    false, to indicates that the model should be excluded.
     */
    protected abstract compare(modelValue: TModelValue, sessionValue: TSessionValue): boolean;
}

export default SessionDataCadFilter;
