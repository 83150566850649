/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import IonicHistoryService = ionic.navigation.IonicHistoryService;
import { IRootScopeService, IScope } from 'angular';
import CustomFilterAllFilters from '../../../filtering/custom-filters/CustomFilterAllFilters';
import IonicPopupService = ionic.popup.IonicPopupService;
import IonicPopupFullOptions = ionic.popup.IonicPopupFullOptions;
import SaveFilterStrings from './popups/strings';
import FiltersStorage from '../../../filtering/storage/FiltersStorage';
import { logEvent } from '../../../../api/analytics/firebase-analytics-service';
import { FilterAnalytics } from '../../../filtering/analytics/events';
import { FilterActions } from '../../../filtering/consts';
import Setting from '../../../../settings/Setting';

export default class SaveFilterController<T> {

    private readonly popupCssClass = 'save-popup';
    private readonly positiveButtonType = 'button-positive';

    constructor(
        public $rootScope: IRootScopeService,
        public $ionicHistory: IonicHistoryService,
        public $scope: IScope,
        public customFilterAllFilters: CustomFilterAllFilters,
        public $ionicPopup: IonicPopupService,
        public filtersStorage: FiltersStorage,
        public actions: FilterActions,
        public setting: Setting<T>,
        private filterAnalytics: FilterAnalytics,
        private isSaveFromFilterScreen: boolean = true
    ) {}

    public openSaveFilterDialog () {
        this.$scope.newFilter = { filterName: '' };

        this.$ionicPopup.show(<IonicPopupFullOptions>{
            template: require('./popups/save-filter.html'),
            cssClass: this.popupCssClass,
            title: SaveFilterStrings.save_filter_title,
            scope: this.$scope,
            buttons: [
                { text: SaveFilterStrings.cancel },
                {
                    text: SaveFilterStrings.save_confirm,
                    type: this.positiveButtonType,
                    onTap: this.saveFilter
                }
            ]
        });
    }

    public openRenameFilterDialog () {
        this.$scope.renameFilter = { filterName: '', currentName: this.getEditingFilterName() };

        this.$ionicPopup.show(<IonicPopupFullOptions>{
            template: require('./popups/rename-filter.html'),
            cssClass: this.popupCssClass,
            title: SaveFilterStrings.rename_filter_title,
            subTitle: SaveFilterStrings.rename_filter_subtitle1 + this.$scope.renameFilter.currentName + SaveFilterStrings.rename_filter_subtitle2,
            scope: this.$scope,
            buttons: [
                { text: SaveFilterStrings.cancel },
                {
                    text: SaveFilterStrings.rename_confirm,
                    type: this.positiveButtonType,
                    onTap: this.renameFilter
                }
            ]
        });
    }

    public openSaveAsDialog () {
        this.$scope.saveAs = { filterName: '' };

        this.$ionicPopup.show(<IonicPopupFullOptions>{
            template: require('./popups/save-as-filter.html'),
            cssClass: this.popupCssClass,
            title: SaveFilterStrings.save_as_filter_title,
            subTitle: SaveFilterStrings.save_as_filter_subtitle,
            scope: this.$scope,
            buttons: [
                { text: SaveFilterStrings.cancel },
                {
                    text: SaveFilterStrings.save_confirm,
                    type: this.positiveButtonType,
                    onTap: this.saveAs
                }
            ]
        });
    }

    public openDeleteFilterDialog() {
        this.$ionicPopup.show(<IonicPopupFullOptions>{
            cssClass: this.popupCssClass,
            title: SaveFilterStrings.delete_filter_title,
            subTitle: SaveFilterStrings.delete_filter_subtitle1 + this.getEditingFilterName() + SaveFilterStrings.delete_filter_subtitle2,
            scope: this.$scope,
            buttons: [
                { text: SaveFilterStrings.cancel },
                {
                    text: SaveFilterStrings.confirm,
                    type: this.positiveButtonType,
                    onTap: this.deleteFilter
                }
            ]
        });
    }

    private validateFilterName(filterName: string) {
        if (this.isDuplicatedName(filterName)) {
            this.$ionicPopup.alert({ template: SaveFilterStrings.save_filter_duplicated_name });
            return false;
        }

        if (this.filtersStorage.isFilterNameInvalid(filterName)) {
            this.$ionicPopup.alert({ template: SaveFilterStrings.invalid_name });
            return false;
        }

        if (this.filtersStorage.isFilterNameSizeInvalid(filterName)) {
            this.$ionicPopup.alert({ template: SaveFilterStrings.too_long });
            return false;
        }

        return true;
    }

    private performSave = (filterName: string) => {
        // if we are saving the Current Filter we should remove the current filter from the list since it is a saved filter already
        if (this.getEditingFilterName() === this.filtersStorage.CURRENT_FILTER_KEY || this.getEditingFilterName() === '') {
            this.filtersStorage.deleteUnsavedFilterIfExist();
            this.$rootScope.$broadcast(`${this.actions.ADVANCED_FILTER_DELETED}`, { deletedFilterName: this.filtersStorage.CURRENT_FILTER_KEY});
        }

        this.filtersStorage.setCurrentFilter(filterName);
        this.customFilterAllFilters.applyAllFilters();

        this.$rootScope.$broadcast(`${this.actions.ADVANCED_FILTER_CHANGED}`);
    };

    public renameFilter = () => {
        try {
            const currentName = this.getEditingFilterName();
            const newFilterName = this.$scope.renameFilter?.filterName;
            if (!this.validateFilterName(newFilterName)) {
                return;
            }

            this.performDelete(currentName);
            this.performSave(newFilterName);
            this.filtersStorage.setCurrentAsEditing();

            logEvent(this.filterAnalytics.rename_filter_action);
        } catch (error) {
            this.$ionicPopup.alert({ template: SaveFilterStrings.rename_filter_generic_error });
        }
    };

    public saveAs = () => {
        try {
            const newFilterName = this.$scope.saveAs?.filterName;
            if (!this.validateFilterName(newFilterName)) {
                return;
            }

            this.performSave(newFilterName);
            this.filtersStorage.setCurrentAsEditing();

            logEvent(this.filterAnalytics.save_as_filter_action);
        } catch (error) {
            this.$ionicPopup.alert({ template: SaveFilterStrings.save_as_filter_generic_error });
        }
    };

    public saveFilter = () => {
        try {
            const filterName = this.$scope.newFilter?.filterName;

            if (!this.validateFilterName(filterName)) {
                return;
            }

            this.performSave(filterName);

            if (this.isSaveFromFilterScreen) {
                this.$ionicHistory.goBack();
                logEvent(this.filterAnalytics.save_filter_screen_action);
            } else {
                logEvent(this.filterAnalytics.save_list_screen_action);
            }
        } catch (error) {
            this.$ionicPopup.alert({template: SaveFilterStrings.save_filter_generic_error});
        }
    };

    private isDuplicatedName = (filterName: string) => {
        return this.filtersStorage.isFilterAlreadySaved(filterName);
    };

    private getEditingFilterName(): string {
        return this.filtersStorage.getEditingFilterName();
    }

    private performDelete = (deletedFilterName: string) => {
        this.filtersStorage.deleteFilter(deletedFilterName);
        this.$rootScope.$broadcast(this.actions.ADVANCED_FILTER_DELETED, { deletedFilterName });
    };

    public deleteFilter = () => {
        try {
            const deletedFilterName = this.getEditingFilterName();
            this.setting.selectedIndex = 0;

            this.performDelete(deletedFilterName);
            logEvent(this.filterAnalytics.delete_filter_action);
            this.$ionicHistory.goBack();
        } catch (error) {
            this.$ionicPopup.alert({template: SaveFilterStrings.delete_filter_generic_error});
        }
    };
}
