/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { CookieCleaner } from './cookie-cleaner';
import { CookieMasterWrapper } from '../../login/cookie-master-wrapper';
import { Credentials } from '../Credentials';
import { injectionCondition } from '../../conditional-injection';

/**
 * Deletes all session cookies when using Basic Authentication in the cordova environment.
 */
@Injectable()
@injectionCondition({ platforms: ['android', 'ios'] })
export class CordovaBasicAuthenticationCookieCleaner implements CookieCleaner {

    /**
     * Constructs a new instance of the CordovaBasicAuthenticationCookieCleaner class.
     *
     * @param cookieMasterWrapper A wrapper around the Cordova Cookie Master plugin.
     */
    constructor(private cookieMasterWrapper: CookieMasterWrapper) {
    }

    /**
     * @inheritdoc
     */
    public deleteCookies(_credentials?: Credentials): Promise<void> {
        return this.cookieMasterWrapper.clear();
    }
}
