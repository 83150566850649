/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { FieldNode } from '../../nodes/property/field-node';
import { ModelCreationData } from '../model-creation-data';
import { PropertyDeserializer } from '../property-deserializer';

/**
 * Performs a "visit" on a FieldNode during the process of creating the human-friendly model.
 */
@Injectable()
export class FieldNodeModelCreationVisitor implements TypeSpecificTreeNodeVisitor<FieldNode, ModelCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [FieldNode];

    /**
     * Constructs a new instance of the FieldNodeModelCreationVisitor class.
     *
     * @param propertyDeserializer Derializes a property from the JSON response received from the database.
     */
    constructor(private propertyDeserializer: PropertyDeserializer) {
    }

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: FieldNode, data: ModelCreationData): void {
        for (let { databaseModel, humanFriendlyModel } of data.modelPairs.peek()) {
            if (databaseModel) {
                humanFriendlyModel[node.propertyKey] = this.propertyDeserializer.deserialize(node, databaseModel[node.field]);
            }
        }
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: FieldNode, _data: ModelCreationData): void {
        // Nothing to do here.
    }
}
