/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import angular, { IRootScopeService, IScope } from 'angular';
import CustomFilterByAgencies from '../../../custom-filters/CustomFilterByAgencies';
import CustomFilterByTypes from '../../../custom-filters/CustomFilterByTypes';
import CustomFilterByZones from '../../../custom-filters/CustomFilterByZones';
import CustomFilterOnlyMyCalls from '../../../custom-filters/CustomFilterOnlyMyCalls';
import CustomFilterAllFilters from '../../../custom-filters/CustomFilterAllFilters';
import { logEvent } from '../../../../../api/analytics/firebase-analytics-service';
import { FilterAnalytics } from '../../../analytics/events';
import { FilterActions } from '../../../consts';
import FiltersStorage from '../../../storage/FiltersStorage';
import CallFilter from '../../../../calls/filtering/CallFilter';
import CADModel from '../../../../..//schema/interfaces/CADModel';
import CustomDataCadFilter from '../../../baseClasses/CustomDataCadFilter';
import { filterLabel } from './strings';
import UnitFilter from '../../../../units/filtering/UnitFilter';
import SaveFilterController from '../../../../../../app/cad/filtering/storage/save-filter-popup/save-filter-popup';

import IonicHistoryService = ionic.navigation.IonicHistoryService;
import IonicPopupService = ionic.popup.IonicPopupService;
import Setting from '../../../../../../app/settings/Setting';

/**
 * The controller for the FilterHeaderComponent
 */
export default abstract class FilterHeaderComponentController {
    public abstract routeName: string;

    private filters: CustomDataCadFilter<CADModel>[];
    private saveFilterController: SaveFilterController<any>;

    constructor(
        $rootScope: IRootScopeService,
        private $scope: IScope,
        customFilterByAgencies: CustomFilterByAgencies,
        private customFilterOnlyMyCalls: CustomFilterOnlyMyCalls,
        customFilterByTypes: CustomFilterByTypes,
        customFilterByZones: CustomFilterByZones,
        private filtersStorage: FiltersStorage,
        customFilterAllFilters: CustomFilterAllFilters,
        private $state: angular.ui.IStateService,
        filterActions: FilterActions,
        private filterAnalytics: FilterAnalytics,
        $ionicHistory: IonicHistoryService,
        $ionicPopup: IonicPopupService,
        setting: Setting<any>,
    ) {
        this.filters = [];
        $scope.newFilter = { filterName: '' };

        this.saveFilterController = new SaveFilterController($rootScope, $ionicHistory, $scope, customFilterAllFilters, $ionicPopup, filtersStorage, filterActions, setting, filterAnalytics, false);

        customFilterOnlyMyCalls && this.filters.push(customFilterOnlyMyCalls);
        customFilterByAgencies && this.filters.push(customFilterByAgencies);
        customFilterByTypes && this.filters.push(customFilterByTypes);
        customFilterByZones && this.filters.push(customFilterByZones);

        this.updateFilterConfig();

        this.$scope.goToEditPageScreen = this.goToEditPageScreen;
        this.$scope.openSaveModal = this.openSaveModal;

        $rootScope.$on(filterActions.ADVANCED_FILTER_CHANGED, () => {
            this.updateFilterConfig();
        });

        $rootScope.$on(filterActions.SELECTED_INDEX_CHANGED, (event, {selectedOption, selectedType}) => {
            if (selectedType !== CallFilter.customFilter && selectedType !== UnitFilter.unitCustomFilter) {
                this.$scope.label = '';
                this.$scope.isFilterVisible = false;
            } else {
                this.$scope.isFilterVisible = true;

                filtersStorage.setCurrentFilter(selectedOption);
                customFilterAllFilters.restoreAllFilters();
                this.updateFilterConfig();
            }
        });
    }

    private openSaveModal = () => {
        this.saveFilterController.openSaveFilterDialog();
    };

    private goToEditPageScreen = () => {
        this.filtersStorage.setCurrentAsEditing();
        logEvent(this.filterAnalytics.edit_filter_click);
        this.$state.go(this.routeName);
    };

    private updateFilterConfig() {
        const labels: string[] = [];

        if (this.customFilterOnlyMyCalls?.getFilterLabel() !== undefined) {
            labels.push(this.customFilterOnlyMyCalls.getFilterLabel());
        } else {
            this.filters.forEach((filter) => {
                if (filter.getFilterLabel() !== undefined) {
                    labels.push(filter.getFilterLabel());
                }
            });
        }

        this.$scope.label = labels.length > 0 ? `${filterLabel}${labels.join(', ')}` : '';

        this.$scope.isFilterUnsaved = !this.filtersStorage.isFilterAlreadySaved(this.filtersStorage.getCurrentFilter());
    }


    public $onInit = () => { };
}
