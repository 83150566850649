/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';
import { NameReference } from '../../shared/model/name-reference';

/**
 * A case file defendant.
 */
@table('pcdfdtl')
export class Defendant {

    @expand('namenum', NameReference)
    public name: NameReference | string;
}
