/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IRootScopeService = angular.IRootScopeService;
import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAttributes = angular.IAttributes;
import MappingUtilities from './MappingUtilities';
import Location from './Location';
import Setting from '../../settings/Setting';
import SettingKey from '../../settings/SettingKey';
import Theme from '../../theme/Theme';
import { PlatformDetector } from 'app/conditional-injection/platform-detection/platform-detector.service';

const SCALE = 'scale';
const STYLE = 'style';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
GoogleStaticMapDirective.$inject = ['mappingUtilities', '$rootScope', 'themeSetting', 'platformDetector'];

/**
 * A factory function that creates the GoogleStaticMapDirective directive.
 *
 * @param mappingUtilities A utility class that provides methods for working with maps and locations.
 * @param $rootScope The Angular $rootScope that can be used to listen to application events.
 * @param themeSetting The setting that determines which CSS theme is currently used.
 * @returns {IDirective} A directive that displays a static Google map.
 */
export default function GoogleStaticMapDirective(
    mappingUtilities: MappingUtilities,
    $rootScope: IRootScopeService,
    themeSetting: Setting<Theme>,
    platformDetector: PlatformDetector
): IDirective {

    /**
     * Stores the path to the Google Static Maps API.
     */
    let GOOGLE_STATIC_MAPS_BASE_URL = 'https://maps.googleapis.com/maps/api/staticmap?';

    return {
        restrict: 'E',
        template: '<img ng-src={{fullUrl}}>',
        scope: {
            location: '=',
            size: '@',
            zoom: '@',
            scale: '@'
        },
        link: ($scope: GoogleStaticMapScope, $element: IAugmentedJQuery, $attributes: IAttributes) => {

            /**
             * Attempts to load the map using the current scope properties.
             */
            function tryLoadMap(): void {

                let apiKey: string;

                if (platformDetector.isBrowser()) {
                    apiKey = process.env.MAP_API_KEY_BROWSER;
                } else {
                    apiKey = process.env.MAP_API_KEY_ANDROID_IOS;
                }

                let center = mappingUtilities.tryGetAddressOrLatLng($scope.location, true);
                if (center && $scope.size && $scope.zoom) {
                    let parameters = {
                        key: apiKey,
                        markers: center,
                        size: $scope.size,
                        zoom: $scope.zoom
                    };

                    if ($scope.scale) {
                        parameters[SCALE] = $scope.scale;
                    }

                    if (themeSetting.value === Theme.night) {
                        parameters[STYLE] = 'invert_lightness:true';
                    }

                    let queryString = angular.element.param(parameters);
                    $scope.fullUrl = GOOGLE_STATIC_MAPS_BASE_URL + queryString;
                    $element.removeClass('ng-hide');
                } else {
                    $scope.fullUrl = undefined;
                    $element.addClass('ng-hide');
                }
            }

            $scope.$watch('location', tryLoadMap, true);
            $attributes.$observe('size', tryLoadMap);
            $attributes.$observe('zoom', tryLoadMap);
            $attributes.$observe('scale', tryLoadMap);
            $attributes.$observe('invertLightness', tryLoadMap);
            $rootScope.$on(`${Setting.SETTING_CHANGED}:${SettingKey.themeSetting}`, tryLoadMap);
        }
    };
}

/**
 * An interface that defines the scope for the GoogleStaticMapDirective.
 */
interface GoogleStaticMapScope extends IScope {

    /**
     * The location to use as the center point of the map.
     */
    location: Location;

    /**
     * The horizontal and vertical size of the map (e.g. 100x100).
     */
    size: string;

    /**
     * The zoom level of the map. The higher the number the more zoomed in.
     */
    zoom: string;

    /**
     * Can either be 1 or 2. 2 means that twice as many pixels will be returned.
     */
    scale: string;

    /**
     * The full URL to the Google Static Maps API including the querystring parameters.
     */
    fullUrl: string;
}
