/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../../shared/database/database.service';
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import { SlideSourceInfo } from '../../../schema/SlideSourceInfo';
import { JoinPipe, DatePipe } from '../../../shared/pipes';
import {
    Circumstance,
    Disposition,
    Force,
    InvolvedInmate,
    JailIncident,
    ModusOperandi
} from './model';

/**
 * A component that displays the details of a jail incident.
 */
@Component({
    selector: 'sds-jail-incident-detail',
    template: require('./jail-incident-detail.component.html')
})
export class JailIncidentDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        ['Narrative', { srcHtml: 'app/rms/shared/slides/comments.html' }],
        ['Supplements', { srcHtml: 'app/rms/shared/slides/supplements.html' }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The jail incident model to display.
     */
    public jailIncident: JailIncident;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the JailIncidentDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     * @param datePipe The pipe that knows how to format dates.
     * @param $stateParams The passed in url parameters.
     * @param involvementsSlideSourceInfo inject the involvement slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        private joinPipe: JoinPipe,
        private datePipe: DatePipe,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * Format functions needed to display a list of items correctly inside of a popup.
     */
    public singleItemFormatter = (model: any) => model.description;
    public moFormatter = (mo: ModusOperandi) => this.joinPipe.transform([mo.factor, mo.description], ' - ');
    public dispositionFormatter = (disposition: Disposition) => this.joinPipe.transform([disposition.description, this.datePipe.transform(disposition.date)], ' - ');
    public circumstanceFormatter = (circumstance: Circumstance) => this.joinPipe.transform([circumstance.description, circumstance.comment], ' - ');
    public forceFormatter = (model: Force) => this.joinPipe.transform([model.description, this.datePipe.transform(model.date), model.officer], ' - ');
    public inmateFormatter = (inmate: InvolvedInmate) => (typeof inmate.name !== 'string') ? this.joinPipe.transform([inmate.name.last, this.joinPipe.transform([inmate.name.first, inmate.name.middle], ' ')], ', ') : inmate.name;

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(JailIncident, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.jailIncident = detailModel, () => this.onError.emit());
    }
}
