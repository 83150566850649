/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import City from '../../schema/City';
import TableCache from './../TableCache';
import IQService = angular.IQService;

/**
 * Caches the apcity database table.
 */
export default class CityCache extends TableCache<City> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['cityAPI', '$q'];

    /**
     * Constructs a new instance of the CityCache class.
     *
     * @param cityAPI The service that retrieves cities from the server.
     * @param $q The angular service that handles creating and working with promises.
     */
    constructor(cityAPI: IResourceClass<City>, $q: IQService) {
        super(cityAPI, $q, 'abbr');
    }
}
