/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { ExpandNode } from '../../nodes/property/expand-node';
import { ModelCreationData } from '../model-creation-data';
import { TablesApiHelper } from '../tables-api-helper';

/**
 * Performs a "visit" on an ExpandNode during the process of creating the human-friendly model.
 */
@Injectable()
export class ExpandNodeModelCreationVisitor implements TypeSpecificTreeNodeVisitor<ExpandNode, ModelCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [ExpandNode];

    /**
     * Constructs a new instance of the ExpandNodeModelCreationVisitor class.
     *
     * @param tablesApiHelper A helper that knows how to work with the database models returned by the Tables API.
     */
    constructor(private tablesApiHelper: TablesApiHelper) {
    }

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: ExpandNode, data: ModelCreationData): void {
        const newPairs = data.modelPairs.peek().map(({ databaseModel }) => ({
            databaseModel: this.tablesApiHelper.getExpandedObject(databaseModel, node.column)
        }));
        data.modelPairs.push(newPairs);
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(node: ExpandNode, data: ModelCreationData): void {
        const childPairs = data.modelPairs.pop();
        const parentPairs = data.modelPairs.peek();

        for (let i = 0; i < childPairs.length; i++) {
            const childHumanFriendlyModel = childPairs[i].humanFriendlyModel;
            const parentDatabaseValue = parentPairs[i].databaseModel[node.column];

            // If the child model exists, then use it. Otherwise, it may be in another partitition.
            // If that's the case, then all we have access to is the foreign key (string) that points to the child model.
            // So we'll assign that to the model. Thus, all expands should have a type like `ReferenceModel | string`.
            parentPairs[i].humanFriendlyModel[node.propertyKey] = childHumanFriendlyModel || this.tablesApiHelper.getValue(parentDatabaseValue);
        }
    }
}
