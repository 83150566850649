/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IRootScopeService = angular.IRootScopeService;
import SettingKey from '../SettingKey';
import Setting from '../Setting';
import SettingStorage from '../SettingStorage';
import Option from '../Option';
import CallFilter from '../../cad/calls/filtering/CallFilter';
import FiltersStorage from '../../cad/filtering/storage/FiltersStorage';
import CallFilterVersion from './CallFilterVersion';
import { PlatformDetector } from '../../../app/conditional-injection/platform-detection/platform-detector.service';
import { CALL_ACTIONS } from '../../cad/filtering/consts';

/**
 * The setting that determines which CAD calls are filtered from the list.
 */
export default class CallFilterSetting extends Setting<CallFilter> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$q', 'settingStorage', '$rootScope', '$state', 'callFiltersStorage', 'callFilterVersion', 'platformDetector'];
    /**
     * Constructs a new instance of the CallFilterSetting class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param settingStorage The object that handles persistent storage of the setting value.
     * @param $rootScope The Angular $rootScope that can be used to broadcast events throughout the app.
     */
    constructor(
        $q: IQService,
        settingStorage: SettingStorage,
        $rootScope: IRootScopeService,
        public $state: angular.ui.IStateService,
        private filtersStorage: FiltersStorage,
        callFilterVersion: CallFilterVersion,
        platformDetector: PlatformDetector
    ) {
        super($q, settingStorage, $rootScope, SettingKey.callFilterSetting, 'Call filtering', [new Option('All', CallFilter.filterNone)], 0, callFilterVersion, platformDetector);

        // Listen to ADVANCED_FILTER_CHANGED to create new Custom Filter and apply its filters
        $rootScope.$on(`${CALL_ACTIONS.ADVANCED_FILTER_CHANGED}`, () => {
            this.createCustomFilter();
        });

        // Listen to ADVANCED_FILTER_DELETED to delete a Custom Filter
        $rootScope.$on(`${CALL_ACTIONS.ADVANCED_FILTER_DELETED}`, (event, { deletedFilterName }) => {
            this.deleteCustomFilter(deletedFilterName);
        });

        // First time CallFilterSetting is constructed then create custom filter if needed
        this.createCustomFilter();
    }

    private createCustomFilter() {
        const savedFilterNames = this.filtersStorage.getAllSavedFilterNames();

        savedFilterNames.forEach(name => {
            let filterIndex = this.options.findIndex(option => option.text === name);

            if (filterIndex === -1) {
                const customFilter = (this.options as unknown as Option<CallFilter>[]).push(new Option(name, CallFilter.customFilter, { requiresUnit: true }));
                // If last saved option is custom filter or newFilterApply is true then add recently added custom filter option
                if (this.filtersStorage.isCurrentFilter(name)) {
                    this.selectedIndex = customFilter - 1;
                }
            } else {
                if (this.filtersStorage.isCurrentFilter(name)) {
                    let selectedIndex = this.options.findIndex(option => option.text === name);

                    this.selectedIndex = selectedIndex;
                }
            }
        });
    }

    private deleteCustomFilter(deletedFilterName: string) {
        let filterIndex = this.options.findIndex(option => option.text === deletedFilterName);

        if (filterIndex !== -1) {
            this.options.splice(filterIndex, 1);
        }
    }
}
