/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../../shared/database/database.service';
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import { JailOffense } from './model/jail-offense';
import { SlideSourceInfo } from '../../../schema/SlideSourceInfo';
import PermissionName from '../../../permissions/enums/PermissionName';

/**
 * A component that displays the details of a jail offense.
 */
@Component({
    selector: 'sds-jail-offense-detail',
    template: require('./jail-offense-detail.component.html')
})
export class JailOffenseDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        ['Addtional Info', { srcHtml: 'app/rms/shared/slides/comments.html' }],
        ['Booking', { srcHtml: 'app/rms/jail/shared/slides/bookings.html', permissions: PermissionName.booking }],
        ['Arrests', { srcHtml: 'app/rms/jail/shared/slides/arrests.html', permissions: PermissionName.arrest }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The jail offense model to display.
     */
    public jailOffense: JailOffense;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the JailOffenseDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param involvementsSlideSourceInfo inject the involvement slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(JailOffense, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.jailOffense = detailModel, () => this.onError.emit());
    }
}
