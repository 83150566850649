/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IStateService = angular.ui.IStateService;
import { DurationInputObject } from 'moment';
import { BaseSearchController } from '../../../searching/BaseSearchController';
import Option from '../../../settings/Option';

/**
 * The component that allows the user to search for property in the database.
 */
export const propertySearchComponent: angular.IComponentOptions = {
    template: require('./property-search.component.html'),
    controller: class PropertySearchController extends BaseSearchController {
        public static $inject = [
            '$state',
            'serverDateTimeFormat',
            'standardDateOptions'
        ];

        /**
         * Constructs a new instance of the PropertySearchController class.
         *
         * @param $state The service that transitions between states.
         * @param serverDateTimeFormat The constant that defines the format in which both a date and time are sent by the server.
         * @param standardDateOptions The standard options from which the user can select when performing a search on a date field.
         */
        constructor(
            $state: IStateService,
            serverDateTimeFormat: string,
            public standardDateOptions: Option<DurationInputObject>[]
        ) {
            super($state, serverDateTimeFormat, { color: true });
        }

        public $onInit = () => {};

        /**
         * @inheritdoc
         */
        protected get listStateName(): string {
            return 'app.search.propertiesList';
        }
    }
};
