/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { VersionService } from './version.service';
import { VersionParser } from './version-parser.service';

@NgModule({
    providers: [
        VersionParser,
        VersionService
    ]
})
export class VersioningModule { }
