/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import IResourceArray = angular.resource.IResourceArray;
import CadCallComment from '../../../../schema/CadCallComment';
import ResourceUtils from '../../../../resource/ResourceUtils';
import CallQuery from './CallQuery';

/**
 * Requests the Comments associated with the call.
 */
export default class CommentsCallQuery extends CallQuery<IResourceArray<CadCallComment>> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['cadCallCommentAPI', 'resourceUtils'];

    /**
     * Constructs a new instance of the CommentsCallQuery class.
     *
     * @param cadCallCommentAPI The API for retrieving CAD call comments from the server.
     * @param resourceUtils Provides utility methods for working with the Angular $resource service.
     */
    constructor(private cadCallCommentAPI: IResourceClass<CadCallComment>, private resourceUtils: ResourceUtils) {
        super();
    }

    // @Override
    protected get watchPropertyNames(): string | string[] {
        return ['callIdAndType', 'comments'];
    }

    // @Override
    protected get updatePropertyName(): string {
        return 'parsedComments';
    }

    // @Override
    protected get defaultValue(): IResourceArray<CadCallComment> {
        return this.resourceUtils.createResourceArray<CadCallComment>();
    }

    // @Override
    protected performQuery(newValues: any[]): IResourceArray<CadCallComment> {
        return this.cadCallCommentAPI.query({ id: newValues[0] });
    }
}
