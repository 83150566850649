/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import UnitQuery from './UnitQuery';
import IResourceArray = angular.resource.IResourceArray;
import OfficerName from '../../../../schema/OfficerName';
import UnitOfficerResourceClass from '../../../../api/unitOfficer/UnitOfficerResourceClass';
import ResourceUtils from '../../../../resource/ResourceUtils';
import UnitOfficer from '../../../../schema/UnitOfficer';

/**
 * Queries for all officers that are assigned to a particular unit.
 */
class OfficersUnitQuery extends UnitQuery<IResourceArray<OfficerName>> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['unitOfficerAPI', 'resourceUtils'];

    /**
     * Constructs a new instance of the OfficersUnitQuery class.
     *
     * @param unitOfficerAPI The API for retrieving Unit Officers from the server.
     * @param resourceUtils Provides utility methods for working with the Angular $resource service.
     */
    constructor(
        private unitOfficerAPI: UnitOfficerResourceClass,
        private resourceUtils: ResourceUtils
    ) {
        super();
    }

    // @Override
    protected get watchPropertyNames(): string | string[] {
        return 'unit';
    }

    // @Override
    protected get updatePropertyName(): string {
        return 'allOfficers';
    }

    // @Override
    protected get defaultValue(): IResourceArray<OfficerName> {
        return this.resourceUtils.createResourceArray<OfficerName>();
    }

    // @Override
    protected performQuery(newValues: any[]): IResourceArray<OfficerName> {
        let params = {
            filter: `unitno=${newValues[0]}`,
            expand: 'officer',
            sort: 'seq'
        };

        // First query for the cdunito records.
        let unitOfficersPromise = this.unitOfficerAPI.query(params).$promise;

        // Then select the apnames records.
        let officerNamesPromise = unitOfficersPromise.then(unitOfficers => unitOfficers.map(this.selectOfficerName).filter(o => !!o));
        return this.resourceUtils.createResourceArray(officerNamesPromise);
    }

    /**
     * Selects the OfficerName (apnames record) from the given UnitOfficer (cdunito record).
     *
     * @param unitOfficer An object that links an officer to a unit.
     * @returns {OfficerName} The OfficerName object or undefined if one is not found.
     */
    private selectOfficerName = (unitOfficer: UnitOfficer): OfficerName => {
        let officer = unitOfficer.officer;
        return (officer && typeof officer !== 'string') ? officer.fields : undefined;
    };
}

export default OfficersUnitQuery;
