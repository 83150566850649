/* Copyright © 2021 Motorola Solutions, Inc. All rights reserved. */

import ILocationService = angular.ILocationService;
import ILogService = angular.ILogService;
import { NotificationData } from './notification-data.model';
import { IRootScopeService } from 'angular';
import CallRepository from '../../cad/calls/repository/CallRepository';
import { PushNotificationApi } from './push-notification-api';
import { TOUCH_ANDROID_APP_ID, TOUCH_IOS_APP_ID } from './notification-constants';
import IInjectorService = angular.auto.IInjectorService;
import { REFRESH_PUSH_NOTIFICATIONS_EVENT } from '../../notifications/notification-service';
import { CustomNotificationBuilder } from '../../notifications/custom-notification-builder/custom-notification-builder';

declare const FCM: any;
declare const cordova: any;

export class PushNotificationService {
    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        'ionic',
        '$rootScope',
        '$location',
        '$injector',
        'pushNotificationApi',
        '$log',
        'customNotificationBuilder'
    ];

    private username: string;
    private unit: string;
    private fcmToken: string;
    private apnsToken: string;
    private usersCallIds: string[];

    private callRepository: CallRepository;

    private notificationDisposable: any;
    private notificationVersionDisposable: any;
    private refreshTokenDisposable: any;
    private isAlreadyInitialized: boolean;
    private isRefreshEvent: boolean;

    /**
     * Constructs a new instance of the PushNotificationService class.
     *
     * @param ionic A reference to the ionic framework.
     * @param $rootScope The Angular $rootScope that can be used to listen to application events.
     * @param $location The Angular service that performs location.
     * @param $injector The Angular injector service.
     * @param pushNotificationApi The HTTP service used to subscribe/unsubscribe of the user on push notification
     * @param $log The Angular service that performs logging.
     * @param customNotificationBuilder
     */

    constructor(private ionic: IonicStatic,
        private $rootScope: IRootScopeService,
        private $location: ILocationService,
        private $injector: IInjectorService,
        private pushNotificationApi: PushNotificationApi,
        private $log: ILogService,
        private customNotificationBuilder: CustomNotificationBuilder
    ) {
        this.isAlreadyInitialized = false;
        this.isRefreshEvent = false;
    }

    /**
     * clearAllNotifications - The method for clearing all push notifications available in status bar
     */

    public clearAllNotifications(): void {
        if (this.validatePlugins) {
            FCM.clearAllNotifications();
        }
    }

    /**
     * initializeCadCallNotifications - The method for initialization and setup the ability to receive
     * push notifications about the CAD calls updates and assigning the Unit to the new call.
     */

    public initializeCadCallNotifications(unit: string, username: string): void {
        if (!this.validatePlugins) {
            return;
        }
        this.initializePermissionsAndToken().then( () => {
            this.callRepository = this.$injector.get<CallRepository>('callRepository');
            this.username = username;
            this.unit = unit;
            this.$rootScope.$on(this.callRepository.updateEventName, () => {
                this.usersCallIds = this.getUserCallIds();

                if (this.usersCallIds && !this.isAlreadyInitialized) {
                    this.ionic.Platform.ready(() => {
                        this.isAlreadyInitialized = true;
                        this.sendFcmTokenToAPI();
                    });
                }
            });
        });

        /**
         * Handle the refresh event to update subscription on push notification when the user
         * was unassigned from the CAD call from the Flex-client side
         */
        this.$rootScope.$on(REFRESH_PUSH_NOTIFICATIONS_EVENT, (_, args: string[]) => {
            if (!this.isAlreadyInitialized) {
                return;
            }
            this.clearAllNotifications();
            this.notificationDisposable.dispose();
            this.notificationDisposable = undefined;
            this.usersCallIds = args;
            this.isRefreshEvent = true;
            this.sendFcmTokenToAPI();
        });
    }

    /**
     * initializeAvailableVersionUpdate - The method for initialization and setup the ability to receive
     * push notifications about the new application version.
     */

    public initializeAvailableVersionUpdate(): void {
        if (!this.validatePlugins) {
            return;
        }

        this.initializePermissionsAndToken().then(() => {
            /**
             * getInitialPushPayload - used for receiving the notification addressed the Touch
             * from the OS Notification center when the Touch was closed
             */
            FCM.getInitialPushPayload().then(this.processAvailableVersionPayload);

            /**
             * onNotification - used to handle all received push notifications
             */
            this.notificationVersionDisposable = FCM.onNotification(this.processAvailableVersionPayload);
        });
    }

    /**
     * sendFcmTokenToAPI - the method to unsubscribe unit from push notifications and remove all notifications
     */

    public async unsubscribeFromPushNotification(): Promise<any> {
        if (!this.validatePlugins) {
            return new Promise((resolve) => resolve(true));
        }

        if (this.refreshTokenDisposable) {
            this.refreshTokenDisposable.dispose();
        }

        if (this.notificationDisposable) {
            this.notificationDisposable.dispose();
        }

        if (this.notificationVersionDisposable) {
            this.notificationVersionDisposable.dispose();
        }

        await FCM.clearAllNotifications();

        // this construction added to stabilize the application work with old servers
        try {
            await this.pushNotificationApi.unsubscribeFromNotifications(this.username);
        } catch (e) {
            this.$log.error(e);
        }

        return new Promise((resolve) => resolve(true));
    }

    /**
     * getUserCallIds The method used to receive the call ids that the current user was assigned to.
     */

    private getUserCallIds(): string[] {
        const calls = this.callRepository.models;
        const filteredCalls = calls.filter(call => call.unit === this.unit || call.otherUnits.includes(this.unit));
        return filteredCalls.map(call => call.callIdAndType);
    }

    /**
     * initializePermissionsAndToken - The method for checking push notification permissions,
     * requesting them if permissions don't receive.
     * Also receiving and saving Firebase Cloud Messaging token.
     */

    private async initializePermissionsAndToken(): Promise<void> {
        if (cordova?.platformId === 'android') {
            const permissions = await FCM.hasPermission();

            if (!permissions) {
                const wasPermissionGiven: boolean = await FCM.requestPushPermission();

                if (!wasPermissionGiven) {
                    return;
                }
            }
        }
        let apnsToken: string;
        if (cordova.platformId === 'ios') {
            await FCM.requestPushPermission();

            apnsToken = await FCM.getAPNSToken();
        }

        const token = await FCM.getToken();

        if (!token) {
            return;
        }

        this.$log.info(token);
        this.fcmToken = token;
        this.apnsToken = apnsToken;
        this.handleRefreshToken();
    }

    /**
     * handleRefreshToken - used to handle Firebase refresh token and update current fcm token for the client and server sides
     */

    private handleRefreshToken(): void {
        this.refreshTokenDisposable = FCM.onTokenRefresh((token: string) => {
            this.notificationDisposable.dispose();
            this.fcmToken = token;
            this.isRefreshEvent = true;
            this.sendFcmTokenToAPI();
        });
    }

    /**
     * sendFcmTokenToAPI - the method to subscribe unit on push notifications
     */

    private sendFcmTokenToAPI(): void {
        this.pushNotificationApi.subscribeOnNotifications(this.username, this.unit, this.fcmToken, this.usersCallIds??[])
            .then(
                (res) => {
                    this.$log.info(res);
                    /**
                     * onNotification - used to handle all received push notifications
                     */

                    this.notificationDisposable = FCM.onNotification(this.processPushPayload);

                    /**
                     * createNotificationChannel - used to create different notification channels to play
                     * different sounds according to the notification type
                     */

                    FCM.createNotificationChannel({
                        id: 'newCall',
                        name: 'New calls',
                        importance: 'high',
                        sound: 'notification_new_call',
                        visibility: 'public',
                        vibration: true,
                        lights: true
                    }).then();

                    FCM.createNotificationChannel({
                        id: 'callUpdate',
                        name: 'Existing calls',
                        importance: 'high',
                        sound: 'notification_call_update',
                        visibility: 'public',
                        vibration: true,
                        lights: true
                    }).then();

                    /**
                     * If the sendFcmTokenToAPI method fired for refreshing the server configuration
                     * we don't need to check initial push payload
                     */

                    if (this.isRefreshEvent) {
                        this.isRefreshEvent = false;
                        return;
                    }

                    /**
                     * getInitialPushPayload - used for receiving the notification addressed the Touch
                     * from the OS Notification center when the Touch was closed
                     */

                    FCM.getInitialPushPayload().then(this.processPushPayload);
                },
                error => this.$log.error(error)
            );
    }

    /**
     * validatePlugins - The method to validate all plugins used in this service
     */

    private get validatePlugins(): boolean {
        return typeof cordova !== 'undefined' && (cordova.plugins || cordova.plugins.market || FCM);
    }

    /**
     * processPushPayload - The method for processing the notification with information
     * about the CAD call updates and redirect to the call that was receive in the notification
     *
     * @param payload - The object with data received from the Firebase console
     */

    private processPushPayload = (payload: NotificationData): void => {
        if (!payload) {
            return;
        }
        if (payload.wasTapped && payload.call !== undefined) {
            this.clearAllNotifications();
            this.$location.path(`/app/calls/call/${payload.call}`);
        }
    };

    /**
     * processAvailableVersionPayload - The method for processing the notification with information
     * about the new version of the application available on the store
     *
     * @param payload - The object with data received from the Firebase console
     */

    private processAvailableVersionPayload = (payload: NotificationData): void => {
        const divider = '\n\n';
        if (!payload || !payload.isNotificationAboutAppUpdate) {
            return;
        }

        const appID = cordova.platformId === 'android' ? TOUCH_ANDROID_APP_ID : TOUCH_IOS_APP_ID;

        if (payload.wasTapped) {
            /**
             * If the user taps on push-notification then the user will be automatically redirected to the store
             */

            cordova.plugins.market.open(appID);
        } else {
            /**
             * If the user works in the application he will see a toast message about application update
             */

            const message = payload.title + divider + payload.body;
            this.customNotificationBuilder.showNotification({
                message,
                duration: 10000,
                position: 'bottom',
                styling: {
                    textAlign: 'center',
                    fontSize: 14,
                    opacity: 0.8,
                    color: '#fff',
                    backgroundColor: '#000'
                }
            }, () => cordova.plugins.market.open(appID));
        }

        this.clearAllNotifications();
    };
}
