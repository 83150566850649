/* Copyright © 2021 Motorola Solutions, Inc. All rights reserved. */

import { gridColumns } from '../../directives/IncidentHistoryConstants';
import { incidentSearchMetadataMap } from '../../../../rms/incident/search/incident-search-metadata';
import { IncidentType } from '../../../../rms/incident/incident-type';
import { BaseSearchController } from '../../../../searching/BaseSearchController';
import { CallHistoryAdvancedFilterService } from './call-history-advanced-filter.service';
/**
 * A component of call history filter.
 */
export const callHistoryAdvancedFilterComponent: angular.IComponentOptions = {
    template: require('./call-history-advanced-flter.component.html'),
    bindings: {
        incidentType: '<'
    },
    controller:

        /**
         * The controller for the CallHistoryAdvancedFilterComponent.
         */
        class CallHistoryAdvancedFilterComponentController extends BaseSearchController {

            protected get listStateName(): string {
                throw new Error('Method not implemented.');
            }

            public $onInit = () => {};

            public static $inject = ['$scope', 'serverDateTimeFormat', '$state', 'callHistoryAdvancedFilterService'];
            public columns = gridColumns;
            public searchParams = {
                nature: '',
                dtrepor: '',
                dispos: '',
                agency: '',
                respoff: '',
                geoaddr: ''
            };
            public incidentType: string;
            public callHistoryAdvancedFilterService: CallHistoryAdvancedFilterService;
            private IncidentType = IncidentType;

            /**
             * Constructs a new instance of the DisplayListComponentController class.
             *
             * @param $scope The Angular scope object that can listen for events.
             */
            constructor(
                $scope: any,
                serverDateTimeFormat: string,
                $state: angular.ui.IStateService,
                callHistoryAdvancedFilterService: CallHistoryAdvancedFilterService
            ) {
                super($state, serverDateTimeFormat);
                this.callHistoryAdvancedFilterService = callHistoryAdvancedFilterService;
                $scope.clearFilter = () => {
                    Object.keys(this.searchParams).map(key => {
                        this.searchParams[key] = ''; 
                    });
                    this.setLocation();
                    $scope.onSearch();
                };
                $scope.onSearch = () => {
                    callHistoryAdvancedFilterService.isSpinnerShown = true;
                    callHistoryAdvancedFilterService.searchParams[this.incidentType] = this.searchParams;
                    super.search(false, true);
                    callHistoryAdvancedFilterService.onSearch(this.incidentType, this.filter).then(
                        (data: any) => {
                            $scope.$emit('incidentsListUpdate', { data });
                        },
                        (error: any) => {
                            $scope.$emit('incidentsListUpdate', { error });
                        }).finally(() => {
                        callHistoryAdvancedFilterService.isSpinnerShown = false;
                    });
                };
                $scope.$watch(() => {
                    return callHistoryAdvancedFilterService.incidentsLocation;
                }, () => {
                    if (callHistoryAdvancedFilterService.incidentsLocation) {
                        this.setLocation();
                    }
                });
            }
            /**
             * Save location of incidents to filter params
             */
            private setLocation(): void {
                this.searchParams.geoaddr = this.callHistoryAdvancedFilterService.incidentsLocation;
            }
            /**
             * Gets metadata about the active incident type.
             */
            public getDispositionTable(): string {
                return incidentSearchMetadataMap.get(this.IncidentType[this.incidentType]).dispositionTable;
            }

            /**
             * Checks search parameters availability
             */
            public hasSearchParameters(): boolean {
                return !super.thereAreNoSearchParameters(1);
            }
        }
};
