/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IPromise = angular.IPromise;
import DirectoryFinder from './DirectoryFinder';
import FileUtilities from './FileUtilities';

/**
 * Finds the absolute path to Documents directory on an Android device.
 *
 * Note that the system-wide, top-level Documents directory is returned. This can easily be reached using a file browser.
 */
export default class AndroidDocumentsDirectoryFinder implements DirectoryFinder {

    /**
     * The name of the documents directory.
     */
    public static DOCUMENTS_DIRECTORY = 'Documents';

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['fileUtilities'];

    /**
     * Constructs a new instance of the AndroidDocumentsDirectoryFinder class.
     *
     * @param fileUtilities A class that provides utility methods for working with files.
     */
    constructor(private fileUtilities: FileUtilities) {
    }

    // @Override
    public findDirectory(): IPromise<string> {
        return this.fileUtilities.findDirectory(AndroidDocumentsDirectoryFinder.DOCUMENTS_DIRECTORY, true);
    }
}
