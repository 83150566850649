/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ResourceArrayPager = angular.resource.ResourceArrayPager;
import IPromise = angular.IPromise;
import IQService = angular.IQService;

/**
 * A "dummy" pager that, in fact, does no paging whatsoever but rather shows all results.
 */
export default class DummyResourceArrayPager<T> implements ResourceArrayPager<T> {

    public static $inject = ['$q'];

    /**
     * Constructs a new instance of the DummyResourceArrayPager class.
     *
     * @param _$q The angular service that handles creating and working with promises.
     */
    constructor(private _$q: IQService) {
    }

    /**
     * @override
     */
    public hasNextPage(): boolean {
        return false;
    }

    /**
     * @override
     */
    public appendNextPage(): IPromise<T[]> {
        return this._$q.when([]);
    }
}
