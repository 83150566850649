/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import CadListFilter from '../../filtering/CadListFilter';
import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import CallFilter from './CallFilter';
import SettingsRepository from '../../../settings/SettingsRepository';
import IInjectorService = angular.auto.IInjectorService;
import SettingKey from '../../../settings/SettingKey';

/**
 * An actual Angular filter that can be used in the view to select a subset of calls from an array.
 * This filter is configured by Filter Screen and uses values set there to determine how the calls should be filtered.
 *
 */
export default class CallCustomFilter extends CadListFilter<AggregatorCadCall, CallFilter> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['settingsRepository', '$injector'];

    /**
     * Constructs a new instance of the CallCustomFilter class.
     *
     * @param settingsRepository The repository that stores all settings.
     * @param $injector The Angular injector service.
     */
    constructor(settingsRepository: SettingsRepository, $injector: IInjectorService) {
        super(settingsRepository, $injector);
    }

    protected get settingKey(): SettingKey {
        return SettingKey.callFilterSetting;
    }

    protected getFilterName(value: CallFilter): string {
        return CallFilter[value];
    }
}
