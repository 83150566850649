/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { CookieCleaner } from '../cookie-cleanup/cookie-cleaner';
import { LogoutService } from '../../authentication';

/**
 * The service which handles logging the user out of the app.
 */
@Injectable()
export class ReloadingLogoutService implements LogoutService {

    /**
     * Constructs a new instance of the LogoutService.
     *
     * @param cookieCleaner Removes session cookies from the browser.
     */
    constructor(
        private cookieCleaner: CookieCleaner
    ) {
    }

    /**
     * Logs the current user out of the app.
     */
    public logout(): void {
        const reload = window.location.reload.bind(window.location);
        this.cookieCleaner.deleteCookies().then(reload, reload);
    }
}
