/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAttributes = angular.IAttributes;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IWindowService = angular.IWindowService;
import * as moment from 'moment';
import Moment = moment.Moment;
import RelativeTimeKey = moment.RelativeTimeKey;
import DurationAs = moment.unitOfTime.DurationAs;

const SDS_TIME_AGO = 'sdsTimeAgo';
const TIME_UNIT = 'timeUnit';
const USE_DECIMAL = 'useDecimal';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
TimeAgoDirective.$inject = ['$window', 'timeAgoPrecision'];

/**
 * A directive which will automatically update the relative time with decimal
 * precision given a time unit.
 * Parts of the code are taken from angular-moment library:
 * @see https://github.com/urish/angular-moment
 *
 * @param $window The Angular wrapper around the window object.
 * @param timeAgoPrecision An interface which defines the configuration for the TimeAgoDirective.
 * @returns {IDirective} A directive which automatically updates relative time.
 */
export default function TimeAgoDirective($window: IWindowService, timeAgoPrecision: TimeAgoPrecision): IDirective {
    const DEFAULT_PRECISION = 0;

    /**
     * Maps a `RelativeTimeKey` value to a `DurationAs` value.
     * This is used to display the final duration with the correct units.
     */
    const relativeTimeToDurationAsMap = new Map<RelativeTimeKey, DurationAs>([
        ['s', 's'],
        ['mm', 'm'],
        ['hh', 'h'],
        ['dd', 'd'],
        ['yy', 'y']
    ]);

    return {
        restrict: 'A',
        link: function (scope: IScope, element: IAugmentedJQuery, attrs: IAttributes) {
            let withoutSuffix = true;
            let activeTimeout: number = undefined;
            let modelName = attrs[SDS_TIME_AGO];
            let specifiedTimeUnit = attrs[TIME_UNIT];
            let useDecimal = attrs[USE_DECIMAL];
            let currentValue: any;

            /**
             * @returns A moment object of the current time when the
             * function is called.
             */
            function getNow(): Moment {
                return moment();
            }

            /**
             * Cancels the current $window timer.
             */
            function cancelTimer() {
                if (activeTimeout) {
                    $window.clearTimeout(activeTimeout);
                    activeTimeout = undefined;
                }
            }

            /**
             * Sets the html text for an element.
             * Processes time difference and sets a timer in seconds until the
             * next update call.
             * @param momentInstance A moment object.
             */
            function updateTime(momentInstance: Moment) {
                element.text(getPrecision(momentInstance));

                let howOld = Math.abs(getNow().diff(momentInstance, 'minute'));
                let secondsUntilUpdate = 3600;
                if (howOld < 1) {
                    secondsUntilUpdate = 1;
                } else if (howOld < 60 && !useDecimal) {
                    secondsUntilUpdate = 1;
                } else if (howOld < 60) {
                    secondsUntilUpdate = 30;
                } else if (howOld < 180) {
                    secondsUntilUpdate = 300;
                }

                activeTimeout = $window.setTimeout(function () {
                    updateTime(momentInstance);
                }, secondsUntilUpdate * 1000);
            }

            /**
             * Cancels the current timer and creates a moment from a given value
             * which is passed into the function updateTime.
             */
            function updateMoment() {
                cancelTimer();
                if (currentValue) {
                    let momentValue = moment(currentValue);
                    updateTime(momentValue);
                }
            }

            /**
             * A helper function for dertermining if a value is undefined or undefined.
             *
             * @param val A value to be checked.
             * @returns {boolean} Whether the value is undefined or null.
             */
            function isUndefinedOrNull(val: any): boolean {
                return angular.isUndefined(val) || val === undefined;
            }

            /**
             * Determines the unit for the current time difference, calculates
             * the decimal precision from the 'precision' object config variable
             * and then applies the relative time suffix from the moment library.
             *
             * @param momentInstance A moment object.
             * @returns {string} The combination of the relative time difference
             * and suffix.
             */
            function getPrecision(momentInstance: Moment): string {
                const timeMillis = Math.abs(getNow().diff(momentInstance)).valueOf();
                const timeDuration = moment.duration(timeMillis);
                let isFuture: boolean;
                let timeUnit: RelativeTimeKey[];
                const timeMap = new Map<string, number>([
                    ['s', timeDuration.seconds()],
                    ['mm', timeDuration.minutes()],
                    ['hh', timeDuration.hours()],
                    ['dd', timeDuration.asDays()],
                    ['yy', timeDuration.years()]
                ]);

                if (specifiedTimeUnit) {
                    timeUnit = [specifiedTimeUnit];
                } else if (timeMillis < 1000 * 60) {
                    timeUnit = ['mm', 's'];
                } else if (timeMillis < 1000 * 60 * 60) {
                    timeUnit = ['mm', 's'];
                } else if (timeMillis < 1000 * 60 * 60 * 24) {
                    timeUnit = ['hh', 'mm'];
                } else if (timeMillis < 1000 * 60 * 60 * 24 * 365) {  // Does not account for leap years
                    timeUnit = ['dd', 'hh'];
                } else {
                    timeUnit = ['yy', 'dd'];
                }

                isFuture = getNow().diff(momentInstance) < 0;

                let combinedTime = '';
                timeUnit.forEach(unit => {
                    combinedTime += moment.localeData().relativeTime(Math.floor(timeMap.get(unit)), withoutSuffix, unit, isFuture) + ' ';
                });

                const precision = timeAgoPrecision[timeUnit[0]] || DEFAULT_PRECISION;
                const durationAs = relativeTimeToDurationAsMap.get(timeUnit[0]);
                const timeValue = timeDuration.as(durationAs);
                const timeNumber = timeValue.toFixed(precision);

                if (specifiedTimeUnit) {
                    return timeNumber;
                } else if (useDecimal) {
                    return moment.localeData().relativeTime(+timeNumber, withoutSuffix, timeUnit[0], isFuture);
                } else {
                    return combinedTime.trim();
                }
            }

            /**
             * A watch function for changes with the current value.
             *
             * @prop modelName The value passed into the TimeAgoDirective.
             * @handler function(value) A function to set currentValue and
             * call function updateMmoment().
             */
            scope.$watch(modelName, (value) => {
                if (isUndefinedOrNull(value) || (value === '')) {
                    cancelTimer();
                    if (currentValue) {
                        element.text('');
                        currentValue = undefined;
                    }
                    return;
                }
                currentValue = value;
                updateMoment();
            });

            /**
             * When the scope is destroyed a function is called cleaning up the
             * activeTimer.
             */
            scope.$on('$destroy', function () {
                cancelTimer();
            });
        }
    };
}

/**
 *  An interface which defines the set of configurable options for the TimeAgo directive.
 */
interface TimeAgoPrecision {
    /**
     * The decimal precision for seconds.
     */
    s?: number;

    /**
     * The decimal precision for minutes.
     */
    mm?: number;

    /**
     * The decimal precision for hours.
     */
    hh?: number;

    /**
     * The decimal precision for days.
     */
    dd?: number;

    /**
     * The decimal precision for years.
     */
    yy?: number;
}
