/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * The address of a location where the vehicle is frequently located.
 */
@table('vhfreloc')
export class FrequentAddress {

    @expand('atype', 'descrpt')
    public type: string;

    @field('street')
    public street: string;

    @field('city')
    public city: string;

    @field('state')
    public state: string;

    @field('zip')
    public zip: string;
}
