/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * The force model
 */
@table('jlicforc')
export class Force {

    @field('ftime')
    public date: Date;

    @expand('officer', 'fullnam')
    public officer: string;

    @expand('force', 'descrpt')
    public description: string;
}
