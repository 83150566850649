/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * The model of an offense.
 */
@table('jloffens')
export class Offense {

    @field('num')
    public id: string;

    @expand('statute', 'desc')
    public statute: string;

    @expand('classif', 'desc')
    public classification: string;
}
