/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import Credentials from './Credentials';
import OfficerName from '../schema/OfficerName';
import UnitLongTerm from '../schema/UnitLongTerm';

/**
 * A class that contains the data stored in the Session.
 */
export default class SessionData {

    private _server: string;
    private _port: string;
    private _secureConnection: boolean;
    private _userCode: string;
    private _shortName: string;
    private _fullName: string;
    private _loginName: string;
    private _password: string;
    private _agencyCode: string;
    private _unitNumber: string;
    private _unitType: string;
    private _zone: string;
    private _credentials: Credentials;

    /**
     * Constructs a new instance of the SessionData class.
     *
     * @param credentials The credentials with which the user used to log in. It cannot be null.
     * @param user An object which represents the user. It cannot be null.
     * @param unit An object which represents the user's CAD unit. It can be null.
     */
    constructor(credentials: Credentials, user: OfficerName, unit: UnitLongTerm) {
        this.storeCredentialData(credentials);
        this.storeUserData(user);
        this.storeUnitData(unit);
    }

    /**
     * Stores data from the credentials.
     *
     * @param credentials The credentials used to login. It cannot be null.
     */
    private storeCredentialData(credentials: Credentials): void {
        this._server = credentials.server;
        this._port = credentials.port;
        this._password = credentials.password;
        this._secureConnection = credentials.secureConnection;
        this._credentials = credentials;
    }

    /**
     * Stores data from the user object.
     *
     * @param user An object that contains data about the user. It cannot be null.
     */
    private storeUserData(user: OfficerName): void {
        this._userCode = user.abbr;
        this._shortName = user.desc;
        this._fullName = user.fullnam;
        this._loginName = user.uidname;

        // The agency can either be of type `string` or `Expand`.
        let agency = user.agency;
        this._agencyCode = (typeof agency === 'string') ? agency : (agency && agency.value);
    }

    /**
     * Stores data from the CAD unit.
     *
     * @param unit The CAD unit to which the user is assigned. It can be null.
     */
    private storeUnitData(unit: UnitLongTerm): void {
        if (unit) {
            this._unitNumber = unit.unitno;
            this._unitType = unit.type;

            // The zone can either be of type `string` or `Expand`.
            let zone = unit.zone;
            this._zone = (typeof zone === 'string') ? zone : (zone && zone.value);
        }
    }

    /**
     * @returns The server that the user entered when logging in.
     */
    public get server(): string {
        return this._server;
    }

    /**
     * @returns The port that the user entered when logging in.
     */
    public get port(): string {
        return this._port;
    }

    /**
     * @returns Whether the server supports a secure connection (SSL).
     */
    public get secureConnection(): boolean {
        return this._secureConnection;
    }

    /**
     * @returns The user's code as defined by the apnames.abbr field in the database.
     */
    public get userCode(): string {
        return this._userCode;
    }

    /**
     * @returns The user's short name as defined by the apnames.desc field in the database.
     */
    public get shortName(): string {
        return this._shortName;
    }

    /**
     * @returns The user's full name as defined by the apnames.fullnam field in the database.
     */
    public get fullName(): string {
        return this._fullName;
    }

    /**
     * @returns The user's login name as defined by the apnames.uidname field in the database.
     */
    public get loginName(): string {
        return this._loginName;
    }

    /**
     * @returns The user's password.
     */
    public get password(): string {
        return this._password;
    }

    /**
     * @returns The user's agency code as defined by the apnames.agency field in the database.
     */
    public get agencyCode(): string {
        return this._agencyCode;
    }

    /**
     * @returns The user's unit number as defined by the cdunit.unitno field in the database.
     */
    public get unitNumber(): string {
        return this._unitNumber;
    }

    /**
     * @returns The user's unit type as defined by the cdunit.type field in the database.
     */
    public get unitType(): string {
        return this._unitType;
    }

    /**
     * @returns The user's zone as defined by the cdunit.zone field in the database.
     */
    public get zone(): string {
        return this._zone;
    }

    /**
     * @returns The credentials entered by the user when logging in.
     */
    public get credentials(): Credentials {
        return this._credentials;
    }
}
