export const APP_TAB_CALL_PERMISSION_NAME = 'app.calls';
export const APP_TAB_UNITS_PERMISSION_NAME = 'app.units';
export const APP_TAB_INCIDENTS_PERMISSION_NAME = 'app.myIncidents';
export const APP_TAB_SEARCH_PERMISSION_NAME = 'app.search';

export const updateTypes = {
    ASSIGN: 'assign',
    UPDATE: 'update',
    NONE: ''
};

export const CALL_DETAILS_STATE = 'app.calls.callsDetails';
