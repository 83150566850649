/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { SpillmanLocalStorage } from '../../../shared/storage/spillman-local-storage';
import { NEW_FILTER_TITLE } from '../consts';

/**
 * A class that handles persistent storage of the user's custom filter configuration.
 */
export default abstract class FiltersStorage {

    public abstract BUCKET: string;

    /**
     * A constant that specifies the key used when saving the filters list to localStorage.
     */
    private FILTER_PREFIX = 'filter_storage';

    /**
     * A constant that specifies the key used when applying a filter without saving.
     */
    public CURRENT_FILTER_KEY = 'Unsaved Filter';

    /**
     * A constant that specifies the maximum length of a filter name .
     */
    public static readonly MAXIMUM_LENGTH_FILTER_NAME = 20;

    /**
     * A constant that specifies the current filter's name
     */
    private currrentFilterName = '';

    /**
     * A constant that specifies the editing filter's name
     */
    private editingFilterName = '';

    /**
     * A constant that specifies the key used when saving the filters list to localStorage.
     */
    public static FILTERS_KEY = 'filters';

    /**
     * A constant that specifies the key used when saving multiple filters list
     */
    public abstract ALL_SAVED_FILTER_NAMES: string;

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['spillmanLocalStorage'];

    /**
     * Constructs a new instance of the FiltersStorage class.
     *
     * @param spillmanLocalStorage The object that handles storing Spillman-specific data in local storage.
     */
    constructor(private spillmanLocalStorage: SpillmanLocalStorage) { }

    /**
     * retrieves a list of strings with all of the filter's names.
     *
     * @returns {string[]} The saved array of filters names
     */
    public getAllSavedFilterNames = (): string[] => {
        return this.spillmanLocalStorage.getObject({ key: this.ALL_SAVED_FILTER_NAMES, userSpecific: true }) ?? [];
    };

    /**
     * delete the saved filter
     *
     * @param {string[]} The saved array of filters names
     */
    public deleteFilter = (filterName: string): void => {
        let allFilters = this.getAllSavedFilterNames() ;
        if (this.editingFilterName === filterName) {
            this.editingFilterName = '';
        }
        if (this.currrentFilterName === filterName) {
            this.setCurrentFilter('');
        }
        allFilters = allFilters.filter((current) => current !== filterName);
        this.spillmanLocalStorage.setObject({ key: this.ALL_SAVED_FILTER_NAMES, userSpecific: true}, allFilters);

        this.spillmanLocalStorage.remove({ key: this.buildFilterStorageKey(filterName, FiltersStorage.FILTERS_KEY), userSpecific: true });
    };

    /**
     * delete the unsaved filter
     */
    public deleteUnsavedFilterIfExist = (): void => {
        this.deleteFilter(this.CURRENT_FILTER_KEY);
    };

    public hasSavedFilters(): boolean {
        return this.spillmanLocalStorage.getObject({ key: FiltersStorage.FILTERS_KEY, userSpecific: true }) !== undefined;
    }

    public clearEditingFilter(): void {
        this.editingFilterName = '';
    }

    public setCurrentFilter(filterName: string): void {
        this.currrentFilterName = filterName;
    }

    public getCurrentFilter(): string {
        return this.currrentFilterName;
    }

    public setCurrentAsEditing(): void {
        this.editingFilterName = this.currrentFilterName;
    }

    public promoteEditingAsCurrent(): void {
        if (this.editingFilterName){
            this.setCurrentFilter(this.editingFilterName);
        } else {
            this.setCurrentFilter( this.CURRENT_FILTER_KEY);
        }
    }

    public getEditingFilterName(): string {
        return this.editingFilterName;
    }

    /**
     * Retrieves the current saved filter values
     * @param storageKey Indicates which type of filter to retrieve based on storageKey value.
     * @returns {FilterModel[]} The saved array of filters or an empty array if no filters are saved.
     */
    public retrieveCurrentFilter(storageKey: string): FilterModel[] {
        if (this.currrentFilterName) {
            return this.retrieve(this.currrentFilterName, storageKey);
        }

        return [];
    }

    /**
     * Retrieves the editing filter values
     * @param storageKey Indicates which type of filter to retrieve based on storageKey value.
     * @returns {FilterModel[]} The saved array of filters or an empty array if no filters are saved.
     */
    public retrieveEditingFilter(storageKey: string): FilterModel[] {
        if (this.editingFilterName) {
            return this.retrieve(this.editingFilterName, storageKey);
        }

        return [];
    }

    /**
     * Retrieves the saved filters list.
     * @param storageKey Indicates which type of filter to retrieve based on storageKey value.
     * @returns {FilterModel[]} The saved array of filters or an empty array if no filters are saved.
     */
    public retrieve(filterName: string, storageKey: string): FilterModel[] {
        let filters = this.spillmanLocalStorage.getObject({ key: this.buildFilterStorageKey(filterName, FiltersStorage.FILTERS_KEY), userSpecific: true });
        return filters === undefined ? [] : filters.filter((item: FilterModel) => item.storageKey === storageKey);
    }

    /**
     * Replaces the saved array of filters with the new ones
     *
     * @param filterName The new filter name.
     * @param filterModels The new filter array to be saved.
     */
    public saveOnCurrentFilter(filterModels: FilterModel[]): void {
        if (this.currrentFilterName) {
            this.saveOrUpdate(this.currrentFilterName, filterModels);
        } else {
            this.currrentFilterName = this.CURRENT_FILTER_KEY;
            this.saveOrUpdate(this.CURRENT_FILTER_KEY, filterModels);
        }
    }

    /**
     * Replaces the saved array of filters with the new ones
     *
     * @param filterName The new filter name.
     * @param filterModels The new filter array to be saved.
     */
    private saveOrUpdate(filterName: string, filterModels: FilterModel[]): void {
        this.saveNewFilter(filterName);

        // Save only basic data
        const mapFilters = filterModels.map(({applied, checked, id, storageKey}) => {
            return {
                applied,
                checked,
                id,
                storageKey
            };
        });

        this.spillmanLocalStorage.setObject({ key: this.buildFilterStorageKey(filterName, FiltersStorage.FILTERS_KEY), userSpecific: true }, mapFilters);
    }

    public isEditingFilterSaved = () => {
        return this.currrentFilterName !== this.CURRENT_FILTER_KEY && this.getAllSavedFilterNames().includes(this.editingFilterName);
    };

    public isCurrentFilter = (filterName: string) => {
        return filterName && filterName === this.currrentFilterName;
    };

    /**
     * Build the full storage key that is used in the filters storage
     *
     * @param filterName The new filter name.
     * @param filterKey The filter key.
     * @param fullKey The full filter key.
     */
    private buildFilterStorageKey = (filterName: string, filterKey: string): string => {
        return [this.FILTER_PREFIX, this.BUCKET ?? '', filterName, filterKey].join('-');
    };

    /**
     * Save a new filter in the saved list filters
     *
     * @param filterName The new filter name.
     */
    private saveNewFilter = (newFilterName: string): void => {
        const allFilters = this.getAllSavedFilterNames() ;
        this.setCurrentFilter(newFilterName);
        if (!allFilters.includes(newFilterName)) {
            allFilters.push(newFilterName);
            this.spillmanLocalStorage.setObject({ key: this.ALL_SAVED_FILTER_NAMES, userSpecific: true}, allFilters);
        }
    };

    /**
     * Checks if filter is already saved
     *
     * @param filterName The filter name.
     */
    public isFilterAlreadySaved(filterName: string) {
        return this.getAllSavedFilterNames().includes(filterName) && !filterName.match(this.CURRENT_FILTER_KEY);
    }

    /**
     * Checks if filter name is valid
     *
     * @param filterName The filter name.
     */
    public isFilterNameInvalid(filterName: string) {
        return !filterName || ['All', NEW_FILTER_TITLE, this.CURRENT_FILTER_KEY].includes(filterName);
    }

    /**
     * Checks if filter name length is valid
     *
     * @param filterName The filter name.
     */
    public isFilterNameSizeInvalid(filterName: string) {
        return !filterName || filterName.length > FiltersStorage.MAXIMUM_LENGTH_FILTER_NAME;
    }
}
