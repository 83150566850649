/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * The demographic group member detail model
 */
@table('rcpassdl')
export class GroupMember {

    @expand('abbr', 'desc')
    public member: string;

    @expand('race', 'desc')
    public race: string;

    @field('gender')
    public gender: string;

    @field('age')
    public ageRange: string;

    @expand('ethnic', 'desc')
    public ethnic: string;
}
