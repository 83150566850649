/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { InjectionCondition } from './injection-condition';

/**
 * The metadata key used to associate a dependency injection condition with a particular class.
 */
export const injectionConditionMetadataKey = Symbol('injectionCondition');

/**
 * A decorator that associates conditions with the service on which it is applied.
 * The service will only be injected if the conditions are met.
 *
 * @param condition The condition that must be met in order for the service to be injected.
 * @returns A decorator that associates dependency injection conditions with the class.
 */
export function injectionCondition(condition: InjectionCondition) {
    return Reflect.metadata(injectionConditionMetadataKey, condition);
}
