/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../shared/database/decorators';

/**
 * The incident reference model.
 */
@table('lwmain')
export class IncidentReference {

    @field('number')
    public id: string;

    @field('nature')
    public nature: string;
}
