/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { PropertyNodeFactory } from './property-node-factory';
import { ClassNode } from '../class/class-node';
import { PropertyNode } from './property-node';
import { involvementsMetadataKey } from '../../../decorators';
import { InvolvementsNode } from './involvements-node';

/**
 * A factory that knows how to create InvolvementsNodes.
 */
@Injectable()
export class InvolvementsNodeFactory implements PropertyNodeFactory {

    /**
     * @inheritdoc
     */
    public create(parent: ClassNode, propertyKey: string | symbol): PropertyNode {
        const involvementMetadata: symbol = Reflect.getMetadata(involvementsMetadataKey, parent.classConstructor.prototype, propertyKey);

        if (involvementMetadata) {
            return new InvolvementsNode(parent, propertyKey);
        }
    }
}
