/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ITimeoutService = angular.ITimeoutService;
import IDocumentService = angular.IDocumentService;
import GuaranteedTimeout from './GuaranteedTimeout';

/**
 * A factory that creates instances of the GuaranteedTimeout class.
 *
 * The reason that a factory is used is because the GuaranteedTimeout constructor
 * accepts both dependencies that must be injected in by Angular (i.e. $timeout, $document, moment)
 * as well as per-instance variables (i.e. func and delay). This factory handles dependency injection
 * thus simplifying creation of new GuaranteedTimeout instances.
 */
export default class GuaranteedTimeoutFactory {

    public static $inject = ['$timeout', '$document'];

    /**
     * Constructs a new instance of the GuaranteedTimeoutFactory class.
     *
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param $document The Angular wrapper around the native document.
     */
    constructor(private $timeout: ITimeoutService,
        private $document: IDocumentService) {
    }

    /**
     * Creates a new GuaranteedTimeout that will execute the given func after the specified delay.
     *
     * @param func The function to be executed after the delay.
     * @param delay The amount of time to wait before executing the function.
     * @returns A new instance of the GuaranteedTimeout class.
     */
    public timeout(func: Function, delay: number): GuaranteedTimeout {
        return new GuaranteedTimeout(this.$timeout, this.$document, func, delay);
    }
}
