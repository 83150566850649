/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * A map containing a list of hardcoded gender codes and their descriptions.
 */
export const genderLookup = Object.freeze({
    M: 'Male',
    F: 'Female',
    U: 'Unknown',
    X: 'Nonbinary'
});

/**
 * A pipe that formats a gender code to its description or leaves the code as-is if no
 *  look up is available.
 *
 * Usage:
 *   genderCode | sdsGender
 *
 * Example:
 *   {{ 'F' |  sdsGender }}
 *   outputs: Female
 */
@Pipe({
    name: 'sdsGender'
})
export class GenderPipe implements PipeTransform {

    /**
     * Transforms a gender code to a description.
     *
     * @param code the gender code to expand.
     */
    public transform(code: string): string {
        return code in genderLookup ? genderLookup[code] : code;
    }
}
