/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';

/**
 * The premise area model
 */
@table('biarea')
export class Area {

    @expand('locatn', 'desc')
    public location: string;
}
