/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import RadioLog from '../../schema/RadioLog';

const GET_ID = 'getId';

/**
 * A directive that displays a list of Radio Logs with a header section.
 */
export default function RadioLogListDirective(): IDirective {
    return {
        restrict: 'E',
        scope: {
            radioLogs: '='
        },
        template: require('./radioLogList.html'),
        link: (scope) => {
            // Create a unique id for each radio log. Radio logs have a composite primary key so multiple fields must be used.
            // This allows for faster updates, because the entire list doesn't need to be re-rendered just those items that changed.
            scope[GET_ID] = (radioLog: RadioLog) => `unit:${radioLog.unit},seq:${radioLog.seq},logdate:${radioLog.logdate}`;
        }
    };
}
