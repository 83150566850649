/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import Option from '../Option';
import { getBoolean } from '../../../app/api/analytics/firebase-remote-config-service';
import { logError } from '../../../app/api/analytics/firebase-crashlytics-service';
import AdvancedUnitsFilterController from '../../cad/units/screens/advanced-units-filter-screen/advanced-units-filter-controller';
import UnitFilter from '../../cad/units/filtering/UnitFilter';
import { logEvent } from '../../../app/api/analytics/firebase-analytics-service';
import FiltersStorage from 'app/cad/filtering/storage/FiltersStorage';
import { unitsAnalyticsEvents } from '../../cad/filtering/analytics/events';

/**
 * A class that handles persistent storage of the user's custom filter configuration.
 */
export default class UnitFilterVersion {

    public static $inject = ['unitFiltersStorage', '$state'];

    private ADVANCED_UNIT_FILTER_FEATURE_TOGGLE = 'advanced_unit_filter_feature_toggle';

    /**
     * Constructs a new instance of the UnitFilterVersion class.
     *
     * @param filtersStorage The object that handles storing filter specific data in local storage.
     * @param $state The object that handles this class state.
     */
    constructor(
        private filtersStorage: FiltersStorage,
        public $state: angular.ui.IStateService
    ) { }

    private newFilterOptions = [
        new Option('All', UnitFilter.filterNone),
        new Option('Create New Filter', UnitFilter.unitNewFilter, {} ,() => {
            this.openFilterScreen();
        })
    ];

    private oldFilterOptions = [
        new Option('All', UnitFilter.filterNone),
        new Option('Agency', UnitFilter.unitFilterByAgency),
        new Option('Type', UnitFilter.unitFilterByType, { requiresUnit: true }),
        new Option('Zone', UnitFilter.unitFilterByZone, { requiresUnit: true }),
    ];

    public getNewFilterOptions = this.newFilterOptions;

    /**
     * Gets the feature flag for unit filter toggle and decides which options to use.
     *
     * @return list of options to be shown in filter dropdown of unit screen.
     */
    public getFilterOptions = (): Promise<Option<UnitFilter>[]> => {
        return getBoolean(this.ADVANCED_UNIT_FILTER_FEATURE_TOGGLE).then((newFilterFeatureToggle: any) => {
            if (newFilterFeatureToggle) {
                return this.newFilterOptions;
            } else {
                return this.oldFilterOptions;
            }
        }).catch((error: any) => {
            logError(error);
            return this.oldFilterOptions;
        });
    };

    private openFilterScreen = () => {
        this.filtersStorage.clearEditingFilter();
        const routeName = AdvancedUnitsFilterController.routeName;
        logEvent(unitsAnalyticsEvents.open_filter_screen);
        this.$state.go(routeName);
    };
}
