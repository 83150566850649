/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import { Offense } from '../../../jail/shared/model/offense';

/**
 * A component which displays a field which links to a jail offense.
 */
@Component({
    selector: 'sds-jail-offense-link',
    template: `
        <sds-permissions-link state="app.search.jailOffenseDetails" [id]="jailOffense.id" *ngIf="jailOffense && jailOffense.id">
            <p>{{jailOffense.id}}</p>
            <p *ngIf="jailOffense.statute">{{jailOffense.statute}}</p>
        </sds-permissions-link>
        <span *ngIf="jailOffense && !jailOffense.id">{{jailOffense}}</span>
        <span *ngIf="!jailOffense">&nbsp;</span>
    `,
    styles: [
        require('./link.component.scss')
    ]
})
export class JailOffenseLinkComponent {

    /**
     * The jail offense to display.
     */
    @Input() public jailOffense: Offense;
}
