/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { IncludeOneNode } from '../../nodes/property/include-one-node';
import { IncludeOneSelectNode } from '../../nodes/property/include-one-select-node';
import { IncludeManyNode } from '../../nodes/property/include-many-node';
import { IncludeManySelectNode } from '../../nodes/property/include-many-select-node';
import { RequestCreationData } from '../request-creation-data';

type IncludeNode = IncludeOneNode | IncludeOneSelectNode | IncludeManyNode | IncludeManySelectNode;

/**
 * Performs a "visit" on either an IncludeOneNode or IncludeOneSelectNode or IncludeManyNode or IncludeManySelectNode
 * during the process of building a request.
 */
@Injectable()
export class IncludeNodeRequestCreationVisitor implements TypeSpecificTreeNodeVisitor<IncludeNode, RequestCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [IncludeOneNode, IncludeOneSelectNode, IncludeManyNode, IncludeManySelectNode];

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: IncludeNode, data: RequestCreationData): void {
        if (data.currentPath.length > 0) {
            throw new Error(`The include on the ${node.parent.classConstructor.name}.${node.propertyKey as string} property is not supported since it is inside of either another include or an expand`);
        }

        // Push the table onto the path.
        data.currentPath.push(node.table);

        // Add to the set of includes.
        data.request.includes.add(node.table);
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: IncludeNode, data: RequestCreationData): void {
        // Pop off the table.
        data.currentPath.pop();
    }
}
