/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { DurationInputObject } from 'moment';
import Option from '../settings/Option';

/**
 * The standard options from which the user can select when performing a search on a date field.
 */
export default [
    new Option<DurationInputObject>('2 Hours', { hours: 2 }),
    new Option<DurationInputObject>('8 Hours', { hours: 8 }),
    new Option<DurationInputObject>('1 Day', { days: 1 }),
    new Option<DurationInputObject>('3 Days', { days: 3 }),

    // TODO: Remove the "cast" when this bug is fixed: https://github.com/moment/moment/issues/3702
    new Option<DurationInputObject>('1 Week', <DurationInputObject>{ weeks: 1 }),
    new Option<DurationInputObject>('2 Weeks', <DurationInputObject>{ weeks: 2 }),
    new Option<DurationInputObject>('1 Month', { months: 1 }),
    new Option<DurationInputObject>('3 Months', { months: 3 }),
    new Option<DurationInputObject>('6 Months', { months: 6 })
];
