/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import FileMetadata from '../schema/FileMetadata';
import ComputedUrlResource from '../resource/computedUrl/ComputedUrlResource';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
FileAPI.$inject = ['computedUrlResource'];

/**
 * A factory function that creates an API for retrieving file metadata from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @returns {IResourceClass<ImageMetadata>} A resource class that knows how to load file metadata from the REST server.
 */
function FileAPI(computedUrlResource: ComputedUrlResource): IResourceClass<FileMetadata> {
    return computedUrlResource<FileMetadata>({ path: '/tables/:table/:id/files' });
}

export default FileAPI;
