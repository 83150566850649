/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import CadFilter from '../CadFilter';
import CADModel from '../../../schema/interfaces/CADModel';

/**
 * A filter that accepts an array of other filters. If any one of those others filters returns true,
 * then the final result will be true. If all of them return false, then the final result will be false.
 * In other words, this class performs a logical OR operation on those filters.
 */
abstract class LogicalOrFilter<T extends CADModel> implements CadFilter<T> {

    /**
     * The array of filters.
     */
    protected filters: CadFilter<T>[];

    /**
     * Constructs a new instance of the LogicalOrFilter class.
     *
     * @param filters A collection of filters to which to apply an OR operation.
     */
    constructor(...filters: CadFilter<T>[]) {
        this.filters = filters || [];
    }

    // @Override
    public include(model: T): boolean {
        for (let filter of this.filters) {
            if (filter.include(model)) {
                return true;
            }
        }
        return false;
    }
}

export default LogicalOrFilter;
