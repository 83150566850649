/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * TODO: The README file for this npm module needs to explicity state that this class must
 * be implemented.
 */

/**
 * An abstract class which performs the logout process.
 */
export abstract class LogoutService {

    /**
     * Logs the user out of the application.
     */
    public abstract logout(): void;
}
