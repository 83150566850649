/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../../shared/database/decorators';

/**
 * An offense.
 */
@table('lwoffs')
export class Offense {

    @expand('statute', 'desc')
    public statute: string;
}
