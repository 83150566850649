/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * An object representing a component which displays a message on the screen.
 */
const messageComponent = {
    transclude: true,
    template: '<div class="message" ng-transclude></div>'
};

export default messageComponent;
