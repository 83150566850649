/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Credentials } from '../Credentials';

/**
 * An abstract class that defines a method for removing cookies.
 */
export abstract class CookieCleaner {

    /**
     * Deletes session cookies from the browser (particularly the JSESSIONID and JSESSIONIDSSO cookies).
     *
     * @param credentials (optional) The information used to authenticate the user. By default the credentials stored in the
     *                    session will be used. The credentials need to be explicitly passed in if a session does not exist.
     * @returns A promise whose completion indicates that all cookies were deleted.
     */
    public abstract deleteCookies(credentials?: Credentials): Promise<void>;
}
