/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, includeMany, expand, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { Alarm } from './alarm';
import { Area } from './area';
import { Business } from './business';
import { Contact } from './contact';
import { Description } from './description';
import { Involvement } from '../../../schema/Involvement';

/**
 * The premise model
 */
@table('bimain')
export class Premise {

    @field('alrmno')
    public alarmNumber: string;

    @includeMany('bialarms', Alarm)
    public alarms: Alarm[];

    @includeMany('biarea', Area)
    public areas: Area[];

    @includeMany('bibusin', Business)
    public businesses: Business[];

    @field('city')
    public city: string;

    @field('comm')
    public comments: string;

    @includeMany('bicontct', Contact)
    public contacts: Contact[];

    @field('cname')
    public commonName: string;

    @includeMany('bidesc', Description)
    public descriptions: Description[];

    @field('fdepart')
    public fireAgency: string;

    @expand('fireoff', 'fullnam')
    public fireOfficer: string;

    @field('floors')
    public floors: number;

    @field('gpm')
    public gpmRequired: string;

    @field('lawenf')
    public lawAgency: string;

    @expand('secoff', 'fullnam')
    public lawOfficer: string;

    @field('fileno')
    public plansNumber: string;

    @field('state')
    public state: string;

    @field('street')
    public street: string;

    @expand('ptype', 'desc')
    public type: string;

    @field('zip')
    public zip: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
