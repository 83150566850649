/* Copyright © 2024 Motorola Solutions, Inc. All rights reserved. */

export const AUTH_EVENTS = {
    init_set_trust_to_default: 'auth_flow_init_set_trust_to_default',
    success_set_trust_to_default: 'auth_flow_success_set_trust_to_default',
    fail_set_trust_to_default: 'auth_flow_fail_set_trust_to_default',

    init_get_version: 'auth_flow_init_get_version',
    success_get_version: 'auth_flow_success_get_version',
    fail_get_version: 'auth_flow_fail_get_version',

    init_set_trust_to_no_check: 'auth_flow_init_set_trust_to_no_check',
    success_set_trust_to_no_check: 'auth_flow_success_set_trust_to_no_check',
    fail_set_trust_to_no_check: 'auth_flow_fail_set_trust_to_no_check',
    new_service_error: 'auth_flow_new_service_error',

    init_get_version_2: 'auth_flow_2_init_get_version_',
    success_get_version_2: 'auth_flow_2_success_get_version',
    fail_get_version_2: 'auth_flow_2_fail_get_version',

    init_symodule: 'auth_flow_init_symodule_request',
    success_symodule: 'auth_flow_success_symodule_request',
    fail_symodule: 'auth_flow_fail_symodule',
    ok_symodule: 'auth_flow_ok_symodule',

    init_user_verification: 'auth_flow_init_user_verification',
    success_user_verification: 'auth_flow_success_user_verification',
    fail_user_verification: 'auth_flow_fail_user_verification',

    not_assigned_to_unit: 'auth_flow_not_assigned_to_unit',
    unit_check_ok: 'auth_flow_unit_check_ok',

    session_timeout_init: 'auth_flow_session_timeout_init',
    session_timeout_success: 'auth_flow_session_timeout_success',

    push_notification_set: 'auth_flow_push_notification_set_request',
    push_notification_set_success: 'auth_flow_push_notification_set_success',

    regeneratable_error: 'auth_flow_regeneratable_error',
    attach_files_tables_store_error: 'auth_flow_attach_files_tables_store_error',
    attach_files_tables_store_success: 'auth_flow_attach_files_tables_store_success',

    generic_auth_error: 'auth_flow_generic_auth_error',

    checking_password_expiration: 'auth_flow_checking_password_expiration_status',
    checked_password_expiration: 'auth_flow_checked_password_expiration',
    expired_password_expiration: 'auth_flow_expired_password_expiration',
    error_password_expiration: 'auth_flow_error_password_expiration',

    system_use_success: 'auth_flow_system_use_success',
    system_use_failed: 'auth_flow_system_use_failed',

    authenticationService_complete: 'auth_flow_authenticationService_complete',

    available_version_update_init: 'auth_flow_available_version_update_init',
    available_version_update_fail: 'auth_flow_available_version_update_fail',

    credentials_update_init: 'auth_flow_credentials_update_init',
    credentials_update_success: 'auth_flow_credentials_update_success',

    navigate_to_home: 'auth_flow_navigate_to_home',

    open_password_expiration_popup: 'auth_flow_open_password_expiration_popup',
} ;
