/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { SpillmanLocalStorage } from '../../../../shared/storage/spillman-local-storage';
import FiltersStorage from '../../../filtering/storage/FiltersStorage';
import { UNIT_BUCKET } from '../../../filtering/consts';

/**
 * A class that handles persistent storage of the user's unit custom filter configuration.
 */
export default class UnitFiltersStorage extends FiltersStorage {

    public BUCKET: string;

    // @override
    public ALL_SAVED_FILTER_NAMES = 'all_units_saved_filter_names';

    /**
     * Constructs a new instance of the FiltersStorage class.
     *
     * @param spillmanLocalStorage The object that handles storing Spillman-specific data in local storage.
     */
    constructor(spillmanLocalStorage: SpillmanLocalStorage) {
        super(spillmanLocalStorage);
        this.BUCKET = UNIT_BUCKET;
        this.deleteUnsavedFilterIfExist();
    }
}
