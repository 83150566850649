/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';

/**
 * The demographic outcome detail model
 */
@table('rcocdl')
export class Outcome {

    @expand('abbr', 'desc')
    public outcome: string;
}
