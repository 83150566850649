/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { combinePropertyDecorators } from './decorator-helpers';
import { declareProperty } from './declare-property.decorator';
import { Constructor } from '../../interfaces/constructor';

/**
 * The metadata key used to associate an expanded reference with an object property.
 */
export const expandMetadataKey = Symbol('expand');

/**
 * The data stored by the expand decorator.
 */
export interface ExpandData {

    /**
     * The database column to expand.
     */
    column: string;

    /**
     * The constructor of the child model. This will be passed onto the child ClassNode.
     * This is needed because of a limitation with TypeScript and the Reflect Metadata API.
     * The Union Type (e.g. `ChildModel | string`) isn't stored in the metadata -- it just reports `Object`.
     */
    childConstructor?: Constructor<any>;

    /**
     * The name of the single field to retrieve from the expanded object.
     * If undefined, then all fields from the expanded object will be retrieved.
     */
    select: string;
}

/**
 * A decorator factory that associates a database column to be expanded
 * with the property on which it is applied.
 *
 * @param column The name of the database column to expand.
 * @param childConstructorOrSelect Either the constructor of the child model or the name of a column to select.
 * @returns The actual decorator that associates the expanded column with the property.
 */
export function expand(column: string, childConstructorOrSelect: Constructor<any> | string) {
    const data: ExpandData = {
        column,
        childConstructor: (typeof childConstructorOrSelect !== 'string') ? childConstructorOrSelect : undefined,
        select: (typeof childConstructorOrSelect === 'string') ? childConstructorOrSelect : undefined
    };
    return combinePropertyDecorators(declareProperty, Reflect.metadata(expandMetadataKey, data));
}
