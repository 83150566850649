/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResource = angular.resource.IResource;
import IResourceArray = angular.resource.IResourceArray;
import PropertyChangedQuery from '../../../../shared/PropertyChangedQuery';

/**
 * The base class for all Unit queries.
 */
abstract class UnitQuery<T extends IResource<any> | IResourceArray<any>> extends PropertyChangedQuery<T> {
    // @Override
    protected get objectName(): string {
        return 'unit';
    }
}

export default UnitQuery;
