/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';
import { NameReference } from '../../shared/model/name-reference';

/**
 * The premise business model
 */
@table('bibusin')
export class Business {

    @expand('nameid', NameReference)
    public name: NameReference | string;
}
