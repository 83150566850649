/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Pipe, PipeTransform, Inject } from '@angular/core';
import { TableInfo } from '../../table-info';

/**
 * A pipe that retrieves information about a database table from the `tableInfoMap`.
 *
 * Example:
 *   {{ 'nmmain' | sdsTableInfo:'name' }} --> 'Name'
 *   {{ 'nmmain' | sdsTableInfo:'mainState' }} --> 'app.search.namesDetails'
 */
@Pipe({
    name: 'sdsTableInfo'
})
export class TableInfoPipe implements PipeTransform {

    /**
     * Constructs a new instance of the TableInfoPipe class.
     *
     * @param tableInfoMap The map that stores information database tables.
     */
    constructor( @Inject('tableInfoMap') private tableInfoMap: Map<string, TableInfo>) {
    }

    /**
     * Retrieves a specific piece of information about the database table.
     *
     * @param table The database table for which to retrieve the information.
     * @param propertyName The name of the property to retrieve from the `tableInfoMap`.
     */
    public transform(table: string, propertyName: string): any {
        if (this.tableInfoMap.has(table)) {
            const info = this.tableInfoMap.get(table);
            return info[propertyName];
        }
        return undefined;
    }
}
