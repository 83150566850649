/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * A case file event.
 */
@table('pcsched')
export class Event {

    @expand('type', 'desc')
    public type: string;

    @field('when')
    public when: Date;
}
