/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { PropertyNode } from './property-node';
import { ClassNode } from '../class/class-node';
import { TreeNode } from '../../../../data-structures/tree-node';

/**
 * A node that associates a single column from an expanded object with a property of a JavaScript class.
 */
export class ExpandSelectNode extends PropertyNode {

    /**
     * Constructs a new instance of the ExpandSelectNode class.
     *
     * @param parent The parent ClassNode.
     * @param propertyKey The property key (or name if you prefer).
     * @param column The database column to expand.
     * @param select The column from the expanded object to select.
     */
    constructor(parent: ClassNode, propertyKey: string | symbol, public column: string, public select: string) {
        super(parent, propertyKey);
    }

    /**
     * @inheritdoc
     */
    protected *initializeChildren(): Iterable<TreeNode> {
        // No children
    }
}
