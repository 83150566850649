/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import Name from '../../../schema/Name';
import Involvement from '../../../schema/Involvement';
import InvolvementResourceClass from '../../involvement/InvolvementResourceClass';
import DecoratorTransform from '../../../shared/transforms/DecoratorTransform';
import ResourceUtils from '../../../resource/ResourceUtils';
import CriticalNotice from '../../../schema/CriticalNotice';
import SexOffender from '../../../schema/SexOffender';

/**
 * A decorator transform that will add involvement alerts to the Name.
 */
export default class NameAlertsDecoratorTransform implements DecoratorTransform<Name> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['involvementAPI', 'resourceUtils'];

    /**
     * Constructs a new instance of the NameAlertsDecoratorTransform class.
     *
     * @param involvementAPI involvementAPI The API for retrieving involvements from the server.
     * @param resourceUtils
     */
    constructor(private involvementAPI: InvolvementResourceClass, private resourceUtils: ResourceUtils) {
    }

    // @Override
    public invoke(name: Name): void {
        if (name.number) {
            let involvementAlerts = this.involvementAPI.alerts({
                table: 'nmmain',
                id: name.number
            });

            name.alerts = this.resourceUtils.createResourceArray(involvementAlerts.$promise.then<(CriticalNotice|SexOffender|Involvement)[]>(() => {
                const combinedAlerts: (CriticalNotice|SexOffender|Involvement)[] = [];
                combinedAlerts.pushAll(involvementAlerts);

                // Add the sex offender records (if present).
                if (name.sexOffenders) {
                    combinedAlerts.pushAll(name.sexOffenders);
                }

                // Add critical notice records (if present).
                if (name.criticalNotices) {
                    combinedAlerts.pushAll(name.criticalNotices);
                }

                return combinedAlerts;
            }));
        }
    }
}
