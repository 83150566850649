/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * Information about a person's probation.
 */
@table('nmextra')
export class Probation {

    @expand('ptype', 'desc')
    public type: string;

    @field('pofficr')
    public officer: string;
}
