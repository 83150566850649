/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IHttpService = angular.IHttpService;
import { Inject, Injectable } from '@angular/core';
import { RequestOptions } from '@angular/http';
import { SetAuthenticationHeadersService, authorizationHeaderName } from '../../authentication';

/**
 * A class which will set the default security headers for angular 1 and 2.
 */
@Injectable()
export class Ng1And2SetAuthenticationHeadersService extends SetAuthenticationHeadersService {

    /**
     * Constructs a new instance of SetAuthenticationHeadersService.
     */
    constructor(
        requestOptions: RequestOptions,
        @Inject('$http') private $http: IHttpService
    ) {
        super(requestOptions);
    }

    /**
     * Sets the default security headers for angular 1 and 2.
     *
     * @param token The security header value.
     */
    public setHeaders(token: string): void {
        super.setHeaders(token);
        this.$http.defaults.headers.common[authorizationHeaderName] = token;
    }
}
