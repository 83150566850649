/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * A critical notice.
 */
@table('nmcrtnot')
export class CriticalNotice {

    @field('cntype')
    public typeCode: string;

    @expand('cntype', 'descrpt')
    public type: string;

    @field('begdate')
    public beginningDate: Date;

    @field('expdate')
    public endingDate: Date;

    @field('entrdby')
    public enteredBy: string;

    @field('comment')
    public comment: string;
}
