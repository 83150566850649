/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceArray = angular.resource.IResourceArray;
import GeneralIncident from '../schema/interfaces/GeneralIncident';
import FireIncident from '../schema/FireIncident';
import EMSIncident from '../schema/EMSIncident';
import ResourceUtils from '../resource/ResourceUtils';
import Incident from '../schema/Incident';
import ExtendedResourceClass from './incident/ExtendedResourceClass';
import MultiResourceArrayPager from '../resource/paging/MultiResourceArrayPager';
import { IRootScopeService } from 'angular';
import {
    CALL_HISTORY_PAGE_COUNT,
    DISABLE_INFINITY_SCROLL_EVENT,
    INFINITY_SCROLL_COMPLETE_EVENT
} from '../cad/calls/details/CallDetailsConstants';

/**
 * The API for simultaneously retrieving law, fire, and EMS involvements from the server.
 */
export default class LawFireEmsAPI {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$rootScope', 'incidentAPI', 'fireIncidentAPI', 'emsIncidentAPI', 'resourceUtils'];

    /**
     * @private pager The object that is responsible for paging the resource array.
     */

    private pager: MultiResourceArrayPager<GeneralIncident<any>>;

    /**
     * Constructs a new instance of the LawFireEmsAPI class.
     *
     * @param $rootScope
     * @param incidentAPI The service that retrieves law incidents from the server.
     * @param fireIncidentAPI The service that retrieves fire incidents from the server.
     * @param emsIncidentAPI The service that retrieves EMS incidents from the server.
     * @param resourceUtils Provides utility methods for working with the Angular $resource service.
     */
    constructor(protected $rootScope: IRootScopeService,
        private incidentAPI: ExtendedResourceClass<Incident>,
        private fireIncidentAPI: ExtendedResourceClass<FireIncident>,
        private emsIncidentAPI: ExtendedResourceClass<EMSIncident>,
        private resourceUtils: ResourceUtils) {
    }

    /**
     * Simultaneously queries for law, fire, and EMS incident records.
     *
     * @param params The search parameters. Only fields that are common to all three types of incidents should be used.
     *               For example, don't try to use the `offetkn` field because it only exists on law incidents.
     * @param isCallScreen - is a condition that told us to use another type of request to prevent sending 'syinvolv'
     *                       query on the call detail screen
     * @param isRegistration this value is used to know that it is the first request
     * @returns {IResourceArray<GeneralIncident>} The combined array of law, fire, and EMS Incident records.
     */
    public query(params: { filter: string }, isCallScreen: boolean, isRegistration?: boolean): IResourceArray<GeneralIncident<any>> {
        if (isCallScreen) {
            if (isRegistration) {
                this.pager = this.resourceUtils.combineResourceArrays<GeneralIncident<any>>([
                    this.incidentAPI.callQuery(params),
                    this.fireIncidentAPI.callQuery(params),
                    this.emsIncidentAPI.callQuery(params)
                ], undefined, CALL_HISTORY_PAGE_COUNT);
            } else {
                if (this.pager.hasNextPage()) {
                    this.pager.appendNextPage().then(() => {
                        this.$rootScope.$broadcast(INFINITY_SCROLL_COMPLETE_EVENT);
                    }).catch(error => {
                        // Handle losing of the context from the server-side and trying to refresh incident history
                        if (error.status === 400) {
                            this.query(params, true, true).$promise.then(() => {
                                // Complete the infinite scroll after incident history data was refreshed
                                this.$rootScope.$broadcast(INFINITY_SCROLL_COMPLETE_EVENT);
                            });
                        } else {
                            // If the error status code has another value disable infinite scroll
                            this.disableInfinityScroll();
                        }
                    });
                } else {
                    this.disableInfinityScroll();
                }
            }
            return this.pager.resourceArray;
        }

        return this.resourceUtils.combineResourceArrays<GeneralIncident<any>>([
            this.incidentAPI.query(params),
            this.fireIncidentAPI.query(params),
            this.emsIncidentAPI.query(params)
        ]).resourceArray;
    }

    /**
     * disableInfinityScroll The method used to disable infinite scroll from the outside
     */

    public disableInfinityScroll(): void {
        this.$rootScope.$broadcast(INFINITY_SCROLL_COMPLETE_EVENT);
        this.$rootScope.$broadcast(DISABLE_INFINITY_SCROLL_EVENT);
    }
}
