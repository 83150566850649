/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Platform } from './platform';

/**
 * Detects the current platform.
 */
@Injectable()
export class PlatformDetector {

    /**
     * Gets the current platform.
     *
     * @returns The current platform.
     */
    public getPlatform(): Platform {
        // If `cordova` doesn't exist, then it's automatically a browser platform.
        if (!window.cordova) {
            return 'browser';
        }

        if (cordova.platformId === 'ios') {
            return 'ios';
        }

        if (cordova.platformId === 'android') {
            return 'android';
        }

        throw new Error('Unsupported platform');
    }

    public isBrowser() {
        return this.getPlatform() === 'browser';
    };
}
