/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IScope = angular.IScope;
import IResource = angular.resource.IResource;
import IResourceArray = angular.resource.IResourceArray;

/**
 * A base class that performs a query in response to a property change on some object.
 * When the response is returned by the server, the object is automatically updated.
 */
export abstract class PropertyChangedQuery<T extends IResource<any> | IResourceArray<any>> {

    private _$scope: IScope = undefined;

    /**
     * Registers this PropertyChangedQuery with the $scope so that the query will
     * be performed whenever the property changes.
     *
     * @param $scope The scope that contains the object to watch.
     * @returns {Function} A deregistration function that will stop the $watch.
     */
    public register($scope: IScope): Function {
        if (!$scope) {
            throw Error('The $scope cannot be undefined');
        }

        // Store off the $scope for later.
        this._$scope = $scope;

        let watchPropertyNames = this.getPropertyNamesArray();
        let watchExpression = watchPropertyNames.map(n => this.objectName + '.' + n);
        return $scope.$watchGroup(watchExpression, () => this.query(true));
    }

    /**
     * Performs a query using the current value of the 'watch' property and then
     * updates the 'update' property.
     */
    public query(isRegistration?: boolean): void {
        let object = this._$scope[this.objectName];
        if (object) {
            let newValues = this.getPropertyNamesArray().map(n => object[n]);
            if (newValues.every(n => n)) {
                // If the object already has the update property, then update it after the data has been retrieved.
                if (object[this.updatePropertyName]) {
                    const promise = <angular.IPromise<any>> this.performQuery(newValues, isRegistration).$promise;
                    promise.then(result => object[this.updatePropertyName] = result);
                } else {
                    // Otherwise assign the empty resource immediately to the update property.
                    object[this.updatePropertyName] = this.performQuery(newValues, isRegistration);
                }
            } else {
                object[this.updatePropertyName] = this.defaultValue;
            }
        }
    }

    /**
     * Gets the name of the object whose property will be watched.
     *
     * @returns {string} The name of the object whose property will be watched.
     */
    protected get objectName(): string {
        throw Error('Abstract property');
    }

    /**
     * Gets the name of the property to watch for changes.
     *
     * @returns {string} The name of the property to watch.
     */
    protected get watchPropertyNames(): string | string[] {
        throw Error('Abstract property');
    }

    /**
     * Gets the name of the property to update with the new data.
     *
     * @returns {string} The name of the property to update with the new data.
     */
    protected get updatePropertyName(): string {
        throw Error('Abstract property');
    }

    /**
     * Gets the default value that will be returned if the property that is being watched
     * does not have a value (i.e. it's falsy).
     *
     * @returns {T} The default value.
     */
    protected get defaultValue(): T {
        return undefined;
    }

    /**
     * Actually performs the query.
     *
     * @param newValues The new values of the properties being watched. They are guaranteed to be truthy.
     * @param isRegistration this value is used to know that it is the first request
     * @returns {T} The result of the query.
     */
    protected abstract performQuery(newValues: any[], isRegistration?: boolean): T;

    /**
     * Places passed in parameters into an array.
     *
     * @returns {string[]} An array containing the property names which will be watched.
     */
    private getPropertyNamesArray(): string[] {
        let tempProperty = this.watchPropertyNames;
        let watchPropertyNames: string[] = [];

        if (typeof tempProperty === 'string') {
            watchPropertyNames.push(tempProperty);
        } else {
            watchPropertyNames = tempProperty;
        }

        return watchPropertyNames;
    }
}

export default PropertyChangedQuery;
