/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import angular, { IRootScopeService, IScope } from 'angular';
import CustomFilterByAgencies from '../../../../filtering/custom-filters/CustomFilterByAgencies';
import CustomFilterByTypes from '../../../../filtering/custom-filters/CustomFilterByTypes';
import CustomFilterByZones from '../../../../filtering/custom-filters/CustomFilterByZones';
import CustomFilterAllFilters from '../../../../filtering/custom-filters/CustomFilterAllFilters';
import { UNIT_ACTIONS } from '../../../../filtering/consts';
import FilterHeaderComponentController from '../../../../filtering/components/baseClasses/filter-header/filter-header-component-controller';
import { unitsAnalyticsEvents } from '../../../../filtering/analytics/events';
import FiltersStorage from '../../../../filtering/storage/FiltersStorage';
import AdvancedUnitsFilterController from '../../../screens/advanced-units-filter-screen/advanced-units-filter-controller';

import IonicHistoryService = ionic.navigation.IonicHistoryService;
import IonicPopupService = ionic.popup.IonicPopupService;
import UnitFilterSetting from '../../../../../../app/settings/implementations/UnitFilterSetting';

/**
 * A component of filter headers to show which filters are being applied
 */
export const unitFilterHeaderComponent: angular.IComponentOptions = {
    template: require('../../../../filtering/components/baseClasses/filter-header//filter-header-component.component.html'),
    controller: class UnitFilterHeaderComponentController extends FilterHeaderComponentController {
        public routeName = AdvancedUnitsFilterController.routeName;

        public static $inject = [
            '$rootScope',
            '$scope',
            'unitCustomFilterByAgencies',
            'unitCustomFilterByTypes',
            'unitCustomFilterByZones',
            'unitFiltersStorage',
            'unitCustomFilterAllFilters',
            '$state',
            '$ionicHistory',
            '$ionicPopup',
            'unitFilterSetting'
        ];

        constructor(
            $rootScope: IRootScopeService,
            $scope: IScope,
            customFilterByAgencies: CustomFilterByAgencies,
            customFilterByTypes: CustomFilterByTypes,
            customFilterByZones: CustomFilterByZones,
            filtersStorage: FiltersStorage,
            customFilterAllFilters: CustomFilterAllFilters,
            $state: angular.ui.IStateService,
            $ionicHistory: IonicHistoryService,
            $ionicPopup: IonicPopupService,
            unitFilterSetting: UnitFilterSetting
        ) {
            super(
                $rootScope,
                $scope,
                customFilterByAgencies,
                undefined,
                customFilterByTypes,
                customFilterByZones,
                filtersStorage,
                customFilterAllFilters,
                $state,
                UNIT_ACTIONS,
                unitsAnalyticsEvents,
                $ionicHistory,
                $ionicPopup,
                unitFilterSetting
            );
        }
    }
};
