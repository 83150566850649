/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * A scar, mark, or tattoo
 */
@table('nmsmt')
export class ScarMarkTattoo {

    @expand('ncicsmt', 'desc')
    public ncicCode: string;

    @field('misc')
    public description: string;
}
