/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import UnitRepository from './repository/UnitRepository';
import UnitPropertiesTransform from './repository/transforms/UnitPropertiesTransform';
import AllUnitTransforms from './repository/transforms/AllUnitTransforms';
import UnitListController from './list/UnitListController';
import UnitDetailsController from './details/UnitDetailsController';
import AgencyUnitQuery from './details/queries/AgencyUnitQuery';
import RadioLogsUnitQuery from './details/queries/RadioLogsUnitQuery';
import ZoneUnitQuery from './details/queries/ZoneUnitQuery';
import GeoCoordinatesUnitQuery from './details/queries/GeoCoordinatesUnitQuery';
import { UnitQueryFactory } from './details/queries/unit-query-factory.service';
import UnitLocationAdapter from './UnitLocationAdapter';
import UnitFilterByAgency from './filtering/UnitFilterByAgency';
import UnitFilterByType from './filtering/UnitFilterByType';
import UnitFilterByZone from './filtering/UnitFilterByZone';
import UnitListFilter from './filtering/UnitListFilter';
import OfficersUnitQuery from './details/queries/OfficersUnitQuery';
import UnitOfficerDirective from './directives/UnitOfficerDirective';
import PermissionName from '../../permissions/enums/PermissionName';
import AdvancedUnitsFilterController from './screens/advanced-units-filter-screen/advanced-units-filter-controller';
import { upgradeAdapter } from '../../upgrade-adapter';
import { unitsAgencyComponent } from './components/filter/agency/unit-agency-component-filter';
import UnitFiltersStorage from './filtering/UnitFiltersStorage/UnitFiltersStorage';
import { unitTypeComponent } from './components/filter/type/unit-type-component.component';
import UnitCustomFilterByAgencies from '../filtering/units/UnitCustomFilterByAgencies';
import UnitCustomFilterAllFilters from '../filtering/units/UnitCustomFilterAllFilters';
import UnitCustomFilterByTypes from '../filtering/units/UnitCustomFilterByTypes';
import UnitCustomFilterByZones from '../filtering/units/UnitCustomFilterByZones';
import { unitZoneComponent } from './components/filter/zones/unit-zone-component.component';
import { unitFilterOptionsSubheader } from './components/filter/filter-options-subheader/UnitFilterOptionsSubheader.component';
import { unitAccordionComponent } from './components/filter/generic/accordion/accordion-component.component';
import { unitFilterHeaderComponent } from './components/filter/filter-header/filter-header-component.component';

export const unitModule = angular.module('unit', [])

    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.units', {
                url: '/units',
                abstract: true,
                views: {
                    units: {
                        template: '<ion-nav-view></ion-nav-view>'
                    }
                },
                data: {
                    permissions: [PermissionName.touchunit]
                }
            })
            .state('app.units.unitsList', {
                url: '',
                template: require('./list/list.html'),
                controller: 'unitListController as vm',
                data: {
                    homePageIndex: 1
                }
            })
            .state('app.units.unitsDetails', {
                url: '/:id',
                template: require('./details/details.html'),
                controller: 'unitDetailsController'
            })
            .state('app.units.filterScreen', {
                url: '/units/filter',
                template: require('./screens/advanced-units-filter-screen/advanced-units-filter-screen.html'),
                controller: 'advancedUnitsFilterController as vm'
            });
    }])

    // Directives
    .directive('sdsUnitOfficer', UnitOfficerDirective)

    // Components
    .component('sdsUnitAgencies', unitsAgencyComponent)
    .component('sdsUnitTypes', unitTypeComponent)
    .component('sdsUnitZones', unitZoneComponent)
    .component('sdsUnitFilterOptionsSubheader', unitFilterOptionsSubheader)
    .component('sdsUnitAccordion', unitAccordionComponent)
    .component('sdsUnitFilterHeader', unitFilterHeaderComponent)

    // Services
    .service('unitRepository', UnitRepository)
    .service('agencyUnitQuery', AgencyUnitQuery)
    .service('radioLogsUnitQuery', RadioLogsUnitQuery)
    .service('zoneUnitQuery', ZoneUnitQuery)
    .service('geoCoordinatesUnitQuery', GeoCoordinatesUnitQuery)
    .service('officersUnitQuery', OfficersUnitQuery)
    .service('unitQueryFactory', UnitQueryFactory)
    .service('unitPropertiesTransform', UnitPropertiesTransform)
    .factory('allUnitTransforms', AllUnitTransforms)
    .service('unitLocationAdapter', UnitLocationAdapter)
    .service('unitFilterByAgency', UnitFilterByAgency)
    .service('unitFilterByType', UnitFilterByType)
    .service('unitFilterByZone', UnitFilterByZone)
    .service('unitFiltersStorage', UnitFiltersStorage)
    .service('unitCustomFilterByAgencies', UnitCustomFilterByAgencies)
    .service('unitCustomFilterAllFilters', UnitCustomFilterAllFilters)
    .service('unitCustomFilterByZones', UnitCustomFilterByZones)
    .service('unitCustomFilterByTypes', UnitCustomFilterByTypes)

    .serviceAsFilter('unitListFilter', UnitListFilter, 'filter')

    // Controllers
    .controller('unitListController', UnitListController)
    .controller('unitDetailsController', UnitDetailsController)
    .controller('advancedUnitsFilterController', AdvancedUnitsFilterController);

upgradeAdapter.upgradeNg1Provider('unitRepository');
