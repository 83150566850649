/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import AssociativeArray from '../shared/interfaces/AssociativeArray';
import CallCategory from './CallCategory';

/**
 * A class that assigns a color to a CAD call based on its status.
 * The color assignment is actually performed by assigning a CSS class to the call.
 */
export default class ColorAssigner {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['callCategories'];

    /**
     * Constructs a new instance of the ColorAssigner class.
     *
     * @param callCategories The map of all call categories.
     */
    constructor(private callCategories: AssociativeArray<CallCategory>) {
    }

    /**
     * Gets the CSS class that corresponds to the call status.
     *
     * @param status The status of the CAD call.
     * @returns {string} The CSS class that corresponds to the call status
     *                   or the default CSS class if there is no CSS class explicitly associted with the status.
     */
    public getClass = (status: string): string => {
        let defaultClass = '';

        for (let categoryName in this.callCategories) {
            let category = this.callCategories[categoryName];

            if (status in category.values) {
                return category.cssClass;
            }

            if (category.isDefault) {
                defaultClass = category.cssClass;
            }
        }
        // The status was not contained in any of the categories, so just return the default.
        return defaultClass;
    };
}
