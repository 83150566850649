/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import last = require('lodash/last');

/**
 * A last-in, first-out (LIFO) data structure.
 */
export class Stack<T> {

    /**
     * The internal array that is used to store the items.
     */
    private innerArray: T[] = [];

    /**
     * Constructs a new instance of the Stack class.
     *
     * @param initialItems An initial array of items to push onto the stack.
     */
    constructor(initialItems?: T[]) {
        if (initialItems) {
            initialItems.forEach(i => this.push(i));
        }
    }

    /**
     * The iterator for this class.
     */
    public *[Symbol.iterator](): IterableIterator<T> {
        for (let i = this.innerArray.length - 1; i >= 0; i--) {
            yield this.innerArray[i];
        }
    }

    /**
     * Pushes an item on the top of the stack.
     *
     * @param item The item to push onto the top of the stack.
     */
    public push(item: T): void {
        this.innerArray.push(item);
    }

    /**
     * Removes and returns the item that is on top of the stack.
     *
     * @returns The item that has been removed from the top of the stack.
     */
    public pop(): T {
        return this.innerArray.pop();
    }

    /**
     * Returns, but does not remove, the item that is on top of the stack.
     *
     * @returns The item that is on top of the stack.
     */
    public peek(): T {
        return last(this.innerArray);
    }

    /**
     * Gets the number of items that have been pushed onto the stack.
     *
     * @returns The number of items on the stack.
     */
    public get size(): number {
        return this.innerArray.length;
    }
}
