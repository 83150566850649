/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import LogicalOrFilter from '../../filtering/baseClasses/LogicalOrFilter';
import AggregatorUnit from '../../../schema/AggregatorUnit';
import FilterByUnit from '../../filtering/filters/FilterByUnit';
import FilterByType from '../../filtering/filters/FilterByType';

/**
 * Filters the units so that only those whose type is the same as the logged-in user's, will be shown.
 * As with all unit filters, the current user's unit will be shown no matter what - it cannot be filtered out.
 */
export default class UnitFilterByType extends LogicalOrFilter<AggregatorUnit> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['filterByUnit', 'filterByType'];

    /**
     * Constructs a new instance of the UnitFilterByType class.
     *
     * @param filterByUnit The filter that includes the current user's own unit.
     * @param filterByType The filter that includes any units that have the same type as the current user.
     */
    constructor(filterByUnit: FilterByUnit, filterByType: FilterByType) {
        super(filterByUnit, filterByType);
    }
}
