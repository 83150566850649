/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../../shared/database/decorators';

/**
 * A person's supervisor.
 */
@table('nmextra')
export class Supervisor {

    @field('supname')
    public name: string;

    @field('supwpho')
    public workPhone: string;

    @field('suphpho')
    public homePhone: string;
}
