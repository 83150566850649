/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';
import { VehicleReference } from '../../shared/model/vehicle-reference';

/**
 * Accident vehicle.
 */
@table('acvhdtl')
export class AccidentVehicle {

    @expand('vehicle', VehicleReference)
    public vehicle: VehicleReference | string;
}
