/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * A local identification.
 */
@table('nmlocal')
export class NameLocalId {

    @expand('type', 'desc')
    public type: string;

    @field('localid')
    public id: string;
}
