/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAttributes = angular.IAttributes;

const MAX_LENGTH = 'maxlength';
/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
AutoTabDirective.$inject = ['ionic'];

/**
 * A directive that will automatically move focus to the next form control (input, select, or textarea)
 * when the value of the current control has reached the max length.
 *
 * @param ionic The ionic object that contains information about the current platform.
 * @returns {IDirective} A directive that will automatically move focus to the next form control.
 */
export default function AutoTabDirective(ionic: IonicStatic): IDirective {
    return {
        restrict: 'A',
        link: function (_scope: IScope, $element: IAugmentedJQuery, $attributes: IAttributes) {
            // Automatically focusing the next input will not work on iOS. So simply return.
            // See http://stackoverflow.com/questions/18728166/programatically-focus-on-next-input-field-in-mobile-safari
            if (ionic.Platform.device().platform === 'iOS') {
                return;
            }

            // This directive only applies if the element has a max length defined.
            if (!$attributes[MAX_LENGTH]) {
                return;
            }

            let maxlength = +$attributes[MAX_LENGTH];

            // The max length must be a number.
            if (isNaN(maxlength)) {
                return;
            }

            $element.on('input', function () {
                const value = angular.element(this).val();
                if (typeof value === 'string' && value.length >= maxlength) {
                    let inputs = angular.element(this).closest('form').find(':input'); // Note that ':input' doesn't just match <input> elements.
                    let nextIndex = inputs.index(this) + 1;
                    let nextInput = inputs.eq(nextIndex);
                    nextInput.trigger('focus');
                }
            });
        }
    };
}
