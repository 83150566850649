/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * A component that displays a scrollable list.
 */
export const scrollableListComponent: angular.IComponentOptions = {
    template: require('./scrollable-list.component.html'),
    bindings: {
        items: '<',
        itemSelected: '&'
    }
};
