/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * A higher-level wrapper around the native localStorage API. The primary advantage is that
 * this class allows you to store and retrieve an object as opposed to strings only.
 */
abstract class BaseLocalStorage<TKey> {

    /**
     * Stores the value in localStorage using the provided key.
     *
     * @param key The key that identifies the value.
     * @param value The value to be stored.
     */
    public set(key: TKey, value: string): void {
        // localStorage has a quirk where it converts every value to a string: null becomes "null",
        // undefined becomes "undefined". It's preferable to just have an empty string in those cases.
        window.localStorage.setItem(this.createFinalKey(key), value || '');
    }

    /**
     * Gets the value associated with the given key from localStorage.
     *
     * @param key The key that identifies the value.
     * @param defaultValue The default value that will be returned if there is no value associated with the given key.
     * @returns {string} The value associated with the key or the default value if no value was found.
     */
    public get(key: TKey, defaultValue?: string): string {
        return window.localStorage.getItem(this.createFinalKey(key)) || defaultValue;
    }

    /**
     * Stores the value as a JSON object in localStorage using the provided key.
     *
     * @param key The key that identifies the value.
     * @param value Any object to be stored.
     * @param replacer A function that transforms the results.
     */
    public setObject(key: TKey, value: any, replacer?: (key: any, value: any) => any): void {
        window.localStorage.setItem(this.createFinalKey(key), JSON.stringify(value, replacer));
    }

    /**
     * Gets the object (as opposed to a string) associated with the given key from localStorage.
     *
     * @param key The key that identifies the object.
     * @param reviver A function that transforms the results. This function is called for each member of the object.
     * @returns {any} The object associated with the key or undefined if there was no value associated with the key.
     */
    public getObject(key: TKey, reviver?: (key: any, value: any) => any): any {
        let item = window.localStorage.getItem(this.createFinalKey(key));
        return item ? JSON.parse(item, reviver) : undefined;
    }

    /**
     * Remove the value associated with the given key from localStorage.
     *
     * @param key The key whose value will be removed.
     */
    public remove(key: TKey): void {
        window.localStorage.removeItem(this.createFinalKey(key));
    }

    /**
     * Creates the final key, which must be of type `string`, given the original
     * key which is of type `TKey`.
     *
     * @param originalKey The original key.
     * @returns The final key used to store the data in local storage.
     */
    protected abstract createFinalKey(originalKey: TKey): string;
}

export default BaseLocalStorage;
