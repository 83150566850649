/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Http, RequestOptionsArgs, Response, URLSearchParams } from '@angular/http';
import { UrlFactory } from '../../authentication';
import { Observable } from 'rxjs/Observable';

/**
 * A service for retrieving values from the /cache, which is a
 * database table that stores several tables in memory making it
 * a faster request.
 */
@Injectable()
export class CacheService {
    private readonly _BASE_PATH = '/../cache/';
    private readonly _PARAM_TEXT_LABEL = 'text';
    private readonly _PARAM_FORMAT_LABEL = 'format';

    /**
     * Constructs a new instance of the CacheService class.
     *
     * @param http The Angular service that makes http requests.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     */
    constructor(
        private http: Http,
        private urlFactory: UrlFactory
    ) {
    }

    /**
     * Gets the Observable that will translate into the Response of the cached table.
     *
     * @param table The name of the database table.
     * @param options options to be passed to the request
     * @returns An Observable that contains the codes/descriptions.
     */
    public get = (table: string, options?: RequestOptionsArgs): Observable<Response> => {
        // Create the Url
        const url = this.urlFactory.create({
            path: `${this._BASE_PATH}${table}`
        });

        // Make the request
        return this.http.get(url, options);
    };

    /**
     * Gets the options that will be used in the request method.
     *
     * @param text The text to match.
     * @param format A sprintf-style format string that determines how the code and description are combined.
     * @returns An RequestOptionsArgs that contains the text and format passed.
     */
    public createRequestOptions = (text: string, format?: string): RequestOptionsArgs => {
        // Create the options request
        const searchOptions = { search: new URLSearchParams() };
        searchOptions.search.set(this._PARAM_TEXT_LABEL, text);

        if (format) {
            searchOptions.search.set(this._PARAM_FORMAT_LABEL, format);
        }

        return searchOptions;
    };
}
