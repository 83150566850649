/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Inject, Injectable } from '@angular/core';
import { Http, RequestOptions, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { UrlFactory } from '../../authentication';
import { MyUnitProvider } from '../my-unit-provider.service';
import TenCodeCache from '../../shared/caching/TenCodeCache';

/**
 * The error messages that may be thrown by the StatusService.
 */
export const errorMessages = Object.freeze({
    invalidStatus: 'The status cannot be null, undefined, or empty',
    invalidUnit: 'The unit cannot be null, undefined, or empty'
});

/**
 * The service that provides a list of statuses and the ability to change a unit's status.
 */
@Injectable()
export class StatusService {

    /**
     * The backing field for the status getter.
     */
    private _statuses: string[];

    /**
     * A regular expression that determines if a string consists entirely of numbers.
     */
    private isNumeric = /^\d+$/;

    constructor(
        @Inject('tenCodeCache') private tenCodeCache: TenCodeCache,
        private http: Http,
        private urlFactory: UrlFactory,
        private myUnitProvider: MyUnitProvider
    ) {
    }

    /**
     * Gets the sorted array of statuses.
     *
     * @returns The sorted array of statuses.
     */
    public get statuses(): string[] {
        if (!this._statuses) {
            this._statuses = this.tenCodeCache.array
                .filter(t => t.mobile)
                .map(t => t.abbr)
                .sort(this.sortStatuses);
        }
        return this._statuses;
    }

    /**
     * Updates the unit's status for the currently logged-in user with an optional comment.
     *
     * @param status - The new status for the logged-in user's unit.
     * @param comment - (optional) A comment about the status change.
     * @returns An observable that can be used to monitor whether the status change succeeded or failed.
     */
    public updateCurrentUserStatus(status: string, comment?: string): Observable<Response> {
        return this.updateStatus(status, this.myUnitProvider.number, comment);
    }

    /**
     *  Updates a unit's status with an optional comment.
     *
     * @param status - The new status for the unit.
     * @param unit - The unit whose status will be updated.
     * @param comment - (optional) A comment about the status change.
     * @returns An observable that can be used to monitor whether the status change succeeded or failed.
     */
    public updateStatus(status: string, unit: string, comment?: string): Observable<Response> {
        if (!status) {
            throw new Error(errorMessages.invalidStatus);
        }
        if (!unit) {
            throw new Error(errorMessages.invalidUnit);
        }

        const url = this.urlFactory.create({
            path: `/CAD/units/${unit}/status/${status}`
        });

        const radioComment = new RequestOptions();
        const finalComment = comment || this.getDescription(status);

        if (finalComment) {
            radioComment.search = new URLSearchParams('comment=(App) ' + finalComment);
        }

        return this.http.put(url, '', radioComment);
    }

    /**
     * Gets the description associated with the status.
     *
     * @param status - The new status for the user's unit.
     * @returns The description associated with the status or undefined if the status is not recognized.
     */
    private getDescription(status: string): string {
        const tenCode = this.tenCodeCache.map[status];
        return tenCode ? tenCode.desc : undefined;
    }

    /**
     * Sorts the statuses alphabetically if they have at least one letter.
     * Those that contain only numbers will be moved to the end of the
     * list and be sorted numerically.
     *
     * Example:
     *   const statuses = ['A', '2', '19', 'C', '22', '6', 'B'];
     *   const sorted = statuses.sort(sortStatuses);
     *   console.log(sorted); // ['A', 'B', 'C', '2', '6', '19', '22']
     *
     * @param status1 - the first status to sort.
     * @param status2 - the second status to sort.
     */
    private sortStatuses = (status1: string, status2: string): number => {
        const status1IsNumeric = this.isNumeric.test(status1);
        const status2IsNumeric = this.isNumeric.test(status2);

        if (status1IsNumeric && !status2IsNumeric) {
            return 1;
        }
        if (!status1IsNumeric && status2IsNumeric) {
            return -1;
        }
        if (status1IsNumeric && status2IsNumeric) {
            return Number(status1) - Number(status2);
        }
        if (status1 < status2) {
            return -1;
        }
        if (status1 > status2) {
            return 1;
        }
        return 0;
    };
}
