/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IInjectorService = angular.auto.IInjectorService;
import AddPropertiesTransform from '../../../../shared/transforms/AddPropertiesTransform';
import AggregatorCadCall from '../../../../schema/AggregatorCadCall';
import UnitRepository from '../../../units/repository/UnitRepository';
import AggregatorUnit from '../../../../schema/AggregatorUnit';

/**
 * A transform that adds three properties, related to Units, to a given CAD Call.
 * The three properties allow access to
 * 1. The call's responsible unit
 * 2. An array of additional units (besides the responsible unit) assigned to the call.
 * 3. An array that contains all of the above units.
 */
export default class CallUnitPropertiesTransform extends AddPropertiesTransform<AggregatorCadCall> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$injector'];

    private _unitRepository: UnitRepository;

    /**
     * Constructs a new instance of the CallUnitPropertiesTransform class.
     *
     * @param $injector The Angular injector service.
     */
    constructor(private $injector: IInjectorService) {
        super();
    }

    // @Override
    protected getPropertyDescriptorMap(call: AggregatorCadCall): PropertyDescriptorMap {
        return {
            responsibleUnit: {
                get: () => this.unitRepository.modelMap[call.unit],
                enumerable: true
            },
            additionalUnits: {
                get: () => this.getAdditionalUnits(call),
                enumerable: true
            },
            allUnits: {
                get: () => this.getAllUnits(call),
                enumerable: true
            },
            activeUnits: {
                get: () => this.activeUnits(call),
                enumerable: true
            },
            isUsersActiveCall: {
                get: () => this.isUsersActiveCall(call),
                enumerable: true
            }
        };
    }

    /**
     * Gets the unit repository.
     *
     * Note: The call repository depends on this class which depends on the unit repository,
     * and the unit repository depends on the call repository. So we have a circular dependency.
     * Angular's dependency injection framework does not allow circular dependencies. So we have to
     * circumvent it by lazily injecting the unit repository.
     *
     * @returns {UnitRepository} The unit repository.
     */
    private get unitRepository(): UnitRepository {
        if (!this._unitRepository) {
            this._unitRepository = this.$injector.get<UnitRepository>('unitRepository');
        }
        return this._unitRepository;
    }

    /**
     * Gets all units other than the responsible unit assigned to the call.
     *
     * @param call The call for which to retrieve the additional units.
     * @returns {AggregatorUnit[]} The array of additional units assigned to the call.
     */
    private getAdditionalUnits(call: AggregatorCadCall): AggregatorUnit[] {
        if (!call.otherUnits) {
            return [];
        }
        return call.otherUnits.split(',')
            .map(u => this.unitRepository.modelMap[u.trim()])
            .filter(u => !!u);
    }

    /**
     * Gets all units assigned to the call. This includes both the responsible
     * unit as well as the additional units.
     *
     * @param call The call for which to retrieve all units.
     * @returns {AggregatorUnit[]} The array of all assigned units.
     */
    private getAllUnits(call: AggregatorCadCall): AggregatorUnit[] {
        let responsibleUnitArray: AggregatorUnit[] = [];

        if (call.responsibleUnit) {
            responsibleUnitArray.push(call.responsibleUnit);
        }
        return responsibleUnitArray.concat(call.additionalUnits);
    }

    /**
     * Calculates the number of units which are actively working on a given call.
     *
     * @param call The call for which to retrieve all units.
     * @returns {number} The number of active units on a call.
     */
    private activeUnits(call: AggregatorCadCall): AggregatorUnit[] {
        return call.allUnits.filter(unit => unit.callIdAndType === call.callIdAndType);
    }

    /**
     * Determines if the given call is the current user's active call.
     *
     * @param call The call to evaluate.
     * @returns True if the given call is the current user's active call, false otherwise.
     */
    private isUsersActiveCall(call: AggregatorCadCall) {
        const myUnit = call.tryGetMyUnit();
        return !!myUnit && myUnit.callIdAndType === call.callIdAndType;
    }
}
