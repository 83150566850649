/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * The model of a Radio Log.
 */
@table('rlmain')
export class RadioLog {

    @field('logdate')
    public logdate: Date;

    @field('unit')
    public unit: string;

    @field('tencode')
    public tencode: string;

    @field('agency')
    public agency: string;

    @expand('dpatchr', 'fullnam')
    public dpatchr: string;

    @field('desc')
    public desc: string;

    @field('seq')
    public seq: string;
}
