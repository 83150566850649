/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { isAnalyticsAvailable } from './firebase-validators';
import { log } from './firebase-crashlytics-service';

const logEvent = (eventName: string, params: Record<string, string | number | object[]> = {}): Promise<void> => {
    try {
        if (isAnalyticsAvailable()) {
            return cordova.plugins.firebase.analytics.logEvent(eventName, params);
        }
    } catch (e) {
        log('ERROR WHILE LOGGING EVENT');
        log('event: ' + eventName);
    }
};

export { logEvent };
