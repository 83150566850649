/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import { JailBookingReference } from '../../model/jail-booking-reference';

/**
 * A component which displays a field which links to a jail booking.
 */
@Component({
    selector: 'sds-jail-booking-link',
    template: `
        <sds-permissions-link state="app.search.jailBookingDetails" [id]="jailBooking.id" *ngIf="jailBooking && jailBooking.id">
            <span>{{jailBooking.id}}</span>
        </sds-permissions-link>
        <span *ngIf="jailBooking && !jailBooking.id">{{jailBooking}}</span>
        <span *ngIf="!jailBooking">&nbsp;</span>
    `,
    styles: [
        require('./link.component.scss')
    ]
})
export class JailBookingLinkComponent {

    /**
     * The jail booking to display.
     */
    @Input() public jailBooking: JailBookingReference;
}
