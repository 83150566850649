/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ZoneModel } from './zone-model';
import { VersionService } from '../../../conditional-injection';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { CacheExpireService } from '../../../../app/shared/services/cacheExpireService';

/**
 * A service for retrieving values from the "tbzones", which is a
 * database table that stores zone data.
 */
@Injectable()
export class ZoneService {
    public readonly ZONES_API_MIN_VERSION = '2023.4';
    private readonly ZONE_TABLE = 'tbzones';

    public isLoading: Observable<boolean>;
    private isLoadingBehavior: BehaviorSubject<boolean>;

    /**
     * Constructs a new instance of the ZoneService class.
     *
     * @param cacheService The service that request cache requests.
     */
    constructor(
        private cacheExpireService: CacheExpireService,
        private versionService: VersionService
    ) {
        this.isLoadingBehavior = new BehaviorSubject(false);
        this.isLoading = this.isLoadingBehavior.asObservable();
    }

    /**
     * Gets the zones that match the text from the tbzones database table.
     *
     * @param text The text to match.
     * @param format A sprintf-style format string that determines how the code and description are combined.
     * @returns An Observable that contains the zone models.
     */
    public async getZones(userAgency?: string, text?: string, format?: string): Promise<ZoneModel[]> {
        if (!this.isZonesApiAvailable()) {
            return [];
        }

        try {
            this.isLoadingBehavior.next(true);

            const zonesJson = await this.cacheExpireService.getCachedData(this.ZONE_TABLE, text, format);
            const zones = this.toZoneModel(zonesJson, userAgency);
            return zones;
        } finally {
            this.isLoadingBehavior.next(false);
        }
    }

    public isZonesApiAvailable() {
        const currentVersion = this.versionService.serverVersion.raw;
        return currentVersion >= `${this.ZONES_API_MIN_VERSION}.0`;
    }

    private toZoneModel(data: any[], userAgency?: string): ZoneModel[] {
        let zones: ZoneModel[] = [];

        for (const zone of data) {
            const model = ZoneModel.createModelFromObject(zone);
            if (model) {
                zones.push(ZoneModel.createModelFromObject(zone));
            }
        }

        zones = ZoneModel.orderListByAbbr(zones, userAgency);

        return zones;
    }
}
