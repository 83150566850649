/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import SessionDataCadFilter from './SessionDataCadFilter';
import CADModel from '../../../schema/interfaces/CADModel';
import EqualityComparer from '../../../shared/comparison/EqualityComparer';
import Session from '../../../login/Session';

/**
 * A class that extends the SessionDataCadFilter and checks if the model value *equals* the session value.
 */
abstract class EqualsSessionDataCadFilter<TModel extends CADModel, TValue> extends SessionDataCadFilter<TModel, TValue, TValue> {

    /**
     * Constructs a new instance of the EqualsSessionDataCadFilter class.
     *
     * @param session The object that stores information about the current user's session.
     * @param equalityComparer The comparer that determines if two values are equal.
     */
    constructor(session: Session, private equalityComparer: EqualityComparer<TValue>) {
        super(session);
    }

    // @Override
    protected compare(modelValue: TValue, sessionValue: TValue): boolean {
        return this.equalityComparer.equals(modelValue, sessionValue);
    }
}

export default EqualsSessionDataCadFilter;
