/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * The model of a booking.
 */
@table('jlbook')
export class Booking {

    @field('num')
    public id: string;

    @expand('dispo', 'descrpt')
    public disposition: string;

    @expand('type', 'descrpt')
    public bookingType: string;

    @field('intdate')
    public initialBookingDate: Date;
}
