/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import CadListFilter from '../../filtering/CadListFilter';
import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import CallFilter from './CallFilter';
import SettingsRepository from '../../../settings/SettingsRepository';
import IInjectorService = angular.auto.IInjectorService;
import SettingKey from '../../../settings/SettingKey';
import Setting from '../../../settings/Setting';
import CustomFilterAllFilters from '../../filtering/custom-filters/CustomFilterAllFilters';
import FiltersStorage from '../../filtering/storage/FiltersStorage';
import { IRootScopeService } from 'angular';
import { CALL_ACTIONS } from '../../../cad/filtering/consts';

/**
 * An actual Angular filter that can be used in the view to select a subset of calls from an array.
 * This filter reads the `callFilterSetting` and uses that value to determine how the calls should be filtered.
 *
 * Example:
 * <div ng-repeat="call in calls | callListFilter"></div>
 */
export default class CallListFilter extends CadListFilter<AggregatorCadCall, CallFilter> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['settingsRepository', '$injector'];

    /**
     * Constructs a new instance of the CallListFilter class.
     *
     * @param settingsRepository The repository that stores all settings.
     * @param $injector The Angular injector service.
     */
    constructor(settingsRepository: SettingsRepository, $injector: IInjectorService) {
        super(settingsRepository, $injector);

        const setting = <Setting<CallFilter>> settingsRepository.get(this.settingKey);

        if (setting.value === CallFilter.customFilter) {
            const customFilter = $injector.get<CustomFilterAllFilters>('callCustomFilterAllFilters');
            const filtersStorage = $injector.get<FiltersStorage>('callFiltersStorage');

            filtersStorage.setCurrentFilter(setting.selectedOption.text);
            customFilter.restoreAllFilters();
            //     // If last selected option is custom filter then trigger ADVANCED_FILTER_CHANGED to update filter header

            const $rootScope = $injector.get<IRootScopeService>('$rootScope');
            $rootScope.$broadcast(`${CALL_ACTIONS.ADVANCED_FILTER_CHANGED}`);
        }
    }

    // @Override
    protected get settingKey(): SettingKey {
        return SettingKey.callFilterSetting;
    }

    // @Override
    protected getFilterName(value: CallFilter): string {
        return CallFilter[value];
    }
}
