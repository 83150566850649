/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IPromise = angular.IPromise;
import DirectoryFinder from './DirectoryFinder';

/**
 * Finds the absolute path to the Documents directory on an iOS device.
 *
 * Note that due to limitations on iOS, the Documents directory is specific to (and can only be accessed by) the app.
 * See https://developer.apple.com/library/ios/documentation/FileManagement/Conceptual/FileSystemProgrammingGuide/FileSystemOverview/FileSystemOverview.html
 */
export default class IOSDocumentsDirectoryFinder implements DirectoryFinder {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$q'];

    /**
     * Constructs a new instance of the IOSDocumentsDirectoryProvider class.
     *
     * @param $q The angular service that handles creating and working with promises.
     */
    constructor(private $q: IQService) {
    }

    // @Override
    public findDirectory(): IPromise<string> {
        return this.$q.when(cordova.file.documentsDirectory);
    }
}
