/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Type } from '@angular/core';
import { ConditionalInjectionFilter } from './conditional-injection-filter';
import { InjectionCondition } from '../injection-condition';
import { Environment } from '../environment';

/**
 * A filter that returns those services that can be used in the current environment.
 */
@Injectable()
export class EnvironmentConditionalInjectionFilter extends ConditionalInjectionFilter<'environment', Environment[]> {

    /**
     * @inheritdoc
     */
    protected readonly propertyName = 'environment';

    /**
     * @inheritdoc
     */
    protected readonly defaultValue: Environment[] = ['development', 'production'];

    /**
     * @inheritdoc
     */
    protected doParsing(rawValue: InjectionCondition['environment']): Environment[] {
        return [rawValue];
    }

    /**
     * @inheritdoc
     */
    protected doFiltering(tuples: [Environment[], Type<any>][]): [Environment[], Type<any>][] {
        const environment = process.env.NODE_ENV;

        if (!environment) {
            throw new Error('NODE_ENV not defined. Ensure it is defined in the Webpack config.');
        }

        return tuples.filter(t => t[0].some(e => e === environment));
    }
}
