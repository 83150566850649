/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import ITimeoutService = angular.ITimeoutService;
import IonicPopupService = ionic.popup.IonicPopupService;

/**
 * An alert that displays a message to the user and automatically closes
 * after a specified amount of time.
 */
export default class TimedAlert {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$timeout', '$ionicPopup', 'timedAlertDefaultDuration'];

    /**
     * Constructs a new instance of the TimedAlert class.
     *
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param timedAlertDefaultDuration The default amount of time to show the TimedAlert.
     */
    constructor(private $timeout: ITimeoutService,
        private $ionicPopup: IonicPopupService,
        private timedAlertDefaultDuration: number) {
    }

    /**
     * Shows the timed alert.
     *
     * @param message The message to display to the user.
     * @param callback (optional) The function to execute when the alert has been closed.
     * @param duration (optional) The amount of time for which the alert will be shown.
     *                            If not specified, then the `timedAlertDefaultDuration` will be used.
     */
    public show(message: string, callback?: (value: any) => void, duration?: number): void {
        let popup = this.$ionicPopup.alert({template: message});

        if (callback) {
            popup.then(callback);
        }

        duration = angular.isNumber(duration) ? duration : this.timedAlertDefaultDuration;
        this.$timeout(() => popup.close(), duration);
    }
}
