/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * A suspect scar, mark, or tattoo
 */
@table('nmspsmt')
export class SuspectScarMarkTattoo {

    @expand('ncicsmt', 'desc')
    public ncicCode: string;

    @field('misc')
    public description: string;
}
