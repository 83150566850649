/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * The premise description model
 */
@table('bidesc')
export class Description {

    @expand('subject', 'desc')
    public subject: string;

    @field('narratv')
    public narrative: string;
}
