/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { UrlConfig } from './url-config';
import trimStart = require('lodash/trimStart');
import trimEnd = require('lodash/trimEnd');

/**
 * A factory that facilitates creating absolute URLs to the Spillman server.
 */
@Injectable()
export class UrlFactory {

    /**
     * Constructs a new instance of the UrlFactory class.
     *
     * @param defaultConfig The default configuration for the UrlFactory.
     */
    constructor(private defaultConfig: UrlConfig) {
    }

    /**
     * Creates an absolute URL using the provided options.
     *
     * @param config The configuration that determines which URL should be created.
     * @returns The absolute URL.
     */
    public create(config: UrlConfig): string {
        if (!config) {
            throw Error('The config cannot be null or undefined');
        }

        // TODO: When this bug fix is released: https://github.com/lodash/lodash/issues/2983
        // then we can just use lodash instead and do: `defaults({}, config, this.defaultConfig);`
        const finalConfig = this.applyDefaults(config);

        // Example baseUrl: "https://lin1:4443"
        const baseUrl = `${finalConfig.protocol}${finalConfig.secureConnection ? 's' : ''}://${finalConfig.server}:${finalConfig.port}`;

        return this.joinUrlFragments(baseUrl, finalConfig.baseApiUrl, finalConfig.path);
    }

    /**
     * Applies the default values to any properties that aren't specified in the given config.
     *
     * @param config The local config that may not specify every property.
     * @returns A new config where any properties that weren't explicitly stated
     * in the original config have been set to the default value.
     */
    private applyDefaults(config: UrlConfig): UrlConfig {
        const finalConfig = Object.assign({}, config) as UrlConfig;

        for (let key in this.defaultConfig) {
            if (finalConfig[key] === undefined) {
                finalConfig[key] = this.defaultConfig[key];
            }
        }

        return finalConfig;
    }

    /**
     * Joins the given url fragments and ensures that there is exactly one slash between each one.
     *
     * @param fragments An array of URL fragments that need to be combined with a slash separator.
     * @returns A full URL with a single slash separating each fragment.
     */
    private joinUrlFragments(...fragments: string[]): string {
        return fragments.filter(f => !!f).map(this.trimSlashesBasedOnIndex).join('/');
    }

    /**
     * Trims the slashes from the given value based on its position in the array.
     * If the value is the first element, then only trailing slashes will be trimmed.
     * If the value is the last element, then only leading slashes will be trimmed.
     * If the value is neither first nor last, then both leading and trailing slashes will be trimmed.
     *
     * @param value The value that will potentially have slashes trimmed from its start or end.
     * @param index The value's index in the array.
     * @param array The array that contains the value.
     * @returns The value that has had slashes trimmed from it.
     */
    private trimSlashesBasedOnIndex(value: string, index: number, array: string[]): string {
        // If the value is not first, then trim leading slashes.
        if (index > 0) {
            value = trimStart(value, '/');
        }

        // If the value is not last, then trim trailing slashes.
        if (index < array.length - 1) {
            value = trimEnd(value, '/');
        }

        return value;
    }
}
