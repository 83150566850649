/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Observable } from 'rxjs/Observable';

/**
 * An abstract class which defines a method for displaying the system use notification message
 * as a popup when the user logs into the application.
 */
export abstract class SystemUse {

    /**
     * @returns An Observable which, when resolved signifies the completion of the display popup.
     */
    public abstract getNotificationPopup(): Observable<any>;
}
