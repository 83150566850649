/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that filters an array of strings and returns those that contain a word
 * (i.e. there must be a word boundary) that starts with the given text.
 */
@Pipe({ name: 'sdsContainsWord' })
export class ContainsWordPipe implements PipeTransform {

    /**
     * @inheritdoc
     */
    public transform(items: string[], text: string): string[] {
        if (!text) {
            return items;
        }

        const regex = new RegExp(`\\b${text}`, 'i');
        return items.filter(i => regex.test(i));
    }
}
