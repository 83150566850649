/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * Returns the first element from the data array.
 *
 * Note: The `get` action is for retrieving a single model from the server using a primary key.
 * Angular expects the returned data to NOT be an array.
 * However, the Spillman Tables API sends an array even when there could only possibly be one model.
 * Therefore, we need to return (what should be) the only element in the data array.
 *
 * @param data An array of data sent by the server.
 * @returns {any} The first element from the array.
 */
export default function GetFirstElementTransform(data: any[]) {
    return data[0];
}
