/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * TODO: The README file for this npm module needs to explicity state that this class must
 * be implemented.
 */

/**
 * An abstract class which provides the values needed to authorize the application with the server.
 */
export abstract class ApiAuthenticationConfig {

    /**
     * The id used identify the application.
     */
    public clientId: string;

    /**
     * The client secret which is used to authorize the application with the server.
     */
    public clientSecret: string;
}
