/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import CADModel from '../../../../app/schema/interfaces/CADModel';
import CustomDataCadFilter from '../baseClasses/CustomDataCadFilter';
import { AgencyModel } from '../../services/agency/agencyModel';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import FiltersStorage from '../../filtering/storage/FiltersStorage';

export default abstract class CustomFilterByAgencies extends CustomDataCadFilter<CADModel> {

    public static STORAGE_KEY = 'AGENCIES';
    public static $inject = ['callFiltersStorage'];

    public STORAGE_KEY = CustomFilterByAgencies.STORAGE_KEY;
    public selectedAgenciesObservable: Observable<AgencyModel[]>;

    private agenciesBehaviorSubject: BehaviorSubject<AgencyModel[]>;
    private selectedValues: AgencyModel[];

    constructor(
        filtersStorage: FiltersStorage
    ) {
        super(filtersStorage);
        this.selectedValues = [];
        this.agenciesBehaviorSubject = new BehaviorSubject(this.selectedValues);
        this.selectedAgenciesObservable = this.agenciesBehaviorSubject.asObservable();
        this.isFilterApplied = false;
    }

    public include(model: CADModel): boolean {
        const selectedValues = this.getSelectedValues().filter(value => value.applied === true);

        if (selectedValues === undefined || selectedValues.length === 0) {
            return false;
        }

        const result = selectedValues.find((data: FilterModel) => data.id === this.getModelValue(model));

        return result !== undefined;
    }

    public getFilterLabel(): string {
        return this.isFilterApplied ? `Agencies (${this.selectedValues.filter(value => value.applied).length})` : undefined;
    }

    public applyFilter() {
        this.isFilterApplied = false;
        this.selectedValues = this.selectedValues.map(item => {
            item.applied = item.checked;
            return item;
        });

        this.isFilterApplied = this.selectedValues.some(item => item.checked === true);
    }

    public setValues(selectedValues: AgencyModel[]) {
        this.agenciesBehaviorSubject.next(selectedValues);
        this.selectedValues = selectedValues;
    }

    public getSelectedValues(): AgencyModel[] {
        return this.selectedValues.filter(value => value.applied === true);
    }

    public getAllValues(): AgencyModel[] {
        return this.selectedValues;
    }

    protected getModelValue(model: CADModel): string {
        return model.agency;
    }
}
