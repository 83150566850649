/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import CADModel from '../../schema/interfaces/CADModel';
import SettingsRepository from '../../settings/SettingsRepository';
import SettingKey from '../../settings/SettingKey';
import IInjectorService = angular.auto.IInjectorService;
import CadFilter from './CadFilter';
import Setting from '../../settings/Setting';
import CustomFilterAllFilters from './custom-filters/CustomFilterAllFilters';
import FiltersStorage from '../calls/filtering/CallFiltersStorage';

/**
 * A base class for those Angular filters that can be used in the view to select a subset of CadModels from an array.
 * This filter reads the setting specified by derived classes, and uses that value to determine
 * how the models should be filtered.
 */
abstract class CadListFilter<TModel extends CADModel, TEnum> {

    /**
     * Constructs a new instance of the CadListFilter class.
     *
     * @param settingsRepository The repository that stores all settings.
     * @param $injector The Angular injector service.
     */
    constructor(private settingsRepository: SettingsRepository, private $injector: IInjectorService) {
    }

    /**
     * Filters the list by checking a setting and then applying the filtering
     * specified by the settings.
     *
     * @param originalList The unfiltered list.
     * @returns {TModel[]} The filtered list of models.
     */
    public filter(originalList: TModel[]): TModel[] {
        const setting = <Setting<TEnum>> this.settingsRepository.get(this.settingKey);
        const filterName = this.getFilterName(setting.value);

        // Inject the filter and filter the list according to filter type
        // if no filtername is retrieved then it is a saved filter

        if (filterName === 'customFilter') {
            // If Only My Calls is disabled then mix filters (types, zones and agencies) and apply it
            const customFilter = this.$injector.get<CustomFilterAllFilters>('callCustomFilterAllFilters');
            const filtersStorage = this.$injector.get<FiltersStorage>('callFiltersStorage');

            if (filtersStorage.getCurrentFilter() !== setting.selectedOption.text) {
                filtersStorage.setCurrentFilter(setting.selectedOption.text);
                customFilter.restoreAllFilters();
            }

            if (customFilter?.isFilteringCustom()) {
                return originalList.filter(customFilter.include.bind(customFilter));
            } else {
                return originalList;
            }
        } else if (filterName === 'unitCustomFilter') {
            // If Only My Calls is disabled then mix filters (types, zones and agencies) and apply it
            const unitCustomFilter = this.$injector.get<CustomFilterAllFilters>('unitCustomFilterAllFilters');
            const unitFiltersStorage = this.$injector.get<FiltersStorage>('unitFiltersStorage');

            if (unitFiltersStorage?.getCurrentFilter() !== setting.selectedOption.text) {
                unitFiltersStorage?.setCurrentFilter(setting.selectedOption.text);
                unitCustomFilter.restoreAllFilters();
            }

            if (unitCustomFilter?.isFilteringCustom()) {
                return originalList.filter(unitCustomFilter.include.bind(unitCustomFilter));
            } else {
                return originalList;
            }

        } else {
            const cadFilter = this.$injector.get<CadFilter<TModel>>(filterName);
            return originalList.filter(cadFilter.include.bind(cadFilter));
        }
    }

    /**
     * Gets the key of the setting that specifies how to sort the list.
     *
     * @returns {SettingKey} The key of the setting that specifies how to sort the list.
     */
    protected get settingKey(): SettingKey {
        throw new Error('Abstract property');
    }

    /**
     * Gets the string name associated with the specified enum value.
     *
     * @param value An enum value (i.e. a number).
     * @returns {string} The name associated with the enum value.
     */
    protected abstract getFilterName(value: TEnum): string;
}

export default CadListFilter;
