/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IFilterFilter = angular.IFilterFilter;
import IParseService = angular.IParseService;

/**
 * A class that provides the ability to filter an array by only examining certain specified properties.
 * Those properties that contain (case-insensitive) the given text will be included.
 */
export default class ContainsTextFilter<T> {

    public static $inject = ['filterFilter', '$parse'];

    /**
     * Constructs a new instance of the ContainsTextFilter class.
     *
     * @param filterFilter Angular's built-in filter for filtering arrays.
     * @param $parse The Angular service that parses a string and converts it into code.
     */
    constructor(private filterFilter: IFilterFilter, private $parse: IParseService) {
    }

    /**
     * Filters the array by returning only those elements that have one or more of the
     * specified properties that contains (case-insensitive) the text.
     *
     * @param array The array to filter.
     * @param text The text that is sought for.
     * @param propertyNames The names of the properties that will be searched.
     * @returns A new array that contains only those elements that have one or more of the
     *          specified properties that contain (case-insensitive) the given text.
     */
    public filter(array: T[], text: string, propertyNames?: string[]): T[] {
        // Nothing to filter against, so just return the entire array.
        if (!text) {
            return array;
        }

        // If no property names are specified, then fallback to Angular's built-in filter.
        if (!propertyNames || !propertyNames.length) {
            return this.filterFilter(array, text);
        }

        return array.filter(item => propertyNames.some(propertyName => {
            let value = this.$parse(propertyName)(item);
            return (value === undefined) ? false : value.toString().containsCaseInsensitive(text);
        }));
    }
}
