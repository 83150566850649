/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import MyCallsFilter from '../../calls/filtering/MyCallsFilter';
import CustomFilterAllFilters from '../custom-filters/CustomFilterAllFilters';
import FiltersStorage from '../storage/FiltersStorage';
import CustomFilterByZones from '../custom-filters/CustomFilterByZones';
import CustomFilterOnlyMyCalls from '../custom-filters/CustomFilterOnlyMyCalls';
import CustomFilterByAgencies from '../custom-filters/CustomFilterByAgencies';
import CustomFilterByTypes from '../custom-filters/CustomFilterByTypes';
import CallFilterByUnit from '../../calls/filtering/CallFilterByUnit';

/**
 * Filters the calls so that only those whose agency, type or zone is the same as the selected ones, will be shown.
 * As with all call filters, any calls that are assigned to the user will be shown no matter what -
 * they cannot be filtered out.
 */
export default class CallCustomFilterAllFilters extends CustomFilterAllFilters {
    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['myCallsFilter', 'callFilterByUnit', 'callCustomFilterByTypes', 'callCustomFilterByAgencies', 'customFilterOnlyMyCalls', 'callCustomFilterByZones', 'callFiltersStorage'];

    /**
     * Constructs a new instance of the CallCustomFilterAllFilters class.
     *
     * @param myCallsFilter The filter that includes any calls assigned to the current user.
     * @param callFilterByUnit The filter that includes only calls assigned to the current unit.
     * @param customFilterByTypes The filter that includes any calls that have the selected types.
     * @param customFilterByAgencies The filter that includes any calls that have the selected agencies.
     * @param customFilterOnlyMyCalls The filter that includes only calls assigned to the current user.
     * @param customFilterByZones The filter that includes calls from the selected zones.
     * @param filtersStorage The filter storage manager that saves and loads saved filters.
     *///
    constructor(
        myCallsFilter: MyCallsFilter,
        callFilterByUnit: CallFilterByUnit,
        customFilterByTypes: CustomFilterByTypes,
        customFilterByAgencies: CustomFilterByAgencies,
        customFilterOnlyMyCalls: CustomFilterOnlyMyCalls,
        customFilterByZones: CustomFilterByZones,
        filtersStorage: FiltersStorage
    ) {
        super(myCallsFilter, callFilterByUnit, customFilterByTypes, customFilterByAgencies, customFilterOnlyMyCalls, customFilterByZones, filtersStorage, undefined);
    }
}
