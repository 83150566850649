/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { OpaqueToken } from '@angular/core';
import { ExpandNodeModelCreationVisitor } from './expand-node-model-creation-visitor';
import { ExpandSelectNodeModelCreationVisitor } from './expand-select-node-model-creation-visitor';
import { FieldNodeModelCreationVisitor } from './field-node-model-creation-visitor';
import { IncludeManyNodeModelCreationVisitor } from './include-many-node-model-creation-visitor';
import { IncludeManySelectNodeModelCreationVisitor } from './include-many-select-node-model-creation-visitor';
import { IncludeOneNodeModelCreationVisitor } from './include-one-node-model-creation-visitor';
import { IncludeOneSelectNodeModelCreationVisitor } from './include-one-select-node-model-creation-visitor';
import { TableNodeModelCreationVisitor } from './table-node-model-creation-visitor';
import { InvolvementsNodeModelCreationVisitor } from './involvements-node-model-creation-visitor';
import { InvolvementsAlertsNodeModelCreationVisitor } from './involvements-alerts-node-model-creation-visitor';

/**
 * A token that allows us to bundle up all of the type-specific visitors pertaining to model creation.
 * The visitors can then be injected into another class as an array instead of one-by-one.
 */
export const MODEL_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN = new OpaqueToken('ModelCreationTypeSpecificVisitors');

/**
 * An array that contains all providers within the "model-creation/type-specific-visitors" folder.
 */
export const MODEL_CREATION_TYPE_SPECIFIC_VISITOR_PROVIDERS: any[] = [
    { provide: MODEL_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: ExpandNodeModelCreationVisitor, multi: true },
    { provide: MODEL_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: ExpandSelectNodeModelCreationVisitor, multi: true },
    { provide: MODEL_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: FieldNodeModelCreationVisitor, multi: true },
    { provide: MODEL_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: IncludeManyNodeModelCreationVisitor, multi: true },
    { provide: MODEL_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: IncludeManySelectNodeModelCreationVisitor, multi: true },
    { provide: MODEL_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: IncludeOneNodeModelCreationVisitor, multi: true },
    { provide: MODEL_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: IncludeOneSelectNodeModelCreationVisitor, multi: true },
    { provide: MODEL_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: TableNodeModelCreationVisitor, multi: true },
    { provide: MODEL_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: InvolvementsNodeModelCreationVisitor, multi: true },
    { provide: MODEL_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN, useClass: InvolvementsAlertsNodeModelCreationVisitor, multi: true }
];
