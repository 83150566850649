export const CALL_BUCKET = 'call';
export const CALL_RETRY_FILTER = 'Spillman:Retry:Call:Filter';
export const CALL_DISABLE_FIELDS = 'Spillman:Call:DisableFields';
export const CALL_CLEAN_FIELDS = 'Spillman:Call:CleanFields';
export const CALL_ENABLE_FIELDS = 'Spillman:Call:EnableFields';
export const CALL_ADVANCED_FILTER_CHANGED = 'Spillman:Call:AdvancedFilterChanged';
export const CALL_ADVANCED_FILTER_DELETED = 'Spillman:Call:AdvancedFilterDeleted';
export const CALL_CLEAN_FOCUS = 'Spillman:Call:CleanFocus';
const CALL_SELECTED_INDEX_CHANGED = 'Spillman:SelectedIndexChanged';

export const UNIT_BUCKET = 'unit';
export const UNIT_RETRY_FILTER = 'Spillman:Retry:Unit:Filter';
export const UNIT_DISABLE_FIELDS = 'Spillman:Unit:DisableFields';
export const UNIT_CLEAN_FIELDS = 'Spillman:Unit:CleanFields';
export const UNIT_ENABLE_FIELDS = 'Spillman:Unit:EnableFields';
export const UNIT_ADVANCED_FILTER_CHANGED = 'Spillman:Unit:AdvancedFilterChanged';
export const UNIT_ADVANCED_FILTER_DELETED = 'Spillman:Unit:AdvancedFilterDeleted';
export const UNIT_CLEAN_FOCUS = 'Spillman:Unit:CleanFocus';
const UNIT_SELECTED_INDEX_CHANGED = 'Spillman:Unit:SelectedIndexChanged';

export const NEW_FILTER_TITLE = 'Create New Filter';

export class FilterActions {
    public CLEAN_FIELDS: string;
    public ENABLE_FIELDS: string;
    public DISABLE_FIELDS: string;
    public RETRY_FILTER: string;
    public CLEAN_FOCUS: string;
    public ADVANCED_FILTER_CHANGED: string;
    public ADVANCED_FILTER_DELETED: string;
    public SELECTED_INDEX_CHANGED: string;

    constructor( cleanFields: string,  enableFields: string, disableFields: string, retry: string, clean: string, filterChange: string, filterDelete: string, selected_index_changed: string){
        this.CLEAN_FIELDS = cleanFields;
        this.ENABLE_FIELDS = enableFields;
        this.DISABLE_FIELDS = disableFields;
        this.RETRY_FILTER = retry;
        this.CLEAN_FIELDS = clean;
        this.ADVANCED_FILTER_CHANGED = filterChange;
        this.ADVANCED_FILTER_DELETED = filterDelete;
        this.SELECTED_INDEX_CHANGED = selected_index_changed;
    }
}

export const UNIT_ACTIONS = new FilterActions(
    UNIT_CLEAN_FIELDS,
    UNIT_ENABLE_FIELDS,
    UNIT_DISABLE_FIELDS,
    UNIT_RETRY_FILTER,
    UNIT_CLEAN_FIELDS,
    UNIT_ADVANCED_FILTER_CHANGED,
    UNIT_ADVANCED_FILTER_DELETED,
    UNIT_SELECTED_INDEX_CHANGED
);


export const CALL_ACTIONS = new FilterActions(
    CALL_CLEAN_FIELDS,
    CALL_ENABLE_FIELDS,
    CALL_DISABLE_FIELDS,
    CALL_RETRY_FILTER,
    CALL_CLEAN_FIELDS,
    CALL_ADVANCED_FILTER_CHANGED,
    CALL_ADVANCED_FILTER_DELETED,
    CALL_SELECTED_INDEX_CHANGED
);
