/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TablesApiHelper } from './tables-api-helper';
import { PropertyNode } from '../nodes/property/property-node';
import { DateConverter } from './converters/date-converter';

/**
 * Deserializes a property from a JSON object and performs any conversion necessary
 * to make it assignable to a target property (on our "human-friendly" models).
 */
@Injectable()
export class PropertyDeserializer {

    /**
     * Constructs a new instance of the PropertyAssigner class.
     *
     * @param tablesApiHelper A helper that knows how to work with the database models returned by the Tables API.
     * @param dateConverter Converts a string into an actual Date object.
     */
    constructor(private tablesApiHelper: TablesApiHelper, private dateConverter: DateConverter) {
    }

    /**
     * Deserializes the value -- performing any type conversion necessary so that it can be assigned
     * to the property represented by the targetNode.
     *
     * @param targetNode A PropertyNode that represents a property that will be assigned a value.
     * @param value The value to be deserialized and converted to the correct type.
     */
    public deserialize(targetNode: PropertyNode, value: any): any {
        const propertyType = Reflect.getMetadata('design:type', targetNode.parent.classConstructor.prototype, targetNode.propertyKey);
        const rawValue = this.tablesApiHelper.getValue(value);

        // TODO: We can remove the ` || propertyType === Object` part after the following bug gets fixed:
        // https://github.com/s-panferov/awesome-typescript-loader/issues/214
        if (propertyType === Date || propertyType === Object) {
            return this.dateConverter.convert(rawValue);
        }
        return rawValue;
    }
}
