/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';

/**
 * Road Contributing Circumstance
 */
@table('acrdcc')
export class RoadContributingCircumstance {

    @expand('roadcc', 'desc')
    public desc: string;
}
