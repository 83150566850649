/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import PropertyListController from './list/PropertyListController';
import PermissionName from '../../permissions/enums/PermissionName';
import { upgradeAdapter } from '../../upgrade-adapter';
import { PropertyDetailComponent } from './detail/property-detail.component';
import { createDetailState } from '../state-definition-helpers';
import { propertySearchComponent } from './search/property-search.component';

export const propertyModule = angular.module('property', [])
    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.search.propertiesList', {
                url: '/results/properties?filter&color',
                template: require('./list/list.html'),
                controller: 'propertyListController as vm',
                data: {
                    permissions: [PermissionName.property]
                }
            })
            .state('app.search.propertiesDetails', createDetailState('property', 'prmain', { permission: PermissionName.property, customUrl: '/results/properties/:id' }));
    }])

    // Components
    .component('sdsPropertySearch', propertySearchComponent)

    // Controllers
    .controller('propertyListController', PropertyListController)

    // Directives
    .directive('sdsPropertyDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(PropertyDetailComponent));
