/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import { sharedModule } from '../shared/shared.module.ng1';
import { callModule } from './calls/call.module.ng1';
import { unitModule } from './units/unit.module.ng1';
import ColorAssigner from './ColorAssigner';
import FilterByAgency from './filtering/filters/FilterByAgency';
import FilterByType from './filtering/filters/FilterByType';
import FilterByUnit from './filtering/filters/FilterByUnit';
import FilterByZone from './filtering/filters/FilterByZone';
import FilterNone from './filtering/filters/FilterNone';
import CadListFilter from './filtering/CadListFilter';
import LogicalOrFilter from './filtering/baseClasses/LogicalOrFilter';
import CadConnection from './CadConnection';
import { upgradeAdapter } from '../upgrade-adapter';
import { StatusControlComponent } from './status-control/status-control.component';
import { MyUnitProvider } from './my-unit-provider.service';
import { AddRadioLogComponent } from './add-radio-log/add-radio-log.component';

upgradeAdapter.upgradeNg1Provider('colorAssigner');

export const cadModule = angular.module('cad', [
    sharedModule.name,
    callModule.name,
    unitModule.name
])
    .constant('cadDateTimeFormat', 'HH:mm:ss MM/DD/YYYY')
    .value('callCategories', {
        received: {
            values: {},
            cssClass: 'received',
            isDefault: true
        },
        enRoute: {
            values: {
                '17': true,
                '17H': true,
                '17J': true,
                ASSGN: true,
                ENRT: true,
                ENRTH: true,
                ENRTJ: true
            },
            cssClass: 'en-route'
        },
        arrived: {
            values: {
                '6': true,
                '7': true,
                '23': true,
                '60': true,
                '78': true,
                '80': true,
                '95': true,
                '23H': true,
                '23J': true,
                ARR: true,
                ARRVD: true,
                ARRVJ: true,
                ARVDH: true,
                BCKP: true,
                BUSY: true,
                FPRST: true,
                MSS: true,
                OHOME: true,
                OOSRV: true,
                OSA: true,
                OVIOL: true,
                PURST: true,
                TS: true
            },
            cssClass: 'arrived'
        }
    })
    .service('colorAssigner', ColorAssigner)
    .service('filterByAgency', FilterByAgency)
    .service('filterByType', FilterByType)
    .service('filterByUnit', FilterByUnit)
    .service('filterByZone', FilterByZone)
    .service('filterNone', FilterNone)
    .filter('cadListFilter', CadListFilter)
    .service('logicalOrFilter', LogicalOrFilter)
    .service('cadConnection', CadConnection)
    .factory('myUnitProvider', upgradeAdapter.downgradeNg2Provider(MyUnitProvider))

    .directive('sdsAddRadioLog', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(AddRadioLogComponent))
    .directive('sdsStatusControl', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(StatusControlComponent));
