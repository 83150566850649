/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { ServiceAttempt } from './service-attempt';
import { NameReference } from '../../shared/model/name-reference';
import { Involvement } from '../../../schema/Involvement';
import { Offense } from './offense';
import { WantLocalId } from './want-local-id';
import { IncidentReference } from '../../shared/model/incident-reference';

/**
 * The want model
 */
@table('wamain')
export class Want {

    @field('agency')
    public agency: string;

    @includeMany('waserve', ServiceAttempt)
    public attempts: ServiceAttempt[];

    @field('bailamt')
    public bailAmount: number;

    @field('cash')
    public cashOnly: string;

    @includeOne('waremrk', 'text')
    public comments: string;

    @field('court')
    public courtNumber: string;

    @expand('cclass', 'desc')
    public crimeClass: string;

    @field('expdate')
    public dateExpires: Date;

    @field('issdate')
    public dateIssued: Date;

    @field('retrndt')
    public dateReturned: Date;

    @expand('dispos', 'desc')
    public disposition: string;

    @field('dispdt')
    public dispositionDate: Date;

    @field('docket')
    public docketNumber: string;

    @field('extrad')
    public extradition: string;

    @expand('isscrt', 'desc')
    public issuingCourt: string;

    @field('judge')
    public judge: string;

    @includeMany('walocal', WantLocalId)
    public localIds: WantLocalId[];

    @expand('nameid', NameReference)
    public name: NameReference | string;

    @expand('nciccd', 'descrpt')
    public ncicCode: string;

    @field('nitesrv')
    public nightService: string;

    @expand('offcode', 'desc')
    public offenseCode: string;

    @includeMany('waoffs', Offense)
    public offenses: Offense[];

    @expand('officer', 'fullnam')
    public officer: string;

    @expand('procid', 'desc')
    public processType: string;

    @expand('relinci', IncidentReference)
    public relatedIncident: IncidentReference | string;

    @includeOne('waremrk', 'text')
    public remarks: string;

    @field('wanted')
    public wantedFor: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
