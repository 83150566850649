/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Queue } from './queue';

/**
 * A Queue that has a fixed maximum size. If an item is enqueued when the queue is already full,
 * then the oldest item will automatically be removed.
 */
export default class LimitedSizeQueue<T> extends Queue<T> {

    /**
     * Constructs a new instance of the LimitedSizeQueue class.
     *
     * @param _maxSize The maximum number of items that may be enqueued.
     * @param initialItems An initial array of items to enqueue.
     */
    constructor(private _maxSize: number, initialItems?: T[]) {
        super(initialItems);

        if (typeof _maxSize !== 'number' || _maxSize <= 0) {
            throw new Error('The max size must be greater than zero.');
        }
    }

    /**
     * @returns The maximum number of elements that may be enqueued.
     */
    public get maxSize() {
        return this._maxSize;
    }

    /**
     * @inheritdoc
     */
    public enqueue(item: T): void {
        // Technically, the size should never be larger than the max size.
        // That could only happen if someone violated the encapsulation of the underlying
        // Queue class and modified the private `_innerArray` property.
        // It's perfectly valid, however, for the size to equal the max size.
        while (this.size >= this.maxSize) {
            // Dequeue to make room for the item that's about to be added.
            this.dequeue();
        }

        super.enqueue(item);
    }
}
