/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { VehicleReference } from '../../shared/model/vehicle-reference';
import { NameReference } from '../../shared/model/name-reference';
import { IncidentReference } from '../../shared/model/incident-reference';
import { Involvement } from '../../../schema/Involvement';

/**
 * The vehicle impound model
 */
@table('vimain')
export class VehicleImpound {

    @field('number')
    public id: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @expand('incid', IncidentReference)
    public incident: IncidentReference | string;

    @expand('owner', NameReference)
    public owner: NameReference | string;

    @expand('vehicle', VehicleReference)
    public vehicle: VehicleReference | string;

    @field('agency')
    public agency: string;

    @field('salwhen')
    public dateSold: Date;

    @field('drivadd')
    public driverAddress: string;

    @field('drivnam')
    public driver: string;

    @field('drivpho')
    public driverPhone: string;

    @field('drivcsz')
    public driverCityStateZip: string;

    @field('impdate')
    public impoundDate: Date;

    @expand('imptype', 'desc')
    public impoundType: string;

    @field('lienadd')
    public lienHolderAddress: string;

    @field('liencsz')
    public lienHolderCityStateZip: string;

    @field('liennam')
    public lienHolder: string;

    @field('lienpho')
    public lienHolderPhone: string;

    @expand('impby', 'fullnam')
    public officer: string;

    @field('reason')
    public reason: string;

    @field('reladd')
    public releasedToAddress: string;

    @field('relcsz')
    public releasedToCityStateZip: string;

    @expand('relby', 'fullnam')
    public releasedBy: string;

    @field('relnam')
    public releasedTo: string;

    @field('salepri')
    public salePrice: number;

    @field('schsale')
    public scheduledSale: Date;

    @expand('status', 'desc')
    public status: string;

    @field('storage')
    public storageLocation: string;

    @field('ticket')
    public ticketNumber: string;

    @field('towdate')
    public towed: Date;

    @expand('towedby', 'desc')
    public towedBy: string;

    @field('from')
    public towedFrom: string;

    @field('vin')
    public verifiedVin: string;

    @field('relwhen')
    public whenReleased: Date;
}
