/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import IncidentHistoryListDirective from './directives/IncidentHistoryListDirective';
import IncidentHistorySortingDirective from './directives/IncidentHistorySortingDirective';
import CallRepository from './repository/CallRepository';
import CallDateTransform from './repository/transforms/CallDateTransform';
import CallFullCityTransform from './repository/transforms/CallFullCityTransform';
import CallUnitMethodsTransform from './repository/transforms/CallUnitMethodsTransform';
import CallUnitPropertiesTransform from './repository/transforms/CallUnitPropertiesTransform';
import AllCallTransforms from './repository/transforms/AllCallTransforms';
import CallListController from './list/CallListController';
import CallDetailsController from './details/CallDetailsController';
import RadioLogsCallQuery from './details/queries/RadioLogsCallQuery';
import IncidentHistoryCallQuery from './details/queries/IncidentHistoryCallQuery';
import AddressAlertsCallQuery from './details/queries/AddressAlertsCallQuery';
import CommentsCallQuery from './details/queries/CommentsCallQuery';
import { CallQueryFactory } from './details/queries/call-query-factory.service';
import CallLocationAdapter from './CallLocationAdapter';
import ComplainantAlertsCallQuery from './details/queries/ComplainantAlertsCallQuery';
import MyCallsFilter from './filtering/MyCallsFilter';
import CallListFilter from './filtering/CallListFilter';
import CallActionDirective from './directives/callAction/CallActionDirective';
import CallListDisplayProperties from './list/CallListDisplayProperties';
import PermissionName from '../../permissions/enums/PermissionName';
import callHistoryLinkComponent from './components/call-history-link/call-history-link.component';
import { callHistoryAdvancedFilterComponent } from './components/call-history-advanced-filter/call-history-advanced-filter.component';
import { CallHistoryAdvancedFilterService } from './components/call-history-advanced-filter/call-history-advanced-filter.service';
import FilterScreenController from './components/filter-screen/FilterScreenController';
import { callTypeComponent } from './components/filter-components/type/type-component.component';
import CallCustomFilter from './filtering/CallCustomFilter';
import CallCustomFilterAllFilters from '../filtering/calls/CallCustomFilterAllFilters';
import CallFilterByAgency from './filtering/CallFilterByAgency';
import CallFilterByType from './filtering/CallFilterByType';
import CallFilterByUnit from './filtering/CallFilterByUnit';
import CallFilterByZone from './filtering/CallFilterByZone';
import { callAccordionComponent } from './components/filter-components/generic/accordion/accordion-component.component';
import { callAgencyComponent } from './components/filter-components/agency/agency-component.component';
import { AgencyService } from '../services/agency/agency-service';
import { ZoneService } from '../services/zones/zone-service';
import { callZoneComponent } from './components/filter-components/zone/call-zone-component.component';
import { upgradeAdapter } from '../../upgrade-adapter';
import { myCallsComponent } from './components/filter-components/my-calls/my-calls-component.component';
import CustomFilterOnlyMyCalls from '../filtering/custom-filters/CustomFilterOnlyMyCalls';
import { callFilterHeaderComponent } from './components/filter-components/filter-header/filter-header-component.component';
import CallFiltersStorage from './filtering/CallFiltersStorage';
import CallCustomFilterByAgencies from '../filtering/calls/CallCustomFilterByAgencies';
import CallCustomFilterByTypes from '../filtering/calls/CallCustomFilterByTypes';
import CallCustomFilterByZones from '../filtering/calls/CallCustomFilterByZones';
import { PlatformDetector } from '../../../app/conditional-injection/platform-detection/platform-detector.service';
import { callFilterOptionsSubheader } from './components/call-filter-options-subheader/CallFilterOptionsSubheader.component';

export const callModule = angular.module('call', [])
    .component('sdsCallHistoryLink', callHistoryLinkComponent)
    .component('callHistoryAdvancedFilter', callHistoryAdvancedFilterComponent)
    .component('sdsCallTypeComponent', callTypeComponent)
    .component('sdsCallAccordion', callAccordionComponent)
    .component('sdsCallAgencies', callAgencyComponent)
    .component('sdsCallZones', callZoneComponent)
    .component('sdsOnlyMyCalls', myCallsComponent)
    .component('sdsCallFilterHeader', callFilterHeaderComponent)
    .component('sdsCallFilterOptionsSubheader', callFilterOptionsSubheader)
    .constant('callListDisplayProperties', CallListDisplayProperties)
    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.calls', {
                url: '/calls',
                abstract: true,
                views: {
                    calls: {
                        template: '<ion-nav-view></ion-nav-view>'
                    }
                },
                data: {
                    permissions: [PermissionName.touchcalls]
                }
            })
            .state('app.calls.callsList', {
                url: '',
                template: require('./list/list.html'),
                controller: 'callListController as vm',
                data: {
                    homePageIndex: 0
                }
            })
            .state('app.calls.callsDetails', {
                url: '/call/:id',
                template: require('./details/details.html'),
                controller: 'callDetailsController',
                params: {
                    updates: []
                }
            })
            .state('app.calls.filterScreen', {
                url: '/filter',
                template: require('./components/filter-screen/filter-screen.html'),
                controller: 'filterScreenController as vm'
            });
    }])

    .directive('incidentHistoryList', IncidentHistoryListDirective)
    .directive('incidentHistorySorting', IncidentHistorySortingDirective)
    .directive('sdsCallAction', CallActionDirective)

    // Services
    .service('callRepository', CallRepository)
    .service('radioLogsCallQuery', RadioLogsCallQuery)
    .service('incidentHistoryCallQuery', IncidentHistoryCallQuery)
    .service('addressAlertsCallQuery', AddressAlertsCallQuery)
    .service('callQueryFactory', CallQueryFactory)
    .service('callDateTransform', CallDateTransform)
    .service('callFullCityTransform', CallFullCityTransform)
    .service('callUnitMethodsTransform', CallUnitMethodsTransform)
    .service('callUnitPropertiesTransform', CallUnitPropertiesTransform)
    .factory('allCallTransforms', AllCallTransforms)
    .service('callLocationAdapter', CallLocationAdapter)
    .service('commentsCallQuery', CommentsCallQuery)
    .service('complainantAlertsCallQuery', ComplainantAlertsCallQuery)
    .service('callFilterByAgency', CallFilterByAgency)
    .service('callFilterByType', CallFilterByType)
    .service('callFilterByUnit', CallFilterByUnit)
    .service('callFilterByZone', CallFilterByZone)
    .service('currentFilter', CallCustomFilter)
    .service('newFilter', CallCustomFilter)
    .service('callCustomFilterByAgencies', CallCustomFilterByAgencies)
    .service('callCustomFilterByTypes', CallCustomFilterByTypes)
    .service('callCustomFilterByZones', CallCustomFilterByZones)
    .service('customFilterOnlyMyCalls', CustomFilterOnlyMyCalls)
    .service('myCallsFilter', MyCallsFilter)
    .service('callCustomFilterAllFilters', CallCustomFilterAllFilters)
    .service('callHistoryAdvancedFilterService', CallHistoryAdvancedFilterService)
    .service('agencyService', upgradeAdapter.downgradeNg2Provider(AgencyService))
    .service('zoneService', upgradeAdapter.downgradeNg2Provider(ZoneService))
    .service('callFiltersStorage', CallFiltersStorage)
    .factory('platformDetector', upgradeAdapter.downgradeNg2Provider(PlatformDetector))
    .serviceAsFilter('callListFilter', CallListFilter, 'filter')

    // Controllers
    .controller('callListController', CallListController)
    .controller('callDetailsController', CallDetailsController)
    .controller('filterScreenController', FilterScreenController);
