/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IStateService = angular.ui.IStateService;
import PermissionsRepository from '../../permissions/PermissionsRepository';
import { TabsScope } from '../interfaces/TabsScope';
import { NotificationService } from '../../notifications/notification-service';
import { checkTabsVisibility } from './tabs/tabsVisibility';
import { addNotificationWatcher } from './tabs/tabsNotificationWatcher';
import { updateTypes } from './tabs/constants';

HeaderTabsDirective.$inject = ['$state', 'permissionsRepository', 'notificationService'];

/**
 * A directive that displays the entire set of header tab buttons.
 */
export default function HeaderTabsDirective($state: IStateService, permissionsRepository: PermissionsRepository, notificationService: NotificationService): IDirective {
    return {
        restrict: 'E',
        link: (scope: TabsScope) => {
            checkTabsVisibility(scope, permissionsRepository);

            scope.callUpdateType = updateTypes.NONE;
            addNotificationWatcher(scope, $state, notificationService);
        },
        template: require('./headerTabs.html')
    };
}
