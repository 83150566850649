/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Formatter } from './formatter';

/**
 * A formatter that knows how to format an Error object.
 */
@Injectable()
export class ErrorFormatter implements Formatter<Error> {

    /**
     * @inheritdoc
     */
    public canFormat(error: any): error is Error {
        return error instanceof Error;
    }

    /**
     * @inheritdoc
     */
    public format(error: Error): string {
        const description = `${error.name}: ${error.message}`;
        const stack = error.stack;

        if (!stack) {
            return description;
        }

        // Some browsers (chrome, opera, IE) automatically include the description
        // before the stack. Others (safari, firefox) do not.
        // So here we're making sure it's consistent across all browsers.
        return stack.startsWith(description) ? stack : `${description}\n${stack}`;
    }
}
