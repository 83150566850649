/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { FieldNode } from '../../nodes/property/field-node';
import { RequestCreationData } from '../request-creation-data';

/**
 * Performs a "visit" on a FieldNode during the process of building a request.
 */
@Injectable()
export class FieldNodeRequestCreationVisitor implements TypeSpecificTreeNodeVisitor<FieldNode, RequestCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [FieldNode];

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: FieldNode, data: RequestCreationData): void {
        // Currently, the Tables API only allows you to specify the fields you want for the topmost model.
        // You cannot specify which fields you want when doing an expand or include.
        // Therefore, only if the current path is empty, will we add to the set of fields.
        if (data.currentPath.length < 1) {
            data.request.fields.add(node.field);
        }
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: FieldNode, _data: RequestCreationData): void {
        // Nothing to do here.
    }
}
