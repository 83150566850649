/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../shared/database/decorators';

/**
 * The property reference model.
 */
@table('prmain')
export class PropertyReference {
    @field('number')
    public id: string;

    @field('item')
    public item: string;

    @field('brand')
    public brand: string;

    @field('model')
    public model: string;

    @field('color')
    public color: string;
}
