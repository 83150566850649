/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { PROPERTY_NODE_FACTORY_PROVIDERS } from './property/type-specific-node-factory-providers';
import { TableNodeFactory } from './class/table-node-factory';
import { MasterPropertyNodeFactory } from './property/master-property-node-factory';

/**
 * An array that contains all node factories.
 */
export const NODE_PROVIDERS = PROPERTY_NODE_FACTORY_PROVIDERS.concat(TableNodeFactory, MasterPropertyNodeFactory);
