/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import ReconnectTiming from './ReconnectTiming';

/**
 * A class that uses the exponential backoff algorithm found here: http://dthain.blogspot.co.uk/2009/02/exponential-backoff-in-distributed.html
 * in order to determine when to reconnect.
 */
class ExponentialBackoffReconnectTiming implements ReconnectTiming {

    /**
     * Constructs a new instance of the ExponentialBackoffReconnectTiming class.
     *
     * @param initialTimeout The initial (or minimum) amount of time to wait before reconnecting.
     *                       It should be set at the outer limits of the expected response time for your server.
     * @param maxTimeout The maximum amount of time to wait before reconnecting.
     */
    constructor(private initialTimeout: number, private maxTimeout: number) {
    }

    // @Override
    public getDelay(attempt: number): number {
        let R = Math.random() + 1;
        let T = this.initialTimeout;
        let F = 2;
        let N = attempt;
        let M = this.maxTimeout;
        return Math.floor(Math.min(R * T * Math.pow(F, N), M));
    }
}

export default ExponentialBackoffReconnectTiming;
