/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import LogicalOrFilter from '../../filtering/baseClasses/LogicalOrFilter';
import MyCallsFilter from './MyCallsFilter';
import FilterByUnit from '../../filtering/filters/FilterByUnit';
import CADModel from 'app/schema/interfaces/CADModel';

/**
 * Filters the calls so that only those whose unit number is the same as the logged-in user's, will be shown.
 * As with all call filters, any calls that are assigned to the user will be shown no matter what -
 * they cannot be filtered out.
 */
export default class CallFilterByUnit extends LogicalOrFilter<CADModel> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['myCallsFilter', 'filterByUnit'];

    /**
     * Constructs a new instance of the CallFilterByUnit class.
     *
     * @param myCallsFilter The filter that includes any calls assigned to the current user.
     * @param filterByUnit The filter that includes any calls that have the same unit number as the current user.
     */
    constructor(myCallsFilter: MyCallsFilter, filterByUnit: FilterByUnit) {
        super(myCallsFilter, filterByUnit);
    }
}
