/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { SemVer, valid } from 'semver';
import trimStart = require('lodash/trimStart');

/**
 * Parses a string to pull out the version information from it.
 *
 * NOTE: This class is more lenient than the underlying SemVer parser.
 * This class will accept one to three dot-separated numbers, whereas a valid SemVer must have exactly three.
 * This class will automatically append zeros to make it a valid SemVer.
 * Examples:
 *   '1701'         --> '1701.0.0'
 *   '1701.1234'    --> '1701.1234.0'
 *   '1701.1234.0'  --> '1701.1234.0'
 *
 * Also, a valid SemVer must not have leading zeros. This class, however, will remove leading zeros.
 * Examples:
 *   '001701.1234.0'    --> '1701.1234.0'
 *   '1701.00001234.0'  --> '1701.1234.0'
 *   '1701.1234.0000'   --> '1701.1234.0'
 */
@Injectable()
export class VersionParser {

    /**
     * Parses a string to pull out the version information.
     *
     * @param value A string representation of a version.
     * @returns A Version object that contains the version information in multiple separate properties.
     */
    public parse(value: string): SemVer {
        const formattedValue = this.format(value);
        return new SemVer(formattedValue);
    }

    /**
     * Determines if the given string is a valid version.
     *
     * @param value The value that may or may not be a valid version.
     * @returns True, if the string is a valid version, false otherwise.
     */
    public isValid(value: string): boolean {
        const formattedValue = this.format(value);
        return !!valid(formattedValue);
    }

    /**
     * Formats the value by removing leading zeros from each part of the version (major, minor, patch)
     * as well as ensuring that all three parts are present by adding zeros if necessary.
     *
     * For example: '1701.00001234' --> '1701.1234.0'
     *
     * @param value The value that may need formatting to look like a valid version.
     * @returns The formatted version string.
     */
    private format(value: string): string {
        if (!value) {
            return value;
        }

        // If it starts or ends with a dot or there are two adjacent dots, then just return it.
        // It's not valid and will fail parsing.
        if (/^\.|\.{2,}|\.$/.test(value)) {
            return value;
        }

        const parts = value.split('.').map(i => trimStart(i, '0') || '0');

        while (parts.length < 3) {
            parts.push('0');
        }

        return parts.join('.');
    }
}
