/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import angular, { IRootScopeService, IScope } from 'angular';
import CustomFilterByAgencies from '../../../../filtering/custom-filters/CustomFilterByAgencies';
import CustomFilterByTypes from '../../../../filtering/custom-filters/CustomFilterByTypes';
import CustomFilterByZones from '../../../../filtering/custom-filters/CustomFilterByZones';
import CustomFilterOnlyMyCalls from '../../../../filtering/custom-filters/CustomFilterOnlyMyCalls';
import FilterScreenController from '../../filter-screen/FilterScreenController';
import FiltersStorage from '../../../filtering/CallFiltersStorage';
import CustomFilterAllFilters from '../../../../filtering/custom-filters/CustomFilterAllFilters';
import { CALL_ACTIONS } from '../../../../filtering/consts';
import FilterHeaderComponentController from '../../../../filtering/components/baseClasses/filter-header/filter-header-component-controller';
import { callAnalyticsEvents } from '../../../../filtering/analytics/events';
import CallFilterSetting from '../../../../../../app/settings/implementations/CallFilterSetting';

import IonicHistoryService = ionic.navigation.IonicHistoryService;
import IonicPopupService = ionic.popup.IonicPopupService;
/**
 * A component of filter headers to show which filters are being applied
 */
export const callFilterHeaderComponent: angular.IComponentOptions = {
    template: require('../../../../filtering/components/baseClasses/filter-header//filter-header-component.component.html'),
    controller: class CallFilterHeaderComponentController extends FilterHeaderComponentController {
        public routeName = FilterScreenController.routeName;

        public static $inject = [
            '$rootScope',
            '$scope',
            'callCustomFilterByAgencies',
            'customFilterOnlyMyCalls',
            'callCustomFilterByTypes',
            'callCustomFilterByZones',
            'callFiltersStorage',
            'callCustomFilterAllFilters',
            '$state',
            '$ionicHistory',
            '$ionicPopup',
            'callFilterSetting'
        ];

        constructor(
            $rootScope: IRootScopeService,
            $scope: IScope,
            customFilterByAgencies: CustomFilterByAgencies,
            customFilterOnlyMyCalls: CustomFilterOnlyMyCalls,
            customFilterByTypes: CustomFilterByTypes,
            customFilterByZones: CustomFilterByZones,
            filtersStorage: FiltersStorage,
            customFilterAllFilters: CustomFilterAllFilters,
            $state: angular.ui.IStateService,
            $ionicHistory: IonicHistoryService,
            $ionicPopup: IonicPopupService,
            callFilterSetting: CallFilterSetting
        ) {
            super(
                $rootScope,
                $scope,
                customFilterByAgencies,
                customFilterOnlyMyCalls,
                customFilterByTypes,
                customFilterByZones,
                filtersStorage,
                customFilterAllFilters,
                $state,
                CALL_ACTIONS,
                callAnalyticsEvents,
                $ionicHistory,
                $ionicPopup,
                callFilterSetting
            );
        }
    }
};
