/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BatchRequest } from '../batch-request/batch-request.service';
import { Involvable } from '../../schema/interfaces/involvable';
import { SystemInvolvement } from '../../schema/SystemInvolvement';
import AssociativeArray from '../../shared/interfaces/AssociativeArray';
import { InvolvementAlertsRequestCreator, TYPE_NUMBER_KEY } from './involvement-alerts-request-creator.service';
import { ResponseParser } from '../../api/response-parser.service';
import { Logger } from '../../logging';

/**
 * A service that loads all of the involvement alerts for an entire list of involvable models
 * (e.g. names, vehicles, properties, etc) by making only one request to the server.
 */
@Injectable()
export class InvolvementAlertsBatchRequest extends BatchRequest<Involvable, SystemInvolvement> {

    /**
     * Constructs a new instance of the InvolvementAlertsBatchRequest class.
     *
     * @param http The Angular service that makes http requests.
     * @param requestCreator The object that creates the request to retrieve
     *                       the joined items for the list of models.
     * @param responseParser The object that knows how to parse a response from Spillman's API.
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param logger Logs errors to the appropriate location based on the environment.
     */
    constructor(
        http: Http,
        requestCreator: InvolvementAlertsRequestCreator,
        responseParser: ResponseParser,
        logger: Logger
    ) {
        super(http, requestCreator, responseParser, logger);
    }

    /**
     * @inheritdoc
     */
    protected getPrimaryKey(model: Involvable): string {
        return model.number;
    }

    /**
     * @inheritdoc
     */
    protected getForeignKey(involvement: SystemInvolvement): string {
        return involvement.rec;
    }

    /**
     * @inheritdoc
     */
    protected updateModelWithJoins(model: Involvable, involvementAlertsPromise: Promise<SystemInvolvement[]>): void {
        model.involvementAlerts = involvementAlertsPromise;
    }

    /**
     * @inheritdoc
     */
    protected createAdditionalData(models: Involvable[]): AssociativeArray<any> {
        return {
            [TYPE_NUMBER_KEY]: models[0].involvementType
        };
    }
}
