/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * An endorsement.
 */
@table('nmdlend')
export class Endorsement {

    @field('endorse')
    public code: string;

    @expand('endorse', 'descrpt')
    public description: string;
}
