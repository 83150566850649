/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IInjectorService = angular.auto.IInjectorService;
import SettingKey from './SettingKey';
import Setting from './Setting';
import ObjectEnumerator from '../shared/interfaces/ObjectEnumerator';

/**
 * A class that stores all settings and provides methods for them to be retrieved.
 */
export default class SettingsRepository {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$injector', 'enumEnumerator'];

    /**
     * Stores all settings in an array.
     */
    private _all: Setting<any>[] = [];

    /**
     * Stores all settings in a map indexed by the string representation of the SettingKey.
     */
    private _map: { [key: string]: Setting<any> } = {};

    /**
     * Constructs a new SettingsRepository.
     *
     * @param $injector The Angular injector service.
     * @param enumEnumerator The function that will enumerate the values of the SettingKey enum.
     */
    constructor($injector: IInjectorService, enumEnumerator: ObjectEnumerator) {
        enumEnumerator(SettingKey).forEach(key => {
            // Programmatically inject the setting.
            let setting = <Setting<any>>$injector.get(key);

            // Add it to both the array and the map.
            this._all.push(setting);
            this._map[setting.key] = setting;
        });
    }

    /**
     * An accessor to get all settings as an array.
     *
     * @returns {Setting<any>[]} The array of all settings.
     */
    public get all(): Setting<any>[] {
        return this._all;
    }

    /**
     * Gets the setting whose key matches the passed in key.
     *
     * @param key The used to look up the setting.
     * @returns {Setting<any>} The setting with the specified key or undefined if no setting exists.
     */
    public get = (key: SettingKey): Setting<any> => {
        return this._map[key];
    };
}
