/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * A want local identification.
 */
@table('walocal')
export class WantLocalId {

    @expand('type', 'desc')
    public type: string;

    @field('localid')
    public id: string;
}
