/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import DecoratorTransform from './DecoratorTransform';

/**
 * A transform that adds one or more properties to an object.
 */
abstract class AddPropertiesTransform<T> implements DecoratorTransform<T> {

    // @Override
    public invoke(model: T): void {
        let properties = this.getPropertyDescriptorMap(model);
        Object.keys(properties).forEach((key: string) => this.addProperty(model, key, properties[key]));
    }

    /**
     * Gets the property descriptor map that defines all of the properties to add to the model.
     *
     * @param model The model to which to add all properties from the descriptor map.
     * @returns {PropertyDescriptorMap} A JavaScript object that contains one or more descriptor objects.
     *                                  Each descriptor object describes a data property or an accessor property.
     */
    protected abstract getPropertyDescriptorMap(model: T): PropertyDescriptorMap;

    /**
     * Adds a new property to the model if the model does not already contain the property.
     *
     * @param model The model to which to add a property.
     * @param propertyName The name of the property.
     * @param propertyDescriptor Describes metadata about the new property.
     */
    private addProperty(model: T, propertyName: string, propertyDescriptor: PropertyDescriptor): void {
        if (!model.hasOwnProperty(propertyName)) {
            Object.defineProperty(model, propertyName, propertyDescriptor);
        }
    }
}

export default AddPropertiesTransform;
