/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../shared/database/decorators';
import { Offense } from '../../shared/model/offense';

/**
 * The class that joins an offense with a arrest.
 *
 * Note: The reason this class exists is because there is a many-to-many relationship
 *       between arrests and offenses. So there has to be a joining table in between.
 *       Currently the DatabaseService (and decorators) don't allow you to flatten
 *       joining tables such as this, but it would be a great feature to add.
 */
@table('jlofxar')
export class OffenseJoin {

    @expand('offnum', Offense)
    public offense: Offense | string;
}
