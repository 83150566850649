/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';

import { DatabaseService, ArrayResultMetadata } from '../../../shared/database/database.service';
import { AddressAlertsBatchRequest } from '../../../api/address-alerts/address-alerts-batch-request.service';
import { Call } from '../list/model/call';
import Session from '../../../login/Session';

/**
 * The number of records to retrieve initially as well as
 * each time the user scrolls to the bottom of the page.
 */
const pageSize = 50;

const callHistoryProperties = Object.freeze([
    'nature',
    'street',
    'city',
    'state',
    'zip',
    'whenReported',
    'type'
]);

@Component({
    selector: 'sds-call-history',
    template: require('./call-history.component.html')
})
export class CallHistoryComponent {

    /**
     * The filter used to limit displayed calls in the history list.
     */
    @Input() public set filter(value: string) {
        this.filterText = value;
        this.calls = this.filterCalls(value);
    }

    /**
     * Refresh the call history list.
     */
    @Input() public set refresh(_value: boolean) {
        this.requestData();
    }

    /**
     * Retrieve the next page of results from the server.
     */
    @Input() public set loadNextPage(_value: boolean) {
        this.requestNextPage();
    }

    /**
     * Event indicating that the refresh has completed.
     */
    @Output() public refreshComplete = new EventEmitter();

    /**
     * Event indicating that the next page has been loaded.
     */
    @Output() public nextPageLoaded = new EventEmitter();

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * The list of calls to be displayed.
     */
    public calls: Call[];

    /**
     * The list of all calls.
     */
    private allCalls: Call[];

    /**
     * The filter text for filtering calls.
     */
    private filterText = '';

    /**
     * The ID that points to the next page of results.
     */
    private nextPageId: string;

    /**
     * The record index that points to the next page of results.
     */
    private nextRecordIndex: string;

    /**
     * The filter needed to the next page of results.
     */
    private nextFilter: string;

    /**
     * Stop infite loading due to error in next page request.
     */
    private stopLoadingDueToError = false;

    constructor(
        @Inject('serverDateTimeFormat') private serverDateTimeFormat: string,
        @Inject('session') private session: Session,
        private databaseService: DatabaseService,
        private addressAlertsBatchRequest: AddressAlertsBatchRequest<Call>
    ) {
        this.stopLoadingDueToError = false;
    }

    /**
     * Returns a unique identifier for the call.
     *
     * @param index The index of the call within the list.
     * @param call The call to identify.
     * @returns The unique identifier for the call.
     */
    public identifyCall(_index: number, call: Call): string {
        return call.id;
    }

    /**
     * Request the call history records using the database service.
     */
    private requestData(): void {
        this.stopLoadingDueToError = false;
        const startDate = moment().subtract(7, 'days').format(this.serverDateTimeFormat);

        this.processResults(() => this.databaseService.getAll(Call, `reptd>=${startDate}`, pageSize))
            .finally(() => {
                this.finishedLoading = true;
                this.refreshComplete.emit(true);
            })
            .subscribe(calls => {
                this.allCalls = calls;
                this.calls = this.filterCalls(this.filterText);
            });
    }

    /**
     * Requests the next page of results from the server.
     */
    private requestNextPage(): void {
        if (this.nextPageId && !this.stopLoadingDueToError) {
            this.processResults(() => this.databaseService.getNextPage(Call, this.nextPageId, pageSize, this.nextRecordIndex, this.nextFilter))
                .finally(() => {
                    this.nextPageLoaded.emit(true);
                })
                .catch((err: any, caught: Observable<Call[]>) => {
                    this.stopLoadingDueToError = true;
                    return Promise.reject();
                })
                .subscribe((calls: Call[]) => {
                    this.allCalls = this.allCalls.concat(calls);
                    this.calls = this.filterCalls(this.filterText);
                });
        } else {
            this.nextPageLoaded.emit(true);
        }
    }

    /**
     * Processes the raw array of results from the server.
     *
     * @param request The request to perform.
     * @returns An observable that returns the array of processed calls.
     */
    private processResults(request: () => Observable<ArrayResultMetadata<Call>>): Observable<Call[]> {
        const agency = this.session.data.agencyCode;

        return request()

            // Save off the nextPageId.
            .map(({ results, nextPageId, nextRecordIndex, nextFilter }) => {
                this.nextPageId = nextPageId;
                this.nextRecordIndex = nextRecordIndex;
                this.nextFilter = nextFilter;
                return results;
            })

            // Filter out active calls.
            .map(calls => calls.filter(call => call.activeCalls.length === 0))

            // Include only calls for the officer's agency.
            .map(calls => calls.filter(call => call.agencies.some(callAgency => callAgency === agency)))

            // Add address alerts.
            .map(calls => this.addressAlertsBatchRequest.query(calls));
    }

    /**
     * Filter the call list using the given text value.
     *
     * @param value The text to filter on.
     * @returns The calls that match the filter.
     */
    private filterCalls(value: string): Call[] {
        if (!value) {
            return this.allCalls;
        }

        return this.allCalls.filter(
            call => callHistoryProperties.some(property => call[property] &&
                call[property].toString().containsCaseInsensitive(value)));
    }
}
