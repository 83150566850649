/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CallDetailComponent } from './call-detail.component';
import { CallHistoryComponent } from './history/call-history.component';

@NgModule({
    imports: [SharedModule],
    declarations: [CallDetailComponent, CallHistoryComponent]
})
export class CallModule { }
