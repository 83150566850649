/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import AddressAlert from '../../schema/AddressAlert';
import ComputedUrlResource from '../../resource/computedUrl/ComputedUrlResource';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
AddressAlertAPI.$inject = ['computedUrlResource'];

/**
 * A factory function that creates an API for retrieving Address Alerts from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @returns {IResourceClass<AddressAlert>} A resource class that knows how to load Address Alerts from the REST server.
 */
function AddressAlertAPI(computedUrlResource: ComputedUrlResource): IResourceClass<AddressAlert> {
    return computedUrlResource<AddressAlert>({ path: '/tables/gbadalrt' }, {}, {
        query: {
            method: 'GET',
            isArray: true,
            params: {
                expand: 'code'
            }
        }
    });
}

export default AddressAlertAPI;
