/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import FiltersStorage from '../../calls/filtering/CallFiltersStorage';
import CustomFilterByZones from '../custom-filters/CustomFilterByZones';

export default class UnitCustomFilterByZones extends CustomFilterByZones {
    public static $inject = ['unitFiltersStorage'];

    constructor( filtersStorage: FiltersStorage) {
        super(filtersStorage);
    }
}
