/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import NameListController from './list/NameListController';
import PermissionName from '../../permissions/enums/PermissionName';
import NameAddressDirective from './directives/NameAddressDirective';
import { upgradeAdapter } from '../../upgrade-adapter';
import { NameDetailComponent } from './detail/name-detail.component';
import { createDetailState } from '../state-definition-helpers';
import { nameSearchComponent } from './search/name-search.component';
import { PhoneBuilder } from './search/phone-builder.service';

export const nameModule = angular.module('name', [])
    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.search.namesList', {
                url: '/results/names?filter&phone',
                template: require('./list/list.html'),
                controller: 'nameListController as vm',
                data: {
                    permissions: [PermissionName.names]
                }
            })
            .state('app.search.namesDetails', createDetailState('name', 'nmmain', { permission: PermissionName.names, customUrl: '/results/names/:id' }));
    }])

    // Components
    .component('sdsNameSearch', nameSearchComponent)

    // Controllers
    .controller('nameListController', NameListController)

    // Directives
    .directive('sdsNameAddress', NameAddressDirective)
    .directive('sdsNameDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(NameDetailComponent))

    // Services
    .factory('phoneBuilder', upgradeAdapter.downgradeNg2Provider(PhoneBuilder));
