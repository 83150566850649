/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IPromise = angular.IPromise;
import IResourceArray = angular.resource.IResourceArray;
import ErrorHandlingController from '../../../errorHandling/ErrorHandlingController';
import Vehicle from '../../../schema/Vehicle';
import MultiQueryResourceClass from '../../../resource/multiQuery/MultiQueryResourceClass';
import VehicleListStateParams from './VehicleListStateParams';
import ApplicationScope from '../../../ApplicationScope';
import IonicLoadingService = ionic.loading.IonicLoadingService;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;

/**
 * The controller that displays a list of vehicles based on the search criteria entered by user.
 */
export default class VehicleListController extends ErrorHandlingController<IResourceArray<Vehicle>> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$scope', '$ionicLoading', '$timeout', 'vehicleAPI', '$stateParams', '$ionicScrollDelegate'];

    /**
     * The array of vehicles to display.
     */
    public vehicles: IResourceArray<Vehicle>;

    /**
     * Constructs a new instance of the VehicleListController class.
     *
     * @param $scope The Angular scope object that can listen to and raise events.
     * @param $ionicLoading An overlay that displays a spinner while waiting for data to be loaded.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param vehicleAPI The service that retrieves vehicles from the server.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     * @param scrollDelegate The delegate that controls the ion-scroll directive.
     */
    constructor($scope: ApplicationScope,
        $ionicLoading: IonicLoadingService,
        $timeout: angular.ITimeoutService,
        private vehicleAPI: MultiQueryResourceClass<Vehicle>,
        private $stateParams: VehicleListStateParams,
        private scrollDelegate: IonicScrollDelegate) {
        super($scope, $ionicLoading, $timeout);
    }

    public $onInit = () => {};

    // @Override
    protected performRequest(): IPromise<IResourceArray<Vehicle>> {
        let filter = this.$stateParams.filter;
        let magicColor = this.$stateParams.color;
        return this.vehicleAPI.multiQuery({magicColor: magicColor}, {filter: filter}).$promise;
    }

    // @Override
    protected requestComplete(vehicles: IResourceArray<Vehicle>): void {
        this.scrollDelegate.scrollTop();
        this.vehicles = vehicles;
    }
}
