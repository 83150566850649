/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * The activity model
 */
@table('pnactdet')
export class Activity {

    @field('number')
    public id: string;

    @expand('activ', 'desc')
    public activity: string;

    @field('quant')
    public numberCompleted: number;
}
