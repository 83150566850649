/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../../shared/database/decorators';
import { NameReference } from '../../../../shared/model';
import { Assessment } from './assessment';
import { DrugAdministered } from './drug-administered';
import { PatientTreatment } from './patient-treatment';

/**
 * The ems patient model
 */
@table('empatien')
export class Patient {

    @field('number')
    public id: string;

    @expand('patient', NameReference)
    public name: NameReference | string;

    @field('miles')
    public transportMiles: number;

    @expand('trans', 'desc')
    public transportType: string;

    public assessments: Assessment[];

    public drugs: DrugAdministered[];

    public treatments: PatientTreatment[];
}
