/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Http, HttpModule } from '@angular/http';
import { ApiModule } from './api/api.module';
import {
    AuthenticationModule,
    LogoutService,
    ApiAuthenticationConfig,
    UrlConfig,
    SetAuthenticationHeadersService
} from './authentication';
import { CadModule } from './cad/cad.module';
import { ConditionalInjectionModule } from './conditional-injection/conditional-injection.module';
import { HistoryModule } from './history/history.module';
import { LoggingModule } from './logging/logging.module';
import { LoginModule } from './login/login.module';
import { Touch2DefaultUrlConfig } from './login/framework-overrides/touch2-default-url-config';
import { ReloadingLogoutService } from './login/framework-overrides/reloading-logout.service';
import { Ng1And2SetAuthenticationHeadersService } from './login/framework-overrides/set-authentication-headers.service';
import { PermissionsModule } from './permissions/permissions.module';
import { PrivacyPolicyModule } from './privacy-policy/privacy-policy.module';
import { RmsModule } from './rms/rms.module';
import { NativeHttpInterceptor } from './native-http/native-http.interceptor';
import { AgencyService } from './cad/services/agency/agency-service';
import { ZoneService } from './cad/services/zones/zone-service';

@NgModule({
    imports: [
        BrowserModule,
        HttpModule,
        ApiModule,
        AuthenticationModule,
        CadModule,
        ConditionalInjectionModule,
        HistoryModule,
        LoggingModule,
        LoginModule,
        PermissionsModule,
        PrivacyPolicyModule,
        RmsModule
    ],
    providers: [
        {provide: UrlConfig, useClass: Touch2DefaultUrlConfig},
        {provide: LogoutService, useClass: ReloadingLogoutService},
        {provide: SetAuthenticationHeadersService, useClass: Ng1And2SetAuthenticationHeadersService},
        {
            provide: ApiAuthenticationConfig,
            useValue: {
                clientId: process.env.CLIENT_ID,
                clientSecret: process.env.CLIENT_SECRET
            }
        },
        { provide: Http, useClass: NativeHttpInterceptor },
        AgencyService,
        ZoneService
    ]
})
export class AppModule { }
