/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAttributes = angular.IAttributes;

/**
 * A factory function that creates a directive that simply reevaluates an expression on each digest cycle.
 *
 * @returns {IDirective} A directive that simply reevaluates an expression on each digest cycle.
 */
export default function EvalDirective(): IDirective {
    return {
        restrict: 'A',
        link: (scope: IScope, _element: IAugmentedJQuery, attributes: EvalAttributes) => {
            scope.$watch(attributes.sdsEval);
        }
    };
}

/**
 * The set of known attributes that are available within the EvalDirective.
 */
interface EvalAttributes extends IAttributes {

    /**
     * The expression to evaluate.
     */
    sdsEval: string;
}
