/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Inject, Injectable } from '@angular/core';
import { Observable, ObservableInput } from 'rxjs/Observable';
import IonicPopupService = ionic.popup.IonicPopupService;
import IonicPopupConfirmPromise = ionic.popup.IonicPopupConfirmPromise;

import { injectionCondition } from '../../conditional-injection';
import { SystemUseNotification, SystemUseModel } from './system-use-notification.service';
import { SystemUse } from './system-use';

/**
 * Retrieves and presents the system use notification agreement.
 */
@Injectable()
@injectionCondition({ minServerVersion: '1720' })
export class SystemUseService implements SystemUse {

    /**
     * Constructs a new instance of the SystemUseService class.
     *
     * @param systemUseNotification A service which handles the retrieval and logging of the notification.
     * @param $ionicPopup The service that displays a native-looking dialog.
     */
    constructor(
        private systemUseNotification: SystemUseNotification,
        @Inject('$ionicPopup') private $ionicPopup: IonicPopupService
    ) {
    }

    /**
     * @inheritdoc
     */
    public getNotificationPopup(): Observable<any> {
        return this.systemUseNotification.getNotification()
            .mergeMap(data => data.required ? <ObservableInput<boolean>> this.launchPopup(data) : Observable.empty<boolean>())
            .mergeMap(response => this.systemUseNotification.saveResponse(response));
    }

    /**
     * Launches the popup which displays the system use message.
     *
     * @param data The data returned from the server containing the message.
     * @returns A promise containing the result of the user's choice.
     */
    private launchPopup(data: SystemUseModel): IonicPopupConfirmPromise {
        return this.$ionicPopup.confirm({
            template: data.message,
            okText: 'Accept',
            cancelText: 'Decline',
            cancelType: 'button-cancel'
        });
    }
}
