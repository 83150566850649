/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import { BaseNodeVisitor } from '../../../data-structures/base-node-visitor';
import { TypeSpecificTreeNodeVisitor } from '../../../data-structures/tree-node-visitor';
import { TreeNode } from '../../../data-structures/tree-node';
import { RequestCreationData } from './request-creation-data';
import { REQUEST_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN } from './type-specific-visitors/request-creation-type-specific-visitor-providers';

/**
 * Visits all nodes in the tree and builds a request to send to the Tables API.
 */
@Injectable()
export class RequestCreationVisitor extends BaseNodeVisitor<RequestCreationData> {

    /**
     * Constructs a new instance of the RequestCreationVisitor class.
     *
     * @param typeSpecificVisitors The set of type-specific visitors for each type of node.
     */
    constructor(
    @Inject(REQUEST_CREATION_TYPE_SPECIFIC_VISITORS_TOKEN)
        typeSpecificVisitors: TypeSpecificTreeNodeVisitor<TreeNode, RequestCreationData>[]
    ) {
        super(typeSpecificVisitors);
    }
}
