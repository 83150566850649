/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as moment from 'moment';
import IHttpService = angular.IHttpService;
import IPromise = angular.IPromise;
import IQService = angular.IQService;
import IHttpRequestConfigHeaders = angular.IHttpRequestConfigHeaders;
import { UrlFactory } from '../../authentication';
import OfficerName from '../../schema/OfficerName';
import Credentials from '../Credentials';
import SymoduleVerification from './interfaces/SymoduleVerification';
import SystemModule from '../../schema/SystemModule';
import { logErrorIfLoginIsVerbose, logIfLoginIsVerbose } from '../../api/analytics/firebase-crashlytics-service';
import { logEvent } from '../../api/analytics/firebase-analytics-service';
import { AUTH_EVENTS } from '../authentication/events/authentication-events';

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
SymoduleVerificationFactory.$inject = [
    '$http',
    '$q',
    'urlFactory'
];

/**
 * A factory method which verifies if the Symodule is enabled.
 *
 * @param $http The Angular service that makes http requests.
 * @param $q The angular service that handles creating and working with promises.
 * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
 *
 * @returns {SymoduleVerification} An interface describing a function which when resolved
 * returns a promise.
 */
function SymoduleVerificationFactory(
    $http: IHttpService,
    $q: IQService,
    urlFactory: UrlFactory
): SymoduleVerification {

    return (user: OfficerName, credentials: Credentials, authorization: string): IPromise<any> => {
        logIfLoginIsVerbose('Checking symodule...');
        logEvent(AUTH_EVENTS.init_symodule);
        const MODULE_NAME = 'Touch';

        // Create the URL to which to authenticate.
        let url = urlFactory.create({
            server: credentials.server,
            port: credentials.port,
            secureConnection: credentials.secureConnection,
            path: '/tables/symodule'
        });

        // Create the config object to be used with the $http.get request.
        let config = {
            headers: <IHttpRequestConfigHeaders>{
                Authorization: authorization
            },
            params: {
                filter: 'module=' + MODULE_NAME,
                include: 'symoddtl'
            }
        };

        return $http.get(url, config)

            // The server returns a valid response but there is not a symodule, then reject the promise.
            .then(response => !response.data[0] ? $q.reject() : response.data[0])

            // Verify the symodule.
            .then((symodule: SystemModule) => {
                let now = moment();

                // The lack of an expire date means it hasn't expired so set it to false.
                let isSymoduleExpired = symodule.expire ? now.isAfter(moment(symodule.expire)) : false;

                // Check if the symodule is enabled and that the date has not expired.
                if (symodule.enabled && !isSymoduleExpired) {
                    let joinedAgency = symodule.joins.find(j => j.fields.agency === user.agency);

                    // Check if agency exists.
                    if (joinedAgency) {
                        let agency = joinedAgency.fields;

                        // The lack of an expire date means it hasn't expired so set it to false.
                        let isAgencyExpired = agency.expire ? now.isAfter(moment(agency.expire)) : false;

                        // Check if the agency is enabled and that the date has not expired.
                        if (agency.enabled && !isAgencyExpired) {
                            logEvent(AUTH_EVENTS.success_symodule);
                            return;
                        }
                    }
                }

                return $q.reject();
            })
            .catch((error) => {
                logEvent(AUTH_EVENTS.fail_symodule);

                logErrorIfLoginIsVerbose('SymoduleVerificationFactory: ', error);
                return $q.reject('Symodule not enabled');
            });
    };
}

export default SymoduleVerificationFactory;
