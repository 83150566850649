/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Observable } from 'rxjs/Observable';

import { LawIncident } from '../model/law-incident';

/**
 * A service that retrieves the statutes for a law incident.
 */
export abstract class StatuteService {

    /**
     *  Attaches the statutes to the incident.
     *
     * @param incident The incident for which to attach the statutes.
     * @returns An observable stream that returns the original incident
     *          that has been modified to include the statutes.
     */
    public attachStatutes = (incident: LawIncident): Observable<LawIncident> => {
        if (incident && incident.number) {
            return this.getStatutes(incident.number).map(statutes => {
                incident.statutes = statutes || [];
                return incident;
            });
        }
        return Observable.of(incident);
    };

    /**
     * Retrieves the statutes for the specified law incident.
     *
     * @param incidentId The incident's primary key.
     * @returns An observable stream that contains the array of statutes associated with the incident.
     */
    protected abstract getStatutes(incidentId: string): Observable<string[]>;
}
