/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IIntervalService = angular.IIntervalService;
import IResourceClass = angular.resource.IResourceClass;
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import ColorAssigner from '../../ColorAssigner';
import CallDetailsScope from './CallDetailsScope';
import CallRepository from '../repository/CallRepository';
import { CallQueryFactory } from './queries/call-query-factory.service';
import BaseCallDetailsController from './BaseCallDetailsController';
import CadCallComment from '../../../schema/CadCallComment';
import LocationAdapter from '../../../shared/mapping/LocationAdapter';
import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import IonicPopupService = ionic.popup.IonicPopupService;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;
import { IStateService } from 'angular-ui-router';
import { NotificationService } from '../../../../app/notifications/notification-service';
import { PushNotificationService } from '../../../login/push-notification/push-notification.service';
import SettingsRepository from '../../../settings/SettingsRepository';
import { DeepLinkService } from '../../../../app/shared/deeplink/deep-link-service';


/**
 * The controller for the Call Details page.
 */
export default class CallDetailsController extends BaseCallDetailsController {
    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        '$scope',
        'callRepository',
        'colorAssigner',
        'callQueryFactory',
        '$interval',
        'callLocationAdapter',
        '$stateParams',
        'cadCallCommentAPI',
        'notificationService',
        'pushNotificationService',
        '$ionicPopup',
        '$ionicScrollDelegate',
        '$state',
        '$timeout',
        'settingsRepository',
        'deepLinkService'
    ];

    /**
     * Constructs a new instance of the CallDetailsController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param callRepository The repository that stores CAD calls pushed from the server.
     * @param colorAssigner The object that assigns a color based on the status of the CAD call.
     * @param callQueryFactory The factory that creates the queries used to get data not pushed out by the aggregator.
     * @param $interval The Angular service that repeatedly performs some action on a regular interval.
     * @param callLocationAdapter The adapter that obtains a location from a CAD Call.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     * @param cadCallCommentAPI The service that retrieves comments for CAD calls from the server.
     * @param notificationService The service to provide  in-app notifications logic
     * @param pushNotificationService The service which configure and works with push-notifications
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param $ionicScrollDelegate A delegate used to control the ion-scroll directive.
     * @param $state The service that transitions between states.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param settingsRepository
     * @param deepLinkService The service that contains methods to deep link to another apps (by phone number).
     */
    constructor(
        $scope: CallDetailsScope,
        callRepository: CallRepository,
        colorAssigner: ColorAssigner,
        callQueryFactory: CallQueryFactory,
        $interval: IIntervalService,
        callLocationAdapter: LocationAdapter<AggregatorCadCall>,
        $stateParams: DetailsStateParams,
        cadCallCommentAPI: IResourceClass<CadCallComment>,
        notificationService: NotificationService,
        pushNotificationService: PushNotificationService,
        $ionicPopup: IonicPopupService,
        $ionicScrollDelegate: IonicScrollDelegate,
        $state: IStateService,
        $timeout: angular.ITimeoutService,
        settingsRepository: SettingsRepository,
        deepLinkService: DeepLinkService
    ) {
        super($scope, callRepository, colorAssigner, callQueryFactory, $interval,
            callLocationAdapter, $stateParams.id, cadCallCommentAPI, notificationService,
            pushNotificationService, $ionicPopup, $ionicScrollDelegate, $state, $timeout, settingsRepository);

        $scope.callItem = (phone: string) => {
            deepLinkService.open1021PhoneNumber(phone);
        };
    }

    public $onInit = () => { };
}
