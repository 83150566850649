/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { Activity } from './activity';
import { Involvement } from '../../../schema/Involvement';
import { IncidentReference } from '../../shared/model/incident-reference';

/**
 * The pawn activity model
 */
@table('pnactiv')
export class PawnActivity {

    @field('number')
    public id: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @includeMany('pnactdet', Activity)
    public activities: Activity[];

    @expand('incidnt', IncidentReference)
    public incident: IncidentReference | string;

    @field('reqwhen')
    public whenRequested: Date;

    @field('assignd')
    public whenAssigned: Date;

    @field('complet')
    public whenCompleted: Date;

    @field('tmspent')
    public timeSpent: number;

    @field('value')
    public value: number;

    @field('reqby')
    public requestedBy: string;

    @expand('officer', 'fullnam')
    public officer: string;

    @expand('shop', 'desc')
    public shop: string;

    @field('ticket')
    public ticket: string;

    @field('evidenc')
    public evidence: string;

    @field('comment')
    public comments: string;
}
