/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { ExpandNode } from '../../nodes/property/expand-node';
import { ExpandSelectNode } from '../../nodes/property/expand-select-node';
import { RequestCreationData } from '../request-creation-data';

/**
 * Performs a "visit" on either an ExpandNode or ExpandSelectNode during the process of building a request.
 */
@Injectable()
export class ExpandNodeRequestCreationVisitor implements TypeSpecificTreeNodeVisitor<ExpandNode | ExpandSelectNode, RequestCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [ExpandNode, ExpandSelectNode];

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: ExpandNode | ExpandSelectNode, data: RequestCreationData): void {
        // Push the column onto the path.
        data.currentPath.push(node.column);

        // Add to the set of expands.
        const dotDelimitedPath = data.currentPath.join('.');
        data.request.expands.add(dotDelimitedPath);
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: ExpandNode | ExpandSelectNode, data: RequestCreationData): void {
        // Pop off the column.
        data.currentPath.pop();
    }
}
