/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../shared/database/decorators';

/**
 * Environment Contributing Circumstance.
 */
@table('acenvcc')
export class EnvironmentContributingCircumstance {

    @expand('envcc', 'desc')
    public desc: string;
}
