/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import EqualityComparer from './EqualityComparer';

/**
 * An EqualityComparer that considers two values to be equal if:
 * (1) they are both falsy
 *     or
 * (2) they are equal using the === operator.
 *
 * In other words, all falsy values are considered equal.
 */
export default class FalsyEqualityComparer<T> implements EqualityComparer<T> {

    // @Override
    public equals(arg1: T, arg2: T): boolean {
        return (!arg1 && !arg2) || arg1 === arg2;
    }
}
