/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * A class that represents an option from which a user can select in a drop-down.
 *
 * @param T the type of value that this option stores.
 */
export default class Option<T> {

    /**
     * Constructs a new Option.
     *
     * @param text The text that will be displayed to the user.
     * @param value The actual value of the setting.
     * @param metadata A bag that can be used to store any kind of metadata about the option.
     */
    constructor(public text: string, public value: T, public metadata?: any, public action?: () => void) {
    }
}
