/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IPromise = angular.IPromise;
import IResourceArray = angular.resource.IResourceArray;
import IResourceClass = angular.resource.IResourceClass;
import ErrorHandlingController from '../../../errorHandling/ErrorHandlingController';
import GeneralIncident from '../../../schema/interfaces/GeneralIncident';
import ListStateParams from '../../../shared/interfaces/ListStateParams';
import ApplicationScope from '../../../ApplicationScope';
import IonicLoadingService = ionic.loading.IonicLoadingService;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;

/**
 * The controller that displays a list of incidents based on the search criteria entered by user.
 */
export abstract class BaseIncidentListController<T extends GeneralIncident<T>> extends ErrorHandlingController<IResourceArray<T>> {

    /**
     * The array of incidents to display.
     */
    public incidents: IResourceArray<T>;

    /**
     * Constructs a new instance of the BaseIncidentListController class.
     *
     * @param $scope The Angular scope object that can listen to and raise events.
     * @param $ionicLoading An overlay that displays a spinner while waiting for data to be loaded.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param incidentAPI The service that retrieves law incidents from the server.
     * @param $stateParams Contains the URL parameters that were specified when navigating to the current state.
     * @param scrollDelegate The delegate that controls the ion-scroll directive.
     * @param detailState The name of the state to go to when the user clicks on an item in the list.
     */
    constructor(
        $scope: ApplicationScope,
        $ionicLoading: IonicLoadingService,
        $timeout: angular.ITimeoutService,
        protected incidentAPI: IResourceClass<T>,
        private $stateParams: ListStateParams,
        private scrollDelegate: IonicScrollDelegate,
        public detailState: string
    ) {
        super($scope, $ionicLoading, $timeout);
    }

    // @Override
    protected performRequest(): IPromise<IResourceArray<T>> {
        let filter = this.$stateParams.filter;
        return this.incidentAPI.query({ filter: filter }).$promise;
    }

    // @Override
    protected requestComplete(incidents: IResourceArray<T>): void {
        this.scrollDelegate.scrollTop();
        this.incidents = incidents;
    }
}
