/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Injector } from '@angular/core';
import { ClassNodeFactory } from './class-node-factory';
import { MasterPropertyNodeFactory } from '../property/master-property-node-factory';
import { PropertyNodeFactory } from '../property/property-node-factory';
import { ClassNode } from './class-node';
import { tableMetadataKey } from '../../../decorators';
import { TableNode } from './table-node';
import { Constructor } from '../../../../interfaces/constructor';

/**
 * A factory that knows how to create TableNodes.
 */
@Injectable()
export class TableNodeFactory implements ClassNodeFactory {

    /**
     * The backing property for the `nodeFactory` getter.
     */
    private _nodeFactory: PropertyNodeFactory;

    /**
     * Constructs a new instance of the TableNodeFactory class.
     *
     * @param injector The dependency injector provided by the Angular framework.
     */
    constructor(private injector: Injector) {
    }

    /**
     * Gets a factory that knows how to create PropertyNodes.
     *
     * Note: The factory is lazy-loaded because of an unavoidable circular dependency.
     * The MasterPropertyNodeFactory depends upon the ExpandNodeFactory which in turn depends upon this class.
     *
     * @returns The factory that knows how to create PropertyNodes.
     */
    private get nodeFactory(): PropertyNodeFactory {
        if (!this._nodeFactory) {
            this._nodeFactory = this.injector.get(MasterPropertyNodeFactory);
        }
        return this._nodeFactory;
    }

    /**
     * @inheritdoc
     */
    public create(classConstructor: Constructor<any>): ClassNode {
        const table: string = Reflect.getMetadata(tableMetadataKey, classConstructor);

        if (!table) {
            throw new Error(`The ${classConstructor.name} class must have the table decorator`);
        }

        return new TableNode(classConstructor, table, this.nodeFactory);
    }
}
