/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * An enumeration of the possible CSS themes.
 */
enum Theme {

    /**
     * Day mode - light background with dark text.
     */
    day,

    /**
     * Night mode - dark background with light text.
     */
    night
}

export default Theme;
