/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany } from '../../../../shared/database/decorators';
import AddressAlert from '../../../../schema/AddressAlert';

/**
 * The CAD call model for the list screen.
 */
@table('cdcall')
export class Call {

    @field('number')
    public id: string;

    @field('type')
    public type: string;

    @expand('nature', 'desc')
    public nature: string;

    @field('reptd')
    public whenReported: Date;

    @field('addrid')
    public geoid: string;

    @field('rtaddr')
    public street: string;

    @expand('rtcity', 'name')
    public city: string;

    @expand('rtcity', 'state')
    public state: string;

    @expand('rtcity', 'zip')
    public zip: string;

    @includeMany('cdhist', 'agency')
    public agencies: string[];

    @includeMany('sycad', 'callnum')
    public activeCalls: string[];

    public addressAlerts: AddressAlert[];
}
