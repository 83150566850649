/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import Setting from '../../../settings/Setting';

/**
 * An object representing a component which displays a message for an empty list.
 */
@Component({
    selector: 'sds-no-results',
    template: require('./no-results.component.html')
})
export class NoResultsComponent {
    /**
     * The filter being used with the query.
     */
    @Input() public filter: Setting<any>;

    /**
     * The list items generated from the query.
     */
    @Input() public list: { length: number };

    /**
     * The name of the list.
     */
    @Input() public name: string;
};
