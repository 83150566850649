/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { AuthenticationTokenBuilder } from './authentication-token-builder';
import { injectionCondition } from '../../conditional-injection/injection-condition.decorator';
import { Observable } from 'rxjs/Observable';
import { AuthenticationTokenService, Credentials } from '../../authentication';

/**
 * An implementation of the `AuthenticationTokenBuilder` interface that creates
 * a JWT token when using OAuth2.
 */
@Injectable()
@injectionCondition({ minServerVersion: '1701' })
export class OauthAuthenticationTokenBuilder implements AuthenticationTokenBuilder {

    /**
     * Constructs a new instance of the OauthAuthenticationTokenBuilder class.
     *
     * @param authenticationTokenService The service that retrieves the JWT token.
     */
    constructor(private authenticationTokenService: AuthenticationTokenService<Credentials>
    ) {
    }

    /**
     * @inheritdoc
     */
    public buildAuthenticationToken(credentials: Credentials): Observable<string> {
        return this.authenticationTokenService.getToken(credentials);
    }
}
