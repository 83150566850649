/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Http, URLSearchParams, Response } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import * as URI from 'urijs';

import { Constructor } from '../interfaces/constructor';
import { ClassNode } from './model-traversal/nodes/class/class-node';
import { TableNodeFactory } from './model-traversal/nodes/class/table-node-factory';
import { DepthFirstTraversal } from '../data-structures/depth-first-traversal';
import { Constraint } from './model-traversal/request-creation/constraint';
import { TablesApiRequest } from './model-traversal/request-creation/request-creation-data';
import { RequestCreationVisitor } from './model-traversal/request-creation/request-creation-visitor';
import { ModelCreationVisitor } from './model-traversal/model-creation/model-creation-visitor';
import { ModelPair } from './model-traversal/model-creation/model-creation-data';
import { UrlFactory } from '../../authentication';
import { ResponseParser } from '../../api/response-parser.service';
import { Stack } from '../data-structures/stack';
import Involvement from '../../schema/Involvement';
import { AssociativeArray } from '../interfaces/AssociativeArray';
import { logError } from '../../../app/api/analytics/firebase-crashlytics-service';

const nextPageHeader = 'next_page';
const nextIdParam = 'nextId';
const recordIndex = 'recordIndex';
const filterParam = 'filter';

/**
 * An interface that represents the metadata that is returned for an array of results.
 */
export interface ArrayResultMetadata<T> {

    /**
     * The array of results.
     */
    results: T[];

    /**
     * The ID to pass to the server to load the next page of results.
     */
    nextPageId: string;

    /**
     * The record index to pass to the server to load the next page of results.
     */
    nextRecordIndex: string;

    /**
     * The filter to pass to the server to load the next page of results.
     */
    nextFilter: string;
}

/**
 * A service that will auto-magically create a query to get the data needed to populate a model.
 * This is accomplished by analyzing the decorators placed on the model.
 */
@Injectable()
export class DatabaseService {

    /**
     * Constructs a new instance of the DatabaseService class.
     *
     * @param nodeFactory The factory that knows how to create the root node.
     * @param depthFirstTraversal The object that performs a depth-first traversal of the tree.
     * @param requestCreationVisitor The visitor that builds a Tables API request as it traverses the tree.
     * @param modelCreationVisitor The visitor that builds the models as it traverses the tree.
     * @param http The Angular service that makes http requests.
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param responseParser The object that knows how to parse a response from Spillman's API.
     */
    constructor(
        private nodeFactory: TableNodeFactory,
        private depthFirstTraversal: DepthFirstTraversal,
        private requestCreationVisitor: RequestCreationVisitor,
        private modelCreationVisitor: ModelCreationVisitor,
        private http: Http,
        private urlFactory: UrlFactory,
        private responseParser: ResponseParser
    ) {
    }

    /**
     * Retrieves a single model, specified by its primary key, from the database.
     * NOTE: This method only supports involvements if a single primary key is specified.
     *       It does not support involvements when using a composite key (i.e. AssociativeArray<string>).
     *
     * @param modelConstructor The constructor for the model.
     * @param id The primary key.
     * @returns An observable that will return the model once it has been populated with data from the database.
     */
    public get<T>(modelConstructor: Constructor<T>, id: string | AssociativeArray<string>): Observable<T> {
        const constraint: Constraint = (typeof id === 'string') ? { type: 'id', id } : { type: 'composite-id', map: id };
        return this.request<T, T>(modelConstructor, constraint, this.performRequestForSingleModel, this.createModel);
    }

    /**
     * Retrieves an array of models from the database that match the specified filter.
     * NOTE: This method does not support involvements at this time.
     *
     * @param modelConstructor The constructor used to instantiate each model.
     * @param filter The filter that determines which models to retrieve.
     * @param pageSize The page size that determines how many models to retrieve.
     *    NOTE: This value is defaulted to 999 to fix a server-side issue where the default value of 1000 blows up.
     * @returns An observable stream that will return the array of models.
     */
    public getAll<T>(modelConstructor: Constructor<T>, filter: string, pageSize: number = 999): Observable<ArrayResultMetadata<T>> {
        return this.request<T, ArrayResultMetadata<T>>(modelConstructor, { type: 'filter', filter, pageSize }, this.performRequestForArrayOfModels, this.createModelArray);
    }

    /**
     * Retrieves the next page of models from the database.
     * NOTE: This method does not support involvements at this time.
     *
     * @param modelConstructor The constructor used to instantiate each model.
     * @param nextPageId The identifier that allows the server to send the next page of results.
     * @param pageSize The page size that determines how many models to retrieve.
     *    NOTE: This value is defaulted to 999 to fix a server-side issue where the default value of 1000 blows up.
     * @returns An observable stream that will return the array of models.
     */
    public getNextPage<T>(modelConstructor: Constructor<T>, nextPageId: string, pageSize: number = 999, nextRecordIndex: string = '', nextFilter: string = ''): Observable<ArrayResultMetadata<T>> {
        return this.request<T, ArrayResultMetadata<T>>(modelConstructor, { type: 'nextPage', nextPageId, pageSize, nextRecordIndex, nextFilter }, this.performRequestForArrayOfModels, this.createModelArray)
            .catch((error) => {
                return Promise.reject();
            });
    }

    /**
     * Makes the request and maps the database model to the human-friendly model.
     *
     * @param modelConstructor The constructor for the human-friendly model.
     * @param constraint The constraint that determines which records are returned by the API.
     * @param requestor The function that makes the request.
     * @param mapper The function that maps the database model to the human-friendly model.
     * @returns An observable stream that will return M.
     *
     * @template T The type of constructor for the human-friendly model.
     * @template U The type of model to return.
     */
    private request<T, U>(
        modelConstructor: Constructor<T>,
        constraint: Constraint,
        requestor: (request: TablesApiRequest) => Observable<any>,
        mapper: (rootNode: ClassNode, databaseModel: any) => U
    ): Observable<U> {
        const rootNode = this.nodeFactory.create(modelConstructor);
        const request = new TablesApiRequest(constraint);
        this.depthFirstTraversal.traverse(rootNode, { currentPath: [], request }, this.requestCreationVisitor);
        return requestor(request).map(d => mapper(rootNode, d)).catch((err: any, caught: Observable<U>) => {
            logError(err);
            return Promise.reject();
        });
    }

    /**
     * Creates the human-friendly model from the database model.
     *
     * @param rootNode The root node of the decorated class tree.
     * @param databaseModel The raw model returned by the Table API.
     * @returns A human-friendly model that represents the database model.
     */
    private createModel = <T>(rootNode: ClassNode, databaseModel: any): T => {
        const modelPairs = new Stack<ModelPair[]>();
        modelPairs.push([{ databaseModel }]);

        this.depthFirstTraversal.traverse(rootNode, { modelPairs }, this.modelCreationVisitor);
        return <T>modelPairs.peek()[0].humanFriendlyModel;
    };

    /**
     * Creates the array of human-friendly models from the database models.
     *
     * @param rootNode The root node of the decorated class tree.
     * @param databaseModels The raw array of models returned by the Table API.
     * @returns An array of human-friendly models that represents the database models.
     */
    private createModelArray = <T>(rootNode: ClassNode, response: Response): ArrayResultMetadata<T> => {
        const databaseModels = this.responseParser.parseArray<any>(response);
        const results = databaseModels.map(d => this.createModel<T>(rootNode, d));

        const nextPageUrl = response.headers ? response.headers.get(nextPageHeader) : undefined;
        const nextPageId = nextPageUrl ? this.getNextId(nextPageUrl) : undefined;
        const nextRecordIndex = nextPageUrl ? this.getRecordIndex(nextPageUrl) : undefined;
        const nextFilter = nextPageUrl ? this.getNextFilter(nextPageUrl) : undefined;

        return { results, nextPageId, nextRecordIndex, nextFilter };
    };

    /**
     * Performs the request, potentially including involvements as well if specified by the request.
     *
     * @param request The object that contains all information necessary for the request.
     * @returns An observable that will provide the database model returned by the API.
     */
    private performRequestForSingleModel = (request: TablesApiRequest): Observable<any> => {
        return this.performRequest(request)
            .map<Response, any>(this.responseParser.parse)
            .flatMap(databaseModel => !request.getInvolvements ? Observable.of(databaseModel) : this.performInvolvementsRequest(request)
                .map(involvements => {
                    if (involvements) {
                        databaseModel.involvements = involvements;
                    }
                    return databaseModel;
                }));
    };

    /**
     * Performs the request to retrieve an array of models.
     * NOTE: Involvements are not implemented for the `getAll` method. It's possible to implement, but would require substantial changes.
     *
     * @param request The object that contains all information necessary for the request.
     * @returns An observable that will provide the array of database models returned by the API.
     */
    private performRequestForArrayOfModels = (request: TablesApiRequest): Observable<Response> => {
        if (request.getInvolvements) {
            throw new Error('Involvements for the `getAll` method is not implemented at this time');
        }
        return this.performRequest(request);
    };

    /**
     * Performs the request to the Tables API.
     *
     * @param request The object that contains all information necessary for the request.
     * @param parser The function that extracts the database model from the response.
     * @returns An observable that will provide the database model returned by the API.
     */
    private performRequest(request: TablesApiRequest): Observable<Response> {
        const path = this.getPath(request);
        const url = this.urlFactory.create({ path: `/tables/${path}` });

        const params = new URLSearchParams();
        this.addParam(params, 'fields', request.fields);
        this.addParam(params, 'expand', request.expands);
        this.addParam(params, 'include', request.includes);

        const filter = this.getFilter(request.constraint);
        if (filter) {
            params.set(filter[0], filter[1]);
        }
        const pageSize = this.getPageSize(request.constraint);
        if (pageSize) {
            params.set('pageSize', pageSize.toString());

            const nextData = this.getRecordIndexAndFilter(request.constraint);

            if (nextData !== undefined) {
                params.set('recordIndex', nextData[0]);
                params.set('filter', nextData[1]);
            }
        }

        return this.http.get(url, { search: params });
    }

    /**
     * Performs the request to the involvements endpoint of the Tables API.
     *
     * @param request The object that contains all information necessary for the request.
     * @returns An observable that will provide the involvements returned by the API.
     */
    private performInvolvementsRequest(request: TablesApiRequest): Observable<Involvement[]> {
        if (request.constraint.type !== 'id') {
            throw new Error('Involvements are only supported when using a single primary key -- not composite keys');
        }

        const path = `${request.table}/${request.constraint.id}/involvements`;
        const url = this.urlFactory.create({ path: `/tables/${path}` });

        const params = new URLSearchParams();
        params.set('sort', '-alert,-type');

        return this.http.get(url, { search: params }).map<Response, Involvement[]>(this.responseParser.parseArray);
    }

    /**
     * Adds a Set of values to the URL search parameters.
     *
     * @param params The parameters to which to add the Set of values.
     * @param name The name of the parameter.
     * @param values The Set of values for the parameter.
     */
    private addParam(params: URLSearchParams, name: string, values: Set<string>): void {
        if (values.size) {
            params.set(name, [...values].join(','));
        }
    }

    /**
     * Gets the path, which is part of the URL, and specifies which record(s) to return.
     *
     * @param request The object that contains all information necessary for the request.
     * @returns The path to the record(s) to return.
     */
    private getPath(request: TablesApiRequest): string {
        switch (request.constraint.type) {
            case 'id':
                return `${request.table}/${request.constraint.id}`;
            case 'composite-id':
            case 'filter':
            case 'nextPage':
                return request.table;
            default:
                return this.assertNever(request.constraint);
        }
    }

    /**
     * Gets the filter to send to the Tables API.
     *
     * @param constraint The constraint that determines which records are returned by the API.
     * @returns The filter to send to the Tables API.
     */
    private getFilter(constraint: Constraint): [string, string] {
        switch (constraint.type) {
            case 'id':
                return undefined;
            case 'nextPage':
                return [nextIdParam, constraint.nextPageId];
            case 'composite-id':
                return [filterParam, Object.keys(constraint.map).map(key => `${key}=${constraint.map[key]}`).join(';')];
            case 'filter':
                return [filterParam, constraint.filter];
            default:
                return this.assertNever(constraint);
        }
    }

    /**
     * Gets the page size to send to the Tables API.
     *
     * @param constraint The constraint that determines how many records are returned by the API.
     * @returns The page size to send to the Tables API.
     */
    private getPageSize(constraint: Constraint): number {
        switch (constraint.type) {
            case 'id':
            case 'composite-id':
                return undefined;
            case 'filter':
            case 'nextPage':
                return constraint.pageSize;
            default:
                return this.assertNever(constraint);
        }
    }

    /**
     * Gets the record index and filter to send to the Tables API to get next page data.
     *
     * @param constraint The constraint that determines how many records are returned by the API.
     * @returns Array string with 2 items: record index and filter, both to send to the Tables API.
     */
    private getRecordIndexAndFilter(constraint: Constraint): [string, string] {
        switch (constraint.type) {
            case 'nextPage':
                return [constraint.nextRecordIndex, constraint.nextFilter];
            default:
                return undefined;
        }
    }

    /**
     * A function that should never be executed.
     * It's used to ensure that all cases are handled within a switch statement.
     * See https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
     *
     * @param x The invalid object.
     */
    private assertNever(x: never): never {
        throw new Error('Unexpected object: ' + JSON.stringify(x));
    }

    /**
     * Parses the specified url and returns the value of the next ID from the query string.
     *
     * @param url The url from which to retrieve the next ID.
     * @returns {string} The next ID query parameter.
     */
    private getNextId(url: string): string {
        const queryParameters = URI.parseQuery(URI.parse(url).query);
        return queryParameters[nextIdParam];
    }

    /**
     * Parses the specified url and returns the value of the next record index from the query string.
     *
     * @param url The url from which to retrieve the next record index.
     * @returns {string} The next record index query parameter.
     */
    private getRecordIndex(url: string): string {
        const queryParameters = URI.parseQuery(URI.parse(url).query);
        return queryParameters[recordIndex];
    }

    /**
     * Parses the specified url and returns the value of the filter from the query string.
     *
     * @param url The url from which to retrieve the next filter.
     * @returns {string} The next record index query parameter.
     */
    private getNextFilter(url: string): string {
        const queryParameters = URI.parseQuery(URI.parse(url).query);
        return queryParameters[filterParam];
    }
}
