/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import EqualityComparer from './EqualityComparer';

/**
 * The standard equality comparer that uses triple equals.
 */
export default class StandardEqualityComparer<T> implements EqualityComparer<T> {

    // @Override
    public equals(arg1: T, arg2: T): boolean {
        return arg1 === arg2;
    }
}
