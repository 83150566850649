/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IRootScopeService = angular.IRootScopeService;
import SettingKey from './SettingKey';
import Option from './Option';
import SettingStorage from './SettingStorage';
import BaseRegeneratable from '../login/regeneratable/BaseRegeneratable';
import IQService = angular.IQService;
import { PlatformDetector } from '../conditional-injection/platform-detection/platform-detector.service';
import CallFilterVersion from './implementations/CallFilterVersion';
import { CALL_ACTIONS, NEW_FILTER_TITLE, UNIT_ACTIONS } from '../cad/filtering/consts';
import UnitFilterVersion from './implementations/UnitFilterVersion';

/**
 * A class that represents a user-defined Setting for the App.
 *
 * @param T The type of value.
 */
export default class Setting<T> extends BaseRegeneratable {

    /**
     * The name of the event that is broadcast when the value changes.
     */
    public static SETTING_CHANGED = 'Spillman:SettingChanged';

    /**
     * Stores the selected index. It is lazily-loaded by the `selectedIndex` getter.
     */
    private _selectedIndex: number;

    /**
     * Constructs a new Setting.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param settingStorage The object that handles persistent storage of the setting value.
     * @param $rootScope The Angular $rootScope that can be used to broadcast events throughout the app.
     * @param key The key that uniquely identifies this setting.
     * @param name The human-friendly name of the setting.
     * @param options The array of options from which the user can select.
     * @param defaultIndex The index (in the options array) of the default value.
     */
    constructor($q: IQService,
        private settingStorage: SettingStorage,
        private $rootScope: IRootScopeService,
        public key: SettingKey,
        public name: string,
        public options: Option<T>[],
        public defaultIndex: number = 0,
        public callFilterVersion: CallFilterVersion = undefined,
        private platformDetector: PlatformDetector = undefined,
        public unitFilterVersion: UnitFilterVersion = undefined,
    ) {
        super($q);
    }

    /**
     * Gets the value for this setting, which is defined to be the value of the selected option.
     *
     * @returns {T} The value for this setting.
     */
    public get value(): T {
        return this.selectedOption.value;
    }

    /**
     * Gets the selected option.
     *
     * @returns {Option<T>} The selected option.
     */
    public get selectedOption(): Option<T> {
        return this.options[this.selectedIndex];
    }

    /**
     * Gets the index of the selected option.
     *
     * @returns {number} The index of the selected option.
     */
    public get selectedIndex(): number {
        if (!angular.isNumber(this._selectedIndex)) {
            let savedIndex = this.settingStorage.get(this.key);

            // If the savedIndex is invalid (e.g. the user tampered with local storage), then fallback to the default index.
            this._selectedIndex = this.isValidIndex(savedIndex) ? savedIndex : this.defaultIndex;
        }
        return this._selectedIndex;
    }

    /**
     * Sets the selected index.
     *
     * @param newIndex The index of the option that will be selected.
     */
    public set selectedIndex(newIndex: number) {
        if (this.isValidIndex(newIndex)) {
            const isCallFilterSetting = this.key === SettingKey.callFilterSetting;
            const isUnitFilterSetting = this.key === SettingKey.unitFilterSetting;

            const optionValue = (isCallFilterSetting || isUnitFilterSetting) ? this.options[newIndex].text : undefined;

            if (optionValue && optionValue === NEW_FILTER_TITLE) {
                this.options[newIndex].action();
            } else {
                if (newIndex < this.options.length) {
                    this._selectedIndex = newIndex;
                } else {
                    this._selectedIndex = 0;
                }
            }
            this.settingStorage.set(this.key, this._selectedIndex);

            if (isCallFilterSetting) {
                this.$rootScope.$broadcast(CALL_ACTIONS.SELECTED_INDEX_CHANGED, {selectedOption: this.selectedOption.text, selectedType: this.selectedOption.value});
            }
            if (isUnitFilterSetting) {
                this.$rootScope.$broadcast(UNIT_ACTIONS.SELECTED_INDEX_CHANGED, {selectedOption: this.selectedOption.text, selectedType: this.selectedOption.value});
            }
            this.$rootScope.$broadcast(`${Setting.SETTING_CHANGED}:${this.key}`);
        } else {
            this._selectedIndex = 0;
        }
    }

    // @Override
    protected performInitialization(): void {
        this._selectedIndex = undefined;

        const isCallFilterSetting = this.key === SettingKey.callFilterSetting;

        if (isCallFilterSetting && this.callFilterVersion) {
            if (this.platformDetector && this.platformDetector.getPlatform() === 'browser') {
                this.options = this.callFilterVersion.getNewFilterOptions as any;
                this.$rootScope.$broadcast(`${CALL_ACTIONS.ADVANCED_FILTER_CHANGED}`);
            } else {
                this.callFilterVersion.getFilterOptions().then((res: any) => {
                    this.options = res;
                    if (this.options.length === 2) {
                        this.$rootScope.$broadcast(`${CALL_ACTIONS.ADVANCED_FILTER_CHANGED}`);
                    }
                }).catch((err: any) => {
                    this.options = err;
                });
            }
        }

        const isUnitFilterSetting = this.key === SettingKey.unitFilterSetting;

        if (isUnitFilterSetting && this.unitFilterVersion) {
            if (this.platformDetector && this.platformDetector.getPlatform() === 'browser') {
                this.options = this.unitFilterVersion.getNewFilterOptions as any;
                this.$rootScope.$broadcast(`${UNIT_ACTIONS.ADVANCED_FILTER_CHANGED}`);
            } else {
                this.unitFilterVersion.getFilterOptions().then((res: any) => {
                    this.options = res;
                    if (this.options.length === 2) {
                        this.$rootScope.$broadcast(`${UNIT_ACTIONS.ADVANCED_FILTER_CHANGED}`);
                    }
                }).catch((err: any) => {
                    this.options = err;
                });
            }
        }

    }

    // @Override
    protected performDestruction(): void {
        // Nothing to do here.

        // Note: The `_selectedIndex` is cleared out during initialization instead of destruction.
        // There's a very good reason for that. If the user logs out while on the settings screen,
        // an error will be thrown because of digest cycles run after it's cleared out.
    }

    /**
     * Checks whether the specified index is valid.
     *
     * @param index The index to check.
     * @returns {boolean} True if valid, false otherwise.
     */
    private isValidIndex(index: number): boolean {
        // Since the index will be set by Angular at runtime, any type of value could potentially be passed in.
        // So we need a runtime check that it is indeed a number.
        return angular.isNumber(index) && 0 <= index && index < this.options.length;
    }
}
