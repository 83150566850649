/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';
import { NameReference } from '../../shared/model/name-reference';

/**
 * A case file offense.
 */
@table('pcofdtl')
export class Offense {

    @expand('namenum', NameReference)
    public name: NameReference | string;

    @expand('moffnum', 'statute')
    public statute: string;

    @expand('moffnum', 'offloc')
    public location: string;

    @field('amended')
    public amended: boolean;
}
