/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { ApiAuthenticationService } from './api-authentication.service';
import { LogoutService } from './logout.service';
import { ReAuthenticationService } from './re-authentication.service';
import { Credentials } from './credentials';
import { CredentialsRepository } from './credentials-repository.service';

/**
 * A class which handles re-authenticating the user with the Spillman server.
 */
@Injectable()
export class ApiReAuthenticationService extends ReAuthenticationService<Credentials> {

    /**
     * Constructs a new instance of the ReAuthenticationService class.
     *
     * @param authenticationService The service that handles authenticating the user with the Spillman server.
     * @param logoutService The service which handles logging the user out of the app.
     */
    constructor(
        authenticationService: ApiAuthenticationService,
        logoutService: LogoutService,
        private credentialsRepository: CredentialsRepository
    ) {
        super(authenticationService, logoutService);
    }

    /**
     * @inheritdoc
     */
    protected getAuthenticationParameter(): Credentials {
        return this.credentialsRepository.credentials;
    }
}
