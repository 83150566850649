/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;

/**
 * A directive that builds upon the ExpandingPopupDirective (see the template) to show a list
 * of address alerts within the popup.
 *
 * @returns {IDirective} A directive that, when clicked, displays a list of address alerts within a popup.
 */
export default function AddressAlertsPopupDirective(): IDirective {
    return {
        restrict: 'AE',
        transclude: true,
        template: require('./addressAlertsPopup.html'),
        scope: {
            alerts: '='
        }
    };
}
