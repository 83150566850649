/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { BlobRequester } from '../blob-requester.service';

/**
 * A component that displays an image. It handles authentication correctly when
 * requesting the image from the server.
 */
@Component({
    selector: 'sds-image',
    template: `<img *ngIf="imageUrl" [src]="imageUrl" />
               <i *ngIf="!imageUrl" class="icon ion-image"></i>`,
    styles: [ require('./image.component.scss') ]
})
export class ImageComponent implements OnInit {

    /**
     * The http source of the image.
     */
    @Input() public source: string;

    /**
     * The safe URL for the image
     */
    public imageUrl: SafeUrl;

    /**
     * Constructs a new image component.
     *
     * @param blobRequester The service that retrieves the blob from the server.
     */
    constructor(private blobRequester: BlobRequester) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.blobRequester.getSafeUrl(this.source).subscribe(
            safeUrl => this.imageUrl = safeUrl
        );
    }
}
