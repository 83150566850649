/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { SlideSourceInfo } from '../schema/SlideSourceInfo';
import IScope = angular.IScope;
import IStateService = angular.ui.IStateService;
import ITimeoutService = angular.ITimeoutService;
import PermissionsRepository from '../permissions/PermissionsRepository';
import PermissionConfiguration from '../permissions/PermissionConfiguration';
import IState = angular.ui.IState;
import { incidentSearchMetadataMap } from '../rms/incident/search/incident-search-metadata';

/**
 * The controller for the Searching Page.
 */
class SearchingController {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$scope', '$timeout', 'permissionsRepository', '$state'];

    /**
     * The index of the active slide.
     */
    public activeIndex = 0;

    /**
     * The array of slides that are enabled based on the user's permissions.
     */
    public enabledSlides: SlideSourceInfo[];

    /**
     * The parent state from which all of the slides inherit.
     */
    private parentStateName = 'app.search.forms';

    /**
     * The array that contains all the potential slides that may be shown.
     */
    private allSlides: SlideSourceInfo[] = [{
        title: 'Name',
        srcHtml: 'app/searching/slides/name-search.html',
        stateName: this.parentStateName + '.names'
    }, {
        title: 'Vehicle',
        srcHtml: 'app/searching/slides/vehicle-search.html',
        stateName: this.parentStateName + '.vehicles'
    }, {
        title: 'Property',
        srcHtml: 'app/searching/slides/property-search.html',
        stateName: this.parentStateName + '.properties'
    }, {
        title: 'Incident',
        srcHtml: 'app/searching/slides/incident-search.html',
        stateName: this.parentStateName + '.incidents'
    }];

    /**
     * Constructs a new instance of the SearchingController class.
     *
     * @param $scope The Angular scope object that can listen to and raise events.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param permissionsRepository The repository which stores permissions of a user.
     * @param $state The service that transitions between states.
     */
    constructor(
        $scope: IScope,
        $timeout: ITimeoutService,
        private permissionsRepository: PermissionsRepository,
        private $state: IStateService
    ) {
        this.enabledSlides = this.allSlides.filter(this.isEnabled);

        $scope.$on('$stateChangeSuccess', (_event, toState, _toParams, fromState) => this.stateChanged(toState, fromState));

        // Ensure that the activeIndex matches the current state at the time when this controller is constructed.
        // We must use $timeout so that the update happens after the constructor has finished executing.
        $timeout(() => this.stateChanged($state.current));
    }

    /**
     * Handles the event that is raised when the slide changes due to the user
     * swiping or taping on one of the header links.
     *
     * @param index The index of the new active slide.
     */
    public slideChanged = (index: number) => {
        this.activeIndex = index;

        // Reset the active flag to false for all slides.
        this.enabledSlides.forEach(s => s.active = false);

        // Find and active the slide that corresponds to the new index.
        const activeSlide = this.enabledSlides[index];
        activeSlide.active = true;

        // Transition to the state that corresponds to the new slide.
        const stateName = activeSlide.stateName;
        if (stateName) {
            this.$state.go(stateName);
        }
    };

    public $onInit = () => { };

    /**
     * Determines if the specified slide is enabled based on whether the current user's
     * has the permissions that the slide requires.
     *
     * @param slide The slide whose permissions will be checked.
     * @returns True if the user has the permissions to view the slide, false otherwise.
     */
    private isEnabled = (slide: SlideSourceInfo): boolean => {
        let permissions: PermissionConfiguration;

        // If the slide has a stateName, then use the state's permissions.
        if (slide.stateName) {
            const state = this.$state.get(slide.stateName);
            permissions = (state && state.data) ? state.data.permissions : undefined;
        }

        // If no permissions were found on the state, then use the slide's own permissions.
        if (permissions === undefined) {
            permissions = slide.permissions;
        }
        return permissions === undefined || this.permissionsRepository.processPermissionsWithDefault(permissions);
    };

    /**
     * Updates the slide index whenever the ui-router state changes.
     *
     * @param newState The new ui-router state.
     * @param oldState The previous ui-router state.
     */
    private stateChanged(newState: IState, oldState?: IState) {
        // If the new state is the parent state, then redirect to the first enabled slide.

        if (newState.name === this.parentStateName) {
            if (oldState === undefined) {
                this.changeSlide(`${this.parentStateName}.names`);
            } else {
                const incidentsSearchScreens = Array.from(incidentSearchMetadataMap.values()).map(i => i.listState);
                switch (true) {
                    case incidentsSearchScreens.indexOf(oldState.name) !== -1: this.changeSlide(`${this.parentStateName}.incidents`);
                        break;
                    case oldState.name === 'app.search.vehiclesList': this.changeSlide(`${this.parentStateName}.vehicles`);
                        break;
                    case oldState.name === 'app.search.propertiesList': this.changeSlide(`${this.parentStateName}.properties`);
                        break;
                    default: this.changeSlide(`${this.parentStateName}.names`);
                }
            }
        } else {
            this.changeSlide(newState.name);
        }
    }

    /**
     * Gets slide index and redirects to apropriate slide
     * @param name ui-router state
     */
    private changeSlide(name: string) {
        const newIndex = this.enabledSlides.findIndex(s => s.stateName === name);
        if (typeof newIndex === 'number' && newIndex >= 0) {
            this.slideChanged(newIndex);
        }
    }
}

export default SearchingController;
