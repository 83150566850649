/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/components/autocomplete/autocomplete';
import { SharedModule as AppWideSharedModule } from '../../shared/shared.module';
import { PermissionsModule } from '../../permissions/permissions.module';
import { AttachmentModule } from './attachments/attachment.module';
import { InvolvementListComponent } from './components/involvement-list/involvement-list.component';
import {
    CadCallLinkComponent,
    IncidentLinkComponent,
    JailBookingLinkComponent,
    JailOffenseLinkComponent,
    NameDisplayComponent,
    NameLinkComponent,
    PropertyLinkComponent,
    RoadwayLinkComponent,
    TrafficStopLinkComponent,
    VehicleDisplayComponent,
    VehicleLinkComponent
} from './components/links';
import { TableInfoPipe } from './pipes/table-info.pipe';
import { CodeTableAutocompleteComponent } from './components/code-table-autocomplete/code-table-autocomplete.component';
import { CodeTableService } from './components/code-table-autocomplete/code-table.service';
import { RadioLogService } from './radio-log/radio-log.service';
import { AlertComponent } from './components/alert/alert.component';

@NgModule({
    imports: [
        AppWideSharedModule,
        AutoCompleteModule,
        FormsModule,
        PermissionsModule,
        AttachmentModule
    ],
    declarations: [
        AlertComponent,
        CadCallLinkComponent,
        CodeTableAutocompleteComponent,
        IncidentLinkComponent,
        InvolvementListComponent,
        JailBookingLinkComponent,
        JailOffenseLinkComponent,
        NameDisplayComponent,
        NameLinkComponent,
        PropertyLinkComponent,
        RoadwayLinkComponent,
        TrafficStopLinkComponent,
        VehicleDisplayComponent,
        VehicleLinkComponent,
        TableInfoPipe
    ],
    exports: [
        // Modules
        AppWideSharedModule,
        FormsModule,
        PermissionsModule,

        // Components
        AlertComponent,
        CadCallLinkComponent,
        CodeTableAutocompleteComponent,
        IncidentLinkComponent,
        InvolvementListComponent,
        JailBookingLinkComponent,
        JailOffenseLinkComponent,
        NameDisplayComponent,
        NameLinkComponent,
        PropertyLinkComponent,
        RoadwayLinkComponent,
        TrafficStopLinkComponent,
        VehicleDisplayComponent,
        VehicleLinkComponent,
        TableInfoPipe
    ],
    providers: [
        CodeTableService,
        TableInfoPipe,
        RadioLogService
    ]
})
export class SharedModule { }
