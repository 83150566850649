/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */
import IonicPopoverService = ionic.popover.IonicPopoverService;
/**
 * This helper function check if the text is wrapped by checking if element scrollWidth is largest than
 * clientWidth. In that case some popover with full text will appear while user is pressing on wrapped text for
 * half second
 */
export function createPopover ( options: PopoverOptions, $ionicPopover: IonicPopoverService ): void {
    const manager = new Hammer.Manager(options.element);
    const press = new Hammer.Press({
        time: 500
    });
    manager.add(press);

    manager.on('press', (event) => {
        let template = `<ion-popover-view class="ios"><ion-content> ${options.element.textContent} </ion-content> </ion-popover-view>`;
        let popover = $ionicPopover.fromTemplate(template, {});
        if (options.element.clientWidth < options.element.scrollWidth) {
            const popoverWidth = options.element.scrollWidth + 30;
            if (popoverWidth > window.screen.width * 0.8) {
                document.documentElement.style.setProperty('--popover_width', `${window.screen.width * 0.8}px`);
            } else {
                document.documentElement.style.setProperty('--popover_width', `${popoverWidth}px`);
            }

            popover.show(event).then(() => {
                setTimeout(() => popover.hide(), 2500);
            });
        }
    });

}

export interface PopoverOptions {
    element: any;
}
