/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { OpaqueToken } from '@angular/core';
import { ExpandNodeFactory } from './expand-node-factory';
import { FieldNodeFactory } from './field-node-factory';
import { IncludeManyNodeFactory } from './include-many-node-factory';
import { IncludeOneNodeFactory } from './include-one-node-factory';
import { InvolvementsNodeFactory } from './involvements-node-factory';
import { InvolvementsAlertsNodeFactory } from './involvements-alerts-node-factory';

/**
 * A token that allows us to bundle up all of the type-specific property node factories.
 * The factories can then be injected into another class as an array instead of one-by-one.
 */
export const PROPERTY_NODE_FACTORIES_TOKEN = new OpaqueToken('PropertyNodeFactories');

/**
 * An array that contains all the type-specific property node factories.
 */
export const PROPERTY_NODE_FACTORY_PROVIDERS: any[] = [
    { provide: PROPERTY_NODE_FACTORIES_TOKEN, useClass: ExpandNodeFactory, multi: true },
    { provide: PROPERTY_NODE_FACTORIES_TOKEN, useClass: FieldNodeFactory, multi: true },
    { provide: PROPERTY_NODE_FACTORIES_TOKEN, useClass: IncludeManyNodeFactory, multi: true },
    { provide: PROPERTY_NODE_FACTORIES_TOKEN, useClass: IncludeOneNodeFactory, multi: true },
    { provide: PROPERTY_NODE_FACTORIES_TOKEN, useClass: InvolvementsNodeFactory, multi: true },
    { provide: PROPERTY_NODE_FACTORIES_TOKEN, useClass: InvolvementsAlertsNodeFactory, multi: true }
];
