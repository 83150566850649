/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { VehicleDetailComponent } from './detail/vehicle-detail.component';

@NgModule({
    imports: [SharedModule],
    declarations: [VehicleDetailComponent]
})
export class VehicleModule { }
