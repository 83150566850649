/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IResourceArray = angular.resource.IResourceArray;
import IHttpService = angular.IHttpService;
import Vehicle from '../../schema/Vehicle';
import ResourceUtils from '../../resource/ResourceUtils';
import MultiQueryResourceClass from '../../resource/multiQuery/MultiQueryResourceClass';
import MultiQueryResourceDecorator from '../../resource/multiQuery/MultiQueryResourceDecorator';
import MultiQueryConfig from '../../resource/multiQuery/MultiQueryConfig';
import VehicleGetTransform from './enums/VehicleGetTransform';
import DecoratorToResponseTransformAdapter from '../../shared/transforms/DecoratorToResponseTransformAdapter';
import ComputedUrlResource from '../../resource/computedUrl/ComputedUrlResource';
import IHttpResponseTransformer = angular.IHttpResponseTransformer;
import { Involvable } from '../../schema/interfaces/involvable';
import { InvolvementAlertsBatchRequest } from '../involvement/involvement-alerts-batch-request.service';
import curry = require('lodash/curry');
import uniqBy = require('lodash/uniqBy');

/**
 * The involement type number for vehicles (vhmain).
 */
const VEHICLE_INVOLVEMENT_TYPE_NUMBER = 600;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
VehicleAPI.$inject = [
    'computedUrlResource',
    'multiQueryResourceDecorator',
    'resourceUtils',
    '$http',
    'getFirstElementTransform',
    'decoratorToResponseTransformAdapter',
    'defaultPageSize',
    'addInvolvementTypeTransform',
    'involvementAlertsBatchRequest'
];

/**
 * A factory function that creates an API for retrieving Vehicles from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @param multiQueryResourceDecorator A function that converts a standard resource into a multi-query resource.
 * @param resourceUtils Provides utility methods for working with the Angular $resource service.
 * @param $http The Angular service that makes http requests.
 * @param getFirstElementTransform A response transform that returns only the first element from the array.
 * @param decoratorToResponseTransformAdapter An adapter that creates a response transform that loops through an array of decorator transforms.
 * @param defaultPageSize The default page size used when displaying a list of records.
 * @param addInvolvementTypeTransform A curried function that can be used to add the involvement type number to domain models.
 * @param involvementAlertsBatchRequest The service that retrieves the involvement alerts for an entire list of involvable models.
 * @returns {MultiQueryResourceClass<Vehicle>} A resource class that knows how to load Vehicles from the REST server.
 */
function VehicleAPI(
    computedUrlResource: ComputedUrlResource,
    multiQueryResourceDecorator: MultiQueryResourceDecorator,
    resourceUtils: ResourceUtils,
    $http: IHttpService,
    getFirstElementTransform: IHttpResponseTransformer,
    decoratorToResponseTransformAdapter: DecoratorToResponseTransformAdapter<Vehicle>,
    defaultPageSize: number,
    addInvolvementTypeTransform: _.CurriedFunction2<number, Involvable | Involvable[], Involvable | Involvable[]>,
    involvementAlertsBatchRequest: InvolvementAlertsBatchRequest
): MultiQueryResourceClass<Vehicle> {
    const addVehicleInvolvementTypeTransform = addInvolvementTypeTransform(VEHICLE_INVOLVEMENT_TYPE_NUMBER);

    let unDecoratedAPI = computedUrlResource<Vehicle>({ path: '/tables/vhmain/:id' }, {}, {
        get: {
            method: 'GET',
            params: {
                expand: 'color1,color2,make,ownerid,vhmchar.ccode,status,lstatus,wrecker',
                include: 'vhmchar,vhmdesc'
            },
            transformResponse: angular.appendTransform(
                $http.defaults.transformResponse,
                getFirstElementTransform,
                addVehicleInvolvementTypeTransform,
                decoratorToResponseTransformAdapter.createTransform(VehicleGetTransform)
            )
        },
        query: {
            method: 'GET',
            isArray: true,
            params: {
                expand: 'color1,make',
                pageSize: defaultPageSize
            },
            transformResponse: angular.appendTransform(
                $http.defaults.transformResponse,
                addVehicleInvolvementTypeTransform,
                involvementAlertsBatchRequest.query
            )
        }
    });

    let curriedQueryByColor = curry(queryByColor);

    let multiQueryConfig: MultiQueryConfig<Vehicle> = {
        specialParameterName: 'magicColor',
        queries: [
            curriedQueryByColor(curry['placeholder'], 'color1'), // eslint-disable-line dot-notation,@typescript-eslint/dot-notation
            curriedQueryByColor(curry['placeholder'], 'color2') // eslint-disable-line dot-notation,@typescript-eslint/dot-notation
        ],
        transform: vehicles => uniqBy(vehicles, 'number'),
        pageSize: defaultPageSize
    };

    let vehicleAPI = multiQueryResourceDecorator(unDecoratedAPI, multiQueryConfig);

    /**
     * Searches for Vehicles by querying the vhmain table using one of either the 'color1' or 'color2' fields
     * in addition to the other search params.
     *
     * @param params The search params, other than the color, entered by the user.
     * @param colorField The name of the color field: either 'color1' or 'color2'.
     * @param color The color code chosen by the user.
     * @returns {IResourceArray<Vehicle>} The unresolved resource array of vehicles.
     */
    function queryByColor(params: any, colorField: string, color: string): IResourceArray<Vehicle> {
        let colorFilter = `${colorField}=${color}`;
        let searchParams = resourceUtils.addFilter(params, colorFilter);
        return vehicleAPI.query(searchParams);
    }

    return vehicleAPI;
}

export default VehicleAPI;
