/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { BatchRequest } from '../../../../../api/batch-request/batch-request.service';
import { Patient, PatientTreatment } from '../model';
import { PatientTreatmentsRequestCreator } from './patient-treatments-request-creator.service';
import { ResponseParser } from '../../../../../api/response-parser.service';
import { Logger } from '../../../../../logging';

/**
 * A service that loads the treatments for an entire list of patients
 * by making only one request to the server.
 */
@Injectable()
export class PatientTreatmentsBatchRequest extends BatchRequest<Patient, PatientTreatment> {

    /**
     * Constructs a new instance of the PatientTreatmentsBatchRequest class.
     *
     * @param http The Angular service that makes http requests.
     * @param requestCreator The object that creates the request to retrieve
     *                       the joined items for the list of models.
     * @param responseParser The object that knows how to parse a response from Spillman's API.
     * @param logger Logs errors to the appropriate location based on the environment.
     */
    constructor(
        http: Http,
        requestCreator: PatientTreatmentsRequestCreator,
        responseParser: ResponseParser,
        logger: Logger
    ) {
        super(http, requestCreator, responseParser, logger);
    }

    /**
     * @inheritdoc
     */
    protected getPrimaryKey(patient: Patient): string {
        return patient.id;
    }

    /**
     * @inheritdoc
     */
    protected getForeignKey(treatment: PatientTreatment): string {
        return treatment.number;
    }

    /**
     * @inheritdoc
     */
    protected updateModelWithJoins(patient: Patient, treatmentsPromise: Promise<PatientTreatment[]>): void {
        treatmentsPromise.then(treatments => patient.treatments = treatments);
    }
}
