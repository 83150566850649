/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IResourceArray = angular.resource.IResourceArray;
import IQService = angular.IQService;

InfiniteScrollDirective.$inject = ['$q'];

/**
 * A factory function that creates the InfiniteScrollDirective.
 *
 * @param $q The angular service that handles creating and working with promises.
 * @returns {IDirective} A directive that will cause more results to be loaded whenever the user scrolls
 *                       to the bottom of the screen. It will automatically hide when there are no more results.
 */
function InfiniteScrollDirective($q: IQService): IDirective {
    return {
        template: '<ion-infinite-scroll ng-if="resourceArray.pager.hasNextPage()" on-infinite="loadNextPage()" immediate-check="false"></ion-infinite-scroll>',
        scope: {
            resourceArray: '=',
            ctrl: '='
        },
        link(scope: InfiniteScrollScope): void {
            scope.loadNextPage = () => {
                const pager = scope.resourceArray.pager;
                const promise = pager.hasNextPage() ? pager.appendNextPage() : undefined;
                $q.when(promise)
                    .then(() => scope.$broadcast('scroll.infiniteScrollComplete'))
                    .catch(error => {
                        // Handle losing of the context from the server-side and trying to refresh Search Results page
                        if (error.status === 400) {
                            scope.ctrl.performRequest().then((model: any) => {
                                scope.ctrl.requestComplete(model);
                                scope.$broadcast('scroll.infiniteScrollComplete');
                            });
                        }
                    });
            };
        }
    };
}

export default InfiniteScrollDirective;

/**
 * Defines the scope for the InfiniteScrollDirective.
 */
interface InfiniteScrollScope extends IScope {

    /**
     * The resource array that will continue to have new items added to it
     * whenever the user reaches the bottom of the screen.
     */
    resourceArray: IResourceArray<any>;

    /**
     * Controller of parent search results page
     */
    ctrl: any;

    /**
     * Loads the next page when the user reaches the bottom of the screen.
     */
    loadNextPage: () => void;
}
