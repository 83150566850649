/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import {
    Headers,
    Http,
    RequestMethod,
    RequestOptions,
    RequestOptionsArgs,
    Response,
    ResponseOptions,
    ResponseOptionsArgs,
    URLSearchParams
} from '@angular/http';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { NativeHttpResponse } from './native-http-response';
import { Inject, Injectable } from '@angular/core';
import {
    ApiAuthenticationConfig,
    authorizationHeaderName,
    Credentials,
    CredentialsRepository,
    originalHttpToken,
    UrlFactory
} from '../authentication';
import { Device } from 'ionic-native/dist/es5/plugins/device';
import { IHttpService } from 'angular';
import * as jwt_decode from 'jwt-decode';

declare const cordova: any;

/**
 * Minutes ahead that will be added to the current time to checking
 * and if it needs to, refreshing the current token
 */
const MINUTES_AHEAD = 5;

@Injectable()
export class NativeHttpInterceptor {
    /**
     * Flag for checking that Cordova is available.
     */
    public isCordova = false;

    /**
     * Request type enum
     */
    protected methodType = RequestMethod;

    /**
     * @var - variable to start token refreshing process
     */
    private isTokenValid: Boolean;

    private enableLog = false;

    constructor(
        @Inject(originalHttpToken) private http: Http,
        private requestOptions: RequestOptions,
        private urlFactory: UrlFactory,
        private credentialsRepository: CredentialsRepository,
        private config: ApiAuthenticationConfig,
        @Inject('$http') private $http: IHttpService
    ) {
        /**
         * Checking that Cordova is available, will be 'true' if platform is Android or iOS.
         */
        this.isCordova = typeof cordova !== 'undefined';
        this.isTokenValid = true;
    }

    /**
     * Method for checking if a token is expired
     * @private
     */

    private static checkIsTokenExpired(currentToken: string): boolean {
        const decoded: { exp: number; iat: number } = jwt_decode(currentToken) as { exp: number; iat: number };
        const dateForCheck = new Date(new Date().getTime() + MINUTES_AHEAD * 60000);
        return decoded.exp < dateForCheck.getTime() / 1000;
    }

    /**
     * Handling all request sent from Angular 2 and if Cordova available
     * convert all standard requests to the native HTTP request and return result
     *
     * @param url
     * @param options
     */

    /**
     * A wrapper around the Http 'get' method.
     *
     * @param url The url string.
     * @param options The request options.
     */
    public get(url: string, options?: RequestOptionsArgs): Observable<Response> {
        if (this.isCordova) {
            return Observable.create((observer: Observer<ResponseOptionsArgs>) =>
                this.intercept(this.methodType.Get, url, observer, undefined, options));
        }
        return this.handleRequest(() => this.http.get(url, options));
    }

    /**
     * A wrapper around the Http 'post' method.
     *
     * @param url The url string.
     * @param body The request body.
     * @param options The request options.
     */
    public post(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        if (this.isCordova) {
            return Observable.create((observer: Observer<ResponseOptionsArgs>) =>
                this.intercept(this.methodType.Post, url, observer, body, options));
        }
        return this.handleRequest(() => this.http.post(url, body, options));
    }

    /**
     * A wrapper around the Http 'put' method.
     *
     * @param url The url string.
     * @param body The request body.
     * @param options The request options.
     */
    public put(url: string, body: any, options?: RequestOptionsArgs): Observable<Response> {
        if (this.isCordova) {
            return Observable.create((observer: Observer<ResponseOptionsArgs>) =>
                this.intercept(this.methodType.Put, url, observer, body, options));
        }
        return this.handleRequest(() => this.http.put(url, body, options));
    }

    /**
     * A wrapper around the Http 'delete' method.
     *
     * @param url The url string.
     * @param options The request options.
     */
    public delete(url: string, options: RequestOptionsArgs): Observable<Response> {
        if (this.isCordova) {
            return Observable.create((observer: Observer<ResponseOptionsArgs>) =>
                this.intercept(this.methodType.Delete, url, observer, undefined, options));
        }
        return this.handleRequest(() => this.http.delete(url, options));
    }

    /**
     * A wrapper around the Http 'patch' method.
     *
     * @param url The url string.
     * @param body The request body.
     * @param options The request options.
     */
    public patch(url: string, body: any, options: RequestOptionsArgs): Observable<Response> {
        return this.handleRequest(() => this.http.patch(url, body, options));
    }

    /**
     * A wrapper around the Http 'head' method.
     *
     * @param url The url string.
     * @param options The request options.
     */
    public head(url: string, options: RequestOptionsArgs): Observable<Response> {
        return this.handleRequest(() => this.http.head(url, options));
    }

    /**
     * A wrapper around the Http 'options' method.
     *
     * @param url The url string.
     * @param options The request options.
     */
    public options(url: string, options: RequestOptionsArgs): Observable<Response> {
        return this.handleRequest(() => this.http.options(url, options));
    }

    /**
     * Performs the request and handles any response error logic.
     *
     * @param performRequest: The function that actually performs the request.
     */
    private handleRequest(performRequest: () => Observable<Response>): Observable<Response> {
        if (this.isTokenExpired()) {
            this.isTokenValid = false;
            const {url, body, headers} = this.prepareReAuthenticateData();
            return this.post(url, body, {headers})
                .timeout(20 * 1000)
                .do(response => {
                    this.isTokenValid = true;
                    const token = `Bearer ${response.json().access_token}`;
                    this.requestOptions.headers.set(authorizationHeaderName, token);
                    this.$http.defaults.headers.common[authorizationHeaderName] = token;
                })
                .flatMap(() => performRequest());
        }

        return performRequest();
    }

    /**
     * This method used for converting standard requests to the native HTTP requests,
     * we check the request type and all parameters and move them to the cordova.plugin.http,
     * also we add to each request callback functions for remapping response to the standard value.
     *
     * @param method
     * @param url
     * @param observer
     * @param requestOptions
     * @param body
     */

    private intercept(method: number, url: string, observer: Observer<ResponseOptionsArgs>, body: any, requestOptions?: RequestOptionsArgs): any {
        const params = {};
        const originalUrl = url;
        if (requestOptions && requestOptions.search && requestOptions.search instanceof URLSearchParams) {
            url = `${url}?${requestOptions.search.toString()}`;
        }

        if (body instanceof URLSearchParams) {
            const tempBody = {};
            const keys = body.paramsMap.keys();
            for (const key of keys) {
                tempBody[key] = body.paramsMap.get(key)[0];
            }
            body = tempBody;
        }

        if (body === '' || body === undefined) {
            body = {};
        }

        const headers = {};
        this.requestOptions.headers.keys().forEach((key: string) => headers[key] = this.requestOptions.headers.get(key));

        this.logInfo(`[ng2]  ${this.methodType[method]} -----> using native HTTP with:  ${encodeURI(url)}`);

        /**
         * Condition for changing request for images
         */

        if (url.includes('api/images/')) {
            const options = {
                headers,
                method: 'get',
                responseType: 'blob'
            };

            if (this.isTokenExpired()) {
                this.refreshToken(observer, () => this.get(originalUrl, requestOptions));
            } else {
                cordova.plugin.http.sendRequest(
                    url,
                    options,
                    (response: NativeHttpResponse) => this.reMapResponse(response, observer),
                    (error: any) => this.reMapError(error, observer));
            }
            return;
        }

        // eslint-disable-next-line default-case
        switch (method) {
            case this.methodType.Get:
                if (this.isTokenExpired()) {
                    this.refreshToken(observer, () => this.get(originalUrl, requestOptions));
                } else {
                    cordova.plugin.http.get(
                        url,
                        params,
                        headers,
                        (response: NativeHttpResponse) => this.reMapResponse(response, observer),
                        (error: any) => this.reMapError(error, observer));
                }
                break;
            case this.methodType.Post:
                if (this.isTokenExpired()) {
                    this.refreshToken(observer, () => this.post(originalUrl, body, requestOptions));
                } else {
                    cordova.plugin.http.post(
                        url,
                        body,
                        headers,
                        (response: NativeHttpResponse) => this.reMapResponse(response, observer),
                        (error: any) => this.reMapError(error, observer));
                }
                break;
            case this.methodType.Put:
                if (this.isTokenExpired()) {
                    this.refreshToken(observer, () => this.put(originalUrl, body, requestOptions));
                } else {
                    cordova.plugin.http.put(
                        url,
                        body,
                        headers,
                        (response: NativeHttpResponse) => this.reMapResponse(response, observer),
                        (error: any) => this.reMapError(error, observer));
                }
                break;
            case this.methodType.Delete:
                if (this.isTokenExpired()) {
                    this.refreshToken(observer, () => this.delete(originalUrl, requestOptions));
                } else {
                    cordova.plugin.http.delete(
                        url,
                        params,
                        headers,
                        (response: NativeHttpResponse) => this.reMapResponse(response, observer),
                        (error: any) => this.reMapError(error, observer)
                    );
                }
                break;
        }
    }

    /**
     * Methode for remapping response from native Http to standard response
     *
     * @param resp
     * @param observer
     * @private
     */

    private reMapResponse(resp: NativeHttpResponse, observer: Observer<Response>) {
        const options = new ResponseOptions({
            body: resp.data,
            status: resp.status,
            headers: this.generateHeaders(resp.headers),
            url: resp.url
        });
        const response = new Response(options);

        this.logInfo(`[ng2] response <----- ${resp.url}`, response);

        observer.next(response);
        observer.complete();
    }

    private generateHeaders(respHeaders: any): Headers {
        let headers = new Headers();
        Object.keys(respHeaders).forEach((key: string) => {
            headers.append(key, `${respHeaders[key]}`);
        });

        return headers;
    }

    /**
     * Methode for generating an error that got from request
     *
     * @param resp
     * @param observer
     * @private
     */

    private reMapError(resp: NativeHttpResponse, observer: Observer<Response>) {
        let error: any;

        try {
            error = JSON.parse(resp.error);
        } catch (e) {
            error = resp.error;
        }

        this.logInfo('[ng2] error <-----', resp);

        observer.error({error});
    }

    /**
     * Prepare all data needed for reauthenticate
     * @private
     */

    private prepareReAuthenticateData() {
        const credentials = this.credentialsRepository.credentials;
        const url = this.urlFactory.create({
            server: credentials.server,
            port: credentials.port,
            secureConnection: credentials.secureConnection,
            path: '/oauth2/token'
        });

        const body = this.getParameters(credentials);

        const headers = new Headers();
        headers.set('Content-Type', 'application/x-www-form-urlencoded');
        return {url, body, headers};
    }

    /**
     * Gets the parameters.
     *
     * @param credentials The credentials entered by the user.
     * @returns A URLSearchParams object that contains the parameters
     */
    private getParameters(credentials: Credentials): URLSearchParams {
        const params = new URLSearchParams();
        const pairs = this.getRequiredPairs(credentials).concat(this.getAdditionalPairs() || []);
        pairs.forEach(p => params.set(p[0], p[1]));
        return params;
    }

    /**
     * Gets the array of required key/value pairs.
     *
     * @param credentials The credentials entered by the user.
     * @returns The array of required key/value pairs.
     */
    private getRequiredPairs(credentials: Credentials): [string, string][] {
        return [
            ['grant_type', 'password'],
            ['username', credentials.username],
            ['password', credentials.password],
            ['client_id', this.config.clientId],
            ['client_secret', this.config.clientSecret]
        ];
    }

    private getAdditionalPairs(): [string, string][] {
        if (this.isCordova) {
            return [
                ['computer_name', Device.platform],
                ['computer_id', Device.uuid]
            ];
        }
        return [];
    }

    /**
     * @method - Method for checking current token expiration time
     */

    private isTokenExpired(): boolean {
        const currentToken = this.requestOptions.headers.get(authorizationHeaderName);
        return Boolean(currentToken && NativeHttpInterceptor.checkIsTokenExpired(currentToken) && this.isTokenValid);
    }

    /**
     * @method refreshToken - Method for refreshing token and creating new one if current token already expired
     * @param observer - observer that will receive data after the token will be refreshed
     * @param performFunction - the method that was blocked by the refresh token process and will be restored after
     * the token will be refreshed
     */

    private refreshToken(observer: Observer<Response>, performFunction: () => Observable<Response>) {
        this.isTokenValid = false;
        const {url, body, headers} = this.prepareReAuthenticateData();
        this.post(url, body, {headers})
            .do((response: Response) => {
                this.isTokenValid = true;
                const token = `Bearer ${response.json().access_token}`;
                this.requestOptions.headers.set(authorizationHeaderName, token);
                this.$http.defaults.headers.common[authorizationHeaderName] = token;
            })
            .flatMap(() => performFunction())
            .subscribe((result: Response) => {
                observer.next(result);
                observer.complete();
            });
    }

    private logInfo(message: string, data?: any): void {
        if (this.enableLog) {
            // eslint-disable-next-line no-console
            data ? console.log(message, data) : console.log(message);
        }
    }
}
