/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { Formatter } from './formatter';

/**
 * The default formatter that will be used as a last resort if none of the other
 * formatters apply. It can convert anything to a string.
 */
@Injectable()
export class DefaultFormatter implements Formatter<any> {

    /**
     * @inheritdoc
     */
    public canFormat(_message: any): _message is any {
        return true;
    }

    /**
     * @inheritdoc
     */
    public format(message: any): string {
        return JSON.stringify(message, undefined, 2);
    }
}
