/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IonicTabsDelegate = ionic.tabs.IonicTabsDelegate;
import IDirective = angular.IDirective;
import IScope = angular.IScope;

HeaderTabDirective.$inject = ['$ionicTabsDelegate'];

/**
 * A directive that displays a tab button in the header bar similar to ion-tab but as a
 * navigation bar button.
 */
export default function HeaderTabDirective($ionicTabsDelegate: IonicTabsDelegate): IDirective {
    return {
        restrict: 'E',
        scope: {
            title: '@',
            icon: '@',
            iconOn: '@',
            iconOff: '@',
            tabIndex: '@',  // index of the associated ion-tab
            uiSref: '@'
        },
        link: (scope: HeaderTab) => {
            scope.isActiveTab = function(index: string) {
                return +index === $ionicTabsDelegate.selectedIndex();
            };
        },
        template: require('./headerTab.html')
    };
}

/**
 * An interface that defines the scope for the HeaderTab directive.
 */
interface HeaderTab extends IScope {
    /**
     * Checks the given index to see if the associated ionic tab item is selected.
     */
    isActiveTab(index: string): boolean;
}
