/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AccidentDetailComponent } from './accident-detail.component';
import { AccidentNameDetailComponent } from './accident-name/accident-name-detail.component';
import { AccidentRoadwayDetailComponent } from './accident-roadway/accident-roadway-detail.component';
import { AccidentVehicleDetailComponent } from './accident-vehicle/accident-vehicle-detail.component';

@NgModule({
    imports: [SharedModule],
    declarations: [
        AccidentDetailComponent,
        AccidentNameDetailComponent,
        AccidentRoadwayDetailComponent,
        AccidentVehicleDetailComponent
    ]
})
export class AccidentModule { }
