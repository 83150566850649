/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';

/**
 * A component that knows how to display an array of alerts.
 */
@Component({
    selector: 'sds-alerts-display',
    template: require('./alerts-display.component.html'),
    styles: [
        require('./alerts-display.component.day.scss'),
        require('./alerts-display.component.night.scss')
    ]
})
export class AlertsDisplayComponent<T> {

    /**
     * The promise that returns the alerts.
     */
    @Input() public alerts: Promise<T[]>;
}
