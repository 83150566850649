/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

enum FilterOption {
    none,
    rename,
    save_as,
    delete
}

class FilterOptionObject {
    public title: String;
    public type: FilterOption;

    constructor(title: String, type: FilterOption) {
        this.title = title;
        this.type = type;
    }
}

export {FilterOption, FilterOptionObject};
