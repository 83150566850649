/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceArray = angular.resource.IResourceArray;
import GeneralIncident from '../../../../schema/interfaces/GeneralIncident';
import ResourceUtils from '../../../../resource/ResourceUtils';
import LawFireEmsAPI from '../../../../api/LawFireEmsAPI';
import CallQuery from './CallQuery';

/**
 * Requests the Incident History, which consists of all law, fire, and EMS incidents
 * that occurred at the same address as the call.
 */
export default class IncidentHistoryCallQuery extends CallQuery<IResourceArray<GeneralIncident<any>>> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['lawFireEmsAPI', 'resourceUtils'];

    /**
     * Constructs a new instance of the IncidentHistoryQuery class.
     *
     * @param lawFireEmsAPI The API for retrieving all incidents (law, fire, and EMS) from the server.
     * @param resourceUtils Provides utility methods for working with the Angular $resource service.
     */
    constructor(private lawFireEmsAPI: LawFireEmsAPI, private resourceUtils: ResourceUtils) {
        super();
    }

    // @Override
    protected get watchPropertyNames(): string | string[] {
        return 'geoid';
    }

    // @Override
    protected get updatePropertyName(): string {
        return 'incidentHistory';
    }

    // @Override
    protected get defaultValue(): IResourceArray<GeneralIncident<any>> {
        this.lawFireEmsAPI.disableInfinityScroll();
        return this.resourceUtils.createResourceArray<GeneralIncident<any>>();
    }

    // @Override
    protected performQuery(newValues: any[], isRegistration?: boolean): IResourceArray<GeneralIncident<any>> {
        return this.lawFireEmsAPI.query({ filter: `geoaddr=${newValues[0]}` }, true, isRegistration);
    }
}
