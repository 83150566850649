/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../interfaces/address';
import { JoinPipe } from './join.pipe';

/**
 * A pipe that formats an address.
 *
 * Usage:
 *   addressType | sdsAddress
 *
 * Example:
 *   {{ { street: 'my street', city: 'city', state: 'XX', zip: '11111' } |  sdsAddress }}
 *   outputs: My Street, City, XX 11111
 */
@Pipe({
    name: 'sdsAddress'
})
export class AddressPipe implements PipeTransform {

    /**
     * The default options that will be used if not overridden.
     */
    private defaultOptions: AddressPipeOptions = Object.freeze({
        streetCitySeparator: ', ',
        cityStateSeparator: ', ',
        stateZipSeparator: ' '
    });

    /**
     * Constructs a new instance of the AddressPipe class.
     *
     * @param joinPipe A pipe that joins the non-empty items of a string array with the specified separator.
     */
    constructor(private joinPipe: JoinPipe) {
    }

    /**
     * Transforms an address by formatting street, city, state, and zip.
     *
     * @param address the address to format.
     * @param options The configurable options that control how the address is formatted.
     */
    public transform(address: Address, options?: AddressPipeOptions): string {
        const finalOptions = options ? Object.assign({}, this.defaultOptions, options) : this.defaultOptions;
        let fullAddr: string;

        fullAddr = this.joinPipe.transform([address.street, address.city], finalOptions.streetCitySeparator);

        // Can't use css to capitalize the street and city since the whole address
        //  is being formatted at once so do the capitalization in code.
        fullAddr = fullAddr.toLowerCase();
        fullAddr = fullAddr.replace(/\b\w/g, firstLetter => firstLetter.toUpperCase());

        fullAddr = this.joinPipe.transform([fullAddr, address.state], finalOptions.cityStateSeparator);
        fullAddr = this.joinPipe.transform([fullAddr, address.zip], finalOptions.stateZipSeparator);

        return fullAddr;
    }
}

/**
 * An interface that defines the configurable options for the AddressPipe.
 */
export interface AddressPipeOptions {

    /**
     * The separator to place between the street address and the city.
     */
    streetCitySeparator?: string;

    /**
     * The separator to place between the city and the state.
     */
    cityStateSeparator?: string;

    /**
     * The separator to place between the state and the zip code.
     */
    stateZipSeparator?: string;
}
