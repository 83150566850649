/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IonicHistoryService = ionic.navigation.IonicHistoryService;
import IonicPopupService = ionic.popup.IonicPopupService;
import { SpinnerDialog } from 'ionic-native/dist/es5/plugins/spinnerdialog';
import LoginScope from './LoginScope';
import CredentialsStorage from './CredentialsStorage';
import { AuthenticationService } from './authentication.service';
import { SpillmanLocalStorage } from '../shared/storage/spillman-local-storage';
import Credentials from './Credentials';
import { PlatformDetector } from '../conditional-injection/platform-detection/platform-detector.service';
import { HomeStateProvider } from '../history/state-history/home-state-provider.service';
import { PushNotificationService } from './push-notification/push-notification.service';

import * as angular from 'angular';
import IStateService = angular.ui.IStateService;
import { setFontSizeToStorage } from '../fontSize/font-size-setter-helpers';
import { ApiPasswordExpirationService } from '../../app/authentication/api-password-expiration.service';
import { passwordExpirationMessages, accountStateMessages } from '../../app/authentication/api-password-expiration.constants';
import { logEvent } from '../../app/api/analytics/firebase-analytics-service';
import { LOGIN_FAILED, LOGIN_FAILED_TYPES, LOGIN_SUCCESS, UNSECURE_LOGIN_USED, BACKEND_VERSION } from './analytics/events';
import { fetchAndActivate } from '../api/analytics/firebase-remote-config-service';
import { VersionService } from 'app/conditional-injection';
import { logIfLoginIsVerbose, logErrorIfLoginIsVerbose } from '../../app/api/analytics/firebase-crashlytics-service';
import { AUTH_EVENTS } from './authentication/events/authentication-events';

/**
 * The controller for the Login page.
 */
export default class LoginController {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = [
        'spillmanLocalStorage',
        '$scope',
        '$state',
        '$ionicHistory',
        '$ionicPopup',
        'ionic',
        'authenticationService',
        'credentialsStorage',
        'platformDetector',
        'homeStateProvider',
        '$cordovaSpinnerDialog',
        'pushNotificationService',
        'apiPasswordExpirationService',
        '$injector',
        'versionService'
    ];

    /**
     * Constructs a new instance of the LoginController class.
     *
     * @param spillmanLocalStorage The object that handles storing Spillman-specific data in local storage.
     * @param $scope The Angular scope object that provides data to the view.
     * @param $state The service that transitions between states.
     * @param $ionicHistory The service that manages the user's browsing history.
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param ionic The ionic object that contains information about the current platform.
     * @param authenticationService The service that handles authenticating the user with the Spillman server.
     * @param credentialsStorage The service that handles persistent storage of the user's credentials.
     * @param platformDetector The object that detects the current platform.
     * @param homeStateProvider determines what the user's home state should be.
     * @param pushNotificationService The service that setup Firebase connection and initialize the handler of available version update
     * @param $cordovaSpinnerDialog Shows or hides a native spinner dialog.
     * @param apiPasswordExpirationService The service that manage user password data.
     */
    constructor(
        spillmanLocalStorage: SpillmanLocalStorage,
        $scope: LoginScope,
        $state: IStateService,
        $ionicHistory: IonicHistoryService,
        $ionicPopup: IonicPopupService,
        ionic: IonicStatic,
        authenticationService: AuthenticationService,
        credentialsStorage: CredentialsStorage,
        platformDetector: PlatformDetector,
        homeStateProvider: HomeStateProvider,
        $cordovaSpinnerDialog: typeof SpinnerDialog,
        pushNotificationService: PushNotificationService,
        apiPasswordExpirationService: ApiPasswordExpirationService,
        $injector: angular.auto.IInjectorService,
        versionService: VersionService
    ) {
        // As soon as the view is loaded and the platform is ready
        $scope.$on('$ionicView.loaded', () => {
            // Recheck the orientation.  This fixes the bug on iOS where the app doesn't recognize landscape mode on startup.
            ionic.Platform.ready(() => {
                if (platformDetector.getPlatform() !== 'browser') {
                    $cordovaSpinnerDialog.hide();
                }
            });

            // fetch the values for Remote Config
            fetchAndActivate();

            // Define login screen view depending on secure/unsecure mode
            $scope.defineLoginScreenView();

            // Set font size according to credentials.username setting by default medium
            setFontSizeToStorage($scope.credentials.username, spillmanLocalStorage, $injector);

            // Clears the history preventing the user from using the device's native back button.
            $ionicHistory.clearHistory();
        });

        /**
         * Stores the saved credentials. Will be empty if there are no saved credentials.
         */
        $scope.credentials = credentialsStorage.retrieve();

        /**
         * Set login params for appropriate login mode
         * @param isSecureLoginMode define login screen mode
         */
        $scope.setLoginParams = function (isSecureLoginMode: boolean) {
            $scope.credentials.port = isSecureLoginMode ? '4443' : '4080';
            $scope.credentials.secureConnection = isSecureLoginMode;
        };

        $scope.$watch(() => {
            return $scope.credentials.secureConnection;
        }, (newVal: any, oldVal: any) => {
            if (newVal !== oldVal) {
                $scope.setLoginParams($scope.credentials.secureConnection);
            }
        });

        /**
         * If using a browser populate the server and port to match the URL to help prevent
         * Mixed Content errors.
         */
        if (platformDetector.getPlatform() === 'browser' && !$scope.credentials.remember) {
            $scope.credentials.server = window.location.hostname;
            $scope.credentials.port = window.location.port;
            $scope.credentials.secureConnection = window.location.protocol === 'https:';
        } else {
            if (!$scope.credentials.remember) {
                /**
                 * If using mobile platform use secure connection by default and use secure port 4443
                 */
                $scope.setLoginParams(true);
            }
        }

        /**
         * Toggles the showMoreInformation flag.
         */
        $scope.doShowMoreInformation = function () {
            $scope.showMoreInformation = !$scope.showMoreInformation;
        };

        /**
         * A flag which determines if the user had their session expire.
         */
        $scope.sessionTimeout = spillmanLocalStorage.getObject({ key: 'sessionTimeout' });

        /**
         * Remove the sessionTimeout flag from local storage.
         */
        spillmanLocalStorage.remove({ key: 'sessionTimeout' });

        /**
         * Attempts to log the user into the Spillman system.
         *
         * @param credentials The information used to authenticate the user.
         */
        $scope.login = function (credentials: Credentials) {
            if (credentials.secureConnection === undefined) {
                credentials.secureConnection = false;
            }

            authenticationService.login(credentials)

                // Success!
                .then(() => {
                    logEvent(AUTH_EVENTS.authenticationService_complete);
                    logIfLoginIsVerbose('successfully authenticated!');

                    // Initialize the handler of available version update
                    try {
                        logEvent(AUTH_EVENTS.available_version_update_init);

                        pushNotificationService.initializeAvailableVersionUpdate();
                        logIfLoginIsVerbose('initialized version update notification!');
                    } catch (e){
                        logEvent(AUTH_EVENTS.available_version_update_fail);

                        logErrorIfLoginIsVerbose('Login authentication', e);
                    }

                    logEvent(AUTH_EVENTS.credentials_update_init);
                    credentialsStorage.update(credentials);
                    logEvent(AUTH_EVENTS.credentials_update_success);

                    logIfLoginIsVerbose('credentials updated!');

                    // In order to prevent the user from being able to navigate back to the login screen,
                    // we need to set the home screen as a 'historyRoot'.
                    $ionicHistory.nextViewOptions({ historyRoot: true });
                    $state.go(homeStateProvider.getHomeState());
                    logIfLoginIsVerbose('navigated to home!');
                    logEvent(AUTH_EVENTS.navigate_to_home);

                    // Set font size according to login.username setting by default medium
                    setFontSizeToStorage(credentials.username, spillmanLocalStorage, $injector);

                    logEvent(LOGIN_SUCCESS);

                    logEvent(`${BACKEND_VERSION}_${versionService.serverVersion.raw.replace(/\./g, '_')}`);

                    if (!credentials.secureConnection) {
                        logEvent(UNSECURE_LOGIN_USED);
                    }
                })

                // Failure
                .catch(errorTemplate => {
                    // Added checking for type of errorTemplate, this solution needed for working with native HTTP
                    // to displaying response errors
                    logErrorIfLoginIsVerbose('Login authentication', errorTemplate);

                    if (authenticationService.isVersionServiceError) {
                        $scope.collectFailedLoginsCount();
                    }
                    const failedLoginsCount = $scope.getFailedLoginsCount();

                    const isAccountError = errorTemplate ? errorTemplate.includes('password expired') || errorTemplate.includes('account locked') : false;

                    const defaultMessage = 'Login Failed';
                    let options = {
                        title: '',
                        template: errorTemplate,
                        buttons: <any>[
                            {
                                text: 'OK',
                                type: 'button-positive'
                            }
                        ],
                        cssClass: ''
                    };

                    // Define error message
                    switch (true) {
                        case options.template && options.template.includes('account locked'):
                            options.template = accountStateMessages.accountLocked;
                            options.title = defaultMessage;
                            logEvent(LOGIN_FAILED, { TYPE_KEY: LOGIN_FAILED_TYPES.account_locked });

                            break;
                        case options.template && options.template.includes('password expired'):
                            options.template = passwordExpirationMessages.passwordExpired;
                            options.title = defaultMessage;
                            options.buttons.push({
                                text: 'Change Password',
                                type: 'button-positive',
                                onTap: () => {
                                    apiPasswordExpirationService.onChangePassword(credentials);
                                }
                            });
                            logEvent(LOGIN_FAILED, { TYPE_KEY: LOGIN_FAILED_TYPES.expired_password });

                            break;
                        default:
                            options.template = defaultMessage;
                            logEvent(LOGIN_FAILED, { TYPE_KEY: LOGIN_FAILED_TYPES.unknown });

                            break;
                    }

                    // In case of more than 2 failed logins for Web due to version service error
                    // enable possibility to use unsecure connection
                    // Develop Mode: to reduce login attemts replace with 0
                    // For Mobile platforms failed logins reduced to 0 with condition,
                    // that user put manually port '4080'

                    let failedLoginsForPlatform = (platformDetector.getPlatform() === 'browser') ? 2 : 0;

                    if (failedLoginsCount > failedLoginsForPlatform && !isAccountError) {
                        options.title = 'Touch cannot connect with the Flex server';
                        options.template = 'Please notify the administrator, so this can be corrected.';
                        if (platformDetector.getPlatform() !== 'browser' && $scope.credentials.port !== '4080') {
                            // Automatic failover attempt count configuration for mobile platforms
                            $scope.isSecureLoginMode = (failedLoginsCount < 3);
                        } else {
                            $scope.isSecureLoginMode = false;
                        }
                        $scope.setLoginParams($scope.isSecureLoginMode);
                        return $ionicPopup.alert(options);
                    } else {
                        $scope.isSecureLoginMode = true;
                    }

                    if (typeof options.template === 'object' && options.template.hasOwnProperty('error')) {
                        return $ionicPopup.alert({ template: options.template.error.message });
                    }

                    return $ionicPopup.alert(options);
                });
        };

        /**
         * Counting of failed login attempts and writing it to LocalStorage
         * * @param init define load of login screen
         */
        $scope.collectFailedLoginsCount = function (init?: boolean) {
            const storedFailedLoginsCount = $scope.getFailedLoginsCount();
            const actualFailedLoginsCount = init ? 0 : storedFailedLoginsCount + 1;

            spillmanLocalStorage.setObject({ key: 'failedLoginsCount' }, actualFailedLoginsCount);
        };

        /**
         * Get failed login attempts from LocalStorage
         */
        $scope.getFailedLoginsCount = function (): number {
            return Number(spillmanLocalStorage.getObject({ key: 'failedLoginsCount' })) || 0;
        };

        /**
         * Define login screen view, depends on failed login attempt count
         */
        $scope.defineLoginScreenView = function () {
            $scope.collectFailedLoginsCount(true);
            $scope.isSecureLoginMode = $scope.getFailedLoginsCount() < 3;
            if ($scope.isSecureLoginMode) {
                $scope.setLoginParams(true);
            }
        };
    }
}
