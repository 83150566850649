/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { CookieCleaner } from './cookie-cleaner';
import { injectionCondition } from '../../conditional-injection/injection-condition.decorator';
import { Credentials } from '../Credentials';

/**
 * Deletes all session cookies when using OAuth Authentication.
 */
@Injectable()
@injectionCondition({ minServerVersion: '1701' })
export class OauthAuthenticationCookieCleaner implements CookieCleaner {

    /**
     * @inheritdoc
     */
    public deleteCookies(_credentials?: Credentials): Promise<void> {
        // There actually are no cookies to delete when using OAuth authentication.
        // So simply return a resolved promise.
        return Promise.resolve();
    }
}
