/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * An offender model
 */
@table('somain')
export class Offender {

    @field('begdate')
    public beginningDate: Date;

    @field('enddate')
    public endingDate: Date;

    @expand('risklvl', 'descrpt')
    public riskLevel: string;

    @expand('type', 'descrpt')
    public type: string;

    @expand('dispos', 'descrpt')
    public disposition: string;
}
