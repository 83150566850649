/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { PropertyNode } from './property-node';
import { ClassNode } from '../class/class-node';
import { TreeNode } from '../../../../data-structures/tree-node';

/**
 * A node that associates the involvementsAlerts with a property of a JavaScript class.
 */
export class InvolvementsAlertsNode extends PropertyNode {

    /**
     * Constructs a new instance of the InvolvementsAlertsNode class.
     *
     * @param parent The parent ClassNode.
     * @param propertyKey The property key (or name if you prefer).
     */
    constructor(parent: ClassNode, propertyKey: string | symbol) {
        super(parent, propertyKey);
    }

    /**
     * @inheritdoc
     */
    protected *initializeChildren(): Iterable<TreeNode> {
        // No children
    }
}
