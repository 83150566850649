/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import DateTransform from '../../../../shared/transforms/DateTransform';
import AggregatorCadCall from '../../../../schema/AggregatorCadCall';

/**
 * A transform that parses string properties of a CAD Call and converts each one to a Date.
 */
export default class CallDateTransform extends DateTransform<AggregatorCadCall> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['serverDateTimeFormat'];

    /**
     * Constructs a new instance of the CallDateTransform class.
     *
     * @param serverDateTimeFormat The format in which dates are sent from the server.
     */
    constructor(serverDateTimeFormat: string) {
        super(serverDateTimeFormat, ['reported', 'statusTime']);
    }
}
