/* Copyright © 2021 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IRootScopeService = angular.IRootScopeService;
import SettingKey from '../SettingKey';
import Setting from '../Setting';
import SettingStorage from '../SettingStorage';
import Option from '../Option';

/**
 * The setting that determines how many rows of comments
 * will be displayed at the call details screen
 */
export default class CommentRowsCountSetting extends Setting<Number> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$q', 'settingStorage', '$rootScope'];

    /**
     * Constructs a new instance of the CommentRowsCountSetting class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param settingStorage The object that handles persistent storage of the setting value.
     * @param $rootScope The Angular $rootScope that can be used to broadcast events throughout the app.
     */
    constructor($q: IQService, settingStorage: SettingStorage, $rootScope: IRootScopeService) {
        super($q, settingStorage, $rootScope, SettingKey.commentRowsCountSetting, 'Comment Rows Count', [
            new Option<Number>('2 Rows', 2),
            new Option<Number>('4 Rows', 4),
            new Option<Number>('6 Rows', 6),
            new Option<Number>('8 Rows', 8),
            new Option<Number>('10 Rows', 10),
            new Option<Number>('12 Rows', 12)
        ], 0);
    }
}
