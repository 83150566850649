/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * The Weapon model
 */
@table('lpweapon')
export class Weapon {

    @field('number')
    public id: string;

    @field('color')
    public color: string;

    @field('value')
    public value: number;

    @field('make')
    public make: string;

    @field('model')
    public model: string;

    @expand('type', 'desc')
    public type: string;

    @field('caliber')
    public caliber: string;

    @field('barrel')
    public barrel: string;

    @field('action')
    public action: string;

    @field('serial')
    public serialNumber: string;

    @field('comment')
    public comment: string;
}
