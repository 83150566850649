/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { RequestArgs } from '@angular/http/src/interfaces';
import { RequestCreator } from '../../../api/request-creator.service';
import { BatchRequestConfig } from '../../../api/batch-request/batch-request-config';
import { UrlFactory } from '../../../authentication';

/**
 * Creates the request for the OffenderTrackingVehicleCommentsBatchRequest service.
 * The request will load the comments for an entire list of offender tracking vehicles.
 */
@Injectable()
export class OffenderTrackingVehicleCommentsRequestCreator extends RequestCreator<BatchRequestConfig> {

    /**
     * Constructs a new instance of the OffenderTrackingVehicleCommentsRequestCreator class.
     *
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     */
    constructor(urlFactory: UrlFactory) {
        super(urlFactory);
    }

    /**
     * @inheritdoc
     */
    public create(config: BatchRequestConfig): RequestArgs {
        const url = this.urlFactory.create({
            path: '/tables/sovehcm'
        });

        const search = new URLSearchParams();
        search.set('filter', `num=${config.delimitedKeys}`);

        return { url, search };
    }
}
