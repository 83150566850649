/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import UnitQuery from './UnitQuery';
import GeoCoordinates from '../../../../shared/mapping/GeoCoordinates';
import UnitResourceClass from '../../../../api/unit/UnitResourceClass';

/**
 * Requests the Unit's latitude and longitude.
 */
export default class GeoCoordinatesUnitQuery extends UnitQuery<GeoCoordinates> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['unitAPI'];

    /**
     * Constructs a new instance of the GeoCoordinatesUnitQuery class.
     *
     * @param unitAPI The API for retrieving CAD Units from the server.
     */
    constructor(private unitAPI: UnitResourceClass) {
        super();
    }

    // @Override
    protected get watchPropertyNames(): string | string[] {
        return 'unit';
    }

    // @Override
    protected get updatePropertyName(): string {
        return 'geoCoordinates';
    }

    // @Override
    protected get defaultValue(): GeoCoordinates {
        return undefined;
    }

    // @Override
    protected performQuery(newValues: any[]): GeoCoordinates {
        return this.unitAPI.getCoordinates({ id: newValues[0] });
    }
}
