/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injector, Injectable, Inject, TypeProvider } from '@angular/core';
import { ConditionalInjectionFilter } from './filters/conditional-injection-filter';
import { conditionalInjectionMapToken } from './conditional-injection-map';
import { intersect } from '../shared/utilities/set-utilities';

/**
 * The set of error messages that may be thrown by the ConditionalServiceLoader.
 */
export const errorMessages = {
    invalidToken: 'The token cannot be null or undefined',
    noServicesForToken: 'There are no services associated with the given token',
    noService: 'There is no service that meets the current conditions',
    multipleServices: 'There are multiple services that meet the current conditions'
};

/**
 * A service that knows how to load (at runtime) other services based on the current conditions (platform, server version, etc).
 * The other services must have already been registered with an `NgModule` using the `provideBasedOnCondition` function.
 *
 * For example:
 *
 * @NgModule({
 *   providers: [
 *     provideBasedOnCondition(
 *       myToken,
 *       Service1,
 *       Service2,
 *       Service3
 *     )
 *   ]
 * })
 *
 * NOTE: This service should only be used on the login screen or by services used on the login screen,
 * since at that point the version is unknown. On any other screen, the version will already be known
 * and in that case you can just rely on standard dependency injection (see the @NgModule example above).
 */
@Injectable()
export class ConditionalServiceLoader {

    /**
     * Constructs a new instance of the ConditionalServiceLoader class.
     *
     * @param injector The dependency injector provided by the Angular framework.
     * @param tokenMap The map that maps the public token to the private token.
     * @param filters An array of filters that will determine which service is ultimately returned.
     */
    constructor(
        private injector: Injector,
        @Inject(conditionalInjectionMapToken) private tokenMap: Map<any, TypeProvider[]>,
        @Inject(ConditionalInjectionFilter) private filters: ConditionalInjectionFilter<any, any>[]
    ) {
    }

    /**
     * Loads the correct service that corresponds to the given dependency injection token
     * and the current conditions.
     *
     * @param token The dependency injection token that determines which set of services to consider.
     * @returns The one and only service that corresponds to the given token and satisfies the current conditions.
     */
    public loadService(token: any): any {
        if (!token) {
            throw new Error(errorMessages.invalidToken);
        }

        const serviceTypes = this.tokenMap.get(token);

        if (!serviceTypes) {
            throw new Error(errorMessages.noServicesForToken);
        }

        const sets = this.filters.map(f => new Set(f.filter(serviceTypes)));
        const intersection = intersect(...sets);

        if (intersection.size < 1) {
            throw new Error(errorMessages.noService);
        }

        if (intersection.size > 1) {
            throw new Error(errorMessages.multipleServices);
        }

        return this.injector.get([...intersection][0]);
    }
}
