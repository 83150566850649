/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { Involvement } from '../../../schema/Involvement';
import { SuspectModusOperandi } from './suspect-modus-operandi';
import { SuspectScarMarkTattoo } from './suspect-scar-mark-tattoo';

/**
 * The suspect-name model
 */
@table('nmspmain')
export class SuspectName {

    @field('age1')
    public age1: number;

    @field('age2')
    public age2: number;

    @expand('area', 'desc')
    public area: string;

    @expand('facial', 'desc')
    public beard: string;

    @expand('build', 'desc')
    public build: string;

    @includeOne('nmspdesc', 'text')
    public comments: string;

    @expand('complx', 'desc')
    public complexion: string;

    @expand('ethnic', 'desc')
    public ethnic: string;

    @expand('eyes', 'desc')
    public eyes: string;

    @field('first')
    public firstName: string;

    @expand('glasses', 'desc')
    public glasses: string;

    @expand('hair', 'desc')
    public hair: string;

    @expand('hairsty', 'desc')
    public hairStyle: string;

    @field('height1')
    public height1: string;

    @field('height2')
    public height2: string;

    @field('last')
    public lastName: string;

    @includeMany('nmspmodo', SuspectModusOperandi)
    public modusOperandi: SuspectModusOperandi[];

    @expand('race', 'desc')
    public race: string;

    @includeMany('nmspsmt', SuspectScarMarkTattoo)
    public scarsMarksTattoos: SuspectScarMarkTattoo[];

    @field('sex')
    public sex: string;

    @expand('speech', 'desc')
    public speech: string;

    @expand('teeth', 'desc')
    public teeth: string;

    @field('weight1')
    public weight1: number;

    @field('weight2')
    public weight2: number;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
