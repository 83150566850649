/* Copyright © 2021 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import { SortingMap, SearchParams } from './call-history-filtering-sorting.interface';

export class CallHistoryAdvancedFilterService {
    public static $inject = ['incidentAPI', 'fireIncidentAPI', 'emsIncidentAPI'];
    public lawIncidentApi: IResourceClass<any>;
    public fireIncidentApi: IResourceClass<any>;
    public emsIncidentApi: IResourceClass<any>;
    public searchParams: SearchParams;
    public sortingMap: SortingMap;
    public incidentsLocation: string;
    public isSpinnerShown = false;
    public sortParameters: Object;
    private filterParameters: Object;

    constructor(
        public incidentAPI: IResourceClass<any>,
        public fireIncidentAPI: IResourceClass<any>,
        public emsIncidentAPI: IResourceClass<any>
    ) {
        this.lawIncidentApi = incidentAPI;
        this.fireIncidentApi = fireIncidentAPI;
        this.emsIncidentApi = emsIncidentAPI;
        this.sortingMap = <any>{};
        this.searchParams = <any>{};
        this.filterParameters = <any>{};
        this.sortParameters = <any>{};
    }

    /**
     * Request to appropriate incident API with filter params
     * @param incidentType type of incident
     * @param filter filter params
     */
    public onSearch = (incidentType: string, filter: string) => {
        const incidentApi = incidentType.toLowerCase() + 'IncidentApi';
        this.filterParameters[incidentType] = filter;
        return this[incidentApi].callQuery({ filter, sort: this.sortParameters[incidentType] }).$promise;
    };

    /**
     * Request to appropriate incident API with sort params
     * @param incidentType type of incident
     */
    public onSort = (incidentType: string) => {
        const incidentApi = incidentType.toLowerCase() + 'IncidentApi';
        this.filterParameters[incidentType] = this.filterParameters[incidentType] || `geoaddr=${this.incidentsLocation}`;
        return this[incidentApi].callQuery({ sort: this.sortParameters[incidentType], filter: this.filterParameters[incidentType] }).$promise;
    };
}
