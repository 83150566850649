/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * An enumeration of the possible CSS font sizes.
 */
enum FontSize {
    /**
     * Small font size.
     */
    small,
    /**
     * Medium font size.
     */
    medium,

    /**
     * Large font size.
     */
    large
}

export default FontSize;
