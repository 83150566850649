/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import { RequestOptions } from '@angular/http';
import { injectionCondition } from '../../../../conditional-injection';
import { FileDownloader } from './file-downloader';
import { UrlFactory } from '../../../../authentication';
import { FileMetadata } from '../../../../schema/FileMetadata';
import FileUtilities from '../../../../shared/fileManagement/FileUtilities';
import DirectoryFinder from '../../../../shared/fileManagement/DirectoryFinder';
import { ReAuthenticationService, Credentials, authorizationHeaderName } from '../../../../authentication';
import { Logger } from '../../../../logging';
declare const cordova: any;
/**
 * The error message that will be displayed to the user if anything goes wrong.
 */
export const errorMessage = 'Unable to open file.\nYou may not have an app installed to open a file of that type.';

/**
 * Downloads a file from the server when run in the cordova environment.
 */
@Injectable()
@injectionCondition({ platforms: ['android', 'ios'] })
export class CordovaFileDownloader extends FileDownloader {

    /**
     * The local path of the file.
     */
    private localPath: string;

    /**
     * The url from which to retrieve the file.
     */
    private url: string;

    /**
     * Constructs a new instance of the CordovaFileDownloader class.
     *
     * @param urlFactory Creates a fully-qualified URL based on the server and port entered by the user.
     * @param fileUtilities A class that provides utility methods for working with files.
     * @param documentsDirectoryFinder Finds the Documents directory on the current platform.
     * @param requestOptions The request options that get passed to every http call made in Angular 2.
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param reAuthenticationService Handles re-authenticating the user with the server.
     * @param logger Logs errors to the appropriate location based on the environment.
     * @param $ionicLoading An overlay that displays a spinner while waiting for the incidents to be retrieved.
     */
    constructor(
        urlFactory: UrlFactory,
        @Inject('fileUtilities') private fileUtilities: FileUtilities,
        @Inject('documentsDirectoryFinder') private documentsDirectoryFinder: DirectoryFinder,
        private requestOptions: RequestOptions,
        @Inject('$ionicPopup') private $ionicPopup: ionic.popup.IonicPopupService,
        private reAuthenticationService: ReAuthenticationService<Credentials>,
        private logger: Logger,
        @Inject('$ionicLoading') private $ionicLoading: ionic.loading.IonicLoadingService
    ) {
        super(urlFactory);
    }

    /**
     * @inheritdoc
     */
    protected downloadFromUrl(url: string, file: FileMetadata): void {
        const downloadSafeFileName = this.fileUtilities.makeDownloadSafe(file.name);
        this.url = url;

        // Find the directory in which the file will be downloaded.
        this.documentsDirectoryFinder.findDirectory()
            .then(directory => {

                // Create the local path
                this.localPath = directory + downloadSafeFileName;

                // Download the file.
                return this.cordovaDownload();
            })
            .catch(error => this.retryDownload(error));
    }

    /**
     * Logs the fact that the file was successfully opened.
     *
     * @param fileName The name of the file that was opened.
     */
    private success = (fileName: string): void => {
        this.logger.debug('Successfully opened ' + fileName);
    };

    /**
     * Handles an error by logging it and then displaying an alert to the user.
     *
     * @param error The error which will be logged.
     */
    private handleError = (error: any): void => {
        this.$ionicLoading.hide();
        this.logger.error(error);
        this.$ionicPopup.alert({ template: errorMessage });
    };

    /**
     * Helper function which downloads and opens the file using cordova.
     */
    private cordovaDownload = (): Promise<any> => {
        this.$ionicLoading.show({ template: '<ion-spinner></ion-spinner>' });
        return cordova.plugin.http.downloadFile(this.url,
            {},
            {Authorization: this.requestOptions.headers.get(authorizationHeaderName)},
            this.localPath, (file: any) => {
                if (file) {
                    this.$ionicLoading.hide();
                    cordova.plugins.disusered.open(file.nativeURL, () => this.success(file.name), this.handleError, true);
                }
            }, this.handleError);

    };

    /**
     * Re-Authenticates and retries the download should a 401 status be received.
     *
     * @param error The error which will be logged.
     */
    private retryDownload(error: any): void {
        if (error.http_status === 401) {
            this.reAuthenticationService.reAuthenticate()
                .toPromise()
                .then(this.cordovaDownload);
        } else {
            this.handleError(error);
        }
    }
}
