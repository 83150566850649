/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { DefaultUrlConfig, CredentialsRepository } from '../../authentication';

/**
 * The default configuration for Touch 2.0 used by the UrlFactory.
 */
@Injectable()
export class Touch2DefaultUrlConfig extends DefaultUrlConfig {

    /**
     * @inheritdoc
     */
    public baseApiUrl = 'touch2/api';

    /**
     * Constructors a new instance of the Touch2DefaultUrlConfig class.
     *
     * @param credentialsRepository The repository that stores the credentials.
     */
    constructor(credentialsRepository: CredentialsRepository) {
        super(credentialsRepository);
    }
}
