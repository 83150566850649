/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as moment from 'moment';
import Name from '../../../schema/Name';
import DecoratorTransform from '../../../shared/transforms/DecoratorTransform';

/**
 * A decorator transform that will add the `age` property to the Name.
 */
export default class NameAgeDecoratorTransform implements DecoratorTransform<Name> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['serverDateTimeFormat'];

    /**
     * Constructs a new instance of the NameAgeDecoratorTransform class.
     *
     * @param serverDateTimeFormat The constant that defines the format in which dates are sent by the server.
     */
    constructor(private serverDateTimeFormat: string) {
    }

    // @Override
    public invoke(name: Name): void {
        let birthDateString = name.birthd;
        if (birthDateString && typeof birthDateString === 'string') {
            let birthDate = moment(birthDateString, this.serverDateTimeFormat);
            name.age = moment().diff(birthDate, 'years');
        }
    }
}
