/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import { Logger } from './logger';
import { LoggerConfig } from './logger-config.service';
import { injectionCondition } from '../conditional-injection';
import { Formatter, formatterArrayToken } from './formatters';

/**
 * Logs error to the console when the app is run in development mode.
 */
@Injectable()
@injectionCondition({ environment: 'development' })
export class DevelopmentLogger extends Logger {

    /**
     * Constructs a new instance of the DevelopmentLogger class.
     *
     * @param config The configuration that determines which logging levels will actually be logged.
     * @param formatters The array of formatters that will convert the message to a human-friendly string.
     */
    constructor(config: LoggerConfig, @Inject(formatterArrayToken) formatters: Formatter<any>[]) {
        super(config, formatters);
    }

    /**
     * @inheritdoc
     */
    protected performLogging(level: string, message: string): void {
        // This is the only place in the entire application where the `console` should be used.
        // eslint-disable-next-line no-console
        (<any>console[level])(message);
    }
}
