/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import LogicalOrFilter from '../../filtering/baseClasses/LogicalOrFilter';
import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import MyCallsFilter from './MyCallsFilter';
import FilterByAgency from '../../filtering/filters/FilterByAgency';

/**
 * Filters the calls so that only those whose agency is the same as the logged-in user's, will be shown.
 * As with all call filters, any calls that are assigned to the user will be shown no matter what -
 * they cannot be filtered out.
 */
export default class CallFilterByAgency extends LogicalOrFilter<AggregatorCadCall> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['myCallsFilter', 'filterByAgency'];

    /**
     * Constructs a new instance of the CallFilterByAgency class.
     *
     * @param myCallsFilter The filter that includes any calls assigned to the current user.
     * @param filterByAgency The filter that includes any calls that have the same agency as the current user.
     */
    constructor(myCallsFilter: MyCallsFilter, filterByAgency: FilterByAgency) {
        super(myCallsFilter, filterByAgency);
    }
}
