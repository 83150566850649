/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import CadFilter from '../CadFilter';
import CADModel from '../../../schema/interfaces/CADModel';

/**
 * Does no filtering whatsoever. All items from the original list are included in the resulting list.
 */
export default class FilterNone implements CadFilter<CADModel> {

    // @Override
    public include(_model: CADModel): boolean {
        return true;
    }
}
