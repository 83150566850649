/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import ColorAssigner from '../../ColorAssigner';
import CADDetailsController from '../../CADDetailsController';
import CallDetailsScope from './CallDetailsScope';
import AggregatorCadCall from '../../../schema/AggregatorCadCall';
import CallRepository from '../repository/CallRepository';
import { CallQueryFactory } from './queries/call-query-factory.service';
import CadCallComment from '../../../schema/CadCallComment';
import LocationAdapter from '../../../shared/mapping/LocationAdapter';
import PermissionName from '../../../permissions/enums/PermissionName';
import PermissionAttribute from '../../../permissions/enums/PermissionAttribute';
import { NotificationService } from '../../../notifications/notification-service';
import { IStateService } from 'angular-ui-router';
import { PushNotificationService } from '../../../login/push-notification/push-notification.service';
import { DISABLE_INFINITY_SCROLL_EVENT, HEIGHT_FOR_ACTIVE_TAB, HEIGHT_FOR_INACTIVE_TAB, MIN_WIDTH_TO_FIT, MIN_WIDTH_TO_FIT_LARGE_FONT, TIME_FOR_ANIMATION_COMPLETE } from './CallDetailsConstants';
import SettingsRepository from '../../../settings/SettingsRepository';
import SettingKey from '../../../settings/SettingKey';
import IIntervalService = angular.IIntervalService;
import IResourceClass = angular.resource.IResourceClass;
import IonicPopupService = ionic.popup.IonicPopupService;
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;

/**
 * The base controller for both the Call Details and "My" Call Details pages.
 */
export default class BaseCallDetailsController extends CADDetailsController<AggregatorCadCall> {
    /**
     * name of the last selected tab
     */
    private lastSelectedTab: number;

    /**
     * Constructs a new instance of the BaseCallDetailsController class.
     *
     * @param $scope The Angular scope object that provides data to the view.
     * @param callRepository The repository that stores CAD calls pushed from the server.
     * @param colorAssigner The object that assigns a color based on the status of the CAD call.
     * @param callQueryFactory The factory that creates the queries used to get data not pushed out by the aggregator.
     * @param $interval The Angular service that repeatedly performs some action on a regular interval.
     * @param callLocationAdapter The adapter that obtains a location from a CAD Call.
     * @param callId The concatenated `callid` and `type` that together uniquely identify the CAD call.
     * @param cadCallCommentAPI The service that retrieves comments for CAD calls from the server.
     * @param notificationService The service to provide  in-app notifications logic
     * @param pushNotificationService The service which configure and works with push-notifications
     * @param $ionicPopup The service that displays a native-looking dialog.
     * @param $ionicScrollDelegate A delegate used to control the ion-scroll directive.
     * @param $state The service that transitions between states.
     * @param $timeout The Angular service that waits for a specified period of time and then executes a function.
     * @param settingsRepository
     */
    constructor($scope: CallDetailsScope,
        callRepository: CallRepository,
        colorAssigner: ColorAssigner,
        callQueryFactory: CallQueryFactory,
        $interval: IIntervalService,
        callLocationAdapter: LocationAdapter<AggregatorCadCall>,
        callId: string,
        cadCallCommentAPI: IResourceClass<CadCallComment>,
        notificationService: NotificationService,
        pushNotificationService: PushNotificationService,
        $ionicPopup: IonicPopupService,
        $ionicScrollDelegate: IonicScrollDelegate,
        $state: IStateService,
        $timeout: angular.ITimeoutService,
        settingsRepository: SettingsRepository
    ) {
        super($scope, callRepository, colorAssigner, callQueryFactory, $interval, callLocationAdapter, callId, 'call', [
            'latitude',
            'longitude',
            'location',
            'city'
        ]);

        $scope.data = {};

        $scope.callId = callId;

        $scope.initialParsedComments = [];

        $scope.finalCommentsList = [];

        $scope.commentRowCount = settingsRepository.get(SettingKey.commentRowsCountSetting).value;

        $scope.loadMore = () => {
            this.loadHistoricalData();
        };

        $scope.operators = [];

        $scope.commentsFilter = {
            operator: ''
        };

        $scope.isCommentsSortingDirectionAscending = true;

        $scope.toggleComment = (comment: CadCallComment) => {
            comment.isCollapsed = !comment.isCollapsed;
            comment.commentHeight = comment.isCollapsed ? `${$scope.commentLinesHeight}px` : 'auto';

            const initialComment = $scope.initialParsedComments.find(item => item.timestamp === comment.timestamp);
            initialComment.isCollapsed = comment.isCollapsed;
            initialComment.commentHeight = comment.commentHeight;
        };

        $scope.getCommentHeight = (id: string, comment: CadCallComment) => {
            if (comment.commentHeight) {
                return comment.commentHeight;
            }

            const el = document.getElementById(id);

            if (!$scope.commentLinesHeight) {
                $scope.setCurrentLineHeight(el);
            }

            comment.needCollapsing = el.scrollHeight > $scope.commentLinesHeight;

            const initialComment = $scope.initialParsedComments.find(item => item.timestamp === comment.timestamp);
            if (initialComment) {
                comment.isCollapsed = initialComment.isCollapsed;
                comment.commentHeight = comment.isCollapsed && comment.needCollapsing ?
                    `${$scope.commentLinesHeight}px` : 'auto';
                initialComment.needCollapsing = comment.needCollapsing;
                initialComment.commentHeight = comment.commentHeight;
            } else {
                const hasCollapsed = Object.hasOwnProperty.bind(comment)('isCollapsed');
                if (!hasCollapsed) {
                    comment.isCollapsed = true;
                }

                comment.commentHeight = comment.isCollapsed && comment.needCollapsing ?
                    `${$scope.commentLinesHeight}px` : 'auto';
                $scope.initialParsedComments.push(comment);
            }

            return comment.commentHeight;
        };

        $scope.setCurrentLineHeight = (el: HTMLElement) => {
            let temp = document.createElement('p');
            temp.setAttribute('style', 'margin:0; padding:0; '
                + 'font-family:' + (el.style.fontFamily || 'inherit') + '; '
                + 'font-size:' + (el.style.fontSize || 'inherit'));
            temp.innerHTML = 'A';

            el.appendChild(temp);
            const oneLinesHeight = temp.clientHeight;
            $scope.commentLinesHeight = $scope.commentRowCount * oneLinesHeight;
            el.removeChild(temp);
        };

        $scope.addComment = () => {
            cadCallCommentAPI.save({
                id: callId,
                comment: $scope.data.comment
            }, {}).$promise
                .then(() => $scope.data.comment = undefined)
                .catch(() => $ionicPopup.alert({ template: 'Comment Failed To Save' }));
        };

        $scope.showUpdates = (updates: any, scrollToUpdate?: boolean) => {
            const updatedParams = updates || $state.params['updates']; // eslint-disable-line dot-notation,@typescript-eslint/dot-notation

            if (updatedParams.length) {
                $timeout(() => {
                    updatedParams.forEach((update: string) => {
                        const commentsParams = ['comments', 'flexComment'];
                        const isCommentUpdate = commentsParams.indexOf(update) !== -1;
                        if (isCommentUpdate && $scope.commentsFilter.operator) {
                            return;
                        }
                        const className = 'highlight-on-update';
                        const callViewClass = `.call-${callId}`;
                        const elementClass = `.call_${update}`;
                        const element = angular.element(callViewClass).find(elementClass);
                        const view = angular.element('.view-container');
                        const tabNav = angular.element('.tab-nav');
                        const item = $scope.getUpdatedElement(element, update);
                        const offset = item.offset() || {};
                        const elementPosition = offset['top']; // eslint-disable-line dot-notation,@typescript-eslint/dot-notation

                        item.addClass(className);
                        if (elementPosition && (elementPosition >= view.height() - tabNav.height()) && scrollToUpdate) {
                            const scrollDistance = elementPosition - view.height() + item.height() + tabNav.height() + 20;
                            $ionicScrollDelegate.scrollTo(0, scrollDistance);
                        }
                    });
                }, 1000);
            }
        };

        $scope.hideHighlight = () => {
            const className = 'highlight-on-update';
            angular.element(document.querySelectorAll(`.${className}`)).removeClass(className);
        };

        $scope.getActiveCallId = () => {
            return location.href.split('/')[location.href.split('/').length - 1];
        };

        $scope.getUpdatedElement = (element: any, update: string) => {
            let visibleElement = element;
            let isElementRendered = false;
            const radioLogParams = ['status', 'unit', 'otherUnits'];
            const isRadiologUpdate = radioLogParams.indexOf(update) !== -1;

            element.toArray().forEach((item: any) => {
                if (item.clientHeight !== 0) {
                    visibleElement = angular.element(item);
                }
            });

            if (isRadiologUpdate) {
                const radioLogElement = visibleElement.find('.radio-log');
                isElementRendered = radioLogElement.length;
                if (isElementRendered) {
                    visibleElement = radioLogElement.first();
                } else {
                    $timeout(() => {
                        visibleElement = isElementRendered && radioLogElement.first();
                    }, 1000);
                }
            }
            return visibleElement;
        };

        $scope.onEnterComment = () => {
            $scope.oldComment = $scope.data.comment;
        };

        $scope.onChangeComment = () => {
            // The first part of ISO-8859-1 (entity numbers from 0-127) is the original ASCII character-set.
            // It contains numbers, upper and lowercase English letters, and some special characters.
            // entity numbers 8220, 8221, 8216, 8217 corresponds to standard quote symbols on iOS
            // In case entered symbol out of scope it will be removed
            const isUnknownSymbolExist = [...$scope.data.comment].find(item =>
                (item.charCodeAt(0) > 127 && [8220, 8221, 8216, 8217].indexOf(item.charCodeAt(0)) === -1));
            $scope.data.comment = isUnknownSymbolExist ? $scope.oldComment : $scope.data.comment;
        };

        $scope.onSortComments = () => {
            $scope.isCommentsSortingDirectionAscending = !$scope.isCommentsSortingDirectionAscending;
            $scope.sortComments();
            $scope.setCommentsSectionClass();
        };

        $scope.sortComments = () => {
            return $scope.finalCommentsList ? $scope.finalCommentsList.sort(function (a: CadCallComment, b: CadCallComment) {
                const x = a.timestamp;
                const y = b.timestamp;
                return $scope.isCommentsSortingDirectionAscending ?
                    ((x < y) ? -1 : ((x > y) ? 1 : 0)) :
                    ((x < y) ? 1 : ((x > y) ? -1 : 0));
            }) : [];
        };

        $scope.setCommentsSectionClass = () => {
            const callViewClass = `.call-${callId}`;
            const elementClass = '.call_comments';
            const ascSortingClass = 'ascending-sorting';
            const descSortingClass = 'descending-sorting';
            const element = angular.element(callViewClass).find(elementClass);
            const className = $scope.isCommentsSortingDirectionAscending ? ascSortingClass : descSortingClass;
            element.removeClass(`${ascSortingClass} ${descSortingClass}`);
            element.addClass(className);
        };

        // Permission variables for comments.
        $scope.commentPermission = [PermissionName.touchcomments];
        $scope.commentAddPermission = {
            or: false,
            permissionName: PermissionName.touchcomments,
            attributes: [PermissionAttribute.access, PermissionAttribute.add]
        };

        $scope.setOperatorsList = () => {
            $scope.call.parsedComments.forEach((comment: CadCallComment) => {
                $scope.operators = Array.from(new Set([...$scope.operators, ...[comment.operator]]));
            });
        };

        $scope.onSelectOperator = () => {
            $scope.filterComments();
            $scope.sortComments();
        };

        $scope.filterComments = () => {
            $scope.finalCommentsList = !$scope.commentsFilter.operator ?
                $scope.call.parsedComments :
                $scope.call.parsedComments.filter(comment => comment.operator === $scope.commentsFilter.operator);
        };

        $scope.$on('$ionicView.enter', () => {
            $scope.showUpdates(false, true);
            $scope.enableInfinityScroll = true;
            pushNotificationService.clearAllNotifications();
        });
        $scope.$on(DISABLE_INFINITY_SCROLL_EVENT, () => {
            $scope.enableInfinityScroll = false;
        });
        $scope.$on('$stateChangeSuccess', (_event, toState, toParams, fromState) => {
            const state = 'app.calls.callsDetails';

            if (fromState.name === state && callId === $scope.getActiveCallId()) {
                $scope.hideHighlight();

                delete notificationService.missedUpdatesList[callId];
            } else if (toState.name === state && notificationService.missedUpdatesList[callId] && callId === toParams.id) {
                const updates = notificationService.missedUpdatesList[callId].data;

                delete notificationService.missedUpdatesList[callId];
                $scope.showUpdates(updates, true);
            }

        });
        $scope.$watch(() => {
            return notificationService.missedUpdatesList[callId];
        }, (newVal: any, _oldVal: any) => {
            if (newVal && $scope.getActiveCallId() === callId) {
                $scope.showUpdates(newVal.data);
                delete notificationService.missedUpdatesList[callId];
                $timeout(() => {
                    $scope.hideHighlight();
                }, 5000);
            }
        });

        $scope.$watch(() => {
            $scope.call.screenWidthCheck = window.screen.width < MIN_WIDTH_TO_FIT || (this.largeFontCheck() && window.screen.width < MIN_WIDTH_TO_FIT_LARGE_FONT);

            return $scope.call.allUnits.length;
        }, (newVal: any, oldVal: any) => {
            if (newVal && oldVal && callId === $scope.getActiveCallId() && newVal !== oldVal) {
                $scope.showUpdates(['assigned_unit'], true);
                $timeout(() => {
                    $scope.hideHighlight();
                }, 5000);
            }
        });

        $scope.$watch(() => {
            const radioLogs = $scope.call.radioLogs;
            return radioLogs !== undefined ? radioLogs.length : 0;
        }, (newVal: any, oldVal: any) => {
            if (newVal && oldVal && callId === $scope.getActiveCallId() && newVal !== oldVal) {
                $scope.showUpdates(['unit'], true);
            }
        });

        $scope.$watch(() => {
            const parsedComments = $scope.call.parsedComments;
            return parsedComments !== undefined ? parsedComments.length : 0;
        }, () => {
            if ($scope.call.parsedComments === undefined || $scope.call.parsedComments.length === 0) {
                return;
            }
            $scope.call.parsedComments.forEach((item: CadCallComment) => {
                const existedComment = $scope.initialParsedComments.find(initialComment => initialComment.timestamp === item.timestamp);
                if (existedComment) {
                    item.needCollapsing = existedComment.needCollapsing;
                    item.isCollapsed = existedComment.isCollapsed;
                    item.commentHeight = existedComment.commentHeight;
                } else {
                    // Uncomment if needed every time collapse comment when user open call details
                    // item.needCollapsing = true;
                    // item.isCollapsed = true;

                    // Remove the next 2 lines if needed every time collapse comment when user open call details
                    item.needCollapsing = Object.hasOwnProperty.bind(item)('needCollapsing') ? item.needCollapsing : true;
                    item.isCollapsed = Object.hasOwnProperty.bind(item)('isCollapsed') ? item.isCollapsed : true;

                    item.commentHeight = '';
                    $scope.initialParsedComments.push(item);
                }
            });
            // Define comments that will be displayed in the list based on filtering/sorting settings
            $scope.finalCommentsList = $scope.finalCommentsList.length ? $scope.finalCommentsList : $scope.call.parsedComments;
            $scope.setOperatorsList();
            $scope.onSelectOperator();
        });

        $scope.getHeightForTab = (currentTab: number, indexTab: number): string => {
            if (this.lastSelectedTab !== currentTab) {
                this.lastSelectedTab = currentTab;
                try {
                    $timeout(() => {
                        try {
                            $ionicScrollDelegate.resize();
                        } catch (e) { }
                    }, TIME_FOR_ANIMATION_COMPLETE);
                } catch (e) { }
            }

            return currentTab === indexTab ? HEIGHT_FOR_ACTIVE_TAB : HEIGHT_FOR_INACTIVE_TAB;
        };
    }

    protected largeFontCheck() {
        let isUsingLargeFont = false;

        try {
            let fontSize = getComputedStyle(document.getElementsByClassName('vert-align')[0]).getPropertyValue('font-size');
            let fontSizeInt = parseInt(fontSize, 10);
            isUsingLargeFont = fontSizeInt > 19;
        } catch (error) {
            isUsingLargeFont = false;
        }

        return isUsingLargeFont;
    }
}
