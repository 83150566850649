/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { OpaqueToken, TypeProvider } from '@angular/core';

/**
 * The dependency injection token used to inject the `conditionalInjectionMap` into a class constructor.
 */
export const conditionalInjectionMapToken = new OpaqueToken('conditionalInjectionMap');

/**
 * A map that maps the token to the list of service types that conditionally may be injected.
 */
export const conditionalInjectionMap = new Map<any, TypeProvider[]>();
