/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * A function that enumerates the values of an enum.
 *
 * For example, consider the following enum:
 * enum Letter { A, B, C }
 *
 * TypeScript will compile this to a JavaScript object similar to:
 * Letter = {
 *   'A': 0,
 *   '0': 'A',
 *   'B': 1,
 *   '1': 'B',
 *   'C': 2,
 *   '2': 'C'
 * };
 *
 * In other words, an enum is essentially a two-way map. You can go from the name to the number
 * (e.g. Letter['A'] === 0) or from the number to the name (e.g. Letter[0] === 'A').
 * This function only returns the names (e.g. 'A', 'B', 'C') and NOT the numbers.
 * The names will ordered according to their associated numbers.
 *
 * @param enumType The type of enum whose values will be returned as an array.
 * @returns {string[]} The array of enum values in numerical order.
 */
export default function EnumEnumerator(enumType: Object): string[] {
    return Object.keys(enumType)

        // Convert all properties to a number. Those that aren't numeric will be NaN.
        .map(val => Number(val))

        // Filter out the NaNs.
        .filter(val => !isNaN(val))

        // Sort them in increasing order.
        .sort((n1, n2) => n1 - n2)

        // Then return the string name for each number.
        .map(n => enumType[n]);
}
