/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { StringLimitedSizeQueueJsonSerializer } from '../shared/serialization/string-limited-size-queue-json-serializer';

// The maximum number of elements that may be stored in history.
export const HISTORY_SIZE = 50;

/**
 * A serializer that serializes the history, which is stored as a LimitedSizeQueue<string>
 * using the HISTORY_SIZE as the max size of the queue.
 */
@Injectable()
export class HistoryJsonSerializer extends StringLimitedSizeQueueJsonSerializer {

    /**
     * Constructs a new instance of the HistoryJsonSerializer class.
     */
    constructor() {
        super(HISTORY_SIZE);
    }
}
