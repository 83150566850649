/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { TableNode } from '../../nodes/class/table-node';
import { RequestCreationData } from '../request-creation-data';

/**
 * Performs a "visit" on a TableNode during the process of building a request.
 */
@Injectable()
export class TableNodeRequestCreationVisitor implements TypeSpecificTreeNodeVisitor<TableNode, RequestCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [TableNode];

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: TableNode, data: RequestCreationData): void {
        // If this is the start of a new request, then add the table.
        if (data.currentPath.length < 1) {
            data.request.table = node.table;
        }
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: TableNode, _data: RequestCreationData): void {
        // Nothing to do here.
    }
}
