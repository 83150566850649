/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';

/**
 * A component that knows how to display a section of content.
 */
@Component({
    selector: 'sds-display-section',
    template: `
        <div class="section-header" *ngIf="header">
            <h1>{{header}}</h1>
        </div>
        <div class="flex-section">
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        require('./display-section.component.scss'),
        require('./display-section.component.day.scss'),
        require('./display-section.component.night.scss')
    ]
})
export class DisplaySectionComponent {

    /**
     * The header for the section.
     */
    @Input() public header: string;
}
