/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { URLSearchParams } from '@angular/http';
import { RequestArgs } from '@angular/http/src/interfaces';
import { RequestCreator } from '../request-creator.service';
import { UrlFactory } from '../../authentication';
import { BatchRequestConfig } from '../batch-request/batch-request-config';

/**
 * The key that is used to index into the associative array of additional data
 * and retrieve the involvement type number for this particular request.
 */
export const TYPE_NUMBER_KEY = 'typeNumber';

/**
 * Creates the request for the InvolvementAlertsBatchRequest service.
 * The request will load the involvement alerts for an entire list of involvable models
 * such as vehicles, property, and incidents.
 */
@Injectable()
export class InvolvementAlertsRequestCreator extends RequestCreator<BatchRequestConfig> {

    /**
     * Constructs a new instance of the InvolvementAlertsRequestCreator class.
     *
     * @param urlFactory
     */
    constructor(urlFactory: UrlFactory) {
        super(urlFactory);
    }

    /**
     * @inheritdoc
     */
    public create(config: BatchRequestConfig): RequestArgs {
        const involvementTypeNumber = (config.additionalData || {})[TYPE_NUMBER_KEY];
        if (typeof involvementTypeNumber !== 'number') {
            throw new Error('The `config.additionalData` must contain the type number');
        }

        const url = this.urlFactory.create({
            path: '/tables/syinvolv'
        });

        const search = new URLSearchParams();
        const filters = [
            `rec=${config.delimitedKeys}`,
            'alert=1',
            `typ=${involvementTypeNumber}`
        ];
        search.set('filter', filters.join(';'));

        return { url, search };
    }
}
