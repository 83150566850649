/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { ExpandSelectNode } from '../../nodes/property/expand-select-node';
import { ModelCreationData } from '../model-creation-data';
import { TablesApiHelper } from '../tables-api-helper';
import { PropertyDeserializer } from '../property-deserializer';

/**
 * Performs a "visit" on an ExpandSelectNode during the process of creating the human-friendly model.
 */
@Injectable()
export class ExpandSelectNodeModelCreationVisitor implements TypeSpecificTreeNodeVisitor<ExpandSelectNode, ModelCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [ExpandSelectNode];

    /**
     * Constructs a new instance of the ExpandSelectNodeModelCreationVisitor class.
     *
     * @param tablesApiHelper A helper that knows how to work with the database models returned by the Tables API.
     * @param propertyDeserializer Derializes a property from the JSON response received from the database.
     */
    constructor(private tablesApiHelper: TablesApiHelper, private propertyDeserializer: PropertyDeserializer) {
    }

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: ExpandSelectNode, data: ModelCreationData): void {
        for (let { databaseModel, humanFriendlyModel } of data.modelPairs.peek()) {
            const expandedObject = this.tablesApiHelper.getExpandedObject(databaseModel, node.column);
            if (expandedObject) {
                humanFriendlyModel[node.propertyKey] = this.propertyDeserializer.deserialize(node, expandedObject[node.select]);
            }
        }
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: ExpandSelectNode, _data: ModelCreationData): void {
        // Nothing to do here.
    }
}
