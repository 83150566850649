/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Logger } from './logger';

/**
 * A centralized error handler that will log errors using the appropriate
 * logger (either development or production).
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ErrorHandler-class.html
 */
@Injectable()
export class LoggingErrorHandler implements ErrorHandler {

    /**
     * Constructs a new instance of the LoggingErrorHandler class.
     *
     * @param injector The dependency injector provided by the Angular framework.
     */
    constructor(private injector: Injector) {
    }

    /**
     * @inheritdoc
     */
    public handleError(error: any): void {
        // We must manually inject the Logger after the server version has been determined,
        // which happens during the login process. This is due to the fact that one of the
        // transitive dependencies of the ProductionLogger requires the server version.
        const logger = this.injector.get(Logger) as Logger;
        logger.error(error);
    }
}
