/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../shared/database/decorators';

/**
 * The involved officer model
 */
@table('jlicoff')
export class InvolvedOfficer {

    @expand('officer', 'fullnam')
    public description: string;
}
