/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { ModelCreationVisitor } from './model-creation-visitor';
import { TablesApiHelper } from './tables-api-helper';
import { MODEL_CREATION_TYPE_SPECIFIC_VISITOR_PROVIDERS } from './type-specific-visitors/model-creation-type-specific-visitor-providers';
import { PropertyDeserializer } from './property-deserializer';
import { DateConverter } from './converters/date-converter';

/**
 * An array that contains all providers within the "model-creation" folder.
 */
export const MODEL_CREATION_PROVIDERS = [
    ...MODEL_CREATION_TYPE_SPECIFIC_VISITOR_PROVIDERS,
    ModelCreationVisitor,
    TablesApiHelper,
    PropertyDeserializer,
    DateConverter
];
