/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject } from '@angular/core';
import { DatabaseService } from '../../../shared/database/database.service';
import { DetailsStateParams } from '../../../shared/interfaces/DetailsStateParams';
import { AccidentRoadway } from './model/accident-roadway';

/**
 * A component that displays the details of a accident roadway.
 */
@Component({
    selector: 'sds-accident-roadway-detail',
    template: require('./accident-roadway-detail.component.html')
})
export class AccidentRoadwayDetailComponent implements OnInit {

    /**
     * The accident roadway model to display.
     */
    public accidentRoadway: AccidentRoadway;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the AccidentRoadwayDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(AccidentRoadway, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => {
                this.accidentRoadway = detailModel;
                this.accidentRoadway.slopeDirectionDegree = detailModel.slopePercentage ? (detailModel.slopeDirection + detailModel.slopePercentage) || detailModel.slopePercentage.toString() : '';
            });
    }
}
