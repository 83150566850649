/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../../shared/database/decorators';

/**
 * The ems condition model
 */
@table('emcond')
export class Condition {

    @expand('concode', 'desc')
    public description: string;
}
