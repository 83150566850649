/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { combinePropertyDecorators } from './decorator-helpers';
import { declareProperty } from './declare-property.decorator';

/**
 * The metadata key used to associate a database field with an object property.
 */
export const fieldMetadataKey = Symbol('field');

/**
 * A decorator factory that associates a database field with the property on which it is applied.
 *
 * @param name The name of the database field.
 * @returns The actual decorator that associates the database field with the property.
 */
export function field(name: string) {
    return combinePropertyDecorators(declareProperty, Reflect.metadata(fieldMetadataKey, name));
}
