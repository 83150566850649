/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { combinePropertyDecorators } from './decorator-helpers';
import { declareProperty } from './declare-property.decorator';

/**
 * The metadata key used to associate a one-to-one reference with an object property.
 */
export const includeOneMetadataKey = Symbol('include-one');

/**
 * The data stored by the includeOne decorator.
 */
export interface IncludeOneData {

    /**
     * The database table to include.
     */
    table: string;

    /**
     * The name of the single field to retrieve from the included object.
     */
    select: string;
}

/**
 * A decorator factory that associates an included database table (one-to-one)
 * with the property on which it is applied.
 *
 * @param table The database table to include.
 * @param select (optional) The name of a single field to retrieve.
 * @returns The actual decorator that associates the included table with the property.
 */
export function includeOne(table: string, select?: string) {
    return combinePropertyDecorators(
        declareProperty,
        Reflect.metadata(includeOneMetadataKey, { table, select })
    );
}
