/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../../../shared/database/decorators';
import { CadCallReference, NameReference } from '../../../../shared/model';
import { Involvement } from '../../../../../schema/Involvement';
import { Circumstance } from './circumstance';
import { Condition } from './condition';
import { ModusOperandi } from './modus-operandi';
import { Responder } from './responder';
import { Supplement } from './supplement';
import { RadioLog, Incident } from '../../../../shared/model';

/**
 * The fire incident model
 */
@table('frmain')
export class FireIncident implements Incident {

    @field('number')
    public id: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @includeMany('frccirc', Circumstance)
    public circumstances: Circumstance[];

    @includeMany('frcond', Condition)
    public conditions: Condition[];

    @includeMany('frmodop', ModusOperandi)
    public mos: ModusOperandi[];

    @includeMany('frrespo', Responder)
    public responders: Responder[];

    @includeMany('frsupl', Supplement)
    public supplements: Supplement[];

    public radioLogs: RadioLog[];

    @field('agency')
    public agency: string;

    @expand('locatn', 'desc')
    public area: string;

    @expand('callid', CadCallReference)
    public cadCall: CadCallReference | string;

    @expand('nameid', NameReference)
    public caller: NameReference | string;

    @field('contact')
    public contact: string;

    @includeOne('frnarr', 'narratv')
    public comments: string;

    @expand('dispos', 'desc')
    public disposition: string;

    @field('dispdat')
    public dispositionDate: Date;

    @expand('howrc', 'desc')
    public howReceived: string;

    @field('misc')
    public miscellaneousEntry: string;

    @expand('nature', 'desc')
    public nature: string;

    @expand('condobs', 'desc')
    public observedCondition: string;

    @field('ocurdt1')
    public occurredFrom: Date;

    @field('ocurdt2')
    public occurredTo: Date;

    @expand('rcvby', 'fullnam')
    public receivedBy: string;

    @expand('condtkn', 'desc')
    public reported: string;

    @expand('respoff', 'fullnam')
    public responsibleOfficer: string;

    @field('dtrepor')
    public whenReported: Date;
}
