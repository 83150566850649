/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import { HomeStatePolicy } from './home-state-policy';
import LimitedSizeQueue from '../../../shared/data-structures/limited-size-queue';
import PermissionsRepository from '../../../permissions/PermissionsRepository';
import { OrderByCountHistoryTransformation } from '../../transformations/order-by-count-history-transformation';

/**
 * A policy that sets the home screen to the state that the user has visited the most in the past.
 * The user must still have permission to access the state, of course.
 */
@Injectable()
export class MostVisitedHomeStatePolicy implements HomeStatePolicy {

    /**
     * Constructs a new instance of the MostVisitedHomeStatePolicy class.
     *
     * @param $state The service that transitions between states.
     * @param permissionsRepository The repository which stores permissions of a user.
     * @param orderByCountHistoryTransformation A transformation that orders the history by the number of times each item appears.
     */
    constructor( @Inject('$state') private $state: angular.ui.IStateService,
        @Inject('permissionsRepository') private permissionsRepository: PermissionsRepository,
        private orderByCountHistoryTransformation: OrderByCountHistoryTransformation) {
    }

    /**
     * @inheritdoc
     */
    public getHomeState(history: LimitedSizeQueue<string>): string {
        for (let stateName of this.orderByCountHistoryTransformation.transform(history)) {
            const state = this.$state.get(stateName);

            // Ensure that it is a valid state and that the user has permissions to it.
            if (state && !state.abstract && this.permissionsRepository.checkStatePermissions(state.name)) {
                return stateName;
            }
        }
        return undefined;
    }
}
