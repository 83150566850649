/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IAnimateService = angular.animate.IAnimateService;
import IDirective = angular.IDirective;
import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAnimationOptions = angular.animate.IAnimationOptions;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
ShowWithCallback.$inject = ['$animate'];

/**
 * A directive based on ng-show that, unlike ng-show, executes a callback after the animation is complete.
 *
 * @param $animate The Angular animation service.
 * @returns {IDirective} A directive that conditionally shows an element and executes a callback
 *                       when the animation to show/hide the element is complete.
 */
export default function ShowWithCallback($animate: IAnimateService): IDirective {
    let NG_HIDE_CLASS = 'ng-hide';
    let NG_HIDE_IN_PROGRESS_CLASS = 'ng-hide-animate';

    return {
        restrict: 'A',
        multiElement: true,
        scope: {
            showWithCallback: '=',
            callback: '&'
        },
        link: function($scope: ShowWithCallbackScope, $element: IAugmentedJQuery) {
            $scope.$watch('showWithCallback', newValue => {
                $animate[newValue ? 'removeClass' : 'addClass']($element, NG_HIDE_CLASS, <IAnimationOptions>{
                    tempClasses: NG_HIDE_IN_PROGRESS_CLASS
                }).then(() => {
                    $scope.callback();
                });
            });
        }
    };
}

/**
 * Defines the interface for the scope of the ShowWithCallback directive.
 */
interface ShowWithCallbackScope extends IScope {

    /**
     * The callback to execute once the animation is complete.
     */
    callback: Function;
}
