/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import Regeneratable from './Regeneratable';
import IQService = angular.IQService;
import IPromise = angular.IPromise;

/**
 * A base class that ensures that the initialization logic cannot be performed again until
 * the `destroy` method is called. Likewise, the destruction logic cannot be performed again
 * until the `initialize` method is called.
 *
 * This allows subclasses to avoid having to track the current state of initialization and
 * make simplifying assumptions about their current state.
 */
abstract class BaseRegeneratable implements Regeneratable {

    constructor(protected $q: IQService) {
    }

    // @Override
    public initialize(): IPromise<void> {
        return this.$q.when(this.performInitialization());
    }

    // @Override
    public destroy(): IPromise<void> {
        return this.$q.when(this.performDestruction());
    }

    /**
     * Performs the actual initialization logic.
     *
     * @returns {void|IPromise<void>} Either:
     *                                (1) nothing (i.e. void) or
     *                                (2) a promise that, when resolved, indicates that initialization is complete.
     */
    protected abstract performInitialization(): void | IPromise<void>;

    /**
     * Performs the actual destruction logic.
     *
     * @returns {void|IPromise<void>} Either:
     *                                (1) nothing (i.e. void) or
     *                                (2) a promise that, when resolved, indicates that destruction is complete.
     */
    protected abstract performDestruction(): void | IPromise<void>;
}

export default BaseRegeneratable;
