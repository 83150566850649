/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field } from '../../../../../shared/database/decorators';

/**
 * The application parameters table.
 */
@table('apparam')
export class AttachFilesTables {

    @field('parval')
    public value: string;
}
