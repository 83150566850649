/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import BaseRegeneratable from './BaseRegeneratable';
import Regeneratable from './Regeneratable';
import MultiInjector from '../../shared/MultiInjector';
import RegeneratableName from './RegeneratableName';
import IQService = angular.IQService;
import IPromise = angular.IPromise;

/**
 * A container that stores all regeneratable services.
 */
class RegeneratableContainer extends BaseRegeneratable {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$q', 'multiInjector'];

    /**
     * The array of regeneratable services. It is lazily-loaded by the `initialize` method.
     */
    private _regeneratableServices: Regeneratable[];

    /**
     * Constructs a new instance of the RegeneratableContainer class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param multiInjector Injects multiple services at once whose names are specified by an enum.
     */
    constructor($q: IQService, private multiInjector: MultiInjector) {
        super($q);
    }

    // @Override
    protected performInitialization(): IPromise<void> {
        // Lazy-load all services.
        if (!this._regeneratableServices) {
            this._regeneratableServices = this.multiInjector.getAll<Regeneratable>(RegeneratableName);
        }

        // Initialize in forward order.
        return this.executeSequentially(this._regeneratableServices, service => service.initialize());
    }

    // @Override
    protected performDestruction(): IPromise<void> {
        // Destroy in reverse order.
        return this.executeSequentially(this._regeneratableServices.invert(), service => service.destroy());
    }

    /**
     * Sequentially executes an asynchronous method on an array of regeneratable services.
     * Each service will not have its method invoked until the previous promise has been resolved.
     *
     * Note: `$q.all` should not be used, because that executes promises in parallel.
     *
     * @param services An array of services on which to invoke an asynchronous method.
     * @param promiseCreator A function that accepts a service and returns a promise by invoking a method on that service.
     * @returns {IPromise<void>} A promise that, when resolved, indicates that all services have completed their asynchronous operations.
     */
    private executeSequentially(services: Regeneratable[], promiseCreator: (service: Regeneratable) => IPromise<void>): IPromise<void> {
        return services.reduce((promise, service) => promise.then(() => promiseCreator(service)), this.$q.when());
    }
}

export default RegeneratableContainer;
