/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { HomeStatePolicy } from './home-state-policy';
import LimitedSizeQueue from '../../../shared/data-structures/limited-size-queue';

/**
 * The fallback policy is used only when the user has no permissions to access any
 * of the potential home screens. This policy simply takes the user to the "other"
 * tab which is always present regardless of permissions.
 */
export class FallbackHomeStatePolicy implements HomeStatePolicy {

    /**
     * @inheritdoc
     */
    public getHomeState(_history: LimitedSizeQueue<string>): string {
        return 'app.other.otherDetails';
    }
}
