/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { IRootScopeService, IScope } from 'angular';
import CustomFilterAllFilters from '../../../custom-filters/CustomFilterAllFilters';
import FiltersStorage from '../../../storage/FiltersStorage';
import SaveFilterController from '../../../storage/save-filter-popup/save-filter-popup';
import Setting from '../../../../../settings/Setting';

import IonicHistoryService = ionic.navigation.IonicHistoryService;
import IonicPopupService = ionic.popup.IonicPopupService;
import { FilterActions } from '../../../consts';
import { FilterAnalytics } from '../../../analytics/events';
import { FilterOptionObject, FilterOption } from './filter-options';
import strings from './strings';

export default abstract class FilterOptionsSubHeaderComponentController<T> {
    private saveFilterController: SaveFilterController<T>;

    public isWideScreen: boolean;

    public filterOptions: FilterOptionObject[];

    public options: any;

    constructor(
        private filtersStorage: FiltersStorage,
        $rootScope: IRootScopeService,
        $ionicHistory: IonicHistoryService,
        $scope: IScope,
        customFilterAllFilters: CustomFilterAllFilters,
        $ionicPopup: IonicPopupService,
        filterSetting: Setting<T>,
        filterAnalytics: FilterAnalytics,
        filterActions: FilterActions,
    ) {
        $scope.newFilter = { filterName: '' };

        this.options = FilterOption.none.toString();

        this.saveFilterController = new SaveFilterController<T>($rootScope, $ionicHistory, $scope, customFilterAllFilters, $ionicPopup, filtersStorage, filterActions, filterSetting, filterAnalytics);

        this.filterOptions = [
            new FilterOptionObject(strings.rename, FilterOption.rename),
            new FilterOptionObject(strings.save_as, FilterOption.save_as),
            new FilterOptionObject(strings.delete, FilterOption.delete),
        ];
    }

    public $onInit = () => { };

    public onDeleteButtonClick = () => {
        this.saveFilterController.openDeleteFilterDialog();
    };

    public openEditNameDialog = () => {
        if (this.isEditingFilterSaved()) {
            this.saveFilterController.openRenameFilterDialog();
        }
    };

    public openSaveAsDialog = () => {
        this.saveFilterController.openSaveAsDialog();
    };

    private isEditingFilterSaved(): boolean {
        return this.filtersStorage.isEditingFilterSaved();
    }

    public onOptionChange() {
        switch (Number(this.options)) {
            case FilterOption.rename:
                this.openEditNameDialog();
                break;

            case FilterOption.save_as:
                this.openSaveAsDialog();
                break;

            case FilterOption.delete:
                this.onDeleteButtonClick();
                break;

            default:
                break;
        }

        this.options = FilterOption.none.toString();
    }
}
