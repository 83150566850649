/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { DatabaseService } from '../../shared/database/database.service';
import { DetailsStateParams } from '../../shared/interfaces/DetailsStateParams';
import { NameAssociation } from './model/name-association';
import { SlideSourceInfo } from '../../schema/SlideSourceInfo';

/**
 * A component that displays the details of a name association.
 */
@Component({
    selector: 'sds-name-association-detail',
    template: require('./name-association-detail.component.html')
})
export class NameAssociationDetailComponent implements OnInit {

    /**
     * Event indicating response error.
     */
    @Output() public onError = new EventEmitter();

    /**
     * A Map containing a list of slides and their source url.
     */
    public slideUrlMap = new Map<string, SlideSourceInfo>([
        this.commentsSlideSourceInfo,
        ['Associates', { srcHtml: 'app/rms/name-association/slides/associates.html' }],
        this.involvementsSlideSourceInfo
    ]);

    /**
     * The name association model to display.
     */
    public nameAssociation: NameAssociation;

    /**
     * A flag that indicates whether or not the data has finished loading.
     */
    public finishedLoading: boolean;

    /**
     * Constructs a new instance of the NameAssociationDetailComponent.
     *
     * @param databaseService A service which will retrieve the data to populate a model.
     * @param $stateParams The passed in url parameters.
     * @param involvementsSlideSourceInfo inject the involvement slide from a value.
     * @param commentsSlideSourceInfo inject the comments slide from a value.
     */
    constructor(
        private databaseService: DatabaseService,
        @Inject('$stateParams') private $stateParams: DetailsStateParams,
        @Inject('involvementsSlideSourceInfo') private involvementsSlideSourceInfo: [string, SlideSourceInfo],
        @Inject('commentsSlideSourceInfo') private commentsSlideSourceInfo: [string, SlideSourceInfo]
    ) {
    }

    /**
     * @inheritdoc
     */
    public ngOnInit() {
        this.databaseService.get(NameAssociation, this.$stateParams.id)
            .finally(() => this.finishedLoading = true)
            .subscribe(detailModel => this.nameAssociation = detailModel, () => this.onError.emit());
    }
}
