/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import FileType from '../../schema/FileType';
import TableCache from './../TableCache';
import IQService = angular.IQService;

/**
 * Caches the fctype database table.
 */
export default class FileTypeCache extends TableCache<FileType> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['fileTypeAPI', '$q'];

    /**
     * Constructs a new instance of the FileTypeCache class.
     *
     * @param fileTypeAPI The service that retrieves file types from the server.
     * @param $q The angular service that handles creating and working with promises.
     */
    constructor(fileTypeAPI: IResourceClass<FileType>, $q: IQService) {
        super(fileTypeAPI, $q, 'code');
    }
}
