/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany, includeOne, involvements, involvementsAlerts } from '../../../../shared/database/decorators';
import { NameReference } from '../../../shared/model/name-reference';
import { PhoneNumber } from './phone-number';
import { InternetAddress } from './internet-address';
import { NameLocalId } from './name-local-id';
import { ScarMarkTattoo } from './scar-mark-tattoo';
import { ModusOperandi } from './modus-operandi';
import { BirthPlace } from './birth-place';
import { Contact } from './contact';
import { Employer } from './employer';
import { Job } from './job';
import { Supervisor } from './supervisor';
import { Probation } from './probation';
import { CriticalNotice } from './critical-notice';
import { DriverLicenseType } from './driver-license-type';
import { Alert } from './alert';
import { Involvement } from '../../../../schema/Involvement';
import { NameExtra } from './name-extra';
import { NameSubType } from './name-sub-type';
import { Address } from './address';
import { Offender } from './offender';

/**
 * The name model
 */
@table('nmmain')
export class Name {
    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @includeMany('nmalert', Alert)
    public alertCodes: Alert[];

    @expand('aka', NameReference)
    public alias: NameReference | string;

    @includeOne('nmextra', 'attorn')
    public attorney: string;

    @expand('facial', 'desc')
    public beard: string;

    @includeOne('nmextra')
    public birthPlace: BirthPlace;

    @expand('build', 'desc')
    public build: string;

    @includeOne('nmextra')
    public nameExtra: NameExtra;

    @field('city')
    public city: string;

    @includeOne('nmmdesc', 'text')
    public comments: string;

    @includeOne('nmextra', 'commis')
    public commissary: boolean;

    @expand('complx', 'desc')
    public complexion: string;

    @includeOne('nmextra')
    public contact: Contact;

    @includeOne('nmextra', 'size2')
    public coverSize: string;

    @includeOne('nmextra', 'henry1')
    public custom1: string;

    @includeOne('nmextra', 'henry2')
    public custom2: string;

    @includeOne('nmextra', 'henry3')
    public custom3: string;

    @includeOne('nmextra', 'henry4')
    public custom4: string;

    @field('deathdt')
    public deathDate: Date;

    @includeMany('nmdltyp', DriverLicenseType) // TODO: Would be nice to be able to do 'type.desc'
    public dlClass: DriverLicenseType[];

    @field('dlnum')
    public dlNumber: string;

    @field('dlstate')
    public dlState: string;

    @field('birthd')
    public dob: Date;

    @includeOne('nmextra', 'educat')
    public education: number;

    @includeOne('nmextra')
    public employer: Employer;

    @expand('ethnic', 'desc')
    public ethnic: string;

    @expand('eyes', 'desc')
    public eyes: string;

    @field('fbinum')
    public fbi: string;

    @field('first')
    public first: string;

    @expand('glasses', 'desc')
    public glasses: string;

    @expand('hair', 'desc')
    public hair: string;

    @expand('hairsty', 'desc')
    public hairStyle: string;

    @field('height')
    public height: string;

    @includeMany('nminter', InternetAddress)
    public internet: InternetAddress[];

    @includeOne('nmextra')
    public job: Job;

    @field('last')
    public last: string;

    @includeMany('nmlocal', NameLocalId)
    public localId: NameLocalId[];

    @field('middle')
    public middle: string;

    @includeOne('nmextra', 'size3')
    public miscSize: string;

    @includeMany('nmmodop', ModusOperandi)
    public modusOperandi: ModusOperandi[];

    @expand('nametyp', 'desc')
    public nameType: string;

    @includeOne('nmextra', 'ncic')
    public ncicPrint: string;

    @includeMany('nmcrtnot', CriticalNotice)
    public notices: CriticalNotice[];

    @includeMany('somain', Offender)
    public offenders: Offender[];

    @includeMany('nmotel', PhoneNumber)
    public otherPhones: PhoneNumber[];

    @includeMany('nmadadrs', Address)
    public otherAddresses: Address[];

    @field('phone')
    public phone: string;

    @includeOne('nmextra')
    public probation: Probation;

    @includeMany('nmprolic', 'descrpt')
    public professionalLicenses: string[];

    @expand('race', 'desc')
    public race: string;

    @includeMany('nmsmt', ScarMarkTattoo)
    public scarsMarksTattoos: ScarMarkTattoo[];

    @field('sex')
    public sex: string;

    @includeOne('nmextra', 'school')
    public school: string;

    @includeOne('nmextra', 'size1')
    public shoeSize: string;

    @expand('speech', 'desc')
    public speech: string;

    @field('ssnum')
    public ssn: string;

    @field('state')
    public state: string;

    @field('stateid')
    public stateId: string;

    @field('street')
    public street: string;

    @includeMany('nmsbtyp', NameSubType) // TODO: Would be nice to be able to do 'subtype.descrpt'
    public subTypes: NameSubType[];

    @includeOne('nmextra')
    public supervisor: Supervisor;

    @expand('teeth', 'desc')
    public teeth: string;

    @field('weight')
    public weight: number;

    @field('wrkphn')
    public workPhone: string;

    @field('zip')
    public zip: string;

    @field('number')
    public number: string;

    public phones: PhoneNumber[];

    public addresses: Address[];

    public noticeAlerts: string[];

    public offenderAlerts: string[];
}
