/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne } from '../../../../shared/database/decorators';
import { IncidentReference } from '../../../shared/model/incident-reference';
import { Offense } from '../../../jail/shared/model/offense';

/**
 * The offender tracking offense model
 */
@table('sooffs')
export class OffenderTrackingOffense {

    @field('num')
    public id: string;

    @field('age')
    public ageAtTime: number;

    @field('condate')
    public convictionDate: Date;

    @field('city')
    public city: string;

    @field('county')
    public county: string;

    @expand('state', 'name')
    public state: string;

    @field('counts')
    public counts: number;

    @expand('court', 'desc')
    public court: string;

    @field('enddate')
    public endDate: Date;

    @field('incarc')
    public incarcerated: boolean;

    @expand('statute', 'desc')
    public localStatute: string;

    @includeOne('sooffst', 'text')
    public statuteText: string;

    @field('other')
    public other: string;

    @field('othstat')
    public otherStatute: string;

    @field('othdesc')
    public otherStatuteDescription: string;

    @expand('incnmbr', IncidentReference)
    public relatedIncident: IncidentReference | string;

    @expand('offnmbr', Offense)
    public relatedOffense: Offense | string;

    @field('sentnce')
    public sentence: string;

    @expand('sttype', 'descrpt')
    public type: string;

    @field('vage')
    public victimAge: number;

    @expand('ethnic', 'desc')
    public victimEthnicity: string;

    @field('juvenle')
    public victimJuvenile: boolean;

    @expand('race', 'desc')
    public victimRace: string;

    @expand('relate', 'desc')
    public victimRelation: string;

    @field('sex')
    public victimSex: string;

    @includeOne('sooffcm', 'text')
    public comments: string;
}
