/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Injector } from '@angular/core';
import { PropertyNodeFactory } from './property-node-factory';
import { ClassNode } from '../class/class-node';
import { PropertyNode } from './property-node';
import { PROPERTY_NODE_FACTORIES_TOKEN } from './type-specific-node-factory-providers';

/**
 * A PropertyNodeFactory that contains a reference to all other PropertyNodeFactories.
 * When a new PropertyNode is to be created, this class delegates to the other factories.
 * The one that knows how to create the correct PropertyNode (based on the Reflect metadata) wins!
 */
@Injectable()
export class MasterPropertyNodeFactory implements PropertyNodeFactory {

    /**
     * The backing property for the `allPropertyNodeFactories` getter.
     */
    private _allPropertyNodeFactories: PropertyNodeFactory[];

    /**
     * Constructs a new instance of the MasterPropertyNodeFactory.
     *
     * @param injector The dependency injector provided by the Angular framework.
     */
    constructor(private injector: Injector) {
    }

    /**
     * Gets an array of factories that know how to create PropertyNodes.
     *
     * Note: The factories are lazy-loaded because of an unavoidable circular dependency.
     * Some of the factories depend upon the TableNodeFactory which in turn depends upon this class.
     *
     * @returns The factory that knows how to create PropertyNodes.
     */
    private get allPropertyNodeFactories(): PropertyNodeFactory[] {
        if (!this._allPropertyNodeFactories) {
            this._allPropertyNodeFactories = this.injector.get(PROPERTY_NODE_FACTORIES_TOKEN);
        }
        return this._allPropertyNodeFactories;
    }

    /**
     * @inheritdoc
     */
    public create(parent: ClassNode, propertyKey: string | symbol): PropertyNode {
        const nodes = this.allPropertyNodeFactories.map(f => f.create(parent, propertyKey)).filter(n => !!n);

        if (nodes.length !== 1) {
            throw new Error(`The ${parent.classConstructor.name}.${propertyKey as string} property must have exactly one database decorator`);
        }

        return nodes[0];
    }
}
