/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe that joins the non-empty items of a string array with the specified separator.
 * NOTE: undefined, null, and empty string are ignored.
 *
 * Usage:
 *   values | sdsJoin:separator
 *
 * Example:
 *   {{ ['Black', 'Blue']] |  sdsJoin:' / '}}
 *   outputs: Black / Blue
 */
@Pipe({
    name: 'sdsJoin'
})
export class JoinPipe implements PipeTransform {
    /**
     * Transforms the data by joining items using the separator.
     *
     * @param values array of string values.
     * @param separator separator used in the join (default is none, strings are concatenated together).
     */
    public transform(values: string[], separator: string = ''): string {
        return values.filter(value => !!value).join(separator);
    }
}
