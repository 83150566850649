/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { PropertyNode } from './property-node';
import { ClassNode } from '../class/class-node';
import { ClassNodeFactory } from '../class/class-node-factory';
import { TreeNode } from '../../../../data-structures/tree-node';
import { Constructor } from '../../../../interfaces/constructor';

/**
 * A node that associates a particular expanded object (the entire object)
 * with a property of a JavaScript class.
 */
export class ExpandNode extends PropertyNode {

    /**
     * Constructs a new instance of the ExpandNode class.
     *
     * @param parent The parent ClassNode.
     * @param propertyKey The property key (or name if you prefer).
     * @param column The database column to expand.
     * @param childConstructor The constructor of the child model. This will be passed onto the child ClassNode.
     *                         This is needed because of a limitation with TypeScript and the Reflect Metadata API.
     *                         The Union Type (e.g. `ChildModel | string`) isn't stored in the metadata -- it just reports `Object`.
     * @param nodeFactory A factory that knows how to create ClassNodes.
     */
    constructor(parent: ClassNode, propertyKey: string | symbol, public column: string, public childConstructor: Constructor<any>, private nodeFactory: ClassNodeFactory) {
        super(parent, propertyKey);
    }

    /**
     * @inheritdoc
     */
    protected *initializeChildren(): Iterable<TreeNode> {
        const propertyType = Reflect.getMetadata('design:type', this.parent.classConstructor.prototype, this.propertyKey);

        if (propertyType !== Object) {
            throw new Error(`The ${this.parent.classConstructor.name}.${this.propertyKey as string} property should be a Union Type with string as one of the types (e.g. ChildModel | string)`);
        }

        yield this.nodeFactory.create(this.childConstructor);
    }
}
