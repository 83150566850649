/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

export const gridColumns = [
    {
        title: 'Incident',
        value: 'nature',
        size: 20
    },
    {
        title: 'Date',
        value: 'dtrepor',
        size: 25
    },
    {
        title: 'Disp.',
        value: 'dispos',
        size: 15
    },
    {
        title: 'Agency',
        value: 'agency',
        size: 20
    },
    {
        title: 'Officer',
        value: 'respoff',
        size: 20
    }
];

export const sortingDirection = {
    ascending: 'asc',
    descending: 'desc',
    default: ''
};
