/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { combinePropertyDecorators } from './decorator-helpers';
import { declareProperty } from './declare-property.decorator';
import { Constructor } from '../../interfaces/constructor';

/**
 * The metadata key used to associate a one-to-many reference with an object property.
 */
export const includeManyMetadataKey = Symbol('include-many');

/**
 * The data stored by the includeMany decorator.
 */
export interface IncludeManyData {

    /**
     * The database table to include.
     */
    table: string;

    /**
     * The constructor of the child model. This will be passed onto the child ClassNode.
     * This is needed because of a limitation with TypeScript and the Reflect Metadata API.
     * The type of the array isn't stored in the metadata -- only the fact that it's an array.
     */
    childConstructor?: Constructor<any>;

    /**
     * The name of the single field to retrieve from the included objects.
     */
    select?: string;
}

/**
 * A decorator factory that associates an included database table (one-to-many)
 * with the property on which it is applied.
 *
 * @param table The database table to include.
 * @param childConstructorOrSelect Either the constructor of the child model or the name of a column to select.
 * @returns The actual decorator that associates the included table with the property.
 */
export function includeMany(table: string, childConstructorOrSelect: Constructor<any> | string) {
    const data: IncludeManyData = {
        table,
        childConstructor: (typeof childConstructorOrSelect !== 'string') ? childConstructorOrSelect : undefined,
        select: (typeof childConstructorOrSelect === 'string') ? childConstructorOrSelect : undefined
    };
    return combinePropertyDecorators(declareProperty, Reflect.metadata(includeManyMetadataKey, data));
}
