/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * A Pipe which will remove pre-specified strings or regular expressions.
 *
 * Usage
 * string | sdsClean:[string|RegExp]
 *
 * Example:
 *   {{This is text. Remove that!that | sdsClean:['Remove', '/that/g', '!']}}
 *   outputs: This is text.
 */
@Pipe({
    name: 'sdsClean'
})
export class CleanPipe implements PipeTransform {
    /**
     * Transforms the data by removing the specified string.
     *
     * @param value A string which should be cleaned
     * @param text The string or RegExp which should be removed.
     */
    public transform(value: string, text: (string|RegExp)[]): string {
        if (text && value) {
            text.forEach(s => value = value.replace(<any>s, ''));
        }
        return value ? value.trim() : undefined;
    }
}
