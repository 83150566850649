/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable } from '@angular/core';
import { TypeSpecificTreeNodeVisitor } from '../../../../data-structures/tree-node-visitor';
import { InvolvementsAlertsNode } from '../../nodes/property/involvements-alerts-node';
import { ModelCreationData } from '../model-creation-data';
import { Involvement } from '../../../../../schema/Involvement';

/**
 * Performs a "visit" on an InvolvementsAlertsNode during the process of creating the human-friendly model.
 */
@Injectable()
export class InvolvementsAlertsNodeModelCreationVisitor implements TypeSpecificTreeNodeVisitor<InvolvementsAlertsNode, ModelCreationData> {

    /**
     * @inheritdoc
     */
    public nodeTypes = [InvolvementsAlertsNode];

    /**
     * @inheritdoc
     */
    public preOrderVisit(node: InvolvementsAlertsNode, data: ModelCreationData): void {
        for (let { databaseModel, humanFriendlyModel } of data.modelPairs.peek()) {
            humanFriendlyModel[node.propertyKey] = databaseModel.involvements
                .filter((element: Involvement) => element.alert)
                .map((involvement: Involvement) => involvement.relationship) || [];
        }
    }

    /**
     * @inheritdoc
     */
    public postOrderVisit(_node: InvolvementsAlertsNode, _data: ModelCreationData): void {
        // Nothing to do here.
    }
}
