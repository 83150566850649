/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * The enum that specifies the set of decorator transforms to apply when calling the
 * `getAlerts` action on the NameAPI.
 */
enum NameGetAlertsTransform {
    nameJoinedTablesDecoratorTransform,
    nameCriticalNoticesDecoratorTransform,
    nameAlertsDecoratorTransform
}

export default NameGetAlertsTransform;
