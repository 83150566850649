/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IDirective = angular.IDirective;
import IScope = angular.IScope;
import ILogService = angular.ILogService;
import PermissionsRepository from '../../permissions/PermissionsRepository';
import { SlideSourceInfo } from '../../schema/SlideSourceInfo';
import PermissionConfiguration from '../../permissions/PermissionConfiguration';
import { ITimeoutService } from 'angular';
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;

DetailSlidesDirective.$inject = [
    '$injector',
    '$log',
    '$timeout',
    '$ionicScrollDelegate'
];

/**
 * A factory function which creates the permission directive.
 *
 * @param $injector The Angular injector service.
 * @param $log The Angular service that performs logging.
 *
 * @returns {IDirective} A directive which will transform the html into a link to another state.
 */
export default function DetailSlidesDirective(
    $injector: angular.auto.IInjectorService,
    $log: ILogService,
    $timeout: ITimeoutService,
    $ionicScrollDelegate: IonicScrollDelegate
): IDirective {
    /**
     * name of the last selected tab
     */
    let lastKeyName = '';

    /**
     * Function intended to take the permissions defined on a slide and process them.
     *
     * @param permissions The permissions for accessing a slide.
     *
     * @returns {boolean} A boolean value which indicates access.
     */
    function isValid(permissions: PermissionConfiguration): boolean {
        try {
            // This directive gets initialized before Angular 2 has been bootstrapped.
            // Therefore, we cannot inject the PermissionsRepository, which depends on an Angular 2 service, in the constructor.
            const permissionsRepository = $injector.get<PermissionsRepository>('permissionsRepository');

            return permissionsRepository.processPermissions(permissions);
        } catch (e) {
            $log.error('Invalid permissions configuration for a slide.');
            return false;
        }
    }

    /**
     * Iterate through the slides keys and push to valid slide array.
     *
     * @param slides A Map containing the list of slides and their associated source url.
     *
     * @returns {string[]} A string array containing a list of slides the user is authorized to see.
     */
    function makeSlideArray(slides: Map<string, SlideSourceInfo>): string[] {

        let validSlides: string[] = [];

        // Loop through the [key, value] pairs of the slides and determine if the user has permissions.
        for (let [slide, sourceInfo] of slides) {
            if (!('permissions' in sourceInfo) || isValid(sourceInfo.permissions)) {
                validSlides.push(slide);
            }
        }

        return validSlides;
    }

    return {
        restrict: 'E',
        scope: {
            slides: '=',
            model: '=',
            name: '@'
        },
        link: (scope: DetailSlides) => {

            scope.validSlides = makeSlideArray(scope.slides);

            // Creates a value on the isolated scope with the same name as the model which is passed to the directive.
            scope[scope.name] = scope.model;

            scope.onSlideChanged = (indexTab: number) => {
                scope.currentTab = indexTab;
                $ionicScrollDelegate.resize();
            };

            scope.getSlideSrc = (keyName) => {
                if (lastKeyName !== keyName) {
                    lastKeyName = keyName;
                }
                return scope.slides.get(keyName).srcHtml;
            };
        },
        template: require('./detail_slides.html')
    };
}

/**
 * An interface which defines the scope used when dynamically creating slides.
 */
interface DetailSlides extends IScope {

    /**
     * The model containing the primary source of information.
     */
    model: Object;

    /**
     * Current tab selected in slides.
     */
    currentTab: number;
    /**
     * The name of the model.
     */
    name: string;

    /**
     * A Map containing a list of slides and their source url.
     */
    slides: Map<string, SlideSourceInfo>;

    /**
     * Method to be triggered when slides index has changed.
     * @param indexTab New selected index.
     */
    onSlideChanged: (indexTab: number) => void;

    /**
     * Returns the srcHtml which is used by the ng-includes inside a template.
     */
    getSlideSrc: (keyName: string) => string;

    /**
     * An array holding the names of authorized slides.
     */
    validSlides: string[];
}
