/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { Involvement } from '../../../schema/Involvement';

/**
 * The dissemination model
 */
@table('dsmain')
export class Dissemination {

    @field('address')
    public address: string;

    @field('agency')
    public agency: string;

    @expand('dsmnatr', 'fullnam')
    public byWho: string;

    @field('method')
    public disseminationMethod: string;

    @field('info')
    public information: string;

    @field('organ')
    public organization: string;

    @field('reason')
    public reasonForInquiry: string;

    @field('recip')
    public recipient: string;

    @field('dsdate')
    public when: Date;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];
}
