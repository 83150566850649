/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import { IRootScopeService, IScope } from 'angular';

import CustomFilterByAgencies from '../../../../../../app/cad/filtering/custom-filters/CustomFilterByAgencies';
import { log, logError } from '../../../../../../app/api/analytics/firebase-crashlytics-service';
import { AgencyService } from '../../../../services/agency/agency-service';
import { AgencyModel } from '../../../../services/agency/agencyModel';
import Session from '../../../../../../app/login/Session';


export default abstract class AgencyComponentController {
    protected preSelectedValues: FilterModel[];

    constructor(
        protected $scope: IScope,
        protected $rootScope: IRootScopeService,
        public customFilterByAgencies: CustomFilterByAgencies,
        public agencyService: AgencyService,
        protected session: Session,
        protected RETRY: string
    ) {
        this.preSelectedValues = this.customFilterByAgencies.getEditingValues();

        $scope.agencies = [];

        $scope.userAgency = this.session.data.agencyCode;

        $scope.newItemSelected = this.newItemSelected;

        $scope.error = false;

        $scope.isLoading = false;

        $rootScope.$on(this.RETRY, () => {
            this.loadAllAgencies();
        });
    }

    protected loadAllAgencies = () => {
        this.$scope.error = false;
        this.$scope.isLoading = true;
        this.$scope.agencies = [];

        this.agencyService.getAgencies().then(this.handleLoadAgencySuccess).catch(this.handleLoadAgencyError);
    };

    private handleLoadAgencySuccess = (data: []) => {
        if (this.preSelectedValues !== undefined && this.preSelectedValues.length > 0) {
            data.map((item: AgencyModel) => {
                item.checked = this.preSelectedValues.find((subItem: FilterModel) => subItem.id === item.abbreviation && subItem.applied) !== undefined;
                item.applied = item.checked;
                return item;
            });
        }

        // Sort it by getting applied items fisrt
        data.sort((a: AgencyModel, b: AgencyModel) => {
            if ((a.applied && !b.applied) || (a.abbreviation === this.$scope.userAgency)) {
                return -1;
            } else if (!a.applied && b.applied || (b.abbreviation === this.$scope.userAgency)) {
                return 1;
            } else {
                return 0;
            }
        });

        this.$scope.agencies = data;
        this.$scope.error = false;
        this.$scope.isLoading = false;
    };

    private handleLoadAgencyError = (error: any) => {
        this.$scope.error = true;
        this.$scope.isLoading = false;

        log('load agency error');
        logError(error);
    };

    private newItemSelected = (data: any) => {
        this.customFilterByAgencies.setValues(data);
    };

    public $onInit = () => {
        this.loadAllAgencies();
    };
}
