/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../../shared/database/decorators';
import { NameReference } from '../../../shared/model/name-reference';
import { IncidentReference } from '../../../shared/model/incident-reference';
import { Involvement } from '../../../../schema/Involvement';
import { ArrestJoin } from './arrest-join';
import { BookingJoin } from './booking-join';

/**
 * The jail offense model
 */
@table('jloffens')
export class JailOffense {

    @field('num')
    public id: string;

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @expand('inmate.namenum', NameReference)
    public inmate: NameReference | string;

    @expand('lawnum', IncidentReference)
    public relatedIncident: IncidentReference | string;

    @includeMany('jlofxar', ArrestJoin)
    public arrestJoins: ArrestJoin[];

    @includeMany('jlbkxof', BookingJoin)
    public bookingJoins: BookingJoin[];

    @field('date')
    public date: Date;

    @field('counts')
    public counts: string;

    @field('statute')
    public statute: string;

    @expand('statute', 'desc')
    public statuteDescription: string;

    @expand('offense', 'desc')
    public offense: string;

    @expand('type', 'desc')
    public type: string;

    @expand('classif', 'desc')
    public crimeClass: string;

    @expand('jurisd', 'desc')
    public jurisdiction: string;

    @expand('ncic', 'descrpt')
    public ncic: string;

    @field('locatn')
    public location: string;

    @expand('area', 'desc')
    public area: string;

    @field('tnsuffx')
    public tnsuffix: string;

    @field('ref')
    public reference: string;

    @expand('entry', 'descrpt')
    public entry: string;

    @expand('court', 'desc')
    public court: string;

    @field('alcoho')
    public alcoholDrug: string;

    @expand('dispo', 'descrpt')
    public disposition: string;

    @field('disdate')
    public dispositionDate: Date;

    // Additonal Info
    @includeOne('jlofnarr', 'text')
    public comments: string;
}
