/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { PropertyNode } from './property-node';
import { ClassNode } from '../class/class-node';
import { ClassNodeFactory } from '../class/class-node-factory';
import { TreeNode } from '../../../../data-structures/tree-node';

/**
 * A node that associates a single included database object with a property of a JavaScript class.
 * This node pertains when you have a one-to-one relationship between database tables.
 */
export class IncludeOneNode extends PropertyNode {

    /**
     * Constructs a new instance of the IncludeOneNode class.
     *
     * @param parent The parent ClassNode.
     * @param propertyKey The property key (or name if you prefer).
     * @param table The database table to include.
     * @param nodeFactory A factory that knows how to create ClassNodes.
     */
    constructor(parent: ClassNode, propertyKey: string | symbol, public table: string, private nodeFactory: ClassNodeFactory) {
        super(parent, propertyKey);
    }

    /**
     * @inheritdoc
     */
    protected *initializeChildren(): Iterable<TreeNode> {
        const childConstructor = Reflect.getMetadata('design:type', this.parent.classConstructor.prototype, this.propertyKey);

        if (!childConstructor) {
            throw new Error(`Unable to get the type of the ${this.parent.classConstructor.name}.${this.propertyKey as string} property`);
        } else if (childConstructor === Array) {
            throw new Error(`The ${this.parent.classConstructor.name}.${this.propertyKey as string} property cannot be an array since the @includeOne decorator was used`);
        }

        yield this.nodeFactory.create(childConstructor);
    }
}
