/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IResourceClass = angular.resource.IResourceClass;
import IHttpService = angular.IHttpService;
import FileType from '../schema/FileType';
import ComputedUrlResource from '../resource/computedUrl/ComputedUrlResource';
import IHttpResponseTransformer = angular.IHttpResponseTransformer;

/**
 * $inject annotation.
 * It provides $injector with information about dependencies to be injected into constructor.
 * See http://docs.angularjs.org/guide/di
 */
FileTypeAPI.$inject = ['computedUrlResource', '$http', 'getFirstElementTransform'];

/**
 * A factory function that creates an API for retrieving file types from the server.
 *
 * @param computedUrlResource A specialized version of the `$resource` service that automatically computes the URL on each request.
 * @param $http The Angular service that makes http requests.
 * @param getFirstElementTransform A response transform that returns only the first element from the array.
 * @returns {IResourceClass<FileType>} A resource class that knows how to load file types from the REST server.
 */
function FileTypeAPI(
    computedUrlResource: ComputedUrlResource,
    $http: IHttpService,
    getFirstElementTransform: IHttpResponseTransformer
): IResourceClass<FileType> {
    return computedUrlResource<FileType>({ path: '/tables/fctype/:id' }, {}, {
        get: {
            method: 'GET',
            transformResponse: angular.appendTransform($http.defaults.transformResponse, getFirstElementTransform)
        }
    });
}

export default FileTypeAPI;
