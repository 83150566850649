/* Copyright © 2023 Motorola Solutions, Inc. All rights reserved. */

import IonicHistoryService = ionic.navigation.IonicHistoryService;
import { IRootScopeService, IScope } from 'angular';
import IonicScrollDelegate = ionic.scroll.IonicScrollDelegate;
import CustomFilterAllFilters from '../../../filtering/custom-filters/CustomFilterAllFilters';
import { AgencyService } from '../../../services/agency/agency-service';
import { ZoneService } from '../../../services/zones/zone-service';
import { logEvent } from '../../../../api/analytics/firebase-analytics-service';
import { callAnalyticsEvents } from '../../../filtering/analytics/events';
import FiltersStorage from '../../../filtering/storage/FiltersStorage';
import { CALL_ACTIONS } from '../../../filtering/consts';

export default class FilterScreenController {

    public static readonly routeName = 'app.calls.filterScreen';

    public static $inject = [
        '$rootScope',
        '$ionicHistory',
        '$scope',
        '$ionicScrollDelegate',
        'callCustomFilterAllFilters',
        'callFiltersStorage',
        'agencyService',
        'zoneService'
    ];

    constructor(
        private $rootScope: IRootScopeService,
        private $ionicHistory: IonicHistoryService,
        $scope: IScope,
        private $ionicScrollDelegate: IonicScrollDelegate,
        private customFilterAllFilters: CustomFilterAllFilters,
        private filtersStorage: FiltersStorage,
        private agencyService: AgencyService,
        private zoneService: ZoneService
    ) {
        this.agencyService.isLoading.subscribe((isLoading: boolean) => {
            $scope.agencyIsLoading = isLoading;
        });

        this.zoneService.isLoading.subscribe((isLoading: boolean) => {
            $scope.zoneIsLoading = isLoading;
        });
    }

    public searchFilterLoseFocus() {
        if (this.$ionicScrollDelegate.getScrollPosition().top > 0) {
            this.$rootScope.$broadcast(`${CALL_ACTIONS.CLEAN_FOCUS}`);
        }
    }

    public clearAll() {
        logEvent(callAnalyticsEvents.clear_all_filters);
        this.$rootScope.$broadcast(`${CALL_ACTIONS.CLEAN_FIELDS}`);
    }

    public applyFilter() {
        this.filtersStorage.promoteEditingAsCurrent();

        this.customFilterAllFilters.applyAllFilters();
        this.$rootScope.$broadcast(`${CALL_ACTIONS.ADVANCED_FILTER_CHANGED}`);
        logEvent(callAnalyticsEvents.apply_all_filters);
        this.$ionicHistory.goBack();
    }

    public $onInit = () => { };

    private getEditingFilterName(): string {
        return this.filtersStorage.getEditingFilterName();
    }

    private isEditingFilterSaved(): boolean {
        return this.filtersStorage.isEditingFilterSaved();
    }

    public getHeader = () => {
        return (this.isEditingFilterSaved() ? this.getEditingFilterName() : 'Call Filters');
    };
}
