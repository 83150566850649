/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Component, Input } from '@angular/core';
import { IncidentReference } from '../../model/incident-reference';

/**
 * A component which displays a field which links to an incident.
 */
@Component({
    selector: 'sds-incident-link',
    template: `
        <sds-permissions-link state="app.search.incidentsDetails" [id]="incident.id" *ngIf="incident && incident.id">
            <p><span>{{incident.id}}</span></p>
            <p *ngIf="incident.nature"><span>{{incident.nature}}</span></p>
        </sds-permissions-link>
        <span *ngIf="incident && !incident.id">{{incident}}</span>
        <span *ngIf="!incident">&nbsp;</span>
    `,
    styles: [
        require('./link.component.scss')
    ]
})
export class IncidentLinkComponent {

    /**
     * The incident to display.
     */
    @Input() public incident: IncidentReference;
}
