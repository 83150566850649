/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Injectable, Inject } from '@angular/core';
import { StateHistoryStorage } from './state-history-storage.service';
import { HOME_STATE_POLICY_TOKEN, HomeStatePolicy } from './home-state-policies';

/**
 * Determines what the user's home screen should be based on their navigation history.
 */
@Injectable()
export class HomeStateProvider {

    /**
     * Constructs a new instance of the HomeStateProvider class.
     *
     * @param stateHistoryStorage
     * @param homeStatePolicies
     */
    constructor(private stateHistoryStorage: StateHistoryStorage,
        @Inject(HOME_STATE_POLICY_TOKEN) private homeStatePolicies: HomeStatePolicy[]) {
    }

    /**
     * Retrieves the home state based on the user's navigation history.
     *
     * @returns The name of the home state.
     */
    public getHomeState() {
        const history = this.stateHistoryStorage.load();

        for (let policy of this.homeStatePolicies) {
            const stateName = policy.getHomeState(history);
            if (stateName) {
                return stateName;
            }
        }

        throw new Error('Unable to find the home state. Please ensure that the fallback state is setup correctly.');
    }
}
