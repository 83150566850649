/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeOne, includeMany, involvements, involvementsAlerts } from '../../../shared/database/decorators';
import { CivilProcessName } from './civil-process-name';
import { CivilProcessType } from './civil-process-type';
import { NameReference } from '../../shared/model/name-reference';
import { Involvement } from '../../../schema/Involvement';
import { IncidentReference } from '../../shared/model/incident-reference';

/**
 * The civil process model
 */
@table('cpmain')
export class CivilProcess {

    @involvements()
    public involvements: Involvement[];

    @involvementsAlerts()
    public involvementsAlerts: string[];

    @field('agency')
    public agency: string;

    @field('bname')
    public billingName: string;

    @expand('bnameid', NameReference)
    public billTo: NameReference | string;

    @field('bcity')
    public city: string;

    @includeOne('cpmdesc', 'desc')
    public comments: string;

    @field('copies')
    public copiesReceived: number;

    @expand('court', 'desc')
    public court: string;

    @field('courtdt')
    public courtDate: Date;

    @field('courtno')
    public courtNumber: string;

    @field('issuedt')
    public dateIssued: Date;

    @field('retrndt')
    public dateReturned: Date;

    @field('exprdt')
    public expirationDate: Date;

    @field('judge')
    public judge: string;

    @includeMany('cpnames', CivilProcessName)
    public civilProcessNames: CivilProcessName[];

    @field('bphone')
    public phone: string;

    @includeMany('cptypes', CivilProcessType)
    public processTypes: CivilProcessType[];

    @field('recvdt')
    public received: Date;

    @expand('incinum', IncidentReference)
    public relatedIncident: IncidentReference | string;

    @field('bstate')
    public state: string;

    @field('bstreet')
    public street: string;

    @field('bzip')
    public zip: string;
}
