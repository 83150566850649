/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IQService = angular.IQService;
import IRootScopeService = angular.IRootScopeService;
import SettingKey from '../SettingKey';
import Setting from '../Setting';
import SettingStorage from '../SettingStorage';
import Option from '../Option';
import FontSize from '../../fontSize/FontSize';

/**
 * The setting that determines which CSS font size is currently used.
 */
export default class FontSizeSetting extends Setting<FontSize> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['$q', 'settingStorage', '$rootScope'];

    /**
     * Constructs a new instance of the FontSizeSetting class.
     *
     * @param $q The angular service that handles creating and working with promises.
     * @param settingStorage The object that handles persistent storage of the setting value.
     * @param $rootScope The Angular $rootScope that can be used to broadcast events throughout the app.
     */
    constructor($q: IQService, settingStorage: SettingStorage, $rootScope: IRootScopeService) {
        super($q, settingStorage, $rootScope, SettingKey.fontSizeSetting, 'Font Size', [
            new Option('Small', FontSize.small),
            new Option('Medium', FontSize.medium),
            new Option('Large', FontSize.large)
        ]);

    }
}
