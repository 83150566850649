/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

// This file statically imports each and every file that needs to later be loaded via ng-include.
// This makes it so that ng-include will work with webpack by using the ng-cache-loader.

// Accident
import './rms/accident/slides/names.html';
import './rms/accident/slides/vehicles.html';

// Attachments
import './rms/shared/attachments/add/files/filePreview.html';
import './rms/shared/attachments/add/images/imagePreview.html';

// Case File
import './rms/case-file/slides/defendants.html';
import './rms/case-file/slides/offenses.html';
import './rms/case-file/slides/witnesses.html';

// Civil Process
import './rms/civil-process/slides/names.html';

// Demographic
import './rms/demographic/slides/contacts.html';

// Dissemination
import './rms/dissemination/slides/information.html';

// EMS Incident
import './rms/incident/ems/detail/slides/patients.html';

// Jail Arrest, Booking and Offense
import './rms/jail/shared/slides/offenses.html';
import './rms/jail/shared/slides/arrests.html';
import './rms/jail/shared/slides/bookings.html';

// License & Permit
import './rms/license-permit/slides/contacts.html';
import './rms/license-permit/slides/vehicles.html';

// Miscellaneous
import './rms/miscellaneous/slides/description.html';

// Names
import './rms/name/detail/slides/notices.html';

// Name Association
import './rms/name-association/slides/associates.html';

// Offender Tracking
import './rms/offender-tracking/slides/activities.html';
import './rms/offender-tracking/slides/offenses.html';
import './rms/offender-tracking/slides/vehicles.html';

// Pawn Activity
import './rms/pawn-activity/slides/activities.html';

// Pawned Property
import './rms/pawned-property/slides/description.html';

// Premise
import './rms/premise/slides/businesses.html';

// Property
import './rms/property/detail/slides/custody-changes.html';

// Search
import './searching/slides/name-search.html';
import './searching/slides/vehicle-search.html';
import './searching/slides/property-search.html';
import './searching/slides/incident-search.html';

// Shared
import './rms/shared/slides/business-contacts.html';
import './rms/shared/slides/comments.html';
import './rms/shared/slides/involvements.html';
import './rms/shared/slides/radio-logs.html';
import './rms/shared/slides/supplements.html';

// Suspect Vehicles
import './rms/suspect-vehicle/slides/characteristics.html';

// SVG Icons
import '../assets/icons/Filter.svg';

// Vehicles
import './rms/vehicle/detail/slides/addresses.html';

// Wants
import './rms/want/slides/offenses.html';
import './rms/want/slides/attempts.html';
