/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import { createDetailState, DetailStateOptions } from '../state-definition-helpers';
import PermissionName from '../../permissions/enums/PermissionName';
import { upgradeAdapter } from '../../upgrade-adapter';
import { AccidentDetailComponent } from './accident-detail.component';
import { AccidentNameDetailComponent } from './accident-name/accident-name-detail.component';
import { AccidentRoadwayDetailComponent } from './accident-roadway/accident-roadway-detail.component';
import { AccidentVehicleDetailComponent } from './accident-vehicle/accident-vehicle-detail.component';

export const accidentModule = angular.module('accident', [])
    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.search.accidentDetails', createDetailState('accident', 'acmain', PermissionName.accident as DetailStateOptions))
            .state('app.search.accidentNameDetails', createDetailState('accident-name', 'acnmdtl', { permission: PermissionName.accident, customUrl: '/results/accident/:id/name/:nameId' }))
            .state('app.search.accidentRoadwayDetails', createDetailState('accident-roadway', 'acrddtl', { permission: PermissionName.accident, customUrl: '/results/accident/:id/roadway' }))
            .state('app.search.accidentVehicleDetails', createDetailState('accident-vehicle', 'acvhdtl', { permission: PermissionName.accident, customUrl: '/results/accident/:id/vehicle/:vehicleId' }));
    }])

    .directive('sdsAccidentDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(AccidentDetailComponent))
    .directive('sdsAccidentNameDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(AccidentNameDetailComponent))
    .directive('sdsAccidentRoadwayDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(AccidentRoadwayDetailComponent))
    .directive('sdsAccidentVehicleDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(AccidentVehicleDetailComponent));
