/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipePolyfill } from './polyfill-date.pipe';

/**
 * The standardized format in which all dates (without a time component) will be displayed.
 */
export const STANDARD_DATE_FORMAT = 'MM/dd/yyyy';

/**
 * A pipe that formats a date (without a time component) in a standardized way.
 */
@Pipe({
    name: 'sdsDate'
})
export class DatePipe implements PipeTransform {

    /**
     * Constructs a new instance of the DatePipe class.
     *
     * @param datePipe The pipe that knows how to format dates.
     */
    constructor(private datePipe: DatePipePolyfill) {
    }

    /**
     * Displays a date in a standardized, human-friendly format.
     *
     * @param value Either a Date or a string representation of a date in the Spillman format.
     */
    public transform(value: string | Date): string {
        return this.datePipe.transform(value, STANDARD_DATE_FORMAT);
    }
}
