/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IScope = angular.IScope;
import IAugmentedJQuery = angular.IAugmentedJQuery;
import IAttributes = angular.IAttributes;
import IDirective = angular.IDirective;
import ICompileService = angular.ICompileService;

settingModelDirective.$inject = ['$compile'];

/**
 * A factory function that creates a directive that binds the ng-model and ng-options
 * of an HTML <select> to a Setting.
 *
 * @param $compile The Angular compiler used to process raw DOM elements.
 * @returns A directive that binds the ng-model and ng-options of an HTML <select> to a Setting.
 */
function settingModelDirective($compile: ICompileService): IDirective {
    const normalizedDirectiveName = 'sdsSettingModel';
    const hyphenatedDirectiveName = normalizedDirectiveName.replace(/([A-Z])/g, '-$1').toLowerCase();

    return {
        restrict: 'A',
        priority: 1201, // A high number so that this directive gets compiled before any others.
        terminal: true, // Don't initially compile any other directives.
        scope: {
            setting: '=' + normalizedDirectiveName
        },
        link(scope: IScope, element: IAugmentedJQuery, attributes: IAttributes) {
            // Add the new directives to the element.
            attributes.$set('ngModel', 'setting.selectedIndex');
            attributes.$set('ngModelOptions', '{getterSetter: true}');
            attributes.$set('ngOptions', '+index as option.text for (index, option) in setting.options | requiresUnitFilter');

            // Remove this directive so that it won't be compiled again.
            element.removeAttr(hyphenatedDirectiveName);

            // Recompile the element with the new directives.
            $compile(element)(scope);
        }
    };
}

export default settingModelDirective;
