/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';

/**
 * A want service attempt.
 */
@table('waserve')
export class ServiceAttempt {

    @field('when')
    public when: Date;

    @expand('by', 'fullnam')
    public by: string;

    @field('comment')
    public comment: string;
}
