/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../shared/database/decorators';
import { NameReference } from '../../shared/model/name-reference';

/**
 * The civil process name model
 */
@table('cpnames')
export class CivilProcessName {

    @expand('nameid', NameReference)
    public name: NameReference | string;

    @field('serve')
    public serve: string;

    @field('nmtype')
    public type: string;
}
