/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand, includeMany } from '../../../../shared/database/decorators';
import { Endorsement } from './endorsement';
import { Restriction } from './restriction';

/**
 * The endorsements and restrictions on a drivers license.
 */
@table('nmdlinfo')
export class DriverLicenseInfo {

    @includeMany('nmdlend', Endorsement)
    public endorsements: string[];

    @includeMany('nmdlrest', Restriction)
    public restrictions: string[];

    @expand('dltype', 'descrpt')
    public dlType: string;

    @field('num') // TODO: Would be nice to be able to do 'dltype.descrpt'
    public num: string;

    @field('expdate')
    public expiration: string;
}
