/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, expand } from '../../../../shared/database/decorators';

/**
 * A property characteristic.
 */
@table('prmchar')
export class Characteristic {

    @expand('ccode', 'desc')
    public description: string;
}
