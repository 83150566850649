/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

/**
 * The error message that is given if zero sets are passed in.
 */
export const noSetsErrorMessage = 'At least one set must be given';

/**
 * Computes the union one or more sets.
 *
 * @param sets The sets for which to compute the union. There must be one or more sets.
 * @returns A set which is the union of those that were passed in.
 */
export function union<T>(...sets: Set<T>[]): Set<T> {
    if (sets.length < 1) {
        throw new Error(noSetsErrorMessage);
    }

    if (sets.length === 1) {
        return new Set(sets[0]);
    }

    const unionOfOthers = union(...sets.slice(1));
    return new Set([...sets[0], ...unionOfOthers]);
}

/**
 * Computes the intersection of one or more sets.
 *
 * @param sets The sets for which to find the intersection. There must be one or more sets.
 * @returns A set which is the intersection of those that were passed in.
 */
export function intersect<T>(...sets: Set<T>[]): Set<T> {
    if (sets.length < 1) {
        throw new Error(noSetsErrorMessage);
    }

    if (sets.length === 1) {
        return new Set(sets[0]);
    }

    const intersectionOfOthers = intersect(...sets.slice(1));
    return new Set([...sets[0]].filter(i => intersectionOfOthers.has(i)));
}
