/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import IResourceClass = angular.resource.IResourceClass;
import ImageType from '../../schema/ImageType';
import TableCache from './../TableCache';
import IQService = angular.IQService;

/**
 * Caches the imgtype database table.
 */
export default class ImageTypeCache extends TableCache<ImageType> {

    /**
     * $inject annotation.
     * It provides $injector with information about dependencies to be injected into constructor.
     * See http://docs.angularjs.org/guide/di
     */
    public static $inject = ['imageTypeAPI', '$q'];

    /**
     * Constructs a new instance of the ImageTypeCache class.
     *
     * @param imageTypeAPI The service that retrieves image types from the server.
     * @param $q The angular service that handles creating and working with promises.
     */
    constructor(imageTypeAPI: IResourceClass<ImageType>, $q: IQService) {
        super(imageTypeAPI, $q, 'code');
    }
}
