/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IProvideService = angular.auto.IProvideService;
import IQService = angular.IQService;
import IInjectorService = angular.auto.IInjectorService;
import { ReAuthenticationService, Credentials, authorizationHeaderName } from '../authentication';
import * as jwt_decode from 'jwt-decode';

export function HTTPWrapper($provide: IProvideService) {

    /* eslint-disable dot-notation,@typescript-eslint/dot-notation */
    if (window.cordova) {
        $provide.decorator('$http', ['$delegate', '$q', '$injector', function ($delegate: any, $q: IQService, $injector: IInjectorService) {
            let reauthAttempts = 0;
            let requests: any[] = [];
            let deferredRequests: any[] = [];
            const $http = $delegate;
            const methods = ['get', 'post', 'put', 'delete'];

            const logInfo = (message: string, data?: any) => {
                const isDevelop = false;
                if (isDevelop) {
                    // eslint-disable-next-line no-console
                    data ? console.log(message, data) : console.log(message);
                }
            };
            // execute if header value is a function for merged headers
            const executeHeaderFns = (headers: any, config: any) => {
                let headerContent = {};
                let processedHeaders = {};

                angular.forEach(headers, function (headerFn: Function, header: string) {
                    if (angular.isFunction(headerFn)) {
                        headerContent = headerFn(config);
                        if (angular.isDefined(headerContent)) {
                            processedHeaders[header] = headerContent;
                        }
                    } else {
                        processedHeaders[header] = headerFn;
                    }
                });
                return processedHeaders;
            };

            /**
             * Chain all given functions
             *
             * This function is used for both request and response transforming
             *
             * @param {*} data Data to transform.
             * @param {function(string=)} headers HTTP headers getter fn.
             * @param {number} status HTTP status code of the response.
             * @param {(Function|Array.<Function>)} fns Function or an array of functions.
             * @returns {*} Transformed data.
             */
            const transformData = (data: any, headers: any, status: any, fns: any) => {
                if (angular.isFunction(fns)) {
                    return fns(data, headers, status);
                }

                angular.forEach(fns, function (fn: Function) {
                    data = fn(data, headers, status);
                });

                return data;
            };

            /**
             * Creates a shallow copy of an object, an array or a primitive.
             *
             * Assumes that there are no proto properties for objects.
             */
            const shallowCopy = (src: any, dst?: any) => {
                if (angular.isArray(src)) {
                    dst = dst || [];

                    for (let i = 0, ii = src.length; i < ii; i++) {
                        dst[i] = src[i];
                    }
                } else if (angular.isObject(src)) {
                    dst = dst || {};

                    for (let key in src) {
                        if (!(key.charAt(0) === '$' && key.charAt(1) === '$')) {
                            dst[key] = src[key];
                        }
                    }
                }
                ;
                return dst || src;
            };

            /**
             * Parse headers into key value object
             *
             * @param {string} headers Raw headers as a string
             * @returns {Object} Parsed headers as key value object
             */
            const parseHeaders = (headers: any) => {
                let parsed = {};
                let i;

                function fillInParsed(key: any, val: any) {
                    if (key) {
                        parsed[key] = parsed[key] ? parsed[key] + ', ' + val : val;
                    }
                }

                if (angular.isString(headers)) {
                    angular.forEach(headers.split('\n'), function (line: string) {
                        i = line.indexOf(':');
                        fillInParsed(angular.lowercase((line.substr(0, i))).trim(), (line.substr(i + 1)).trim());
                    });
                } else if (angular.isObject(headers)) {
                    angular.forEach(headers, function (headerVal: string, headerKey: string) {
                        fillInParsed(angular.lowercase(headerKey), (headerVal).trim());
                    });
                }
                return parsed;
            };

            /**
             * Returns a function that provides access to parsed headers.
             *
             * Headers are lazy parsed when first requested.
             * @see parseHeaders
             *
             * @param {(string|Object)} headers Headers to provide access to.
             * @returns {function(string=)} Returns a getter function which if called with:
             *
             *   - if called with single an argument returns a single header value or null
             *   - if called with no arguments returns an object containing all headers.
             */
            const headersGetter = (headers: any) => {
                let headersObj: any;

                return function (name: any) {
                    if (!headersObj) {
                        headersObj = parseHeaders(headers);
                    }
                    ;
                    if (name) {
                        let value = headersObj[angular.lowercase(name)];
                        if (value === void 0) {
                            value = undefined;
                        }
                        return value;
                    }
                    return headersObj;
                };
            };

            const mergeHeaders = (config: any) => {
                let defHeaders = $http.defaults.headers;
                let reqHeaders = angular.extend({}, config.headers);
                let defHeaderName;
                let lowercaseDefHeaderName;
                let reqHeaderName;

                defHeaders = angular.extend({}, defHeaders.common, defHeaders[angular.lowercase(config.method)]);

                // using for-in instead of forEach to avoid unnecessary iteration after header has been found
                defaultHeadersIteration:
                for (defHeaderName in defHeaders) {
                    lowercaseDefHeaderName = angular.lowercase(defHeaderName);

                    for (reqHeaderName in reqHeaders) {
                        if (angular.lowercase(reqHeaderName) === lowercaseDefHeaderName) {
                            continue defaultHeadersIteration;
                        }
                    }
                    reqHeaders[defHeaderName] = defHeaders[defHeaderName];
                }
                // execute if header value is a function for merged headers
                return executeHeaderFns(reqHeaders, shallowCopy(config));
            };

            const modifyConfig = (requestConfig: any) => {
                const config = angular.extend({
                    transformRequest: $http.defaults.transformRequest,
                    transformResponse: $http.defaults.transformResponse,
                    paramSerializer: $http.defaults.paramSerializer,
                    timeout: 20000
                }, requestConfig);

                config.headers = mergeHeaders(requestConfig);
                config.method = angular.uppercase(config.method);
                config.paramSerializer = angular.isString(config.paramSerializer) ?
                    $injector.get(config.paramSerializer) : config.paramSerializer;

                const headers = config.headers;
                const reqData = transformData(config.data, headersGetter(headers), undefined, config.transformRequest);

                // strip content-type if data is undefined
                if (angular.isUndefined(reqData)) {
                    angular.forEach(headers, function (...params: Array<any>) {
                        if (angular.lowercase(params[1]) === 'content-type') {
                            delete headers[params[1]];
                        }
                    });
                }

                if (angular.isUndefined(config.withCredentials) && !angular.isUndefined($http.defaults.withCredentials)) {
                    config.withCredentials = $http.defaults.withCredentials;
                }
                return config;
            };

            const sendRequest = (config: any, d: any) => {
                try {
                    logInfo(`[ng1] ${config.method}  -----> using native HTTP with: ${encodeURI(config.url)}`);

                    const onSuccess = function (success: any) {
                        // automatic JSON parse if no responseType: text
                        // fall back to text if JSON parse fails too
                        if (config.responseType === 'text') {
                            // don't parse into JSON
                            d.resolve(success);
                            return d.promise;
                        } else {
                            try {
                                const data = JSON.parse(success.data);
                                success.data = data.data || data;
                                d.resolve(success);
                                return d.promise;
                            } catch (e) {
                                logInfo(`[ng1]*** Native HTTP response: JSON parsing failed for ${config.url}`);
                                d.reject(e);
                                return d.promise;
                            }
                        }

                    };
                    const onError = function (error: Error) {
                        logInfo('[ng1]***  Inside native HTTP error: ', JSON.stringify(error));
                        d.reject(error);
                        return d.promise;
                    };

                    // fix of incorrect encryption of " and ' symbols for iOS platform
                    if (config.params && config.params.comment) {
                        config.params.comment = encodeURI(config.params.comment);
                        config.params.comment = decodeURI(config.params.comment
                            .replace(/%E2%80%98/g, "'")
                            .replace(/%E2%80%99/g, "'")
                            .replace(/E2%80%9C/g, '22')
                            .replace(/E2%80%9D/g, '22'));
                    }

                    if (!config.data.file) {
                        /* eslint-disable dot-notation,@typescript-eslint/dot-notation */
                        cordova['plugin'].http.sendRequest(
                            encodeURI(config.url),
                            config,
                            onSuccess,
                            onError);
                    } else {
                        /* eslint-disable dot-notation,@typescript-eslint/dot-notation */
                        cordova['plugin'].http.uploadFile(
                            encodeURI(config.url),
                            config.params,
                            config.headers,
                            config.data.file.path,
                            config.data.file.name,
                            onSuccess,
                            onError);
                    }
                } catch (e) {
                    logInfo('[ng1]*** Failed to send request: ', e);
                }
            };

            const setConfig = (args: any) => {
                args.data = args.data || {};
                if (args.params) {
                    Object.keys(args.params).forEach((key: string) => {
                        args.params[key] = args.params[key] ? args.params[key].toString() : '';
                    });
                }
                ;
                if (args.data && args.data.file) {
                    args.headers = {
                        'Content-Type': ''
                    };
                }
                ;
                return modifyConfig(args);
            };

            const transformResponse = function (response: any) {
                // make a copy since the response must be cacheable
                const isSuccess = (status: any) => (200 <= status && status < 300);
                const resp = angular.extend({}, response);
                resp.data = transformData(response.data, response.headers, response.status, response.config.transformResponse);
                return (isSuccess(response.status)) ? resp : $q.reject(resp);
            };

            /**
             * Update auth header of request
             */
            const setRequestAuthHeader = (request: any) => {
                request.headers[authorizationHeaderName] = $http.defaults.headers.common[authorizationHeaderName];
            };

            // If token is expired, re-authenticate and renew deferred requests.
            const reAuthenticate = function (config: any, d: any) {
                let reAuthenticationService = $injector.get<ReAuthenticationService<Credentials>>('reAuthenticationService');
                deferredRequests[reauthAttempts] = d;
                if (!reauthAttempts) {
                    reAuthenticationService.reAuthenticate()
                        .toPromise()
                        .then(() => {
                            setRequestAuthHeader(config);
                            sendRequest(config, deferredRequests[0]);

                            requests.forEach((item: any, index: number) => {
                                setRequestAuthHeader(item);
                                sendRequest(item, deferredRequests[index + 1]);
                            });
                            deferredRequests = [];
                            reauthAttempts = 0;
                            requests = [];
                        },
                        (e: any) => {
                            deferredRequests[reauthAttempts - 1].reject(e);
                            return deferredRequests[reauthAttempts - 1].promise;
                        });
                } else {
                    requests.push(config);
                }
                reauthAttempts++;
            };

            const wrapper = function (...args: any[]) {
                const url = args[0].url;
                const isOutgoingRequest = /^(http|https):\/\//.test(url);

                if (isOutgoingRequest) {
                    const d = $q.defer();
                    const config = setConfig(args[0]);
                    const currentToken = config.headers[authorizationHeaderName];
                    const decoded: { exp: number; iat: number } = jwt_decode(currentToken) as { exp: number; iat: number };
                    const currentDate = Date.now();
                    const isTokenExpired = decoded.exp < currentDate / 1000;

                    if (isTokenExpired) {
                        reAuthenticate(config, d);
                    } else {
                        sendRequest(config, d);
                    }
                    return d.promise.then(function (resp: any) {
                        const reqHeaders = modifyConfig(args[0]).headers;
                        const respHeaders = resp.headers;
                        const headers = {...reqHeaders, ...respHeaders};

                        resp.headers = args[0].headers || headersGetter(headers);

                        if (args[0].method.toLowerCase() === 'post' && resp.data.length === 1) {
                            resp.data = resp.data[0];
                        }
                        logInfo(`[ng1] response <----- ${encodeURI(url)}`, resp);
                        resp.config = config;
                        return transformResponse(resp);
                    }, function (responseError: any) {
                        logInfo('', responseError);
                        return $q.reject(responseError);
                    });
                } else {
                    return $http.apply($http, arguments);
                }
            };

            const reqMethodDelegate = (method: string) => {
                return function (url: string, config: any, data?: any) {
                    return data ? wrapper(angular.extend(config || {}, {
                        method, url
                    })) : wrapper(angular.extend(config || {}, {
                        method, url, data
                    }));
                };
            };

            // wrap around all HTTP methods
            Object.keys($http).forEach(function (key: string) {
                if (typeof $http[key] === 'function') {
                    wrapper[key] = function () {
                        return $http[key].apply($http, arguments);
                    };
                } else {
                    wrapper[key] = $http[key];
                }
            });

            methods.forEach(method => {
                $delegate[method] = reqMethodDelegate(method);
            });

            return wrapper;
        }]);
    }
}
