/**
 * An associative array of error messages that this class may return.
 */
export const errorMessages = {
    default: 'Login failed',
    defaultCommon: 'The operation cannot be performed. Please contact your administrator.',
    status400: 'Invalid username or password',
    status404: 'The operation cannot be performed because the endpoint does not exist. Please contact your administrator.'
};

/**
 * A map that maps a status code to a custom error message.
 */
export const statusToErrorMessageMap = new Map<number, string>([
    [400, errorMessages.status400],
    [404, errorMessages.status404]
]);

/**
 * An associative array of error messages that this class may return.
 */
export const passwordExpirationStatuses = {
    ok: 'OK',
    passwordExpired: 'password expired',
    expireIn: 'expire in',
    accountLocked: 'account locked',
    requestIncorrect: 'request incorrect'
};

export const passwordExpirationMessages = {
    passwordExpired: 'Your password has expired.',
    passwordExpiresToday: 'Your password expires today.'
};

export const accountStateMessages = {
    accountLocked: 'Your account is locked, please contact your administrator.'
};
export const changePasswordStatuses = {
    passwordChanged: 'password changed',
    passwordNotChanged: 'password not changed',
    requestIncorrect: 'request incorrect',
    tRecordLock: 't - could not obtain data record lock',
    notAllowChangePass: 'you are not allowed to change your password'
};

export const changePasswordTitles = {
    passwordChange: 'Password Change',
    passwordExpire: 'Password will expire soon'
};

export const changePasswordMessages = {
    passwordChanged: 'Your password is successfully changed.',
    passwordNotChanged: 'Your password has not been changed, please contact your administrator.',
    passwordWasNotChanged: 'Your password has not been changed: ',
    passwordNotAllowed: 'You are not allowed to change your password.',
    pleaseContactAdmin: 'Please contact your administrator.'
};

export const pathMap = {
    passexp: '/oauth2/passexp',
    passchange: '/oauth2/change/password'
};

export const formValidationErrors = {
    required: 'Fields are required',
    match: 'Fields should match',
    new: 'Password should not repeat old one'
};

export const passwordPolicyStatuses: Array<string> = [
    'the new password contains username',
    'the new password does not contain enough alpha characters',
    'the new password does not contain enough non-alpha characters',
    'the new password is not long enough',
    'the new password does not contain enough unique characters',
    'the new password is a dictionary word or a proper name',
    'this password was used previously',
    'the new password is too similar to the old password'
];
