/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import * as angular from 'angular';
import IStateProvider = angular.ui.IStateProvider;
import { createDetailState } from '../state-definition-helpers';
import PermissionName from '../../permissions/enums/PermissionName';
import { upgradeAdapter } from '../../upgrade-adapter';
import { JailArrestDetailComponent } from './arrest/jail-arrest-detail.component';
import { JailBookingDetailComponent } from './booking/jail-booking-detail.component';
import { JailIncidentDetailComponent } from './incident/jail-incident-detail.component';
import { JailOffenseDetailComponent } from './offense/jail-offense-detail.component';

export const jailModule = angular.module('jail', [])
    .config(['$stateProvider', function ($stateProvider: IStateProvider) {
        $stateProvider
            .state('app.search.jailArrestDetails', createDetailState('jail-arrest', 'jlarrest', { permission: PermissionName.arrest }))
            .state('app.search.jailBookingDetails', createDetailState('jail-booking', 'jlbook', { permission: PermissionName.booking }))
            .state('app.search.jailIncidentDetails', createDetailState('jail-incident', 'jlincdnt', { permission: PermissionName.incident }))
            .state('app.search.jailOffenseDetails', createDetailState('jail-offense', 'jloffens', { permission: PermissionName.offense }));
    }])

    .directive('sdsJailArrestDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(JailArrestDetailComponent))
    .directive('sdsJailBookingDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(JailBookingDetailComponent))
    .directive('sdsJailIncidentDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(JailIncidentDetailComponent))
    .directive('sdsJailOffenseDetail', <angular.IDirectiveFactory>upgradeAdapter.downgradeNg2Component(JailOffenseDetailComponent));
