/* Copyright © 2020 Motorola Solutions, Inc. All rights reserved. */

import { table, field, expand } from '../../../../shared/database/decorators';

/**
 * The accident roadway model
 */
@table('acrddtl')
export class AccidentRoadway {

    @field('number')
    public id: string;

    @expand('acccntl', 'desc')
    public accessControl: string;

    @field('mainvol')
    public approachVolume: number;

    @field('lnwidth')
    public averageLaneWidth: number;

    @field('mdwidth')
    public averageMedianWidth: number;

    @field('shwidth')
    public averageShoulderWidth: number;

    @expand('bikeway', 'desc')
    public bikeway: string;

    @field('bridgid')
    public bridgeOrStructureId: string;

    @field('rrid')
    public rrCrossingId: string;

    @field('alignln')
    public curveLength: number;

    @expand('delinpr', 'desc')
    public delineator: string;

    @expand('lanesin', 'desc')
    public lanesAtIntersection: string;

    @expand('lanessr', 'desc')
    public lanesOnSideRoad: string;

    @expand('alignum', 'desc')
    public measurementUnit: string;

    @field('partnhs')
    public nationalHighway: boolean;

    @expand('pavemrk', 'desc')
    public pavementMarkings: string;

    @expand('pavemat', 'desc')
    public pavementMaterial: string;

    @field('aligncv')
    public radius: number;

    @expand('fclass', 'desc')
    public roadwayFunctionalClass: string;

    @expand('rwlight', 'desc')
    public roadwayLighting: string;

    @field('gradslp')
    public slopeDirection: string;

    @field('gradper')
    public slopePercentage: number;

    @field('alignsp')
    public superelevation: number;

    @expand('traftyp', 'desc')
    public trafficControlType: string;

    @field('annveh')
    public vehiclesPerDay: number;

    @field('anntra')
    public year: number;

    public slopeDirectionDegree: string;
}
